import { createActionGroup, props } from '@ngrx/store';

import { Config } from '@yuno/api/interface';

export const appConfigActions = createActionGroup({
	source: 'AppConfig',
	events: {
		load: props<{ appId: string }>(),
		loadSuccess: props<{ data: Partial<Config> }>(),
		loadFailure: props<{ error: Error }>(),

		update: props<{ data: Partial<Config> }>(),
		updateSuccess: props<{ data: Partial<Config> }>(),
		updateFailure: props<{ error: Error }>()
	}
});
