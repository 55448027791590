import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import { DashboardHelpItem, DashboardHelpLink } from '@yuno/api/interface';

import { DashboardFacade } from '../../data-access';

export interface ItemForm {
	public?: FormControl<boolean>;
	title: FormGroup<LanguageFormType>;
	description?: FormGroup<LanguageFormType>;
	image?: FormGroup<ImageForm>;
	links?: FormArray<FormGroup<LinkForm>>;
}

export interface ImageForm {
	preview?: FormControl<string>;
	source?: FormControl<string>;
	typeOf?: FormControl<'image' | 'video' | 'gif'>;
}

export type LinkForm = {
	destination?: FormControl<string>;
	title?: FormControl<string>;
};

@Injectable()
export class DashboardEditorService {
	dashboardForm: FormGroup<ItemForm>;

	linkValues: string[] = [];
	linkDisplay: string[] = [];

	get links(): FormArray {
		return (
			(this.dashboardForm.get('links') as FormArray) || new FormArray<FormGroup<LinkForm>>([])
		);
	}

	createFormGroup(): void {
		this.dashboardForm = new FormGroup<ItemForm>({
			public: new FormControl(true, { nonNullable: true }),
			title: newLanguageFormGroup(),
			description: newLanguageFormGroup(),
			image: new FormGroup<ImageForm>({
				preview: new FormControl('', { nonNullable: true }),
				source: new FormControl('', { nonNullable: true }),
				typeOf: new FormControl('image', { nonNullable: true })
			}),
			links: new FormArray<FormGroup<LinkForm>>([])
		});
	}

	removeLink(i: number): void {
		this.links.removeAt(i);
	}

	addLink(link?: DashboardHelpLink): void {
		const newLink = new FormGroup<LinkForm>({
			title: new FormControl('', { nonNullable: true }),
			destination: new FormControl('', { nonNullable: true })
		});
		if (link) {
			newLink.patchValue(link);
		}
		this.links.push(newLink);
	}

	addLinks(links: DashboardHelpLink[]): void {
		if (!links) {
			return;
		}
		const count = links.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addLink(links[i]);
			}
		}
	}
}
