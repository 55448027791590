import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { TilesetCustom } from '@yuno/api/interface';

import { customTilesetActions } from './tilesets-custom.actions';

export interface CustomTilesetState {
	loaded: boolean;
	customTilesets: Partial<TilesetCustom>[];
	selectedLoaded: boolean;
	selected: Partial<TilesetCustom> | undefined;
	status: Status;
}

export const initialStateCustom: CustomTilesetState = {
	loaded: false,
	customTilesets: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateCustom,
	on(appActions.reset, () => initialStateCustom),
	on(customTilesetActions.load, () => ({
		...initialStateCustom,
		status: Status.VALIDATING
	})),
	on(customTilesetActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		customTilesets: data,
		status: Status.SUCCESS
	})),
	on(customTilesetActions.loadFailure, () => ({
		...initialStateCustom,
		status: Status.FAILED
	})),

	// Select
	on(customTilesetActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(customTilesetActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(customTilesetActions.selectFailure, (state, { error }) => ({ ...state, error })),

	// Select Update
	on(customTilesetActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(customTilesetActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	// Save Update
	on(customTilesetActions.saveSuccess, (state, { _id }) => {
		const index = state.customTilesets.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const tilesets = [...state.customTilesets];
		if (index >= 0) {
			tilesets[index] = { ...tilesets[index], ...state.selected };
		} else if (state.selected) {
			tilesets.push({ ...state.selected, _id });
		}
		return {
			...state,
			customTilesets: tilesets
		};
	})
);

export const customTilesetFeature = createFeature({
	name: 'customTileset',
	reducer
});
