@if (form) {
	<form [formGroup]="form">
		<div formGroupName="options" class="grid grid-cols-1 gap-4">
			<div formArrayName="bounds" class="grid grid-cols-2 gap-4">
				<yuno-forms-span class="col-span-2 font-semibold">Bottom Left</yuno-forms-span>
				<yuno-forms-number [formControlName]="0" label="Lng (X)"></yuno-forms-number>
				<yuno-forms-number [formControlName]="1" label="Lat (Y)"></yuno-forms-number>
				<yuno-forms-span class="col-span-2 font-semibold">Top Right</yuno-forms-span>
				<yuno-forms-number [formControlName]="2" label="Lng (X)"></yuno-forms-number>
				<yuno-forms-number [formControlName]="3" label="Lat (Y)"></yuno-forms-number>
			</div>
			<yuno-forms-number formControlName="minZoom" label="minZoom"></yuno-forms-number>
			<yuno-forms-number formControlName="padding" label="padding"></yuno-forms-number>
		</div>
	</form>
}
