import { createActionGroup, props } from '@ngrx/store';

import { Event } from '@yuno/api/interface';

export const eventsActions = createActionGroup({
	source: 'Events',
	events: {
		toggle: props<{ bool: boolean }>(),
		update: props<{ data: Event[] }>(),
		key: props<{
			key: 'events' | 'activeEvents' | 'onClick' | 'onMouseMove' | 'onMouseLeave';
		}>()
	}
});
