import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { TextNewsItem } from '@yuno/api/interface';

import { textNewsItemsActions } from './text-news-item.actions';

export interface TextNewsItemsState {
	data: Partial<TextNewsItem>[];
	status: Status;
}

export const initialStateNewsItems: TextNewsItemsState = {
	data: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateNewsItems,
	on(appActions.reset, () => initialStateNewsItems),
	on(textNewsItemsActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(textNewsItemsActions.loadSuccess, (state, { data }) => ({
		...state,
		data,
		status: Status.SUCCESS
	})),
	on(textNewsItemsActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	}))
);

export const textNewsItemsFeature = createFeature({
	name: 'textnewsitems',
	reducer
});
