import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { State } from '@yuno/api/interface';

import { statesActions } from './states.actions';

export interface StatesState {
	loaded: boolean;
	states: Partial<State>[];
	selectedLoaded: boolean;
	selected: Partial<State> | undefined;
	status: Status;
}

export const initialState: StatesState = {
	loaded: false, // has the States list been loaded
	states: [], // which States record has been selected
	selectedLoaded: false, // last known error (if any)
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(statesActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(statesActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		states: data,
		status: Status.SUCCESS
	})),
	on(statesActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	// Select
	on(statesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(statesActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(statesActions.selectFailure, (state, { error }) => ({ ...state, error })),

	// Select Update
	on(statesActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(statesActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(statesActions.saveSuccess, state => {
		const index = state.states.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const states = [...state.states];
		if (index >= 0) {
			states[index] = { ...states[index], ...state.selected };
		} else if (state.selected) {
			states.push({ ...state.selected });
		}
		// states.sort((a, b) => ((a.state as string) > (b.state as string) ? 1 : -1));

		return {
			...state,
			states: states
		};
	})
);

export const statesFeature = createFeature({
	name: 'states',
	reducer
});
