<router-outlet></router-outlet>

<yuno-card>
	<ng-container content>
		<section class="grid grid-cols-1">
			@if (data$ | async; as data) {
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="false"
					[pagination]="true"
					[pageOptions]="{
						pageSize: 100,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="data.objects"
					[buttons]="tableButtons"
					[columns]="[
						{ key: 'id', label: 'object', parent: 'data' },
						{ key: 'type', label: 'type' },
						{ key: 'public', label: 'public', parent: 'data' },
						{ key: 'epsg', label: 'epsg', parent: 'data' }
					]"
					(clicked)="onSelect($event)">
					<button yuno-admin-add-button (click)="onCreate()">New Object</button>
				</yuno-admin-table>
			}
		</section>
	</ng-container>
</yuno-card>

<ng-template #tableButtons let-row>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
