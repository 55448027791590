<ng-container *transloco="let t">
	@if (data$ | async; as data) {
		<yuno-admin-navbar-buttons-container>
			<button yuno-admin-save-button (click)="onSave()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</yuno-admin-navbar-buttons-container>
		@if (data.legend) {
			<div
				class="!pointer-events-none fixed z-[250] flex h-full flex-col justify-end"
				[ngStyle]="{ 'bottom.px': 36, 'right.px': 36 }">
				<yuno-legend [data]="data.legend" [language]="data.language">
					{{ t('buttons.legend') }}
				</yuno-legend>
			</div>
		}
		<div class="absolute left-0 right-[300px] top-0">
			@if (editContent) {
				<yuno-edit-container
					[col]="12"
					[pos]="3"
					[span]="8"
					[tabs]="['Icon', 'SVG', 'Image', 'List']"
					[startTab]="getStartTab(service.activeContent)"
					(outputTab)="onSwitchTab($event.key)">
					<ng-container buttons>
						<button yuno-admin-close-button (click)="editContent = false"></button>
					</ng-container>
					<div edit-container-content [hasTabs]="true">
						@if (service.activeContent) {
							<form [formGroup]="service.activeContent">
								<yuno-forms-toggle
									labelPos="side"
									formControlName="public"
									label="Public"></yuno-forms-toggle>
								<section formGroupName="title">
									@for (lang of languages; track lang) {
										@if (lang === data.language) {
											<yuno-forms-text
												[language]="lang"
												[formControlName]="lang"
												label="Legend name"></yuno-forms-text>
										}
									}
								</section>
								<yuno-forms-toggle
									labelPos="side"
									formControlName="bold"
									label="Bold Text"></yuno-forms-toggle>
								<yuno-forms-divider></yuno-forms-divider>
							</form>
						}
						@if (service.tabValue === 'Icon') {
							<yuno-admin-legend-icon-editor
								[form]="service.activeContent"></yuno-admin-legend-icon-editor>
						}
						@if (service.tabValue === 'SVG') {
							<yuno-admin-legend-svg-editor
								[form]="service.activeContent"></yuno-admin-legend-svg-editor>
						}
						@if (service.tabValue === 'Image') {
							<yuno-admin-legend-image-editor
								[form]="service.activeContent"></yuno-admin-legend-image-editor>
						}
						@if (service.tabValue === 'List') {
							<yuno-admin-legend-list-editor
								[form]="service.activeContent"
								[language]="data.language"
								(list)="editList = true"></yuno-admin-legend-list-editor>
						}
						<yuno-admin-legend-active-layer-editor
							[form]="service.activeContent"></yuno-admin-legend-active-layer-editor>
					</div>
				</yuno-edit-container>
			}
			@if (editList) {
				<yuno-edit-container
					[col]="8"
					[pos]="3"
					[span]="4"
					[style.zIndex]="210"
					[tabs]="['Icon', 'SVG', 'Image', 'Gradient']"
					[startTab]="getStartTab(service.activeList)"
					(outputTab)="onSwitchTabList($event.key)">
					<ng-container buttons>
						<button yuno-admin-close-button (click)="editList = false"></button>
					</ng-container>
					<div edit-container-content [hasTabs]="true">
						@if (service.activeList) {
							<form [formGroup]="service.activeList">
								<yuno-forms-toggle
									labelPos="side"
									formControlName="public"
									label="Public"></yuno-forms-toggle>
								<section formGroupName="title">
									@for (lang of languages; track lang) {
										@if (lang === data.language) {
											<yuno-forms-text
												[language]="lang"
												[formControlName]="lang"
												label="Legend name"></yuno-forms-text>
										}
									}
								</section>
								<yuno-forms-toggle
									labelPos="side"
									formControlName="bold"
									label="Bold Text"></yuno-forms-toggle>
								<yuno-forms-divider></yuno-forms-divider>
							</form>
						}
						@if (service.tabListValue === 'Icon') {
							<yuno-admin-legend-icon-editor
								[form]="service.activeList"></yuno-admin-legend-icon-editor>
						}
						@if (service.tabListValue === 'SVG') {
							<yuno-admin-legend-svg-editor
								[form]="service.activeList"></yuno-admin-legend-svg-editor>
						}
						@if (service.tabListValue === 'Image') {
							<yuno-admin-legend-image-editor
								[form]="service.activeList"></yuno-admin-legend-image-editor>
						}
						@if (service.tabListValue === 'Gradient') {
							<yuno-admin-legend-gradient-editor
								[form]="service.activeList"></yuno-admin-legend-gradient-editor>
						}
						<yuno-admin-legend-active-layer-editor
							[form]="service.activeList"></yuno-admin-legend-active-layer-editor>
					</div>
				</yuno-edit-container>
			}
		</div>
		@if (data.legend) {
			<yuno-card>
				<ng-container title>Legend</ng-container>
				<ng-container content>
					@if (service.form) {
						<form [formGroup]="service.form" class="flex flex-col gap-4 xl:grid-cols-1">
							<yuno-forms-text
								formControlName="id"
								label="Legend name"></yuno-forms-text>
							<section formGroupName="title">
								@for (lang of languages; track lang) {
									@if (lang === data.language) {
										<yuno-forms-text
											[language]="lang"
											[formControlName]="lang"
											label="Title text (optional)"></yuno-forms-text>
									}
								}
							</section>
							<yuno-forms-toggle
								labelPos="side"
								formControlName="openOnStartup"
								label="Open on Startup"></yuno-forms-toggle>
							<div
								cdkDropList
								[cdkDropListData]="service.content"
								(cdkDropListDropped)="drop($event)"
								formArrayName="content">
								<div class="mb-1 mt-6">
									<span class="text-sm font-semibold">Content</span>
								</div>
								@for (
									alias of service.content.controls;
									track alias;
									let i = $index
								) {
									<div
										cdkDrag
										[formGroupName]="i"
										class="mb-2 flex h-15 w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-300 bg-yuno-blue-gray-200 p-2">
										<div cdkDragHandle class="hover:cursor-move">
											<svg
												viewBox="0 0 16 24"
												class="mx-2 h-6 w-4 text-gray-400 group-hover:text-yuno-blue-text"
												fill="currentColor">
												<use href="#drag-icon" />
											</svg>
										</div>
										<!-- SVG -->
										@if (!alias.value.image && alias.value.svg) {
											<yuno-svg
												class="mt-1 h-4 w-6"
												[src]="alias.value.svg"
												[fill]="alias.value.color"
												[parentScale]="true"></yuno-svg>
										}
										<span
											[innerHTML]="
												alias.value.title
													| languageSelect: data.language
													| safeHtml
											"></span>
										<div class="flex-1"></div>
										<div class="flex justify-end gap-2">
											<button
												yuno-admin-duplicate-button
												(click)="addContent(alias.value)"></button>
											<button
												yuno-admin-edit-button
												(click)="editContentAt(i)"></button>
											<button
												yuno-admin-delete-button
												(click)="removeContentAt(i)"></button>
										</div>
									</div>
								}
								<button yuno-admin-add-button (click)="addContent()">
									Legend Item
								</button>
							</div>
						</form>
					}
				</ng-container>
			</yuno-card>
		}
	}
	<router-outlet></router-outlet>
</ng-container>
