import { gql } from 'apollo-angular';

import { Sidemenu } from '@yuno/api/interface';

export const GET_SIDEMENUS = gql`
	query getSidemenus($appId: String!) {
		getSidemenus(appId: $appId) {
			_id
			id
			updatedAt
		}
	}
`;

export const GET_SIDEMENU = gql`
	query getSidemenu($appId: String!, $id: String!) {
		getSidemenu(appId: $appId, id: $id) {
			_id
			appId
			default
			id
			layers {
				group
				items {
					layer
					public
					title
				}
				layer
				public
				title
			}
			themes {
				public
				theme {
					id
					style {
						_id
						style
					}
				}
				title
			}
		}
	}
`;

export type GetQuery = {
	getSidemenus: Partial<Sidemenu>[];
};

export type SelectQuery = {
	getSidemenu: Partial<Sidemenu>;
};
