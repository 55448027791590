import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DestroyRef, ElementRef, Injectable, TrackByFunction, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormGroup } from '@angular/forms';
import { debounceTime, startWith, tap } from 'rxjs';

import { EventForm, EventsFacade } from '@yuno/admin/features/events';
import { moveItemInFormArray } from '@yuno/angular/forms';
import { MessageService, ToastItem, ToastType } from '@yuno/angular/notifications';
import { TextfieldDataInject, TextfieldDataService } from '@yuno/angular/textfield';
import {
	Textfield,
	TextfieldComponentKeys,
	TextfieldComponents,
	TextfieldComponentsTypes
} from '@yuno/api/interface';

import {
	TextfieldComponentForm,
	TextfieldEditorService,
	TextfieldFacade,
	TextfieldForm
} from '../../../data-access';
import { ContentKeys } from '../../content/types';
import { DuplicateTextfieldComponent } from './textfield-dynamic/textfield-dynamic.component';

@Injectable({
	providedIn: 'root'
})
export class TextfieldEditorComponentService {
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly eventsFacade = inject(EventsFacade);
	private readonly destroyRef = inject(DestroyRef);
	private readonly message = inject(MessageService);
	private readonly textfieldDataService = inject(TextfieldDataService);

	readonly textfieldEditorService = inject(TextfieldEditorService);

	originalData: Partial<Textfield>;
	disableClose = false;
	checkForId = false;
	addItems = false;
	library = false;
	splitContainer: ElementRef;

	injectorComponents: TextfieldDataInject[];
	editor: {
		type: TextfieldComponentKeys | undefined;
		content: TextfieldComponentsTypes | undefined;
		index: number | undefined;
	} = { type: undefined, content: undefined, index: undefined };

	textfield$ = this.textfieldFacade.selectedTextfield$.pipe(
		tap(data => {
			if (!this.originalData) {
				this.originalData = data as Textfield;
				this.textfieldEditorService.active$.next(true);
				this.textfieldEditorService.addComponents(data?.components);

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.textfieldEditorService.form.patchValue(this.originalData as any);
			}

			if (data && this.checkForId) {
				this.checkForId = false;
				if (data.components && data.components.length >= 1) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.textfieldEditorService.components.patchValue(data.components as any);
				}
			}
		})
	);

	toggle$ = this.eventsFacade.toggle$.pipe(
		startWith(false),
		tap(toggle => {
			if (toggle) {
				this.disableClose = true;
			}
			if (!toggle) {
				setTimeout(() => {
					this.disableClose = false;
				}, 500);
			}
		})
	);

	templates$ = this.textfieldFacade.templates$.pipe(
		tap(data => {
			if (data && data.length >= 1) {
				this.textfieldEditorService.addComponents(data);
			}
		})
	);

	get form(): FormGroup<TextfieldForm> {
		return this.textfieldEditorService.form;
	}

	get component(): FormGroup | null {
		return this.textfieldEditorService.component;
	}

	get components(): FormArray<FormGroup<TextfieldComponentForm>> {
		return this.textfieldEditorService.components;
	}

	get events(): FormArray<FormGroup<EventForm>> | null {
		return this.textfieldEditorService.events;
	}

	get eventType(): 'events' | 'activeEvents' {
		return this.textfieldEditorService.eventType;
	}

	onChanges(): void {
		this.textfieldEditorService.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.textfieldFacade.updateSelect(
					this.textfieldEditorService.form.getRawValue() as Partial<Textfield>
				);
			});
	}

	onSave() {
		this.textfieldEditorService.form.markAllAsTouched();
		this.textfieldFacade.save();
		this.checkForId = true;
	}

	onSelect(id: string | null) {
		if (!id) {
			this.textfieldFacade.updateSelect(
				this.textfieldEditorService.form.getRawValue() as Partial<Textfield>
			);
			return;
		}
		this.textfieldFacade.select(id);
	}

	onClose(): void {
		this.textfieldFacade.clearSelect();
		this.textfieldEditorService.active$.next(false);
	}

	onDestroy() {
		const id = this.textfieldEditorService.form.get('_id')?.value;
		if (id) {
			this.textfieldEditorService._closed.next(id);
		}
		this.textfieldFacade.clearSelect();
	}

	onBrowseLibrary() {
		this.onEditClear();
		this.addItems = false;
		this.library = true;
	}

	onAddItem() {
		this.onEditClear();
		this.addItems = true;
		this.library = false;
	}

	onAddComponent(e: ContentKeys, component?: TextfieldComponents) {
		this.textfieldEditorService.addComponent(e, component);
		this.sendToast(`successfully added a ${e}!`, 'success');
	}

	onEditComponent(event: TextfieldComponentKeys, item: TextfieldComponentsTypes, index: number) {
		this.onEditClear();

		setTimeout(() => {
			this.editor.content = item;
			this.editor.type = event;
			this.editor.index = index;
			this.addItems = false;
			this.library = false;
			this.textfieldEditorService.componentIndex = index;
			this.textfieldEditorService.componentType = event;
		}, 100);
	}

	onEditClear() {
		this.editor.type = undefined;
		this.editor.content = undefined;
		this.editor.index = undefined;
	}

	/* CDK DragList Drop Event */
	drop(event: CdkDragDrop<FormArray<FormGroup<TextfieldComponentForm>>>): void {
		moveItemInFormArray(
			this.textfieldEditorService.components,
			event.previousIndex,
			event.currentIndex
		);
	}

	sendToast(message: string, type: ToastType) {
		const item: ToastItem = {
			message: message
		};
		this.message.showToast(item, type);
	}

	trackByComponentId: TrackByFunction<TextfieldDataInject> = (index, component) => {
		return this.textfieldDataService.getComponentId(index, component);
	};

	/**
	 * Duplicate a existing component
	 */
	onDuplicateComponent(options: DuplicateTextfieldComponent, index: number): void {
		try {
			if (!options.component) {
				this.message.sendToast(`failed to duplicated the ${options.type}!`, 'error');
				return;
			}

			// save the current scroll position
			const current = this.splitContainer?.nativeElement.scrollTop;

			// Create a deep copy of the component
			// and remove the id when available
			const comp = JSON.parse(JSON.stringify(options.component));
			if (typeof comp !== 'string') {
				// removes the objectId
				// to later save as a new component
				delete comp._id;

				// id is used to create a unique identifier
				// when duplicating we adjust this, to avoid
				// saving issues
				if (comp.id) {
					// delete comp.id;
					comp.id = `${comp.id}-duplicate-${Date.now()}`;
				} else {
					comp.id = `duplicate-${Date.now()}`;
				}

				// 	same applies to Name
				if (comp.name) {
					comp.name = `${comp.name}-duplicate-${Date.now()}`;
				} else {
					comp.name = `duplicate-${Date.now()}`;
				}
			}

			// creates the component
			this.textfieldEditorService.addComponent(
				options.type,
				{
					[options.type]: comp
				},
				index + 1
			);

			// start editing the duplicated component
			this.onEditComponent(options.type, comp, index + 1);

			this.message.sendToast(`successfully duplicated the ${options.type}!`, 'success');

			// If current scrollpostiiion is available
			// scroll to that position
			if (current) {
				this.splitContainer.nativeElement.scrollTop = current;
			}
		} catch (e) {
			this.message.sendToast(`successfully duplicated the ${options.type}!`, 'success');
		}
	}

	onRemoveComponent(index: number) {
		this.textfieldEditorService.remove(index);
	}
}
