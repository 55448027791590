import { gql } from 'apollo-angular';

export const DELETE_SIDEMENU = gql`
	mutation deleteSidemenuById($_id: String!, $appId: String!) {
		deleteSidemenuById(_id: $_id, appId: $appId)
	}
`;

export type DeleteMutation = {
	deleteSidemenuById: string;
};
