<ng-container [formGroup]="form">
	<label class="yuno-form">
		@if (item.label) {
			<span
				class="select-none"
				[innerHtml]="
					item.label
						| languageSelect: language
						| safeHtml: item.validators?.required || item.required
				">
			</span>
		}
		@if (control.value) {
			<input [name]="item.key" type="text" [value]="item.value" [formControl]="control" />
		}
		@if (!control.value) {
			<input
				type="file"
				[accept]="accept"
				[name]="item.key"
				[placeholder]="item.placeholder | languageSelect: language || ''" />
		}
	</label>
</ng-container>
