import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SelectFieldComponent } from './select-field.component';

@NgModule({
	declarations: [SelectFieldComponent],
	imports: [CommonModule],
	exports: [SelectFieldComponent]
})
export class YunoSelectFieldModule {}
