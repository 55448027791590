import { gql } from 'apollo-angular';

export const GET_PRODUCTION_BY_APPID = gql`
	query checkProductionByAppId($appId: String!) {
		checkProduction(appId: $appId) {
			planning
			deliverables
		}
	}
`;

export type ProductionQuery = {
	checkProduction: {
		planning: boolean;
		deliverables: boolean;
	};
};
