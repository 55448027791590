@if (form) {
	<form [formGroup]="form" class="grid grid-cols-1 gap-4">
		<yuno-forms-span>
			This is a <strong>Legacy</strong> option. Better to use the Zoom to Bounding Box event.
		</yuno-forms-span>
		<div formGroupName="options" class="grid grid-cols-1 gap-4">
			<div formArrayName="bounds" class="grid grid-cols-2 gap-4">
				<yuno-forms-span class="col-span-2 font-semibold">Bottom Left</yuno-forms-span>
				<yuno-forms-number [formControlName]="0" label="Lng (X)"></yuno-forms-number>
				<yuno-forms-number [formControlName]="1" label="Lat (Y)"></yuno-forms-number>
				<yuno-forms-span class="col-span-2 font-semibold">Top Right</yuno-forms-span>
				<yuno-forms-number [formControlName]="2" label="Lng (X)"></yuno-forms-number>
				<yuno-forms-number [formControlName]="3" label="Lat (Y)"></yuno-forms-number>
			</div>
			<yuno-forms-divider></yuno-forms-divider>
			<yuno-forms-number formControlName="minZoom" label="Zoom"></yuno-forms-number>
		</div>
	</form>
}
