import { gql } from 'apollo-angular';

export const SAVE_DASHBOARD = gql`
	mutation saveDashboard($dashboard: DashboardInput!, $appId: String!) {
		saveDashboard(dashboard: $dashboard, appId: $appId) {
			_id
			intro {
				public
				title
				description
			}
			matomo {
				public
				typeOf
				siteId
			}
			help {
				public
				items {
					public
					title
					description
					image {
						typeOf
						source
						preview
					}
					links {
						destination
						title
					}
				}
			}
			public
			key
		}
	}
`;
