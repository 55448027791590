import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'yuno-warning-icons',
	template: `@if (type) {
		<div
			class="absolute right-4 z-10 h-6 w-6 overflow-hidden rounded-full border border-yuno-blue-gray-200 opacity-80"
			[ngClass]="{ 'top-[38px]': padding, 'top-4': !padding }">
			@switch (type) {
				@case ('warning') {
					<svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#E43535">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
					</svg>
				}
			}
		</div>
	}`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
	standalone: true
})
export class YunoWarningIconsComponent {
	@Input() type?: string;
	@Input() padding? = true;
}
