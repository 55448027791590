import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

// Array Validators
export class ArrayValidators {
	/**
	 * Validates a Form Array to contain a maximum amount of objects
	 *
	 * @param max: Number
	 */
	static maxLength(max: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!(control instanceof FormArray)) {
				return null;
			}

			if (control.length > max) {
				return { maxlength: true };
			}

			return null;
		};
	}

	/**
	 * Validates a Form Array to contain a minimum amount of objects
	 *
	 * @param min: Number
	 */
	static minLength(min: number): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!(control instanceof FormArray)) {
				return null;
			}

			if (control.length < min) {
				return { minlength: true };
			}

			return null;
		};
	}
}
