import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PanoramaEditorComponent } from '../editor/panorama-editor.component';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./panoramas.component').then(m => m.PanoramasComponent),
		children: [
			{ path: 'create', component: PanoramaEditorComponent },
			{ path: 'edit/:id', component: PanoramaEditorComponent },
			{ path: '**', redirectTo: '' }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PanoramasRoutingModule {}
