@if (data$ | async) {
	<div class="mt-4 grid grid-cols-1 gap-4">
		<yuno-forms-divider></yuno-forms-divider>
		<label class="yuno-form">
			<span class="select-none"> Select the Marker </span>
			<select
				class="truncate"
				[(ngModel)]="markerValue"
				(ngModelChange)="onSelectMarker($event)">
				<option [value]="undefined" hidden>Select marker...</option>
				<option [value]="null">Zoom to Self</option>
				@for (select of service.markerValues; track select; let i = $index) {
					<option [value]="select">
						{{ returnDisplay(i, select) }}
					</option>
				}
			</select>
		</label>
		<yuno-admin-annotation type="info">
			<div class="flex flex-col">
				<yuno-forms-span class="font-semibold">*Place Markers</yuno-forms-span>
				<yuno-forms-span>
					Use <strong>Zoom to Self</strong> for letting place markers zoom to their own
					location.
				</yuno-forms-span>
			</div>
		</yuno-admin-annotation>
		<yuno-admin-annotation type="info">
			<div class="flex flex-col">
				<yuno-forms-span class="font-semibold">*Zoom to Self</yuno-forms-span>
				<yuno-forms-span>
					This Feature requires a <strong>Marker</strong> or
					<strong>Point layer</strong> to work.
				</yuno-forms-span>
			</div>
		</yuno-admin-annotation>
		<!--		<yuno-admin-annotation type="info">-->
		<!--			<div class="flex flex-col">-->
		<!--				<yuno-forms-span>-->
		<!--					Currently this only copies the selected markers location. And will not store the-->
		<!--					Markers data. After save, the reference will be lost.-->
		<!--				</yuno-forms-span>-->
		<!--				<yuno-forms-span class="font-semibold"> This is on the to-do list!</yuno-forms-span>-->
		<!--			</div>-->
		<!--		</yuno-admin-annotation>-->
	</div>
}
@if (form) {
	<form [formGroup]="form" class="grid grid-cols-1 gap-4">
		<yuno-forms-divider></yuno-forms-divider>
		<ng-container>
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				@if (form.get('options.coordinates')) {
					<div formArrayName="coordinates" class="grid grid-cols-2 gap-4">
						<yuno-forms-number
							[formControlName]="0"
							label="Lng (X)"></yuno-forms-number>
						<yuno-forms-number
							[formControlName]="1"
							label="Lat (Y)"></yuno-forms-number>
					</div>
				}
				<yuno-forms-number formControlName="zoom" label="Zoom"></yuno-forms-number>
			</div>
		</ng-container>
	</form>
}
