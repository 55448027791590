'use strict';

// https://262.ecma-international.org/15.0/#table-the-typedarray-constructors
module.exports = {
  __proto__: null,
  name: {
    __proto__: null,
    $Int8Array: 'INT8',
    $Uint8Array: 'UINT8',
    $Uint8ClampedArray: 'UINT8C',
    $Int16Array: 'INT16',
    $Uint16Array: 'UINT16',
    $Int32Array: 'INT32',
    $Uint32Array: 'UINT32',
    $BigInt64Array: 'BIGINT64',
    $BigUint64Array: 'BIGUINT64',
    $Float32Array: 'FLOAT32',
    $Float64Array: 'FLOAT64'
  },
  size: {
    __proto__: null,
    $INT8: 1,
    $UINT8: 1,
    $UINT8C: 1,
    $INT16: 2,
    $UINT16: 2,
    $INT32: 4,
    $UINT32: 4,
    $BIGINT64: 8,
    $BIGUINT64: 8,
    $FLOAT32: 4,
    $FLOAT64: 8
  }
};