import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { YunoAdminButtonComponent } from 'libs/admin/ui/src/lib/button';
import { YunoAdminCardComponent } from 'libs/admin/ui/src/lib/card/card-default/card.component';

import { CardButtonDisplay, CardButtonOutput } from '../types';

@Component({
	selector: 'yuno-card-draggable',
	standalone: true,
	imports: [CommonModule, YunoAdminButtonComponent, YunoAdminCardComponent],
	templateUrl: './card-draggable.component.html',
	styleUrls: ['./card-draggable.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminCardDraggableComponent {
	_active = false;
	@Input() draggable = true;
	@Input() paddingCondensed = false;
	@Input() header = true;
	@Input() footer = true;
	@Input() fullHeight = false;
	@Input() buttons: CardButtonDisplay[] = [];
	@Input()
	set active(bool: boolean) {
		this._active = bool;
		this.setOpacity(bool);
	}
	get active(): boolean {
		return this._active;
	}

	@Output() outputBtn = new EventEmitter<CardButtonOutput>();

	opacity = 0;
	hover = false;

	onBtnClick(click: CardButtonOutput): void {
		this.outputBtn.emit(click);
	}

	mouseEvent(bool: boolean): void {
		!this.active && this.setOpacity(bool);
		this.setHover(bool);
	}

	setOpacity(bool: boolean): void {
		this.opacity = bool ? 0.93 : 0;
	}

	setHover(bool: boolean): void {
		this.hover = bool;
	}
}
