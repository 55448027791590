import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { OpenTextPopupEvent } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-open-text-popup',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AnnotationComponent, AsyncPipe],
	templateUrl: './open-text-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenTextPopupComponent extends EventsDefaultFormItemComponent implements OnInit {
	ngOnInit(): void {
		this.service.getTextfields();
		this.onValueChanged((this.form.value.options as OpenTextPopupEvent['options']).id);
	}
}
