import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ImageButtonEditorComponent } from '../editor/image-button-editor.component';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./image-buttons.component').then(m => m.ImageButtonsComponent),
		children: [
			{ path: 'create', component: ImageButtonEditorComponent },
			{ path: 'edit/:id', component: ImageButtonEditorComponent },
			{ path: '**', redirectTo: '' }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ImageButtonsRoutingModule {}
