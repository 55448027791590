import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { LngLatBoundsLike, StyleSpecification } from 'maplibre-gl';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Marker, MarkerCategory } from '@yuno/api/interface';

import { markerCategoriesActions } from './marker-categories.actions';

export interface MarkerCategoriesState {
	loaded: boolean;
	markerCategories: Partial<MarkerCategory>[];
	selectedLoaded: boolean;
	selected: Partial<MarkerCategory> | undefined;
	selectedInput: { index: number } | undefined;
	selectedStyle: { index: number } | undefined;
	status: Status;
	//
	linkedFence: boolean | undefined;
	placeMarkers: Partial<Marker>[] | undefined;
	selectedMarker: Partial<Marker> | undefined;
	markerStatus: Status;

	mapStyle: StyleSpecification | undefined;
}

export const initialState: MarkerCategoriesState = {
	loaded: false, // has the category list been loaded
	markerCategories: [], // which categories record has been selected
	selectedLoaded: false, // last known error (if any)
	selected: undefined,
	selectedInput: undefined,
	selectedStyle: undefined,
	status: Status.PENDING,
	//
	linkedFence: undefined,
	placeMarkers: undefined,
	selectedMarker: undefined,
	markerStatus: Status.PENDING,

	mapStyle: undefined
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(markerCategoriesActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(markerCategoriesActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		markerCategories: data,
		status: Status.SUCCESS
	})),
	on(markerCategoriesActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(markerCategoriesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(markerCategoriesActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(markerCategoriesActions.selectFailure, (state, { error }) => ({
		...state,
		error
	})),

	on(markerCategoriesActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(markerCategoriesActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(markerCategoriesActions.selectInput, (state, { index }) => ({
		...state,
		selectedInput: { index }
	})),

	on(markerCategoriesActions.selectStyle, (state, { index }) => ({
		...state,
		selectedStyle: { index }
	})),

	on(markerCategoriesActions.saveSuccess, state => {
		const categories = [...(state.markerCategories || [])];
		const index = categories.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		if (index >= 0) {
			categories[index] = { ...categories[index], ...state.selected };
		} else if (state.selected) {
			categories.push({ ...state.selected });
		}
		return {
			...state,
			markerCategories: [...categories]
		};
	}),

	/* MARKERS */
	on(markerCategoriesActions.loadMarkersSuccess, (state, { data }) => ({
		...state,
		placeMarkers: data
	})),

	on(markerCategoriesActions.selectMarkerSuccess, (state, { data }) => ({
		...state,
		selectedMarker: data
	})),

	on(markerCategoriesActions.updateMarker, (state, { data }) => ({
		...state,
		selectedMarker: { ...data }
	})),

	on(markerCategoriesActions.clearMarker, state => ({
		...state,
		selectedMarker: undefined,
		markerStatus: Status.PENDING
	})),

	on(markerCategoriesActions.saveMarker, state => ({
		...state,
		markerStatus: Status.PENDING
	})),
	on(markerCategoriesActions.saveMarkerFailure, state => ({
		...state,
		markerStatus: Status.FAILED
	})),
	on(markerCategoriesActions.saveMarkerSuccess, (state, { data }) => {
		const newMarker: Partial<Marker> = {
			geometry: state.selectedMarker?.geometry,
			properties: {
				id: state.selectedMarker?.properties?.id,
				rotation: state.selectedMarker?.properties?.rotation,
				alignment: state.selectedMarker?.properties?.alignment
			}
		};
		const markers = [...(state.placeMarkers || [])];
		const index = markers.findIndex(
			x => x['_id'] === (state.selectedMarker && state.selectedMarker['_id'])
		);

		if (index >= 0) {
			markers[index] = { ...markers[index], ...newMarker };
		} else if (state.selectedMarker) {
			markers.push(newMarker);
		}

		return {
			...state,
			markerStatus: Status.SUCCESS,
			placeMarkers: [...markers],
			selectedMarker: data,
			linkedFence: undefined
		};
	}),
	on(markerCategoriesActions.saveFenceSuccess, state => ({
		...state,
		linkedFence: true
	})),
	on(markerCategoriesActions.loadMapStyleSuccess, (state, acion) => ({
		...state,
		mapStyle: acion.data
	}))
);

export const markerCategoriesFeature = createFeature({
	name: 'markerCategories',
	reducer,
	extraSelectors: ({ selectSelected, selectPlaceMarkers, selectMapStyle }) => ({
		selectedCategoryName: createSelector(selectSelected, data => data?.id),
		selectMarkerById: (id: string) =>
			createSelector(selectPlaceMarkers, data => data?.find(marker => marker._id === id)),
		selectMapStyleBounds: createSelector(selectMapStyle, data => {
			if (!data) {
				return undefined;
			}

			const metadata = data.metadata as { [key: string]: unknown };
			if (!metadata?.['yuno:theme:bounds']) {
				return undefined;
			}

			return metadata['yuno:theme:bounds'] as LngLatBoundsLike;
		})
	})
});
