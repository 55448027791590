import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	inject
} from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { EventsFacade } from '@yuno/admin/features/events/data-access';
import {
	DragRowComponent,
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminAddButtonComponent,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent
} from '@yuno/admin/ui';
import { moveItemInFormArray } from '@yuno/angular/forms';

import {
	EventForm,
	EventFormType,
	EventFormsService,
	EventsForm
} from './../data-access/event-forms.service';
import { EventItemFormComponent } from './event-item-form/event-item-form.component';
import { getEventNames } from './event-item-form/types';

@Component({
	selector: 'yuno-admin-events-forms',
	standalone: true,
	imports: [
		CommonModule,
		EditContainerComponent,
		EditContainerContentDirective,
		DragDropModule,
		DragRowComponent,
		ReactiveFormsModule,
		EventItemFormComponent,
		YunoAdminButtonComponent,
		YunoAdminAddButtonComponent,
		YunoAdminCloseButtonComponent
	],
	templateUrl: './events.forms.component.html',
	styleUrls: ['./events.forms.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsFormsComponent {
	service = inject(EventFormsService);
	eventsFacade = inject(EventsFacade);
	cdr = inject(ChangeDetectorRef);

	/**
	 * the FormGroup that contains the FormArray
	 */
	@Input({ required: true }) group: FormGroup<EventsForm> | FormGroup;

	/**
	 * The FormArray itself
	 */
	@Input({ required: true }) array: FormArray<FormGroup<EventForm>>;

	/**
	 * The Name of the FormArray
	 */
	@Input({ required: true }) arrayName: EventFormType | string;

	hover: number | null;
	// eventIndex = 0;
	editEvent = false;
	editItem: FormGroup<EventForm> | null;

	currentEvent: Event | undefined;
	// currentIndex: number | undefined;

	// constructor(private cdr: ChangeDetectorRef, public eventsFacade: EventsFacade) {}

	drop(event: CdkDragDrop<Event[]>): void {
		moveItemInFormArray(this.array, event.previousIndex, event.currentIndex);
	}

	onAddEvent() {
		const event = this.service.createEvent('zoomTo');
		this.array.push(event);

		this.cdr.markForCheck();
	}

	onEditEvent(event: FormGroup<EventForm>) {
		this.editItem = null;

		setTimeout(() => {
			this.editItem = event;
			this.cdr.markForCheck();
		}, 0);
	}

	getEventName(name?: string): string {
		if (!name) {
			return 'select event...';
		}
		return getEventNames(name);
	}

	onRemoveEvent(index: number) {
		this.array.removeAt(index);
	}

	onClose() {
		this.eventsFacade.toggleEvents(false);
	}
}
