import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { YunoButtonModule } from '@yuno/project-atlas/ui';

import { DrawMapService } from './services/draw-map.service';
import { ToolBarComponent } from './tool-bar/tool-bar.component';

@NgModule({
	imports: [CommonModule, YunoButtonModule, AngularSvgLoaderModule, ToolBarComponent],
	providers: [DrawMapService],
	exports: [ToolBarComponent]
})
export class DrawMapModule {}
