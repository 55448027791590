import { NgClass, NgStyle } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';

import { LanguagePipe } from '@yuno/angular/pipes';

@Component({
	selector: 'yuno-textfield-list-item',
	template: `
		<button
			type="button"
			class="yuno-text-list-button"
			(click)="btnClick()"
			(mouseenter)="hover = true"
			(mouseleave)="hover = false"
			[ngStyle]="{
				color: getActiveColorState() ? color : null,
				backgroundColor: getActiveColorState() ? backgroundColor : null
			}">
			<ng-content></ng-content>
			{{ title | languageSelect: language }}
		</button>
	`,
	styleUrls: ['item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgStyle, NgClass, LanguagePipe]
})
export class TextListItemComponent implements AfterViewInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	@Input() multiselect = false;
	@Input() language?: string;
	@Input() title?: string;
	@Input() disableActiveState = false;

	private _active = false;
	@Input() set active(bool: boolean) {
		this._active = bool;
	}
	get active(): boolean {
		return this._active;
	}

	@Output() activeChange = new EventEmitter<boolean>();

	private _color = '#fff';
	@Input() set color(color: string | undefined) {
		this._color = color || '#fff';
	}
	get color(): string {
		return this._color;
	}

	private _backgroundColor = '#00ADF1';
	@Input() set backgroundColor(color: string | undefined) {
		this._backgroundColor = color || '#00ADF1';
	}
	get backgroundColor(): string {
		return this._backgroundColor;
	}

	hover = false;

	ngAfterViewInit(): void {
		if (this.active) {
			this.activeChange.emit(this.active);
		}
	}

	btnClick(): void {
		// when the disableActiveState is enabled
		// we always return active true
		if (this.disableActiveState) {
			this.active = true;
			this.activeChange.emit(this.active);
			return;
		}

		this.active = !this.active;
		this.activeChange.emit(this.active);
	}

	getActiveColorState(): boolean {
		if (this.disableActiveState) {
			return this.hover || false;
		}

		if (this.hover) {
			return this.active || false;
		}

		return this.active;
	}
}
