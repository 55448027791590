import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { TinymceEditorFormControlNameComponent } from '@yuno/angular/tinymce';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoLanguageIconsComponent } from '../_language-icons/language-icons.component';

@Component({
	selector: 'yuno-forms-html',
	template: `
		<form [formGroup]="getFormGroup()">
			<label class="yuno-form">
				@if (label) {
					<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
				}
				<yuno-tinymce-editor-controlname
					[formControlName]="ngControl.name"></yuno-tinymce-editor-controlname>

				<yuno-language-icons [language]="language"></yuno-language-icons>

				@if (ngControl.invalid && (ngControl.dirty || ngControl.touched)) {
					<div class="invalid-message">
						<ng-content></ng-content>
					</div>
				}
			</label>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		TinymceEditorFormControlNameComponent,
		YunoFormsLabelRequired,
		YunoLanguageIconsComponent
	]
})
export class YunoFormsHtmlTextComponent extends YunoFormsDefaultComponent {
	@Input() language?: string;
}

@Component({
	selector: 'yuno-forms-template-html',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<yuno-tinymce-editor-controlname
				[ngModel]="value"
				(ngModelChange)="valueChange($event)" />
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, TinymceEditorFormControlNameComponent, YunoFormsLabelRequired],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsHtmlTextTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsHtmlTextTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Input() language?: string;
}
