import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';

/**
 * Should return the id from the url
 * using angulars paramMap
 * @param route
 * @returns
 */
export const getIdFromRouter = (route: ActivatedRoute): Promise<string | null> => {
	return new Promise(resolve => {
		route.paramMap.pipe(take(1)).subscribe(data => {
			resolve(data.get('id'));
		});
	});
};
