import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Client } from '@yuno/api/interface';

import { clientsActions } from './clients.actions';
import { clientsFeature } from './clients.state';

@Injectable()
export class ClientsFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(clientsFeature.selectLoaded));
	clients$ = this.store.pipe(select(clientsFeature.selectClients));
	selectedClient$ = this.store.pipe(select(clientsFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(clientsFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(clientsFeature.selectStatus));

	loadClients() {
		this.store.dispatch(clientsActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(clientsActions.select({ _id }));
	}

	clearSelect() {
		this.store.dispatch(clientsActions.clearSelect());
	}

	updateClient(data: Partial<Client>) {
		this.store.dispatch(clientsActions.updateSelect({ data: data }));
	}

	save() {
		this.store.dispatch(clientsActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(clientsActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(clientsActions.delete({ _id }));
	}
}
