import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { ParticipationNotifications } from '@yuno/api/interface';

import { notificationsActions } from './notifications.actions';

export interface ParticipationsNotificationState {
	data: Partial<ParticipationNotifications>[];
	loaded: Status;
	selected: ParticipationNotifications | undefined;
}

export const initialStateNotifications: ParticipationsNotificationState = {
	data: [],
	loaded: Status.PENDING,
	selected: undefined
};

const reducer = createReducer(
	initialStateNotifications,
	on(appActions.reset, () => initialStateNotifications),
	on(notificationsActions.reset, () => ({
		...initialStateNotifications
	})),

	on(notificationsActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),

	on(notificationsActions.loadSuccess, (state, action) => ({
		...state,
		data: action.data,
		loaded: Status.SUCCESS
	})),

	on(notificationsActions.loadFailure, state => ({
		...state,
		models: [],
		modelsLoaded: Status.FAILED
	})),

	on(notificationsActions.selectSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),

	on(notificationsActions.clearSelect, state => ({
		...state,
		selected: undefined
	})),

	on(notificationsActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: {
			...state.selected,
			...data
		} as ParticipationNotifications
	})),

	on(notificationsActions.saveSuccess, state => {
		// Extract the 'notifications' and 'selectedNotification' properties from the 'state' object using destructuring.
		const list = state.data;
		const model = state.selected;

		// Find the index of the selected model in the list using 'findIndex'.
		const index = list.findIndex(x => x['_id'] === model?.['_id']);

		// Create a new array 'data' by spreading the 'list' array.
		const data = [...list];

		// If the selected model already exists in the array, update it by merging the existing object with the selected object.
		// Otherwise, if the selected model exists and is truthy, add it to the end of the 'data' array.
		if (index >= 0) {
			data[index] = { ...data[index], ...model };
		} else if (model) {
			data.push(model);
		}

		// Sort the 'data' array based on the 'id' property of its elements.
		// The nullish coalescing operator '??' is used to handle undefined or null values in the 'id' property.
		data.sort((a, b) => {
			const idA = a.id ?? '';
			const idB = b.id ?? '';
			return idA > idB ? 1 : -1;
		});

		return {
			...state,
			data: data
		};
	})
);

export const notificationsFeature = createFeature({
	name: 'participation-notifications',
	reducer
});
