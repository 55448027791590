import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Injector,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs';

import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { TextEditorsService } from '../text-editors.service';
import { token } from '../token';

interface InjectData {
	data: FormGroup;
	index: number;
}

@Component({
	selector: 'yuno-admin-header-editor',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AsyncPipe],
	templateUrl: './page-header-editor.component.html',
	styleUrls: ['./page-header-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageHeaderEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly inject = inject(Injector);
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService().pipe(
		map(service => {
			return this.editService.returnPagesService(service);
		})
	);
	language$ = this.editService.language$;

	injectedData: InjectData;
	originalData: FormGroup;

	ngOnInit(): void {
		const data = this.inject.get(token) as InjectData | unknown;
		this.injectedData = data as InjectData;
		this.originalData = this.injectedData.data;
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
