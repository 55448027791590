@if (data$ | async; as data) {
	<section class="grid grid-cols-1 gap-4">
		<div class="flex justify-between gap-2">
			@if (minimalAppRole(userRoles.EDITOR)) {
				<button yuno-admin-add-button (click)="onAdd(undefined, data.appId)">
					Add Item
				</button>
				<yuno-admin-search-bar
					[debounceTime]="100"
					[minimumLength]="0"
					(searches)="applyFilter($event)"></yuno-admin-search-bar>
			}
		</div>
		<div class="flex select-none flex-col flex-wrap items-end justify-end gap-2 md:flex-row">
			<div
				class="pointer-events-auto flex flex-col items-start justify-center gap-2 rounded-lg border border-gray-300 bg-gray-100 p-1 xs:flex-row xs:items-center xs:rounded-full">
				<div class="px-2 text-sm italic">Filter</div>
				@for (pill of filterPills; track pill) {
					<div
						class="flex w-full cursor-pointer flex-row justify-center rounded-full px-4 py-1 text-sm transition-colors ease-in-out hover:bg-gray-300"
						[ngClass]="{
							'pointer-events-none bg-yuno-blue text-white': filter === pill.value
						}"
						(click)="setFilter(pill.value)">
						{{ pill.label }}
					</div>
				}
			</div>

			<div
				class="pointer-events-auto flex flex-col items-start justify-center gap-2 rounded-lg border border-gray-300 bg-gray-100 p-1 xs:flex-row xs:items-center xs:rounded-full">
				<div class="px-2 text-sm italic">Preview</div>
				@for (pill of previewPills; track pill) {
					<div
						class="flex w-full cursor-pointer flex-row justify-center rounded-full px-4 py-1 text-sm transition-colors ease-in-out hover:bg-gray-300"
						[ngClass]="{
							'pointer-events-none bg-yuno-blue text-white': preview === pill
						}"
						(click)="setPreview(pill)">
						<div class="flex flex-row gap-2">
							{{ pill }}
						</div>
					</div>
				}
			</div>
		</div>
		@if (data.files) {
			<yuno-admin-table
				[selectable]="false"
				[draggable]="false"
				[filterable]="false"
				[sortable]="true"
				[pagination]="true"
				[pageOptions]="{
					pageSize: 50,
					pageSizeOptions: [10, 25, 50, 100],
					hidePageSize: false
				}"
				[defaultColumn]="preview === 'No' ? 'fileName' : 'url'"
				[data]="data.files"
				[customTemplate]="tableImage"
				[buttons]="tableButtons"
				[activeRow]="activeFile"
				[columns]="[
					{
						key: 'url',
						label: 'Image',
						type: 'custom',
						parent: 'data',
						hidden: preview === 'No',
						sticky: true,
						maxWidth: 17,
						sortable: false
					},
					{ key: 'fileName', label: 'Name', parent: 'data' },
					{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
				]"
				(clicked)="setActiveFile($event)">
			</yuno-admin-table>
		}
	</section>

	<ng-template #tableImage let-row>
		<div
			class="bg-stripes-gray my-2 flex aspect-video w-48 items-center justify-center overflow-hidden rounded md:w-60">
			@if (row.data.ext; as ext) {
				@if (ext === '.pdf') {
					<img
						class="max-h-full object-cover"
						src="https://cdn.projectatlas.app/content/static/images/portal-pdf.svg"
						alt="pdf" />
				} @else if (ext === '.zip') {
					<img
						class="max-h-full object-cover"
						src="https://cdn.projectatlas.app/content/static/images/portal-zip.svg"
						alt="zip" />
				} @else {
					<img
						class="max-h-full object-cover"
						[src]="row.data?.url"
						[srcset]="createSrcSet(row)"
						(error)="onImgError($event, row)"
						loading="lazy"
						[alt]="row.data?.fileName" />
				}
			}
		</div>
	</ng-template>

	<ng-template #tableButtons let-row>
		<button yuno-admin-button-table color="secondary" (click)="onCopy(row)">Copy Link</button>
		<button yuno-admin-edit-button (click)="onEdit(row, data.appId)"></button>
		<button yuno-admin-delete-admin-button (click)="onDelete(row, data.appId)"></button>
	</ng-template>
}
