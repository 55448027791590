import { NgClass, NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnInit,
	inject
} from '@angular/core';

import { LanguageStringsModel, MediaImage } from '@yuno/api/interface';

import { LanguagePipe } from '../../../../../pipes/src/lib/language/language.pipe';
import { EventsService } from '../../services/events.service';
import { TextfieldToken } from '../../textfield.injection.token';

@Component({
	selector: 'yuno-textfield-image',
	templateUrl: './text-image.component.html',
	styleUrls: ['./text-image.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, NgStyle, LanguagePipe]
})
export class TextImageComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	private events = inject(EventsService);

	@HostBinding('class') classes = 'mt-2 md:mt-4 first:mt-0 max-w-full h-full block';
	@HostBinding('class.h-full') hFull = false;
	@HostBinding('class.h-auto') hAuto = true;

	_data: MediaImage;
	@Input() set data(data: MediaImage) {
		this._data = data;
		this.handleData();
	}

	get data(): MediaImage {
		return this._data;
	}

	@Input() set fullheight(bool: boolean) {
		this.hAuto = !bool;
		this.hFull = bool;
	}

	@Input() src?: string;
	@Input() language?: string;
	@Input() description?: LanguageStringsModel;
	@Input() alt?: LanguageStringsModel;

	@Input() @HostBinding('class.zoomable') zoomable?: boolean;

	@Input() disableInjection = false;

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as MediaImage,
				this.injectValue.language
			);
	}

	/**
	 * Setup the Component by using the Injection Method
	 *
	 * @param {MediaImage} data
	 */
	handleInjectionData(data: MediaImage, language?: string): void {
		this.language = language;
		this.data = data;
	}

	handleData(): void {
		this.src = this.src || this.data?.file?.data?.url;
		this.description = this.data.description;
		this.alt = this.data.alt;
		this.zoomable = this.data.zoomable;
	}

	openImage(): void {
		this.events.sendEvent([
			{
				type: 'openImage',
				options: {
					src: this.src || this.getImageUrl()
				}
			}
		]);
	}

	getImageUrl(): string | undefined {
		if (!this.data) {
			return undefined;
		}

		return this.data?.file?.data?.url;
	}

	getImageSize(): number | undefined {
		if (!this.data) {
			return undefined;
		}

		return this.data?.file?.data?.originalSize;
	}
}
