import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_STATE,
	DeleteStateMutation
} from '@yuno/admin/features/states/utils/graphql/deleteStates';
import {
	GET_STATES_BY_APPID,
	GET_STATE_BY_ID,
	SelectStateQuery,
	StatesQuery
} from '@yuno/admin/features/states/utils/graphql/getStates';
import {
	DUPLICATE_STATE,
	DuplicateStateMutation,
	SAVE_STATE,
	SaveStateMutation
} from '@yuno/admin/features/states/utils/graphql/saveState';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { statesActions } from './states.actions';
import { statesFeature } from './states.state';

export const loadStates = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(statesActions.load, statesActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<StatesQuery>({
						query: GET_STATES_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.states) {
								throw new Error('no State found');
							}
							return statesActions.loadSuccess({
								data: data.data.states
							});
						}),
						take(1),
						catchError(error => of(statesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectStates = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(statesActions.select),
			exhaustMap(state =>
				graphql
					.query<SelectStateQuery>({
						query: GET_STATE_BY_ID,
						variables: {
							_id: state._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedState) {
								message.sendToast(`Error selecting state!`, 'error');
								throw new Error('no State with that id found');
							}

							return statesActions.selectSuccess({
								data: data.data.selectedState
							});
						}),
						take(1),
						catchError(error => of(statesActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedState = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(statesActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating State!`, 'error');
					throw new Error('no State found');
				}
				return statesActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(statesActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveState = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(statesActions.save),
			withLatestFrom(
				store.pipe(select(statesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveStateMutation>({
						mutation: SAVE_STATE,
						variables: {
							state: selected,
							appId: appId
						}
					})
					.pipe(
						map(state => {
							if (!state.data?.saveState) {
								throw new Error('error saving State to the database');
							}
							store.dispatch(
								statesActions.updateSelect({
									data: state.data.saveState
								})
							);
							return statesActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(statesActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateState = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(statesActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([state, appId]) =>
				graphql
					.mutate<DuplicateStateMutation>({
						mutation: DUPLICATE_STATE,
						variables: {
							_id: state._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateState) {
								throw new Error('error duplicating State to the database');
							}
							store.dispatch(statesActions.reload());
							return statesActions.duplicateSuccess({
								data: data.data.duplicateState
							});
						}),
						take(1),
						catchError(error => of(statesActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteState = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(statesActions.delete),
			switchMap(state =>
				graphql
					.mutate<DeleteStateMutation>({
						mutation: DELETE_STATE,
						variables: {
							_id: state._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteState) {
								throw new Error('error deleting State from the database');
							}
							store.dispatch(statesActions.reload());
							return statesActions.deleteSuccess({
								_id: data.data.deleteState
							});
						}),
						take(1),
						catchError(error => of(statesActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveStateSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(statesActions.saveSuccess),
			tap(() => {
				message.sendToast(`State successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveStateFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(statesActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving State!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateStateSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(statesActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`State successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateStateFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(statesActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating State!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteStateSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(statesActions.deleteSuccess),
			tap(() => {
				message.sendToast(`State successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteStateFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(statesActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting State!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
