import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Pages } from '@yuno/api/interface';

import { pagesActions } from './pages.actions';
import { pagesFeature } from './pages.state';

@Injectable()
export class PagesFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(pagesFeature.selectLoaded));
	pages$ = this.store.pipe(select(pagesFeature.selectPages));
	pageItems$ = this.store.pipe(select(pagesFeature.selectPageItems));

	selectedPages$ = this.store.pipe(select(pagesFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(pagesFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(pagesFeature.selectStatus));

	get() {
		this.store.dispatch(pagesActions.load());
	}

	getPageItems() {
		this.store.dispatch(pagesActions.loadItems());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(pagesActions.select({ _id }));
	}

	updateSelect(data: Partial<Pages>) {
		this.store.dispatch(pagesActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(pagesActions.clearSelect());
	}

	save() {
		this.store.dispatch(pagesActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(pagesActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(pagesActions.delete({ _id }));
	}
}
