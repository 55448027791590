import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { YunoCardModule } from '@yuno/admin/ui';

import { FileLibraryComponent } from '../file-library/file-library.component';

@Component({
	selector: 'yuno-file-library-routing',
	standalone: true,
	imports: [YunoCardModule, FileLibraryComponent, RouterOutlet],
	templateUrl: './file-library-routing.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryRoutingComponent {}
