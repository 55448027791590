var jsonlint = require('jsonlint-lines'),
  geojsonHintObject = require('./object');

/**
 * @alias geojsonhint
 * @param {(string|object)} GeoJSON given as a string or as an object
 * @param {Object} options
 * @param {boolean} [options.noDuplicateMembers=true] forbid repeated
 * properties. This is only available for string input, becaused parsed
 * Objects cannot have duplicate properties.
 * @param {boolean} [options.precisionWarning=true] warn if GeoJSON contains
 * unnecessary coordinate precision.
 * @returns {Array<Object>} an array of errors
 */
function hint(str, options) {
  var gj,
    errors = [];
  if (typeof str === 'object') {
    gj = str;
  } else if (typeof str === 'string') {
    try {
      gj = jsonlint.parse(str);
    } catch (e) {
      var match = e.message.match(/line (\d+)/);
      var lineNumber = parseInt(match[1], 10);
      return [{
        line: lineNumber - 1,
        message: e.message,
        error: e
      }];
    }
  } else {
    return [{
      message: 'Expected string or object as input',
      line: 0
    }];
  }
  errors = errors.concat(geojsonHintObject.hint(gj, options));
  return errors;
}
module.exports.hint = hint;