import { OverlayModule } from '@angular/cdk/overlay';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	inject
} from '@angular/core';
import { combineLatest, startWith, tap } from 'rxjs';

import { AdminSearchBarComponent, YunoAdminButtonsModule, YunoCardModule } from '@yuno/admin/ui';
import { ApiModule } from '@yuno/angular/api';
import { Textfield } from '@yuno/api/interface';

import { TextfieldFacade } from '../../data-access';
import { TextfieldLibraryService } from './textfield-library.service';

@Component({
	selector: 'yuno-textfield-library',
	standalone: true,
	imports: [
		YunoCardModule,
		YunoAdminButtonsModule,
		ApiModule,
		CommonModule,
		OverlayModule,
		AdminSearchBarComponent,
		AsyncPipe
	],
	template: `
		@if (data$ | async; as data) {
			<section class="mb-6 flex flex-row justify-end gap-2">
				<yuno-admin-search-bar
					[debounceTime]="100"
					[minimumLength]="0"
					(searches)="applyFilter($event)"></yuno-admin-search-bar>
			</section>
			<div class="flex flex-col gap-2">
				@for (text of data.textfields; track text._id) {
					<div
						class="flex w-full flex-row items-center justify-between gap-2 rounded-md border border-yuno-blue-gray-300 bg-yuno-blue-gray-200 p-2 text-yuno-blue-gray-600 hover:border-yuno-blue-gray-400 hover:bg-yuno-blue-gray-300"
						[ngClass]="{
							'bg-yuno-blue-gray-400 text-yuno-blue-text':
								data?.selected?._id === text._id
						}">
						{{ text.id }}
						<button
							yuno-admin-button-table
							color="primary"
							(click)="selectTextfield(text)">
							Select
						</button>
					</div>
				}
			</div>
		}
	`,

	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextfieldLibraryComponent implements OnInit {
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly service = inject(TextfieldLibraryService);
	private readonly cdr = inject(ChangeDetectorRef);

	data$ = combineLatest({
		textfields: this.textfieldFacade.allTextfields$.pipe(
			tap(() => {
				this.runChangeDetection();
			})
		),
		selected: this.service.selected$.pipe(startWith(undefined)),
		filter: this.service.filter$.pipe(
			startWith(undefined),
			tap(filter => {
				if (filter && filter.length >= 2) {
					this.textfieldFacade.search(filter);
				}
			})
		)
	});

	ngOnInit(): void {
		this.textfieldFacade.get();
	}

	applyFilter(str: string): void {
		this.service.filter(str.toLowerCase());
	}

	selectTextfield(text: Partial<Textfield>): void {
		this.service.selectTextfield(text as Textfield);
	}

	// For some reason when using inside TinyMCE the change detection does not work
	// correctly. This is a workaround to force the change detection to run.
	runChangeDetection() {
		setTimeout(() => {
			this.cdr.detectChanges();
		});
	}
}
