import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Config } from '@yuno/api/interface';

import { appConfigActions } from './config.actions';

export interface ConfigState {
	loaded: boolean;
	config: Partial<Config>;
	status: Status;
}

export const initialState: ConfigState = {
	loaded: false,
	config: {},
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(appConfigActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(appConfigActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		config: data,
		status: Status.SUCCESS
	})),
	on(appConfigActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(appConfigActions.update, (state, { data }) => ({
		...state,
		config: data
	}))
);

export const appConfigFeature = createFeature({
	name: 'appConfig',
	reducer
});
