import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Sidemenu } from '@yuno/api/interface';

import { sidemenuActions } from './sidemenu.actions';
import { sidemenuFeature } from './sidemenu.state';

@Injectable()
export class SidemenuFacade {
	private readonly store = inject(Store);

	data$ = this.store.pipe(select(sidemenuFeature.selectData));
	selected$ = this.store.pipe(select(sidemenuFeature.selectSelected));
	status$ = this.store.pipe(select(sidemenuFeature.selectLoaded));

	get() {
		this.store.dispatch(sidemenuActions.load());
	}

	select(id: string): void {
		this.store.dispatch(sidemenuActions.select({ _id: id }));
	}

	updateSelected(data: Sidemenu): void {
		this.store.dispatch(sidemenuActions.updateSelect({ data }));
	}

	duplicate(id: string): void {
		this.store.dispatch(sidemenuActions.duplicate({ _id: id }));
	}

	delete(id: string): void {
		this.store.dispatch(sidemenuActions.delete({ _id: id }));
	}

	save(): void {
		this.store.dispatch(sidemenuActions.save());
	}

	clearSelected(): void {
		this.store.dispatch(sidemenuActions.clearSelect());
	}
}
