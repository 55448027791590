import { gql } from 'apollo-angular';

import { Layer } from '@yuno/api/interface';

export const GET_LAYERS_BY_APPID = gql`
	query getLayersByAppId($appId: String!) {
		layers(appId: $appId) {
			_id
			id
			public
			source
			sourceLayer
		}
	}
`;

export const GET_LAYER_BY_ID = gql`
	query getLayerById($_id: String!) {
		selectedLayer(_id: $_id) {
			_id
			id
			order
			public
			after
			before
			minzoom
			maxzoom
			interactive
			ref
			source
			sourceLayer
			type
			filter
			layout
			paint
			editMode {
				canMove
				canRotate
				canScale
			}
		}
	}
`;

export type LayersQuery = {
	layers: Partial<Layer>[];
};

export type SelectLayerQuery = {
	selectedLayer: Partial<Layer>;
};
