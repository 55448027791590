import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { Panorama } from '@yuno/api/interface';

import { panoramasActions } from './panoramas.actions';

export interface PanoramasState {
	loaded: boolean;
	panoramas: Partial<Panorama>[];
	selectedLoaded: boolean;
	selected: Partial<Panorama> | undefined;
	status: Status;
}

export const initialState: PanoramasState = {
	loaded: false,
	panoramas: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(panoramasActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(panoramasActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		panoramas: data,
		status: Status.SUCCESS
	})),
	on(panoramasActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(panoramasActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(panoramasActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(panoramasActions.selectFailure, (state, { error }) => ({ ...state, error })),

	// Select Update
	on(panoramasActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(panoramasActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(panoramasActions.saveSuccess, state => {
		const index = state.panoramas.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const panoramas = [...state.panoramas];
		if (index >= 0) {
			panoramas[index] = { ...panoramas[index], ...state.selected };
		} else if (state.selected) {
			panoramas.push({ ...state.selected });
		}
		// panoramas.sort((a, b) => ((a.id as string) > (b.id as string) ? 1 : -1));

		return {
			...state,
			panoramas: panoramas
		};
	})
);

export const panoramasFeature = createFeature({
	name: 'panoramas',
	reducer,
	extraSelectors: ({ selectSelected, selectPanoramas }) => ({
		getNext: createSelector(selectSelected, selectPanoramas, (selected, data) => {
			if (selected && data && data.length >= 1) {
				const index = data.findIndex(pano => pano?._id === selected?._id);
				if (index === data.length - 1) {
					return data[0]?._id;
				}
				return data[index + 1]?._id;
			} else {
				return null;
			}
		}),
		getPrevious: createSelector(selectSelected, selectPanoramas, (selected, data) => {
			if (selected && data && data.length >= 1) {
				const index = data.findIndex(pano => pano?._id === selected?._id);
				if (index === 0) {
					return data[data.length - 1]?._id;
				}
				return data[index - 1]?._id;
			} else {
				return null;
			}
		})
	})
});
