import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { AppState, appActions, appFeature } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { PrivacyStatementConfig, PrivacyStatementProject } from '@yuno/api/interface';

import { privacyStatementCustomActions } from './privacy-statement.actions';

interface PrivacyStateCustom {
	values: Partial<PrivacyStatementProject>[];
	config: Partial<PrivacyStatementConfig> | undefined;
	selected: Partial<PrivacyStatementProject> | undefined;
	status: Status;
	configStatus: Status;
	statusSelected: Status;
}

const initialState: PrivacyStateCustom = {
	values: [],
	config: undefined,
	selected: undefined,
	status: Status.PENDING,
	configStatus: Status.PENDING,
	statusSelected: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(privacyStatementCustomActions.clear, () => initialState),
	on(privacyStatementCustomActions.clearSelected, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.PENDING
	})),
	on(privacyStatementCustomActions.config, state => ({
		...state,
		configStatus: Status.VALIDATING
	})),
	on(privacyStatementCustomActions.configPending, state => ({
		...state,
		configStatus: Status.PENDING
	})),
	on(privacyStatementCustomActions.configSuccess, (state, action) => ({
		...state,
		config: action.data,
		configStatus: Status.SUCCESS
	})),
	on(privacyStatementCustomActions.configFailure, state => ({
		...state,
		configStatus: Status.FAILED
	})),
	on(privacyStatementCustomActions.values, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(privacyStatementCustomActions.valuesPending, state => ({
		...state,
		status: Status.PENDING
	})),
	on(privacyStatementCustomActions.valuesSuccess, (state, action) => ({
		...state,
		values: action.data,
		status: Status.SUCCESS
	})),
	on(privacyStatementCustomActions.valuesFailure, state => ({
		...state,
		status: Status.FAILED
	})),
	on(privacyStatementCustomActions.patchValue, (state, action) => ({
		...state,
		selected: action.data
	})),

	on(privacyStatementCustomActions.patchConfig, (state, action) => ({
		...state,
		config: action.data
	})),

	on(privacyStatementCustomActions.value, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.VALIDATING
	})),
	on(privacyStatementCustomActions.valueSuccess, (state, action) => ({
		...state,
		selected: action.data,
		statusSelected: Status.SUCCESS
	})),
	on(privacyStatementCustomActions.valueFailure, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.FAILED
	})),
	on(privacyStatementCustomActions.saveValueSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(privacyStatementCustomActions.saveConfigSuccess, (state, action) => ({
		...state,
		config: action.data
	}))
);

export const privacyCustomFeature = createFeature({
	name: 'privacy-statements-custom',
	reducer,
	extraSelectors: () => ({
		appId: createSelector(appFeature.selectAppState, (state: AppState) => state?.appId),
		appLanguage: createSelector(
			appFeature.selectAppState,
			(state: AppState) => state?.config?.language || 'nl'
		),
		privacyUrl: createSelector(appFeature.selectAppState, (state: AppState) => {
			return `privacy/${state?.client?.url?.[0]}/${state?.app?.url?.[0]}/`;
		})
	})
});
