import { gql } from 'apollo-angular';

import {
	MediaNotification,
	MediaVideo,
	TextButton,
	TextCustom,
	TextLink,
	TextList,
	TextNewsItem,
	TextToggle
} from '@yuno/api/interface';

export const GET_TEXTBUTTONS = gql`
	query getButtonsByAppId($appId: String!) {
		buttons(appId: $appId) {
			updatedAt

			_id
			id
			content
			activeContent
			alignBottom
			activeToggle
			align
			events {
				type
				options
			}
			activeEvents {
				type
				options
			}
		}
	}
`;

export type TextButtonsQuery = {
	buttons: Partial<TextButton>[];
};

export const GET_TEXTCUSTOMS = gql`
	query getTextCustomsByAppId($appId: String!) {
		customs(appId: $appId) {
			updatedAt
			_id
			name
			content
			customData
		}
	}
`;

export type TextCustomsQuery = {
	customs: Partial<TextCustom>[];
};

export const GET_TEXTLINKS = gql`
	query getLinksByAppId($appId: String!) {
		links(appId: $appId) {
			updatedAt
			_id
			id
			display
			url
			color
		}
	}
`;

export type TextLinksQuery = {
	links: Partial<TextLink>[];
};

export const GET_TEXTLIST = gql`
	query getListsByAppId($appId: String!) {
		lists(appId: $appId) {
			updatedAt

			_id
			id
			title
			active {
				color
				backgroundColor
			}
			items {
				id
				title
				active
			}
			keepActiveDataset
			disableActiveState
			multiselect
			select
			defaultValue
		}
	}
`;

export type TextListsQuery = {
	lists: Partial<TextList>[];
};

export const GET_TEXT_NEWS_ITEMS = gql`
	query getTextNewsItemsByAppId($appId: String!) {
		textNewsItems(appId: $appId) {
			_id
			id
			bgColor
			textColor
			textHeadingColor
			textLinkColor
			align
			size
			title
			subTitle
			content
			imageSrc
			imageFile {
				_id
				data {
					fileName
					url
				}
			}
			imageLink
			imageZoom
			videoSrc
			videoType
			description
			links {
				color
				page
				title
				type
				text
				url
			}
			updatedAt
		}
	}
`;

export type TextNewsItemQuery = {
	textNewsItems: Partial<TextNewsItem>[];
};

export const GET_TEXTTOGGLES = gql`
	query getTogglesByAppId($appId: String!) {
		toggles(appId: $appId) {
			updatedAt

			_id
			id
			items {
				group
				title
				items {
					public
					title
					type
					layer
					dataset
					color
				}
				type
				public
				layer
				dataset
				color
			}
		}
	}
`;

export type TextTogglesQuery = {
	toggles: Partial<TextToggle>[];
};

export const GET_TEXTIMAGES = gql`
	query getImagesByAppId($appId: String!) {
		images(appId: $appId) {
			updatedAt
			_id
			file {
				_id
				data {
					url
					path
					filename
					fileName
				}
			}
			alt
			description
			url
			zoomable
		}
	}
`;

export const GET_MEDIAVIDEOS = gql`
	query getVideosByAppId($appId: String!) {
		videos(appId: $appId) {
			updatedAt
			_id
			id
			type
			videoId
			description
		}
	}
`;

export type MediaVideosQuery = {
	videos: Partial<MediaVideo>[];
};

export const GET_MEDIANOTIFICATIONS = gql`
	query getNotificationsByAppId($appId: String!) {
		notifications(appId: $appId) {
			updatedAt
			_id
			id
			type
			title
			content
		}
	}
`;

export type MediaNotificationsQuery = {
	notifications: Partial<MediaNotification>[];
};
