import { gql } from 'apollo-angular';

import { Textblock } from '@yuno/api/interface';

export const GET_TEXTBLOCKS = gql`
	query getTextblocksByAppId($appId: String!) {
		textblocks(appId: $appId) {
			updatedAt
			_id
			id
			content
		}
	}
`;

export const GET_TEXTBLOCK_BY_ID = gql`
	query getTextblockById($_id: String!) {
		selectedTextblock(_id: $_id) {
			_id
			id
			content
			createdBy {
				id
			}
			updatedBy {
				id
			}
			createdAt
			updatedAt
		}
	}
`;

export type TextblocksQuery = {
	textblocks: Partial<Textblock>[];
};
export type TextblockQuery = {
	selectedTextblock: Partial<Textblock>;
};
