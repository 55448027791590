import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';


@Component({
	selector: 'yuno-language-icons',
	template: `@if (language) {
		<div
			class="absolute right-4 z-10 h-6 w-6 overflow-hidden rounded-full border border-yuno-blue-gray-200 opacity-80"
			[ngClass]="{ 'top-[38px]': padding, 'top-4': !padding }">
			@switch (language) {
				@case ('nl') {
					<svg viewBox="0 0 512 512" class="h-6 w-6">
						<use href="#country-nl" />
					</svg>
				}
				@case ('nl-BE') {
					<svg viewBox="0 0 512 512" class="h-6 w-6">
						<use href="#country-nl-BE" />
					</svg>
				}
				@case ('de') {
					<svg viewBox="0 0 512 512" class="h-6 w-6">
						<use href="#country-de" />
					</svg>
				}
				@case ('en') {
					<svg viewBox="0 0 512 512" class="h-6 w-6">
						<use href="#country-en" />
					</svg>
				}
				@case ('fr') {
					<svg viewBox="0 0 512 512" class="h-6 w-6">
						<use href="#country-fr" />
					</svg>
				}
				@case ('da') {
					<svg viewBox="0 0 512 512" class="h-6 w-6">
						<use href="#country-da" />
					</svg>
				}
				@case ('sv') {
					<svg viewBox="0 0 512 512" class="h-6 w-6">
						<use href="#country-sv" />
					</svg>
				}
			}
		</div>
	}`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass],
	standalone: true
})
export class YunoLanguageIconsComponent {
	@Input() language?: string;
	@Input() padding? = true;
}
