import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
	YunoAdminButtonComponent,
	YunoAdminButtonRoundComponent,
	YunoAdminButtonTableComponent
} from './button.component';
import {
	YunoAdminAddButtonComponent,
	YunoAdminAddMutedButtonComponent,
	YunoAdminCancelButtonComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent
} from './default-buttons.component';
import {
	YunoAdminDeleteAdminButtonComponent,
	YunoAdminDeleteButtonComponent,
	YunoAdminDuplicateButtonComponent,
	YunoAdminEditButtonComponent,
	YunoAdminViewButtonComponent
} from './default-table-buttons.component';

@NgModule({
	imports: [
		CommonModule,
		YunoAdminButtonComponent,
		YunoAdminCancelButtonComponent,
		YunoAdminDeleteButtonComponent,
		YunoAdminDuplicateButtonComponent,
		YunoAdminEditButtonComponent,
		YunoAdminSaveButtonComponent,
		YunoAdminViewButtonComponent,
		YunoAdminAddButtonComponent,
		YunoAdminAddMutedButtonComponent,
		YunoAdminCloseButtonComponent,
		YunoAdminDeleteAdminButtonComponent,
		YunoAdminButtonTableComponent,
		YunoAdminButtonRoundComponent
	],
	exports: [
		YunoAdminButtonComponent,
		YunoAdminCancelButtonComponent,
		YunoAdminDeleteButtonComponent,
		YunoAdminDuplicateButtonComponent,
		YunoAdminEditButtonComponent,
		YunoAdminSaveButtonComponent,
		YunoAdminViewButtonComponent,
		YunoAdminAddButtonComponent,
		YunoAdminAddMutedButtonComponent,
		YunoAdminCloseButtonComponent,
		YunoAdminDeleteAdminButtonComponent,
		YunoAdminButtonTableComponent,
		YunoAdminButtonRoundComponent
	]
})
export class YunoAdminButtonsModule {}
