import { environment } from './environments/environment';

// Somehow this generates errors inside AppModule
export const isProduction =
	environment['yuno-stage'] === 'production' || environment['yuno-stage'] === 'acceptance';

/* Radians to Degrees */
export const toDegrees = (rad: number) => rad * (180 / Math.PI);

/* Degrees to Radians */
export const toRadians = (deg: number) => deg * (Math.PI / 180);

export const precisionRound = (nr: number, precision: number) => {
	const factor = Math.pow(10, precision);
	return Math.round(nr * factor) / factor;
};
