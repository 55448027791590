import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CreateAppDto } from '@yuno/api/dto';

export const createAppActions = createActionGroup({
	source: 'CreateApp',
	events: {
		createApp: props<{ dto: CreateAppDto }>(),
		createAppPending: emptyProps(),
		createAppSuccess: props<{ id: string }>(),
		createAppFailure: props<{ error: Error | Error[] }>()
	}
});
