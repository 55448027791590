@if (service$ | async; as service) {
	@if (service.component) {
		<form [formGroup]="service.component" class="flex flex-col gap-4">
			<section>
				<yuno-forms-title>Legend Editor</yuno-forms-title>
				<yuno-forms-span>You can add a legend via the dropdown below.</yuno-forms-span>
			</section>
			@if (data$ | async; as data) {
				<yuno-forms-select
					(changes)="editService.onSelectLegend($event)"
					formControlName="_id"
					placeholder="Select legend..."
					label="Legend"
					[selectValues]="service.legendValues"
					[display]="service.legendDisplay"></yuno-forms-select>
			}
		</form>
	}
}
