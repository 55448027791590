import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { dashboardFeature } from '@yuno/admin/features/dashboard/data-access/+state/dashboard.state';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';
import { Dashboard } from '@yuno/api/interface';

import { GET_DASHBOARD_BY_APPID, SAVE_DASHBOARD } from '../../utils';
import { dashboardActions } from './dashboard.actions';

export type DashboardQuery = {
	dashboard: Partial<Dashboard>;
};

export type SaveDashboardMutation = {
	saveDashboard: Partial<Dashboard>;
};

export const loadDashboard = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(dashboardActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.query<DashboardQuery>({
						query: GET_DASHBOARD_BY_APPID,
						variables: {
							appId: appId,
							key: data.key
						}
					})
					.pipe(
						map(data => {
							if (!data.data.dashboard) {
								throw new Error('no dashboard');
							}
							return dashboardActions.loadSuccess({
								data: data.data.dashboard
							});
						}),
						take(1),
						catchError(error => of(dashboardActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveDashboard = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(dashboardActions.save),
			withLatestFrom(
				store.pipe(select(dashboardFeature.selectDashboard)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveDashboardMutation>({
						mutation: SAVE_DASHBOARD,
						variables: {
							dashboard: selected,
							appId: appId
						}
					})
					.pipe(
						map(dataset => {
							if (!dataset.data?.saveDashboard) {
								throw new Error('error saving Dashboard to the database');
							}
							message.sendToast(`Dashboard successfully saved!`, 'success');
							return dashboardActions.saveSuccess();
						}),
						take(1),
						catchError(error => {
							if (error && error.length >= 1) {
								message.sendToast(`Error saving dashboard!`, 'error');
							}
							return of(dashboardActions.saveFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);
