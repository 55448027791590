import { createFeature, createReducer, on } from '@ngrx/store';

import { CmsConfig } from '@yuno/api/interface';

import { appDataActions } from './app-data.actions';

export interface AppDataState {
	cmsConfig: CmsConfig;
}

export const initialState: AppDataState = {
	cmsConfig: {
		planning: false,
		deliverables: false
	}
};

const reducer = createReducer(
	initialState,
	on(appDataActions.productionSuccess, (state, action) => {
		return {
			...state,
			cmsConfig: action.data
		};
	})
);

export const AppDataFeature = createFeature({
	name: 'appData',
	reducer
});
