<ng-container [formGroup]="form">
	<div>
		@if (item.label) {
			<label class="yuno-form">
				<span
					class="select-none"
					[innerHtml]="
						item.label
							| languageSelect: language
							| safeHtml: item.validators?.required || item.required
					">
				</span>
			</label>
		}

		<ul>
			@for (radio of item.radioValues; track radio) {
				<li class="yuno-form-radio">
					<label class="yuno-form">
						<input
							type="radio"
							[value]="radio"
							[name]="item.key"
							[formControl]="control" />
						<span class="select-none">
							{{ radio }}
						</span>
					</label>
				</li>
			}
		</ul>
	</div>
</ng-container>
