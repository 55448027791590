@if (service$ | async; as service) {
	@if (service.component) {
		@if (editService.language$ | async; as language) {
			<form [formGroup]="service.component" class="flex flex-col gap-4">
				<section>
					<yuno-forms-title>Textblock</yuno-forms-title>
					<yuno-forms-span>You can edit the selected textblock below.</yuno-forms-span>
				</section>
				<yuno-forms-text
					class="flex-1"
					formControlName="id"
					placeholder="Textblock name"
					label="Title"></yuno-forms-text>
				<section formGroupName="content">
					@for (lang of editService.languages; track lang) {
						@if (lang === language) {
							<yuno-forms-html-image-library
								[formControlName]="lang"
								[language]="lang">
							</yuno-forms-html-image-library>
						}
					}
				</section>
			</form>
		}
	}
}
