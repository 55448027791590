<ng-container [formGroup]="form">
	<div class="flex" [ngClass]="{ 'mt-2': item.options?.labelPos === 'side' }">
		<label
			class="yuno-form toggle h-full"
			[ngClass]="{
				'flex-col': item.options?.labelPos !== 'side',
				'mt-2 flex-row-reverse gap-2': item.options?.labelPos === 'side',
				'mt-2': !(item.label | languageSelect: language)
			}">
			@if (item.label) {
				<span
					class="select-none"
					[ngClass]="{ 'mt-2': item.options?.labelPos === 'side' }"
					[innerHtml]="
						item.label
							| languageSelect: language
							| safeHtml: item.validators?.required || item.required
					">
				</span>
			}
			<input
				class="toggle my-auto block"
				type="checkbox"
				[name]="item.key"
				[formControl]="control" />
		</label>
		<div class="flex-1"></div>
	</div>
</ng-container>
