import { gql } from 'apollo-angular';

import { App, AppUser, Client, Config } from '@yuno/api/interface';

export const GET_CLIENT_APP_AND_CONFIG = gql`
	query getAppWithConfig($appId: String!) {
		clientByAppId(appId: $appId) {
			_id
			id
			url
		}
		appById(appId: $appId) {
			_id
			id
			url
			client {
				_id
				id
				url
			}
			trackingId
			public
		}
		configByAppId(appId: $appId) {
			_id
			title
			customTitle
			path
			language
			languages
			epsg
			interface {
				mapOnly
				controlZoom
				navbarType
				embedType

				fullscreenButton
				fullscreenPopup
				embedFullscreenPopup
				buttonStyle

				help {
					mobile
					desktop
				}
			}

			help {
				popup {
					display
					icon
				}
				textfields {
					mobile
					desktop
				}
				target
				active
			}
			colors {
				navbar {
					background
					font
					active
					activeFont
					underline
				}
				embedNavbar {
					background
					font
					active
					activeFont
					underline
				}
				states {
					background
					font
				}
				panoramas
				panoramasClusterCount
				additional
				button
				buttonFont
				textHeading
				textLink
				locationMarker
				measure
			}
			fontHeader
			fontParagraph
		}
	}
`;

export type AppQuery = {
	clientByAppId: Partial<Client>;
	appById: Partial<App>;
	configByAppId: Partial<Config>;
	appUsers: Partial<AppUser>[];
};
