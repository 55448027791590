@if (data$ | async; as data) {
	@if (form) {
		<form [formGroup]="form">
			<yuno-forms-span
				>Add an icon based on an .svg url. You can also select a svg from the spritesheet.
				This method will override the Icon options, so make sure to clear all fields when
				disbanding this option.</yuno-forms-span
			>
			<yuno-forms-text
				formControlName="svg"
				placeholder="svg url..."
				label="SVG url"></yuno-forms-text>
			@if (data.appId && data.clientId) {
				<div class="mt-2 rounded-md border-2 border-gray-300 bg-gray-200 p-4">
					<yuno-admin-spritesheet-manager
						[appId]="data.appId"
						[clientId]="data.clientId"
						[disableCopy]="true"
						(selectIcon)="setSvg($event)"></yuno-admin-spritesheet-manager>
				</div>
			}
		</form>
	}
}
