@if (data$ | async; as data) {
	<div
		class="z-20 flex h-full w-full flex-col overflow-hidden"
		[ngClass]="{ hidden: !showPano || !data?.panorama?.url?.pano }">
		<div class="absolute bottom-16 right-0 z-100">
			<div class="flex gap-2 p-4">
				<button yuno-admin-button color="primary" (click)="changePano(data?.prev)">
					Previous
				</button>
				<button yuno-admin-button color="secondary" (click)="changePano(data?.next)">
					Next
				</button>
			</div>
		</div>
		<div class="relative w-full flex-1 bg-slate-900">
			@if (xml && data.panorama) {
				<yuno-krpano
					[xml]="xml"
					color="#45940e"
					[panoramaData]="data.panorama"
					[calibrate]="false"
					[coordinates]="data.panorama.location?.coordinates"
					[disableDefaultIdleEvent]="true"
					[zoom]="0 * -1"
					(krpano)="setKrpanoInstance($event)"
					(onviewchanged)="onSetRotation($event)"></yuno-krpano>
			}
		</div>
		<div class="flex w-full flex-row justify-start bg-white p-2">
			<div class="flex flex-1 flex-col">
				<div class="flex flex-row">
					<span class="mr-2 font-semibold">Degrees:</span>
					<div class="mr-2 select-text">
						<strong>fov</strong>: {{ currentView && currentView.fov | number }}
					</div>
					<div class="mr-2 select-text">
						<strong>pitch</strong>: {{ currentView && currentView.vlookat | number }}
					</div>
					<div class="mr-2 select-text">
						<strong>yaw</strong>: {{ currentView && currentView.hlookat | number }}
					</div>
				</div>
				<div class="flex flex-row">
					<span class="mr-2 font-semibold">Radians:</span>
					<!-- toRadians -->
					<div class="mr-2 select-text">
						<strong>fov</strong>: {{ currentView && currentView.fov | toRadians }}
					</div>
					<div class="mr-2 select-text">
						<strong>pitch</strong>: {{ currentView && currentView.vlookat | toRadians }}
					</div>
					<div class="mr-2 select-text">
						<strong>yaw</strong>: {{ currentView && currentView.hlookat | toRadians }}
					</div>
				</div>
			</div>
			<div class="flex flex-row items-center justify-end bg-white">
				@if (data.states && data.states.length >= 1) {
					<select class="mr-4" [value]="data.activeState" (change)="changeState($event)">
						@for (state of data.states; track state) {
							<option [value]="state.state">
								{{ state.state }}
							</option>
						}
					</select>
				}
				<div class="ml-6">
					@if (!hideBtn) {
						<button yuno-admin-button color="primary" (click)="onApplyView()">
							Apply CurrentView
						</button>
					}
				</div>
			</div>
		</div>
	</div>
}
