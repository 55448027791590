import { gql } from 'apollo-angular';

import { Marker } from '@yuno/api/interface';

export const SAVE_PLACEMARKER = gql`
	mutation savePlaceMarker(
		$marker: MarkerInput!
		$categoryName: String!
		$appId: String!
		$fences: JSON!
	) {
		savePlaceMarker(
			marker: $marker
			categoryName: $categoryName
			appId: $appId
			fences: $fences
		) {
			_id
			public
			category
			style
			properties {
				alignment
				display
				endDate
				id
				location
				maxZoom
				minZoom
				number
				rotation
				startDate
				status
				theme
				title
				toggleTime
				type
			}
			customProperties
			geometry {
				type
				coordinates
			}
			events {
				onClick {
					type
					options
				}
				onMouseMove {
					type
					options
				}
			}
		}
	}
`;

export const SAVE_MARKER = gql`
	mutation saveMarker($marker: MarkerInput!, $appId: String!, $categoryName: String) {
		saveMarker(marker: $marker, categoryName: $categoryName, appId: $appId) {
			_id
			style
			properties {
				alignment
				display
				endDate
				id
				location
				maxZoom
				minZoom
				number
				rotation
				startDate
				status
				theme
				title
				toggleTime
				type
			}
			geometry {
				type
				coordinates
			}
		}
	}
`;

export const DUPLICATE_MARKER = gql`
	mutation duplicateMarker($_id: String!, $appId: String!) {
		duplicateMarker(_id: $_id, appId: $appId) {
			_id
			style
			properties {
				id
				maxZoom
				minZoom
			}
			geometry {
				type
				coordinates
			}
		}
	}
`;

export type SaveMarkerMutation = {
	saveMarker: Partial<Marker>;
};

export type DuplicateMarkerMutation = {
	duplicateMarker: Partial<Marker>;
};

export type SavePlaceMarkerMutation = {
	savePlaceMarker: Partial<Marker>;
};
