import { gql } from 'apollo-angular';

import { Participated } from '@yuno/api/interface';

const participatedItemSelection = `_id
			appId
			participateId
			state
			stateUpdatedBy {
				displayName
			}
			data
			answer {
				content
			}
			status {
				new {
					...Status
				}
				onhold {
					...Status
				}
				answered {
					...Status
				}
				published {
					...Status
				}
				archived {
					...Status
				}
			}`;

const participatedItemStatusFragment = `fragment Status on ParticipatedStatusItem {
		modified
	}`;

export const GET_PARTICIPATION_PARTICIPATIONS = gql`
	query getParticipationPosts($id: String!, $appId: String!) {
		getParticipationPosts(id: $id, appId: $appId) { ${participatedItemSelection} }
	}
	${participatedItemStatusFragment}
`;

export const GET_PARTICIPATION_PARTICIPATION = gql`
	query getParticipationPostAdmin($appId: String!, $id: String!) {
		getParticipationPostAdmin(appId: $appId, id: $id) { ${participatedItemSelection} }
	}
	${participatedItemStatusFragment}
`;

export const PARTICIPATION_ONHOLD = gql`
	mutation setParticipationOnHold($_id: String!, $appId: String!) {
		setParticipationOnHold(_id: $_id, appId: $appId) { ${participatedItemSelection} }
	}
	${participatedItemStatusFragment}
`;
export const PARTICIPATION_SEND_ANSWER = gql`
	mutation setParticipationAnswered($_id: String!, $appId: String!) {
		setParticipationAnswered(_id: $_id, appId: $appId) { ${participatedItemSelection} }
	}
	${participatedItemStatusFragment}
`;
export const PARTICIPATION_PUBLISHED = gql`
	mutation setParticipationPublished($_id: String!, $appId: String!) {
		setParticipationPublished(_id: $_id, appId: $appId) { ${participatedItemSelection} }
	}
	${participatedItemStatusFragment}
`;
export const PARTICIPATION_ARCHIVED = gql`
	mutation setParticipationArchived($_id: String!, $appId: String!) {
		setParticipationArchived(_id: $_id, appId: $appId) { ${participatedItemSelection} }
	}
	${participatedItemStatusFragment}
`;

export const PARTICIPATION_UPDATE_ANSWER = gql`
	mutation setParticipationUpdateAnswer($_id: String!, $appId: String!, $content: String!) {
		setParticipationUpdateAnswer(_id: $_id, appId: $appId, content: $content){ ${participatedItemSelection} }
	}
	${participatedItemStatusFragment}
`;

export const PARTICIPATION_UPDATE_REACTION = gql`
	mutation setParticipationUpdateReaction($_id: String!, $appId: String!, $content: String!) {
		setParticipationUpdateReaction(_id: $_id, appId: $appId, content: $content){ ${participatedItemSelection} }
	}
	${participatedItemStatusFragment}
`;

export type ParticipatedQuery = {
	getParticipationPosts: Partial<Participated>[];
};
export type ParticipatedSelectQuery = {
	getParticipationPostAdmin: Participated;
};

export type ParticipatedOnHold = {
	setParticipationOnHold: Participated;
};
export type ParticipatedAnswered = {
	setParticipationAnswered: Participated;
};
export type ParticipatedPublished = {
	setParticipationPublished: Participated;
};
export type ParticipatedArchived = {
	setParticipationArchived: Participated;
};

export type ParticipatedUpdateAnswer = {
	setParticipationUpdateAnswer: Participated;
};
export type ParticipatedUpdateReaction = {
	setParticipationUpdateReaction: Participated;
};
