import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { colspan } from '../../../helpers';
import { JsonFormControl } from '../../../json-forms.models';
import { ControlsSelectorComponent } from '../../controls/controls.selector.component';

@Component({
	selector: 'yuno-json-forms-types-control',
	templateUrl: './control.component.html',
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ControlsSelectorComponent]
})
export class ControlComponent {
	_control: UntypedFormControl | undefined;

	@Input() item: JsonFormControl;

	@Input()
	set control(control: UntypedFormControl | undefined) {
		this._control = control;
		this.className = this.colspan();
	}
	get control(): UntypedFormControl | undefined {
		return this._control;
	}

	@Input() form: UntypedFormGroup;

	@HostBinding('className') className: string;

	colspan(): string {
		return colspan(this.item.colspan);
	}
}
