@if (service$ | async; as service) {
	@if (service.form) {
		<form [formGroup]="service.form" class="flex flex-col gap-4">
			<ng-container formArrayName="components">
				<section>
					<yuno-forms-title>Container Editor</yuno-forms-title>
					<yuno-forms-span>You can edit the selected container below.</yuno-forms-span>
				</section>
				@if (service.componentIndex !== undefined) {
					<ng-container [formGroupName]="service.componentIndex">
						<yuno-forms-text
							class="flex-1"
							formControlName="container"
							placeholder="Container name"
							label="Container name"></yuno-forms-text>
					</ng-container>
				}
			</ng-container>
		</form>
	}
}
