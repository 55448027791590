import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { RippleDirectiveModule } from '../../../../../../../project-atlas/ui/src/lib/ripple/ripple.directive';
import { colspan } from '../../../helpers';
import { JsonButtonForm } from '../../../json-forms.models';
import { ActionsService } from '../../../services/actions.service';

@Component({
	selector: 'yuno-json-forms-types-button',
	templateUrl: './button.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, RippleDirectiveModule]
})
export class ButtonComponent {
	_item: JsonButtonForm;

	@Input()
	set item(item: JsonButtonForm) {
		this._item = item;
		this.className = this.colspan();
	}
	get item(): JsonButtonForm {
		return this._item;
	}

	@Input() removeMarginTop = false;
	@Input() disableAction = false;
	@Input() add = false;

	@HostBinding('className') className: string;

	constructor(private actionService: ActionsService) {}

	colspan(): string {
		return colspan(this.item.colspan);
	}

	sendAction(): void {
		if (this.disableAction || !this.item.options?.events) {
			return;
		}

		for (const event of this.item.options.events) {
			this.actionService.sendAction(event);
		}
	}
}
