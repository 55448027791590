import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { FeatureCollection, Position } from 'geojson';
import { isEqual } from 'lodash';
import { LngLatBoundsLike, Map } from 'maplibre-gl';
import { combineLatest, distinctUntilChanged, map, startWith, tap } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { AppFacade } from '@yuno/admin/features/apps';
import { MapFacade } from '@yuno/admin/features/map';
import { ThemeFacade } from '@yuno/admin/features/themes';
import { Theme } from '@yuno/api/interface';

import { DefaultMapBounds, DefaultMapBoundsLngLat, DefaultMapStyle } from '../theme-editor.service';

@Component({
	standalone: true,
	imports: [NgxMapLibreGLModule, AsyncPipe],
	selector: 'yuno-admin-map-viewer',
	templateUrl: './map-viewer.component.html',
	styleUrls: ['./map-viewer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapViewerComponent {
	readonly environment = inject(ENVIRONMENT);
	private readonly appFacade = inject(AppFacade);
	private readonly mapFacade = inject(MapFacade);
	private readonly themeFacade = inject(ThemeFacade);

	themeData: Partial<Theme>;

	data$ = combineLatest({
		theme: this.themeFacade.selectedTheme$.pipe(
			tap(data => {
				if (data) {
					this.themeData = data as Theme;

					if (data.mapStyles && data.mapStyles.length >= 1) {
						if (data.mapStyles[0]?.style && data.mapStyles[0]?.style._id) {
							this.mapFacade.getStyle(data.mapStyles[0]?.style._id);
						}
					}
				}
			}),
			distinctUntilChanged((prev, curr) => {
				if (!prev || !curr) {
					return false;
				}

				return prev?.view?.style?._id === curr?.view?.style?._id;
			})
		),
		bounds: this.themeFacade.selectedTheme$.pipe(
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					return false;
				}

				return isEqual(prev?.view?.bounds, curr?.view?.bounds);
			}),
			map(data => {
				if (!data?.view?.bounds) {
					return {
						polygon: this.createPolygonFeature([DefaultMapBounds]),
						fitBounds: DefaultMapBoundsLngLat
					};
				}

				const bounds = this.getMapBounds(data.view.bounds);
				const polygon: Position[][] = [
					[
						bounds[1],
						[bounds[0][0], bounds[1][1]],
						bounds[0],
						[bounds[1][0], bounds[0][1]],
						bounds[1]
					]
				];

				return {
					polygon: this.createPolygonFeature(polygon),
					fitBounds: bounds as LngLatBoundsLike
				};
			})
		),
		appId: this.appFacade.appId$.pipe(startWith(null)),
		mapStyle: this.mapFacade.style$.pipe(
			startWith(undefined),
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					// when no style is selected, load the default mapstyle
					this.mapFacade.getStyle(DefaultMapStyle);
					return false;
				}

				const prevMetadata: { [key: string]: unknown } = prev?.metadata as {
					[key: string]: unknown;
				};
				const currMetadata: { [key: string]: unknown } = curr?.metadata as {
					[key: string]: unknown;
				};

				if (!prevMetadata || !currMetadata) {
					return false;
				}

				return isEqual(prev?.metadata, curr?.metadata);
			})
		)
	});

	getMapBounds(bounds?: [[number, number], [number, number]]): [number, number][] {
		if (bounds) {
			return bounds as [number, number][];
		} else {
			return DefaultMapBoundsLngLat as [number, number][];
		}
	}

	createPolygonFeature(polygon: Position[][]): FeatureCollection {
		return {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					properties: {},
					geometry: {
						type: 'Polygon',
						coordinates: polygon
					}
				}
			]
		};
	}

	mapOnMissingImage(image: { id: string; target?: Map }): void {
		if (!image.target) {
			return;
		}

		const map = image.target;
		const id = image.id;
		const url = `${this.environment['yuno-cdn']}/sprites/public/sdf/sprites/${image.id}.png`;

		if (!map.hasImage(id)) {
			map.loadImage(url).then(img => {
				if (!img) {
					throw new Error(`Image could not be loaded for, ${url}`);
				}

				// the Map fires the same missing image event multiple times
				// then throws an error: "An image named "{{id}}" already exists."
				if (!map.hasImage(id)) {
					map.addImage(id, img.data, {
						sdf: true,
						pixelRatio: 1
					});
				}
			});
		}
	}
}
