import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { QueryParamsHandling, RouterLink, RouterLinkActive } from '@angular/router';

@Component({
	selector: 'yuno-admin-ui-selector',
	standalone: true,
	imports: [],
	template: `
		<div
			class="pointer-events-auto inline-flex flex-row items-center justify-center gap-1 truncate rounded-full bg-yuno-blue-gray-100 p-1 text-sm outline outline-1  outline-yuno-blue-gray-400">
			<span class="px-2 italic empty:hidden">
				<ng-content select="[description]"></ng-content>
			</span>
			<ng-content></ng-content>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminUiSelectorComponent {}

@Component({
	selector: 'yuno-admin-ui-selector-button',
	standalone: true,
	imports: [],
	template: `
		<button
			class="pointer-events-auto inline-flex cursor-pointer flex-row  items-center justify-center truncate rounded-full px-4 py-2 text-sm outline outline-1 transition-colors duration-300">
			@if (add) {
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="mr-2 inline-block h-4 w-4"
					viewBox="0 0 16 16">
					<circle cx="8" cy="8" r="8" style="fill: #3a4558" />
					<path
						d="M12.3 6.9H9.1V3.7c0-.4-.3-.7-.7-.7h-.8c-.4 0-.7.3-.7.7v3.2H3.7c-.4 0-.7.3-.7.7v.7c0 .4.3.7.7.7h3.2v3.2c0 .4.3.7.7.7h.7c.4 0 .7-.3.7-.7V9.1h3.2c.4 0 .7-.3.7-.7v-.8c.1-.4-.2-.7-.6-.7z"
						style="fill: #e9ecee" />
				</svg>
			}
			<ng-content></ng-content>
		</button>
	`,
	styleUrls: ['./selector.button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminUiSelectorButtonComponent {
	_color?: 'primary' | undefined;
	@HostBinding('class') @Input() set color(color: 'primary' | undefined) {
		this._color = color;
	}

	get color(): 'primary' | undefined {
		return this._color;
	}

	@Input() add = false;
}

@Component({
	selector: 'yuno-admin-ui-selector-item',
	standalone: true,
	imports: [RouterLink, RouterLinkActive],
	template: `
		<a
			class="pointer-events-auto inline-block rounded-full px-4 py-1 text-yuno-blue-gray-600 transition-colors duration-300 hover:bg-yuno-blue-gray-300  hover:text-yuno-blue-gray-600"
			[routerLink]="link"
			[routerLinkActive]="'bg-yuno-portal-blue !text-white !pointer-events-none'"
			[queryParamsHandling]="queryParamsHandling">
			<ng-content></ng-content>
		</a>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminUiSelectorItemComponent {
	@Input({ required: true }) link: string | string[];
	@Input() queryParamsHandling: QueryParamsHandling = 'merge';
}

@Component({
	selector: 'yuno-admin-ui-selector-item-button',
	standalone: true,
	imports: [RouterLink, RouterLinkActive, NgClass],
	template: `
		<button
			class="pointer-events-auto inline-flex flex-row items-center justify-center gap-2 truncate rounded-full px-4 py-1 text-yuno-blue-gray-600 transition-colors duration-300 hover:bg-yuno-blue-gray-300  hover:text-yuno-blue-gray-600"
			[ngClass]="{ 'pointer-events-none !bg-yuno-portal-blue !text-white': active }">
			<ng-content></ng-content>
		</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminUiSelectorItemButtonComponent {
	@Input() active = false;
}
