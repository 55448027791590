import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { imagesFeature } from '@yuno/admin/features/textfield-pages';
import {
	GET_IMAGEFILES,
	GET_IMAGES,
	GET_IMAGE_BY_ID,
	ImageFilesQuery,
	ImageQuery,
	ImagesQuery
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/image/getImages';
import {
	SAVE_IMAGE,
	SaveImageMutation
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/image/saveImages';
import { GraphQLService } from '@yuno/angular-graphql';

import { imagesActions } from './image.actions';

export const loadImages = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(imagesActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<ImagesQuery>({
						query: GET_IMAGES,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.images) {
								throw new Error('no Images found');
							}
							return imagesActions.loadSuccess({
								data: data.data.images
							});
						}),
						take(1),
						catchError(error => of(imagesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectImages = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(imagesActions.select),
			exhaustMap(image =>
				graphql
					.query<ImageQuery>({
						query: GET_IMAGE_BY_ID,
						variables: {
							_id: image._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedImage) {
								throw new Error('no Image with that id found');
							}
							return imagesActions.selectSuccess({
								data: data.data.selectedImage
							});
						}),
						take(1),
						catchError(error => of(imagesActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveImage = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(imagesActions.save),
			withLatestFrom(
				store.pipe(select(imagesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveImageMutation>({
						mutation: SAVE_IMAGE,
						variables: {
							Image: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveImage) {
								throw new Error('error saving Image to the database');
							}

							return imagesActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(imagesActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadImageFiles = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(imagesActions.loadImageFiles),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<ImageFilesQuery>({
						query: GET_IMAGEFILES,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.imageFiles) {
								throw new Error('no Images found');
							}
							return imagesActions.loadImageFilesSuccess({
								data: data.data.imageFiles
							});
						}),
						take(1),
						catchError(error => of(imagesActions.loadImageFilesFailure({ error })))
					)
			)
		),
	{ functional: true }
);
