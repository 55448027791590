@switch (key) {
	@case ('text') {
		<yuno-forms-text
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('email') {
		<yuno-forms-email
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('password') {
		<yuno-forms-password
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('tel') {
		<yuno-forms-telephone
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('url') {
		<!-- <yuno]-forms-url formControlName="key"   /> -->
	}
	@case ('number') {
		<yuno-forms-number
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('range') {
		<yuno-forms-range
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('select') {
		<yuno-forms-select
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label"
			[display]="display || selectValues"
			[selectValues]="selectValues" />
	}
	@case ('textarea') {
		<yuno-forms-textarea
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('checkbox') {
		<yuno-forms-checkbox
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('radio') {
		<yuno-forms-radio
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('toggle') {
		<yuno-forms-toggle
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('file') {
		<yuno-forms-file
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('image') {
		<yuno-forms-file
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label"
			[accept]="'image/*'" />
	}
	@case ('color') {
		<yuno-forms-color
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@case ('html') {
		<yuno-forms-html
			[formControl]="getFormControl()"
			[placeholder]="placeholder"
			[label]="label" />
	}
	@default {}
}
