// eslint-disable-next-line @angular-eslint/component-selector

/* eslint-disable @angular-eslint/component-selector */

/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { DefaultButtonsComponent, YunoAdminButtonTableComponent } from './button.component';

/**
 * minimal AppRole = EDITOR
 */
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-edit-button]',
	standalone: true,
	imports: [YunoAdminButtonTableComponent],
	template: `
		<span yuno-admin-button-table [disabled]="disabled" color="primary">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Edit</span>
		</span>
	`,
	styleUrls: ['./button.table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminEditButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.minimalAppRole(this.userRoles.EDITOR);
	}
}

/**
 * minimal AppRole = READER
 * maximum AppRole = READER
 */
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-view-button]',
	standalone: true,
	imports: [YunoAdminButtonTableComponent],
	template: `
		<span yuno-admin-button-table [disabled]="disabled" color="secondary">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">View</span>
		</span>
	`,
	styleUrls: ['./button.table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminViewButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.maximumAppRole(this.userRoles.READER);
	}
}

/**
 * minimal AppRole = EDITOR
 */
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-delete-button]',
	standalone: true,
	imports: [YunoAdminButtonTableComponent],
	template: `
		<span yuno-admin-button-table [disabled]="disabled" color="danger">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Delete</span>
		</span>
	`,
	styleUrls: ['./button.table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminDeleteButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.minimalAppRole(this.userRoles.EDITOR);
	}
}

/**
 * minimal AppRole = ADMIN
 */
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-delete-admin-button]',
	standalone: true,
	imports: [YunoAdminButtonTableComponent],
	template: `
		<span yuno-admin-button-table [disabled]="disabled" color="danger">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Delete</span>
		</span>
	`,
	styleUrls: ['./button.table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminDeleteAdminButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.minimalAppRole(this.userRoles.ADMIN);
	}
}

/**
 * minimal AppRole = EDITOR
 */
@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-duplicate-button]',
	standalone: true,
	imports: [YunoAdminButtonTableComponent],
	template: `
		<span yuno-admin-button-table [disabled]="disabled" color="secondary">
			<span class="peer empty:hidden">
				<ng-content></ng-content>
			</span>
			<!-- if no ng-content -->
			<span class="hidden peer-empty:!inline-block">Duplicate</span>
		</span>
	`,
	styleUrls: ['./button.table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminDuplicateButtonComponent extends DefaultButtonsComponent implements OnInit {
	ngOnInit(): void {
		this.hidden = !this.minimalAppRole(this.userRoles.EDITOR);
	}
}
