import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	GET_TEXTCUSTOMS,
	TextCustomsQuery
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/getTextfieldComponents';
import { GraphQLService } from '@yuno/angular-graphql';

import { textCustomsActions } from './text-custom.actions';

export const loadTextCustoms = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textCustomsActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<TextCustomsQuery>({
						query: GET_TEXTCUSTOMS,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.customs) {
								throw new Error('no text-customs found');
							}
							return textCustomsActions.loadSuccess({
								data: data.data.customs
							});
						}),
						take(1),
						catchError(error => of(textCustomsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);
