import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { customTilesetFeature } from '@yuno/admin/features/tilesets/data-access';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	CustomTilesetsQuery,
	DELETE_TILESET,
	DeleteCustomTilesetMutation,
	GET_TILESETS_BY_APPID,
	GET_TILESET_BY_ID,
	SAVE_TILESET,
	SaveCustomTilesetMutation,
	SelectCustomTilesetQuery
} from '../../utils';
import { customTilesetActions } from './tilesets-custom.actions';

export const loadTileset = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(customTilesetActions.load, customTilesetActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<CustomTilesetsQuery>({
						query: GET_TILESETS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(tilesets => {
							if (!tilesets.data.customTilesets) {
								throw new Error('no Tilesets found');
							}
							return customTilesetActions.loadSuccess({
								data: tilesets.data.customTilesets
							});
						}),
						take(1),
						catchError(error => of(customTilesetActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectTileset = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(customTilesetActions.select),
			exhaustMap(tileset =>
				graphql
					.query<SelectCustomTilesetQuery>({
						query: GET_TILESET_BY_ID,
						variables: {
							_id: tileset._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedCustomTileset) {
								message.sendToast(`Error selecting tileset!`, 'error');
								throw new Error('no Tileset with that id found');
							}

							return customTilesetActions.selectSuccess({
								data: data.data.selectedCustomTileset
							});
						}),
						take(1),
						catchError(error => of(customTilesetActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelected = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(customTilesetActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating tileset!`, 'error');
					throw new Error('no Tileset found');
				}
				return customTilesetActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(customTilesetActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveTileset = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(customTilesetActions.save),
			withLatestFrom(
				store.pipe(select(customTilesetFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveCustomTilesetMutation>({
						mutation: SAVE_TILESET,
						variables: {
							tileset: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveCustomTileset) {
								throw new Error('error saving tileset to the database');
							}
							const _id = data.data?.saveCustomTileset._id || '';
							return customTilesetActions.saveSuccess({ _id });
						}),
						take(1),
						catchError(error => of(customTilesetActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(customTilesetActions.saveSuccess),
			tap(() => {
				message.sendToast(`Tileset successfully saved!`, 'success');
			})
		),
	{ functional: true, dispatch: false }
);
export const saveFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(customTilesetActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving tileset!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);

export const deleteTileset = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(customTilesetActions.delete),
			switchMap(tileset =>
				graphql
					.mutate<DeleteCustomTilesetMutation>({
						mutation: DELETE_TILESET,
						variables: {
							_id: tileset._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteCustomTileset) {
								throw new Error('error deleting Custom Tileset from the database');
							}
							store.dispatch(customTilesetActions.reload());
							return customTilesetActions.deleteSuccess({
								_id: data.data.deleteCustomTileset
							});
						}),
						take(1),
						catchError(error => of(customTilesetActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(customTilesetActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Custom Tileset successfully deleted!`, 'success');
			})
		),
	{ functional: true, dispatch: false }
);
export const deleteFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(customTilesetActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting custom tileset!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);
