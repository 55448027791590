import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { State } from '@yuno/api/interface';

import { statesActions } from './states.actions';
import { statesFeature } from './states.state';

@Injectable()
export class StatesFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(statesFeature.selectLoaded));
	states$ = this.store.pipe(select(statesFeature.selectStates));
	selectedState$ = this.store.pipe(select(statesFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(statesFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(statesFeature.selectStatus));

	get() {
		this.store.dispatch(statesActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(statesActions.select({ _id }));
	}

	updateSelect(data: Partial<State>) {
		this.store.dispatch(statesActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(statesActions.clearSelect());
	}

	save() {
		this.store.dispatch(statesActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(statesActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(statesActions.delete({ _id }));
	}
}
