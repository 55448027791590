import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { FencesFacade } from '../../data-access';

export const FencesResolver: ResolveFn<boolean> = () => {
	const fences = inject(FencesFacade);
	fences.get();

	return true;
};
