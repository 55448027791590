import { Inject, Injectable, InjectionToken, Optional, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

export const COOKIE_DOMAIN = new InjectionToken('CookieDomain');
export const COOKIE_SEEN = 'yuno-cms-cookie-message';
export const COOKIE_NEW_CMS_MESSAGE = 'yuno-cms-new-cms-message';

@Injectable({
	providedIn: 'root'
})
export class AdminCookiesService {
	private readonly service = inject(CookieService);

	constructor(
		@Optional() @Inject(COOKIE_DOMAIN) private readonly cookie_domain: string | undefined
	) {}

	// Get Cookie Stored in the browser
	getCookie(name: string): string {
		return this.service.get(name);
	}

	// Set cookie in the browser
	setCookie(name: string, value: string, expires?: number): void {
		const time = this.cookieExpireTime(expires || 365);
		this.service.set(name, value, time, '/', this.cookie_domain);
	}

	// Generate Cookie Expiring Time
	cookieExpireTime(days: number): Date {
		const date = new Date();

		// Default at 365 days.
		days = days || 365;

		// Get unix milliseconds at current time plus number of days * milliseconds
		date.setTime(+date + days * 86400000); // 24 * 60 * 60 * 1000

		return date;
	}
}
