import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject
} from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsCodeEditorComponent, YunoFormsModule } from '@yuno/angular/forms';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-zoom-to-polygon',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		YunoFormsCodeEditorComponent,
		AnnotationComponent
	],
	templateUrl: './zoom-to-polygon.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomToPolygonComponent
	extends EventsDefaultFormItemComponent
	implements AfterViewInit
{
	private readonly cdr = inject(ChangeDetectorRef);
	polygonValue: string;
	polygonSavedValue: string;
	invalidValue = false;

	ngAfterViewInit(): void {
		this.polygonValue = this.polygonSavedValue =
			JSON.stringify(this.form?.controls.options.get('polygon')?.value, null, 4) || '[]';

		this.cdr.detectChanges();
	}

	polyChanges(event: string): void {
		try {
			const value: [number, number][] = JSON.parse(event);

			this.invalidValue = false;
			const poly = this.form.controls.options.get('polygon');
			const polyControl = this.form.controls.options.controls.polygon;

			if (!poly || !polyControl) {
				return;
			}

			polyControl.clear();

			for (let val of value) {
				if (!Array.isArray(val)) {
					val = [0, 0];
				}

				const array = new FormArray<FormControl<number>>([
					new FormControl<number>((val[0] as number) || 0, {
						nonNullable: true
					}),
					new FormControl<number>((val[1] as number) || 0, {
						nonNullable: true
					})
				]);

				polyControl.push(array);
			}

			this.cdr.markForCheck();
			this.polygonSavedValue = JSON.stringify(poly.value, null, 4);
		} catch {
			this.invalidValue = true;
			this.cdr.markForCheck();
		}
	}
}
