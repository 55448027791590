import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/utils';

import { createAppActions } from './create-app.actions';

export interface CreateAppState {
	status: Status;
	error: Error | Error[] | undefined;
}

export const initialState: CreateAppState = {
	status: Status.PENDING,
	error: undefined
};

const reducer = createReducer(
	initialState,
	on(createAppActions.createApp, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(createAppActions.createAppPending, state => ({
		...state,
		status: Status.PENDING
	})),
	on(createAppActions.createAppSuccess, (state, action) => ({
		...state,
		status: Status.SUCCESS
	})),
	on(createAppActions.createAppFailure, (state, action) => ({
		...state,
		status: Status.FAILED,
		error: action.error
	}))
);

export const createAppFeature = createFeature({
	name: 'createApp',
	reducer
});
