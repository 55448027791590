import { gql } from 'apollo-angular';

import { State } from '@yuno/api/interface';

export const GET_STATES_BY_APPID = gql`
	query getStatesByAppId($appId: String!) {
		states(appId: $appId) {
			_id
			state
			updatedAt
		}
	}
`;

export const GET_STATE_BY_ID = gql`
	query getStateById($_id: String!) {
		selectedState(_id: $_id) {
			_id
			state
			display
		}
	}
`;

export type StatesQuery = {
	states: Partial<State>[];
};

export type SelectStateQuery = {
	selectedState: Partial<State>;
};
