@if (data$ | async; as data) {
	@if (data.mapStyle) {
		<mgl-map
			(styleImageMissing)="mapOnMissingImage($event)"
			class="absolute left-0 top-0 z-0 h-full w-full"
			[dragRotate]="data.theme?.view?.dragRotate"
			[dragPan]="data.theme?.view?.dragPan"
			[pitchWithRotate]="data.theme?.view?.pitchWithRotate"
			[touchZoomRotate]="data.theme?.view?.touchZoomRotate"
			[style]="data.mapStyle"
			[fitBounds]="data.bounds.fitBounds"
			[fitBoundsOptions]="{ padding: 80 }">
			<mgl-geojson-source id="bounds" [data]="data.bounds.polygon"> </mgl-geojson-source>
			<mgl-layer
				id="bound-fill"
				type="fill"
				source="bounds"
				[paint]="{
					'fill-color': '#1e70bf',
					'fill-opacity': 0.28
				}">
			</mgl-layer>
			<mgl-layer
				id="bounds-line"
				type="line"
				source="bounds"
				[paint]="{
					'line-color': '#1e70bf',
					'line-width': 3
				}">
			</mgl-layer>
			<ng-content></ng-content>
		</mgl-map>
	}
}
