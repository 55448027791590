import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { StyleSpecification } from 'maplibre-gl';

import { MapStyle, MapViewOptions } from '@yuno/api/interface';

export const mapActions = createActionGroup({
	source: 'Map',
	events: {
		loadMapStyles: emptyProps(),
		loadMapStylesSuccess: props<{ data: MapStyle[] }>(),
		loadMapStylesFailure: props<{ error: Error }>(),

		loadStyle: props<{ _id: string }>(),
		loadStyleSuccess: props<{ data: StyleSpecification }>(),
		loadStyleFailure: props<{ error: Error }>(),
		clearStyle: emptyProps(),

		loadDefault: props<{ appId: string; style?: string }>(),
		loadDefaultSuccess: props<{ style: StyleSpecification; view?: MapViewOptions }>(),
		loadDefaultFailure: props<{ error: Error }>(),
		clearDefault: emptyProps()
	}
});
