@if (masterForm.length >= 1) {
	@for (form of data?.data; track i; let i = $index) {
		<yuno-json-forms-dynamic-form
			[ngClass]="{ hidden: i !== formIndex && masterForm.length !== 1 }"
			[formValue]="formValue?.data?.[i]"
			[formGroup]="masterForm[i]"
			[formData]="form"
			[noMargin]="noMargin"
			(formEvent)="formEvent.emit($event)"
			(formChanges)="formChanges.emit($event)">
		</yuno-json-forms-dynamic-form>
	}
}
