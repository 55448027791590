import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Panorama } from '@yuno/api/interface';

import { panoramasActions } from './panoramas.actions';
import { panoramasFeature } from './panoramas.state';

@Injectable()
export class PanoramasFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(panoramasFeature.selectLoaded));
	panoramas$ = this.store.pipe(select(panoramasFeature.selectPanoramas));
	selectedPanorama$ = this.store.pipe(select(panoramasFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(panoramasFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(panoramasFeature.selectStatus));
	next$ = this.store.pipe(select(panoramasFeature.getNext));
	prev$ = this.store.pipe(select(panoramasFeature.getPrevious));

	get() {
		this.store.dispatch(panoramasActions.load());
	}

	getNextPanorama() {
		this.store.dispatch(panoramasActions.nextPanorama());
	}

	getPrevPanorama() {
		this.store.dispatch(panoramasActions.prevPanorama());
	}

	select(_id?: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(panoramasActions.select({ _id }));
	}

	updateSelect(data: Partial<Panorama>) {
		this.store.dispatch(panoramasActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(panoramasActions.clearSelect());
	}

	save() {
		this.store.dispatch(panoramasActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(panoramasActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(panoramasActions.delete({ _id }));
	}
}
