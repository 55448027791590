import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';

import {
	TinymceEditorComponent,
	TinymceEditorFormControlNameComponent
} from './tinymce-editor.component';
import { TinymceInlineComponent } from './tinymce-inline/tinymce-inline.component';

@NgModule({
	imports: [
		CommonModule,
		EditorModule,
		FormsModule,
		ReactiveFormsModule,
		TinymceEditorComponent,
		TinymceEditorFormControlNameComponent,
		TinymceInlineComponent
	],
	exports: [TinymceEditorComponent, TinymceInlineComponent, TinymceEditorFormControlNameComponent]
})
export class YunoTinymceEditorModule {}
