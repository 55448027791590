import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ControlsOf, LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import { CdnData, CdnFile } from '@yuno/api/interface';

export interface ImageButtonForm {
	_id: FormControl<string>;
	id: FormControl<string>;
	file?: FormGroup<ControlsOf<CdnFile> | undefined>;
	image?: FormGroup<{
		_id: FormControl<string>;
	}>;
	size: FormControl<'small' | 'large'>;
	src: FormControl<string>;
	title: FormGroup<LanguageFormType>;
	wide: FormControl<boolean>;
}

@Injectable({
	providedIn: 'root'
})
export class ImageButtonEditorService {
	form: FormGroup<ImageButtonForm>;
	sizeValues = ['small', 'large'];

	get file(): FormGroup<ControlsOf<CdnFile> | undefined> {
		return this.form.get('file') as FormGroup<ControlsOf<CdnFile> | undefined>;
	}

	createFormGroup(): void {
		this.form = new FormGroup<ImageButtonForm>({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{
					nonNullable: true
				}
			),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			size: new FormControl('large', { nonNullable: true }),
			src: new FormControl('', { nonNullable: true }),
			file: new FormGroup<ControlsOf<CdnFile> | undefined>({
				_id: new FormControl({ value: undefined, disabled: true }, { nonNullable: true }),
				data: new FormGroup<ControlsOf<CdnData> | undefined>({
					fileName: new FormControl(
						{ value: undefined, disabled: true },
						{ nonNullable: true }
					),
					url: new FormControl(
						{ value: undefined, disabled: true },
						{ nonNullable: true }
					)
				})
			}),
			title: newLanguageFormGroup(),
			wide: new FormControl(false, { nonNullable: true })
		});
	}
}
