import { gql } from 'apollo-angular';

import { GeoPhoto } from '@yuno/api/interface';

export const GET_GEOPHOTOS = gql`
	query geoPhotos($categoryId: String!, $appId: String!) {
		geoPhotos(appId: $appId, categoryId: $categoryId) {
			_id
			categoryId
			id
			public
			rotation
			coordinates
			updatedAt
		}
	}
`;

export const GET_GEOPHOTO = gql`
	query getGeoPhoto($id: String!, $appId: String!) {
		getGeoPhoto(appId: $appId, _id: $id) {
			_id
			categoryId
			appId
			id
			coordinates
			rotation
			title
			description
			file {
				_id
				data {
					url
					fileName
				}
			}
			public
			updatedAt
		}
	}
`;

export const SAVE_GEOPHOTO = gql`
	mutation saveGeoPhoto($data: GeoPhotoInput!, $appId: String!) {
		saveGeoPhoto(data: $data, appId: $appId) {
			_id
			categoryId
			id
			public
			title
			description
			file {
				_id
				data {
					url
					fileName
				}
			}
			updatedAt
		}
	}
`;

export const DELETE_GEOPHOTO = gql`
	mutation deleteGeoPhotoById($_id: String!, $appId: String!) {
		deleteGeoPhotoById(appId: $appId, _id: $_id)
	}
`;

// export const DUPLICATE_GEOPHOTO = gql`
//     mutation duplicateGeoPhotoById($_id: String!, $appId: String!) {
//         duplicateGeoPhotoById(_id: $_id, appId: $appId) {
//             _id
//             id
//             updatedAt
//         }
//     }
// `;

export type GeoPhotosQuery = {
	geoPhotos: Partial<GeoPhoto>[];
};

export type GeoPhotoSelectQuery = {
	getGeoPhoto: GeoPhoto;
};

export type GeoPhotoSave = {
	saveGeoPhoto: GeoPhoto;
};

// export type GeoPhotoDuplicate = {
// 	duplicateGeoPhotoById: GeoPhoto;
// };

export type GeoPhotoDelete = {
	deleteGeoPhotoById: string;
};
