import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { YunoAdminUiSelectorButtonComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';

import { MarkerService } from '../../markers.service';

@Component({
	selector: 'yuno-admin-marker-viewer-download',
	standalone: true,
	imports: [YunoAdminUiSelectorButtonComponent],
	templateUrl: './download.component.html',
	styleUrls: ['./download.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminMarkersDownloadComponent extends AppDataComponent {
	private readonly markerService = inject(MarkerService);

	downloadGeojson(appId: string) {
		this.markerService.downloadGeojson(appId);
	}

	uploadGeojson(appId: string) {
		this.markerService.uploadGeojson(appId);
	}
}
