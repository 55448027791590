import { gql } from 'apollo-angular';

import { Client } from '@yuno/api/interface';

export const SAVE_CLIENT = gql`
	mutation saveClient($client: ClientInput!) {
		saveClient(client: $client) {
			_id
			id
			url
			path
		}
	}
`;

export const DUPLICATE_CLIENT = gql`
	mutation duplicateClient($_id: String!) {
		duplicateClient(_id: $_id) {
			_id
			id
			url
		}
	}
`;

export type SaveClientMutation = {
	saveClient: Partial<Client>;
};

export type DuplicateClientMutation = {
	duplicateClient: Partial<Client>;
};
