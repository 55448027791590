import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, tap } from 'rxjs';

import { YunoAdminButtonsModule, YunoAdminTableComponent, YunoCardModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { ParticipationModel } from '@yuno/api/interface';

import { ParticipationModelFacade } from '../../../data-access';

@Component({
	selector: 'yuno-admin-participation-view-selector',
	standalone: true,
	imports: [
		YunoCardModule,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		RouterModule,
		AsyncPipe
	],
	templateUrl: './view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationViewSelectorComponent extends AppDataComponent implements OnInit {
	private facade = inject(ParticipationModelFacade);

	data$ = combineLatest({
		list: this.facade.data$,
		selected: this.facade.selected$.pipe(
			tap(data => {
				if (data?._id && data.id === 'new category') {
					this.router.navigate(['./', data._id, 'map', 'edit'], {
						relativeTo: this.route
					});
				}
			})
		)
	});

	ngOnInit(): void {
		this.facade.get();
	}

	onCreate(): void {
		this.facade.createNew();
	}

	getData(data: Partial<ParticipationModel>[] | null | undefined): { [key: string]: unknown }[] {
		return data as { [key: string]: unknown }[];
	}

	onView(row: Partial<ParticipationModel>): void {
		this.router.navigate([row._id as string], {
			relativeTo: this.route
		});
	}

	onEdit(row: Partial<ParticipationModel>): void {
		this.router.navigate(['./', row._id, 'map', 'edit'], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<ParticipationModel>): void {
		this.facade.duplicate(row._id as string);
	}

	onDelete(row: Partial<ParticipationModel>): void {
		this.facade.delete(row._id as string);
	}
}

@Component({
	selector: 'yuno-admin-participation-view',
	standalone: true,
	imports: [RouterModule],

	template: '<router-outlet></router-outlet>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationViewComponent {}
