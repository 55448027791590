import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { MainUiService } from '../services';
import { CanComponentDeactivate } from './reset.guard';

export const ResetContainerUi: CanDeactivateFn<CanComponentDeactivate> = () => {
	const service = inject(MainUiService);
	service.setContainerType('data');

	return true;
};
