@if (data$ | async; as data) {
	<yuno-edit-container
		[col]="4"
		[pos]="1"
		[span]="4"
		[tabs]="['Settings', 'Map Options', 'Map UI', 'Datasets', 'Participate', 'Panorama']"
		(outputTab)="onSwitchTab($event.key)">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>

		<div class="flex flex-col gap-2 md:h-full md:grid-cols-2 md:flex-row">
			<div edit-container-content [hasTabs]="true" class="md:max-w-[520px]">
				@if (!minimalAppRole(userRoles.EDITOR)) {
					<yuno-user-rights-message class="mb-4"></yuno-user-rights-message>
				}
				@if (tabValue === 'Settings') {
					<yuno-admin-theme-settings
						[form]="service.form"
						[sidemenusActive]="data.sidemenusCreated"></yuno-admin-theme-settings>
				}
				@if (tabValue === 'Map Options') {
					<yuno-admin-theme-map-options
						[form]="service.form"
						[language]="data.language"></yuno-admin-theme-map-options>
				}
				@if (tabValue === 'Map UI') {
					<yuno-admin-theme-map-ui [form]="service.form"></yuno-admin-theme-map-ui>
				}
				@if (tabValue === 'Datasets') {
					<yuno-admin-theme-datasets [form]="service.form"></yuno-admin-theme-datasets>
				}
				@if (tabValue === 'Participate') {
					<yuno-admin-theme-participate
						[form]="service.form"
						[language]="data.language"></yuno-admin-theme-participate>
				}
				@if (tabValue === 'Panorama') {
					<yuno-admin-theme-panorama [form]="service.form"></yuno-admin-theme-panorama>
				}
				<div [ngClass]="{ 'm-auto max-w-md': !desktopView }"></div>
			</div>
			<div edit-container-content class="flex-1">
				<yuno-admin-map-viewer></yuno-admin-map-viewer>
			</div>
		</div>
	</yuno-edit-container>
}
