<router-outlet></router-outlet>

<yuno-card>
	<ng-container content>
		<section class="grid grid-cols-1">
			@if (data$ | async; as data) {
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="false"
					[pagination]="true"
					[pageOptions]="{
						pageSize: 100,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="data.imageButtons"
					[buttons]="tableButtons"
					[buttonsWidth]="9"
					[columns]="[{ key: 'id', label: 'id' }]"
					(clicked)="onSelect($event)">
					<div class="flex justify-between">
						<button yuno-admin-add-button [routerLink]="['./create/']">
							New Image Button
						</button>
					</div>
				</yuno-admin-table>
			}
		</section>
	</ng-container>
</yuno-card>

<ng-template #tableButtons let-row>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
