import { gql } from 'apollo-angular';

export const DELETE_LAYER = gql`
	mutation deleteLayerById($_id: String!) {
		deleteLayer(_id: $_id)
	}
`;

export type DeleteLayerMutation = {
	deleteLayer: string;
};
