import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { catchError, of, switchMap } from 'rxjs';

import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';

export const placeMarkersGuard: CanActivateFn = route => {
	const { id } = route.params;
	if (!id) {
		return false;
	}

	if (id === 'create') {
		return true;
	}

	const facade = inject(MarkerCategoriesFacade);
	facade.clearSelect();
	facade.select(id);

	return facade.selectedMarkerCategory$.pipe(
		switchMap(() => of(true)),
		catchError(() => of(false))
	);
};

export const placeMarkersListGuard: CanActivateFn = () => {
	const facade = inject(MarkerCategoriesFacade);
	facade.get();
	facade.clearSelect();

	return facade.markerCategories$.pipe(
		switchMap(() => of(true)),
		catchError(() => of(true))
	);
};
