import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

import { PillContainerComponent } from './pill-container/pill-container.component';
import { PillItemComponent } from './pill-item/pill-item.component';

@NgModule({
	declarations: [PillItemComponent, PillContainerComponent],
	imports: [CommonModule, MatChipsModule],
	exports: [PillItemComponent, PillContainerComponent]
})
export class YunoAdminPillModule {}
