import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FencesResolver } from './fences.resolver';

const routes: Routes = [
	{
		path: '',
		resolve: {
			data: FencesResolver
		},
		loadComponent: () => import('./fences.component').then(c => c.FencesComponent),
		children: [
			{
				path: 'create',
				loadComponent: () =>
					import('../editor/fences-editor.component').then(c => c.FencesEditorComponent)
			},
			{
				path: 'edit/:id',
				loadComponent: () =>
					import('../editor/fences-editor.component').then(c => c.FencesEditorComponent)
			}
		]
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FencesRoutingModule {}
