import { Injectable } from '@angular/core';

import { loadScript } from './_helpers';

export const YouTubeApiUrl = 'https://www.youtube.com/iframe_api';

@Injectable({
	providedIn: 'root'
})
export class YoutubeService {
	private loaded = false;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private videoMap: { [key: string]: any } = {};

	/**
	 * Load the Youtube Iframe-API
	 *
	 * @return {*}  {Promise<void>}
	 * @memberof YoutubeService
	 */
	async setupApi(): Promise<void> {
		if (this.loaded) {
			return;
		}

		await loadScript(YouTubeApiUrl);
		const onYouTubeIframeAPIReady = () => {
			this.loaded = true;
			return true;
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any)['onYouTubeIframeAPIReady'] = onYouTubeIframeAPIReady;
	}

	/**
	 * Creates a Youtube player using the Youtube Iframe API
	 *
	 * @param {{
	 * 			elementId: string;
	 * 			url: string;
	 * 			events?: {
	 * 				onReady: (player: unknown, e: unknown) => void;
	 * 				onStateChange: (e: unknown) => void;
	 * 			};
	 * 		}} options
	 * @param {boolean} [idMode]
	 * @return {*}  {Promise<void>}
	 * @memberof YoutubeService
	 */
	async setupPlayer(
		options: {
			elementId: string;
			url: string;
			events?: {
				onReady: (player: unknown, e: unknown) => void;
				onStateChange: (e: unknown) => void;
			};
		},
		idMode?: boolean
	): Promise<void> {
		try {
			await this.setupApi();

			let videoId: string;
			if (idMode) {
				// when only ID
				videoId = options.url;
			} else {
				// if shortend URL
				videoId = options.url.includes('//youtu.be/')
					? options.url.split('be/')[1]
					: options.url.split('v=')[1].split('&')[0];
			}

			if (videoId) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(window as any)['YT'].ready(() => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.videoMap[options.elementId] = new (window as any)['YT'].Player(
						options.elementId,
						{
							host: 'http://www.youtube-nocookie.com',
							events: {
								onReady: () => options.events?.onReady,
								onStateChange: () => options.events?.onStateChange
							},
							videoId: videoId
						}
					);
				});
			}
		} catch (err) {
			console.error(err);
		}
	}

	/**
	 * removes a video by its ID
	 * @param id {string}
	 */
	destroyPlayer(id: string): void {
		if (this.videoMap[id]) {
			this.videoMap[id].destroy();
			delete this.videoMap[id];
		}
	}
}
