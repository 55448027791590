import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { combineLatest } from 'rxjs';

import { TextfieldComponents } from '@yuno/api/interface';

import { TemplatesFacade } from '../data-access';
import { TemplateItemComponent } from './template-item.component';

@Component({
	standalone: true,
	imports: [CommonModule, TemplateItemComponent],
	selector: 'yuno-admin-templates',
	templateUrl: './choose-template.component.html',
	styleUrls: ['./choose-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseTemplateComponent {
	private _backdrop = true;
	private _selector = 'textfield';

	private readonly templateFacade = inject(TemplatesFacade);

	@Input()
	set backdrop(bool: boolean) {
		this._backdrop = bool;
	}

	get backdrop(): boolean {
		return this._backdrop;
	}

	@Input()
	set selector(str: string) {
		this._selector = str;
		this.templateFacade.get(str);
	}

	get selector(): string {
		return this._selector;
	}

	@Output() clicked = new EventEmitter<TextfieldComponents[]>();
	@Output() bgClicked = new EventEmitter<void>();

	data$ = combineLatest({
		templates: this.templateFacade.templates$
	});

	onSelect(c: unknown) {
		const components = c as TextfieldComponents[];
		this.clicked.emit(components);
	}

	onBackgroundClick() {
		this.bgClicked.emit();
	}
}
