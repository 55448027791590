import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AsyncSubject, combineLatest, map, startWith, tap } from 'rxjs';

import { ChooseTemplateComponent, TemplateItemComponent } from '@yuno/admin/features/templates';
import {
	TableChanged,
	YunoAdminButtonsModule,
	YunoAdminTableComponent,
	YunoCardModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { DialogItem, MessageService } from '@yuno/angular/notifications';
import { TextBlockComponent, TextLinkComponent } from '@yuno/angular/textfield';
import {
	Textfield,
	TextfieldComponents,
	UserRolesEnum,
	YunoUserRolesEnum,
	minimalUserRole
} from '@yuno/api/interface';

import { TextfieldFacade } from '../../../data-access';

@Component({
	standalone: true,
	imports: [
		YunoAdminButtonsModule,
		RouterModule,
		YunoCardModule,
		YunoAdminTableComponent,
		AsyncPipe,
		ChooseTemplateComponent,
		TemplateItemComponent,
		TextLinkComponent,
		TextBlockComponent
	],
	selector: 'yuno-admin-textfield',
	templateUrl: './textfields.component.html',
	styleUrls: ['./textfields.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextfieldsComponent extends AppDataComponent implements OnInit {
	private readonly messageService = inject(MessageService);
	private readonly textfieldFacade = inject(TextfieldFacade);

	/* 	an observerable that checks when legends are collected 	*/
	private _textfieldsChecked = new AsyncSubject<boolean>();

	data$ = combineLatest({
		textfields: this.textfieldFacade.allTextfields$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.masterToggle = this.getMasterToggle(data);
					if (this.masterToggle) {
						this._textfieldsChecked.next(true);
						this._textfieldsChecked.complete();
					}
				}
			}),
			map(data => {
				let textfields = data;
				if (data && this.disableUserAccess()) {
					textfields = data
						.filter(text => text.public)
						.map(item => {
							return item;
						});
				}
				return textfields as { [key: string]: unknown }[];
			})
		),
		dialog: this.messageService.dialog$.pipe(
			startWith(undefined),
			tap(confirmed => {
				if (confirmed) {
					this.onToggleAllConfirm();
				}
			})
		),
		selected: this.textfieldFacade.selectedTextfield$,
		checked: this._textfieldsChecked.asObservable().pipe(startWith(false))
	});

	texts: { [key: string]: unknown }[];
	selected: Textfield | undefined;
	masterToggle: 'true' | 'indeterminate' | 'false';
	toggles: {
		checked: boolean;
		filter: string;
	} = {
		checked: false,
		filter: ''
	};
	toggleTemplates = false;

	ngOnInit(): void {
		this.textfieldFacade.get();
	}

	getMasterToggle(data: Partial<Textfield>[]): 'true' | 'indeterminate' | 'false' {
		if (data.every(text => text.public === true)) {
			return 'true';
		}
		if (data.every(text => text.public === false)) {
			return 'false';
		}
		if (data.some(text => text.public === true) && data.some(text => text.public === false)) {
			return 'indeterminate';
		}
		return 'indeterminate';
	}

	onSelectTemplate(bool: boolean) {
		this.toggleTemplates = bool;
	}

	onCreate(components?: TextfieldComponents[]) {
		this.textfieldFacade.clearSelect();
		this.router.navigate(['create'], {
			relativeTo: this.route
		});
		this.textfieldFacade.setTemplate(components);
		this.toggleTemplates = false;
	}

	onSelectTextfield(row: Partial<Textfield>): void {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDeleteTextfield(row: Partial<Textfield>): void {
		this.textfieldFacade.delete(row._id as string);
	}

	onUpdatePublic(e: TableChanged) {
		// The User should be a XKP member with EDITOR rights
		// and has a EDITOR role inside the application
		if (
			!minimalUserRole(UserRolesEnum.EDITOR) ||
			!this.minimalAppRole(YunoUserRolesEnum.EDITOR)
		) {
			return;
		}

		const textfield = {
			public: e.checked,
			id: e.item['id'],
			_id: e.item['_id']
		} as Partial<Textfield>;
		this.textfieldFacade.updateTextfield(textfield, e.index);
	}

	onToggleAllPublic(checked: boolean) {
		this.toggles.checked = checked;
		const data: DialogItem = {
			title: 'Confirm toggling textfields',
			message: 'Are you sure you want to toggle the visibility of these textfields?',
			buttons: [
				{
					key: 'Yes',
					type: 'primary',
					confirm: true
				},
				{
					key: 'Cancel',
					type: 'muted'
				}
			],
			confirm: 'Confirmed'
		};
		this.messageService.openDialog(data);
	}

	onToggleAllConfirm() {
		this.textfieldFacade.updateAllTextfields(this.toggles.checked, this.toggles?.filter || '');
	}

	disableUserAccess(): boolean {
		const hasMinimalUserRole = this.minimalRole(this.roles.EDITOR);
		const hasMinimalAppRole = this.minimalAppRole(this.userRoles.EDITOR);
		return !(hasMinimalUserRole && hasMinimalAppRole);
	}
}
