import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, startWith, take, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	FileLibraryEditContainerComponent,
	FileLibraryService
} from '@yuno/admin/features/file-library';
import { ImageButtonFacade } from '@yuno/admin/features/media/data-access';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { CdnFile, LanguageAll, MediaImageButton } from '@yuno/api/interface';

import { ImageButtonEditorService } from './image-button-editor.service';

@Component({
	selector: 'yuno-admin-image-button-editor',
	templateUrl: './image-button-editor.component.html',
	styleUrls: ['./image-button-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageButtonEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly imageButtonFacade = inject(ImageButtonFacade);
	private readonly imageService = inject(FileLibraryService);
	private readonly appFacade = inject(AppFacade);
	private readonly destroyRef = inject(DestroyRef);
	readonly service = inject(ImageButtonEditorService);

	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;

	originalData: Partial<MediaImageButton>;
	readonly = !this.minimalAppRole(this.userRoles.EDITOR);
	browseImageLibrary = false;
	languages = LanguageAll;

	data$ = combineLatest({
		imageButton: this.imageButtonFacade.selectedImageButton$.pipe(
			tap(data => {
				if (!this.originalData) {
					this.originalData = data as MediaImageButton;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(this.originalData as any);
				}
				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data?._id}`]);
				}
			})
		),
		language: this.appFacade.language$.pipe(startWith('nl'))
	});
	selectImage$ = this.imageService.activeFile$.pipe(
		tap(file => {
			if (this.browseImageLibrary) {
				this.patchImageValue(file);
			}
		})
	);

	close$ = this.imageService.close$.pipe(
		tap(() => {
			if (this.browseImageLibrary) {
				this.closeOverlay();
			}
		})
	);

	get imageBtn(): MediaImageButton {
		return this.service.form.getRawValue() as MediaImageButton;
	}

	ngOnInit(): void {
		this.service.createFormGroup();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.imageButtonFacade.onSelect(id);
		});

		this.languageSelector();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.imageButtonFacade.update(this.service.form.getRawValue() as MediaImageButton);
			});
	}

	onSave() {
		this.imageButtonFacade.save();
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		redirectTo(this.route, this.router);
		this.imageButtonFacade.clearSelect();
	}

	openImageLibrary(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			// tinymce uses high z-indexes
			panelClass: 'z-10000'
		});

		const imageEditor = new ComponentPortal(FileLibraryEditContainerComponent);
		const t = this.overlayRef?.attach(imageEditor);
		t?.changeDetectorRef?.detectChanges();
		this.browseImageLibrary = true;
	}

	closeOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.browseImageLibrary = false;
	}

	patchImageValue(file: CdnFile): void {
		this.service.file?.patchValue(file);
	}

	clearImage(): void {
		this.service.file?.reset();
	}

	ngOnDestroy() {
		this.imageButtonFacade.clearSelect();
		this.languageService.destroyLanguageSelector();
	}
}
