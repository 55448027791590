import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { TilesetCustom } from '@yuno/api/interface';

export const customTilesetActions = createActionGroup({
	source: 'CustomTileset',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<TilesetCustom>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		selectSuccess: props<{ data: Partial<TilesetCustom> }>(),
		selectFailure: props<{ error: Error }>(),
		clearSelect: emptyProps(),

		save: emptyProps(),
		saveSuccess: props<{ _id: string }>(),
		saveFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<TilesetCustom> }>(),
		updateSelectSuccess: props<{ data: Partial<TilesetCustom> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		upload: props<{ files: File[] }>(),
		uploadSuccess: emptyProps(),
		uploadFailure: props<{ error: Error }>()
	}
});
