import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { sourcesCustomActions } from '@yuno/admin/features/sources/data-access/+state/sources-custom/sources-custom.actions';
import { Status } from '@yuno/admin/utils';
import { CustomSource } from '@yuno/api/interface';

export interface SourcesCustomState {
	loaded: boolean;
	customSources: Partial<CustomSource>[];
	selectedLoaded: boolean;
	selected: Partial<CustomSource> | undefined;
	status: Status;
}

export const initialStateCustom: SourcesCustomState = {
	loaded: false,
	customSources: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateCustom,
	on(appActions.reset, () => initialStateCustom),
	on(sourcesCustomActions.load, () => ({
		...initialStateCustom,
		status: Status.VALIDATING
	})),
	on(sourcesCustomActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		customSources: data,
		status: Status.SUCCESS
	})),
	on(sourcesCustomActions.loadFailure, () => ({
		...initialStateCustom,
		status: Status.FAILED
	})),

	on(sourcesCustomActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(sourcesCustomActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(sourcesCustomActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(sourcesCustomActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(sourcesCustomActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(sourcesCustomActions.saveSuccess, (state, { _id }) => {
		const index = state.customSources.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const sources = [...state.customSources];
		if (index >= 0) {
			sources[index] = { ...sources[index], ...state.selected };
		} else if (state.selected) {
			sources.push({ ...state.selected, _id });
		}

		return {
			...state,
			customSources: sources
		};
	}),

	on(sourcesCustomActions.duplicateSuccess, (state, { data }) => {
		const customSources = [...state.customSources, data];
		return {
			...state,
			customSources: customSources
		};
	}),

	on(sourcesCustomActions.deleteSuccess, (state, { _id }) => {
		const array = [...(state.customSources || [])];
		const i = array.findIndex(customSource => customSource._id === _id);
		array.splice(i, 1);
		return {
			...state,
			customSources: array
		};
	})
);

export const sourcesCustomFeature = createFeature({
	name: 'customSources',
	reducer
});
