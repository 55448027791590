import { gql } from 'apollo-angular';

export const GET_APPS_CLIENT = gql`
	query getAppsClient {
		apps {
			_id
			id
			url
			client {
				url
			}
			public
			currentUserRole
		}
	}
`;

export const GET_APPS_INTERNAL = gql`
	query getAppsInternal {
		apps {
			_id
			id
			url
			client {
				id
				url
			}
			public
			currentUserRole
		}
	}
`;
