import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard, EditorGuard, ResetContainerUi } from '@yuno/admin/core/guards';
import { uiContainerResolver } from '@yuno/admin/core/resolvers';
import { TextfieldEditorContainerComponent } from '@yuno/admin/features/place-markers/feature/settings/marker/textfield-editor-container/textfield-editor-container.component';

import { YunoAdminPlaceMarkersViewerRoutingComponent } from './components/routing/routing.component';

const routes: Routes = [
	{
		path: '',
		canDeactivate: [ResetContainerUi],
		resolve: [uiContainerResolver],
		data: { containerType: 'map' },
		loadComponent: () => import('./view.component').then(c => c.PlaceMarkersViewComponent),
		children: [
			{
				path: 'map',
				component: YunoAdminPlaceMarkersViewerRoutingComponent,
				children: [
					{
						path: 'edit',
						canActivate: [AdminGuard],
						loadComponent: () =>
							import('../settings/category/category-editor.component').then(
								c => c.CategoryEditorComponent
							)
					},
					{
						path: 'marker/create',
						canActivate: [EditorGuard],
						loadComponent: () =>
							import('../settings/marker/marker-editor.component').then(
								c => c.MarkerEditorComponent
							),
						children: [
							{
								path: 'textfield/:id',
								component: TextfieldEditorContainerComponent
							},
							{ path: '**', redirectTo: '' }
						]
					},
					{
						path: 'marker/:id',
						loadComponent: () =>
							import('../settings/marker/marker-editor.component').then(
								c => c.MarkerEditorComponent
							),
						children: [
							{
								path: 'textfield/:id',
								component: TextfieldEditorContainerComponent
							},
							{ path: '**', redirectTo: '' }
						]
					}
				]
			},
			{
				path: 'list',
				loadComponent: () =>
					import('./components/list-viewer/list-viewer.component').then(
						c => c.PlaceMarkersListViewerComponent
					),
				children: [
					{
						path: 'edit',
						canActivate: [AdminGuard],
						loadComponent: () =>
							import('../settings/category/category-editor.component').then(
								c => c.CategoryEditorComponent
							)
					}
				]
			},
			{ path: '**', redirectTo: 'map' }
		]
	},
	{
		path: 'create',
		canActivate: [EditorGuard],
		loadComponent: () =>
			import('../settings/marker/marker-editor.component').then(c => c.MarkerEditorComponent)
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	declarations: []
})
export class PlaceMarkersViewViewerRoutingModule {}
