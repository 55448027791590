@if (service$ | async; as service) {
	@if (service.component) {
		@if (editor.getSelector(service.component) | async; as selector) {
			<form [formGroup]="service.component" class="flex flex-col gap-4">
				<section>
					<yuno-forms-title>{{ editor.getTitle(selector) }} Editor</yuno-forms-title>
					<yuno-forms-span>You can edit the text below.</yuno-forms-span>
				</section>
				@if (data$ | async; as data) {
					<div formGroupName="options" class="flex flex-col gap-4">
						@defer (when selector === 'h1' || selector === 'h2' || selector === 'h3') {
							<yuno-admin-predefined-heading-editor
								[form]="service.component"
								[language]="data.language" />
						}

						@defer (when selector === 'paragraph') {
							<yuno-admin-predefined-paragraph-editor
								[form]="service.component"
								[language]="data.language" />
						}

						@defer (when selector === 'summary' || selector === 'dateSummary') {
							<yuno-admin-predefined-summary-editor
								[form]="service.component"
								[language]="data.language" />
						}

						@defer (when selector === 'blockquote') {
							<yuno-admin-predefined-blockquote-editor
								[form]="service.component"
								[language]="data.language" />
						}

						@defer (when selector === 'divider') {
							<yuno-admin-predefined-divider-editor
								[form]="service.component"
								[language]="data.language" />
						}

						@defer (when selector === 'file') {
							<yuno-admin-predefined-file-editor
								[form]="service.component"
								[language]="data.language" />
						}
					</div>
				}
			</form>
		}
	}
}
