<ng-container *ngIf="data$ | async as data">
	<mgl-map
		*ngIf="mapStyle$ | async as mapStyle"
		class="z-0 h-full w-full"
		(mapLoad)="mapLoad($event)"
		(moveEnd)="moveEnd($event)"
		[style]="mapStyle"
		[zoom]="[zoom]"
		[center]="center"
		[pitch]="[pitch]"
		[bearing]="[bearing]"
		[fitBounds]="data.bounds"
		[fitBoundsOptions]="data.boundsOptions">
		<ng-content></ng-content>
		<ng-container *ngIf="service.map$ | async as map">
			<!-- Markers other than the Selected Marker -->
			<ng-container *ngFor="let marker of data.style.markers">
				<yuno-project-atlas-marker
					*ngIf="data.style.marker?.data?.id !== marker.data.id"
					(mouseClickEvent)="markerMouseClickEvent($event)"
					[map]="map"
					[placeMarker]="true"
					[language]="data.language"
					[setMarkerType]="'label'"
					[geometry]="marker.geometry"
					[data]="marker.data"></yuno-project-atlas-marker>
			</ng-container>

			<!-- Selected Marker -->
			<yuno-project-atlas-marker
				*ngIf="data.style.marker as marker"
				(mouseClickEvent)="markerMouseClickEvent($event)"
				(dragEnd)="dragEnd($event)"
				[map]="map"
				[draggable]="data.draggable"
				[language]="data.language"
				[setMarkerType]="'label'"
				[geometry]="marker.geometry"
				[data]="marker.data"></yuno-project-atlas-marker>
		</ng-container>
	</mgl-map>
</ng-container>
