import { createFeature, createReducer, on } from '@ngrx/store';

import { navbarActions } from './navbar.actions';

export interface NavbarRoute {
	route: string[];
	display: string;
	index: number;
	exact?: boolean;
}

export interface NavbarRouteTemp extends Omit<NavbarRoute, 'route'> {
	route?: string[];
}

export interface NavbarState {
	routes: NavbarRoute[];
}

export const initialState: NavbarState = {
	routes: []
};

const reducer = createReducer(
	initialState,
	on(navbarActions.addRoute, (state, { route }) => {
		// Create new Mutable array
		const routes = [...state.routes];

		// When a route does not have a display value
		// we remote it from the array at the given index
		if (!route.display) {
			routes.length = route.index + 1;

			return {
				...state,
				routes
			};
		}

		// Replace the route at index
		routes[route.index] = route;

		// Use Array.length to set a new size for an array,
		// which is faster than Array.splice
		if (routes.length - 1 > route.index) {
			routes.length = route.index + 1;
		}

		return {
			...state,
			routes
		};
	}),
	on(navbarActions.removeAt, (state, { index }) => {
		// Create new Mutable array
		const routes = [...state.routes];
		return {
			...state,
			routes: routes.slice(0, index + 1)
		};
	})
);

export const navbarFeature = createFeature({
	name: 'navbar',
	reducer
});
