<ng-container [formGroup]="form">
	<label class="yuno-form">
		@if (item.label) {
			<span
				class="select-none"
				[innerHtml]="
					item.label
						| languageSelect: language
						| safeHtml: item.validators?.required || item.required
				">
			</span>
		}
		<input
			[(colorPicker)]="colorValue"
			(colorPickerChange)="form.get(item.key)?.setValue($event)"
			[name]="item.key"
			[ngStyle]="{
				backgroundColor: form.get(item.key)?.value || '#fff',
				color: getTextColor(form.get(item.key)?.value) || '#000'
			}"
			[cpPosition]="item.options?.colorDir || 'auto'"
			[type]="'text'"
			[placeholder]="item.placeholder | languageSelect: language || ''"
			[value]="item.value"
			[formControl]="control" />
	</label>
</ng-container>
