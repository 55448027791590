import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Theme } from '@yuno/api/interface';

import { themesActions } from './theme.actions';

export interface ThemesState {
	loaded: boolean;
	themes: Partial<Theme>[];
	selectedLoaded: boolean;
	selected: Partial<Theme> | undefined;
	bounds: [[number, number], [number, number]] | undefined;
	maxBounds: [[number, number], [number, number]] | undefined;
	status: Status;
}

export const initialState: ThemesState = {
	loaded: false,
	themes: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING,
	maxBounds: undefined,
	bounds: undefined
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(themesActions.load, state => ({
		...state,
		loaded: false,
		status: Status.VALIDATING
	})),
	on(themesActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		themes: data,
		status: Status.SUCCESS
	})),
	on(themesActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(themesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(themesActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(themesActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(themesActions.updateBoundsByFenceSuccess, (state, { bounds }) => {
		return {
			...state,
			bounds: bounds
		};
	}),
	on(themesActions.updateMaxBoundsByFenceSuccess, (state, { bounds }) => {
		return {
			...state,
			maxBounds: bounds
		};
	}),

	on(themesActions.saveSuccess, state => {
		const index = state.themes.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const themes = [...state.themes];
		if (index >= 0) {
			themes[index] = { ...themes[index], ...state.selected };
		} else if (state.selected) {
			themes.push(state.selected);
		}
		themes.sort((a, b) => ((a.id as string) > (b.id as string) ? 1 : -1));
		return {
			...state,
			themes: themes
		};
	}),

	on(themesActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data } as Partial<Theme>,
		selectedLoaded: true
	})),
	on(themesActions.updateSelectFailure, (state, { error }) => ({ ...state, error }))
);

export const themesFeature = createFeature({
	name: 'themes',
	reducer
});
