import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { TextList } from '@yuno/api/interface';

import { textListsActions } from './text-list.actions';

export interface TextListState {
	lists: Partial<TextList>[];
	status: Status;
}

export const initialStateTextList: TextListState = {
	lists: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateTextList,
	on(appActions.reset, () => initialStateTextList),
	on(textListsActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(textListsActions.loadSuccess, (state, { data }) => ({
		...state,
		lists: data,
		status: Status.SUCCESS
	})),
	on(textListsActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	}))
);

export const textListsFeature = createFeature({
	name: 'textLists',
	reducer
});
