import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { TextToggle } from '@yuno/api/interface';

import { textTogglesActions } from './text-toggle.actions';

export interface TextToggleState {
	toggles: Partial<TextToggle>[];
	status: Status;
}

export const initialStateTextToggles: TextToggleState = {
	toggles: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateTextToggles,
	on(appActions.reset, () => initialStateTextToggles),
	on(textTogglesActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(textTogglesActions.loadSuccess, (state, { data }) => ({
		...state,
		toggles: data,
		status: Status.SUCCESS
	})),
	on(textTogglesActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	}))
);

export const textTogglesFeature = createFeature({
	name: 'textToggles',
	reducer
});
