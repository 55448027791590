import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { LngLatBoundsLike, StyleSpecification } from 'maplibre-gl';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { Marker } from '@yuno/api/interface';

import { markersActions } from './markers.actions';

export interface MarkersState {
	loaded: boolean;
	markers: Partial<Marker>[];
	selectedLoaded: boolean;
	selected: Partial<Marker> | undefined;
	mapStyle: StyleSpecification | undefined;
	status: Status;
	saveStatus: Status;
}

export const initialState: MarkersState = {
	loaded: false, // has the Markers list been loaded
	markers: [], // which Markers record has been selected
	selectedLoaded: false, // last known error (if any)
	selected: undefined,
	mapStyle: undefined,
	status: Status.PENDING,
	saveStatus: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(markersActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(markersActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		markers: data,
		status: Status.SUCCESS
	})),
	on(markersActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(markersActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(markersActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),

	on(markersActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data }
	})),
	on(markersActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),
	on(markersActions.removeEmptyMarker, state => {
		// remove empty markers from the list
		const arr = state.markers.filter(x => x._id);
		return {
			...state,
			markers: arr
		};
	}),

	// Save Update
	on(markersActions.saveSuccess, (state, { data }) => {
		const index = state.markers.findIndex(x => x['_id'] === data['_id']);

		const markers = [...state.markers];
		if (index >= 0) {
			markers[index] = { ...markers[index], ...data };
		} else if (state.selected) {
			markers.push({ ...state.selected });
		}

		return {
			...state,
			selected: data,
			saveStatus: Status.SUCCESS,
			markers: markers
		};
	}),
	on(markersActions.saveSuccessReset, state => {
		return {
			...state,
			saveStatus: Status.PENDING
		};
	}),
	on(markersActions.saveFailure, state => {
		return {
			...state,
			saveStatus: Status.PENDING
		};
	}),
	on(markersActions.loadMapStyleSuccess, (state, acion) => ({
		...state,
		mapStyle: acion.data
	}))
);

export const markersFeature = createFeature({
	name: 'markers',
	reducer,
	extraSelectors: ({ selectMapStyle }) => ({
		selectMapStyleBounds: createSelector(selectMapStyle, data => {
			if (!data) {
				return undefined;
			}

			const metadata = data.metadata as { [key: string]: unknown };
			if (!metadata?.['yuno:theme:bounds']) {
				return undefined;
			}

			return metadata['yuno:theme:bounds'] as LngLatBoundsLike;
		})
	})
});
