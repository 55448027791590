import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	CREATE_PARTICIPATION_MODEL,
	CreateMutation,
	DELETE_PARTICIPATION_MODEL,
	DUPLICATE_PARTICIPATION_MODEL,
	DeleteMutation,
	DuplicateMutation,
	GET_PARTICIPATION_MODEL,
	GET_PARTICIPATION_MODELS,
	GetQuery,
	SAVE_PARTICIPATION_MODEL,
	SaveMutation,
	SelectQuery
} from '../../../utils';
import { modelsActions } from './models.actions';
import { modelsFeature } from './models.state';

export const createModel = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(modelsActions.create),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.mutate<CreateMutation>({
						mutation: CREATE_PARTICIPATION_MODEL,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.createParticipationModel) {
								throw new Error('Failed creating');
							}

							return modelsActions.createSuccess({
								id: data.data.createParticipationModel._id
							});
						}),
						take(1),
						catchError(error => of(modelsActions.createFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const createModelSuccess = createEffect(
	(actions$ = inject(Actions), store = inject(Store)) =>
		actions$.pipe(
			ofType(modelsActions.createSuccess),
			tap(data => {
				store.dispatch(modelsActions.select({ _id: data.id }));
			})
		),
	{ dispatch: false, functional: true }
);

export const createModelFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.createFailure),
			tap(() => {
				message.sendToast(`Error creating Model!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const loadModels = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(modelsActions.load, modelsActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<GetQuery>({
						query: GET_PARTICIPATION_MODELS,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getParticipationModels) {
								throw new Error('Not found');
							}

							return modelsActions.loadSuccess({
								data: data.data.getParticipationModels
							});
						}),
						take(1),
						catchError(error => of(modelsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadModelsFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.loadFailure),
			tap(() => {
				message.sendToast(`Error loading Model!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const selectModel = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(modelsActions.select),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([action, appId]) =>
				graphql
					.query<SelectQuery>({
						query: GET_PARTICIPATION_MODEL,
						variables: {
							id: action._id,
							appId
						}
					})
					.pipe(
						map(data => {
							console.log(data.data);
							if (!data.data.getParticipationModel) {
								throw new Error('Not found');
							}

							return modelsActions.selectSuccess({
								data: data.data.getParticipationModel
							});
						}),
						take(1),
						catchError(error => of(modelsActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectModelFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.selectFailure),
			tap(() => {
				message.sendToast(`Error selecting Model!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const updateModel = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(modelsActions.updateSelect),
			map(action => {
				return modelsActions.updateSelectSuccess({
					data: action.data
				});
			}),
			catchError(error => of(modelsActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveModel = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(modelsActions.save),
			withLatestFrom(
				store.pipe(select(modelsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveMutation>({
						mutation: SAVE_PARTICIPATION_MODEL,
						variables: {
							data: { ...selected, updatedAt: undefined },
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveParticipationModel) {
								throw new Error('error saving to the database');
							}

							store.dispatch(
								modelsActions.selectSuccess({
									data: data.data.saveParticipationModel
								})
							);

							return modelsActions.saveSuccess();
						}),
						take(1),
						catchError(error => {
							return of(modelsActions.saveFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const duplicateModel = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(modelsActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([model, appId]) =>
				graphql
					.mutate<DuplicateMutation>({
						mutation: DUPLICATE_PARTICIPATION_MODEL,
						variables: {
							_id: model._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateParticipationModelById) {
								throw new Error(
									'error duplicating Participation Model to the database'
								);
							}
							store.dispatch(modelsActions.reload());
							return modelsActions.duplicateSuccess({
								data: data.data.duplicateParticipationModelById
							});
						}),
						take(1),
						catchError(error => {
							return of(modelsActions.duplicateFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const deleteModel = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(modelsActions.delete),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.mutate<DeleteMutation>({
						mutation: DELETE_PARTICIPATION_MODEL,
						variables: {
							_id: data._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteParticipationModelById) {
								throw new Error('error deleting Model from the database');
							}
							store.dispatch(modelsActions.reload());
							return modelsActions.deleteSuccess({
								_id: data.data?.deleteParticipationModelById
							});
						}),
						take(1),
						catchError(error => {
							return of(modelsActions.deleteFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const saveModelSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.saveSuccess),
			tap(() => {
				message.sendToast(`Model successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveModelFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Model!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateModelSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Model successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateModelFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Model!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteModelSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Model successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteModelFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(modelsActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Model!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
