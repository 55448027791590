@if (service$ | async; as service) {
	@if (data$ | async; as data) {
		<section>
			@if (service.component) {
				<form [formGroup]="service.component" class="flex flex-col gap-4">
					<section>
						<yuno-forms-title>Legacy Page textblock</yuno-forms-title>
						<yuno-forms-span>You can edit the selected item below.</yuno-forms-span>
					</section>
					<yuno-forms-text
						class="flex-1"
						formControlName="id"
						placeholder="Legacy page textblock name"
						label="Title"></yuno-forms-text>
					<yuno-forms-select
						class="flex-1"
						formControlName="class"
						label="Legacy page textblock type"
						placeholder="Choose type"
						[selectValues]="service.classSelectValues"
						[display]="service.classSelectDisplay"></yuno-forms-select>
					<yuno-forms-divider></yuno-forms-divider>
					<section formGroupName="title" class="flex flex-col gap-4">
						<h4>Text</h4>
						<section formGroupName="title">
							@for (lang of editService.languages; track lang) {
								@if (lang === data.language) {
									<yuno-forms-text
										[formControlName]="lang"
										placeholder="Page item title"
										label="Title"></yuno-forms-text>
								}
							}
						</section>
						<section class="flex gap-2">
							<yuno-forms-color
								formControlName="color"
								placeholder="#fff"
								[colorDirection]="'top'"
								label="Title color"></yuno-forms-color>
							<yuno-forms-text
								formControlName="icon"
								placeholder="Choose icon"
								label="Title Icon"></yuno-forms-text>
						</section>
					</section>
					<yuno-forms-html-image-library
						formControlName="content"></yuno-forms-html-image-library>
					<yuno-forms-divider></yuno-forms-divider>
					<ng-container formArrayName="link">
						<h4>Links</h4>
						@for (alias of service.itemLinks?.controls; track alias; let i = $index) {
							<div
								[formGroupName]="i"
								class="flex flex-col gap-2 rounded-md border-2 border-gray-200 bg-gray-100 p-4">
								<yuno-forms-select
									class="flex-1"
									formControlName="type"
									placeholder="Select type"
									label="Type"
									[selectValues]="service.linkTypeSelectValues"
									[display]="service.linkTypeSelectDisplay"></yuno-forms-select>
								<section formGroupName="text">
									@for (lang of editService.languages; track lang) {
										@if (lang === data.language) {
											<yuno-forms-text
												[formControlName]="lang"
												placeholder="Title"
												label="Title"></yuno-forms-text>
										}
									}
								</section>
								<yuno-forms-color
									class="flex-1"
									formControlName="color"
									placeholder="#fff"
									[colorDirection]="'top'"
									label="Color"></yuno-forms-color>
								@if (alias.value.type === 'page') {
									<section formGroupName="pageUrl">
										<yuno-forms-select
											class="flex-1"
											formControlName="_id"
											placeholder="Select page"
											label="Page"
											[selectValues]="service.linkPageSelectValues"
											[display]="service.linkPageSelectDisplay"
											(changes)="editService.onPageItemUrlIdChange($event, i)"
											(changesDisplay)="
												editService.onPageItemUrlChange($event, i)
											"></yuno-forms-select>
									</section>
								}
								@if (alias.value.type === 'navigation') {
									<yuno-forms-select
										class="flex-1"
										formControlName="url"
										placeholder="Select route"
										label="Navigation"
										[selectValues]="service.navigationSelectValues"
										[display]="
											service.navigationSelectDisplay
										"></yuno-forms-select>
								}
								@if (alias.value.type === 'url') {
									<section formGroupName="url">
										@for (lang of editService.languages; track lang) {
											@if (lang === data.language) {
												<yuno-forms-text
													class="flex-1"
													[formControlName]="lang"
													placeholder="Link url"
													label="Url"></yuno-forms-text>
											}
										}
									</section>
								}
								@if (alias.value.type === 'navigation') {
									<ng-container formGroupName="pageUrl">
										@if (alias.value.type === 'page') {
											<yuno-forms-select
												class="flex-1"
												formControlName="_id"
												placeholder="Select page url"
												label="Page url"
												[selectValues]="service.navigationSelectValues"
												[display]="
													service.navigationSelectDisplay
												"></yuno-forms-select>
										}
									</ng-container>
								}
								<div class="flex justify-end">
									<button
										yuno-admin-button
										color="danger"
										(click)="service.removePageItemLink(i)">
										remove
									</button>
								</div>
							</div>
						}
						<button
							yuno-admin-button
							color="primary"
							(click)="service.addPageItemLink()"
							[add]="true">
							Add Item
						</button>
						<yuno-admin-annotation class="col-span-3 mt-4" type="info">
							<span
								>the <strong>Page</strong> and <strong>Navigation</strong> links are
								<strong>not</strong> actually linked to the Navigation Page. <br />
								When changing the link in your Navigation, this link will be broken
								and has to be re-applied.</span
							>
						</yuno-admin-annotation>
					</ng-container>
					<yuno-forms-divider></yuno-forms-divider>
					<ng-container formArrayName="imageButtons">
						<h4>Image Buttons</h4>
						@for (
							alias of service.imageButtons?.controls;
							track alias;
							let i = $index
						) {
							<div
								[formGroupName]="i"
								class="flex flex-col gap-2 rounded-md border-2 border-gray-200 bg-gray-100 p-4">
								<ng-container formGroupName="item">
									<yuno-forms-select
										class="flex-1"
										formControlName="_id"
										placeholder="Select image button"
										label="Item"
										[selectValues]="service.imageButtonSelectValues"
										[display]="
											service.imageButtonSelectDisplay
										"></yuno-forms-select>
								</ng-container>
								<yuno-forms-select
									class="flex-1"
									formControlName="link"
									placeholder="Select page url"
									label="Link"
									[selectValues]="service.navigationSelectValues"
									[display]="service.navigationSelectValues"></yuno-forms-select>
								<div class="flex justify-end">
									<button
										yuno-admin-button
										color="danger"
										(click)="service.removePageItemButton(i)">
										remove
									</button>
								</div>
							</div>
						}
						<button
							yuno-admin-button
							color="primary"
							(click)="service.addPageItemButton()"
							[add]="true">
							Add Image Button
						</button>
					</ng-container>
				</form>
			}
		</section>
	}
}
