import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface PanoramaOffset {
	x: number;
	y: number;
	z: number;
	height: number;
	locX: number;
	locY: number;
}

@Injectable({
	providedIn: 'root'
})
export class ResizeService {
	private _offset = new Subject<PanoramaOffset>();
	offset$ = this._offset.asObservable();

	setOffset(offset: PanoramaOffset): void {
		this._offset.next(offset);
	}

	clearOffset(): void {
		this._offset.next({
			x: 0,
			y: 0,
			z: 0,
			height: 0,
			locX: 0,
			locY: 0
		});
	}
}
