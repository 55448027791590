import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';

@Component({
	selector: 'yuno-admin-language-selector',
	standalone: true,
	imports: [FormsModule, AsyncPipe],
	templateUrl: 'language.selector.component.html',
	styleUrls: ['language.selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminLanguageSelectorComponent implements OnDestroy {
	private appFacade = inject(AppFacade);
	private originalLanguage: string;
	languageSelected = 'nl';

	language$ = this.appFacade.language$.pipe(
		tap(value => {
			if (value && !this.originalLanguage) {
				this.originalLanguage = value as string;
				this.languageSelected = value as string;
			}
		})
	);

	$languages = toSignal(this.appFacade.languages$);

	changeLanguage(event: Event) {
		const value = (event.target as HTMLInputElement).value;
		this.languageSelected = value;
		this.appFacade.updateSettings({ language: value });
	}

	ngOnDestroy() {
		this.appFacade.updateSettings({ language: this.originalLanguage });
	}
}
