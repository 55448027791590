import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import * as dashboardEffects from './+state/dashboard.effects';
import { DashboardFacade } from './+state/dashboard.facade';
import { dashboardFeature } from './+state/dashboard.state';

@NgModule({
	declarations: [],
	imports: [CommonModule, MatDialogModule, MatSnackBarModule],
	providers: [provideState(dashboardFeature), provideEffects([dashboardEffects]), DashboardFacade]
})
export class DashboardDataAccessModule {}
