<router-outlet></router-outlet>
@if (toggleTemplates) {
	<yuno-admin-templates
		[selector]="'textfield'"
		(bgClicked)="toggleTemplates = false"
		(clicked)="onCreate($event)"></yuno-admin-templates>
}
<yuno-card>
	<section class="grid grid-cols-1" content>
		@if (data$ | async; as data) {
			<yuno-admin-table
				[selectable]="false"
				[draggable]="false"
				[filterable]="true"
				[sortable]="true"
				[pagination]="true"
				[pageOptions]="{
					pageSize: 25,
					pageSizeOptions: [5, 10, 25, 100],
					hidePageSize: false
				}"
				[data]="data.textfields"
				[buttons]="tableButtons"
				[buttonsWidth]="9"
				[columns]="[
					{ key: 'id', label: 'textfield' },
					{
						key: 'public',
						label: 'Client',
						type: 'masterToggle',
						masterToggle: masterToggle,
						hidden: disableUserAccess()
					},
					{ key: 'updatedBy', label: 'last modified by', child: 'displayName' },
					{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
				]"
				(changed)="onUpdatePublic($event)"
				(clicked)="onSelectTextfield($event)"
				(outputFilter)="toggles.filter = $event"
				(masterToggled)="onToggleAllPublic($event)">
				@if (minimalAppRole(userRoles.EDITOR)) {
					<button yuno-admin-button (click)="onSelectTemplate(true)" [add]="true">
						New Textfield
					</button>
				}
			</yuno-admin-table>
		}
	</section>
</yuno-card>

<ng-template #tableButtons let-row>
	<button yuno-admin-edit-button (click)="onSelectTextfield(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDeleteTextfield(row)"></button>
</ng-template>
