import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';

import { YunoSelectFieldModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { TextfieldInjectorComponent } from '@yuno/angular/textfield';
import { PagesComponents } from '@yuno/api/interface';
import { LoadersModule } from '@yuno/project-atlas/ui';

import { TextfieldDynamicComponent } from '../../textfields/editor/textfield-dynamic/textfield-dynamic.component';
import { ComponentList, TextfieldContentService } from '../textfield-content.service';

@Component({
	selector: 'yuno-admin-browse-items',
	standalone: true,
	imports: [
		LoadersModule,
		YunoSelectFieldModule,
		AsyncPipe,
		TextfieldDynamicComponent,
		TextfieldInjectorComponent
	],
	templateUrl: './browse-items.component.html',
	styleUrls: ['./browse-items.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowseItemsComponent extends AppDataComponent {
	@Input() editor: 'textfield' | 'page' = 'textfield';
	@Output() selected = new EventEmitter<{
		item: ComponentList;
		component?: PagesComponents;
	}>();

	readonly service = inject(TextfieldContentService);

	componentList: ComponentList[] = [
		{ key: 'textblock', label: 'Textblock' },
		{ key: 'custom', label: 'Custom Textblock' },
		{ key: 'button', label: 'Button' },
		{ key: 'link', label: 'Link' },
		{ key: 'image', label: 'Image' },
		{ key: 'video', label: 'Video' },
		{ key: 'news', label: 'News Item' },
		{ key: 'notification', label: 'Notification' }
	];
	textComponents: ComponentList[] = [
		{ key: 'toggles', label: 'Toggle' },
		{ key: 'list', label: 'List' }
	];
	pagesComponents: ComponentList[] = [{ key: 'item', label: 'Page Item' }];
	textfieldList = [...this.componentList, ...this.textComponents];
	pageList = [...this.componentList, ...this.pagesComponents];

	language$ = (this.route.snapshot.data['config']?.language as string) || 'nl';

	getList() {
		return this.editor === 'textfield' ? this.textfieldList : this.pageList;
	}
}
