import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Event } from '@yuno/api/interface';

import { eventsActions } from './events.actions';
import { eventsFeature } from './events.state';

export type EventKeyTypes = 'events' | 'activeEvents' | 'onClick' | 'onMouseMove' | 'onMouseLeave';

@Injectable()
export class EventsFacade {
	private readonly store = inject(Store);

	toggle$ = this.store.pipe(select(eventsFeature.selectToggle));
	data$ = this.store.pipe(select(eventsFeature.selectEvents));
	key$ = this.store.pipe(select(eventsFeature.selectKey));

	toggleEvents(bool: boolean) {
		this.store.dispatch(eventsActions.toggle({ bool }));
	}

	updateEvents(data: Event[]) {
		this.store.dispatch(eventsActions.update({ data }));
	}

	setKey(key: EventKeyTypes) {
		this.store.dispatch(eventsActions.key({ key }));
	}
}
