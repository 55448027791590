import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HtmlEditorImageLibraryComponent } from '@yuno/admin/features/html-form';
import {
	DragRowComponent,
	YunoAdminButtonComponent,
	YunoAdminCodeEditorModule,
	YunoCardModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { YunoFormsCodeEditorComponent, YunoFormsModule } from '@yuno/angular/forms';

import { EventFormsService } from '../data-access';
import { EventsFormsComponent } from './events.forms.component';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		YunoFormsModule,
		DragRowComponent,
		YunoEditContainerModule,
		YunoAdminButtonComponent,
		DragDropModule,
		YunoFormsCodeEditorComponent,
		YunoAdminCodeEditorModule,
		YunoCardModule,
		DragDropModule,
		HtmlEditorImageLibraryComponent,
		EventsFormsComponent
	],
	providers: [EventFormsService],
	exports: [EventsFormsComponent]
})
export class EventsModule {}
