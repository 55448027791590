import { gql } from 'apollo-angular';

export const DELETE_PANO = gql`
	mutation deletePanoramaById($_id: String!) {
		deletePanorama(_id: $_id)
	}
`;

export type DeletePanoramaMutation = {
	deletePanorama: string;
};
