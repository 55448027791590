@if (minimalAppRole(userRoles.EDITOR)) {
	<div class="flex w-full flex-row flex-wrap justify-end gap-4">
		<yuno-admin-ui-selector-button (click)="uploadGeojson(appId)">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				stroke="currentColor"
				stroke-width="1.5"
				class="mr-1 h-5 w-5"
				viewBox="0 0 24 24">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
			</svg>

			GeoJSON
		</yuno-admin-ui-selector-button>
		<yuno-admin-ui-selector-button (click)="downloadGeojson(appId)">
			<svg viewBox="0 0 24 24" class="mr-1 h-5 w-5" stroke-width="1.5" stroke="currentColor">
				<use href="#download-icon" />
			</svg>
			GeoJSON
		</yuno-admin-ui-selector-button>
	</div>
}
