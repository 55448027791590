@if (service.layers$ | async) {
	@if (form) {
		<div class="flex flex-col gap-4">
			<yuno-admin-annotation>
				<p>These properties will be merged with the current Layer Style.</p>
				<p>
					When you overwrite a property here, it will be used instead of the original
					style.
				</p>
				<p>
					When you add a new property it will be included. You cannot remove original
					layer values.
				</p>
				<p>
					Later we plan to support the complete overwrite of a Layer Style. And make the
					original style visible in the editor. This is just the first mvp to help with
					IQIP.
				</p>
			</yuno-admin-annotation>
			<form [formGroup]="form">
				<div formGroupName="options" class="grid grid-cols-1 gap-4">
					<yuno-forms-select
						formControlName="id"
						label="Layer to change Style"
						[display]="service.layersDisplay"
						[selectValues]="service.layersValues"
						[customProperties]="true"
						placeholder="Select a Layer..."></yuno-forms-select>
				</div>
			</form>
			<section>
				<yuno-forms-span class="font-semibold">Layout Properties</yuno-forms-span>
				<yuno-code-editor
					[readOnly]="false"
					[control]="layoutControl"
					(changed)="updateControlForm($event, 'layout')"></yuno-code-editor>
			</section>
			<section>
				<yuno-forms-span class="font-semibold">Paint Properties</yuno-forms-span>
				<yuno-code-editor
					[readOnly]="false"
					[control]="paintControl"
					(changed)="updateControlForm($event, 'paint')"></yuno-code-editor>
			</section>
		</div>
	}
}
