import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, take } from 'rxjs';

import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { ConfigQuery, GET_CONFIG_BY_APPID } from '../../utils';
import { appConfigActions } from './config.actions';

export const loadConfig = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(appConfigActions.load),
			switchMap(data =>
				graphql
					.query<ConfigQuery>({
						query: GET_CONFIG_BY_APPID,
						variables: {
							appId: data.appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.config) {
								throw new Error('no Settings found');
							}
							return appConfigActions.loadSuccess({
								data: data.data.config
							});
						}),
						take(1),
						catchError(error => of(appConfigActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateConfig = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(appConfigActions.update),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating App Configuration!`, 'error');
					throw new Error('no Config found');
				}
				return appConfigActions.updateSuccess({
					data: data.data
				});
			}),
			catchError(error => of(appConfigActions.updateFailure({ error })))
		),
	{ functional: true }
);
