import { gql } from 'apollo-angular';

export const DELETE_IMAGE_BUTTON = gql`
	mutation deleteImageButton($_id: String!) {
		mediaImageButtonDelete(_id: $_id)
	}
`;

export type DeleteImageButtonMutation = {
	mediaImageButtonDelete: string;
};
