import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, startWith, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { EventsFacade, EventsModule } from '@yuno/admin/features/events';
import { ImageButtonFacade } from '@yuno/admin/features/media';
import { PagesFacade } from '@yuno/admin/features/textfield-pages';
import { ThemeFacade } from '@yuno/admin/features/themes';
import {
	AnnotationComponent,
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminTableComponent,
	YunoCardModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import { YunoFormsModule } from '@yuno/angular/forms';
import { DialogItem, MessageDialogComponent, MessageService } from '@yuno/angular/notifications';
import { Event, LanguageAll } from '@yuno/api/interface';

import { NavigationEditorService } from '../../internal-view/navigations.service';

@Component({
	selector: 'yuno-admin-navigation-dropdown-editor',
	standalone: true,
	imports: [
		CommonModule,
		JsonFormsModule,
		ReactiveFormsModule,
		YunoEditContainerModule,
		YunoCardModule,
		YunoFormsModule,
		YunoAdminButtonsModule,
		YunoAdminTableComponent,
		EventsModule,
		AnnotationComponent,
		EditContainerContentDirective
	],
	templateUrl: './navigation-dropdown-editor.component.html',
	styleUrls: ['./navigation-dropdown-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationDropdownEditorComponent extends AppDataComponent implements OnInit {
	private readonly pagesFacade = inject(PagesFacade);
	private readonly themesFacade = inject(ThemeFacade);
	private readonly imageButtonFacade = inject(ImageButtonFacade);
	private readonly eventsFacade = inject(EventsFacade);
	private readonly appFacade = inject(AppFacade);
	private readonly dialog = inject(MatDialog);
	private dialogRef: MatDialogRef<MessageDialogComponent>;

	readonly service = inject(NavigationEditorService);
	readonly message = inject(MessageService);

	languages = LanguageAll;
	dropdownEvents: boolean;
	disableClose = false;
	originLink: string;
	linkChanged = false;

	data$ = combineLatest({
		pages: this.pagesFacade.pages$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.pageValues = data.map(object => 'page/' + object.id) as string[];
					this.service.pageDisplay = data.map(object => object.id) as string[];
				}
			})
		),
		themes: this.themesFacade.themes$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.themeValues = data.map(object => object._id) as string[];
					this.service.themeDisplay = data.map(object => object.id) as string[];
				}
			})
		),
		imageButtons: this.imageButtonFacade.imageButtons$.pipe(
			tap(data => {
				this.service.imgBtnValues = ['', ...(data.map(object => object._id) as string[])];
				this.service.imgBtnDisplay = [
					'none',
					...(data.map(object => object.id) as string[])
				];
			})
		),
		toggle: this.eventsFacade.toggle$.pipe(
			startWith(false),
			tap(toggle => {
				if (!toggle) {
					this.dropdownEvents = false;
					setTimeout(() => {
						this.disableClose = false;
					}, 500);
				}
			})
		),
		language: this.appFacade.language$.pipe(startWith('nl'))
	});

	linkToggle: 'map' | 'page' = 'map';

	@Output() closed = new EventEmitter<boolean>();

	ngOnInit(): void {
		this.pagesFacade.get();
		this.themesFacade.get();
		this.imageButtonFacade.getImageButtons();
		if (!this.service.navItem) {
			this.onClose();
		}
		if (this.service.navItem) {
			const link = this.service.navItem.controls.link.value;
			this.originLink = link;
			if (link && link.includes('page/')) {
				this.linkToggle = 'page';
			}
		}
		this.languageSelector();
	}

	onClose() {
		if (!this.linkChanged) {
			this.doClose();
		} else {
			const data: DialogItem = {
				title: 'Warning',
				message:
					'<p>Changing the link can <strong>break the connection</strong> of certain events, <br> make sure to check and relink those <strong>events</strong>/<strong>textfield-links.</p>',
				buttons: [
					{
						key: 'Confirm',
						type: 'success',
						confirm: true
					},
					{
						key: 'Cancel'
					}
				],
				confirm: 'Confirmed'
			};

			this.dialogRef = this.dialog.open(MessageDialogComponent, {
				data: data
			});
			this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.doClose();
					this.message.sendToast(
						`Link changed, relink your events and textfield-links!`,
						'warning'
					);
				}
			});
		}
	}

	/* 	navigates back to the Dataset page */
	doClose(): void {
		!this.disableClose && this.closed.next(true);
	}

	onToggleEvents() {
		const events: Event[] = this.service.navDropdownEvents.getRawValue() as Event[];
		this.eventsFacade.toggleEvents(true);
		this.eventsFacade.setKey('events');
		this.eventsFacade.updateEvents(events);
		this.dropdownEvents = true;
		this.disableClose = true;
	}

	onLinkChange(val: string) {
		if (!this.originLink) {
			return;
		}
		this.linkChanged = this.originLink !== val;
	}
}
