import { GLOBAL_ENV } from './types';

const version = require('./../package.json').version;

declare let require: any;

const stage: GLOBAL_ENV['yuno-stage'] = 'local';
const mainUrl = `projectatlas.app`;

export const globalEnvironment: GLOBAL_ENV = {
	'yuno-production': false,
	'yuno-version': version,
	'yuno-stage': stage,
	'yuno-main': mainUrl,
	'yuno-main-url': `https://${mainUrl}`,
	'yuno-api': `/api`,
	// 'yuno-api': `http://${window.location.hostname}/api`,
	'yuno-graphql': `http://127.0.0.1:3333/api/graphql`,
	// 'yuno-api': `https://projectatlas.app/api`,
	// 'yuno-graphql': `https://projectatlas.app/api/graphql`,
	// 'yuno-cdn': `https://cdn.${mainUrl}`,
	// 'yuno-cdn-api': `https://cdn.${mainUrl}/api`,
	'yuno-cdn': `http://localhost:3637`,
	'yuno-cdn-api': `http://localhost:3637`,
	// 'yuno-cdn-api-storage': `http://localhost:3637`,
	'yuno-cdn-api-storage': `https://cdn.projectatlas.app/api/storage`,
	'yuno-matomo': `https://matomo.${mainUrl}`,
	'yuno-tileserver': `https://tiles.${mainUrl}`,
	'yuno-tilegenerator': `https://tiles.${mainUrl}/generator`,
	'yuno-tools-url': `https://yunotools.projectatlas.app`,
	// 'yuno-tileserver': `http://localhost:4321`,
	// 'yuno-tilegenerator': `http://localhost:5051`,
	'yuno-arcgis-api-token':
		'AAPKa23e49b69b4e4fac80c0dd1d5795fb279N_0qTKAO1IRJ1q0xjDIekYQ3qBQ0A3iXNPn9tRxbYlTnLw8emd9bUIpVtgex85X'
};
