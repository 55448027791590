<ng-container [formGroup]="form">
	<div class="relative">
		<span class="text-sm font-semibold">
			{{ item.label }}
		</span>
		<div class="grid items-start gap-4" [ngClass]="columns()">
			@if (control) {
				@for (groupItem of item.controls; track groupItem) {
					@switch (groupItem.controlType) {
						@case ('button') {
							@if (asButton(groupItem); as buttonItem) {
								<yuno-json-forms-types-button [item]="buttonItem">
								</yuno-json-forms-types-button>
							}
						}
						@case ('label') {
							@if (asLabel(groupItem); as labelItem) {
								<yuno-json-forms-types-label [item]="labelItem">
								</yuno-json-forms-types-label>
							}
						}
						@case ('divider') {
							<yuno-json-forms-types-divider> </yuno-json-forms-types-divider>
						}
						@case ('array') {
							@if (asArray(groupItem); as arrayControlItem) {
								<yuno-json-forms-types-array
									[formValue]="formValue?.[arrayControlItem.key]"
									[form]="control"
									[item]="arrayControlItem"
									[control]="arrayControlItem.control">
								</yuno-json-forms-types-array>
							}
						}
						@case ('group') {
							@if (asGroup(groupItem); as groupControlItem) {
								<yuno-json-forms-types-group
									[formValue]="formValue?.[groupControlItem.key]"
									[form]="control"
									[item]="groupControlItem"
									[control]="groupControlItem.control">
								</yuno-json-forms-types-group>
							}
						}
						@case ('control') {
							@if (asFormControl(groupItem); as formControlItem) {
								<yuno-json-forms-types-control
									[form]="control"
									[item]="formControlItem"
									[control]="formControlItem.control">
								</yuno-json-forms-types-control>
							}
						}
						@case ('language') {
							@if (asFormLanguage(groupItem); as formLanguageItem) {
								<yuno-json-forms-language
									[form]="control"
									[item]="formLanguageItem"
									[control]="formLanguageItem.control">
								</yuno-json-forms-language>
							}
						}
					}
				}
			}
		</div>
	</div>
</ng-container>
