import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { TextLink } from '@yuno/api/interface';

import { textLinksActions } from './text-link.actions';

export interface TextLinkState {
	links: Partial<TextLink>[];
	status: Status;
}

export const initialStateTextLinks: TextLinkState = {
	links: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateTextLinks,
	on(appActions.reset, () => initialStateTextLinks),
	on(textLinksActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(textLinksActions.loadSuccess, (state, { data }) => ({
		...state,
		links: data,
		status: Status.SUCCESS
	})),
	on(textLinksActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	}))
);

export const textLinksFeature = createFeature({
	name: 'textLinks',
	reducer
});
