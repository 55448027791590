import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';

import { Sprites } from '@yuno/api/interface';

@Component({
	selector: 'yuno-admin-sprite-list',
	templateUrl: './sprite-list.component.html',
	styleUrls: ['./sprite-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpriteListComponent {
	@Input() sprites: Sprites['files'] | Sprites['sdf'];
	@Input() title: string;
	@Input() sdf = false;
	@Input() @HostBinding('class.editable') editable = true;
	@Output() edit: EventEmitter<string> = new EventEmitter<string>();

	/**
	 * Returns the name of the Sprite itself
	 *
	 * @param {string} str
	 * @return {*}  {string}
	 */
	public getSpriteName(str: string) {
		const url = str.split('/').pop() as string;
		return url.split('.')[0];
	}

	public editSprite(sprite: string) {
		this.edit.emit(sprite);
	}
}
