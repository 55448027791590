@if (language$ | async) {
	<ng-container [formGroup]="form">
		<div class="relative">
			@if (item.label || item.labelDescr) {
				<section class="flex flex-col gap-2">
					@if (item.label) {
						<span class="text-sm font-semibold">{{ item.label }}</span>
					}
					@if (item.labelDescr) {
						<span class="mb-4 text-sm">{{ item.labelDescr }}</span>
					}
				</section>
			}
			<div class="grid items-start gap-4" [ngClass]="columns()">
				@if (item.options?.control && control) {
					@if (asFormControl(item.options!.control!); as formControlItem) {
						@if (getFormControlItem(); as item) {
							<yuno-json-forms-types-control
								[form]="control"
								[item]="item"
								[control]="getLanguageControl">
							</yuno-json-forms-types-control>
						}
					}
				}
			</div>
		</div>
	</ng-container>
}
