import { ChangeDetectionStrategy, Component } from '@angular/core';

import { YunoAdminCardComponent } from '@yuno/admin/ui';

@Component({
	selector: 'yuno-card-modal',
	standalone: true,
	imports: [YunoAdminCardComponent],
	template: `
		<div
			class="pointer-events-auto flex h-screen w-screen flex-col items-center justify-center bg-yuno-gray-500/70 backdrop-blur-xs">
			<yuno-card
				class="h-auto max-w-screen-sm border border-gray-200"
				[white]="true"
				[relativeButtons]="true">
				<ng-content title select="[title]"></ng-content>
				<ng-content subTitle select="[subTitle]"></ng-content>
				<ng-content content select="[content]"></ng-content>
				<ng-content buttons select="[buttons]"></ng-content>
			</yuno-card>
		</div>
	`,
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminCardModalComponent {}
