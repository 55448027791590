@if (form) {
	<form [formGroup]="form">
		<div formGroupName="modules">
			<div formGroupName="participation" class="grid gap-2">
				<yuno-forms-title>Participation</yuno-forms-title>
				<yuno-forms-toggle formControlName="button" label="Button"></yuno-forms-toggle>
				<!-- LANGUAGE -->
				<section formGroupName="startTitle">
					@for (lang of languages; track lang) {
						@if (lang === this.language) {
							<yuno-forms-text
								[language]="lang"
								[formControlName]="lang"
								label="Title"></yuno-forms-text>
						}
					}
				</section>
				<!-- LANGUAGE -->
				<section formGroupName="buttonText">
					@for (lang of languages; track lang) {
						@if (lang === this.language) {
							<yuno-forms-text
								[language]="lang"
								[formControlName]="lang"
								label="Button text"></yuno-forms-text>
						}
					}
				</section>
				<!-- LANGUAGE -->
				<section formGroupName="startText">
					@for (lang of languages; track lang) {
						@if (lang === this.language) {
							<yuno-forms-textarea
								[language]="lang"
								[formControlName]="lang"
								[rows]="8"
								label="Start text (used only when multiple participate are added)"></yuno-forms-textarea>
						}
					}
				</section>
				<!-- LANGUAGE -->
				<section formGroupName="dataTitle">
					@for (lang of languages; track lang) {
						@if (lang === this.language) {
							<yuno-forms-text
								[language]="lang"
								[formControlName]="lang"
								label="List Title (used only when multiple participate are added)"></yuno-forms-text>
						}
					}
				</section>
			</div>
		</div>
		<div
			cdkDropList
			(cdkDropListDropped)="drop($event)"
			formGroupName="modules"
			class="mb-2 mt-6">
			<h5>Items</h5>
			<div formGroupName="participation">
				<div formGroupName="data" class="flex flex-col gap-2">
					@for (item of service.themeParticipation.controls; track item; let i = $index) {
						<yuno-admin-drag-row
							[keepButtons]="true"
							cdkDrag
							cdkDragLockAxis="y"
							[formGroupName]="i">
							<div class="flex flex-1 flex-row items-center justify-between" title>
								<yuno-forms-select
									formControlName="data"
									class="flex-1 text-gray-500"
									placeholder="Choose Participation..."
									[selectValues]="service.participateValues"
									[display]="service.participateDisplay"></yuno-forms-select>
							</div>
							<ng-container buttons>
								<button
									yuno-admin-delete-admin-button
									(click)="removeItem(i)"></button>
							</ng-container>
						</yuno-admin-drag-row>
					}
				</div>
			</div>
		</div>
		<button yuno-admin-add-button class="self-end" (click)="addItem()">
			Add Participation
		</button>
	</form>
}
