import { gql } from 'apollo-angular';

import { Theme } from '@yuno/api/interface';

export const SAVE_THEME = gql`
	mutation saveTheme($theme: ThemeInput!, $appId: String!) {
		saveTheme(theme: $theme, appId: $appId) {
			_id
			id
			datasets {
				_id
				id
			}
			transition
			keepView
			controls {
				breadcrumbs {
					active
				}
				backToOverview {
					active
				}
				controlbar {
					location
					zoom
					measure
					mapStyles
				}
				mapscale
			}
			states {
				default {
					_id
					state
				}
				available {
					defaultText
					events
					state {
						_id
						state
					}
				}
			}
			modules {
				measure {
					color
				}
				searchAdress {
					active
					country
				}
				disclaimer {
					active
					when
					textblock {
						_id
						id
					}
				}
				panoramas {
					vr
					popup
					calibrate
					splitscreen {
						active
						keepView
						dial
					}
					threejs {
						active
						range
					}
					hotspots {
						active
						range
						type
					}
				}
				participation {
					button
					buttonText
					startTitle
					startText
					dataTitle
					showData
					data {
						data
					}
				}
			}
			mapStyles {
				style {
					_id
					id
				}
				description
			}
			view {
				bounds
				maxBounds
				minZoom
				zoom
				maxZoom
				minPitch
				pitch
				maxPitch
				bearing
				boxZoom
				doubleClickZoom
				interactive
				dragPan
				dragRotate
				keyboard
				scrollZoom
				touchZoom
				touchPitch
				touchRotate
				panoramaPopup
				clustering {
					mp {
						active
						minZoom
					}
				}
			}
		}
	}
`;

export const DUPLICATE_THEME = gql`
	mutation duplicateTheme($_id: String!, $appId: String!) {
		duplicateTheme(_id: $_id, appId: $appId) {
			_id
			id
			datasets {
				_id
				id
			}
			keepView
			transition
			states {
				default {
					_id
					state
				}
				available {
					defaultText
					events
					state {
						_id
						state
					}
				}
			}
			modules {
				participation {
					button
				}
				panoramas {
					threejs {
						active
					}
				}
			}
			mapStyles {
				style {
					_id
					id
				}
				description
			}
			view {
				bounds
				maxBounds
				minZoom
				zoom
				maxZoom
				minPitch
				pitch
				maxPitch
				bearing
				boxZoom
				doubleClickZoom
				interactive
				dragPan
				dragRotate
				keyboard
				scrollZoom
				touchZoom
				touchPitch
				touchRotate
				panoramaPopup
				clustering {
					mp {
						active
						minZoom
					}
				}
			}
		}
	}
`;

export type SaveThemeMutation = {
	saveTheme: Partial<Theme>;
};

export type DuplicateThemeMutation = {
	duplicateTheme: Partial<Theme>;
};
