import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Textfield, TextfieldComponents } from '@yuno/api/interface';

export const textfieldsActions = createActionGroup({
	source: 'Textfields',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<Textfield>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),
		loadFiltered: props<{ filter: string }>(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<Textfield> }>(),
		selectFailure: props<{ error: Error }>(),
		setTemplate: props<{ data: TextfieldComponents[] }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<Textfield> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<Textfield> }>(),
		updateSelectSuccess: props<{ data: Partial<Textfield> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		updateTextfield: props<{ data: Partial<Textfield>; index: number }>(),
		updateTextfieldSuccess: props<{ bool: boolean | undefined; index: number }>(),
		updateTextfieldFailure: props<{ error: Error }>(),

		updateAllTextfields: props<{ bool: boolean; filter: string }>(),
		updateAllTextfieldsSuccess: props<{ bool: boolean; filter: string }>(),
		updateAllTextfieldsFailure: props<{ error: Error }>()
	}
});
