<ng-container [formGroup]="form">
	<label class="yuno-form">
		@if (item.label) {
			<span
				class="select-none"
				[innerHtml]="
					item.label
						| languageSelect: language
						| safeHtml: item.validators?.required || item.required
				">
			</span>
		}
		<input
			[name]="item.key"
			[type]="item.type"
			[placeholder]="item.placeholder | languageSelect: language || ''"
			[value]="item.value"
			[min]="item.options?.min || '2010-01-01'"
			[max]="item.options?.max || '2100-01-01'"
			[formControl]="control" />
	</label>
</ng-container>
