import { gql } from 'apollo-angular';

export const SAVE_NAVIGATION = gql`
	mutation saveNavigation($navigation: NavigationInput!, $appId: String!) {
		saveNavigation(navigation: $navigation, appId: $appId) {
			_id
			navigation {
				display
				theme {
					_id
				}
				dropdown {
					item {
						_id
					}
					theme {
						_id
					}
					link
					text
					public
					color
					display
					events {
						type
						options
					}
				}
				link
				public
				events {
					type
					options
				}
			}
		}
	}
`;
