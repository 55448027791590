import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Source } from '@yuno/api/interface';

export const sourcesActions = createActionGroup({
	source: 'Sources',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<Source>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		selectSuccess: props<{ data: Partial<Source> }>(),
		selectFailure: props<{ error: Error }>(),
		clearSelect: emptyProps(),

		sourceLayers: props<{ url: string }>(),
		sourceLayersByName: props<{ id: string }>(),
		sourceLayersById: props<{ id: string }>(),
		sourceLayersSuccess: props<{ data: string[] }>(),
		sourceLayersFailure: props<{ error: Error }>(),
		clearSourceLayers: emptyProps(),

		updateSelect: props<{ data: Partial<Source> }>(),
		updateSelectSuccess: props<{ data: Partial<Source> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: props<{ _id: string }>(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<Source> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>()
	}
});
