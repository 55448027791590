@if (service$ | async; as service) {
	@if (service.component) {
		<form [formGroup]="service.component" class="flex flex-col gap-4">
			<section>
				<yuno-forms-title>Toggles Editor</yuno-forms-title>
				<yuno-forms-span
					>You can edit the selected toggles component below.
				</yuno-forms-span>
			</section>
			<yuno-forms-text
				class="flex-1"
				formControlName="id"
				placeholder="toggle name"
				label="Toggle component name"></yuno-forms-text>
			<yuno-forms-divider></yuno-forms-divider>
			<section class="flex flex-row gap-4">
				<yuno-forms-toggle
					formControlName="listLike"
					label="Display as List"></yuno-forms-toggle>
				<yuno-forms-toggle formControlName="addColor" label="Add Color"></yuno-forms-toggle>
			</section>
			@if (editService.data$ | async; as data) {
				<section formGroupName="title">
					@for (lang of editService.languages; track lang) {
						@if (lang === data.language) {
							<yuno-forms-text
								[formControlName]="lang"
								[language]="lang"
								placeholder="Toggle title"
								label="Toggle title (optional)"></yuno-forms-text>
						}
					}
				</section>
			}

			@if (service.component.value?.listLike) {
				<section class="flex flex-col gap-4">
					<section formGroupName="active" class="flex gap-2">
						<yuno-forms-color
							formControlName="color"
							placeholder="#fff"
							label="Active color"></yuno-forms-color>
						<yuno-forms-color
							formControlName="backgroundColor"
							placeholder="#00ADF1"
							label="Active background color"></yuno-forms-color>
					</section>
				</section>
			}
			<yuno-forms-divider></yuno-forms-divider>
			<section
				formArrayName="items"
				cdkDropList
				[cdkDropListData]="service.toggleItems"
				(cdkDropListDropped)="editService.dropToggleItems($event)">
				<label class="yuno-form">
					<span class="select-none">Items</span>
				</label>
				@for (item of service.toggleItems.controls; track item; let i = $index) {
					<div
						cdkDrag
						[formGroupName]="i"
						class="mb-2 flex flex-col gap-2 rounded border border-gray-300 bg-gray-100 p-2">
						<yuno-admin-toggle-item [form]="item" [index]="i">
							<div class="m-auto mx-4 cursor-move pr-4" cdkDragHandle>
								<svg
									viewBox="0 0 16 24"
									class="h-8 w-4"
									fill="#9ca3af"
									border="#9ca3af">
									<use href="#drag-icon" />
								</svg>
							</div>
						</yuno-admin-toggle-item>
					</div>
				}
				<button yuno-admin-button (click)="service.addToggleItem()" [add]="true">
					Add Item
				</button>
			</section>
		</form>
	}
}
