import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Navigation } from '@yuno/api/interface';

import { navigationActions } from './navigations.actions';

export interface NavigationsState {
	loaded: boolean;
	navigation: Partial<Navigation> | undefined;
	status: Status;
}

export const initialState: NavigationsState = {
	loaded: false,
	navigation: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(navigationActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(navigationActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		navigation: data,
		status: Status.SUCCESS
	})),
	on(navigationActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(navigationActions.saveSuccess, state => ({
		...state
	})),

	// Update
	on(navigationActions.update, (state, { navigation }) => ({
		...state,
		navigation: { ...state.navigation, ...navigation }
	})),
	on(navigationActions.updateFailure, (state, { error }) => ({ ...state, error }))
);

export const navigationFeature = createFeature({
	name: 'navigation',
	reducer,
	extraSelectors: ({ selectNavigation }) => ({
		navigationId: createSelector(
			selectNavigation,
			(state: NavigationsState['navigation']) => state?._id
		)
	})
});
