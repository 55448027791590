import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { CustomSource } from '@yuno/api/interface';

import { sourcesCustomActions } from './sources-custom.actions';
import { sourcesCustomFeature } from './sources-custom.state';

@Injectable()
export class SourcesCustomFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(sourcesCustomFeature.selectLoaded));
	customSources$ = this.store.pipe(select(sourcesCustomFeature.selectCustomSources));
	selected$ = this.store.pipe(select(sourcesCustomFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(sourcesCustomFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(sourcesCustomFeature.selectStatus));

	get() {
		this.store.dispatch(sourcesCustomActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(sourcesCustomActions.select({ _id }));
	}

	updateSelect(data: Partial<CustomSource>) {
		this.store.dispatch(sourcesCustomActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(sourcesCustomActions.clearSelect());
	}

	save() {
		this.store.dispatch(sourcesCustomActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(sourcesCustomActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(sourcesCustomActions.delete({ _id }));
	}
}
