<div class="mb-4 flex flex-col">
	@if (data$ | async; as data) {
		@if (data.mapStyle) {
			<div class="h-96 w-full">
				<mgl-map
					(styleImageMissing)="mapOnMissingImage($event)"
					class="z-0 h-full w-full"
					[style]="data.mapStyle"
					[fitBounds]="data.bounds.fitBounds || getMapBounds()"
					[fitBoundsOptions]="{ animate: false }">
					@if (data.panorama && data.panorama.map?.coordinates) {
						<mgl-marker [lngLat]="getLngLat(data.panorama.map?.coordinates)">
						</mgl-marker>
					}
				</mgl-map>
			</div>
		}
	}
</div>
