@if (data$ | async; as data) {
	@if (addSprite) {
		<yuno-edit-container [col]="4" [pos]="3" [span]="2" [style.zIndex]="210">
			<ng-container buttons>
				<button
					yuno-admin-button
					color="success"
					(click)="onSaveSprite(data.client?._id || '')">
					Save
				</button>
				<button yuno-admin-close-button (click)="addSprite = false"></button>
			</ng-container>
			<div edit-container-content>
				<div class="mb-8">
					<input
						id="inputfile"
						type="file"
						accept=".svg"
						(change)="sprites.onFileChange($event)"
						[multiple]="true" />
					@if (success) {
						<div
							class="my-4 flex flex-col gap-2 rounded-md border border-green-500 bg-green-50 p-4 md:p-6">
							<div class="text-xl font-semibold">Icon successfully uploaded!</div>
							<div>
								If you've added a new icon, you might need to refresh the page to
								see your new icon.
							</div>
						</div>
					}
					<h4 class="ui divider"></h4>
					<img src="{{ environment['yuno-cdn'] }}/sprites/svg-export-settings.png" />
				</div>
			</div>
		</yuno-edit-container>
	}

	<yuno-edit-container
		[col]="4"
		[pos]="1"
		[span]="2"
		[tabs]="['Settings', 'Spritesheet']"
		(outputTab)="onSwitchTab($event.key)">
		<ng-container buttons>
			<button yuno-admin-button color="success" (click)="onSave()">Save</button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>
		<div edit-container-content [hasTabs]="true">
			@if (tabValue === 'Settings') {
				@if (service.form) {
					<form [formGroup]="service.form">
						<div class="flex flex-col gap-8">
							<yuno-forms-text
								class="flex-1"
								formControlName="id"
								label="Client name"
								placeholder="client name"></yuno-forms-text>
							<yuno-forms-text
								class="flex-1"
								formControlName="path"
								label="Path"
								placeholder="path name"></yuno-forms-text>
						</div>
						<yuno-forms-divider></yuno-forms-divider>
						<yuno-forms-title>URL</yuno-forms-title>
						<yuno-forms-span class="my-2">
							these are the subdomains given to all atlasses with this client. e.g.
							providing an url with xkp will give the domain xkp.projectatlas.app/
						</yuno-forms-span>
						<div formArrayName="url" class="my-2 grid grid-cols-1 gap-1">
							@for (alias of service.urls.controls; track alias; let i = $index) {
								<div
									class="flex w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-300 bg-gray-200 p-2">
									<yuno-forms-text
										class="flex-1"
										[formControlName]="i"
										placeholder="insert url"></yuno-forms-text>
									<ng-container buttons>
										<button
											yuno-admin-button
											color="danger"
											(click)="service.removeUrl(i)">
											Remove
										</button>
									</ng-container>
								</div>
							}
						</div>
						<button yuno-admin-button [add]="true" (click)="service.addUrl()">
							Add Url
						</button>
					</form>
				}
			}
			@if (tabValue === 'Colors') {}
			@if (tabValue === 'Spritesheet') {
				@if (originalData._id) {
					<yuno-admin-spritesheet-manager
						[clientId]="originalData._id"
						(addSprite)="addSprite = true"></yuno-admin-spritesheet-manager>
				}
			}
		</div>
	</yuno-edit-container>
}
