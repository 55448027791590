@if (data.dropdown) {
	<section
		class="mb-1 inline-flex w-full items-center gap-2 rounded-lg bg-yuno-gray-100/50 p-2 px-4 hover:cursor-pointer"
		(click)="dropdownActive = !dropdownActive">
		<span class="font-semibold text-yuno-blue-gray-800 empty:hidden">
			{{ data.title | languageSelect: language }}
		</span>

		<div class="hidden flex-1 flex-row justify-end md:flex">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-4 w-4"
				[ngClass]="{ 'scale-y-[-1] transform': dropdownActive }"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor">
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M19 9l-7 7-7-7" />
			</svg>
		</div>
	</section>
	@if (dropdownActive) {
		<section class="flex flex-col gap-2 rounded-lg border border-yuno-gray-100/50 p-4">
			@for (style of data.category.styles; track style) {
				<label class="yuno-form toggle">
					@if (!data.hide && style.style) {
						@if (style.style.type === 'icon') {
							<!--svg icon-->
							<img class="mr-6 h-6 w-6" [src]="returnSvg(style.style)" />
						}
						@if (style.style.type === 'label') {
							<!--label icon-->
							<svg
								class="mr-6 h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 32 32">
								<path
									[style]="{ fill: returnColor(style.style) }"
									d="M11.634 24.713C6.485 22.89 3 18.139 3 12.673 3 5.703 8.703 0 15.673 0s12.673 5.703 12.673 12.673c0 5.07-3.089 9.743-7.762 11.723L15.99 32l-4.356-7.287Z" />
								<path fill="#fff" d="M12 12.97 15.97 9l3.97 3.97-3.97 3.97z" />
							</svg>
						}
					}

					@if (style.id) {
						<div class="flex-1 font-semibold capitalize text-yuno-blue-gray-700">
							{{ style.id }}
						</div>
					}
					<input
						class="toggle my-auto"
						type="checkbox"
						[ngModel]="isActive(style.id)"
						(ngModelChange)="toggleChange($event, style.id)" />
				</label>
			}
		</section>
	}
}

@if (!data.dropdown) {
	<section class="flex flex-col gap-2 rounded-lg bg-yuno-gray-100/50 p-4">
		<span class="font-semibold leading-6 text-yuno-blue-gray-800 empty:hidden">{{
			data.title | languageSelect: language
		}}</span>
		@for (style of data.category.styles; track style) {
			<label class="yuno-form toggle">
				@if (!data.hide && style.style) {
					@if (style.style.type === 'icon') {
						<!--svg icon-->
						<img class="mr-6 h-6 w-6" [src]="returnSvg(style.style)" />
					}
					@if (style.style.type === 'label') {
						<!--label icon-->
						<svg
							class="mr-6 h-6 w-6"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 32 32">
							<path
								[style]="{ fill: returnColor(style.style) }"
								d="M11.634 24.713C6.485 22.89 3 18.139 3 12.673 3 5.703 8.703 0 15.673 0s12.673 5.703 12.673 12.673c0 5.07-3.089 9.743-7.762 11.723L15.99 32l-4.356-7.287Z" />
							<path fill="#fff" d="M12 12.97 15.97 9l3.97 3.97-3.97 3.97z" />
						</svg>
					}
				}

				@if (style.id) {
					<!--<span>{{ data.id | languageSelect: language }}</span>-->
					<div class="flex-1 font-semibold capitalize text-yuno-blue-gray-700">
						{{ style.id }}
					</div>
				}
				<input
					class="toggle my-auto"
					type="checkbox"
					[ngModel]="isActive(style.id)"
					(ngModelChange)="toggleChange($event, style.id)" />
			</label>
		}
	</section>
}
