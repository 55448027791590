import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { MediaImageButton } from '@yuno/api/interface';

import { imageButtonsActions } from './image-button.actions';

export interface ImageButtonState {
	loaded: boolean;
	imageButtons: Partial<MediaImageButton>[];
	selectedLoaded: boolean;
	selected: Partial<MediaImageButton> | undefined;
	status: Status;
}

export const initialState: ImageButtonState = {
	loaded: false,
	imageButtons: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(imageButtonsActions.load, state => ({
		...state,
		loaded: false,
		status: Status.VALIDATING
	})),
	on(imageButtonsActions.loadSuccess, (state, { data }) => ({
		...state,
		imageButtons: data,
		loaded: true,
		status: Status.SUCCESS
	})),
	on(imageButtonsActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	})),

	on(imageButtonsActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(imageButtonsActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(imageButtonsActions.selectFailure, () => ({
		...initialState
	})),
	on(imageButtonsActions.updateSelect, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(imageButtonsActions.saveSuccess, state => {
		const index = state.imageButtons.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const buttons = [...state.imageButtons];
		if (index >= 0) {
			buttons[index] = { ...buttons[index], ...state.selected };
		} else if (state.selected) {
			buttons.push({ ...state.selected });
		}
		// buttons.sort((a, b) => ((a.id as string) > (b.id as string) ? 1 : -1));
		return {
			...state,
			imageButtons: buttons
		};
	})
);

export const imageButtonsFeature = createFeature({
	name: 'imageButtons',
	reducer
});
