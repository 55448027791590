import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
	HttpParams,
	HttpResponse
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { catchError, lastValueFrom, map, throwError } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { ApiService } from '@yuno/angular/api';
import { CdnFile } from '@yuno/api/interface';

export class CdnData {
	appName: string;
	client: string;
}

export interface UploadCdnFile {
	success: boolean;
	message: string;
	data: {
		url: string;
		path: string;
		fileName: string;
		ext: string;
		sizes: [number];
		originalSize: number;
		maxSize: number;
	};
}

@Injectable({
	providedIn: 'root'
})
export class CdnFileUploadService {
	readonly environment = inject(ENVIRONMENT);
	private cdn = `${this.environment['yuno-cdn-api']}`;
	private cdnStorage = `${this.environment['yuno-cdn-api-storage']}`;

	constructor(
		private http: HttpClient,
		private api: ApiService
	) {}

	_toHttpParams(params: Params) {
		return Object.getOwnPropertyNames(params).reduce(
			(p, key) => p.set(key, params[key]),
			new HttpParams()
		);
	}

	async get<T>(requestUrl: string, params?: Params) {
		const requestOptions = {
			params: this._toHttpParams({ ...params }),
			headers: new HttpHeaders().set('Content-Type', 'application/json')
		};

		return await this.http.get<T>(`${this.cdn}/${requestUrl}`, requestOptions);
	}

	delete(requestUrl: string) {
		const requestOptions = {
			headers: new HttpHeaders().set('Content-Type', 'application/json')
		};
		return this.http.delete(`${this.cdnStorage}/v2/content/${requestUrl}`, requestOptions).pipe(
			map(response => response),
			catchError((err: HttpErrorResponse) => throwError(() => err))
		);
	}

	update<T>(requestUrl: string, body?: unknown) {
		if (!body) {
			body = {};
		}
		return this.http
			.patch<T>(`${this.cdnStorage}/v2/content/upload/${requestUrl}`, body, {
				reportProgress: true,
				observe: 'events',
				responseType: 'json'
			})
			.pipe(
				map(response => response),
				catchError((err: HttpErrorResponse) => throwError(() => err))
			);
	}

	post<T>(requestUrl: string, body: FormData) {
		return this.http
			.post<T>(`${this.cdnStorage}/v2/content/upload/${requestUrl}`, body, {
				reportProgress: true,
				observe: 'events',
				responseType: 'json'
			})
			.pipe(
				map(response => response),
				catchError((err: HttpErrorResponse) => throwError(() => err))
			);
	}

	/* Static Files */
	async getStatic<T>(folder: string, params?: Params) {
		const requestOptions = {
			params: this._toHttpParams({ ...params }),
			headers: new HttpHeaders().set('Content-Type', 'application/json')
		};

		return await lastValueFrom(
			this.http.get<T>(`${this.cdn}/static/${folder}/list`, requestOptions)
		);
	}

	postStatic<T>(folder: string, file: File) {
		const formData = new FormData();
		formData.append('file', file);

		return lastValueFrom(this.http.post<T>(`${this.cdn}/static/upload/${folder}`, formData));
	}

	getFileBody(file: File, body?: unknown): FormData {
		const formData = new FormData();
		formData.append('file', file);

		if (body) {
			/* Clean all empty values */
			Object.keys(body).forEach(
				key =>
					body[key as keyof typeof body] == null && delete body[key as keyof typeof body]
			);
			/* remove file from body */
			delete body[file as keyof typeof body];

			for (const key in body) {
				formData.append(key, body[key as keyof typeof body]);
			}
		}

		return formData;
	}

	async getUploadCDNData(appId: string): Promise<CdnData> {
		return (await this.api.get(`media/upload/cdn/${appId}`)) as CdnData;
	}

	async saveToDB(appId: string, response: HttpResponse<unknown>): Promise<CdnFile> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (!(response.body as any)['data']) {
			throw new Error('No response data inside uploader');
		}

		return await this.api.post<CdnFile>(
			`media/upload/cdn/${appId}`,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(response.body as any)['data']
		);
	}
}
