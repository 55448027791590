import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Source } from '@yuno/api/interface';

import { sourcesActions } from './sources.actions';
import { sourcesFeature } from './sources.state';

@Injectable()
export class SourcesFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(sourcesFeature.selectLoaded));
	sources$ = this.store.pipe(select(sourcesFeature.selectSources));
	selected$ = this.store.pipe(select(sourcesFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(sourcesFeature.selectSelectedLoaded));
	sourceLayers$ = this.store.pipe(select(sourcesFeature.selectSourceLayers));
	status$ = this.store.pipe(select(sourcesFeature.selectStatus));

	/**
	 * Use the initialization action to perform one
	 * or more tasks in your Effects.
	 */
	get() {
		this.store.dispatch(sourcesActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(sourcesActions.select({ _id }));
	}

	updateSelect(data: Partial<Source>) {
		this.store.dispatch(sourcesActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(sourcesActions.clearSelect());
	}

	save() {
		this.store.dispatch(sourcesActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(sourcesActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(sourcesActions.delete({ _id }));
	}

	getSourceLayers(url: string) {
		this.store.dispatch(sourcesActions.sourceLayers({ url }));
	}

	clearSourceLayers() {
		this.store.dispatch(sourcesActions.clearSourceLayers());
	}

	/**
	 * Gets the Source Layers using the _id (ObjectId) of the Source
	 * @param id: string
	 */
	getSourceLayersById(id: string) {
		this.store.dispatch(sourcesActions.sourceLayersById({ id }));
	}

	/**
	 * Gets the Source Layers using the id (name) of the Source
	 * @param id: string
	 */
	getSourceLayersByName(id: string) {
		this.store.dispatch(sourcesActions.sourceLayersByName({ id }));
	}
}
