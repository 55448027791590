import { gql } from 'apollo-angular';

export const GET_COOKIE_LANGUAGES = gql`
	query getCookieLanguages {
		getCookieLanguages {
			_id
			language
		}
	}
`;

export const GET_COOKIE_LANGUAGE_BY_ID = gql`
	query getCookieLanguageById($id: String!) {
		getCookieLanguageById(id: $id) {
			_id
			language
			cookies {
				analytics {
					description
					title
				}
				functional {
					description
					title
				}
				thirdparty {
					description
					title
				}
			}
			interface {
				description
				hide
				saveAll
				saveSelection
				show
				title
			}
		}
	}
`;

export const GET_COOKIES = gql`
	query getCookies {
		getCookies {
			_id
			cookieType
			name
			type
		}
	}
`;

export const GET_COOKIE_BY_ID = gql`
	query getCookie($id: String!) {
		getCookie(id: $id) {
			_id
			cookieType
			expiry
			name
			policy
			provider
			purpose
			type
		}
	}
`;

export const GET_PRIVACY_STATEMENT = gql`
	query getPrivacyStatementById($id: String!) {
		getPrivacyStatementById(id: $id) {
			_id
			language
			content
		}
	}
`;
export const GET_PRIVACY_STATEMENTS = gql`
	query getPrivacyStatements {
		getPrivacyStatements {
			_id
			language
		}
	}
`;

export const GET_PRIVACY_STATEMENTS_CUSTOM = gql`
	query getPrivacyStatementProjects($appId: String!) {
		getPrivacyStatementProjects(appId: $appId) {
			_id
			id
		}
	}
`;

export const GET_PRIVACY_STATEMENTS_CUSTOM_BY_ID = gql`
	query getPrivacyStatementProject($id: String!, $appId: String!) {
		getPrivacyStatementProject(id: $id, appId: $appId) {
			_id
			id
			languages
		}
	}
`;

export const GET_PRIVACY_CONFIG = gql`
	query getPrivacyConfigByAppId($appId: String!) {
		getPrivacyConfig(appId: $appId) {
			_id
			appId
			clientActive
			clientName
			custom {
				_id
			}
			options {
				participate
				processingAgreement
			}
			url
		}
	}
`;
