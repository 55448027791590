import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	OnInit,
	Output,
	inject
} from '@angular/core';
import { Position } from 'geojson';
import { isEqual } from 'lodash';
import { LngLatBoundsLike, LngLatLike, Map } from 'maplibre-gl';
import { combineLatest, distinctUntilChanged, map, startWith, tap } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { MapFacade } from '@yuno/admin/features/map';
import { PanoramasFacade } from '@yuno/admin/features/panoramas';
import { ThemeFacade } from '@yuno/admin/features/themes';

@Component({
	selector: 'yuno-admin-pano-map',
	templateUrl: './pano-map.component.html',
	styleUrls: ['./pano-map.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanoMapComponent implements OnInit {
	private readonly panoramaFacade = inject(PanoramasFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly mapFacade = inject(MapFacade);

	readonly environment = inject(ENVIRONMENT);

	private vectorBasis = '5e42a2df5e53be02b5f37b00';
	private mapBoundsLngLat: LngLatBoundsLike = [
		[7.22, 53.7],
		[3.2, 50.75]
	];

	data$ = combineLatest({
		themes: this.themeFacade.themes$.pipe(
			tap(themes => {
				if (themes && themes.length >= 1) {
					if (themes[0]?._id) {
						this.themeFacade.select(themes[0]._id);
					}
				}
			})
		),
		style: this.themeFacade.selectedTheme$.pipe(
			tap(theme => {
				if (theme) {
					if (theme.view?.bounds) {
						this.mapBoundsLngLat = theme.view.bounds;
					}

					if (theme.mapStyles && theme.mapStyles.length >= 1) {
						if (theme.mapStyles[0]?.style && theme.mapStyles[0]?.style._id) {
							this.mapFacade.getStyle(theme.mapStyles[0]?.style._id);
						}
					}
				}
			})
		),
		panorama: this.panoramaFacade.selectedPanorama$,
		bounds: this.panoramaFacade.selectedPanorama$.pipe(
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					return false;
				}

				return isEqual(prev?.map?.coordinates, curr?.map?.coordinates);
			}),
			map(data => {
				if (data?.map?.coordinates && data?.map?.coordinates.length >= 2) {
					const coordinate: LngLatLike = {
						lng: data.map.coordinates[0],
						lat: data.map.coordinates[1]
					};
					const padding = 0.0025;
					const bounds: LngLatBoundsLike = [
						[coordinate.lng - padding, coordinate.lat - padding],
						[coordinate.lng + padding, coordinate.lat + padding]
					];
					return {
						fitBounds: bounds
					};
				}

				return {
					fitBounds: undefined
				};
			})
		),
		mapStyle: this.mapFacade.style$.pipe(
			startWith(undefined),
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					// when no style is selected, load the default mapstyle
					this.mapFacade.getStyle(this.vectorBasis);
					return false;
				}

				const prevMetadata: { [key: string]: unknown } = prev?.metadata as {
					[key: string]: unknown;
				};
				const currMetadata: { [key: string]: unknown } = curr?.metadata as {
					[key: string]: unknown;
				};

				if (!prevMetadata || !currMetadata) {
					return false;
				}

				return isEqual(prev?.metadata, curr?.metadata);
			})
		)
	});

	@Output() clicked = new EventEmitter<Position>();

	ngOnInit() {
		this.mapFacade.getStyle(this.vectorBasis);
		this.themeFacade.get();
	}

	getMapBounds(): LngLatBoundsLike {
		return this.mapBoundsLngLat;
	}

	getLngLat(point: Position | undefined): LngLatLike {
		return point as LngLatLike;
	}

	mapOnMissingImage(image: { id: string; target?: Map }): void {
		if (!image.target) {
			return;
		}

		const map = image.target;
		const id = image.id;
		const url = `${this.environment['yuno-cdn']}/sprites/public/sdf/sprites/${image.id}.png`;

		if (!map.hasImage(id)) {
			map.loadImage(url).then(img => {
				if (!img) {
					throw new Error(`Image could not be loaded for, ${url}`);
				}

				// the Map fires the same missing image event multiple times
				// then throws an error: "An image named "{{id}}" already exists."
				if (!map.hasImage(id)) {
					map.addImage(id, img.data, {
						sdf: true,
						pixelRatio: 1
					});
				}
			});
		}
	}
}
