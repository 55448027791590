<yuno-card>
	<ng-container content>
		<section class="grid grid-cols-1">
			@if (data$ | async; as data) {
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="false"
					[pagination]="true"
					[pageOptions]="{
						pageSize: 100,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="data.list"
					[buttons]="tableButtons"
					[buttonsWidth]="9"
					[columns]="[
						{ key: 'public', type: 'visible', disableVisibility: true },
						{ key: 'id', label: 'Category' }
					]"
					(clicked)="onView($event)">
					@if (xkpMember && minimalAppRole(userRoles.ADMIN)) {
						<button
							yuno-admin-button
							[routerLink]="['./', 'create', 'map', 'edit']"
							[add]="true">
							New Category
						</button>
					}
				</yuno-admin-table>
			}
		</section>
	</ng-container>
</yuno-card>

<ng-template #tableButtons let-row>
	<button yuno-admin-edit-button (click)="onEdit(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
