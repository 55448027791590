import { gql } from 'apollo-angular';

import { Client } from '@yuno/api/interface';

export const GET_CLIENTS = gql`
	query loadClients {
		clients {
			_id
			id
			url
		}
	}
`;

export const GET_CLIENT_BY_ID = gql`
	query getClientById($_id: String!) {
		selectedClient(_id: $_id) {
			_id
			id
			url
			path
		}
	}
`;

export type ClientsQuery = {
	clients: Partial<Client>[];
};

export type SelectClientQuery = {
	selectedClient: Partial<Client>;
};
