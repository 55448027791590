import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Template } from '@yuno/api/interface';

import { templatesActions } from './templates.actions';
import { templatesFeature } from './templates.state';

@Injectable()
export class TemplatesFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(templatesFeature.selectLoaded));
	templates$ = this.store.pipe(select(templatesFeature.selectTemplates));
	selected$ = this.store.pipe(select(templatesFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(templatesFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(templatesFeature.selectStatus));

	get(selector: string) {
		this.store.dispatch(templatesActions.load({ selector }));
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(templatesActions.select({ _id }));
	}

	updateSelect(data: Partial<Template>) {
		this.store.dispatch(templatesActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(templatesActions.clearSelect());
	}

	save() {
		this.store.dispatch(templatesActions.save());
	}

	delete(_id: string) {
		this.store.dispatch(templatesActions.delete({ _id }));
	}
}
