import { gql } from 'apollo-angular';

import { Textblock } from '@yuno/api/interface';

export const SAVE_TEXTBLOCK = gql`
	mutation saveTextblock($textblock: TextblockInput!, $appId: String!) {
		saveTextblock(textblock: $textblock, appId: $appId) {
			_id
			id
			content
		}
	}
`;

export type SaveTextblockMutation = {
	saveTextblock: Partial<Textblock>;
};
