import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface PanoramaUrlForm {
	id: FormControl<string>;
	theme: FormControl<string>;
	state: FormControl<string>;
	iframe: FormControl<boolean>;
	disableUi: FormControl<boolean>;
	disableThree: FormControl<boolean>;
	disableStates: FormControl<boolean>;
}

@Injectable({
	providedIn: 'root'
})
export class PanoramaUrlGeneratorService {
	form: FormGroup<PanoramaUrlForm> = new FormGroup<PanoramaUrlForm>({
		id: new FormControl<string>({ value: '', disabled: true }, { nonNullable: true }),
		theme: new FormControl<string>('', { nonNullable: true }),
		state: new FormControl<string>('', { nonNullable: true }),
		iframe: new FormControl<boolean>(false, { nonNullable: true }),
		disableUi: new FormControl<boolean>(false, { nonNullable: true }),
		disableThree: new FormControl<boolean>(false, { nonNullable: true }),
		disableStates: new FormControl<boolean>(false, { nonNullable: true })
	});

	get id(): FormControl<string> {
		return this.form.get('id') as FormControl<string>;
	}

	get theme(): FormControl<string> {
		return this.form.get('theme') as FormControl<string>;
	}

	get state(): FormControl<string> {
		return this.form.get('state') as FormControl<string>;
	}

	get iframe(): FormControl<boolean> {
		return this.form.get('iframe') as FormControl<boolean>;
	}

	get disableUi(): FormControl<boolean> {
		return this.form.get('disableUi') as FormControl<boolean>;
	}

	get disableThree(): FormControl<boolean> {
		return this.form.get('disableThree') as FormControl<boolean>;
	}

	get disableStates(): FormControl<boolean> {
		return this.form.get('disableStates') as FormControl<boolean>;
	}
}
