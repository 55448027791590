@switch (!!src) {
	@case (true) {
		<div class="yuno-text-image-container">
			<img
				[src]="src"
				class="yuno-text-image"
				[ngStyle]="{ 'maxWidth.px': getImageSize() }"
				(click)="zoomable && openImage()"
				alt="{{ alt | languageSelect: language }}" />
		</div>
	}
	@default {
		<div class="aspect-h-9 aspect-w-16 rounded-md bg-gray-600 text-gray-200">
			<div class="flex h-full w-full flex-col items-center justify-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="inline-block h-20 w-20"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					stroke-width="0.5">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
				</svg>
				<span class="text-sm font-light">Image not found</span>
			</div>
		</div>
	}
}

<span class="text-xs empty:hidden">
	<ng-content></ng-content>
	{{ description | languageSelect: language }}
</span>
