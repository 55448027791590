@if (!play) {
	<div (click)="play = true" class="relative flex h-full w-full flex-col justify-center">
		<div
			class="absolute left-0 top-0 z-20 flex h-full w-full flex-row items-center justify-center opacity-80 transition-opacity ease-in-out hover:opacity-100">
			<svg
				class="h-20 w-20"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
					clip-rule="evenodd" />
			</svg>
		</div>
		<img [src]="preview" class="z-10 m-auto h-auto w-full" loading="lazy" />
	</div>
}

@if (play) {
	<div (click)="play = false" class="relative flex h-full w-full flex-col justify-center">
		<div
			class="absolute left-0 top-0 z-20 flex h-full w-full flex-row items-center justify-center opacity-0 transition-opacity ease-in-out hover:opacity-100">
			<svg
				class="h-20 w-20"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z"
					clip-rule="evenodd" />
			</svg>
		</div>
		<img [src]="gif" class="z-10 h-auto w-full" loading="lazy" />
	</div>
}
