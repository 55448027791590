import { gql } from 'apollo-angular';

import { DataObjects } from '@yuno/api/interface';

export const DELETE_OBJECT = gql`
	mutation deleteObjectById($object: ObjectInput!) {
		deleteObject(object: $object) {
			type
			data
		}
	}
`;

export type DeleteObjectMutation = {
	deleteObject: Partial<DataObjects>;
};
