import { gql } from 'apollo-angular';

import { AppUser } from '@yuno/api/interface';

export const ADD_APP_USER = gql`
	mutation addAppUser($user: AppUserInput!, $appId: String!) {
		addAppUser(user: $user, appId: $appId) {
			role
			mailing {
				participation
			}
			user {
				displayName
				email
			}
		}
	}
`;

export const UPDATE_APP_USER = gql`
	mutation updateAppUser($user: AppUserInput!, $appId: String!) {
		updateAppUser(user: $user, appId: $appId) {
			role
			mailing {
				participation
			}
			user {
				displayName
				email
			}
		}
	}
`;

export type AddUserMutation = {
	addAppUser: Partial<AppUser>[];
};
export type UpdateUserMutation = {
	updateAppUser: Partial<AppUser>[];
};
