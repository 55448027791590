export const stringToNumber = (str: string | number): string | number => {
	if (typeof str === 'string' && !Number.isNaN(Number(str))) {
		return Number(str);
	}

	return str;
};

export const columns = (cols: number | undefined): string => {
	switch (cols) {
		case 1:
			return 'grid-cols-1';
		case 2:
			return 'grid-cols-2';
		case 3:
			return 'grid-cols-3';
		case 4:
			return 'grid-cols-4';
		case 5:
			return 'grid-cols-5';
		case 6:
			return 'grid-cols-6';

		default:
			return 'grid-cols-1';
	}
};

export const colspan = (colspan: number | 'full' | undefined): string => {
	switch (colspan) {
		case 'full':
			return 'col-span-full';
		case 1:
			return 'col-span-1';
		case 2:
			return 'col-span-2';
		case 3:
			return 'col-span-3';
		case 4:
			return 'col-span-4';
		case 5:
			return 'col-span-5';
		case 6:
			return 'col-span-6';

		default:
			return 'col-span-1';
	}
};
