import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import {
	YunoAdminButtonComponent,
	YunoAdminUiSelectorButtonComponent,
	YunoAdminUiSelectorComponent,
	YunoAdminUiSelectorDropdownComponent,
	YunoAdminUiSelectorDropdownItemComponent,
	YunoAdminUiSelectorItemComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';

import { MarkersFacade } from '../../../../data-access';
import { MarkersFilter, MarkersMapViewerService } from '../map-viewer/map-viewer.service';

@Component({
	selector: 'yuno-admin-marker-viewer-ui-selectors',
	standalone: true,
	imports: [
		NgClass,
		AsyncPipe,
		RouterLink,
		YunoAdminButtonComponent,
		YunoAdminUiSelectorComponent,
		YunoAdminUiSelectorItemComponent,
		YunoAdminUiSelectorDropdownComponent,
		YunoAdminUiSelectorDropdownItemComponent,
		YunoAdminUiSelectorButtonComponent
	],
	templateUrl: './ui-selectors.component.html',
	styleUrls: ['./ui-selectors.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerViewerUiSelectorsComponent extends AppDataComponent {
	private readonly mapService = inject(MarkersMapViewerService);
	private readonly markersFacade = inject(MarkersFacade);

	$filter = this.mapService.$filter;

	activeDisplay(string: MarkersFilter): string {
		if (string.length === 0) {
			return string; // return input if it's not a string or is an empty string
		}

		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	filterMarkers(filter: MarkersFilter): void {
		this.mapService.setMarkerFilter(filter);
	}

	backToBounds(): void {
		this.mapService.updateBounds();
	}

	openEditor(url: string[]): void {
		// An array of route segments to navigate to.
		let navigateTo = ['./'];

		// Get the first child route segment, if it exists.
		const child = this.route.firstChild?.snapshot.url[0].path;
		// Add the child route segment to the navigation array, if it exists.
		if (child) {
			navigateTo.push(child);
		}
		// Add the 'edit' route segment to the navigation array.
		navigateTo = [...navigateTo, ...url];

		// Clear the selected item from the facade.
		this.markersFacade.clearSelect();

		// Navigate to the specified route segments relative to the current route.
		this.router.navigate(navigateTo, { relativeTo: this.route });
	}
}
