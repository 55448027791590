import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsModule } from '@yuno/angular/forms';

import { ThemeEditorService, ThemeForm } from '../../theme-editor.service';

@Component({
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule],
	selector: 'yuno-admin-theme-panorama',
	templateUrl: './theme-panorama.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemePanoramaComponent {
	readonly service = inject(ThemeEditorService);

	@Input() form: FormGroup<ThemeForm>;
}
