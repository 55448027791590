import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { cookieFeature } from '@yuno/admin/features/privacy-and-cookies-editor/data-access/+state/cookies/cookies.state';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';
import { Cookie, CookieLanguage } from '@yuno/api/interface';

import {
	GET_COOKIES,
	GET_COOKIE_BY_ID,
	GET_COOKIE_LANGUAGES,
	GET_COOKIE_LANGUAGE_BY_ID,
	SAVE_COOKIE,
	SAVE_COOKIE_LANGUAGE
} from '../../../utils';
import { cookieActions } from './cookies.actions';

export type CookiesQuery = {
	getCookies: Partial<Cookie>[];
};
export type CookiesLanguageQuery = {
	getCookieLanguages: Partial<CookieLanguage>[];
};

export type CookieByIdQuery = {
	getCookie: Cookie;
};
export type CookiesLanguageByIdQuery = {
	getCookieLanguageById: CookieLanguage;
};

export type SaveCookieLanguage = {
	saveCookieLanguage: CookieLanguage;
};
export type SaveCookie = {
	saveCookie: Cookie;
};

export const get = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) => {
		return actions$.pipe(
			ofType(cookieActions.get),
			switchMap(() =>
				graphql
					.query<CookiesQuery>({
						query: GET_COOKIES
					})
					.pipe(
						map(cookies => {
							if (!cookies.data.getCookies) {
								throw new Error('no cookies data');
							}
							return cookieActions.getSuccess({
								data: cookies.data.getCookies
							});
						}),
						take(1),
						catchError(error => of(cookieActions.getFailure({ error })))
					)
			)
		);
	},
	{
		functional: true
	}
);

export const getLanguage = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) => {
		return actions$.pipe(
			ofType(cookieActions.getLanguage),
			switchMap(() =>
				graphql
					.query<CookiesLanguageQuery>({
						query: GET_COOKIE_LANGUAGES
					})
					.pipe(
						map(cookies => {
							if (!cookies.data.getCookieLanguages) {
								throw new Error('no cookies data');
							}
							return cookieActions.getLanguageSuccess({
								data: cookies.data.getCookieLanguages
							});
						}),
						take(1),
						catchError(error => of(cookieActions.getLanguageFailure({ error })))
					)
			)
		);
	},
	{
		functional: true
	}
);

export const getById = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) => {
		return actions$.pipe(
			ofType(cookieActions.getById),
			switchMap(action =>
				graphql
					.query<CookieByIdQuery>({
						query: GET_COOKIE_BY_ID,
						variables: {
							id: action.id
						}
					})
					.pipe(
						map(cookies => {
							if (!cookies.data.getCookie) {
								throw new Error('no cookies data');
							}
							return cookieActions.getByIdSuccess({
								data: cookies.data.getCookie
							});
						}),
						take(1),
						catchError(error => of(cookieActions.getByIdFailure({ error })))
					)
			)
		);
	},
	{
		functional: true
	}
);

export const getLanguageById = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) => {
		return actions$.pipe(
			ofType(cookieActions.getLanguageById),
			switchMap(action =>
				graphql
					.query<CookiesLanguageByIdQuery>({
						query: GET_COOKIE_LANGUAGE_BY_ID,
						variables: {
							id: action.id
						}
					})
					.pipe(
						map(cookies => {
							if (!cookies.data.getCookieLanguageById) {
								throw new Error('no cookies data');
							}
							return cookieActions.getLanguageSuccessById({
								data: cookies.data.getCookieLanguageById
							});
						}),
						take(1),
						catchError(error => of(cookieActions.getLanguageFailureById({ error })))
					)
			)
		);
	},
	{
		functional: true
	}
);

export const saveLanguage = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(cookieActions.saveLanguage),
			withLatestFrom(store.pipe(select(cookieFeature.selectSelectedLanguage))),
			switchMap(([, data]) =>
				graphql
					.mutate<SaveCookieLanguage>({
						mutation: SAVE_COOKIE_LANGUAGE,
						variables: {
							data
						}
					})
					.pipe(
						map(settings => {
							if (!settings.data?.saveCookieLanguage) {
								throw new Error('error updating cookies config');
							}
							return cookieActions.saveLanguageSuccess();
						}),
						take(1),
						catchError(error => of(cookieActions.saveLanguageFailure({ error })))
					)
			)
		);
	},
	{
		functional: true
	}
);

export const saveLanguageSucccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(cookieActions.saveLanguageSuccess),
			tap(() => message.sendToast(`Saved successfully!`, 'success'))
		);
	},
	{ dispatch: false, functional: true }
);

export const saveLanguageFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(cookieActions.saveLanguageFailure),
			tap(() => message.sendToast(`Save Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);

// COOKIES
export const save = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(cookieActions.save),
			withLatestFrom(store.pipe(select(cookieFeature.selectSelected))),
			switchMap(([, data]) =>
				graphql
					.mutate<SaveCookie>({
						mutation: SAVE_COOKIE,
						variables: {
							data
						}
					})
					.pipe(
						map(settings => {
							if (!settings.data?.saveCookie) {
								throw new Error('error updating cookies config');
							}
							return cookieActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(cookieActions.saveFailure({ error })))
					)
			)
		);
	},
	{
		functional: true
	}
);

export const saveSucccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(cookieActions.saveSuccess),
			tap(() => message.sendToast(`Saved successfully!`, 'success'))
		);
	},
	{ dispatch: false, functional: true }
);

export const saveFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(cookieActions.saveFailure),
			tap(() => message.sendToast(`Save Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);
