import { gql } from 'apollo-angular';

import { CustomSource } from '@yuno/api/interface';

export const GET_CUSTOMSOURCES_BY_APPID = gql`
	query getSourcesByAppId($appId: String!) {
		customSources(appId: $appId) {
			_id
			source {
				name
			}
		}
	}
`;

export const GET_CUSTOMSOURCE_BY_ID = gql`
	query getSourceById($_id: String!) {
		selectedCustomSource(_id: $_id) {
			_id
			source {
				name
			}
			tileset {
				name
			}
			newData {
				sourceId
				matchKey
				databuffer
				options
			}
			geojsonOptions {
				sourceId
				matchKey
			}
		}
	}
`;

export const GET_CUSTOMSOURCE_BY_NAME = gql`
	query getCustomSourceByName($name: String!, $appId: String!) {
		selectCustomSourceByName(name: $name, appId: $appId) {
			_id
			source {
				name
				options
			}
		}
	}
`;

export type CustomSourcesQuery = {
	customSources: Partial<CustomSource>[];
};

export type SelectCustomSourceQuery = {
	selectedCustomSource: Partial<CustomSource>;
};

export type SelectCustomSourceByNameQuery = {
	selectCustomSourceByName: Partial<CustomSource>;
};
