import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./legends.component').then(m => m.LegendsComponent)
	},
	{
		path: 'create',
		loadChildren: () =>
			import('@yuno/admin/features/legends/feature/editor/legend-editor.routing.module').then(
				m => m.LegendEditorRoutingModule
			)
	},
	{
		path: 'edit/:id',
		loadChildren: () =>
			import('@yuno/admin/features/legends/feature/editor/legend-editor.routing.module').then(
				m => m.LegendEditorRoutingModule
			)
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LegendsRoutingModule {}
