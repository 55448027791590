@if (service$ | async; as service) {
	@if (editService.data$ | async; as data) {
		@if (service.component) {
			<form [formGroup]="service.component" class="flex flex-col gap-4">
				<section>
					<yuno-forms-title>News Item Editor</yuno-forms-title>
					<yuno-forms-span>You can edit the selected News Item below.</yuno-forms-span>
				</section>
				<yuno-forms-text
					class="flex-1"
					formControlName="id"
					placeholder="News Item name"
					label="News Item name"></yuno-forms-text>

				<!-- Text -->
				<section class="mt-12 flex flex-col gap-4">
					<yuno-forms-title>Text</yuno-forms-title>

					<section formGroupName="title" class="flex flex-col items-start gap-4">
						@for (lang of editService.languages; track lang) {
							@if (lang === data.language) {
								<yuno-forms-text
									class="w-full"
									[formControlName]="lang"
									[language]="lang"
									placeholder="Title"
									label="Title"></yuno-forms-text>
							}
						}
					</section>

					<section formGroupName="subTitle" class="flex flex-col items-start gap-4">
						@for (lang of editService.languages; track lang) {
							@if (lang === data.language) {
								<yuno-forms-text
									class="w-full"
									[formControlName]="lang"
									[language]="lang"
									placeholder="Subtitle"
									label="Subtitle"></yuno-forms-text>
							}
						}
					</section>

					<section formGroupName="content" class="flex flex-col items-start gap-4">
						@for (lang of editService.languages; track lang) {
							@if (lang === data.language) {
								<yuno-forms-html
									class="w-full"
									[language]="lang"
									[formControlName]="lang"
									placeholder="Text"
									label="Text"></yuno-forms-html>
							}
						}
					</section>
				</section>

				<!-- Image -->
				<section class="mt-12 flex flex-col gap-4">
					<yuno-forms-title>Image or Video</yuno-forms-title>
					<!-- First IMAGE -->
					<div
						class="flex flex-col gap-4 rounded-md border border-gray-300 bg-gray-50 p-4">
						<div formGroupName="imageFile" class="flex w-full flex-col items-end gap-2">
							<ng-container formGroupName="data">
								<yuno-forms-text
									class="w-full"
									formControlName="fileName"
									placeholder="Select a image"
									label="Image"></yuno-forms-text>
							</ng-container>
							<div class="flex w-full flex-row justify-end gap-4">
								<button
									yuno-admin-button
									color="secondary"
									(click)="editService.clearImage()">
									Clear
								</button>
								<button yuno-admin-button (click)="openImageLibrary(0)">
									Browse
								</button>
							</div>
							@if ({ img: selectImage$ | async, close: close$ | async }; as select) {}
						</div>
						<div class="flex w-full flex-col items-end gap-2">
							<yuno-forms-text
								class="w-full"
								formControlName="imageLink"
								placeholder="Add a URL that you want the image to link to"
								label="Image link"></yuno-forms-text>
						</div>
						<yuno-forms-toggle
							formControlName="imageZoom"
							labelPos="side"
							label="Allow users to open the image fullscreen"></yuno-forms-toggle>
					</div>

					<!-- SECOND IMAGE -->
					<div
						class="flex flex-col gap-4 rounded-md border border-gray-300 bg-gray-50 p-4">
						<div
							formGroupName="secondaryImageFile"
							class="flex w-full flex-col items-end gap-2">
							<ng-container formGroupName="data">
								<yuno-forms-text
									class="w-full"
									formControlName="fileName"
									placeholder="Select a image"
									label="Secondary Image"></yuno-forms-text>
							</ng-container>
							<div class="flex w-full flex-row justify-end gap-4">
								<button
									yuno-admin-button
									color="secondary"
									(click)="editService.clearSecondaryImage()">
									Clear
								</button>
								<button yuno-admin-button (click)="openImageLibrary(1)">
									Browse
								</button>
							</div>
							@if ({ img: selectImage$ | async, close: close$ | async }; as select) {}
						</div>
						<div class="flex w-full flex-col items-end gap-2">
							<yuno-forms-text
								class="w-full"
								formControlName="secondaryImageLink"
								placeholder="Add a URL that you want the image to link to"
								label="Secondary Image link"></yuno-forms-text>
						</div>
						<yuno-forms-toggle
							formControlName="secondaryImageZoom"
							labelPos="side"
							label="Allow users to open the secondary image fullscreen"></yuno-forms-toggle>
					</div>

					<yuno-forms-select
						formControlName="imageOrder"
						label="Order of the images"
						[selectValues]="imageOrder"
						[display]="imageOrderDisplay"></yuno-forms-select>

					@if (
						service.component.get('align')?.value === 'left' ||
						service.component.get('align')?.value === 'right'
					) {
						<yuno-admin-annotation
							>When the alignment is left or right the order is locked
						</yuno-admin-annotation>
					}
				</section>

				<yuno-forms-divider></yuno-forms-divider>

				<!-- video -->
				<section class="flex flex-col gap-4">
					@if (service.component.get('imageFile')?.get('_id')?.value) {
						<yuno-admin-annotation type="warning">
							When a image is selected, the video will not be shown!
						</yuno-admin-annotation>
					}
					<yuno-forms-select
						formControlName="videoType"
						label="Video platform"
						[selectValues]="videoTypes"
						[display]="videoDisplay"
						[unselectDisplay]="'None'"></yuno-forms-select>

					<div class="flex w-full flex-col items-end gap-2">
						@if (service.component.get('videoType')?.value === 'vimeo') {
							<yuno-forms-text
								class="w-full"
								formControlName="videoSrc"
								placeholder="Vimeo ID"
								label="Vimeo ID"></yuno-forms-text>
						}
						@if (service.component.get('videoType')?.value === 'youtube') {
							<yuno-forms-text
								class="w-full"
								formControlName="videoSrc"
								placeholder="Youtube ID or Url"
								label="Youtube ID or Url"></yuno-forms-text>
						}
					</div>
				</section>

				<yuno-forms-divider></yuno-forms-divider>

				<section formGroupName="description" class="flex flex-col items-start gap-4">
					@for (lang of editService.languages; track lang) {
						@if (lang === data.language) {
							<yuno-forms-html
								class="w-full"
								[formControlName]="lang"
								[language]="lang"
								placeholder="Image or video description"
								label="Image or video description"></yuno-forms-html>
						}
					}
				</section>

				<!-- links -->
				<section class="mt-12 flex flex-col gap-4">
					<yuno-forms-title>Links</yuno-forms-title>
					<div
						formArrayName="links"
						class="flex flex-col gap-4"
						cdkDropList
						[cdkDropListData]="editService.links"
						(cdkDropListDropped)="editService.dropNewsItems($event)">
						@for (alias of editService.links.controls; track alias; let i = $index) {
							<div
								[formGroupName]="i"
								cdkDrag
								class="relative flex flex-col gap-2 rounded-md border-2 border-dashed border-gray-300 bg-gray-100 p-4">
								<div class="-mb-4 -mt-2 cursor-move self-center" cdkDragHandle>
									<svg
										viewBox="0 0 16 24"
										version="2.0"
										class="h-8 w-4"
										fill="#9ca3af"
										border="#9ca3af">
										<use href="#drag-icon" />
									</svg>
								</div>

								<yuno-forms-select
									class="flex-1"
									formControlName="type"
									placeholder="Select type"
									label="Type"
									[selectValues]="linkTypes"
									[display]="linkTypes"
									(changes)="
										editService.onTypeChange($event, i)
									"></yuno-forms-select>

								<section formGroupName="text">
									@for (lang of editService.languages; track lang) {
										@if (lang === data.language) {
											<yuno-forms-text
												[language]="lang"
												[formControlName]="lang"
												placeholder="Title"
												label="Title"></yuno-forms-text>
										}
									}
								</section>

								@if (alias.value.type === 'page') {
									<section formGroupName="pageUrl">
										<yuno-forms-select
											class="flex-1"
											formControlName="_id"
											placeholder="Select page"
											label="Page"
											[selectValues]="service.linkPageSelectValues"
											[display]="service.linkPageSelectDisplay"
											(changes)="editService.onPageUrlIdChange($event, i)"
											(changesDisplay)="
												editService.onPageUrlChange($event, i)
											"></yuno-forms-select>
									</section>
								}

								<section formGroupName="url">
									@for (lang of editService.languages; track lang) {
										@if (lang === data.language) {
											@if (alias.value.type === 'url') {
												<yuno-forms-text
													class="flex-1"
													[language]="lang"
													[formControlName]="lang"
													placeholder="Link url"
													label="Url"></yuno-forms-text>
											}
										}
									}
								</section>
								@if (alias.value.type === 'navigation') {
									<section>
										<yuno-forms-select
											class="flex-1"
											formControlName="link"
											placeholder="Select navigation"
											label="Navigation link"
											[selectValues]="service.navigationSelectValues"
											[display]="
												service.navigationSelectDisplay
											"></yuno-forms-select>
									</section>
								}
								<div class="flex justify-end">
									<button
										yuno-admin-button
										color="danger"
										(click)="editService.removeLink(i)">
										remove
									</button>
								</div>
							</div>
						}
					</div>
					<button
						yuno-admin-button
						color="primary"
						(click)="editService.addLink()"
						[add]="true">
						Add Link
					</button>
				</section>

				<!-- Styling -->
				<section class="mt-12 flex flex-col gap-4">
					<yuno-forms-title>Styling</yuno-forms-title>

					<div class="grid grid-cols-2 gap-2">
						<yuno-forms-color
							class="flex-1"
							formControlName="bgColor"
							label="Background Color"
							colorDirection="bottom"></yuno-forms-color>
						<yuno-forms-color
							class="flex-1"
							formControlName="textColor"
							label="Text Color"
							colorDirection="bottom"></yuno-forms-color>
						<yuno-forms-color
							class="flex-1"
							formControlName="textHeadingColor"
							label="Title Color"
							colorDirection="bottom"></yuno-forms-color>
						<yuno-forms-color
							class="flex-1"
							formControlName="textLinkColor"
							label="Link Color"
							colorDirection="bottom"></yuno-forms-color>
					</div>

					<div class="grid grid-cols-2 gap-2">
						<yuno-forms-select
							class="flex-1"
							[ngClass]="{
								'col-span-2': !(
									service.component.get('align')?.value === 'left' ||
									service.component.get('align')?.value === 'right'
								)
							}"
							formControlName="align"
							placeholder="Select Alignment"
							label="Alignment"
							[selectValues]="alignValues"
							[display]="alignValues"></yuno-forms-select>

						@if (
							service.component.get('align')?.value === 'left' ||
							service.component.get('align')?.value === 'right'
						) {
							<yuno-forms-select
								class="flex-1"
								formControlName="size"
								placeholder="Select Sizing"
								label="Sizing"
								[selectValues]="sizeValues"
								[display]="sizeValues"></yuno-forms-select>
						}
					</div>

					<section class="flex flex-col gap-2">
						<yuno-admin-annotation type="info">
							Colors can be left blank to use the default Project Atlas style.
						</yuno-admin-annotation>
						<yuno-admin-annotation type="info">
							If the "Title Color" is not set, the "Font Color" will be used for
							"Titles"
						</yuno-admin-annotation>
					</section>
				</section>
			</form>
		}
	}
}
