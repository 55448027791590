import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { YunoAdminButtonComponent, YunoAdminTableComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { AddUserComponent } from './add-user/add-user.component';
import { UserDataComponent } from './user-data/user-data.component';

@NgModule({
	declarations: [UserDataComponent, AddUserComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoAdminButtonComponent,
		YunoFormsModule,
		YunoAdminTableComponent,
		MatDialogModule
	]
})
export class UsersModule {}
