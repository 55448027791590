<div
	class="mb-2 flex flex-col md:mb-3 lg:mb-4"
	*ngIf="title"
	[ngStyle]="{ color: textHeadingColor || textColor }">
	<span
		class="mb-1 text-xl font-semibold"
		*ngIf="title"
		[innerHtml]="title | languageSelect: language | safeHtmlIframe">
	</span>
	<span
		class="text-sm italic"
		*ngIf="subTitle"
		[innerHtml]="subTitle | languageSelect: language | safeHtmlIframe"></span>
</div>
<div
	class="flex w-full gap-4"
	[ngStyle]="{
		color: textColor
	}"
	[ngClass]="getColDirection()">
	<!-- Contents -->
	<div class="flex-1" *ngIf="content">
		<div [innerHtml]="content | languageSelect: language | safeHtmlIframe"></div>
		<ng-container *ngFor="let link of links">
			<ng-container [ngSwitch]="link.type">
				<ng-container *ngSwitchCase="'page'">
					<yuno-textfield-link
						*ngIf="link.pageUrl?.id"
						[content]="link.text"
						[language]="language"
						[page]="link.pageUrl?.id!"
						[color]="textLinkColor"
						[disableInjection]="true"></yuno-textfield-link>
				</ng-container>
				<ng-container *ngSwitchCase="'navigation'">
					<yuno-textfield-link
						*ngIf="link.link"
						[content]="link.text"
						[language]="language"
						[page]="link.link!"
						[color]="textLinkColor"
						[disableInjection]="true"></yuno-textfield-link>
				</ng-container>
				<ng-container *ngSwitchDefault>
					@if (link.url | languageSelect: language) {
						<yuno-textfield-link
							[content]="link.text"
							[language]="language"
							[url]="link.url"
							[color]="textLinkColor"
							[disableInjection]="true"></yuno-textfield-link>
					}
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
	<!-- Image/Video -->
	<div
		class="flex w-full max-w-full flex-col items-start justify-start gap-2"
		*ngIf="imageSrc || videoSrc"
		[ngClass]="{
			'md:max-w-1/2': size === '1/2' && align !== 'top' && align !== 'bottom',
			'md:max-w-1/3': size === '1/3' && align !== 'top' && align !== 'bottom',
			'md:max-w-1/4': size === '1/4' && align !== 'top' && align !== 'bottom'
		}">
		<div class="block w-full">
			<ng-container [ngSwitch]="!!imageSrc || !!secondaryImageSrc">
				<ng-container *ngSwitchCase="true">
					<div
						class="grid items-start gap-2"
						[ngClass]="{
							'grid-cols-2':
								imageOrder !== 'column' &&
								!!imageSrc &&
								!!secondaryImageSrc &&
								(align === 'top' || align === 'bottom'),
							'grid-cols-1': imageOrder === 'column' && !!secondaryImageSrc
						}">
						<!-- First Image -->
						<ng-container *ngIf="!!imageSrc">
							<ng-container [ngSwitch]="!!imageLink">
								<div class="flex flex-col gap-2">
									<yuno-textfield-image
										*ngSwitchCase="false"
										[src]="imageSrc"
										[zoomable]="!!imageZoom"
										[disableInjection]="true"></yuno-textfield-image>
									<a
										*ngSwitchCase="true"
										rel="noopener noreferrer nofollow"
										target="_blank"
										[href]="imageLink">
										<yuno-textfield-image
											class="no-pointer-events"
											[src]="imageSrc"
											[zoomable]="false"
											[disableInjection]="true"></yuno-textfield-image>
									</a>
								</div>
							</ng-container>
						</ng-container>

						<!-- Secondary Image -->
						<ng-container *ngIf="!!secondaryImageSrc">
							<ng-container [ngSwitch]="!!secondaryImageLink">
								<div class="flex flex-col gap-2">
									<yuno-textfield-image
										*ngSwitchCase="false"
										[src]="secondaryImageSrc"
										[zoomable]="!!secondaryImageZoom"
										[disableInjection]="true"></yuno-textfield-image>
									<a
										*ngSwitchCase="true"
										rel="noopener noreferrer nofollow"
										target="_blank"
										[href]="secondaryImageLink">
										<yuno-textfield-image
											class="no-pointer-events h-full"
											[src]="secondaryImageSrc"
											[zoomable]="false"
											[disableInjection]="true"></yuno-textfield-image>
									</a>
								</div>
							</ng-container>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="videoSrc">
					<yuno-textfield-video
						*ngSwitchCase="false"
						[language]="language"
						[type]="videoType"
						[videourl]="videoSrc"
						[disableInjection]="true"></yuno-textfield-video>
				</ng-container>
			</ng-container>
		</div>
		<div
			*ngIf="description"
			class="w-full text-right text-sm"
			[innerHtml]="description | languageSelect: language | safeHtmlIframe"></div>
	</div>
</div>
