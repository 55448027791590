import { gql } from 'apollo-angular';

import { TilesetCustom } from '@yuno/api/interface';

export const SAVE_TILESET = gql`
	mutation saveCustomTileset($tileset: TilesetCustomInput!, $appId: String!) {
		saveCustomTileset(tileset: $tileset, appId: $appId) {
			_id
			id
			geojson
			tippecanoe {
				preset
				command
			}
		}
	}
`;

export type SaveCustomTilesetMutation = {
	saveCustomTileset: Partial<TilesetCustom>;
};
