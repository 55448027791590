import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Navigation } from '@yuno/api/interface';

export const navigationActions = createActionGroup({
	source: 'Navigations',
	events: {
		load: emptyProps(),
		loadSuccess: props<{
			data: Partial<Navigation>;
		}>(),
		loadFailure: props<{
			error: Error;
		}>(),
		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{
			error: Error;
		}>(),
		update: props<{
			navigation: Partial<Navigation>;
		}>(),
		updateSuccess: emptyProps(),
		updateFailure: props<{
			error: Error;
		}>()
	}
});
