import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';

import { DialogType } from '@yuno/angular/api';

import { FileUploadComponent } from '../feature/file-upload.component';
import { CdnFileUploadService } from './cdn-file-upload.service';

@Injectable({
	providedIn: 'root'
})
export class FileUploadService {
	private readonly dialog = inject(MatDialog);
	private readonly http = inject(HttpClient);
	private readonly cdn = inject(CdnFileUploadService);

	private dialogRef: MatDialogRef<FileUploadComponent>;
	private route: string;
	_closed = new Subject<boolean>();
	closed$ = this._closed.asObservable();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	response = new Subject<any>();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	_data = new Subject<any>();
	data$ = this._data.asObservable();

	openDialog(data: DialogType, route: string) {
		this.route = route;
		this.dialogRef = this.dialog.open(FileUploadComponent, {
			data: data
		});
		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this._closed.next(true);
			}
		});
	}

	openDialogCdn(data: DialogType) {
		this.dialogRef = this.dialog.open(FileUploadComponent, {
			data: { ...data, cdn: true }
		});

		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this._closed.next(true);
			}
		});
	}

	uploadFile(file: File): Observable<HttpEvent<unknown>> {
		const body: FormData = new FormData();
		body.append('file', file);

		return this.http
			.post(`${this.route}`, body, {
				reportProgress: true,
				observe: 'events',
				responseType: 'json'
			})
			.pipe(
				map(response => response),
				catchError((err: HttpErrorResponse) => throwError(() => err))
			);
	}

	deleteFile(appId: string, id: string) {
		const url = `delete/${appId}/file/${id}`;
		return this.cdn.delete(url);
	}
}
