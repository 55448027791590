import { gql } from 'apollo-angular';

export const GET_DASHBOARD_BY_APPID = gql`
	query getDashboardByAppId($appId: String!, $key: String!) {
		dashboard(appId: $appId, key: $key) {
			_id
			intro {
				public
				title
				description
			}
			matomo {
				public
				typeOf
				siteId
			}
			help {
				public
				items {
					public
					title
					description
					image {
						typeOf
						source
						preview
					}
					links {
						destination
						title
					}
				}
			}
			public
			key
		}
	}
`;
