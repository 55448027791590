import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

export const IdleBadgeAnimationTime = 500;

@Component({
	selector: 'yuno-idle-badge',
	templateUrl: './idle-badge.component.html',
	styleUrls: ['./idle-badge.component.scss'],
	animations: [
		trigger('slideIn', [
			state(
				'deactive',
				style({
					transform: 'translateY(-100%)'
				})
			),
			state('active', style({ transform: 'translateY(0)' })),
			transition('deactive => active', [
				animate(
					`${IdleBadgeAnimationTime}ms cubic-bezier(0.4, 0, 0.2, 1)`,
					style({ transform: 'translateY(0)' })
				)
			]),
			transition('active => deactive', [
				animate(
					`${IdleBadgeAnimationTime}ms cubic-bezier(0.4, 0, 0.2, 1)`,
					style({ transform: 'translateY(-100%)' })
				)
			]),
			transition('* => void', [
				animate(
					`${IdleBadgeAnimationTime}ms cubic-bezier(0.4, 0, 0.2, 1)`,
					style({ transform: 'translateY(-100%)' })
				)
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class IdleBadgeComponent implements OnInit, OnDestroy {
	@HostBinding('@slideIn')
	get slideInToggle(): string {
		return this.status || 'deactive';
	}
	status: 'active' | 'deactive' = 'deactive';

	ngOnInit(): void {
		setTimeout(() => {
			this.setActive();
		}, 500);
	}

	setActive(): void {
		this.status = 'active';
	}

	ngOnDestroy(): void {
		this.status = 'deactive';
	}
}
