<yuno-edit-container [col]="12" [pos]="4" [span]="7" style="z-index: 400">
	<ng-container buttons>
		<button yuno-admin-close-button (click)="onClose()"></button>
	</ng-container>

	<div edit-container-content>
		<h4 class="my-4">MbTiles to upload</h4>
		@for (file of service.files; track file; let i = $index) {
			<div>
				<div class="mb-4 flex">
					<label class="yuno-form flex-1">
						<input [name]="'file-' + i" [type]="'text'" [value]="file.name" disabled />
					</label>
				</div>
			</div>
		}
		<input
			id="inputfile"
			class="hidden"
			type="file"
			accept=".mbtiles"
			(change)="service.onFileChange($event)"
			[multiple]="true" />
		<div class="flex gap-2">
			<button
				yuno-admin-button
				for="inputfile"
				(click)="onFile()"
				[ngStyle]="{ opacity: 0.93 }"
				color="primary"
				[add]="true">
				{{ service.files.length ? 'Replace' : 'Add / Overwrite' }}
			</button>
			@if (service.files.length) {
				<button
					yuno-admin-button
					(click)="service.upload()"
					[ngStyle]="{ opacity: 0.93 }"
					color="success">
					Upload
				</button>
			}
		</div>
	</div>
</yuno-edit-container>
