@if (service.textfields$ | async) {
	@if (form) {
		<form [formGroup]="form">
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				<yuno-forms-text formControlName="property" label="property"></yuno-forms-text>
				<yuno-forms-text formControlName="type" label="type"></yuno-forms-text>
				<div formGroupName="textOptions">
					<yuno-forms-select
						formControlName="id"
						label="textfield"
						[selectValues]="service.textfieldSelectValues"
						[display]="service.textfieldSelectDisplay"
						placeholder="Textfield..."></yuno-forms-select>
				</div>
			</div>
		</form>
	}
}
