import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_PANO,
	DUPLICATE_PANO,
	DeletePanoramaMutation,
	DuplicatePanoramaMutation,
	GET_PANORAMAS_BY_APPID,
	GET_PANORAMA_BY_ID,
	PanoramasQuery,
	SAVE_PANO,
	SavePanoramaQuery,
	SelectPanoramaQuery
} from '@yuno/admin/features/panoramas/utils/graphql';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { panoramasActions } from './panoramas.actions';
import { panoramasFeature } from './panoramas.state';

export const loadPanoramas = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(panoramasActions.load, panoramasActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<PanoramasQuery>({
						query: GET_PANORAMAS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.panoramas) {
								throw new Error('no panoramas found');
							}
							return panoramasActions.loadSuccess({
								data: data.data.panoramas
							});
						}),
						take(1),
						catchError(error => of(panoramasActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectPanorama = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(panoramasActions.select),
			switchMap(panorama =>
				graphql
					.query<SelectPanoramaQuery>({
						query: GET_PANORAMA_BY_ID,
						variables: {
							_id: panorama._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedPanorama) {
								message.sendToast(`Error selecting panorama!`, 'error');
								throw new Error('no Panorama with that id found');
							}

							return panoramasActions.selectSuccess({
								data: data.data.selectedPanorama
							});
						}),
						take(1),
						catchError(error => of(panoramasActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updatePanorama = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(panoramasActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating panorama!`, 'error');
					throw new Error('no Panorama found');
				}
				return panoramasActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(panoramasActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const savePanorama = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(panoramasActions.save),
			withLatestFrom(
				store.pipe(select(panoramasFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SavePanoramaQuery>({
						mutation: SAVE_PANO,
						variables: {
							panorama: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.savePanorama) {
								throw new Error('error saving panorama to the database');
							}
							store.dispatch(
								panoramasActions.updateSelect({ data: data.data.savePanorama })
							);
							return panoramasActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(panoramasActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicatePanorama = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(panoramasActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([panorama, appId]) =>
				graphql
					.mutate<DuplicatePanoramaMutation>({
						mutation: DUPLICATE_PANO,
						variables: {
							_id: panorama._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicatePanorama) {
								throw new Error('error saving panorama to the database');
							}
							store.dispatch(panoramasActions.reload());
							return panoramasActions.duplicateSuccess({
								data: data.data.duplicatePanorama
							});
						}),
						take(1),
						catchError(error => of(panoramasActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deletePanorama = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(panoramasActions.delete),
			switchMap(panorama =>
				graphql
					.mutate<DeletePanoramaMutation>({
						mutation: DELETE_PANO,
						variables: {
							_id: panorama._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deletePanorama) {
								throw new Error('error deleting Panorama from the database');
							}
							store.dispatch(panoramasActions.reload());
							return panoramasActions.deleteSuccess({
								_id: data.data.deletePanorama
							});
						}),
						take(1),
						catchError(error => of(panoramasActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const savePanoramaSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(panoramasActions.saveSuccess),
			tap(() => {
				message.sendToast(`Panorama successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const savePanoramaFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(panoramasActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Panorama!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicatePanoramaSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(panoramasActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Panorama successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicatePanoramaFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(panoramasActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Panorama!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deletePanoramaSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(panoramasActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Panorama successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deletePanoramaFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(panoramasActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Panorama!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
