import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { RippleDirectiveModule } from '@yuno/project-atlas/ui';

import { CheckboxComponent } from './components/controls/checkbox/checkbox.component';
import { ColorComponent } from './components/controls/color/color.component';
import { ControlsSelectorComponent } from './components/controls/controls.selector.component';
import { DateComponent } from './components/controls/date/date.component';
import { EmailComponent } from './components/controls/email/email.component';
import { FileComponent } from './components/controls/file/file.component';
import { NumberComponent } from './components/controls/number/number.component';
import { ParagraphComponent } from './components/controls/paragraph/paragraph.component';
import { PasswordComponent } from './components/controls/password/password.component';
import { RadioComponent } from './components/controls/radio/radio.component';
import { RangeComponent } from './components/controls/range/range.component';
import { SelectComponent } from './components/controls/select/select.component';
import { TelephoneComponent } from './components/controls/telephone/telephone.component';
import { TextComponent } from './components/controls/text/text.component';
import { TextareaComponent } from './components/controls/textarea/textarea.component';
import { ToggleComponent } from './components/controls/toggle/toggle.component';
import { UrlComponent } from './components/controls/url/url.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { ControlArrayComponent } from './components/types/array-control/array-control.component';
import { ArrayComponent } from './components/types/array/array.component';
import { ButtonComponent } from './components/types/button/button.component';
import { ControlComponent } from './components/types/control/control.component';
import { DividerComponent } from './components/types/divider/divider.component';
import { GroupComponent } from './components/types/group/group.component';
import { LabelComponent } from './components/types/label/label.component';
import { LanguageComponent } from './components/types/language/language.component';
import { JsonFormComponent } from './json-form.component';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { ActionsService } from './services/actions.service';
import { FormCreatorService } from './services/form-creator.service';
import { LanguageService } from './services/language.service';
import { ValidationService } from './services/validation.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RippleDirectiveModule,
		ColorPickerModule,
		SafeHtmlPipe,
		JsonFormComponent,
		DynamicFormComponent,
		GroupComponent,
		ArrayComponent,
		ControlComponent,
		ControlArrayComponent,
		ControlsSelectorComponent,
		DateComponent,
		ParagraphComponent,
		NumberComponent,
		SelectComponent,
		TextareaComponent,
		CheckboxComponent,
		ToggleComponent,
		TextComponent,
		EmailComponent,
		PasswordComponent,
		TelephoneComponent,
		UrlComponent,
		ColorComponent,
		FileComponent,
		RadioComponent,
		RangeComponent,
		ButtonComponent,
		DividerComponent,
		LabelComponent,
		LanguageComponent
	],
	providers: [FormCreatorService, ActionsService, ValidationService, LanguageService],
	exports: [JsonFormComponent]
})
export class JsonFormsModule {}
