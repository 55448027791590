import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent, CodeEditorComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-event-set-layer-style',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		AnnotationComponent,
		AsyncPipe,
		CodeEditorComponent
	],
	templateUrl: './set-layer-style.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetLayerStyleComponent extends EventsDefaultFormItemComponent implements OnInit {
	// Temporary Controls used by the Code Editor
	layoutControl = new FormControl<string>('{}', { nonNullable: true });
	paintControl = new FormControl<string>('{}', { nonNullable: true });

	ngOnInit(): void {
		this.service.getLayers();
		this.patchEditorValues();
	}

	/**
	 * Patches the Monaco Editor values
	 * because they cannot directly use the raw Form Values
	 */
	patchEditorValues(): void {
		// @ts-ignore MapLibre uses very long types that generates: Type instantiation is excessively deep and possibly infinite.
		const layoutValue = this.form.get('options')?.get('layout').value;
		// @ts-ignore MapLibre uses very long types that generates: Type instantiation is excessively deep and possibly infinite.
		const paintValue = this.form.get('options')?.get('paint').value;

		this.layoutControl.patchValue(JSON.stringify(layoutValue) || '{}');
		this.paintControl.patchValue(JSON.stringify(paintValue) || '{}');
	}

	updateControlForm(event: string, key: 'filter' | 'layout' | 'paint'): void {
		this.updateFormControls(key, JSON.parse(event));
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	updateFormControls(key: 'filter' | 'layout' | 'paint', json: any) {
		if (key === 'layout' || key === 'paint') {
			this.service.setLayerStyleOptionFormControls(this.form, key, json);
		}
	}
}
