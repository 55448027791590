import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { EventForm, EventFormsService } from '@yuno/admin/features/events';

@Component({
	selector: 'yuno-admin-events-default-form-item',
	standalone: true,
	imports: [AsyncPipe],
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsDefaultFormItemComponent {
	@Input({ required: true }) form: FormGroup<EventForm>;
	service = inject(EventFormsService);

	customProperty = false;

	onValueChanged(e: string | null | undefined) {
		this.customProperty = !!e?.includes('{{');
	}
}
