import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ValidationService {
	private validate: Subject<boolean> = new Subject<boolean>();
	validate$: Observable<boolean> = this.validate.asObservable();

	setValidation(bool: boolean): void {
		this.validate.next(bool);
	}
}
