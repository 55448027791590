<yuno-edit-container [col]="6" [pos]="2" [span]="4" [style.zIndex]="250">
	<ng-container actions>
		<button yuno-admin-add-button (click)="onAddEvent()">Add event</button>
	</ng-container>
	<ng-container buttons>
		<button yuno-admin-close-button (click)="onClose()"></button>
	</ng-container>

	<div class="grid h-full grid-cols-2 gap-2">
		<div
			edit-container-content
			[ngClass]="{
				'col-span-2': !editItem
			}">
			<div class="mb-4 flex justify-between">
				<h2>Events</h2>
			</div>

			@if (group) {
				<form [formGroup]="group">
					<div [formArrayName]="arrayName">
						<div
							cdkDropList
							(cdkDropListDropped)="drop($event)"
							class="mt-4 flex flex-col gap-2">
							@for (item of array.controls; track item; let i = $index) {
								<yuno-admin-drag-row
									cdkDrag
									(click)="onEditEvent(item)"
									(mouseenter)="hover = i"
									(mouseleave)="hover = null">
									<span title>
										{{ getEventName(item.value.type) }}
									</span>
									<ng-container buttons>
										<button
											[ngStyle]="{ opacity: hover === i ? 0.93 : 0 }"
											yuno-admin-button
											color="danger"
											(click)="onRemoveEvent(i)">
											Delete
										</button>
									</ng-container>
								</yuno-admin-drag-row>
							}
						</div>
					</div>
				</form>
			}
		</div>
		@if (editItem) {
			<div edit-container-content>
				<yuno-admin-event-item-form [group]="editItem"></yuno-admin-event-item-form>
			</div>
		}
	</div>
</yuno-edit-container>
