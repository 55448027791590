import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { textListsActions } from './text-list.actions';
import { textListsFeature } from './text-list.state';

@Injectable()
export class TextListFacade {
	private readonly store = inject(Store);

	lists$ = this.store.pipe(select(textListsFeature.selectLists));
	status$ = this.store.pipe(select(textListsFeature.selectStatus));

	get() {
		this.store.dispatch(textListsActions.load());
	}
}
