@if (service$ | async; as service) {
	@if (service.component) {
		<form [formGroup]="service.component" class="flex flex-col gap-4">
			<section>
				<yuno-forms-title>Place Marker Category Editor</yuno-forms-title>
				<yuno-forms-span>You can add a category via the dropdown below. </yuno-forms-span>
			</section>
			@if (data$ | async; as data) {
				<section formGroupName="title" class="flex flex-col items-start gap-4">
					@for (lang of editService.languages; track lang) {
						@if (lang === data.language) {
							<yuno-forms-text
								class="w-full"
								[language]="lang"
								[formControlName]="lang"
								placeholder="Toggle title"
								label="Toggle title (optional)"></yuno-forms-text>
						}
					}
				</section>
			}
			<section formGroupName="category">
				<yuno-forms-select
					(changes)="editService.onSelectCategory($event)"
					formControlName="_id"
					placeholder="Select category..."
					label="Place Marker Categories"
					[selectValues]="service.categoryValues"
					[display]="service.categoryDisplay"></yuno-forms-select>
			</section>
			<section class="flex gap-4">
				<yuno-forms-toggle
					formControlName="dropdown"
					label="Display as Dropdown"></yuno-forms-toggle>
				<yuno-forms-toggle formControlName="hide" label="Hide Icon"></yuno-forms-toggle>
			</section>
		</form>
	}
}
