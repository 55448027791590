import { gql } from 'apollo-angular';

export const DELETE_PAGE = gql`
	mutation deletePageById($_id: String!) {
		deletePage(_id: $_id)
	}
`;

export type DeletePageMutation = {
	deletePage: string;
};
