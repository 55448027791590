import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Sidemenu } from '@yuno/api/interface';

import { sidemenuActions } from './sidemenu.actions';

export interface State {
	data: Partial<Sidemenu>[];
	loaded: Status;
	selected: Partial<Sidemenu> | undefined;
}

export const initialState: State = {
	data: [],
	loaded: Status.PENDING,
	selected: undefined
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(sidemenuActions.load, state => ({ ...state, loaded: Status.VALIDATING })),
	on(sidemenuActions.loadSuccess, (state, action) => ({
		...state,
		loaded: Status.SUCCESS,
		data: action.data
	})),
	on(sidemenuActions.loadFailure, state => ({
		...state,
		loaded: Status.FAILED,
		data: []
	})),
	on(sidemenuActions.selectSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(sidemenuActions.selectFailure, state => ({
		...state,
		selected: undefined
	})),
	on(sidemenuActions.clearSelect, state => ({
		...state,
		selected: undefined
	})),
	on(sidemenuActions.updateSelectSuccess, (state, action) => {
		return {
			...state,
			selected: { ...state.selected, ...action.data }
		};
	}),
	on(sidemenuActions.saveSuccess, state => {
		// Extract the 'notifications' and 'selected' properties from the 'state' object using destructuring.
		const list = state.data;
		const model = state.selected;

		// Find the index of the selected model in the list using 'findIndex'.
		const index = list.findIndex(x => x['_id'] === model?.['_id']);

		// Create a new array 'data' by spreading the 'list' array.
		const data = [...list];

		// If the selected model already exists in the array, update it by merging the existing object with the selected object.
		// Otherwise, if the selected model exists and is truthy, add it to the end of the 'data' array.
		if (index >= 0) {
			data[index] = { ...data[index], ...model };
		} else if (model) {
			data.push(model);
		}

		// Sort the 'data' array based on the 'id' property of its elements.
		// The nullish coalescing operator '??' is used to handle undefined or null values in the 'id' property.
		data.sort((a, b) => {
			const idA = a.id ?? '';
			const idB = b.id ?? '';
			return idA > idB ? 1 : -1;
		});

		return {
			...state,
			data: data
		};
	})
);

export const sidemenuFeature = createFeature({
	name: 'sidemenuModern',
	reducer,
	extraSelectors: ({ selectSelected, selectData }) => ({
		selectSelectedSidemenu: createSelector(selectSelected, selectData, (selected, data) =>
			data.find(s => s.id === selected?._id)
		)
	})
});
