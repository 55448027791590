import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { NavbarFacade } from '@yuno/admin/features/navbar';

export function NavbarGuard(display: string, index: number, exact = true): CanActivateFn {
	return (route: ActivatedRouteSnapshot) => {
		const navbarFacade = inject(NavbarFacade);
		navbarFacade.addRoute({ display, index, exact }, route);

		return true;
	};
}

export function NavbarRemoveGuard(index: number): CanActivateFn {
	return () => {
		const navbarFacade = inject(NavbarFacade);
		navbarFacade.removeAt(index);

		return true;
	};
}
