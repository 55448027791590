@if (service$ | async; as service) {
	@if (service.component) {
		<form [formGroup]="service.component" class="flex flex-col gap-4">
			<section>
				<yuno-forms-title>Video Editor</yuno-forms-title>
				<yuno-forms-span>You can edit the selected video component below.</yuno-forms-span>
			</section>
			<yuno-forms-text
				class="flex-1"
				formControlName="id"
				placeholder="video component name"
				label="Video component name"></yuno-forms-text>
			<yuno-forms-divider></yuno-forms-divider>
			<section class="flex gap-2">
				<yuno-forms-text
					(changes)="
						service.component.value.type === 'vimeo'
							? updateVimeo($event)
							: updateYoutube($event)
					"
					class="flex-1"
					formControlName="videoId"
					placeholder="video link"
					label="Video link"></yuno-forms-text>
				<yuno-forms-select
					formControlName="type"
					label="Platform"
					[selectValues]="service.videoSelectValues"
					[display]="service.videoSelectDisplay"></yuno-forms-select>
			</section>
			@if (editService.language$ | async; as language) {
				<section formGroupName="description">
					@for (lang of editService.languages; track lang) {
						@if (lang === language) {
							<yuno-forms-textarea
								[rows]="2"
								[language]="lang"
								class="flex-1"
								[formControlName]="lang"
								placeholder="video description"
								label="Caption"></yuno-forms-textarea>
						}
					}
				</section>
			}
		</form>
	}
	<div class="flex h-full w-full p-6">
		@if (html) {
			<div class="m-auto" [innerHtml]="html"></div>
		}
	</div>
}
