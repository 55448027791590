@if (data$ | async; as data) {
	@if (form) {
		<form [formGroup]="form">
			<yuno-forms-divider></yuno-forms-divider>
			<yuno-forms-title>Active Layers</yuno-forms-title>
			<yuno-forms-span
				>You can link this legend-item with layers. If the selected layer becomes
				hidden/visible, then this legend-item becomes that as well.</yuno-forms-span
			>
			<div formArrayName="activeLayers" class="my-2 grid grid-cols-1 gap-1">
				@for (alias of activeForm.controls; track alias; let i = $index) {
					<div
						[formGroupName]="i"
						class="flex w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-300 bg-gray-200 p-2">
						<yuno-forms-select
							formControlName="_id"
							class="flex-1"
							placeholder="Choose Layer..."
							[selectValues]="service.layerValues"
							[display]="service.layerDisplay"></yuno-forms-select>
						<div class="flex justify-center">
							<button yuno-admin-button color="danger" (click)="removeLayerAt(i)">
								remove
							</button>
						</div>
					</div>
				}
			</div>
			<button yuno-admin-button color="primary" (click)="addLayer()" [add]="true">
				Add Layer
			</button>
		</form>
	}
}
