export const loadScript = (src: string): Promise<boolean> => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.onload = () => resolve(true);
		script.onerror = (err?: unknown) => reject(err || `Loading of: '${src}' went wrong!`);
		script.src = src;
		document.head.append(script);
	});
};
