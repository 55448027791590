import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';

import { LanguagePipe } from '@yuno/angular/pipes';

import { JsonFormControl } from '../../../json-forms.models';
import { SafeHtmlPipe } from '../../../pipes/safe-html/safe-html.pipe';

@Component({
	selector: 'yuno-json-forms-range',
	templateUrl: './range.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, SafeHtmlPipe, LanguagePipe]
})
export class RangeComponent {
	@Input() form: UntypedFormGroup;
	@Input() control: UntypedFormControl;
	@Input() item: JsonFormControl;
	@Input() language = 'nl';
}
