import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { eventsActions } from '@yuno/admin/features/events/data-access/+state/events.actions';
import { Event } from '@yuno/api/interface';

export interface EventsState {
	editor: {
		open: boolean;
	};
	events: Event[];
	key: 'events' | 'activeEvents' | 'onClick' | 'onMouseMove' | 'onMouseLeave' | string;
}

export const initialState: EventsState = {
	editor: {
		open: false
	},
	events: [],
	key: 'events'
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(eventsActions.toggle, (state, action) => ({
		...state,
		editor: { open: action.bool }
	})),

	on(eventsActions.update, (state, action) => ({
		...state,
		events: action.data
	})),
	on(eventsActions.key, (state, action) => ({
		...state,
		key: action.key
	}))
);

export const eventsFeature = createFeature({
	name: 'events',
	reducer,
	extraSelectors: ({ selectEditor }) => ({
		selectToggle: createSelector(selectEditor, data => data.open)
	})
});
