import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { TilesetsService } from '@yuno/admin/features/tilesets';
import {
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent
} from '@yuno/admin/ui';

@Component({
	selector: 'yuno-upload-mbtiles-container',
	standalone: true,
	imports: [
		CommonModule,
		EditContainerComponent,
		YunoAdminCloseButtonComponent,
		YunoAdminButtonComponent,
		EditContainerContentDirective
	],
	templateUrl: './upload.mbtiles.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadMbTilesContainerComponent {
	readonly service = inject(TilesetsService);

	onFile() {
		const inputFile = document.getElementById('inputfile') as HTMLInputElement;
		inputFile.click();
	}

	onClose(): void {
		this.service.onClose();
	}
}
