<div
	(click)="selectField()"
	class="group flex w-full select-none items-center justify-between gap-4 rounded border border-gray-300 bg-gray-200 p-2 px-4"
	[ngClass]="{
		'cursor-pointer text-gray-500 hover:bg-yuno-blue-hover hover:text-yuno-blue-text':
			!disabled,
		'border-dashed': draggable,
		'pointer-events-none cursor-default border-opacity-60 text-gray-300': disabled
	}">
	{{ display }}
</div>
