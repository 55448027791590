import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	DELETE_PARTICIPATION_NOTIFICATION,
	DUPLICATE_PARTICIPATION_NOTIFICATION,
	GET_PARTICIPATION_NOTIFICATION,
	GET_PARTICIPATION_NOTIFICATIONS,
	NotificationDelete,
	NotificationDuplicate,
	NotificationSave,
	NotificationSelectQuery,
	NotificationsQuery,
	SAVE_PARTICIPATION_NOTIFICATION
} from '../../../utils/graphql';
import { notificationsActions } from './notifications.actions';
import { notificationsFeature } from './notifications.state';

export const loadNotifications = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(notificationsActions.load, notificationsActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<NotificationsQuery>({
						query: GET_PARTICIPATION_NOTIFICATIONS,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getParticipationNotifications) {
								throw new Error('No Participation Settings found');
							}

							return notificationsActions.loadSuccess({
								data: data.data.getParticipationNotifications
							});
						}),
						take(1),
						catchError(error => of(notificationsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectStates = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(notificationsActions.select),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			exhaustMap(([action, appId]) =>
				graphql
					.query<NotificationSelectQuery>({
						query: GET_PARTICIPATION_NOTIFICATION,
						variables: {
							id: action._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getParticipationNotification) {
								throw new Error('No Participation Notification Settings found');
							}

							return notificationsActions.selectSuccess({
								data: data.data.getParticipationNotification
							});
						}),
						take(1),
						catchError(error => of(notificationsActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedNotification = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(notificationsActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Notification!`, 'error');
					throw new Error('no Notification found');
				}
				return notificationsActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(notificationsActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveNotification = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(notificationsActions.save),
			withLatestFrom(
				store.pipe(select(notificationsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<NotificationSave>({
						mutation: SAVE_PARTICIPATION_NOTIFICATION,
						variables: {
							data: { ...selected, updatedAt: undefined },
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveParticipationNotification) {
								throw new Error('error saving');
							}
							store.dispatch(
								notificationsActions.selectSuccess({
									data: data.data.saveParticipationNotification
								})
							);

							return notificationsActions.saveSuccess();
						}),
						take(1),
						catchError(error => {
							return of(notificationsActions.saveFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const duplicateNotification = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(notificationsActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.mutate<NotificationDuplicate>({
						mutation: DUPLICATE_PARTICIPATION_NOTIFICATION,
						variables: {
							_id: data._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateParticipationNotificationById) {
								throw new Error('error duplicating');
							}
							store.dispatch(notificationsActions.reload());
							return notificationsActions.duplicateSuccess({
								data: data.data.duplicateParticipationNotificationById
							});
						}),
						take(1),
						catchError(error => {
							return of(notificationsActions.duplicateFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const deleteNotification = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(notificationsActions.delete),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.mutate<NotificationDelete>({
						mutation: DELETE_PARTICIPATION_NOTIFICATION,
						variables: {
							_id: data._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteParticipationNotificationById) {
								throw new Error('error deleting Notification from the database');
							}
							store.dispatch(notificationsActions.reload());
							return notificationsActions.deleteSuccess({
								_id: data.data?.deleteParticipationNotificationById
							});
						}),
						take(1),
						catchError(error => {
							return of(notificationsActions.deleteFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const saveNotificationSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(notificationsActions.saveSuccess),
			tap(() => {
				message.sendToast(`Notification successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveNotificationFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(notificationsActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Notification!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateNotificationSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(notificationsActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Notification successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateNotificationFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(notificationsActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Notification!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteNotificationSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(notificationsActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Notification successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteNotificationFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(notificationsActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Notification!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
