import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { mapActions } from './map.actions';
import { mapFeature } from './map.state';

@Injectable()
export class MapFacade {
	private readonly store = inject(Store);

	mapStyles$ = this.store.pipe(select(mapFeature.selectMapStyles));
	style$ = this.store.pipe(select(mapFeature.selectStyle));
	default$ = this.store.pipe(select(mapFeature.selectDefaultStyle));
	loaded$ = this.store.pipe(select(mapFeature.selectLoaded));
	status$ = this.store.pipe(select(mapFeature.selectStatus));

	getMapStyles() {
		this.store.dispatch(mapActions.loadMapStyles());
	}

	getStyle(_id: string) {
		this.store.dispatch(mapActions.loadStyle({ _id }));
	}

	clearStyle() {
		this.store.dispatch(mapActions.clearStyle());
	}

	getDefaultStyle(appId: string, style?: string) {
		this.store.dispatch(mapActions.loadDefault({ appId, style }));
	}

	clearDefaultStyle() {
		this.store.dispatch(mapActions.clearDefault());
	}
}
