import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { YunoAdminLanguageSelectorComponent } from '@yuno/admin/ui';

@Injectable({
	providedIn: 'root'
})
export class LanguageFormService {
	private overlay = inject(Overlay);
	private overlayRef: OverlayRef;
	private destroyed$ = new Subject<boolean>();

	isActive = false;

	openLanguageSelector(): void {
		this.isActive = true;

		this.overlayRef = this.overlay.create({
			hasBackdrop: false,
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			positionStrategy: this.overlay.position().global(),
			panelClass: ['z-1000', 'pointer-events-none']
		});

		const componentPortal = new ComponentPortal(YunoAdminLanguageSelectorComponent);

		this.overlayRef.attach(componentPortal);

		this.destroyed$.next(false);
		this.overlayRef
			.backdropClick()
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => this.destroyLanguageSelector());
	}

	destroyLanguageSelector(): void {
		if (!this.overlayRef || !this.isActive) {
			return;
		}

		this.isActive = false;
		this.overlayRef.detach();

		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
