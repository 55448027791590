import { gql } from 'apollo-angular';

import { Fence } from '@yuno/api/interface';

export const SAVE_FENCE = gql`
	mutation saveFence($fence: FenceInput!, $appId: String!) {
		saveFence(fence: $fence, appId: $appId) {
			_id
			id
			minZoom
			maxZoom
		}
	}
`;

export type SaveFenceMutation = {
	saveFence: Partial<Fence>;
};

export const DUPLICATE_FENCE = gql`
	mutation duplicateFence($_id: String!, $appId: String!) {
		duplicateFence(_id: $_id, appId: $appId) {
			_id
			id
			minZoom
			maxZoom
		}
	}
`;

export type DuplicateFenceMutation = {
	duplicateFence: Partial<Fence>;
};
