import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Participated } from '@yuno/api/interface';

import { participationsActions } from './participations.actions';

export interface ParticipationsState {
	data: Partial<Participated>[];
	loaded: Status;
	selected: Participated | undefined;
}

export const initialStateParticipations: ParticipationsState = {
	data: [],
	loaded: Status.PENDING,
	selected: undefined
};

const reducer = createReducer(
	initialStateParticipations,
	on(appActions.reset, () => initialStateParticipations),
	on(participationsActions.reset, () => ({
		...initialStateParticipations
	})),

	on(participationsActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),

	on(participationsActions.loadSuccess, (state, action) => ({
		...state,
		data: action.data,
		loaded: Status.SUCCESS
	})),

	on(participationsActions.loadFailure, state => ({
		...state,
		data: [],
		loaded: Status.FAILED
	})),

	on(participationsActions.selectSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.setOnHoldSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.sendAnswerOnlySuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.setPublishedSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.setArchivedSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.onUpdateAnswerSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.onUpdateReactionSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),

	on(participationsActions.clearSelect, state => ({
		...state,
		selected: undefined
	})),

	on(participationsActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: {
			...state.selected,
			...data
		} as Participated
	}))
);

export const participationsFeature = createFeature({
	name: 'participation-participations',
	reducer,
	extraSelectors: ({ selectData }) => ({
		getCount: (status: Participated['state'] | 'all') =>
			createSelector(selectData, (data): number => {
				if (status === 'all') {
					const archived = data.filter(data => data?.state === 'archived').length || 0;
					const unverified =
						data.filter(data => data?.state === 'unverified').length || 0;

					return data.length - archived - unverified;
				}

				return data.filter(data => data?.state === status).length;
			}),
		getFiltered: (status: Participated['state'] | 'all') =>
			createSelector(selectData, data => {
				if (status === 'all') {
					return data
						.filter(data => data.state !== 'archived' && data.state !== 'unverified')
						.map(data => {
							return {
								...data,
								date: data.status?.new?.modified
							};
						});
				}

				return data
					.filter(data => data?.state === status)
					.map(data => {
						return {
							...data,
							date: data.status?.new?.modified
						};
					});
			})
	})
});
