import { gql } from 'apollo-angular';

export const GET_NAVIGATION_BY_APPID = gql`
	query navigationAdmin($appId: String!) {
		navigationAdmin(appId: $appId) {
			_id
			navigation {
				display
				theme {
					_id
					id
				}
				dropdown {
					item {
						_id
						id
					}
					theme {
						_id
						id
					}
					link
					text
					public
					color
					display
					events {
						type
						options
					}
				}
				link
				public
				events {
					type
					options
				}
			}
		}
	}
`;
