import { gql } from 'apollo-angular';

export const DELETE_CATEGORY = gql`
	mutation deleteCategoryById($_id: String!) {
		deleteCategory(_id: $_id)
	}
`;

export type DeleteCategoryMutation = {
	deleteCategory: string;
};
