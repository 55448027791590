import { Injectable } from '@angular/core';
import { Feature } from 'geojson';
import { Map, MapGeoJSONFeature } from 'maplibre-gl';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import {
	EmbedTextfieldObj,
	Event,
	EventActionHandler,
	generateMapMouseEvent
} from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class EventsService {
	private _event = new Subject<EventActionHandler>();
	event$ = this._event.asObservable();

	private _acceptThirdParty = new BehaviorSubject<boolean>(false);
	acceptThirdParty$ = this._acceptThirdParty.asObservable();

	private _acceptThirdPartyRequest = new BehaviorSubject<boolean>(false);
	acceptThirdPartyRequest$ = this._acceptThirdPartyRequest.asObservable();

	private _embedText = new Subject<EmbedTextfieldObj>();
	embedText$ = this._embedText.asObservable();

	private _map = new ReplaySubject<Map>();
	map$ = this._map.asObservable();

	private _mapStyleUpdate = new Subject<boolean>();
	mapStyleUpdate$ = this._mapStyleUpdate.asObservable();

	setAcceptThirdParty(bool: boolean): void {
		this._acceptThirdParty.next(bool);
	}

	setMap(map: Map): void {
		this._map.next(map);
		this.updateMapStyle();
	}

	updateMapStyle(): void {
		this._mapStyleUpdate.next(true);
	}

	setEmbedText(options?: EmbedTextfieldObj): void {
		this._embedText.next(options);
	}

	acceptThirdPartyRequest(): void {
		this._acceptThirdPartyRequest.next(true);
	}

	sendEvent(events: Event[], feature?: Feature | MapGeoJSONFeature): void {
		if (!feature) {
			feature = {
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Point',
					coordinates: [0, 0]
				}
			};
		}

		this._event.next({
			events,
			feature,
			mouseEvent: generateMapMouseEvent(new MouseEvent('click'), 'click', feature)
		});
	}
}
