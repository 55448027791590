var hat = module.exports = function (bits, base) {
  if (!base) base = 16;
  if (bits === undefined) bits = 128;
  if (bits <= 0) return '0';
  var digits = Math.log(Math.pow(2, bits)) / Math.log(base);
  for (var i = 2; digits === Infinity; i *= 2) {
    digits = Math.log(Math.pow(2, bits / i)) / Math.log(base) * i;
  }
  var rem = digits - Math.floor(digits);
  var res = '';
  for (var i = 0; i < Math.floor(digits); i++) {
    var x = Math.floor(Math.random() * base).toString(base);
    res = x + res;
  }
  if (rem) {
    var b = Math.pow(base, rem);
    var x = Math.floor(Math.random() * b).toString(base);
    res = x + res;
  }
  var parsed = parseInt(res, base);
  if (parsed !== Infinity && parsed >= Math.pow(2, bits)) {
    return hat(bits, base);
  } else return res;
};
hat.rack = function (bits, base, expandBy) {
  var fn = function (data) {
    var iters = 0;
    do {
      if (iters++ > 10) {
        if (expandBy) bits += expandBy;else throw new Error('too many ID collisions, use more bits');
      }
      var id = hat(bits, base);
    } while (Object.hasOwnProperty.call(hats, id));
    hats[id] = data;
    return id;
  };
  var hats = fn.hats = {};
  fn.get = function (id) {
    return fn.hats[id];
  };
  fn.set = function (id, value) {
    fn.hats[id] = value;
    return fn;
  };
  fn.bits = bits || 128;
  fn.base = base || 16;
  return fn;
};