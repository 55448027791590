import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { ThemeEditorService, ThemeForm } from '../../theme-editor.service';

@Component({
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AnnotationComponent],
	selector: 'yuno-admin-theme-settings',
	template: `
		@if (form) {
			<form [formGroup]="form">
				<yuno-forms-title>Theme</yuno-forms-title>
				<yuno-forms-text formControlName="id" label="Theme name"></yuno-forms-text>
				<yuno-forms-divider></yuno-forms-divider>
				<yuno-forms-toggle formControlName="keepView" label="Keep view"></yuno-forms-toggle>
				<yuno-admin-annotation class="col-span-3 mt-4" type="info">
					when this is turned off, the Theme zooms to its own extend onLoad
				</yuno-admin-annotation>
				<yuno-forms-divider></yuno-forms-divider>
				<yuno-forms-toggle
					formControlName="transition"
					label="Animated transition between themes"></yuno-forms-toggle>
				<yuno-admin-annotation class="col-span-3 mt-4" type="info">
					when this is turned off, transitions has no animations. There will be a hard cut
					when transitioning between themes.
				</yuno-admin-annotation>
				<yuno-forms-divider></yuno-forms-divider>
				<yuno-forms-title>Sidemenu</yuno-forms-title>
				<div formGroupName="sidemenu" class="grid gap-2">
					<yuno-forms-select
						formControlName="_id"
						[placeholder]="'none'"
						[selectValues]="service.sidemenuValues"
						[display]="service.sidemenuDisplay"></yuno-forms-select>
				</div>
			</form>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeSettingsComponent {
	readonly service = inject(ThemeEditorService);

	@Input() form: FormGroup<ThemeForm>;
	@Input() sidemenusActive = false;
}
