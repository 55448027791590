@if (form) {
	<form [formGroup]="form">
		<yuno-forms-span
			>Choose an icon based on an image url. This method will override the Icon options, so
			make sure to clear all fields when disbanding this option.</yuno-forms-span
		>
		<yuno-forms-text
			formControlName="image"
			placeholder="image url..."
			label="Image url"></yuno-forms-text>
	</form>
}
