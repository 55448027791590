import { CdkDrag, CdkDragDrop, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	inject
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { ThemeFacade } from '@yuno/admin/features/themes/data-access';
import {
	AnnotationComponent,
	DragRowComponent,
	YunoAdminButtonComponent,
	YunoAdminButtonRoundComponent,
	YunoAdminDeleteAdminButtonComponent
} from '@yuno/admin/ui';
import { YunoFormsModule, moveItemInFormArray } from '@yuno/angular/forms';
import { LanguageAll, LanguageType } from '@yuno/api/interface';

import { ThemeEditorService, ThemeForm } from '../../theme-editor.service';

@Component({
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		YunoAdminButtonComponent,
		YunoAdminButtonRoundComponent,
		AnnotationComponent,
		DragRowComponent,
		DragDropModule,
		CdkDropList,
		CdkDrag,
		YunoAdminDeleteAdminButtonComponent
	],
	selector: 'yuno-admin-theme-map-options',
	templateUrl: './theme-map-options.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeMapOptionsComponent {
	readonly service = inject(ThemeEditorService);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly cdr = inject(ChangeDetectorRef);
	protected readonly languages = LanguageAll;

	@Input() form: FormGroup<ThemeForm>;
	@Input() language?: LanguageType;

	clearMaxBounds(): void {
		this.service.removeMaxBounds();
	}

	toggleMaxBounds(value: Event) {
		const toggle = value.target as HTMLInputElement;
		toggle['checked'] ? this.service.defaultMaxBounds() : this.service.removeMaxBounds();

		this.cdr.markForCheck();
	}

	onSelectedFence(value: Event, type: 'bounds' | 'maxBounds') {
		const fence = (value.target as HTMLSelectElement).value;
		if (type === 'bounds') {
			this.themeFacade.updateBoundsByFence(fence);
			return;
		}

		this.themeFacade.updateMaxBoundsByFence(fence);
	}

	/**
	 * Moves or adds a FormControl between two positions within a FormArray when a key is dropped onto it.
	 * @param event The CdkDragDrop event object.
	 */
	dragDropMapStyles(event: CdkDragDrop<Event[]>): void {
		moveItemInFormArray(this.service.mapStyles, event.previousIndex, event.currentIndex);
	}

	/**
	 * Adds a new MapStyle selector to the Array
	 */
	addMapStyle(): void {
		this.service.addMapStyle();
	}

	/**
	 * Removes an item from the FormArray instance of the current component's Service.
	 * @param index The index of the item to be removed.
	 */
	removeMapStyle(index: number): void {
		this.service.mapStyles.removeAt(index);
	}
}
