import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
	FormsModule,
	ReactiveFormsModule,
	UntypedFormControl,
	UntypedFormGroup
} from '@angular/forms';
import Color from 'color';
import { ColorPickerModule } from 'ngx-color-picker';

import { LanguagePipe } from '@yuno/angular/pipes';

import { JsonFormControl } from '../../../json-forms.models';
import { SafeHtmlPipe } from '../../../pipes/safe-html/safe-html.pipe';

@Component({
	selector: 'yuno-json-forms-color',
	templateUrl: './color.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		ColorPickerModule,
		NgStyle,
		SafeHtmlPipe,
		LanguagePipe
	]
})
export class ColorComponent implements OnInit {
	@Input() form: UntypedFormGroup;
	@Input() control: UntypedFormControl;
	@Input() item: JsonFormControl;
	@Input() language = 'nl';
	@Input() disableBgColor = false;
	colorValue: string;

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.colorValue = this.item.value as string;
	}

	public getTextColor(col: string): string {
		if (!col || this.disableBgColor) {
			return '#000';
		}
		if (!Color(col)) {
			return '#000';
		}
		const color = Color(col);
		const dark = color.isDark();
		return dark ? '#fff' : '#000';
	}
}
