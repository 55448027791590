import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsModule } from '@yuno/angular/forms';

import { ContentForm, legendList } from '../legend-editor.service';

@Component({
	selector: 'yuno-admin-legend-image-editor',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule],
	templateUrl: './legend-image-editor.component.html',
	styleUrls: ['./legend-image-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendImageEditorComponent {
	private _form: FormGroup<ContentForm> | FormGroup<legendList>;

	@Input() set form(content: FormGroup<ContentForm> | FormGroup<legendList>) {
		this._form = content;
	}
	get form(): FormGroup<ContentForm> | FormGroup<legendList> {
		return this._form;
	}
}
