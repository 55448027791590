import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { take } from 'rxjs';

import { TextfieldEditorComponent } from '@yuno/admin/features/textfield-pages/feature/textfields/editor/textfield-editor/textfield-editor.component';
import { AppDataComponent } from '@yuno/admin/utils';

@Component({
	selector: 'yuno-admin-textfield-editor-container',
	standalone: true,
	imports: [TextfieldEditorComponent],
	templateUrl: './textfield-editor-container.component.html',
	styleUrls: ['./textfield-editor-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextfieldEditorContainerComponent extends AppDataComponent implements OnInit {
	id = 'new';

	ngOnInit() {
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			this.id = data.get('id') || 'new';
		});
	}
}
