@if (service$ | async; as service) {
	@if (service.component) {
		@if (data$ | async; as data) {
			<form [formGroup]="service.component" class="flex flex-col gap-4">
				<section>
					<yuno-forms-title>Notification Editor</yuno-forms-title>
					<yuno-forms-span
						>You can add a notification via the settings below.
					</yuno-forms-span>
				</section>
				<div class="flex flex-col gap-4">
					<yuno-forms-text
						class="flex-1"
						formControlName="id"
						placeholder="Add notification name here"
						label="Notification name"></yuno-forms-text>
					<yuno-forms-divider></yuno-forms-divider>
					<section class="grid grid-cols-4 gap-4">
						<ng-container formGroupName="title">
							@for (lang of editService.languages; track lang) {
								@if (lang === data.language) {
									<yuno-forms-text
										class="col-span-3"
										[language]="lang"
										[formControlName]="lang"
										placeholder="Add title text here"
										label="Notification title (optional)"></yuno-forms-text>
								}
							}
						</ng-container>
						<yuno-forms-select
							class="col-span-1"
							formControlName="type"
							placeholder="Select type"
							label="Message type"
							[selectValues]="notificationValues"></yuno-forms-select>
					</section>
					<section formGroupName="content">
						@for (lang of editService.languages; track lang) {
							@if (lang === data.language) {
								<yuno-forms-textarea
									[rows]="3"
									[language]="lang"
									class="flex-1"
									[formControlName]="lang"
									placeholder="Placeholder text"
									label="Notification text"></yuno-forms-textarea>
							}
						}
					</section>
				</div>
			</form>
		}
	}
}
