@if ($languages(); as languages) {
	@if (language$ | async; as language) {
		<div
			class="pointer-events-auto flex h-10 w-72 items-center justify-center gap-4 rounded-b-md bg-yuno-blue px-4 py-2 shadow-md">
			<span class="text-white">Text edit language</span>
			<select (change)="changeLanguage($event)" [(ngModel)]="languageSelected">
				@for (select of languages; track select; let i = $index) {
					<option [value]="select">
						{{ select }}
					</option>
				}
			</select>
		</div>
	}
}
