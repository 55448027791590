import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { MediaVideo } from '@yuno/api/interface';

import { videosActions } from './video.actions';

export interface MediaVideoState {
	videos: Partial<MediaVideo>[];
	status: Status;
}

export const initialStateVideos: MediaVideoState = {
	videos: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateVideos,
	on(appActions.reset, () => initialStateVideos),
	on(videosActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(videosActions.loadSuccess, (state, { data }) => ({
		...state,
		videos: data,
		status: Status.SUCCESS
	})),
	on(videosActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	}))
);

export const videosFeature = createFeature({
	name: 'videos',
	reducer
});
