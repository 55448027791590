import { gql } from 'apollo-angular';

import { CustomSource } from '@yuno/api/interface';

export const SAVE_CUSTOMSOURCE = gql`
	mutation saveCustomSource($customSource: CustomSourceInput!, $appId: String!) {
		saveCustomSource(customSource: $customSource, appId: $appId) {
			_id
			source {
				name
			}
			tileset {
				name
			}
			newData {
				sourceId
				matchKey
				databuffer
				options
			}
			geojsonOptions {
				sourceId
				matchKey
			}
		}
	}
`;

export const DUPLICATE_CUSTOMSOURCE = gql`
	mutation duplicateCustomSourceByAppId($_id: String!, $appId: String!) {
		duplicateCustomSource(_id: $_id, appId: $appId) {
			_id
			source {
				name
				options
			}
		}
	}
`;

export type SaveCustomSourceMutation = {
	saveCustomSource: Partial<CustomSource>;
};

export type DuplicateCustomSourceMutation = {
	duplicateCustomSource: Partial<CustomSource>;
};
