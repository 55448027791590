import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { TextButton } from '@yuno/api/interface';

import { textButtonsActions } from './text-button.actions';

export interface TextButtonState {
	buttons: Partial<TextButton>[];
	status: Status;
}

export const initialStateButton: TextButtonState = {
	buttons: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateButton,
	on(appActions.reset, () => initialStateButton),
	on(textButtonsActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(textButtonsActions.loadSuccess, (state, { data }) => ({
		...state,
		buttons: data,
		status: Status.SUCCESS
	})),
	on(textButtonsActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	}))
);

export const textButtonsFeature = createFeature({
	name: 'textButtons',
	reducer
});
