@if (data$ | async; as data) {
	<div class="pointer-events-none flex w-10 flex-col justify-between gap-2">
		<!-- AREA -->
		@if (settings.polyArea) {
			<yuno-button
				[disablePadding]="true"
				(click)="setDrawType('draw_polygon')"
				yuno-tooltip
				[tooltipText]="'Draw Freeform'"
				tooltipDirection="right">
				<span class="flex w-full justify-center text-yuno-gray-100">
					<yuno-svg
						[src]="
							'https://cdn.projectatlas.app/content/static/icons/draw_area.svg'
						"></yuno-svg>
				</span>
			</yuno-button>
		}
		<!-- RECT -->
		@if (settings.polyRect) {
			<yuno-button
				(click)="setDrawType('draw_rectangle')"
				[disablePadding]="true"
				yuno-tooltip
				[tooltipText]="'Draw Rectangle'"
				tooltipDirection="right">
				<span class="flex w-full justify-center text-yuno-gray-100">
					<yuno-svg
						[src]="
							'https://cdn.projectatlas.app/content/static/icons/draw_rect.svg'
						"></yuno-svg>
				</span>
			</yuno-button>
		}
		<!-- CIRCLE -->
		@if (settings.polyCircle) {
			<yuno-button
				(click)="setDrawType('draw_circle')"
				[disablePadding]="true"
				yuno-tooltip
				[tooltipText]="'Draw Circle'"
				tooltipDirection="right"
				disabled>
				<span class="flex w-full justify-center text-yuno-gray-100">
					<yuno-svg
						[src]="
							'https://cdn.projectatlas.app/content/static/icons/draw_circle.svg'
						"></yuno-svg>
				</span>
			</yuno-button>
		}
		<!-- LINE -->
		@if (settings.line) {
			<yuno-button
				[disablePadding]="true"
				(click)="setDrawType('draw_line_string')"
				yuno-tooltip
				[tooltipText]="'Draw Line'"
				tooltipDirection="right">
				<span class="flex w-full justify-center text-yuno-gray-100">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-5 w-5"
						viewBox="0 0 24 24"
						fill="currentColor">
						<g id="icon_line" data-name="icon line" transform="translate(0.64 0.563)">
							<path
								id="Path_3843"
								data-name="Path 3843"
								d="M914,939l4-9,8,4,4-11"
								transform="translate(-912 -921)"
								fill="none"
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="4"
								opacity="0.5" />
							<rect
								id="Rectangle_3052"
								data-name="Rectangle 3052"
								width="4"
								height="4"
								rx="2"
								transform="translate(0 16)"
								fill="currentColor" />
							<rect
								id="Rectangle_3053"
								data-name="Rectangle 3053"
								width="4"
								height="4"
								rx="2"
								transform="translate(4 7)"
								fill="currentColor" />
							<rect
								id="Rectangle_3054"
								data-name="Rectangle 3054"
								width="4"
								height="4"
								rx="2"
								transform="translate(12 11)"
								fill="currentColor" />
							<rect
								id="Rectangle_3055"
								data-name="Rectangle 3055"
								width="4"
								height="4"
								rx="2"
								transform="translate(16)"
								fill="currentColor" />
						</g>
					</svg>
				</span>
			</yuno-button>
		}
		<!-- POINT -->
		@if (settings.point) {
			<yuno-button
				[disablePadding]="true"
				(click)="setDrawType('draw_point')"
				yuno-tooltip
				[tooltipText]="'Draw Point'"
				tooltipDirection="right">
				<yuno-svg
					[fill]="'#D7DADE'"
					[src]="
						'https://cdn.projectatlas.app/content/static/icons/location.svg'
					"></yuno-svg>
			</yuno-button>
		}
		<!-- DELETE -->
		@if (data.selection) {
			<yuno-button
				[disablePadding]="true"
				(click)="deleteDrawing()"
				yuno-tooltip
				[tooltipText]="'Delete Drawing'"
				tooltipDirection="right">
				<span class="flex w-full justify-center text-yuno-gray-100">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="m-0 h-5 p-0"
						viewBox="0 0 108 124"
						fill="currentColor">
						<path
							class="cls-1"
							d="M11.17,37.16H94.65a8.4,8.4,0,0,1,2,.16,5.93,5.93,0,0,1,2.88,1.56,5.43,5.43,0,0,1,1.64,3.34,7.65,7.65,0,0,1-.06,1.44L94,117.31v0l0,.13,0,.28v0a7.06,7.06,0,0,1-.2.9v0l0,.06v0a5.89,5.89,0,0,1-5.47,4.07H17.32a6.17,6.17,0,0,1-1.25-.19,6.17,6.17,0,0,1-1.16-.48h0a6.18,6.18,0,0,1-3.08-4.88l-7-73.49a7.69,7.69,0,0,1-.06-1.66,5.37,5.37,0,0,1,1.63-3.29,6,6,0,0,1,3-1.58,8.94,8.94,0,0,1,1.79-.13ZM5.65,8.8H37.12V6h0a2.44,2.44,0,0,1,0-.27,6,6,0,0,1,1.76-4h0A6,6,0,0,1,43.09,0H62.46l.3,0a6,6,0,0,1,5.7,6V6h0V8.8h32l.39,0a4.7,4.7,0,0,1,4.31,4.43c0,.18,0,.32,0,.5v9.86a2.59,2.59,0,0,1-2.59,2.59H2.59A2.59,2.59,0,0,1,0,23.62V13.53H0a1.56,1.56,0,0,1,0-.31v0A4.72,4.72,0,0,1,3.88,8.88,10.4,10.4,0,0,1,5.65,8.8Zm42.1,52.7a4.77,4.77,0,0,1,9.49,0v37a4.77,4.77,0,0,1-9.49,0v-37Zm23.73-.2a4.58,4.58,0,0,1,5-4.06,4.47,4.47,0,0,1,4.51,4.46l-2,37a4.57,4.57,0,0,1-5,4.06,4.47,4.47,0,0,1-4.51-4.46l2-37ZM25,61.7a4.46,4.46,0,0,1,4.5-4.46,4.58,4.58,0,0,1,5,4.06l2,37a4.47,4.47,0,0,1-4.51,4.46,4.57,4.57,0,0,1-5-4.06l-2-37Z" />
					</svg>
				</span>
			</yuno-button>
		}
	</div>
}
