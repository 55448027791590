import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';

export interface StateForm {
	_id: FormControl<string>;
	state: FormControl<string>;
	display: FormGroup<LanguageFormType>;
}

@Injectable({
	providedIn: 'root'
})
export class StateEditorService {
	form: FormGroup<StateForm>;

	selectTypes = [
		'fill',
		'line',
		'symbol',
		'circle',
		'fill-extrusion',
		'raster',
		'background',
		'heatmap',
		'hillshade'
	];

	sources: string[] = [];
	sourceLayers: string[] = [];

	createFormGroup(): void {
		this.form = new FormGroup<StateForm>({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{
					nonNullable: true,
					validators: [Validators.required, Validators.minLength(4)]
				}
			),
			state: new FormControl('', { nonNullable: true, validators: Validators.required }),
			display: newLanguageFormGroup()
		});
	}
}
