import { Injectable, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface LayerEditModeForm {
	canMove: FormControl<boolean>;
	canScale: FormControl<boolean>;
	canRotate: FormControl<boolean>;
}

export interface LayerForm {
	_id: FormControl<string>;
	public: FormControl<boolean>;
	id: FormControl<string>;
	type: FormControl<string>;
	order: FormControl<number>;
	after: FormControl<string | null>;
	before: FormControl<string | null>;
	maxzoom: FormControl<number | null>;
	minzoom: FormControl<number | null>;
	source?: FormControl<string | null>;
	sourceLayer?: FormControl<string | null>;
	editMode?: FormGroup<LayerEditModeForm>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filter?: FormArray<any>;
	layout?: FormGroup;
	paint?: FormGroup;
	// metadata?: unknown;
	// interactive?: FormControl<boolean>;
	// ref?: FormControl<string | null>;
}

@Injectable()
export class LayerEditorService {
	private formBuilder = inject(FormBuilder);

	form: FormGroup<LayerForm>;

	selectTypes = [
		'fill',
		'line',
		'symbol',
		'circle',
		'fill-extrusion',
		'raster',
		'background',
		'heatmap',
		'hillshade'
	];

	sources: string[] = [];
	sourceLayers: string[] = [];

	get source(): FormControl {
		return this.form.get('source') as FormControl;
	}

	get sourceLayer(): FormControl {
		return this.form.get('sourceLayer') as FormControl;
	}

	get filter(): FormArray {
		return this.form.get('filter') as FormArray;
	}

	get layout(): FormGroup {
		return this.form.get('layout') as FormGroup;
	}

	get paint(): FormGroup {
		return this.form.get('paint') as FormGroup;
	}

	createFormGroup(): void {
		this.form = new FormGroup<LayerForm>({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{
					nonNullable: true
				}
			),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			public: new FormControl<boolean>(true, { nonNullable: true }),
			type: new FormControl<string>('', { nonNullable: true }),
			order: new FormControl(1, { nonNullable: true }),
			before: new FormControl(''),
			after: new FormControl(''),
			source: new FormControl('', { nonNullable: true }),
			sourceLayer: new FormControl('', { nonNullable: true }),
			minzoom: new FormControl(null),
			maxzoom: new FormControl(null),
			editMode: new FormGroup({
				canMove: new FormControl<boolean>(false, { nonNullable: true }),
				canScale: new FormControl<boolean>(false, { nonNullable: true }),
				canRotate: new FormControl<boolean>(false, { nonNullable: true })
			}),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			filter: new FormArray<any>([]),
			layout: new FormGroup({}),
			paint: new FormGroup({})
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	updateFormControls(key: 'filter' | 'layout' | 'paint', json: any) {
		if (key === 'layout' || key === 'paint') {
			const formControls = Object.keys(this[key].controls);
			formControls.forEach(control => {
				this[key].removeControl(control);
			});

			for (const control in json) {
				this[key].addControl(control, this.formBuilder.control(''));
			}
			this[key].patchValue(json);
		} else if (key === 'filter') {
			const array: [] = json;
			while (this[key].length !== 0) {
				this[key].removeAt(0);
			}
			array.forEach(item => {
				this[key].push(this.formBuilder.control(item));
			});
		}
	}
}
