import { ChangeDetectionStrategy, Component, Input, OnInit, Optional, Self } from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	FormsModule,
	NgControl,
	ReactiveFormsModule,
	UntypedFormControl
} from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { v4 as uuid } from 'uuid';

const TinyMceInit: EditorComponent['init'] = {
	promotion: false,
	suffix: '.min',
	height: 400,
	plugins:
		'searchreplace autolink directionality code visualblocks visualchars link codesample table nonbreaking anchor insertdatetime advlist lists wordcount quickbars autoresize',
	paste_as_text: true,
	branding: false,
	menubar: 'edit insert format table',
	quickbars_insert_toolbar: false,
	contextmenu: 'copy paste | link image inserttable | cell row column deletetable',
	table_style_by_css: true,
	skin: 'oxide',
	toolbar:
		'undo redo | blocks | bold italic blockquote | fontsizeselect formatselect | numlist bullist | removeformat link codesample | code'
};

@Component({
	selector: 'yuno-tinymce-editor',
	template: `
		@if (init && controlValue && id) {
			<editor
				[id]="id"
				[init]="init"
				[ngModel]="controlValue"
				(ngModelChange)="onModelChange($event)"></editor>
		}
	`,
	styleUrls: ['./tinymce-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [EditorComponent, FormsModule]
})
export class TinymceEditorComponent implements OnInit {
	readonly id = uuid();

	tinymceEditor: EditorComponent['init'] = TinyMceInit;
	init: EditorComponent['init'];

	@Input() control: UntypedFormControl = new UntypedFormControl('');
	@Input() validate = false;
	@Input() validationMessage: string;
	@Input() height: 400;

	get controlValue(): string {
		return this.control.value || '';
	}

	ngOnInit(): void {
		this.init = {
			...this.tinymceEditor,
			...{ height: this.height }
		};
	}

	onModelChange(val: string): void {
		this.control.patchValue(val);
	}
}

const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
	writeValue(): void {
		//
	},
	registerOnChange(): void {
		//
	},
	registerOnTouched(): void {
		//
	}
};

@Component({
	selector: 'yuno-tinymce-editor-controlname',
	template: `
		<ng-container [formGroup]="getFormGroup()">
			@if (!!ngControl.disabled) {
				<div
					class="absolute bottom-0 left-0 right-0 top-6 z-100 cursor-default rounded-lg border-2 border-gray-400 bg-gray-400/30"></div>
			}
			<editor [id]="id" [init]="init" [formControlName]="ngControl.name"></editor>
		</ng-container>
	`,
	styleUrls: ['./tinymce-editor.component.scss'],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, EditorComponent]
})
export class TinymceEditorFormControlNameComponent implements OnInit {
	readonly id = uuid();

	tinymceEditor: EditorComponent['init'] = TinyMceInit;
	init: EditorComponent['init'];

	@Input() validationMessage: string;
	@Input() height: 400;
	@Input() disabled = false;

	constructor(
		@Self()
		@Optional()
		public ngControl: NgControl
	) {
		if (this.ngControl) {
			this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
		}
	}

	ngOnInit(): void {
		this.init = {
			...this.tinymceEditor,
			...{ height: this.height }
		};
	}

	getFormControl(): FormControl {
		return this.ngControl.control as FormControl;
	}

	getFormGroup(): FormGroup {
		return this.ngControl.control?.parent as FormGroup;
	}
}
