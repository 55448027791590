@if (data$ | async; as data) {
	@if (form) {
		<form [formGroup]="form">
			<div
				cdkDropList
				[cdkDropListData]="service.list"
				(cdkDropListDropped)="drop($event)"
				formArrayName="list">
				<div class="mb-1 mt-6">
					<span class="text-sm font-semibold">List</span>
				</div>
				@for (alias of service.list.controls; track alias; let i = $index) {
					<div
						cdkDrag
						[formGroupName]="i"
						class="mb-2 flex w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-300 bg-yuno-blue-gray-200 p-2">
						<div cdkDragHandle class="hover:cursor-move">
							<svg
								viewBox="0 0 16 24"
								class="mx-2 h-6 w-4 text-gray-400 group-hover:text-yuno-blue-text"
								fill="currentColor">
								<use href="#drag-icon" />
							</svg>
						</div>
						<!-- SVG -->
						@if (!alias.value.image && (alias.value.svg || alias.value.class)) {
							<yuno-svg
								class="mt-1 h-4 w-6"
								[src]="alias.value.svg || getIconUrl(data.icons, alias.value.class)"
								[fill]="alias.value.color"
								[parentScale]="true"></yuno-svg>
						}
						<span>{{ alias.value.title | languageSelect: language }}</span>
						<div class="flex-1"></div>
						<div class="flex justify-end gap-2">
							<button
								yuno-admin-duplicate-button
								(click)="addList(alias.value)"></button>
							<button yuno-admin-edit-button (click)="editListAt(i)"></button>
							<button yuno-admin-delete-button (click)="removeListAt(i)"></button>
						</div>
					</div>
				}
			</div>
		</form>
	}
}
