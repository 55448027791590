@if (data$ | async; as data) {
	<div class="pointer-events-none relative flex h-full w-full flex-col overflow-visible">
		@if (minimalAppRole(userRoles.ADMIN)) {
			<yuno-admin-navbar-buttons-container>
				<button yuno-admin-button color="secondary" (click)="openEditor(['edit'])">
					Edit
				</button>
			</yuno-admin-navbar-buttons-container>
		}
		<div
			class="pointer-events-auto relative h-full w-full overflow-hidden rounded-lg bg-gray-500 p-4 shadow-sm">
			<router-outlet></router-outlet>
			<yuno-admin-place-markers-map-viewer
				class="absolute left-0 top-0 z-0 h-full w-full"></yuno-admin-place-markers-map-viewer>
		</div>
	</div>
}
