import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { GfsImageModel, MediaImage } from '@yuno/api/interface';

export const imagesActions = createActionGroup({
	source: 'Images',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<MediaImage>[] }>(),
		loadFailure: props<{ error: Error }>(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<MediaImage> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		loadImageFiles: emptyProps(),
		loadImageFilesSuccess: props<{ data: Partial<GfsImageModel>[] }>(),
		loadImageFilesFailure: props<{ error: Error }>()
	}
});
