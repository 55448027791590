import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { MediaNotification } from '@yuno/api/interface';

import { notificationActions } from './notification.actions';

export interface MediaNotificationState {
	notifications: Partial<MediaNotification>[];
	status: Status;
}

export const initialStateNotifications: MediaNotificationState = {
	notifications: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateNotifications,
	on(appActions.reset, () => initialStateNotifications),
	on(notificationActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(notificationActions.loadSuccess, (state, { data }) => ({
		...state,
		notifications: data,
		status: Status.SUCCESS
	})),
	on(notificationActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	}))
);

export const notificationFeature = createFeature({
	name: 'notifications',
	reducer
});
