@if (data$ | async; as data) {
	@if (data.style.category) {
		@if (mapStyle$ | async; as mapStyle) {
			<mgl-map
				class="z-0 h-full w-full"
				(mapLoad)="mapLoad($event)"
				(moveEnd)="moveEnd($event)"
				[style]="mapStyle"
				[zoom]="[zoom]"
				[center]="center"
				[pitch]="[pitch]"
				[bearing]="[bearing]"
				[fitBounds]="data.bounds"
				[fitBoundsOptions]="data.boundsOptions">
				<ng-content></ng-content>
				@if (service.map$ | async; as map) {
					<!-- Markers other than the Selected Marker -->
					@for (marker of data.style.photos; track marker) {
						@if (data.style.geoPhoto?.data?.id !== marker.data.id) {
							<yuno-project-atlas-marker
								(mouseClickEvent)="markerMouseClickEvent($event)"
								[map]="map"
								[placeMarker]="true"
								[language]="data.language"
								[setMarkerType]="'photo'"
								[geometry]="marker.geometry"
								[data]="marker.data"></yuno-project-atlas-marker>
						}
					}
					<!-- Selected Marker -->
					@if (data.style.geoPhoto; as marker) {
						<yuno-project-atlas-marker
							(mouseClickEvent)="markerMouseClickEvent($event)"
							(dragEnd)="dragEnd($event)"
							[map]="map"
							[draggable]="data.draggable"
							[language]="data.language"
							[setMarkerType]="'photo'"
							[geometry]="marker.geometry"
							[data]="marker.data"></yuno-project-atlas-marker>
					}
				}
			</mgl-map>
		}
	}
}
