import { state } from '@angular/animations';
import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService, toastMessage } from '@yuno/angular/notifications';

import {
	DELETE_SIDEMENU,
	DUPLICATE_SIDEMENU,
	DeleteMutation,
	DupeMutation,
	GET_SIDEMENU,
	GET_SIDEMENUS,
	GetQuery,
	SAVE_SIDEMENU,
	SaveMutation,
	SelectQuery
} from '../../utils';
import { sidemenuActions } from './sidemenu.actions';
import { sidemenuFeature } from './sidemenu.state';

export const loadSidemenus = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sidemenuActions.load, sidemenuActions.reload),
			withLatestFrom(store.select(appFeature.selectAppId)),
			switchMap(([, appId]) =>
				graphql
					.query<GetQuery>({
						query: GET_SIDEMENUS,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getSidemenus) {
								throw new Error('no sidemenus found');
							}

							return sidemenuActions.loadSuccess({
								data: data.data.getSidemenus
							});
						}),
						take(1),
						catchError(error => of(sidemenuActions.loadFailure({ error })))
					)
			)
		),
	{
		functional: true
	}
);

export const loadSidemenusFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sidemenuActions.loadFailure),
			map(() => message.showToast(toastMessage(`Error loading Sidemenus!`), 'error'))
		),
	{
		functional: true,
		dispatch: false
	}
);

export const select = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sidemenuActions.select),
			withLatestFrom(store.select(appFeature.selectAppId)),
			switchMap(([action, appId]) =>
				graphql
					.query<SelectQuery>({
						query: GET_SIDEMENU,
						variables: {
							appId,
							id: action._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getSidemenu) {
								throw new Error('no sidemenus found');
							}

							return sidemenuActions.selectSuccess({
								data: data.data.getSidemenu
							});
						}),
						take(1),
						catchError(error => of(sidemenuActions.loadFailure({ error })))
					)
			)
		),
	{
		functional: true
	}
);

export const selectFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sidemenuActions.selectFailure),
			map(() => message.showToast(toastMessage(`Error selecting Sidemenus!`), 'error'))
		),
	{
		functional: true,
		dispatch: false
	}
);

export const onSave = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		graphql = inject(GraphQLService),
		message = inject(MessageService)
	) =>
		actions$.pipe(
			ofType(sidemenuActions.save),
			withLatestFrom(
				store.select(sidemenuFeature.selectSelected),
				store.select(appFeature.selectAppId)
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveMutation>({
						mutation: SAVE_SIDEMENU,
						variables: {
							data: { ...selected, updatedAt: undefined },
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveSidemenu) {
								throw new Error('error saving to the database');
							}

							message.showToast(toastMessage(`Successfully saved!`), 'success');

							// Updates with new Data after save
							// so the Table will update correctly
							if (!selected?._id) {
								store.dispatch(
									sidemenuActions.selectSuccess({
										data: data.data.saveSidemenu
									})
								);
							}

							return sidemenuActions.saveSuccess();
						}),
						take(1),
						catchError(error => {
							message.showToast(toastMessage(`Error saving!`), 'error');
							return of(sidemenuActions.saveFailure({ error }));
						})
					)
			)
		),
	{
		functional: true
	}
);

export const onDelete = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		graphql = inject(GraphQLService),
		message = inject(MessageService)
	) =>
		actions$.pipe(
			ofType(sidemenuActions.delete),
			withLatestFrom(store.select(appFeature.selectAppId)),
			switchMap(([data, appId]) =>
				graphql
					.mutate<DeleteMutation>({
						mutation: DELETE_SIDEMENU,
						variables: {
							_id: data._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteSidemenuById) {
								throw new Error('error deleting Theme from the database');
							}
							store.dispatch(sidemenuActions.reload());
							return sidemenuActions.deleteSuccess({
								_id: data.data?.deleteSidemenuById
							});
						}),
						take(1),
						catchError(error => {
							message.showToast(toastMessage(`Error deleting Sidemenu!`), 'error');
							return of(sidemenuActions.deleteFailure({ error }));
						})
					)
			)
		),
	{
		functional: true
	}
);

export const deleteSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sidemenuActions.deleteSuccess),
			map(() => {
				message.showToast(toastMessage(`Successfully deleted!`), 'success');
				return sidemenuActions.load();
			})
		),
	{
		functional: true
	}
);

export const onUpdate = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(sidemenuActions.updateSelect),
			map(action =>
				sidemenuActions.updateSelectSuccess({
					data: action.data
				})
			)
		),
	{
		functional: true
	}
);

export const onDuplicate = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sidemenuActions.duplicate),
			withLatestFrom(store.select(appFeature.selectAppId)),
			switchMap(([data, appId]) =>
				graphql
					.mutate<DupeMutation>({
						mutation: DUPLICATE_SIDEMENU,
						variables: {
							_id: data._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateSidemenuById) {
								throw new Error('error duplicating Sidemenu to the database');
							}
							store.dispatch(sidemenuActions.reload());
							return sidemenuActions.duplicateSuccess({
								data: data.data.duplicateSidemenuById
							});
						}),
						take(1),
						catchError(error => of(sidemenuActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);
