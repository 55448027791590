import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { filesActions } from './files.actions';
import { filesFeature } from './files.state';

@Injectable()
export class FilesFacade {
	private readonly store = inject(Store);

	files$ = this.store.pipe(select(filesFeature.selectFiles));
	selected$ = this.store.pipe(select(filesFeature.selectSelected));
	loaded$ = this.store.pipe(select(filesFeature.selectLoaded));
	selectedLoaded$ = this.store.pipe(select(filesFeature.selectSelectedLoaded));

	/**
	 * Use the initialization action to perform one
	 * or more tasks in your Effects.
	 */
	get() {
		this.store.dispatch(filesActions.load());
	}

	search(filter: string) {
		this.store.dispatch(filesActions.loadFiltered({ filter }));
	}

	select(_id: string | undefined) {
		if (!_id) {
			return;
		}
		this.store.dispatch(filesActions.select({ _id }));
	}

	clearSelect() {
		this.store.dispatch(filesActions.clearSelect());
	}

	save() {
		this.store.dispatch(filesActions.save());
	}
}
