import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { YunoAdminButtonsModule } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { ThemeEditorService, ThemeForm } from '../../theme-editor.service';

@Component({
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, YunoAdminButtonsModule],
	selector: 'yuno-admin-theme-map-ui',
	templateUrl: './theme-map-ui.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeMapUiComponent {
	readonly service = inject(ThemeEditorService);

	@Input() form: FormGroup<ThemeForm>;

	removeItemAt(i: number): void {
		this.service.removeAvailableState(i);
	}
	addItem(): void {
		this.service.addAvailableState();
	}
}
