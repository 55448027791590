import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import { YunoFormsDefaultTemplateComponent } from '../_default/default.component';

@Component({
	selector: 'yuno-forms-file',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			@if (value) {
				<input
					class="truncate"
					type="text"
					[value]="value"
					[id]="controlName"
					[name]="controlName"
					[formControlName]="controlName" />
			}
			@if (!value) {
				<input
					class="truncate"
					type="file"
					[accept]="accept"
					[id]="controlName"
					[name]="controlName"
					[formControlName]="controlName"
					[placeholder]="placeholder" />
			}
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, YunoFormsLabelRequired]
})
export class YunoFormsFileComponent {
	@Input() label?: string;
	@Input() controlName: string;
	@Input() placeholder? = '';

	@Input() required?: boolean;

	@Input() value?: string;
	@Input() accept? =
		'.json, .geojson, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
}

@Component({
	selector: 'yuno-forms-template-file',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			@if (value) {
				<input
					class="truncate"
					type="text"
					[ngModel]="value"
					(ngModelChange)="valueChange($event)"
					[placeholder]="placeholder" />
			}
			@if (!value) {
				<input
					class="truncate"
					type="file"
					[accept]="accept"
					[ngModel]="value"
					(ngModelChange)="valueChange($event)"
					[placeholder]="placeholder" />
			}
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, YunoFormsLabelRequired],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsFileTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsFileTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Input() controlName: string;
	@Input() accept? =
		'.json, .geojson, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
}
