import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import {
	ParticipationFacade,
	ParticipationModelFacade,
	ParticipationNotificationFacade
} from '../data-access';

export const DeactivateParticipationGuard: CanActivateFn = () => {
	const modelFacade = inject(ParticipationModelFacade);
	const notificationFacade = inject(ParticipationNotificationFacade);
	const viewFacade = inject(ParticipationFacade);

	modelFacade.reset();
	notificationFacade.reset();
	viewFacade.reset();

	return true;
};
