<form class="grid gap-4" [formGroup]="formGroup" [ngClass]="{ 'gap-0': noMargin }">
	@if (formData.label || formData.labelDescr) {
		<section class="flex flex-col gap-2">
			@if (formData.label) {
				<span class="text-xl font-bold">{{ formData.label }}</span>
			}
			@if (formData.labelDescr) {
				<span class="mb-4 text-sm">{{ formData.labelDescr }}</span>
			}
		</section>
	}

	<section class="grid gap-4" [ngClass]="columns(formData.columns)">
		<!-- Generate FormView -->
		@for (item of formData.controls; track i; let i = $index) {
			@switch (item.controlType) {
				@case ('array-control') {
					@if (asArray(item); as arrayControlItem) {
						<yuno-json-forms-types-array
							[formValue]="formValue[i]"
							[form]="formGroup"
							[item]="arrayControlItem"
							[control]="arrayControlItem.control" />
					}
				}
				@case ('button') {
					@if (asButton(item); as buttonItem) {
						<yuno-json-forms-types-button [item]="buttonItem" />
					}
				}
				@case ('label') {
					@if (asLabel(item); as labelItem) {
						<yuno-json-forms-types-label [item]="labelItem" />
					}
				}
				@case ('divider') {
					<yuno-json-forms-types-divider />
				}
				@case ('array') {
					@if (asArray(item); as arrayControlItem) {
						<yuno-json-forms-types-array
							[formValue]="formValue?.[item.key]"
							[form]="formGroup"
							[item]="arrayControlItem"
							[control]="arrayControlItem.control">
						</yuno-json-forms-types-array>
					}
				}
				@case ('group') {
					@if (asGroup(item); as groupControlItem) {
						<yuno-json-forms-types-group
							[formValue]="formValue?.[item.key]"
							[form]="formGroup"
							[item]="groupControlItem"
							[control]="groupControlItem.control" />
					}
				}
				@case ('language') {
					@if (asFormLanguage(item); as formLanguageItem) {
						<yuno-json-forms-language
							[form]="formGroup"
							[item]="formLanguageItem"
							[control]="formLanguageItem.control" />
					}
				}
				@case ('control') {
					@if (asFormControl(item); as formControlItem) {
						<yuno-json-forms-types-control
							[form]="formGroup"
							[item]="formControlItem"
							[control]="formControlItem.control" />
					}
				}
			}
		}
	</section>
</form>
