import { gql } from 'apollo-angular';

import { Source } from '@yuno/api/interface';

export const SAVE_SOURCE = gql`
	mutation saveSource($source: SourceInput!, $appId: String!) {
		saveSource(source: $source, appId: $appId) {
			_id
			id
			sourceData
		}
	}
`;

export const DUPLICATE_SOURCE = gql`
	mutation duplicateSource($_id: String!, $appId: String!) {
		duplicateSource(_id: $_id, appId: $appId) {
			_id
			id
			sourceData
		}
	}
`;

export type SaveSourceMutation = {
	saveSource: Partial<Source>;
};

export type DuplicateSourceMutation = {
	duplicateSource: Partial<Source>;
};
