import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_OBJECT,
	DUPLICATE_OBJECT,
	DeleteObjectMutation,
	DuplicateObjectMutation,
	GET_OBJECTS_BY_APPID,
	GET_OBJECT_BY_ID,
	ObjectsQuery,
	SAVE_OBJECT,
	SaveObjectMutation,
	SelectObjectQuery
} from '@yuno/admin/features/objects/utils';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { objectsActions } from './objects.actions';
import { objectsFeature } from './objects.state';

export const loadObjects = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(objectsActions.load, objectsActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<ObjectsQuery>({
						query: GET_OBJECTS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.objects) {
								throw new Error('no Objects found');
							}
							return objectsActions.loadSuccess({
								data: data.data.objects
							});
						}),
						take(1),
						catchError(error => of(objectsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectObject = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(objectsActions.select),
			exhaustMap(object =>
				graphql
					.query<SelectObjectQuery>({
						query: GET_OBJECT_BY_ID,
						variables: {
							_id: object._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedObjects) {
								throw new Error('no Object with that id found');
							}
							return objectsActions.selectSuccess({
								data: data.data.selectedObjects
							});
						}),
						take(1),
						catchError(error => of(objectsActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedObject = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(objectsActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Object!`, 'error');
					throw new Error('no Object found');
				}
				return objectsActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(objectsActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveObject = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(objectsActions.save),
			withLatestFrom(
				store.pipe(select(objectsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveObjectMutation>({
						mutation: SAVE_OBJECT,
						variables: {
							object: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveObject) {
								throw new Error('error saving object to the database');
							}
							store.dispatch(
								objectsActions.updateSelect({ data: data.data.saveObject })
							);
							return objectsActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(objectsActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateObject = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(objectsActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.mutate<DuplicateObjectMutation>({
						mutation: DUPLICATE_OBJECT,
						variables: {
							object: data.data,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateObject) {
								throw new Error('error saving Object to the database');
							}
							store.dispatch(objectsActions.reload());
							return objectsActions.duplicateSuccess({
								data: data.data.duplicateObject
							});
						}),
						take(1),
						catchError(error => of(objectsActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteObject = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(objectsActions.delete),
			switchMap(data =>
				graphql
					.mutate<DeleteObjectMutation>({
						mutation: DELETE_OBJECT,
						variables: {
							object: data.data
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteObject) {
								throw new Error('error deleting Object from the database');
							}
							store.dispatch(objectsActions.reload());
							return objectsActions.deleteSuccess({
								data: data.data.deleteObject
							});
						}),
						take(1),
						catchError(error => of(objectsActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveObjectSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(objectsActions.saveSuccess),
			tap(() => {
				message.sendToast(`Object successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveObjectFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(objectsActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Object!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateObjectSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(objectsActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Object successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateObjectFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(objectsActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Object!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteObjectSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(objectsActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Object successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteObjectFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(objectsActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Object!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
