import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { ENVIRONMENT } from '@yuno/admin/core';
import { ControlsOf } from '@yuno/angular/forms';
import { KrpanoInstance } from '@yuno/angular/krpano';
import {
	PanoramaLimitView,
	PanoramaLimitViewEnum,
	PanoramaLocation,
	PanoramaMap,
	PanoramaOffset,
	PanoramaOffsetXyz,
	PanoramaStates,
	PanoramaTextfieldState,
	PanoramaUrl,
	PanoramaView,
	State
} from '@yuno/api/interface';
import { degreesToRadians } from '@yuno/shared/helpers';

export interface PanoramaForm {
	_id: FormControl<string>;
	id: FormControl<string>;
	alt?: FormControl<string>;
	public?: FormControl<boolean>;
	selected?: FormControl<boolean>;
	// map
	map?: FormGroup<ControlsOf<PanoramaMap> | undefined>;
	// server
	url?: FormGroup<ControlsOf<PanoramaUrl> | undefined>;
	// pano
	location?: FormGroup<ControlsOf<PanoramaLocation> | undefined>;
	view?: FormGroup<ControlsOf<PanoramaView> | undefined>;
	limitView?: FormGroup<ControlsOf<PanoramaLimitView> | undefined>;
	offset?: FormGroup<ControlsOf<PanoramaOffset> | undefined>;
	// text
	textfield?: FormGroup<PanoTextfieldForm>;
	states?: FormGroup<ControlsOf<PanoramaStates> | undefined>;
	// other
	tileType?: FormControl<string | null>;
	visibility?: FormControl<string | null>;
}

interface PanoTextfieldForm {
	default?: FormControl<string>;
	states: FormArray<FormGroup<PanoStateForm>>;
}

interface PanoStateForm {
	state?: FormControl<string>;
	text?: FormControl<string>;
}

@Injectable({
	providedIn: 'root'
})
export class PanoramaEditorService {
	readonly environment = inject(ENVIRONMENT);

	form: FormGroup<PanoramaForm>;
	iconValues = ['mp', 'mp-grey', 'vp', 'vp-grey', 'grid'];
	limitValues = ['off', 'auto', 'lookat', 'range', 'fullrange', 'offrange'];
	textfieldDisplay: string[] = [];
	textfieldValues: string[] = [];
	stateDisplay: string[] = [];
	stateValues: string[] = [];

	get map(): FormGroup {
		return this.form.get('map') as FormGroup;
	}

	get mapCoords(): FormArray {
		return this.map.get('coordinates') as FormArray;
	}

	get loc(): FormGroup {
		return this.form.get('location') as FormGroup;
	}

	get locCoords(): FormArray {
		return this.loc.get('coordinates') as FormArray;
	}

	get view(): FormGroup {
		return this.form.get('view') as FormGroup;
	}

	get limit(): FormGroup {
		return this.form.get('limitView') as FormGroup;
	}

	get textfield(): FormGroup {
		return this.form.get('textfield') as FormGroup;
	}

	get states(): FormArray {
		return this.textfield.get('states') as FormArray;
	}

	createFormGroup(): void {
		this.form = new FormGroup<PanoramaForm>({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{
					nonNullable: true
				}
			),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			alt: new FormControl('', { nonNullable: true }),
			public: new FormControl(true, { nonNullable: true }),
			selected: new FormControl(true, { nonNullable: true }),
			// visibility: new FormControl(null),
			tileType: new FormControl(null),

			map: new FormGroup<ControlsOf<PanoramaMap> | undefined>({
				display: new FormControl('', {
					nonNullable: true,
					validators: Validators.required
				}),
				icon: new FormControl('vp', { nonNullable: true, validators: Validators.required }),
				rotation: new FormControl(0, { nonNullable: true }),
				minZoom: new FormControl(1, { nonNullable: true }),
				maxZoom: new FormControl(22, { nonNullable: true }),
				coordinates: new FormArray<FormControl<number>>([
					new FormControl(0, { nonNullable: true }),
					new FormControl(0, { nonNullable: true })
				])
			}),
			url: new FormGroup<ControlsOf<PanoramaUrl> | undefined>({
				preview: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
				project: new FormControl('', { nonNullable: true }),
				pano: new FormControl('', { nonNullable: true, validators: Validators.required }),
				client: new FormControl('', { nonNullable: true }),
				prefix: new FormControl(`${this.environment['yuno-cdn']}/panoramas/clients/`, {
					nonNullable: true
				}),
				xml: new FormControl('', { nonNullable: true })
			}),
			location: new FormGroup<ControlsOf<PanoramaLocation> | undefined>({
				height: new FormControl(0, { nonNullable: true }),
				coordinates: new FormArray<FormControl<number>>([
					new FormControl(0, { nonNullable: true }),
					new FormControl(0, { nonNullable: true })
				])
			}),
			view: new FormGroup<ControlsOf<PanoramaView> | undefined>({
				fov: new FormControl(degreesToRadians(70), { nonNullable: true }),
				pitch: new FormControl(0, { nonNullable: true }),
				yaw: new FormControl(0, { nonNullable: true }),
				roll: new FormControl(0, { nonNullable: true })
			}),
			limitView: new FormGroup<ControlsOf<PanoramaLimitView> | undefined>({
				active: new FormControl(false, { nonNullable: true }),
				hlookatmin: new FormControl(0, { nonNullable: true }),
				hlookatmax: new FormControl(0, { nonNullable: true }),
				vlookatmin: new FormControl(0, { nonNullable: true }),
				vlookatmax: new FormControl(0, { nonNullable: true }),
				limitview: new FormControl<PanoramaLimitViewEnum>('off' as PanoramaLimitViewEnum, {
					nonNullable: true
				})
			}),
			offset: new FormGroup<ControlsOf<PanoramaOffset> | undefined>({
				position: new FormGroup<ControlsOf<PanoramaOffsetXyz>>({
					x: new FormControl(0, { nonNullable: true }),
					y: new FormControl(0, { nonNullable: true }),
					z: new FormControl(0, { nonNullable: true })
				}),
				rotation: new FormGroup<ControlsOf<PanoramaOffsetXyz>>({
					x: new FormControl(0, { nonNullable: true }),
					y: new FormControl(0, { nonNullable: true }),
					z: new FormControl(0, { nonNullable: true })
				})
			}),
			textfield: new FormGroup<PanoTextfieldForm>({
				default: new FormControl('', { nonNullable: true }),
				states: new FormArray<FormGroup<PanoStateForm>>([])
			}),
			states: new FormGroup<ControlsOf<PanoramaStates> | undefined>({
				default: new FormGroup<ControlsOf<State> | undefined>({
					_id: new FormControl('', { nonNullable: true }),
					state: new FormControl('', { nonNullable: true })
				}),
				available: new FormArray<FormGroup<ControlsOf<State>>>([])
			})
		});
	}

	// URL
	removeState(i: number): void {
		this.states.removeAt(i);
	}

	addState(): void {
		this.states.push(
			new FormGroup<PanoStateForm>({
				state: new FormControl('', { nonNullable: true }),
				text: new FormControl('', { nonNullable: true })
			})
		);
	}

	addStates(states?: PanoramaTextfieldState[]): void {
		if (!states) {
			return;
		}
		const count = states.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addState();
			}
		}
	}

	krpanoInstance: KrpanoInstance | null = null;

	setKrpanoInstance(instance: KrpanoInstance | null): void {
		this.krpanoInstance = instance;
	}

	rotatePanorama(yaw: number, pitch: number, fov: number): void {
		this.krpanoInstance?.call(`lookto(${yaw}, ${pitch}, ${fov});`);
	}
}
