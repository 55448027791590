import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';
import { Navigation } from '@yuno/api/interface';

import { GET_NAVIGATION_BY_APPID, SAVE_NAVIGATION } from './../../utils/graphql';
import { navigationActions } from './navigations.actions';
import { navigationFeature } from './navigations.state';

export type NavigationQuery = {
	navigationAdmin: Partial<Navigation>;
};

export type SaveNavigationMutation = {
	saveNavigation: Partial<Navigation>;
};

export const load = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(navigationActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<NavigationQuery>({
						query: GET_NAVIGATION_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(nav => {
							if (!nav.data.navigationAdmin) {
								throw new Error('no Navigation found');
							}
							return navigationActions.loadSuccess({
								data: nav.data.navigationAdmin
							});
						}),
						take(1),
						catchError(error => of(navigationActions.loadFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);

export const save = createEffect(
	(
		actions$ = inject(Actions),
		graphql = inject(GraphQLService),
		message = inject(MessageService),
		store = inject(Store)
	) => {
		return actions$.pipe(
			ofType(navigationActions.save),
			withLatestFrom(
				store.pipe(select(navigationFeature.selectNavigation)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, nav, appId]) =>
				graphql
					.mutate<SaveNavigationMutation>({
						mutation: SAVE_NAVIGATION,
						variables: {
							navigation: nav,
							appId: appId
						}
					})
					.pipe(
						map(navigation => {
							if (!navigation.data?.saveNavigation) {
								message.sendToast(`Error saving Navigation!`, 'error');
								throw new Error('error saving Navigation to the database');
							}
							message.sendToast(`Navigation successfully saved!`, 'success');
							return navigationActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(navigationActions.saveFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);
