@if (service$ | async; as service) {
	@if (data$ | async; as data) {
		@if (service.component) {
			<form [formGroup]="service.component" class="flex flex-col gap-4">
				<section>
					<yuno-forms-title>List Editor</yuno-forms-title>
					<yuno-forms-span>You can edit the selected list below.</yuno-forms-span>
				</section>
				<yuno-forms-text
					class="flex-1"
					formControlName="id"
					placeholder="List name"
					label="List name"></yuno-forms-text>
				<section formGroupName="title" class="flex flex-col items-start gap-4">
					@for (lang of editService.languages; track lang) {
						@if (lang === data.language) {
							<yuno-forms-text
								class="w-full"
								[language]="lang"
								[formControlName]="lang"
								placeholder="List title"
								label="Title"></yuno-forms-text>
						}
					}
				</section>
				<section class="flex justify-between gap-4">
					<yuno-forms-toggle
						formControlName="disableActiveState"
						label="Disable button active state"></yuno-forms-toggle>
					<yuno-forms-toggle
						formControlName="multiselect"
						label="Multi-Select"></yuno-forms-toggle>
					<yuno-forms-toggle
						formControlName="select"
						label="Dropdown"></yuno-forms-toggle>
					<yuno-forms-toggle
						formControlName="keepActiveDataset"
						label="Keep active Dataset"></yuno-forms-toggle>
				</section>
				<section formGroupName="active" class="grid grid-cols-2 gap-x-4">
					<yuno-forms-span class="col-span-2 font-bold"
						>Active/Hover colors</yuno-forms-span
					>
					<yuno-forms-color
						formControlName="color"
						placeholder="#fff"
						label="Text color"></yuno-forms-color>
					<yuno-forms-color
						formControlName="backgroundColor"
						placeholder="#00ADF1"
						label="Background color"></yuno-forms-color>
				</section>
				<yuno-forms-divider></yuno-forms-divider>
				<section
					formArrayName="items"
					cdkDropList
					[cdkDropListData]="service.listItems.controls"
					(cdkDropListDropped)="editService.dropListItems($event)">
					<label class="yuno-form">
						<span class="select-none">Items</span>
					</label>
					@for (item of service.listItems.controls; track item; let i = $index) {
						<div
							cdkDrag
							[formGroupName]="i"
							class="mb-2 flex flex-col gap-2 rounded border border-gray-300 bg-gray-200 p-2">
							<div class="flex gap-2">
								<div class="m-auto mx-2 cursor-move" cdkDragHandle>
									<svg
										viewBox="0 0 16 24"
										class="h-8 w-4"
										fill="#9ca3af"
										border="#9ca3af">
										<use href="#drag-icon" />
									</svg>
								</div>
								<yuno-forms-toggle
									formControlName="active"
									labelPos="side"
									label="Active at startup"></yuno-forms-toggle>
							</div>
							<section class="flex flex-col gap-2 pl-10">
								<div class="flex flex-1 gap-4">
									<yuno-forms-text
										formControlName="id"
										placeholder="Item id"
										label="Id (required for events)"></yuno-forms-text>
									<section formGroupName="title">
										@for (lang of editService.languages; track lang) {
											@if (lang === data.language) {
												<yuno-forms-text
													class="flex-1"
													[language]="lang"
													[formControlName]="lang"
													placeholder="Item name"
													label="Item name"></yuno-forms-text>
											}
										}
									</section>
								</div>
								<section class="flex justify-between gap-2">
									<button
										yuno-admin-button
										(click)="editService.onToggleListEvents(i)">
										Events ({{
											service.listItems.value[i].events?.length || 0
										}})
									</button>
									<button
										yuno-admin-button
										color="danger"
										(click)="service.removeListItem(i)">
										remove
									</button>
								</section>
							</section>
						</div>
					}
					<button yuno-admin-button (click)="service.addListItem()" [add]="true">
						Add Item
					</button>
				</section>
			</form>
		}
	}
}
