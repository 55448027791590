import { add, isAfter, isBefore, isSameDay, parseISO } from 'date-fns';

import { DateOptions } from '@yuno/api/interface';

export type FilterTypes = 'date' | 'string' | 'number';
export type FilterFunctions = 'applyDateFilter' | 'applyStringFilter' | 'applyNumberFilter';

export const addDates = {
	years: (a: number) => add(new Date(), { years: a }),
	quarters: (a: number) => add(new Date(), { months: a * 3 }),
	months: (a: number) => add(new Date(), { months: a }),
	weeks: (a: number) => add(new Date(), { weeks: a }),
	days: (a: number) => add(new Date(), { days: a }),
	hours: (a: number) => add(new Date(), { hours: a }),
	minutes: (a: number) => add(new Date(), { minutes: a }),
	seconds: (a: number) => add(new Date(), { seconds: a }),
	milliseconds: (a: number) => add(new Date(), { seconds: a / 1000 })
};

/* Operators Functions */
export const comparisonOperators = {
	'<': (a: number, b: number) => a < b,
	'>': (a: number, b: number) => a > b,
	'>=': (a: number, b: number) => a >= b,
	'<=': (a: number, b: number) => a <= b,
	'==': (a: number | string, b: number | string) => a === b,
	'===': (a: number | string, b: number | string) => a === b
};

export type comparisonOperatorsType = '<' | '>' | '>=' | '<=' | '==' | '===';

/* Date Operators Functions */
export const comparisonOperatorsDate = {
	'<': (date: Date | string, num: number, token: DateOptions) => {
		if (typeof date === 'string') {
			date = parseISO(date);
		}
		return isBefore(date, addDates[token](num));
	},
	'>': (date: Date | string, num: number, token: DateOptions) => {
		if (typeof date === 'string') {
			date = parseISO(date);
		}
		return isAfter(date, addDates[token](num));
	},
	'>=': (date: Date | string, num: number, token: DateOptions) => {
		if (typeof date === 'string') {
			date = parseISO(date);
		}
		return isAfter(date, addDates[token](num)) || isSameDay(date, new Date());
	},
	'<=': (date: Date | string, num: number, token: DateOptions) => {
		if (typeof date === 'string') {
			date = parseISO(date);
		}
		return isBefore(date, addDates[token](num)) || isSameDay(date, new Date());
	},
	'==': (date: Date | string) => {
		if (typeof date === 'string') {
			date = parseISO(date);
		}
		return isSameDay(date, new Date());
	},
	'===': (date: Date | string) => {
		if (typeof date === 'string') {
			date = parseISO(date);
		}
		return isSameDay(date, new Date());
	}
};

export type comparisonDateTypes = '<' | '>' | '>=' | '<=' | '==' | '===';

export const DateImportance = {
	years: 8,
	quarters: 7,
	months: 6,
	weeks: 5,
	days: 4,
	hours: 3,
	minutes: 2,
	seconds: 1,
	milliseconds: 0
};
