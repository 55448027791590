import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_LEGEND,
	DUPLICATE_LEGEND,
	DeleteLegendMutation,
	DuplicateLegendMutation,
	GET_LEGENDS_BY_APPID,
	GET_LEGEND_BY_ID,
	LegendsQuery,
	SAVE_LEGEND,
	SaveLegendMutation,
	SelectLegendQuery
} from '@yuno/admin/features/legends/utils';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { legendActions } from './legend.actions';
import { legendFeature } from './legend.state';

export const loadLegends = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(legendActions.load, legendActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<LegendsQuery>({
						query: GET_LEGENDS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.legends) {
								throw new Error('no legend found');
							}
							return legendActions.loadSuccess({
								data: data.data.legends
							});
						}),
						take(1),
						catchError(error => of(legendActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectLegend = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(legendActions.select),
			switchMap(legend =>
				graphql
					.query<SelectLegendQuery>({
						query: GET_LEGEND_BY_ID,
						variables: {
							_id: legend._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedLegend) {
								message.sendToast(`Error selecting Legend!`, 'error');
								throw new Error('no Legend with that id found');
							}

							return legendActions.selectSuccess({
								data: data.data.selectedLegend
							});
						}),
						take(1),
						catchError(error => of(legendActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateLegend = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(legendActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Legend!`, 'error');
					throw new Error('no Legend found');
				}
				return legendActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(legendActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveLegend = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(legendActions.save),
			withLatestFrom(
				store.pipe(select(legendFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveLegendMutation>({
						mutation: SAVE_LEGEND,
						variables: {
							legend: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveLegend) {
								throw new Error('error saving legend to the database');
							}
							store.dispatch(
								legendActions.updateSelect({ data: data.data.saveLegend })
							);
							return legendActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(legendActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateLegend = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(legendActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([legend, appId]) =>
				graphql
					.mutate<DuplicateLegendMutation>({
						mutation: DUPLICATE_LEGEND,
						variables: {
							_id: legend._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateLegend) {
								throw new Error('error saving legend to the database');
							}
							store.dispatch(legendActions.reload());
							return legendActions.duplicateSuccess({
								data: data.data.duplicateLegend
							});
						}),
						take(1),
						catchError(error => of(legendActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteLegend = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(legendActions.delete),
			switchMap(legend =>
				graphql
					.mutate<DeleteLegendMutation>({
						mutation: DELETE_LEGEND,
						variables: {
							_id: legend._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteLegend) {
								throw new Error('error deleting Legend from the database');
							}
							store.dispatch(legendActions.reload());
							return legendActions.deleteSuccess({
								_id: data.data.deleteLegend
							});
						}),
						take(1),
						catchError(error => of(legendActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveLegendSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(legendActions.saveSuccess),
			tap(() => {
				message.sendToast(`Legend successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveLegendFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(legendActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Legend!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateLegendSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(legendActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Legend successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateLegendFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(legendActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Legend!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteLegendSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(legendActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Legend successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteLegendFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(legendActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Legend!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
