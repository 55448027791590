import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CustomSource } from '@yuno/api/interface';

export const sourcesCustomActions = createActionGroup({
	source: 'CustomSources',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<CustomSource>[] }>(),
		loadFailure: props<{ error: Error }>(),

		select: props<{ _id: string }>(),
		selectSuccess: props<{ data: Partial<CustomSource> }>(),
		selectFailure: props<{ error: Error }>(),
		clearSelect: emptyProps(),

		updateSelect: props<{ data: Partial<CustomSource> }>(),
		updateSelectSuccess: props<{ data: Partial<CustomSource> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: props<{ _id: string }>(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<CustomSource> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>()
	}
});
