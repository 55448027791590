import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
	selector: 'yuno-forms-range',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none px-2" [innerHtml]="label | labelRequired: required">
				</span>
			}
			<div class="px-2">
				<input
					type="range"
					[step]="step || undefined"
					[min]="min || undefined"
					[max]="max || undefined"
					[id]="ngControl.name"
					[name]="ngControl.name"
					[placeholder]="placeholder"
					[formControl]="getFormControl()" />
			</div>
			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		YunoFormsValidatorMessagesComponent,
		YunoFormsLabelRequired
	]
})
export class YunoFormsRangeComponent extends YunoFormsDefaultComponent {
	@Input() step: number;
	@Input() min: number;
	@Input() max: number;
}

@Component({
	selector: 'yuno-forms-template-range',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none px-2" [innerHtml]="label | labelRequired: required">
				</span>
			}
			<div class="px-2">
				<input
					type="range"
					[step]="step || undefined"
					[min]="min || undefined"
					[max]="max || undefined"
					[ngModel]="value"
					(ngModelChange)="valueChange($event)"
					[placeholder]="placeholder"
					[required]="required" />
			</div>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, YunoFormsLabelRequired],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsRangeTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsRangeTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Input() step: number;
	@Input() min: number;
	@Input() max: number;
}
