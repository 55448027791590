@if (data$ | async; as data) {
	<yuno-edit-container [col]="4" [pos]="2" [span]="2">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>
		<div edit-container-content>
			@if (service.form) {
				<form [formGroup]="service.form">
					@if (!minimalAppRole(userRoles.EDITOR)) {
						<yuno-user-rights-message></yuno-user-rights-message>
					}
					<div class="flex flex-col gap-8">
						<yuno-forms-text
							class="flex-1"
							formControlName="id"
							label="Image-Button name"
							placeholder="image-button name"></yuno-forms-text>
						<section formGroupName="title">
							@for (lang of languages; track lang) {
								@if (lang === data.language) {
									<yuno-forms-text
										[formControlName]="lang"
										[language]="lang"
										placeholder="image-button title"
										label="Title"></yuno-forms-text>
								}
							}
						</section>
						<div formGroupName="file" class="flex w-full flex-col items-end gap-2">
							<ng-container formGroupName="data">
								<yuno-forms-text
									class="w-full"
									formControlName="url"
									placeholder="Image From Library"
									label="Image From Library"></yuno-forms-text>
							</ng-container>
							<div class="flex w-full flex-row justify-end gap-4">
								<button yuno-admin-button color="secondary" (click)="clearImage()">
									Clear Image
								</button>
								<button yuno-admin-button (click)="openImageLibrary()">
									Browse Library
								</button>
							</div>
							@if (
								{
									img: selectImage$ | async,
									close: close$ | async
								};
								as data
							) {}
						</div>
					</div>
					<yuno-forms-divider></yuno-forms-divider>
					<yuno-button-card
						[data]="imageBtn"
						[language]="language"
						[wide]="true"
						[size]="imageBtn.size"></yuno-button-card>
					<yuno-forms-divider></yuno-forms-divider>
					<section class="grid grid-cols-2 gap-4">
						<yuno-forms-select
							formControlName="size"
							[selectValues]="service.sizeValues"
							label="Size"></yuno-forms-select>
						<yuno-forms-toggle
							formControlName="wide"
							label="Page Options: Expand over 2 grid columns"></yuno-forms-toggle>
					</section>
				</form>
			}
		</div>
	</yuno-edit-container>
}
