import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { videosActions } from './video.actions';
import { videosFeature } from './video.state';

@Injectable()
export class VideoFacade {
	private readonly store = inject(Store);

	videos$ = this.store.pipe(select(videosFeature.selectVideos));
	status$ = this.store.pipe(select(videosFeature.selectStatus));

	get() {
		this.store.dispatch(videosActions.load());
	}
}
