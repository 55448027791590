import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { textTogglesActions } from './text-toggle.actions';
import { textTogglesFeature } from './text-toggle.state';

@Injectable()
export class TextToggleFacade {
	private readonly store = inject(Store);

	toggles$ = this.store.pipe(select(textTogglesFeature.selectToggles));
	status$ = this.store.pipe(select(textTogglesFeature.selectStatus));

	get() {
		this.store.dispatch(textTogglesActions.load());
	}
}
