import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { tap } from 'rxjs';

import { LanguageType } from '@yuno/api/interface';

import { colspan, columns } from '../../../helpers';
import { JsonFormControl, JsonFormKeyType, JsonLanguageForm } from '../../../json-forms.models';
import { LanguageService } from '../../../services/language.service';
import { ControlComponent } from '../control/control.component';

@Component({
	selector: 'yuno-json-forms-language',
	templateUrl: './language.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, NgClass, ControlComponent, AsyncPipe]
})
export class LanguageComponent {
	private _control: UntypedFormGroup | undefined;
	private _item: JsonLanguageForm;
	private _currentLanguage: LanguageType = 'nl';

	language$ = this.languageService.language$.pipe(tap(lang => (this._currentLanguage = lang)));

	@Input()
	set control(control: UntypedFormGroup | undefined) {
		this._control = control;
		this.hostClass = this.colspan();
	}
	get control(): UntypedFormGroup | undefined {
		return this._control;
	}

	@Input()
	set item(item: JsonLanguageForm) {
		this._item = item;
		this.className = this.columns();
	}
	get item(): JsonLanguageForm {
		return this._item;
	}

	get getLanguageControl(): FormControl {
		return this.control?.controls[this._currentLanguage] as FormControl;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() formValue: any;
	@Input() form: UntypedFormGroup;

	@HostBinding('className') hostClass = 'col-span-full';
	className: string;

	constructor(private languageService: LanguageService) {}

	columns(): string {
		return columns(this.item.columns || 1);
	}

	colspan(): string {
		return colspan(this.item.colspan);
	}

	asFormControl(obj: JsonFormKeyType): JsonFormControl {
		return obj as JsonFormControl;
	}

	getControlKey(key: string): string {
		const str = `${this.item.key}.${key}`;
		return str;
	}

	getFormControlItem(): JsonFormControl | undefined {
		if (!this.item.options?.control) {
			return;
		}

		const newControl = { ...this.item.options.control };
		newControl.key = this._currentLanguage;
		return newControl;
	}
}
