@if (form) {
	<form [formGroup]="form">
		<yuno-forms-title>Textfield</yuno-forms-title>
		<div formGroupName="textfield" class="grid grid-cols-1 gap-2">
			<section formGroupName="default" class="grid grid-cols-2 gap-2">
				<yuno-forms-select
					class="col-span-2"
					formControlName="text"
					[placeholder]="'none'"
					[selectValues]="service.textfieldValues"
					[display]="service.textfieldDisplay"></yuno-forms-select>
			</section>
			<yuno-forms-toggle
				label="Use wide textfield"
				formControlName="wide"
				labelPos="side"
				class="col-span-2"></yuno-forms-toggle>
			<section formGroupName="default" class="grid grid-cols-2 gap-2">
				<yuno-forms-divider class="col-span-2"></yuno-forms-divider>
				<yuno-forms-select
					label="Trigger"
					formControlName="when"
					[selectValues]="service.triggerSelectValues"
					[display]="service.triggerSelectDisplay"></yuno-forms-select>
			</section>
			<section class="col-span-2 grid grid-cols-2 gap-2">
				<yuno-forms-select
					label="Closed In"
					formControlName="isClosed"
					[selectValues]="service.isClosedSelectValues"
					[display]="service.isClosedSelectDisplay"></yuno-forms-select>
				<yuno-forms-select
					label="Visibility"
					formControlName="visibility"
					[selectValues]="service.visibilitySelectValues"
					[display]="service.visibilitySelectDisplay"></yuno-forms-select>
				<yuno-forms-span class="col-span-2 my-2">
					<p>
						The textfield have different visibility options depending on the map or
						panorama view.
					</p>
					<ul>
						<li>
							<strong>When:</strong>
							<p>
								<strong><i>on theme startup:</i></strong> the textfield is open
								based on your choice.
							</p>
						</li>
						<li>
							<strong>Closed in:</strong>
							<p>
								<strong><i>on switching between map / pano view:</i></strong> will
								automatically close the textfield based on your choice. You can
								manually open de textfield, but will always close upon re-entering.
							</p>
						</li>
						<li>
							<strong>Visible:</strong>
							<p>
								textfield is not active based on your choice. It cannot be opened
								manually.
							</p>
						</li>
						<li>
							<strong>Trigger:</strong>
							<p>
								<strong><i>on theme startup:</i></strong> change the trigger of the
								default textfield. Default will check after every mouse move if
								there is no active textfield.
							</p>
						</li>
					</ul>
				</yuno-forms-span>
			</section>
		</div>
		<yuno-forms-divider></yuno-forms-divider>
		<div class="col-span-2 grid grid-cols-2 gap-2">
			<yuno-forms-title class="col-span-2">Controls</yuno-forms-title>
			<section formGroupName="modules" class="col-span-1 grid grid-cols-1 gap-2">
				<div formGroupName="searchAdress" class="grid gap-2">
					<yuno-forms-toggle
						labelPos="side"
						class="col-span-2"
						formControlName="active"
						label="Search Adress"></yuno-forms-toggle>
				</div>
			</section>
			<div formGroupName="controls" class="col-span-1 grid gap-2">
				<section formGroupName="backToOverview" class="grid grid-cols-2 gap-2">
					<yuno-forms-toggle
						labelPos="side"
						class="col-span-2"
						formControlName="active"
						label="Back to Overview"></yuno-forms-toggle>
				</section>
			</div>
			<div formGroupName="controls" class="col-span-1 grid gap-2">
				<yuno-forms-toggle
					labelPos="side"
					class="col-span-2"
					formControlName="mapscale"
					label="Map Scale"></yuno-forms-toggle>
			</div>
			<div formGroupName="controls" class="col-span-2 grid grid-cols-2 gap-2">
				<section formGroupName="controlbar" class="col-span-2 grid grid-cols-2 gap-2">
					<yuno-forms-toggle
						labelPos="side"
						formControlName="location"
						label="Location"></yuno-forms-toggle>
					<yuno-forms-toggle
						labelPos="side"
						formControlName="zoom"
						label="Zoom"></yuno-forms-toggle>
					<yuno-forms-toggle
						labelPos="side"
						formControlName="measure"
						label="Measure"></yuno-forms-toggle>
					<yuno-forms-toggle
						class="col-span-2"
						labelPos="side"
						formControlName="mapStyles"
						label="Show map style options in the map controls"></yuno-forms-toggle>
				</section>
			</div>
		</div>
		<yuno-forms-divider></yuno-forms-divider>
		<yuno-forms-title>Legend</yuno-forms-title>
		<div formGroupName="legend" class="grid gap-2">
			<yuno-forms-select
				formControlName="_id"
				[placeholder]="'none'"
				[selectValues]="service.legendValues"
				[display]="service.legendDisplay"></yuno-forms-select>
		</div>
		<yuno-forms-divider></yuno-forms-divider>
		<yuno-forms-title>Disclaimer</yuno-forms-title>
		<div formGroupName="modules" class="grid gap-2">
			<section formGroupName="disclaimer" class="grid gap-2">
				<yuno-forms-toggle
					labelPos="side"
					formControlName="active"
					label="Active"></yuno-forms-toggle>
				<yuno-forms-select
					formControlName="when"
					placeholder="Default"
					[selectValues]="service.whenSelectValues"
					[display]="service.whenSelectDisplay"></yuno-forms-select>
				<section formGroupName="textblock" class="grid gap-2">
					<yuno-forms-select
						formControlName="_id"
						placeholder="Choose Textblock..."
						[selectValues]="service.textblockValues"
						[display]="service.textblockDisplay"></yuno-forms-select>
				</section>
			</section>
		</div>
		<yuno-forms-divider></yuno-forms-divider>
		<yuno-forms-title>States</yuno-forms-title>
		<div formGroupName="states" class="mb-2 grid gap-2">
			<section formGroupName="default" class="grid gap-2">
				<yuno-forms-select
					formControlName="_id"
					label="Default State"
					placeholder="Choose State..."
					[selectValues]="service.statesValues"
					[display]="service.statesDisplay"></yuno-forms-select>
			</section>
			<div formArrayName="available" class="grid gap-1">
				<span class="text-sm font-semibold">Items</span>
				@for (alias of service.availableStates.controls; track alias; let i = $index) {
					<div
						class="flex w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-300 bg-gray-200 p-2"
						[formGroupName]="i">
						<div title class="flex flex-row gap-2">
							<div formGroupName="state">
								<yuno-forms-select
									formControlName="_id"
									class="text-gray-500"
									placeholder="Choose State..."
									[selectValues]="service.statesValues"
									[display]="service.statesDisplay"></yuno-forms-select>
							</div>
							<yuno-forms-select
								formControlName="defaultText"
								class="col-span-2 text-gray-500"
								placeholder="Choose Textfield..."
								[selectValues]="service.textfieldValues"
								[display]="service.textfieldDisplay"></yuno-forms-select>
						</div>
						<ng-container buttons>
							<button
								yuno-admin-delete-admin-button
								(click)="removeItemAt(i)"></button>
						</ng-container>
					</div>
				}
			</div>
		</div>
		<div class="flex flex-row-reverse">
			<button yuno-admin-add-button (click)="addItem()">Add Item</button>
		</div>
	</form>
}
