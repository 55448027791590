import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'yuno-forms-title',
	template: ` <h2>
		<ng-content></ng-content>
	</h2>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class YunoFormsTitleComponent {}
