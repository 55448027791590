import { gql } from 'apollo-angular';

export const GET_CLIENT = gql`
	query getClient($clientname: String!) {
		client(clientname: $clientname) {
			_id
			id
			url
		}
	}
`;
