import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PrivacyStatementConfig, PrivacyStatementProject } from '@yuno/api/interface';

export const privacyStatementCustomActions = createActionGroup({
	source: 'PrivacyStatementsCustom',
	events: {
		clear: emptyProps(),
		config: emptyProps(),
		configPending: emptyProps(),
		configSuccess: props<{
			data: Partial<PrivacyStatementConfig>;
		}>(),
		configFailure: props<{
			error: Error;
		}>(),
		values: emptyProps(),
		valuesPending: emptyProps(),
		valuesSuccess: props<{
			data: Partial<PrivacyStatementProject>[];
		}>(),
		valuesFailure: props<{
			error: Error;
		}>(),
		saveValue: emptyProps(),
		saveValueSuccess: props<{
			data: Partial<PrivacyStatementProject>;
		}>(),
		saveValueFailure: props<{
			error: Error;
		}>(),
		saveConfig: emptyProps(),
		saveConfigSuccess: props<{
			data: Partial<PrivacyStatementConfig>;
		}>(),
		saveConfigFailure: props<{
			error: Error;
		}>(),
		clearSelected: emptyProps(),
		value: props<{
			id: string;
		}>(),
		valueSuccess: props<{
			data: PrivacyStatementProject;
		}>(),
		valueFailure: props<{
			error: Error;
		}>(),
		patchValue: props<{
			data: Partial<PrivacyStatementProject>;
		}>(),
		patchConfig: props<{
			data: Partial<PrivacyStatementConfig>;
		}>()
	}
});
