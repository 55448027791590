import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'safeHtml',
	standalone: true
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	public transform(value: string, required?: boolean): SafeHtml {
		let newValue = value;
		if (required) {
			newValue += ' *';
		}

		return this.sanitizer.bypassSecurityTrustHtml(newValue);
	}
}
