import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, debounceTime, take, tap } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';
import { ClientsFacade } from '@yuno/admin/features/clients/data-access';
import { SpritesheetsService } from '@yuno/admin/features/spritesheets/feature/internal-view/spritesheets.service';
import { redirectTo } from '@yuno/admin/utils';
import { MessageService, ToastItem, ToastType } from '@yuno/angular/notifications';
import { Client } from '@yuno/api/interface';

import { ClientsEditorService } from './clients-editor.service';

export type clientTabOptions = 'Settings' | 'Colors' | 'Spritesheet';

@Component({
	selector: 'yuno-admin-clients-editor',
	templateUrl: './clients-editor.component.html',
	styleUrls: ['./clients-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsEditorComponent implements OnInit {
	private readonly clientFacade = inject(ClientsFacade);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly destroyRef = inject(DestroyRef);
	readonly route = inject(ActivatedRoute);
	readonly router = inject(Router);

	private readonly message = inject(MessageService);
	readonly sprites = inject(SpritesheetsService);
	readonly service = inject(ClientsEditorService);
	readonly environment = inject(ENVIRONMENT);

	originalData: Partial<Client>;
	addSprite = false;
	success = false;

	data$ = combineLatest({
		client: this.clientFacade.selectedClient$.pipe(
			tap(async data => {
				if (!this.originalData) {
					this.originalData = data as Client;

					this.service.addUrls(data?.url);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(this.originalData as any);
					this.cdr.markForCheck();
				}

				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
				}
			})
		)
	});

	tabValue: clientTabOptions = 'Settings';

	ngOnInit(): void {
		this.service.createFormGroup();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.clientFacade.select(id);
		});
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.clientFacade.updateClient(this.service.form.getRawValue() as Partial<Client>);
			});
	}

	onSave() {
		this.clientFacade.save();
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		redirectTo(this.route, this.router);
		this.clientFacade.clearSelect();
	}

	onSwitchTab(key: string) {
		this.tabValue = key as clientTabOptions;
	}

	async onSaveSprite(clientId: string) {
		if (!clientId) {
			this.sendToast(`Error uploading SVG, no Client found!`, 'error');
		}
		try {
			await this.sprites.postFile(`icon/${clientId}`, this.sprites.file);
			this.sendToast(`SVG successfully added/updated!`, 'success');
			this.success = true;
			this.cdr.markForCheck();
		} catch {
			this.sendToast(`Error uploading SVG!`, 'error');
		}
	}

	sendToast(message: string, type: ToastType) {
		const item: ToastItem = {
			message: message
		};
		this.message.showToast(item, type);
	}
}
