import { TextfieldComponentKeys, TextfieldComponentsTypes } from '@yuno/api/interface';

import { ButtonEditorComponent } from '../../item-editors/button-editor/button-editor.component';
import { CategoryEditorComponent } from '../../item-editors/category-editor/category-editor.component';
import { ContainerEditorComponent } from '../../item-editors/container-editor/container-editor.component';
import { CustomTextEditorComponent } from '../../item-editors/custom-text-editor/custom-text-editor.component';
import { ImageButtonEditorComponent } from '../../item-editors/image-button-editor/image-button-editor.component';
import { ImageEditorComponent } from '../../item-editors/image-editor/image-editor.component';
import { LegendEditorComponent } from '../../item-editors/legend-editor/legend-editor.component';
import { LinkEditorComponent } from '../../item-editors/link-editor/link-editor.component';
import { ListEditorComponent } from '../../item-editors/list-editor/list-editor.component';
import { NotificationEditorComponent } from '../../item-editors/notification-editor/notification-editor.component';
import { PredefinedEditorComponent } from '../../item-editors/predefined-editor/predefined-editor.component';
import { TextNewsEditorComponent } from '../../item-editors/text-news-editor';
import { TextblockEditorComponent } from '../../item-editors/textblock-editor/textblock-editor.component';
import { ToggleEditorComponent } from '../../item-editors/toggle-editor/toggle-editor.component';
import { VideoEditorComponent } from '../../item-editors/video-editor/video-editor.component';
import { PagesEditorComponent } from '../../pages/editor/pages-editor/pages-editor.component';

export interface EditorType {
	type?: TextfieldComponentKeys;
	content?: TextfieldComponentsTypes;
	index?: number;
}

export const TextfieldEditorComponentMap = {
	textblock: TextblockEditorComponent,
	button: ButtonEditorComponent,
	container: ContainerEditorComponent,
	link: LinkEditorComponent,
	list: ListEditorComponent,
	toggles: ToggleEditorComponent,
	image: ImageEditorComponent,
	video: VideoEditorComponent,
	custom: CustomTextEditorComponent,
	item: PagesEditorComponent,
	news: TextNewsEditorComponent,
	legend: LegendEditorComponent,
	category: CategoryEditorComponent,
	notification: NotificationEditorComponent,
	imageButton: ImageButtonEditorComponent,
	predefined: PredefinedEditorComponent
};
