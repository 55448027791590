// Based on:
// https://stackoverflow.com/questions/46141714/use-formcontrolname-for-custom-input-component-in-reactive-form/68353595#68353595
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'yuno-forms-divider',
	imports: [NgClass],
	template: ` <div
		class="col-span-full border-b-2 border-gray-200"
		[ngClass]="{
			'my-6': !useGap
		}"></div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class YunoFormsDividerComponent {
	@Input() useGap = false;
}
