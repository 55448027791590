import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, debounceTime, tap } from 'rxjs';

import {
	AnnotationComponent,
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, getIdFromRouter, redirectTo } from '@yuno/admin/utils';
import { YunoFormsCodeEditorComponent, YunoFormsModule } from '@yuno/angular/forms';
import { Fence } from '@yuno/api/interface';
import { waitFor } from '@yuno/libs/shared/helpers';

import { DrawMapService } from '../../../../../../../angular/draw-map/src/lib/services/draw-map.service';
import { FencesFacade } from '../../data-access';
import { FenceMapComponent } from './fence-map/fence-map.component';
import { FencesEditorService } from './fences-editor.service';

const debounceFormControl = 300;

@Component({
	selector: 'yuno-admin-fences-editor',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		YunoEditContainerModule,
		YunoAdminButtonsModule,
		UserRightsMessageComponent,
		YunoFormsCodeEditorComponent,
		AnnotationComponent,
		FenceMapComponent,
		AsyncPipe,
		EditContainerContentDirective
	],
	templateUrl: './fences-editor.component.html',
	styleUrls: ['./fences-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FencesEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	@HostBinding('class') private readonly className = 'editor-component-container';

	private readonly destroyRef = inject(DestroyRef);
	private readonly fenceFacade = inject(FencesFacade);
	readonly service = inject(FencesEditorService);
	private readonly drawService = inject(DrawMapService);

	originalData: Partial<Fence>;
	readonly = !this.minimalAppRole(this.userRoles.EDITOR);

	data$ = combineLatest({
		fence: this.fenceFacade.selectedFences$.pipe(
			tap(data => {
				if (!this.originalData) {
					this.originalData = data as Fence;

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(this.originalData as any);

					if (this.originalData?.polygon) {
						this.service.updatePolygon(this.originalData.polygon);
					}
				}

				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
				}
			})
		)
	});

	async ngOnInit(): Promise<void> {
		this.service.createFormGroup();
		this.onChanges();

		this.fenceFacade.select(await getIdFromRouter(this.route));
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(debounceFormControl))
			.subscribe(val => {
				this.fenceFacade.updateSelect(val as Partial<Fence>);
			});

		this.service.color.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(debounceFormControl))
			.subscribe(color => {
				this.drawService.updateDrawColor(color);
			});

		this.service.opacity.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(debounceFormControl))
			.subscribe(opacity => {
				this.drawService.updateDrawOpacity(opacity);
			});
	}

	async onSave(): Promise<void> {
		// Update the polygon inside the Form
		await this.service.updatePolygon(this.service.polygon.getRawValue());

		// Wait for the polygon to be updated
		// inside the Store. Should be after the formValueChanges
		await waitFor(debounceFormControl + 100);

		// Now save the data
		this.fenceFacade.save();
	}

	onClose(): void {
		redirectTo(this.route, this.router);
	}

	async polyChanges(event: string): Promise<void> {
		await this.service.updatePolygon(JSON.parse(event) as [number, number][]);
	}

	async ngOnDestroy(): Promise<void> {
		await this.service.updatePolygon([]);
		this.fenceFacade.clearSelect();
	}
}
