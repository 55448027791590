import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export type DashboardForm = {
	public: FormControl<boolean>;
};

@Injectable()
export class DashboardService {
	dashboardForm: FormGroup<DashboardForm>;

	get dashboardPublic(): FormControl<boolean> {
		return this.dashboardForm.get('public') as FormControl<boolean>;
	}

	createFormGroup(): void {
		this.dashboardForm = new FormGroup<DashboardForm>({
			public: new FormControl(false, { nonNullable: true })
		});
	}
}
