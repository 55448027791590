import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest } from 'rxjs';

import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-notification-editor',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AsyncPipe],
	templateUrl: './notification-editor.component.html',
	styleUrl: './notification-editor.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);

	notificationValues = ['warning', 'success', 'info', 'disclaimer'];

	data$ = combineLatest({
		language: this.editService.language$
	});

	service$ = this.editService.getActiveService();

	ngOnInit() {
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
