@if (form) {
	<form [formGroup]="form" class="grid grid-cols-1 gap-2">
		<yuno-admin-annotation type="info">
			<yuno-forms-span>
				Popup a <strong>Text Label</strong> on the map. <br />
				The Popup will spawn right above the event-holder on MouseOver or Click and will
				disappear on MouseLeave or when clicking outside the event-holder's area.
			</yuno-forms-span>
		</yuno-admin-annotation>
		<yuno-admin-annotation type="info">
			<yuno-forms-span ngNonBindable>
				For the Text you can get json data using
				<b>&#123;</b><b>&#123;properties.ATTRIBUTE&#125;</b><b>&#125;</b>.
			</yuno-forms-span>
		</yuno-admin-annotation>
		<div formGroupName="options" class="grid grid-cols-4 gap-4">
			<yuno-forms-text
				formControlName="name"
				label="Text"
				placeholder="name"
				class="col-span-3"></yuno-forms-text>
			<yuno-forms-number
				formControlName="markerOffset"
				label="Offset Y (pixels)"
				[step]="1"></yuno-forms-number>
			<yuno-forms-html
				class="col-span-full"
				formControlName="content"
				label="Content"
				placeholder="content"></yuno-forms-html>
		</div>
	</form>
}
