import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ResetContainerUi } from '@yuno/admin/core';
import { uiContainerResolver } from '@yuno/admin/core/resolvers/ui.container.resolver';

import { DeactivateParticipationGuard } from '../../deactivate.guard';

const routes: Routes = [
	{
		path: '',
		canActivate: [DeactivateParticipationGuard],
		loadComponent: () =>
			import('./view.component').then(m => m.ParticipationViewSelectorComponent)
	},
	{
		path: ':id',
		canDeactivate: [ResetContainerUi],
		resolve: [uiContainerResolver],
		data: { containerType: 'map' },
		loadChildren: () =>
			import('../../editors/view/view.router.module').then(
				m => m.ParticipationViewViewerRoutingModule
			)
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ParticipationViewRoutingModule {}
