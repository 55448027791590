@if (data$ | async; as data) {
	<div
		(click)="onBackgroundClick()"
		class="flex h-screen w-screen flex-col items-center justify-center"
		[ngClass]="{ 'pointer-events-auto bg-yuno-blue-gray-700/80 backdrop-blur-xs': backdrop }">
		<div
			(click)="$event.stopPropagation()"
			class="pointer-events-auto relative z-50 grid gap-4 rounded-lg bg-white p-8">
			<h1>Choose a template</h1>
			<span
				>You always have the option to delete, rearrange, or add additional items to your
				{{ selector }}.</span
			>
			<div class="grid grid-cols-3 gap-4">
				@for (item of data?.templates; track item) {
					<yuno-admin-template-item
						[img]="item.img"
						(clicked)="onSelect(item.options)"></yuno-admin-template-item>
				}
			</div>
		</div>
	</div>
}
