module.exports = Extent;
function Extent(bbox) {
  if (!(this instanceof Extent)) {
    return new Extent(bbox);
  }
  this._bbox = bbox || [Infinity, Infinity, -Infinity, -Infinity];
  this._valid = !!bbox;
}
Extent.prototype.include = function (ll) {
  this._valid = true;
  this._bbox[0] = Math.min(this._bbox[0], ll[0]);
  this._bbox[1] = Math.min(this._bbox[1], ll[1]);
  this._bbox[2] = Math.max(this._bbox[2], ll[0]);
  this._bbox[3] = Math.max(this._bbox[3], ll[1]);
  return this;
};
Extent.prototype.equals = function (_) {
  var other;
  if (_ instanceof Extent) {
    other = _.bbox();
  } else {
    other = _;
  }
  return this._bbox[0] == other[0] && this._bbox[1] == other[1] && this._bbox[2] == other[2] && this._bbox[3] == other[3];
};
Extent.prototype.center = function (_) {
  if (!this._valid) return null;
  return [(this._bbox[0] + this._bbox[2]) / 2, (this._bbox[1] + this._bbox[3]) / 2];
};
Extent.prototype.union = function (_) {
  this._valid = true;
  var other;
  if (_ instanceof Extent) {
    other = _.bbox();
  } else {
    other = _;
  }
  this._bbox[0] = Math.min(this._bbox[0], other[0]);
  this._bbox[1] = Math.min(this._bbox[1], other[1]);
  this._bbox[2] = Math.max(this._bbox[2], other[2]);
  this._bbox[3] = Math.max(this._bbox[3], other[3]);
  return this;
};
Extent.prototype.bbox = function () {
  if (!this._valid) return null;
  return this._bbox;
};
Extent.prototype.contains = function (ll) {
  if (!ll) return this._fastContains();
  if (!this._valid) return null;
  var lon = ll[0],
    lat = ll[1];
  return this._bbox[0] <= lon && this._bbox[1] <= lat && this._bbox[2] >= lon && this._bbox[3] >= lat;
};
Extent.prototype.intersect = function (_) {
  if (!this._valid) return null;
  var other;
  if (_ instanceof Extent) {
    other = _.bbox();
  } else {
    other = _;
  }
  return !(this._bbox[0] > other[2] || this._bbox[2] < other[0] || this._bbox[3] < other[1] || this._bbox[1] > other[3]);
};
Extent.prototype._fastContains = function () {
  if (!this._valid) return new Function('return null;');
  var body = 'return ' + this._bbox[0] + '<= ll[0] &&' + this._bbox[1] + '<= ll[1] &&' + this._bbox[2] + '>= ll[0] &&' + this._bbox[3] + '>= ll[1]';
  return new Function('ll', body);
};
Extent.prototype.polygon = function () {
  if (!this._valid) return null;
  return {
    type: 'Polygon',
    coordinates: [[
    // W, S
    [this._bbox[0], this._bbox[1]],
    // E, S
    [this._bbox[2], this._bbox[1]],
    // E, N
    [this._bbox[2], this._bbox[3]],
    // W, N
    [this._bbox[0], this._bbox[3]],
    // W, S
    [this._bbox[0], this._bbox[1]]]]
  };
};