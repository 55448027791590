import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ThemeEditorComponent } from '../editor/theme-editor.component';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./themes.component').then(m => m.ThemesComponent),
		children: [
			{ path: 'create', component: ThemeEditorComponent },
			{ path: 'edit/:id', component: ThemeEditorComponent },
			{ path: '**', redirectTo: '' }
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ThemesRoutingModule {}
