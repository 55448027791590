import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { Map } from 'maplibre-gl';
import { combineLatest, map } from 'rxjs';

import { AppDataComponent } from '@yuno/admin/utils';
import { Layer } from '@yuno/api/interface';
import { waitFor } from '@yuno/shared/helpers';

import { MapViewerService } from './map-viewer.service';

@Component({
	selector: 'yuno-admin-layer-map-viewer',
	standalone: true,
	imports: [NgxMapLibreGLModule, AsyncPipe],
	providers: [MapViewerService],
	templateUrl: './map-viewer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapViewerComponent extends AppDataComponent {
	readonly service = inject(MapViewerService);

	data$ = combineLatest({
		language: this.service.language$,
		style: this.service.data$.pipe(
			map(style => {
				// We need to set the visibility to visible
				// for all layers while editing
				const layout = {
					...style.layer?.layout,
					visibility: 'visible'
				};

				return {
					...style,
					layer: {
						...style.layer,
						layout
					} as Partial<Layer>
				};
			})
		),
		bounds: this.service.bounds$,
		boundsOptions: this.service.boundsOptions$
	});

	async mapLoad(map: Map): Promise<void> {
		this.service.setMap(map);
		await waitFor(1500);
		this.service.animateBounds();
	}

	getLayerType(value: string) {
		return value as
			| 'symbol'
			| 'fill'
			| 'line'
			| 'circle'
			| 'heatmap'
			| 'fill-extrusion'
			| 'raster'
			| 'hillshade'
			| 'background';
	}

	mapOnMissingImage(image: { id: string; target?: Map }): void {
		if (!image.target) {
			return;
		}

		const map = image.target;
		const id = image.id;
		const url = `${this.environment['yuno-cdn']}/sprites/public/sdf/sprites/${image.id}.png`;

		if (!map.hasImage(id)) {
			map.loadImage(url).then(img => {
				if (!img) {
					throw new Error(`Image could not be loaded for, ${url}`);
				}

				// the Map fires the same missing image event multiple times
				// then throws an error: "An image named "{{id}}" already exists."
				if (!map.hasImage(id)) {
					map.addImage(id, img.data, {
						sdf: true,
						pixelRatio: 1
					});
				}
			});
		}
	}
}
