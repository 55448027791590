<yuno-card-draggable
	(click)="onClick()"
	[active]="active"
	[paddingCondensed]="true"
	[header]="false"
	[draggable]="true">
	<ng-container buttons>
		@if (!duplicateDisabled) {
			<button yuno-admin-button color="muted" (click)="onDuplicate($event)">Duplicate</button>
		}
		@if (!deleteDisabled) {
			<button yuno-admin-button color="danger" (click)="onRemove($event)">Remove</button>
		}
		@if (addList) {
			<button yuno-admin-add-button (click)="onAdd()"></button>
		}
	</ng-container>
	<ng-container popup>
		<span class="overflow-hidden text-ellipsis text-sm capitalize">{{ componentTitle }}</span>
		@if (componentTitle !== 'container') {
			<span class="float-right text-xs"> last modified by: {{ updatedBy }} </span>
		}
	</ng-container>
	<ng-container content>
		<div class="pointer-events-none overflow-hidden">
			<ng-container
				[ngComponentOutlet]="item.component"
				[ngComponentOutletInjector]="item.injector"></ng-container>
		</div>
	</ng-container>
</yuno-card-draggable>
