import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	GET_MEDIAVIDEOS,
	MediaVideosQuery
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/getTextfieldComponents';
import { GraphQLService } from '@yuno/angular-graphql';

import { videosActions } from './video.actions';

export const loadVideos = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(videosActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<MediaVideosQuery>({
						query: GET_MEDIAVIDEOS,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.videos) {
								throw new Error('no videos found');
							}
							return videosActions.loadSuccess({
								data: data.data.videos
							});
						}),
						take(1),
						catchError(error => of(videosActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);
