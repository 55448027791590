import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { GeoPhoto } from '@yuno/api/interface';

import { photosActions } from './photos.actions';
import { photosFeature } from './photos.state';

@Injectable()
export class GeoPhotoFacade {
	private readonly store = inject(Store);

	data$ = this.store.pipe(select(photosFeature.selectData));
	selected$ = this.store.pipe(select(photosFeature.selectSelected));
	status$ = this.store.pipe(select(photosFeature.selectLoaded));

	get(category: string): void {
		this.store.dispatch(photosActions.load({ category }));
	}

	select(id: string | null): void {
		if (!id) {
			return;
		}
		this.store.dispatch(photosActions.select({ _id: id }));
	}

	updateSelected(data: GeoPhoto): void {
		this.store.dispatch(photosActions.updateSelect({ data }));
	}

	duplicate(id: string): void {
		this.store.dispatch(photosActions.duplicate({ _id: id }));
	}

	delete(id: string): void {
		this.store.dispatch(photosActions.delete({ _id: id }));
	}

	save(): void {
		this.store.dispatch(photosActions.save());
	}

	clearSelected(): void {
		this.store.dispatch(photosActions.clearSelect());
	}

	reset(): void {
		this.store.dispatch(photosActions.reset());
	}
}
