import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DataObjects } from '@yuno/api/interface';

export const objectsActions = createActionGroup({
	source: 'Objects',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<DataObjects>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<DataObjects> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ data: Partial<DataObjects> }>(),
		duplicateSuccess: props<{ data: Partial<DataObjects> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ data: Partial<DataObjects> }>(),
		deleteSuccess: props<{ data: Partial<DataObjects> }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<DataObjects> }>(),
		updateSelectSuccess: props<{ data: Partial<DataObjects> }>(),
		updateSelectFailure: props<{ error: Error }>()
	}
});
