@if (toggleTemplates) {
	<yuno-admin-templates
		[selector]="'textfield'"
		(bgClicked)="toggleTemplates = false"
		(clicked)="onCreate($event)"></yuno-admin-templates>
}

<yuno-edit-container [col]="12" [pos]="4" [span]="7" style="z-index: 0">
	<ng-container actions>
		<button yuno-admin-button (click)="toggleTemplates = true" class="mb-1" [add]="true">
			Create textfield
		</button>
	</ng-container>
	<ng-container buttons>
		<button yuno-admin-close-button (click)="onClose()"></button>
	</ng-container>

	<div edit-container-content>
		<yuno-textfield-library></yuno-textfield-library>
	</div>
</yuno-edit-container>
