import { inject } from '@angular/core';
import { CanActivateFn, CanDeactivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { appActions } from '@yuno/admin/features/apps';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const ClearAppGuard: CanActivateFn = () => {
	const store = inject(Store);
	store.dispatch(appActions.appClearState());
	return true;
};

export const ResetStateGuard: CanDeactivateFn<CanComponentDeactivate> = () => {
	const store = inject(Store);
	store.dispatch(appActions.reset());
	return true;
};
