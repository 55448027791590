<input
	class="block w-full transform rounded-full border border-gray-200 bg-white py-2 pl-5 pr-10 placeholder-gray-400 shadow-sm transition duration-500 ease-in-out focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-400"
	type="text"
	[placeholder]="placeholder"
	[(ngModel)]="searchString"
	(keyup)="!enter && onSearch()"
	(keyup.enter)="enter && onSearch()" />

<button
	type="button"
	tabindex="0"
	(click)="onSearch()"
	class="absolute right-0 top-0 h-full w-10 cursor-pointer rounded-r-full pl-1 pr-2 text-gray-500 hover:text-gray-400">
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="h-5 w-5 transition-colors duration-300 ease-in-out"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor">
		<path
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="2"
			d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
	</svg>
</button>
