import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { YunoFormsModule } from '@yuno/angular/forms';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-container-editor',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AsyncPipe],
	templateUrl: './container-editor.component.html',
	styleUrls: ['./container-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerEditorComponent {
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService();
}
