@if (opacity !== 0) {
	<div class="pointer-events-none absolute left-0 top-0 z-10 h-full w-full">
		<div
			class="flex h-6 -translate-y-4 flex-row items-center justify-between gap-4 overflow-hidden text-ellipsis whitespace-nowrap rounded-t-lg bg-yuno-blue px-4 text-sm text-yuno-blue-text">
			<ng-content select="[popup]"></ng-content>
		</div>
	</div>
	@if (draggable && hover) {
		<div
			class="pointer-events-none absolute left-1/2 top-1/2 z-100 -translate-x-1/2 -translate-y-1/2 rounded-full bg-yuno-blue px-2 py-3 text-yuno-blue-text shadow-lg">
			<svg viewBox="0 0 16 24" class="h-8 w-4" fill="#fff" border="#fff">
				<use href="#drag-icon" />
			</svg>
		</div>
	}
}

<yuno-card
	class="border border-gray-400"
	[ngClass]="{
		'border-dashed hover:cursor-move hover:border-solid': draggable,
		'border-solid': !draggable,
		'h-full': fullHeight
	}"
	[paddingCondensed]="paddingCondensed"
	[blueHover]="!!opacity"
	(mouseenter)="mouseEvent(true)"
	(mouseleave)="mouseEvent(false)"
	[header]="header"
	[footer]="footer"
	[buttons]="buttons"
	[fullHeight]="fullHeight"
	[btnOpacity]="opacity"
	(outputBtn)="onBtnClick($event)">
	<ng-content buttons select="[buttons]"></ng-content>
	<ng-content title select="[title]"></ng-content>
	<ng-content subTitle select="[subTitle]"></ng-content>
	<ng-content content select="[content]"></ng-content>
</yuno-card>
