import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { navigationActions, navigationFeature } from '@yuno/admin/features/navigation/data-access';
import { Navigation } from '@yuno/api/interface';

@Injectable()
export class NavigationsFacade {
	private readonly store = inject(Store);

	/**
	 * Combine pieces of state using createSelector,
	 * and expose them as observables through the facade.
	 */
	loaded$ = this.store.pipe(select(navigationFeature.selectLoaded));
	navigation$ = this.store.pipe(select(navigationFeature.selectNavigation));
	status$ = this.store.pipe(select(navigationFeature.selectStatus));

	/**
	 * Use the initialization action to perform one
	 * or more tasks in your Effects.
	 */
	getNavigationsByAppId() {
		this.store.dispatch(navigationActions.load());
	}

	update(navigation: Partial<Navigation>) {
		this.store.dispatch(navigationActions.update({ navigation }));
	}

	save() {
		this.store.dispatch(navigationActions.save());
	}
}
