<form>
	<ngx-monaco-editor
		[style.height.px]="height"
		[options]="editorOptions"
		[formControl]="control"
		(onInit)="onMonacoInit($event)"
		(ngModelChange)="onChange($event)"></ngx-monaco-editor>
	<div class="flex w-full">
		<div class="ml-auto mr-0">
			@if (format) {
				<button yuno-admin-button color="secondary" (click)="onFormat()">Format</button>
			}
		</div>
	</div>
</form>
