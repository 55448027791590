<router-outlet></router-outlet>

@if (data$ | async; as data) {
	<yuno-card>
		<ng-container content>
			<section class="grid grid-cols-1">
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="false"
					[pagination]="false"
					[pageOptions]="{
						pageSize: 100,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="tilesets.tiles"
					[buttons]="tableButtons"
					[buttonEllipsis]="true"
					[defaultColumn]="'name'"
					[columns]="[
						{ key: 'name', label: 'tileset' },
						{
							key: 'date',
							label: 'lastest update',
							type: 'date',
							stickyEnd: true,
							width: 11.875
						}
					]">
					<div class="flex gap-4">
						<button yuno-admin-add-button (click)="openUploadMbtiles()">
							Upload Tilesets
						</button>
						<button yuno-admin-add-button (click)="onCreate()">
							Create Custom Tileset
						</button>
					</div>
				</yuno-admin-table>
				@if (data.customTilesets) {
					<yuno-admin-table
						[selectable]="false"
						[draggable]="false"
						[filterable]="false"
						[sortable]="false"
						[pagination]="false"
						[pageOptions]="{
							pageSize: 100,
							pageSizeOptions: [5, 10, 25, 100],
							hidePageSize: false
						}"
						[defaultColumn]="'name'"
						[data]="tilesets.custom"
						(clicked)="onSelect($event)"
						[buttons]="tableButtonsCustom"
						[buttonEllipsis]="true"
						[columns]="[
							{ key: 'name', label: 'custom tileset' },
							{
								key: 'date',
								label: 'lastest update',
								type: 'date',
								stickyEnd: true,
								width: 11.875
							}
						]">
					</yuno-admin-table>
				}
			</section>
		</ng-container>
	</yuno-card>
}

<ng-template #tableButtons let-row>
	@if (row?.tileJSON) {
		<button yuno-admin-button-table color="muted" (click)="onCopyTileJSON(row)">
			Copy TileJson Url
		</button>
	}
	@if (row?.tileUrl) {
		<button yuno-admin-button-table color="muted" (click)="onCopyTileUrl(row)">
			{{ 'Copy {z}{x}{y}' }}
		</button>
	}
	@if (row?.view) {
		<button yuno-admin-button-table color="secondary" (click)="onView(row)">View</button>
	}
</ng-template>

<ng-template #tableButtonsCustom let-row>
	@if (row?.tileJSON) {
		<button yuno-admin-button-table color="muted" (click)="onCopyTileJSON(row)">
			Copy TileJson Url
		</button>
	}
	@if (row?.tileUrl) {
		<button yuno-admin-button-table color="muted" (click)="onCopyTileUrl(row)">
			{{ 'Copy {z}{x}{y}' }}
		</button>
	}
	@if (row?.view) {
		<button yuno-admin-button-table color="secondary" (click)="onView(row)">View</button>
	}
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
