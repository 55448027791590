import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, inject } from '@angular/core';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { toolbarSettings } from 'libs/angular/draw-map/src/lib/tool-bar/tool-bar.component';
import { Map } from 'maplibre-gl';
import { startWith } from 'rxjs';

import { AppDataComponent } from '@yuno/admin/utils';
import { DrawMapModule } from '@yuno/angular/draw-map';

import { FencesMapService } from './map.service';

@Component({
	selector: 'yuno-admin-fence-map',
	standalone: true,
	imports: [NgxMapLibreGLModule, DrawMapModule, AsyncPipe],
	providers: [FencesMapService],
	templateUrl: './fence-map.component.html',
	styleUrls: ['./fence-map.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FenceMapComponent extends AppDataComponent implements OnDestroy {
	service = inject(FencesMapService);

	readonly = !this.minimalAppRole(this.userRoles.EDITOR);
	data$ = this.service.data$.pipe(startWith(null));

	drawMapSettings: toolbarSettings = {
		polyArea: true,
		polyRect: true,
		polyCircle: true,
		line: false,
		point: false,
		limit: 1
	};

	mapOnMissingImage(image: { id: string; target?: Map }): void {
		if (!image.target) {
			return;
		}

		const map = image.target;
		const id = image.id;
		const url = `${this.environment['yuno-cdn']}/sprites/public/sdf/sprites/${image.id}.png`;

		if (!map.hasImage(id)) {
			map.loadImage(url).then(img => {
				if (!img) {
					throw new Error(`Image could not be loaded for, ${url}`);
				}

				// the Map fires the same missing image event multiple times
				// then throws an error: "An image named "{{id}}" already exists."
				if (!map.hasImage(id)) {
					map.addImage(id, img.data, {
						sdf: true,
						pixelRatio: 1
					});
				}
			});
		}
	}

	ngOnDestroy(): void {
		this.service.resetMap();
	}
}
