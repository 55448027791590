import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Participated } from '@yuno/api/interface';

import { participationsActions } from './participations.actions';
import { participationsFeature } from './participations.state';

@Injectable()
export class ParticipationFacade {
	private readonly store = inject(Store);

	data$ = this.store.pipe(select(participationsFeature.selectData));
	selected$ = this.store.pipe(select(participationsFeature.selectSelected));

	get(id: string): void {
		this.store.dispatch(participationsActions.load({ id }));
	}

	getDataFiltered(variable: Participated['state'] | 'all'): Observable<Partial<Participated>[]> {
		return this.store.pipe(select(participationsFeature.getFiltered(variable)));
	}

	getDataCount(variable: Participated['state'] | 'all'): Observable<number> {
		return this.store.pipe(select(participationsFeature.getCount(variable)));
	}

	select(id: string): void {
		this.store.dispatch(participationsActions.select({ _id: id }));
	}

	updateSelected(data: Participated): void {
		this.store.dispatch(participationsActions.updateSelect({ data }));
	}

	delete(id: string): void {
		this.store.dispatch(participationsActions.delete({ _id: id }));
	}

	save(): void {
		this.store.dispatch(participationsActions.save());
	}

	clearSelected(): void {
		this.store.dispatch(participationsActions.clearSelect());
	}

	moveToTrash(): void {
		this.store.dispatch(participationsActions.setArchived());
	}
	putOnHold(): void {
		this.store.dispatch(participationsActions.setOnHold());
	}
	sendAnswerOnly(): void {
		this.store.dispatch(participationsActions.sendAnswerOnly());
	}
	publish(): void {
		this.store.dispatch(participationsActions.setPublished());
	}
	updateAnswer(str: string): void {
		this.store.dispatch(participationsActions.onUpdateAnswer({ data: str }));
	}
	updateReaction(str: string): void {
		this.store.dispatch(participationsActions.onUpdateReaction({ data: str }));
	}

	reset(): void {
		this.store.dispatch(participationsActions.reset());
	}
}
