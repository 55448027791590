<div
	class="flex h-screen w-screen flex-col items-center justify-center"
	[ngClass]="{ 'pointer-events-auto bg-yuno-gray-500/70 backdrop-blur-xs': backdrop }">
	<div
		class="pointer-events-auto relative z-50 grid w-full gap-4 overflow-hidden p-4"
		[ngClass]="containerClass">
		<ng-container>
			<!-- background -->
			<div
				class="pointer-events-auto flex flex-col overflow-hidden rounded-lg bg-gray-700 p-2 shadow-lg"
				[ngClass]="backgroundClass">
				<!-- Header -->
				<div class="flex max-w-full flex-nowrap justify-between sm:max-h-12">
					<div
						class="flex h-full items-end gap-2 whitespace-nowrap"
						[ngClass]="{
							'overflow-visible': containerTabs.length,
							'overflow-hidden': !containerTabs.length
						}">
						@for (tab of containerTabs; track tab; let i = $index) {
							<div
								(click)="onTabClick(tab, i)"
								class="-mb-2 cursor-pointer select-none truncate rounded-t-lg px-2 pb-2 pt-3 font-medium sm:rounded-b-none sm:px-4"
								[ngClass]="{
									'bg-gray-100 text-gray-800': tab.active,
									'text-white': !tab.active
								}">
								{{ tab.key }}
							</div>
						}
						<ng-content select="[actions]"></ng-content>
					</div>
					<div class="flex flex-row flex-wrap items-end justify-end gap-2">
						<ng-content select="[buttons]"></ng-content>
					</div>
				</div>
				<!-- Body -->
				<div
					#defaultContainer
					class="pointer-events-auto mt-2 h-auto overflow-y-auto md:h-full md:max-h-full md:overflow-hidden">
					<ng-content></ng-content>
				</div>
			</div>
		</ng-container>
	</div>
</div>
