import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest } from 'rxjs';

import { PredefinedEditorService } from '@yuno/admin/features/textfield-pages/feature/item-editors/predefined-editor/predefined-editor.service';
import {
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';
import {
	YunoFormsColorComponent,
	YunoFormsImageComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTextareaComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { AngularPipesModule } from '@yuno/angular/pipes';

import { TextEditorsService } from '../text-editors.service';
import { PredefinedBlockQuoteEditorComponent } from './components/blockquote.component';
import { PredefinedDividerEditorComponent } from './components/divider-editor.component';
import { PredefinedFileEditorComponent } from './components/file-editor.component';
import { PredefinedHeidingEditorComponent } from './components/heading.component';
import { PredefinedParagraphEditorComponent } from './components/paragraph.component';
import { PredefinedSummaryEditorComponent } from './components/summary.component';

@Component({
	selector: 'yuno-admin-predefined-editor',
	standalone: true,
	imports: [
		AsyncPipe,
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsTextComponent,
		YunoFormsTextareaComponent,
		CdkDropList,
		CdkDrag,
		YunoAdminButtonComponent,
		YunoFormsToggleComponent,
		YunoFormsColorComponent,
		YunoFormsImageComponent,
		AngularPipesModule,
		PredefinedFileEditorComponent,
		PredefinedParagraphEditorComponent,
		PredefinedSummaryEditorComponent,
		PredefinedHeidingEditorComponent,
		PredefinedBlockQuoteEditorComponent,
		PredefinedDividerEditorComponent
	],
	templateUrl: './predefined-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly editor = inject(PredefinedEditorService);
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService();

	data$ = combineLatest({
		files: this.editor.files$,
		language: this.editService.language$
	});

	ngOnInit() {
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}

@Component({
	selector: 'yuno-admin-predefined-list-item-editor',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		AsyncPipe,
		EditContainerContentDirective,
		EditContainerComponent,
		YunoAdminCloseButtonComponent
	],
	templateUrl: './predefined-list-item-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PredefinedListItemEditorComponent implements OnInit {
	readonly editor = inject(PredefinedEditorService);
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService();

	data$ = combineLatest({
		selector: this.editor._selector.asObservable(),
		listItem: this.editor._listItem.asObservable(),
		navigations: this.editService.navigation$,
		language: this.editService.language$
	});

	ngOnInit() {
		this.editService.onInitNav();
	}
}
