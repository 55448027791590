import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Source } from '@yuno/api/interface';

import { sourcesActions } from './sources.actions';

export interface SourcesState {
	loaded: boolean;
	sources: Partial<Source>[];
	selectedLoaded: boolean;
	selected: Partial<Source> | undefined;
	sourceLayers: string[] | undefined;
	status: Status;
}

export const initialState: SourcesState = {
	loaded: false,
	sources: [],
	selectedLoaded: false,
	selected: undefined,
	sourceLayers: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(sourcesActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(sourcesActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		sources: data,
		status: Status.SUCCESS
	})),
	on(sourcesActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(sourcesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(sourcesActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(sourcesActions.selectFailure, (state, { error }) => ({ ...state, error })),
	on(sourcesActions.sourceLayersSuccess, (state, { data }) => {
		return {
			...state,
			sourceLayers: data
		};
	}),
	on(sourcesActions.clearSourceLayers, state => {
		return {
			...state,
			sourceLayers: undefined
		};
	}),

	on(sourcesActions.updateSelectSuccess, (state, { data }) => {
		return {
			...state,
			selected: { ...state.selected, ...data },
			selectedLoaded: true
		};
	}),
	on(sourcesActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(sourcesActions.saveSuccess, state => {
		const index = state.sources.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const sources = [...state.sources];
		if (index >= 0) {
			sources[index] = { ...sources[index], ...state.selected };
		} else if (state.selected) {
			sources.push({ ...state.selected });
		}

		return {
			...state,
			sources: sources
		};
	})
);

export const sourcesFeature = createFeature({
	name: 'sources',
	reducer,
	extraSelectors: ({ selectSources }) => ({
		getSourceUrl: (id: string) =>
			createSelector(selectSources, data => {
				const sourceData = data?.find(source => source._id === id)?.sourceData;
				if (!sourceData) {
					return undefined;
				}
				if ('url' in sourceData) {
					return sourceData.url;
				} else {
					return undefined;
				}
			}),
		getSourceName: (id: string) =>
			createSelector(selectSources, data => {
				const sourceData = data?.find(source => source.id === id)?.sourceData;
				if (!sourceData) {
					return undefined;
				}
				if ('url' in sourceData) {
					return sourceData.url;
				} else {
					return undefined;
				}
			})
	})
});
