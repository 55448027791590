import { gql } from 'apollo-angular';

export const DELETE_CLIENT = gql`
	mutation deleteClientById($_id: String!) {
		deleteClient(_id: $_id)
	}
`;

export type DeleteClientMutation = {
	deleteClient: string;
};
