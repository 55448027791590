import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map, take } from 'rxjs';

import { AuthFacade } from '@yuno/angular-auth';
import {
	UserRolesEnum,
	YunoUserRolesEnum,
	minimalUserRole,
	minimalYunoRole
} from '@yuno/api/interface';

/**
 * @description
 * Only allow SuperAdmins to access the route
 * When using this guard, make sure to import it from @yuno/admin/core/guards
 */
export const SuperAdminGuard: CanActivateFn = () => {
	const auth = inject(AuthFacade);
	return auth.userRole$.pipe(
		map(role => {
			return minimalUserRole(UserRolesEnum.SUPERADMIN).includes(role);
		}),
		take(1)
	);
};

/**
 * @description
 * Only allow Yuno Admins to access the route
 * When using this guard, make sure to import it from @yuno/admin/core/guards
 */
export const AdminGuard: CanActivateFn = () => {
	const auth = inject(AuthFacade);
	return auth.appRole$.pipe(
		map(role => {
			return minimalYunoRole(YunoUserRolesEnum.ADMIN).includes(role);
		}),
		take(1)
	);
};

/**
 * @description
 * Only allow Yuno Publisher to access the route
 * When using this guard, make sure to import it from @yuno/admin/core/guards
 */
export const PublisherGuard: CanActivateFn = () => {
	const auth = inject(AuthFacade);
	return auth.appRole$.pipe(
		map(role => {
			return minimalYunoRole(YunoUserRolesEnum.PUBLISHER).includes(role);
		}),
		take(1)
	);
};

/**
 * @description
 * Only allow Yuno Editors to access the route
 * When using this guard, make sure to import it from @yuno/admin/core/guards
 */
export const EditorGuard: CanActivateFn = () => {
	const auth = inject(AuthFacade);
	return auth.appRole$.pipe(
		map(role => {
			return minimalYunoRole(YunoUserRolesEnum.EDITOR).includes(role);
		}),
		take(1)
	);
};

/**
 * @description
 * Only allow Yuno Readers to access the route
 * When using this guard, make sure to import it from @yuno/admin/core/guards
 */
export const ReaderGuard: CanActivateFn = () => {
	const auth = inject(AuthFacade);
	return auth.appRole$.pipe(
		map(role => {
			return minimalYunoRole(YunoUserRolesEnum.READER).includes(role);
		}),
		take(1)
	);
};

/**
 * @description
 * Only allow Yuno Viewer to access the route
 * When using this guard, make sure to import it from @yuno/admin/core/guards
 */
export const ViewerGuard: CanActivateFn = () => {
	const auth = inject(AuthFacade);
	return auth.appRole$.pipe(
		map(role => {
			return minimalYunoRole(YunoUserRolesEnum.VIEWER).includes(role);
		}),
		take(1)
	);
};

/**
 * @description
 * Only allow Yuno Atlas Users to access the route
 * When using this guard, make sure to import it from @yuno/admin/core/guards
 */
export const AtlasGuard: CanActivateFn = () => {
	const auth = inject(AuthFacade);
	return auth.appRole$.pipe(
		map(role => {
			return minimalYunoRole(YunoUserRolesEnum.ATLAS).includes(role);
		}),
		take(1)
	);
};

/**
 * @description
 * Only allow Yuno Internal Atlas Users to access the route
 * When using this guard, make sure to import it from @yuno/admin/core/guards
 */
export const AtlasinternalGuard: CanActivateFn = () => {
	const auth = inject(AuthFacade);
	return auth.appRole$.pipe(
		map(role => {
			return minimalYunoRole(YunoUserRolesEnum.ATLASINTERNAL).includes(role);
		}),
		take(1)
	);
};
