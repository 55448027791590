import { Injectable, inject } from '@angular/core';

import { FileUploadService } from '@yuno/admin/features/file-upload';
import { ApiService, DialogType, FileFormatTypes } from '@yuno/angular/api';

import { environment } from '../../../../../../../../apps/admin/src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class MarkerService {
	private readonly api = inject(ApiService);
	private readonly upload = inject(FileUploadService);

	async downloadGeojson(id: string) {
		const geojson = await this.api.get(`markers/app/${id}/to-geojson`);
		if (!geojson) {
			return;
		}

		const date = Date.now();
		const element = document.createElement('a');
		element.setAttribute(
			'href',
			'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(geojson))
		);
		element.setAttribute('download', `markers-${date}.geojson`);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();
		document.body.removeChild(element);
	}

	async uploadGeojson(id: string) {
		const route = `${environment['yuno-api']}/markers/${id}/geojson`;
		const data: DialogType = {
			appId: id,
			type: FileFormatTypes.geojson,
			filesizeLimit: 50
		};
		this.upload.openDialog(data, route);
	}
}
