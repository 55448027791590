import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { CdnFile } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class FileLibraryService {
	close$ = new ReplaySubject<void>();
	filter$ = new ReplaySubject<string>();
	activeFile$ = new ReplaySubject<CdnFile>();

	onClose() {
		this.close$.next();
	}

	onActiveFile(file: CdnFile) {
		this.activeFile$.next(file);
	}

	filter(filter: string) {
		this.filter$.next(filter);
	}
}
