import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild, inject } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { debounceTime, tap } from 'rxjs';

import { PanoramaService } from '../../services/panorama.service';
import { PanoramaOffset, ResizeService } from '../../services/resize.service';

const defaultOffset = {
	x: 0,
	y: 0,
	z: 0,
	height: 0,
	locX: 0,
	locY: 0
};

@Component({
	selector: 'yuno-panorama-calibration-tool',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './calibration-tool.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalibrationToolComponent {
	private resizeService = inject(ResizeService);
	private data = inject(PanoramaService);

	@ViewChild('offsetForm', { read: NgForm }) offsetForm: NgForm;

	offset: PanoramaOffset = { ...defaultOffset };

	data$ = this.data.pano$.pipe(
		// this delays updating the panorama, because KRPANO needs
		// to be fully loaded to let the function work
		debounceTime(500),
		tap(data => data?.id && this.getFromLocalStorage(data?.id))
	);

	copyData(str: 'x' | 'y' | 'z' | 'height' | 'locX' | 'locY'): void {
		navigator.clipboard.writeText(this.offset[str].toString());
	}

	clearData(): void {
		this.offset = { ...defaultOffset };
		localStorage.removeItem('panoramaOffsets');

		setTimeout(() => {
			this.updatePanoramaOffset();
		}, 500);
	}

	exportData(): void {
		const fileName = `panoramaOffsets.json`;
		const fileContent = localStorage.getItem('panoramaOffsets') || `{}`;
		const file = new Blob([fileContent], { type: 'application/json' });

		const link = document.createElement('a');
		link.href = URL.createObjectURL(file);
		link.download = fileName;
		link.click();
		link.remove();
	}

	updateLocalStorage(): void {
		const ls = localStorage.getItem('panoramaOffsets') || '{}';
		const fromLS = JSON.parse(ls) || {};

		const newOffset = {
			...this.offset,
			height: this.offset.height,
			locX: this.offset.locX,
			locY: this.offset.locY
		};

		fromLS[this.data?.panorama?.id || 'undefined'] = newOffset;
		localStorage.setItem('panoramaOffsets', JSON.stringify(fromLS));

		this.resizeService.setOffset({ ...this.offset });
	}

	getFromLocalStorage(id: string): void {
		const ls = localStorage.getItem('panoramaOffsets');
		if (!ls) {
			this.offset = { ...defaultOffset };
			this.resizeService.clearOffset();
			return;
		}

		const fromLS = JSON.parse(ls);
		if (id) {
			const offset: PanoramaOffset = fromLS?.[id];

			if (!offset) {
				this.offset = { ...defaultOffset };
				this.resizeService.clearOffset();
				return;
			}

			this.offset = offset;
			this.resizeService.setOffset({ ...this.offset });

			this.updatePanoramaOffset();
		}
	}

	changeOffset(): void {
		if (!this.data.krpanoInstance) {
			return;
		}

		this.updatePanoramaOffset();
		this.data?.krpanoInstance.call(`updateobject(true, true)`);
		this.updateLocalStorage();
	}

	updatePanoramaOffset(): void {
		this.data?.krpanoInstance?.set(
			'image.prealign',
			`${this.offset?.x || 0}|${this.offset?.y * -1 || 0}|${this.offset?.z || 0}`
		);
	}
}
