import { gql } from 'apollo-angular';

import { Template } from '@yuno/api/interface';

export const SAVE_TEMPLATE = gql`
	mutation saveTemplate($template: TemplatesInput!) {
		saveTemplate(template: $template) {
			_id
			img
			selector
			options
			updatedAt
		}
	}
`;

export type SaveTemplateMutation = {
	saveTemplate: Partial<Template>;
};
