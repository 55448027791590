import { animate, group, style, transition, trigger } from '@angular/animations';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { Feature } from 'geojson';
import { Map, MapGeoJSONFeature } from 'maplibre-gl';

import { EmbedTextfieldObj, Textfield } from '@yuno/api/interface';

import { IfChangesDirective } from '../../../directives/src/lib/ifchanges.directive';
import { TextfieldInjectorComponent } from './components/textfield-injector/textfield-injector.component';
import { EventsService } from './services/events.service';

@Component({
	selector: 'yuno-textfield',
	template: `
		<div
			[@.disabled]="disableAnimations"
			[@slideIn]
			*ifChanges="data"
			[ngClass]="{
				absolute: !disableAnimations,
				'h-full w-full': !disablePadding
			}"
			class="overflow-hidden">
			<div
				class="none-empty"
				[ngClass]="{
					'px-4 py-2 md:p-4 lg:p-6': !disablePadding
				}">
				<ng-content select="[before-text]"></ng-content>
			</div>

			@if (data && language) {
				<yuno-textfield-injector
					class="h-full"
					[ngClass]="{
						'overflow-y-auto': !disableOverflow,
						'select-none overflow-hidden': disableOverflow,
						'px-4 py-2 md:p-4 lg:p-6': !disablePadding
					}"
					[data]="data"
					[feature]="feature"
					[language]="language"
					[injector]="injector"></yuno-textfield-injector>
			}

			<div
				class="none-empty h-full overflow-y-auto"
				[ngClass]="{
					'px-4 py-2 md:p-4 lg:p-6': !disablePadding
				}">
				<ng-content></ng-content>
			</div>
		</div>
	`,
	styleUrls: ['./textfield.component.scss'],
	animations: [
		trigger('slideIn', [
			transition(':enter', [
				style({
					opacity: 0,
					transform: 'translateX(-100%)',
					overflow: 'hidden'
				}),
				group([
					animate('600ms cubic-bezier(.35,0,.25,1)', style({ opacity: 1 })),
					animate(
						'800ms cubic-bezier(.35,0,.25,1)',
						style({ transform: 'translateX(0)' })
					)
				])
			]),
			transition(':leave', [
				style({
					opacity: 1,
					transform: 'translateX(0)',
					height: '100%'
				}),
				group([
					animate('400ms linear', style({ opacity: 0 })),
					animate('900ms linear', style({ transform: 'translateX(100%)' }))
				])
			])
		])
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [IfChangesDirective, NgClass, TextfieldInjectorComponent]
})
export class TextfieldComponent {
	private _data: Textfield;
	private _language: string | null;
	private _feature: Feature | MapGeoJSONFeature | undefined;

	@Input() set data(data: Textfield) {
		this._data = data;
	}

	get data(): Textfield {
		return this._data;
	}

	@Input() set feature(data: Feature | MapGeoJSONFeature | undefined) {
		this._feature = data;
	}

	get feature(): Feature | MapGeoJSONFeature | undefined {
		return this._feature;
	}

	@Input() set language(language: string | null) {
		this._language = language;
	}

	get language(): string | null {
		return this._language;
	}

	@Input() disableAnimations = false;
	@Input() disablePadding = false;

	@Input() set embed(options: EmbedTextfieldObj) {
		this.events.setEmbedText(options);
	}

	@Input() set map(map: Map) {
		this.events.setMap(map);
	}

	@Input() disableOverflow = false;

	constructor(
		public injector: Injector,
		private events: EventsService
	) {}
}
