@if (form) {
	<form [formGroup]="form">
		<div formGroupName="modules">
			<div formGroupName="panoramas">
				<yuno-forms-title>VR</yuno-forms-title>
				<yuno-forms-span>
					Show the VR button in the Panorama UI which enables the VR view for phones.
				</yuno-forms-span>
				<yuno-forms-toggle
					labelPos="side"
					class="col-span-2"
					formControlName="vr"
					label="VR"></yuno-forms-toggle>
				<yuno-forms-divider></yuno-forms-divider>
				<yuno-forms-title>Calibration Tool</yuno-forms-title>
				<yuno-forms-span>Enables the calibration tool for panoramas.</yuno-forms-span>
				<yuno-forms-toggle
					labelPos="side"
					class="col-span-2"
					formControlName="calibrate"
					label="Calibration Tool"></yuno-forms-toggle>
				<yuno-forms-divider></yuno-forms-divider>
				<yuno-forms-title>Disable Popup</yuno-forms-title>
				<yuno-forms-span
					>Disable the popup text when hovering on panoramas.</yuno-forms-span
				>
				<yuno-forms-toggle
					labelPos="side"
					class="col-span-2"
					formControlName="popup"
					label="Popup"></yuno-forms-toggle>
				<yuno-forms-divider></yuno-forms-divider>
				<div formGroupName="splitscreen" class="grid grid-cols-2">
					<yuno-forms-title class="col-span-2">Splitscreen</yuno-forms-title>
					<yuno-forms-span class="col-span-2">
						Enables split screen, which shows both the map and pano view.
					</yuno-forms-span>
					<yuno-forms-toggle
						labelPos="side"
						class="col-span-1"
						formControlName="active"
						label="Active"></yuno-forms-toggle>
					<!-- <yuno-forms-toggle
          labelPos="side"
          class="col-span-1"
          formControlName="dial"
        label="Dial"></yuno-forms-toggle> -->
					<yuno-forms-toggle
						labelPos="side"
						class="col-span-1"
						formControlName="keepView"
						label="KeepView"></yuno-forms-toggle>
				</div>
				<yuno-forms-divider></yuno-forms-divider>
				<div formGroupName="hotspots" class="grid grid-cols-1 gap-2">
					<yuno-forms-title>Hotspots</yuno-forms-title>
					<yuno-forms-span>
						Adds Hotspot Markers in the Panorama view. You can choose which type of
						markers are viewable and set the radius range.
					</yuno-forms-span>
					<yuno-forms-toggle
						labelPos="side"
						formControlName="active"
						label="Active"></yuno-forms-toggle>
					<yuno-forms-select
						label="type"
						formControlName="type"
						[selectValues]="service.panoramaHotspotsMarkersValues"
						[display]="service.panoramaHotspotsMarkersDisplay"></yuno-forms-select>
					<yuno-forms-number
						labelPos="side"
						formControlName="range"
						[step]="10"
						label="Range"></yuno-forms-number>
				</div>
				<yuno-forms-divider></yuno-forms-divider>
				<div formGroupName="threejs" class="grid grid-cols-1 gap-2">
					<yuno-forms-title>3D data</yuno-forms-title>
					<yuno-forms-span>
						Adds ThreeJS data in the Panorama view, e.g. geojson lines.
					</yuno-forms-span>
					<yuno-forms-toggle
						labelPos="side"
						formControlName="active"
						label="Active"></yuno-forms-toggle>
					<yuno-forms-number
						labelPos="side"
						formControlName="range"
						[step]="10"
						label="Range"></yuno-forms-number>
				</div>
			</div>
		</div>
	</form>
}
