import { gql } from 'apollo-angular';

export const DELETE_CUSTOMSOURCE = gql`
	mutation deleteCustomSourceById($_id: String!) {
		deleteCustomSource(_id: $_id)
	}
`;

export type DeleteCustomSourceMutation = {
	deleteCustomSource: string;
};
