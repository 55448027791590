import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, inject } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContentKeys } from '@yuno/admin/features/textfield-pages/feature/content/types';
import { YunoTinymceEditorModule } from '@yuno/angular/tinymce';
import { PagesComponentsTypes } from '@yuno/api/interface';

import { ButtonEditorComponent } from '../../../item-editors/button-editor/button-editor.component';
import { ContainerEditorComponent } from '../../../item-editors/container-editor/container-editor.component';
import { CustomTextEditorComponent } from '../../../item-editors/custom-text-editor/custom-text-editor.component';
import { ImageButtonEditorComponent } from '../../../item-editors/image-button-editor/image-button-editor.component';
import { ImageEditorComponent } from '../../../item-editors/image-editor/image-editor.component';
import { LegendEditorComponent } from '../../../item-editors/legend-editor/legend-editor.component';
import { LinkEditorComponent } from '../../../item-editors/link-editor/link-editor.component';
import { ListEditorComponent } from '../../../item-editors/list-editor/list-editor.component';
import { NotificationEditorComponent } from '../../../item-editors/notification-editor/notification-editor.component';
import { PageHeaderEditorComponent } from '../../../item-editors/page-header-editor/page-header-editor.component';
import { PageItemEditorComponent } from '../../../item-editors/page-item-editor/page-item-editor.component';
import { PredefinedEditorComponent } from '../../../item-editors/predefined-editor/predefined-editor.component';
import { TextNewsEditorComponent } from '../../../item-editors/text-news-editor';
import { TextblockEditorComponent } from '../../../item-editors/textblock-editor/textblock-editor.component';
import { ToggleEditorComponent } from '../../../item-editors/toggle-editor/toggle-editor.component';
import { token } from '../../../item-editors/token';
import { VideoEditorComponent } from '../../../item-editors/video-editor/video-editor.component';

export interface editorTypes {
	type: ContentKeys | 'header' | undefined;
	content: PagesComponentsTypes | FormGroup | undefined;
	index: number | undefined;
	side: 'left' | 'right';
}

@Component({
	selector: 'yuno-admin-page-editor-dynamic',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, YunoTinymceEditorModule],
	templateUrl: './editor-dynamic.component.html',
	styleUrls: ['./editor-dynamic.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorDynamicComponent implements OnInit {
	private readonly injector = inject(Injector);
	private _editor: editorTypes;

	@Input()
	set editor(value: editorTypes) {
		this._editor = value;
	}
	get editor(): editorTypes {
		return this._editor;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: any;
	myInjector: Injector;

	ngOnInit(): void {
		this.getEditor();
		this._editor?.content && this.component && this.createInjector();
	}

	getEditor() {
		switch (this._editor?.type) {
			case 'header': {
				this.component = PageHeaderEditorComponent;
				break;
			}
			case 'item': {
				this.component = PageItemEditorComponent;
				break;
			}
			case 'textblock': {
				this.component = TextblockEditorComponent;
				break;
			}
			case 'button': {
				this.component = ButtonEditorComponent;
				break;
			}
			case 'container': {
				this.component = ContainerEditorComponent;
				break;
			}
			case 'link': {
				this.component = LinkEditorComponent;
				break;
			}
			case 'list': {
				this.component = ListEditorComponent;
				break;
			}
			case 'toggles': {
				this.component = ToggleEditorComponent;
				break;
			}
			case 'image': {
				this.component = ImageEditorComponent;
				break;
			}
			case 'video': {
				this.component = VideoEditorComponent;
				break;
			}
			case 'custom': {
				this.component = CustomTextEditorComponent;
				break;
			}
			case 'news': {
				this.component = TextNewsEditorComponent;
				break;
			}
			case 'legend': {
				this.component = LegendEditorComponent;
				break;
			}
			case 'notification': {
				this.component = NotificationEditorComponent;
				break;
			}
			case 'imageButton': {
				this.component = ImageButtonEditorComponent;
				break;
			}
			case 'predefined': {
				this.component = PredefinedEditorComponent;
				break;
			}
			default: {
				break;
			}
		}
	}

	createInjector() {
		this.myInjector = Injector.create({
			providers: [
				{
					provide: token,
					useValue: {
						data: this._editor.content,
						index: this._editor.index,
						editor: 'pages',
						side: this._editor.side
					}
				}
			],
			parent: this.injector
		});
	}
}
