import { Feature, FeatureCollection, Point } from 'geojson';
import { cloneDeep } from 'lodash';

import {
	DatasetStatesPhotoCategories,
	GeoPhoto,
	GeoPhotosCategory,
	PhotoMarker,
	VisibilityEnum
} from '@yuno/api/interface';

export const createPhotoCategoryMarkers = (
	category: Partial<GeoPhotosCategory>,
	photos: GeoPhoto[],
	state?: DatasetStatesPhotoCategories[]
): {
	features: FeatureCollection<Point>['features'];
} => {
	// Do not add empty marker arrays
	if (!photos || photos?.length < 1) {
		return {
			features: []
		};
	}

	photos = cloneDeep(photos);

	// FILTER BASED ON STATE
	if (state) {
		const found = state.find(e => e.photoCategory?._id === category._id);

		// When a state is found and should not be visible,
		// stop checking this MarkerCategory and skip to the next
		if (found?.visibility === 'none') {
			return {
				features: []
			};
		}
	}

	// Generate the Layout
	const photoMarkers = getLayout(photos);
	const markerArr: Feature<Point>[] = [];

	for (const photo of photoMarkers) {
		markerArr.push(createMarkerFeature(photo, category.viewer));
	}

	return {
		features: markerArr
	};
};

const createMarkerFeature = (
	marker: PhotoMarker,
	viewer?: GeoPhotosCategory['viewer']
): Feature<Point> => {
	return {
		type: 'Feature',
		properties: {
			id: marker.id,
			title: marker.title,
			viewer: viewer || 'fullscreen',
			minZoom: marker.style.minZoom,
			maxZoom: marker.style.maxZoom,
			style: JSON.stringify(marker.style)
		},
		geometry: marker.geometry as Point
	} as Feature<Point>;
};

const getLayout = (photos: GeoPhoto[]): PhotoMarker[] => {
	const markerArr: PhotoMarker[] = [];
	for (const photo of photos) {
		const id = photo._id?.toString() as string;
		const marker: PhotoMarker = {
			id,
			title: photo.title,
			geometry: {
				type: 'Point',
				coordinates: photo.coordinates
			},
			style: {
				type: 'photo',
				visibility: VisibilityEnum.visible,
				minZoom: 1,
				maxZoom: 22,
				rotation: photo.rotation || 0
			}
		};
		markerArr.push(marker);
	}

	return markerArr;
};
