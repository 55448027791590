import { Injectable } from '@angular/core';
import {
	FormControl,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormControl,
	UntypedFormGroup,
	ValidatorFn,
	Validators
} from '@angular/forms';

import { LanguagesArray } from '@yuno/api/interface';

import {
	JsonArrayForm,
	JsonFormArrayControl,
	JsonFormControl,
	JsonFormKeyType,
	JsonGroupForm
} from './../json-forms.models';
import { CustomValidators } from './custom-validators';

@Injectable()
export class FormCreatorService {
	constructor(private formBuilder: UntypedFormBuilder) {}

	// only the Main FormGroup doesn't have the Group Boolean set.
	// all other formGroups (JsonGroupForm) have the group boolean set to true;
	createJsonFormGroup(
		controls: JsonFormKeyType[],
		group = false,
		validators?: ValidatorFn[]
	): UntypedFormGroup {
		const tempForm = this.formBuilder.group({}, { validators });

		for (const control of controls) {
			if (control.controlType === 'array') {
				const arrayControl = this.createJsonFormArray(control);
				control.control = arrayControl;
				tempForm.addControl(control?.key, arrayControl);

				continue;
			}

			// When the Control should be a Input field,
			// create input and assign it to the TempForm
			if (control.controlType === 'control') {
				if (control.required) {
					if (control.validators && !control.validators?.required) {
						if (control.type === 'checkbox') {
							control.validators.requiredTrue = true;
						} else {
							control.validators.required = true;
						}
					}

					if (!control.validators) {
						if (control.type === 'checkbox') {
							control.validators = {
								requiredTrue: true
							};
						} else {
							control.validators = {
								required: true
							};
						}
					}
				}

				const newControl = this.createJsonFormControl(control);
				control.control = newControl;
				if (control.validators) {
					const validators = this.addFormControlValidators(control);
					newControl.addValidators(validators);
				}
				tempForm.addControl(control?.key, newControl);

				continue;
			}

			if (control.controlType === 'group') {
				// If it is a group Object, loop through this function
				const groupControl = control as JsonGroupForm;
				const newFormGroup = this.createJsonFormGroup(groupControl.controls, true);

				if (control.validators) {
					const validators = this.addFormGroupValidators(groupControl);
					newFormGroup.addValidators(validators);
				}

				if (newFormGroup) {
					control.control = newFormGroup;
					tempForm.addControl(control?.key, newFormGroup);
				}
			}

			if (control.controlType === 'language') {
				const newFormGroup = this.formBuilder.group({});

				for (const lang of LanguagesArray) {
					newFormGroup.addControl(lang, new FormControl(''));
				}

				if (newFormGroup) {
					control.control = newFormGroup;
					tempForm.addControl(control?.key, newFormGroup);
				}
			}
		}

		if (group) {
			return tempForm;
		}

		return tempForm;
	}

	addFormGroupValidators(control: JsonGroupForm): ValidatorFn[] {
		const validatorsToAdd: ValidatorFn[] = [];
		if (control.validators) {
			for (const [key, value] of Object.entries(control.validators)) {
				switch (key) {
					case 'matchControls': {
						if (!value.controlName || !value.matchingControlName) {
							break;
						}

						validatorsToAdd.push(
							CustomValidators.mustMatch(value.controlName, value.matchingControlName)
						);
						break;
					}
					default:
						break;
				}
			}
		}

		return validatorsToAdd;
	}

	addFormControlValidators(control: JsonFormControl | JsonFormArrayControl): ValidatorFn[] {
		const validatorsToAdd: ValidatorFn[] = [];
		if (control.validators) {
			for (const [key, value] of Object.entries(control.validators)) {
				switch (key) {
					case 'noSpacesLowerCase': {
						if (!value) {
							break;
						}
						validatorsToAdd.push(CustomValidators.noSpacesLowerCase());
						break;
					}
					default:
						break;
				}
			}
		}

		return validatorsToAdd;
	}

	createJsonFormArray(control: JsonArrayForm, force = false): UntypedFormArray {
		let formArray = this.formBuilder.array([]);
		if (control.options?.fixed || force) {
			for (const controlItem of control.controls) {
				formArray = this.createJsonFormArrayControls(controlItem, formArray);
			}

			return formArray;
		}

		return formArray;
	}

	createJsonFormArrayControls(
		control: JsonFormKeyType,
		formArray: UntypedFormArray,
		value?: unknown
	): UntypedFormArray {
		if (control.controlType === 'button') {
			return formArray;
		}

		if (control.controlType === 'array') {
			const arrayControl = this.createJsonFormArray(control);
			control.control = arrayControl;
			formArray.push(arrayControl);
			return formArray;
		}

		if (control.controlType === 'control') {
			const formControl = this.createJsonFormControl(control);
			control.control = formControl;
			formArray.push(formControl);
			value && control.control.setValue(value);
			return formArray;
		}

		if (control.controlType === 'array-control') {
			const formControl = this.createJsonFormArrayControl(control);
			control.control = formControl;
			value && control.control.setValue(value);
			formArray.push(formControl);

			return formArray;
		}

		if (control.controlType === 'group') {
			// If it is a group Object, loop through this function
			const newFormGroup = this.createJsonFormGroup(
				(control as JsonGroupForm).controls,
				true
			);

			if (newFormGroup) {
				control.control = newFormGroup;
				formArray.push(newFormGroup);
			}
		}

		return formArray;
	}

	createJsonFormControl(control: JsonFormControl): UntypedFormControl {
		const validatorsToAdd = this.addValidators(control);
		return new UntypedFormControl(
			{
				value: control.value,
				disabled: control.readonly
			},
			validatorsToAdd
		);
	}

	createJsonFormArrayControl(control: JsonFormArrayControl): UntypedFormControl {
		const validatorsToAdd = this.addValidators(control);
		return new UntypedFormControl(control.value, validatorsToAdd);
	}

	addValidators(control: JsonFormControl | JsonFormArrayControl): ValidatorFn[] {
		const validatorsToAdd = [];

		if (control.validators) {
			for (const [key, value] of Object.entries(control.validators)) {
				switch (key) {
					case 'min':
						validatorsToAdd.push(Validators.min(value));
						break;
					case 'max':
						validatorsToAdd.push(Validators.max(value));
						break;
					case 'required':
						if (value) {
							validatorsToAdd.push(Validators.required);
						}
						break;
					case 'requiredTrue':
						if (value) {
							validatorsToAdd.push(Validators.requiredTrue);
						}
						break;
					case 'email':
						if (value) {
							validatorsToAdd.push(Validators.email);
						}
						break;
					case 'minLength':
						validatorsToAdd.push(Validators.minLength(value));
						break;
					case 'maxLength':
						validatorsToAdd.push(Validators.maxLength(value));
						break;
					case 'pattern':
						validatorsToAdd.push(Validators.pattern(value));
						break;
					case 'nullValidator':
						if (value) {
							validatorsToAdd.push(Validators.nullValidator);
						}
						break;
					default:
						break;
				}
			}
		}

		return validatorsToAdd;
	}

	formValidationMessages(
		control: JsonFormControl | JsonFormArrayControl | JsonGroupForm
	): string {
		const validationMessage = [];

		if (control.validators) {
			for (const [key, value] of Object.entries(control.validators)) {
				switch (key) {
					case 'min':
						validationMessage.push(`minimum value of ${value}`);
						break;
					case 'max':
						validationMessage.push(`maximum value of ${value}`);
						break;
					case 'required':
						validationMessage.push(`required`);
						break;
					case 'requiredTrue':
						validationMessage.push(`should be checked`);
						break;
					case 'email':
						validationMessage.push(`not a valid email`);
						break;
					case 'minLength':
						validationMessage.push(`minimum of ${value} characters`);
						break;
					case 'maxLength':
						validationMessage.push(`maximum of ${value} characters`);
						break;
					case 'pattern':
						validationMessage.push(`value not same as patern: ${value}`);
						break;
					case 'matchControls':
						validationMessage.push(`Controls do not match!`);
						break;
					default:
						break;
				}
			}
		}

		return validationMessage.join(', ');
	}
}
