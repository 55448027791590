import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { Textblock, TextfieldComponentsTypes } from '@yuno/api/interface';

import { ButtonEditorComponent } from '../../../item-editors/button-editor/button-editor.component';
import { token } from '../../../item-editors/token';
import { EditorType, TextfieldEditorComponentMap } from '../types';

@Component({
	selector: 'yuno-admin-editor-dynamic',
	standalone: true,
	imports: [NgComponentOutlet],
	templateUrl: './editor-dynamic.component.html',
	styleUrls: ['./editor-dynamic.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorDynamicComponent implements OnInit {
	private readonly injector = inject(Injector);
	private _editor: EditorType = { type: undefined, content: undefined, index: undefined };

	@Input()
	set editor(value: EditorType) {
		this._editor = value;
	}
	get editor(): EditorType {
		return this._editor;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: any;
	componentData?: TextfieldComponentsTypes = undefined;

	myInjector: Injector;

	selected$: Observable<Textblock | undefined>;

	ngOnInit(): void {
		this.getEditor();
		this._editor.content && this.component && this.createInjector();
	}

	getEditor() {
		if (!this._editor.type) {
			return;
		}

		this.component = TextfieldEditorComponentMap[this._editor.type];

		if (this._editor.type === 'button') {
			this.component = ButtonEditorComponent;
		}

		this.componentData = this._editor.content;
	}

	createInjector() {
		this.myInjector = Injector.create({
			providers: [
				{
					provide: token,
					useValue: {
						data: this._editor.content,
						index: this._editor.index,
						editor: 'textfield'
					}
				}
			],
			parent: this.injector
		});
	}
}
