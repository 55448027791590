<div class="flex w-full flex-1 flex-row gap-2">
	@if (control && item && key) {
		<yuno-json-forms-control-selector
			class="flex-1"
			[form]="form"
			[control]="control"
			[item]="getItem()">
		</yuno-json-forms-control-selector>
	}
	<button
		type="button"
		class="flex transform items-center justify-center rounded-lg border border-red-600 bg-red-500 px-5 py-3 text-white shadow-sm transition duration-500 ease-in-out hover:bg-red-600 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-600"
		(click)="removeItem()">
		remove
		<span yuno-ripple-standalone></span>
	</button>
</div>
