import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { YunoAdminButtonComponent } from 'libs/admin/ui/src/lib/button';

import { AuthFacade } from '@yuno/angular-auth';
import { UserRoles, YunoUserRoles, minimalUserRole, minimalYunoRole } from '@yuno/api/interface';

import { CardButtonDisplay, CardButtonOutput } from '../types';

@Component({
	selector: 'yuno-card',
	standalone: true,
	imports: [CommonModule, YunoAdminButtonComponent],
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminCardComponent {
	private readonly authFacade = inject(AuthFacade);

	private _hoverMode = false;
	private _header = true;
	private _footer = true;
	private _fullHeight = false;
	private _buttons: CardButtonDisplay[] = [];

	user$ = this.authFacade.user$;
	appRole$ = this.authFacade.appRole$;

	hover = false;
	opacity = 0.93;

	@Input() white = false;
	@Input() relativeButtons = false;

	@Input()
	set header(value: boolean) {
		this._header = value;
	}
	get header() {
		return this._header;
	}

	@Input()
	set footer(value: boolean) {
		this._footer = value;
	}
	get footer() {
		return this._footer;
	}

	@Input()
	set fullHeight(value: boolean) {
		this._fullHeight = value;
	}
	get fullHeight() {
		return this._fullHeight;
	}

	@Input()
	set buttons(value: CardButtonDisplay[]) {
		this._buttons = value;
	}
	get buttons() {
		return this._buttons;
	}

	@Input()
	set hoverMode(value: boolean) {
		this._hoverMode = value;
		this._hoverMode && (this.opacity = 0);
	}
	get hoverMode() {
		return this._hoverMode;
	}

	@Input()
	set btnOpacity(opac: number) {
		this.opacity = opac;
	}

	@Input() blue = false;
	@Input() blueHover = false;
	@Input() paddingCondensed = false;

	@Output() outputBtn = new EventEmitter<CardButtonOutput>();

	onBtnClick(row: CardButtonOutput) {
		this.outputBtn.emit({ type: row.type });
	}

	setOpacity(bool: boolean) {
		if (this.hoverMode && !bool) {
			this.opacity = 0;
			return;
		}
		this.opacity = 0.93;
	}

	hasAccess(
		role?: UserRoles,
		appRole?: YunoUserRoles | null,
		authRole?: UserRoles,
		authAppRole?: YunoUserRoles
	): boolean {
		if (!role || !appRole) {
			return false;
		}

		if (authRole && !minimalUserRole(authRole || 'superadmin').includes(role)) {
			return false;
		}

		if (authAppRole && !minimalYunoRole(authAppRole || 'admin').includes(appRole)) {
			return false;
		}

		return true;
	}
}
