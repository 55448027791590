export enum Status {
	PENDING = 'PENDING',
	VALIDATING = 'VALIDATING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED'
}

export enum StatusPrivate {
	PENDING = 'PENDING',
	VALIDATING = 'VALIDATING',
	SUCCESS = 'SUCCESS',
	PRIVATE = 'PRIVATE',
	FAILED = 'FAILED'
}
