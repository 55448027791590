@defer (when data.selector === 'h1') {
	@if (data.selector === 'h1') {
		<h1 yuno-predefined>
			{{ data.options.title | languageSelect: language }}
		</h1>
	}
}
@defer (when data.selector === 'h2') {
	@if (data.selector === 'h2') {
		<h2 yuno-predefined>
			{{ data.options.title | languageSelect: language }}
		</h2>
	}
}
@defer (when data.selector === 'h3') {
	@if (data.selector === 'h3') {
		<h3 yuno-predefined>
			{{ data.options.title | languageSelect: language }}
		</h3>
	}
}
@defer (when data.selector === 'paragraph') {
	@if (data.selector === 'paragraph') {
		<yuno-predefined-paragraph>
			<ng-container heading>
				{{ data.options.title | languageSelect: language }}
			</ng-container>
			<ng-container>{{ data.options.content | languageSelect: language }}</ng-container>
		</yuno-predefined-paragraph>
	}
}
@defer (when data.selector === 'summary') {
	@if (data.selector === 'summary') {
		<yuno-predefined-summary
			[title]="data.options.title"
			[list]="data.options.list"
			[language]="language" />
	}
}
@defer (when data.selector === 'dateSummary') {
	@if (data.selector === 'dateSummary') {
		<yuno-predefined-date-summary
			[title]="data.options.title"
			[list]="data.options.list"
			[language]="language" />
	}
}
@defer (when data.selector === 'blockquote') {
	@if (data.selector === 'blockquote') {
		<yuno-predefined-blockquote [color]="data.options.color || '#31AACC'">
			"{{ data.options.content | languageSelect: language }}"
		</yuno-predefined-blockquote>
	}
}
@defer (when data.selector === 'divider') {
	@if (data.selector === 'divider') {
		<yuno-predefined-divider [line]="data.options.line" />
	}
}
@defer (when data.selector === 'file') {
	@if (data.selector === 'file') {
		<yuno-text-file
			[language]="language"
			[title]="data.options.title"
			[data]="data.options.file" />
	}
}
