import { gql } from 'apollo-angular';

export const DELETE_TILESET = gql`
	mutation deleteTileset($_id: String!) {
		deleteCustomTileset(_id: $_id)
	}
`;

export type DeleteCustomTilesetMutation = {
	deleteCustomTileset: string;
};
