import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./navigations.component').then(m => m.NavigationsComponent),
		children: [
			{
				path: 'edit',
				loadComponent: () =>
					import('./../editor/navigation-editor.component').then(
						m => m.NavigationEditorComponent
					)
			}
		]
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class NavigationsRoutingModule {}
