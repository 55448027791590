import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';

import { ClientsFacade } from '@yuno/admin/features/clients/data-access';
import { YunoAdminButtonsModule, YunoAdminTableComponent, YunoCardModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Client } from '@yuno/api/interface';

import { ClientsRoutingModule } from './clients-routing.module';

@Component({
	selector: 'yuno-admin-clients',
	standalone: true,
	imports: [
		ClientsRoutingModule,
		YunoCardModule,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		AsyncPipe
	],

	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientsComponent extends AppDataComponent implements OnInit {
	private readonly clientFacade = inject(ClientsFacade);
	selected$ = this.clientFacade.selectedClient$;
	clients$ = this.clientFacade.clients$;

	hoveredItem: Partial<Client>;

	ngOnInit(): void {
		this.clientFacade.loadClients();
	}

	getData(data: Partial<Client>[] | null | undefined) {
		return data as { [key: string]: unknown }[];
	}

	onCreate() {
		this.clientFacade.clearSelect();

		this.router.navigate(['create'], {
			relativeTo: this.route
		});
	}

	onSelectClient(row: Partial<Client>) {
		this.clientFacade.select(row._id as string);
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}
}
