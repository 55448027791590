<ng-container>
	<h2 mat-dialog-title>Upload new file</h2>
	<mat-dialog-content class="mat-typography">
		<div class="w-screen max-w-screen-md">
			@if (currentFileUpload) {
				<div class="mb-4 w-auto">
					<mat-progress-bar
						class="example-margin"
						[color]="warning || error ? 'warn' : 'primary'"
						[mode]="'determinate'"
						[value]="progress.percentage"
						[bufferValue]="75">
					</mat-progress-bar>
					<div class="label">Uploading Files</div>
				</div>
			}
			<input
				type="file"
				#fileInput
				[accept]="acceptedFileTypes"
				(change)="selectFile($event)" />
			@if (fileToBig) {
				<div>The file size exceeds the {{ filesizeLimit }}MB size limit!</div>
			}
			@if (conflict && conflictType === 'file') {
				<yuno-admin-annotation class="col-span-3 mt-4" type="warning"
					>The file already exists! Do you want to Overwrite the
					file?</yuno-admin-annotation
				>
			}
			@if (conflict && conflictType === 'key') {
				<yuno-admin-annotation class="col-span-3 mt-4" type="warning"
					>There's already a file in this project with the same
					name!</yuno-admin-annotation
				>
			}
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<div class="flex gap-2">
			@if (conflict && conflictType === 'file') {
				<button yuno-admin-button color="warning" (click)="startUpload(true)">
					Overwrite
				</button>
			}
			<button
				yuno-admin-button
				[disabled]="!selectedFiles || fileToBig || conflict"
				color="primary"
				(click)="startUpload()">
				Upload
			</button>
			<button yuno-admin-button [mat-dialog-close]="true" color="primary">Close</button>
		</div>
	</mat-dialog-actions>
</ng-container>
