import { gql } from 'apollo-angular';

import { MarkerCategory } from '@yuno/api/interface';

export const SAVE_CATEGORY = gql`
	mutation saveCategory($category: CategoryInput!, $appId: String!) {
		saveCategory(category: $category, appId: $appId) {
			_id
			appId
			id
			order
			public
			intern
			zIndex
			fence
			fenceData {
				polygon
			}
			type
			layers {
				_id
				id
			}
			theme
			mapStyle
			styles {
				id
				overwriteZoom
				style
			}
			inputs {
				custom {
					key
					label
					listView
					options
					placeholder
					required
					type
				}
				preset {
					label
					listView
					options
					placeholder
					required
					type
				}
			}
			layout {
				fallback
				filter
				options
			}
			cluster {
				active
				countStyle {
					alignment
					backgroundColor
					color
				}
				options {
					minPoints
					minZoom
					maxZoom
					radius
				}
				style {
					alignment
					icon
					scale
				}
			}
			customEvents
			events {
				onClick {
					type
					options
				}
				onMouseMove {
					type
					options
				}
				onMouseLeave {
					type
					options
				}
			}
		}
	}
`;

export const DUPLICATE_CATEGORY = gql`
	mutation duplicateCategory($_id: String!, $appId: String!) {
		duplicateCategory(_id: $_id, appId: $appId) {
			_id
			id
			order
			public
			intern
			type
			inputs {
				custom {
					type
				}
				preset {
					type
				}
			}
			cluster {
				active
			}
			events {
				onClick {
					type
					options
				}
				onMouseMove {
					type
					options
				}
			}
		}
	}
`;

export type SaveCategoryMutation = {
	saveCategory: Partial<MarkerCategory>;
};

export type DuplicateCategoryMutation = {
	duplicateCategory: Partial<MarkerCategory>;
};
