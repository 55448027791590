import { JsonPipe, NgClass, NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';

import { LanguagePipe, SafeHtmlIframePipe, SafeHtmlPipe } from '@yuno/angular/pipes';
import { SvgComponent } from '@yuno/angular/svg-loader';
import { Event, LanguageStringsModel, PageItem, PageItemLink } from '@yuno/api/interface';
import { ButtonCardModule } from '@yuno/project-atlas/ui';

import { EventsService } from '../../services/events.service';
import {
	PageOptions,
	TextfieldInjectionToken,
	TextfieldToken
} from '../../textfield.injection.token';
import { TextLinkComponent } from '../text-link/text-link.component';

@Component({
	selector: 'yuno-page-item',
	templateUrl: './page-item.component.html',
	styleUrls: ['./page-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgStyle,
		SvgComponent,
		ButtonCardModule,
		NgClass,
		TextLinkComponent,
		SafeHtmlPipe,
		SafeHtmlIframePipe,
		LanguagePipe,
		JsonPipe
	]
})
export class TextPageItemComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	private events = inject(EventsService);

	@HostBinding('class') classNames = 'mt-8 first:mt-0 last:mb-8';

	@Input() language?: string;
	@Input() disableInjection = false;

	@Input() title?: PageItem['title'];
	@Input() imageButtons?: PageItem['imageButtons'];
	@Input() content?: LanguageStringsModel;
	@Input() links?: PageItemLink[];
	@Input() pageOptions?: PageOptions;

	@Input() textLinkColor = 'inherit';
	@Input() textHeadingColor = 'inherit';

	@Output() outputEvent = new EventEmitter<Event>();

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.colors &&
			this.setInjectionColors(this.injectValue.colors);

		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as PageItem,
				this.injectValue.language,
				this.injectValue.page
			);
	}

	/**
	 * Setup the Component by using the Injection Method
	 *
	 * @param {PageItem} data
	 */
	handleInjectionData(data: PageItem, language?: string, page?: PageOptions) {
		this.language = language;

		this.title = data.title;
		this.content = data.content;
		this.links = data.link;
		this.imageButtons = data.imageButtons;

		this.pageOptions = page;

		if (data.class === 'theme') {
			this.classNames = 'mt-4';
		}
	}

	setInjectionColors(colors: TextfieldInjectionToken['colors']) {
		this.textLinkColor = colors?.textLinkColor || 'inherit';
		this.textHeadingColor = colors?.textHeadingColor || 'inherit';
	}

	navigateTo(str?: string): void {
		if (!str) {
			return;
		}

		this.events.sendEvent([
			{
				type: 'navigateTo',
				options: {
					url: str
				}
			}
		]);
	}

	openPrivacy(bool: boolean): void {
		this.events.sendEvent([
			{
				type: 'openPrivacy',
				options: {
					type: bool ? 'client' : 'xkp'
				}
			}
		]);
	}
}
