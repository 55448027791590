import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnInit,
	Output,
	inject
} from '@angular/core';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageStringsModel, TextToggle, TextToggleItem } from '@yuno/api/interface';

import { EventsService } from '../../services/events.service';
import { TextfieldToken } from '../../textfield.injection.token';
import { TextTogglesToggleComponent } from './toggle/toggle.component';

export interface ToggleOutput {
	active: boolean;

	data?: {
		layers?: string[];
		datasets?: string[];
	};
}

@Component({
	selector: 'yuno-textfield-toggles',
	template: `
		@if (title | languageSelect: language; as titleLanguage) {
			@if (titleLanguage) {
				<span class="font-semibold italic">{{ titleLanguage }}</span>
			}
		}
		<ng-template #content let-toggle="toggle">
			<div class="toggle-container" [ngClass]="{ group: toggle.group }">
				@switch (toggle.group) {
					@case (true) {
						<span class="font-semibold italic">
							{{ toggle.title | languageSelect: language }}
						</span>
					}
					@case (false) {
						<yuno-textfield-toggles-toggle
							(checkedChange)="toggleChange($event, toggle)"
							[data]="toggle"
							[language]="language"
							[listlike]="listlike"
							[title]="toggle.title"
							[color]="toggle.color"
							[addColor]="addColor"></yuno-textfield-toggles-toggle>
					}
				}

				<!-- Child toggles -->
				@for (item of toggle.items; track item) {
					<yuno-textfield-toggles-toggle
						(checkedChange)="toggleChange($event, item)"
						[data]="item"
						[language]="language"
						[listlike]="listlike"
						[title]="item.title"
						[color]="item.color"
						[addColor]="addColor"></yuno-textfield-toggles-toggle>
				}
			</div>
		</ng-template>

		<!-- keep a div as container to have a correct toggles border  -->
		<div>
			@for (toggle of toggles; track toggle) {
				<ng-container
					[ngTemplateOutlet]="content"
					[ngTemplateOutletContext]="{ toggle: toggle }"></ng-container>
			}

			<!-- support for inlining yuno-text-toggles-toggle -->
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./text-toggles.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass, TextTogglesToggleComponent, NgTemplateOutlet, LanguagePipe]
})
export class TextTogglesComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	private event = inject(EventsService);

	private _toggles: TextToggle['items'];
	@HostBinding('class') classes = 'mt-4 md:mt-8 first:mt-0';

	@Input() language?: string;
	@Input() disableInjection = false;

	@Input() title?: LanguageStringsModel;
	@Input() listlike = false;

	@Input() set toggles(data: string | TextToggle['items']) {
		if (typeof data === 'string') {
			this._toggles = JSON.parse(data);
			return;
		}

		this._toggles = data;
	}

	get toggles(): TextToggle['items'] {
		return this._toggles;
	}

	addColor: boolean;

	@Output() toggleOutput = new EventEmitter<ToggleOutput>();

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as TextToggle,
				this.injectValue.language
			);
	}

	handleInjectionData(data: TextToggle, language?: string): void {
		this.title = data.title;
		this.toggles = data.items;
		this.addColor = data.addColor || false;
		this.language = language;
		this.listlike = !!data.listLike;
	}

	toggleChange(event: boolean, toggle: TextToggleItem) {
		this.toggleOutput.emit({
			active: event,
			data: {
				layers: toggle.layer,
				datasets: toggle.dataset
			}
		});

		if (toggle.layers && toggle.layers.length) {
			this.event.sendEvent([
				{
					type: 'toggleLayer',
					options: {
						layers: toggle.layers.map(layer => layer.id),
						visible: event
					}
				}
			]);
		}

		if (toggle.dataset && toggle.dataset.length) {
			for (const dataset of toggle.dataset) {
				this.event.sendEvent([
					{
						type: 'loadDataset',
						options: {
							id: dataset,
							remove: !event,
							multi: true
						}
					}
				]);
			}
		}
	}
}
