import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Legend } from '@yuno/api/interface';

import { legendActions } from './legend.actions';
import { legendFeature } from './legend.state';

@Injectable()
export class LegendFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(legendFeature.selectLoaded));
	legends$ = this.store.pipe(select(legendFeature.selectLegends));
	selectedLegends$ = this.store.pipe(select(legendFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(legendFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(legendFeature.selectStatus));

	getLegendsByAppId() {
		this.store.dispatch(legendActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(legendActions.select({ _id }));
	}

	updateSelect(data: Partial<Legend>) {
		this.store.dispatch(legendActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(legendActions.clearSelect());
	}

	save() {
		this.store.dispatch(legendActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(legendActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(legendActions.delete({ _id }));
	}
}
