import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isNil, omitBy } from 'lodash';
import { StyleSpecification } from 'maplibre-gl';
import { catchError, map, of, switchMap, take } from 'rxjs';

import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';
import { MapViewOptions } from '@yuno/api/interface';

import {
	GET_MAPSTYLES,
	GET_STYLE_BY_ID,
	GetDefaultStyleQuery,
	MapStylesQuery,
	StyleQuery
} from '../../utils';
import { mapActions } from './map.actions';

export const loadMapStyles = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(mapActions.loadMapStyles),
			switchMap(() =>
				graphql
					.query<MapStylesQuery>({
						query: GET_MAPSTYLES
					})
					.pipe(
						map(data => {
							if (!data.data.mapStyles) {
								message.sendToast(`Error getting the Map Styles list!`, 'error');
								throw new Error('no Map Styles found');
							}

							return mapActions.loadMapStylesSuccess({
								data: data.data.mapStyles
							});
						}),
						take(1),
						catchError(error => of(mapActions.loadMapStylesFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadStyle = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(mapActions.loadStyle),
			switchMap(style =>
				graphql
					.query<StyleQuery>({
						query: GET_STYLE_BY_ID,
						variables: {
							_id: style._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.style) {
								message.sendToast(`Error getting Style Spec data!`, 'error');
								throw new Error('no Style Spec with that id found');
							}

							return mapActions.loadStyleSuccess({
								data: data.data.style
							});
						}),
						take(1),
						catchError(error => of(mapActions.loadMapStylesFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadDefaultStyle = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(mapActions.loadDefault),
			switchMap(action =>
				graphql
					.query<{
						getThemeDefaultStyle: {
							style: StyleSpecification;
							view: MapViewOptions;
						};
					}>({
						query: GetDefaultStyleQuery,
						variables: { appId: action.appId }
					})
					.pipe(
						map(data => {
							if (!data.data.getThemeDefaultStyle) {
								throw new Error('no app data');
							}
							return mapActions.loadDefaultSuccess({
								style: omitBy(
									data.data.getThemeDefaultStyle.style,
									isNil
								) as StyleSpecification,
								view: data.data.getThemeDefaultStyle.view
							});
						}),
						take(1),
						catchError(error => of(mapActions.loadDefaultFailure({ error })))
					)
			)
		),
	{ functional: true }
);
