<button
	#button
	type="button"
	(click)="toggleDropdown()"
	[ngStyle]="{ color: active ? color : null, backgroundColor: active ? backgroundColor : null }">
	<span class="sr-only">Open a Dropdown</span>
	<span class="truncate">{{ currentValue || defaultValue }}</span>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		class="h-4 w-4"
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
		[ngClass]="{ '-scale-y-100': isDropdownOpen }">
		<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
	</svg>
</button>

<ng-template #dropdownContainer>
	<ul class="h-auto w-full overflow-auto rounded-md bg-gray-100 shadow-md">
		@if (resettable) {
			<li>
				<button
					type="button"
					(click)="onSelect('')"
					class="h-10 w-full truncate bg-gray-100 px-4 py-2 text-left transition-colors hover:bg-gray-200">
					{{ resetValue }}
				</button>
			</li>
		}
		@for (option of options; track option) {
			<li>
				<button
					type="button"
					(click)="onSelect(option)"
					class="w-full truncate bg-gray-100 px-4 py-2 text-left transition-colors hover:bg-gray-200">
					{{ option }}
				</button>
			</li>
		}
	</ul>
</ng-template>
