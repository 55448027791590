import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { EventForm, EventFormsService } from '@yuno/admin/features/events';
import { ArrayValidators, LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import { Navigation, NavigationItem, NavigationItemDropdown } from '@yuno/api/interface';

export interface NavForm {
	_id: FormControl<string>;
	navigation: FormArray<FormGroup<NavItemForm>>;
}

export interface NavItemForm {
	public: FormControl<boolean>;
	display: FormGroup<LanguageFormType>;
	dropdown: FormArray<FormGroup<NavDropdownItemForm>>;
	link: FormControl<string>;
	theme: FormGroup<{ _id: FormControl<string> }>;
	events: FormArray<FormGroup<EventForm>>;
}

export interface NavDropdownItemForm {
	public: FormControl<boolean>;
	display: FormGroup<LanguageFormType>;
	link: FormControl<string>;
	text: FormControl<string>;
	color: FormControl<string>;
	item: FormGroup<{ _id: FormControl<string> }>;
	theme: FormGroup<{ _id: FormControl<string> }>;
	events: FormArray<FormGroup<EventForm>>;
}

@Injectable({
	providedIn: 'root'
})
export class NavigationEditorService {
	private readonly eventsForm = inject(EventFormsService);

	form: FormGroup<NavForm>;
	currentIndex: number;
	dropdownIndex: number;

	themeValues: string[] = [];
	themeDisplay: string[] = [];

	pageValues: string[] = [];
	pageDisplay: string[] = [];

	imgBtnValues: string[] = [];
	imgBtnDisplay: string[] = [];

	get navigation(): FormArray<FormGroup<NavItemForm>> {
		return this.form.get('navigation') as FormArray;
	}

	get navItem(): FormGroup<NavItemForm> {
		return this.navigation.controls[this.currentIndex] as FormGroup<NavItemForm>;
	}

	get navDropdown(): FormArray<FormGroup<NavDropdownItemForm>> {
		const currentNavItem = this.navigation.controls[
			this.currentIndex
		] as FormGroup<NavItemForm>;
		if (currentNavItem) {
			return currentNavItem?.get('dropdown') as FormArray;
		}
		return new FormArray<FormGroup<NavDropdownItemForm>>([]);
	}

	get navEvents(): FormArray<FormGroup<EventForm>> {
		const currentNavItem = this.navigation.controls[
			this.currentIndex
		] as FormGroup<NavItemForm>;
		if (currentNavItem) {
			return currentNavItem?.get('events') as FormArray;
		}
		return new FormArray<FormGroup<EventForm>>([]);
	}

	get navDropdownItem(): FormGroup<NavDropdownItemForm> {
		return this.navDropdown.controls[this.dropdownIndex] as FormGroup<NavDropdownItemForm>;
	}

	get navDropdownEvents(): FormArray<FormGroup<EventForm>> {
		const currentDropdownItem = this.navDropdown.controls[
			this.dropdownIndex
		] as FormGroup<NavDropdownItemForm>;
		if (currentDropdownItem) {
			return currentDropdownItem?.get('events') as FormArray;
		}
		return new FormArray<FormGroup<EventForm>>([]);
	}

	createFormGroup(): void {
		this.form = new FormGroup<NavForm>({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{
					nonNullable: true
				}
			),
			navigation: new FormArray<FormGroup<NavItemForm>>([], ArrayValidators.minLength(1))
		});
	}

	removeNavItem(i: number): void {
		this.navigation.removeAt(i);
	}

	addNavItem(items?: NavigationItem, index?: number): void {
		const navItem = new FormGroup<NavItemForm>({
			public: new FormControl<boolean>(
				{ value: false, disabled: true },
				{ nonNullable: true }
			),
			display: newLanguageFormGroup(),
			dropdown: new FormArray<FormGroup<NavDropdownItemForm>>([]),
			link: new FormControl<string>('', { nonNullable: true }),
			theme: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			}),
			events: new FormArray<FormGroup<EventForm>>([])
		});

		if (items) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			navItem.patchValue(items as any);

			// Setup Events
			if (items.events) {
				for (const event of items.events) {
					const eventForm = this.eventsForm.createEvent(event.type, event.options);
					const arr = navItem.get('events') as FormArray;
					arr.push(eventForm);
				}
			}
		}
		this.navigation.push(navItem);
		if (items?.dropdown && items.dropdown.length >= 1) {
			this.addDropdownItems(items.dropdown, index);
		}
	}

	addNavItems(items: Navigation['navigation']): void {
		if (!items) {
			return;
		}
		const count = items.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addNavItem(items[i], i);
			}
		}
	}

	// DROPDOWN
	removeDropdownItem(i: number): void {
		this.navDropdown.removeAt(i);
	}

	addDropdownItem(items?: NavigationItemDropdown, index?: number): void {
		const dropdownItem = new FormGroup<NavDropdownItemForm>({
			public: new FormControl<boolean>(
				{ value: false, disabled: true },
				{ nonNullable: true }
			),
			color: new FormControl<string>('', { nonNullable: true }),
			display: newLanguageFormGroup(),
			events: this.eventsForm.createEventForm(),
			item: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			}),
			link: new FormControl<string>('', { nonNullable: true }),
			text: new FormControl<string>('', { nonNullable: true }),
			theme: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		});

		if (items) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			dropdownItem.patchValue(items as any);
		}

		if (index) {
			this.currentIndex = index;
		}

		this.navDropdown.push(dropdownItem);
	}

	addDropdownItems(items: NavigationItemDropdown[], index?: number): void {
		if (!items) {
			return;
		}

		const count = items.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addDropdownItem(items[i], index);
			}
		}
	}

	enablePublicToggles(): void {
		for (const control of this.navigation.controls) {
			control.controls.public.enable();

			const dropdown = control.get('dropdown') as FormArray<FormGroup<NavDropdownItemForm>>;
			if (dropdown) {
				for (const subControl of dropdown.controls) {
					subControl.controls.public.enable();
				}
			}
		}
	}
}
