@if (data$ | async; as data) {
	@if (data.toggle && dropdownEvents) {
		<yuno-admin-events-forms
			class="z-220"
			[group]="service.navDropdownItem"
			[array]="service.navDropdownEvents"
			[arrayName]="'events'">
		</yuno-admin-events-forms>
	}
	@if (service.navDropdownItem) {
		<yuno-edit-container [col]="12" [pos]="4" [span]="7" [style.zIndex]="210">
			<ng-container buttons>
				<button yuno-admin-close-button (click)="onClose()"></button>
			</ng-container>
			<div edit-container-content>
				@if (service.navDropdownItem) {
					<form [formGroup]="service.navDropdownItem">
						<div class="mb-8 flex flex-col gap-4">
							<section class="col-span-3" formGroupName="display">
								@for (lang of languages; track lang) {
									@if (lang === data.language) {
										<yuno-forms-text
											[language]="lang"
											[formControlName]="lang"
											label="Display"></yuno-forms-text>
									}
								}
							</section>
							<yuno-forms-divider></yuno-forms-divider>
							<ng-container formGroupName="item">
								<yuno-forms-select
									formControlName="_id"
									[placeholder]="'select image button'"
									[selectValues]="service.imgBtnValues"
									[display]="service.imgBtnDisplay"
									label="Image Button"></yuno-forms-select>
							</ng-container>
							<yuno-forms-divider></yuno-forms-divider>
							<div class="flex">
								<label class="yuno-form">
									<span class="select-none">Link type</span>
									<div
										class="pointer-events-auto flex h-[52px] flex-row gap-2 rounded-full border border-gray-300 bg-gray-50 p-1 font-semibold">
										<div
											(click)="linkToggle = 'map'"
											class="flex cursor-pointer select-none flex-row items-center justify-center rounded-full px-4 py-5 text-sm transition-colors ease-in-out hover:bg-gray-300"
											[ngClass]="{
												'pointer-events-none bg-yuno-blue text-white':
													linkToggle === 'map'
											}">
											<div class="flex flex-row gap-2">Map</div>
										</div>
										<div
											(click)="linkToggle = 'page'"
											class="flex cursor-pointer select-none flex-row items-center justify-center rounded-full px-4 py-5 text-sm transition-colors ease-in-out hover:bg-gray-300"
											[ngClass]="{
												'pointer-events-none bg-yuno-blue text-white':
													linkToggle === 'page'
											}">
											<div class="flex flex-row gap-2">Page</div>
										</div>
									</div>
								</label>
							</div>
							@if (linkToggle === 'map') {
								<yuno-forms-text
									formControlName="link"
									label="Link"
									(changes)="onLinkChange($event)"></yuno-forms-text>

								<yuno-admin-annotation>
									When editing this link, also make sure to update all Links/Image
									Buttons that should link to this Map.
								</yuno-admin-annotation>
								<!-- themes -->
								<ng-container formGroupName="theme">
									<yuno-forms-select
										formControlName="_id"
										[placeholder]="'select theme'"
										[selectValues]="service.themeValues"
										[display]="service.themeDisplay"
										label="Theme"></yuno-forms-select>
								</ng-container>
							}
							@if (linkToggle === 'page') {
								<yuno-forms-select
									formControlName="link"
									[placeholder]="'select page'"
									[selectValues]="service.pageValues"
									[display]="service.pageDisplay"
									label="Link to Page"></yuno-forms-select>
							}
							<yuno-forms-divider></yuno-forms-divider>
							<yuno-forms-color
								formControlName="color"
								colorDirection="bottom"
								placeholder="#fff"
								label="Color"></yuno-forms-color>
							<yuno-forms-divider></yuno-forms-divider>
							<yuno-forms-title>Events</yuno-forms-title>
							<div class="flex flex-row items-start justify-start gap-4">
								@if (
									{
										length: service.navDropdownEvents.controls.length || 0
									};
									as data
								) {
									<button yuno-admin-button (click)="onToggleEvents()">
										Events ({{ data.length }})
									</button>
								}
							</div>
						</div>
					</form>
				}
			</div>
		</yuno-edit-container>
	}
}
