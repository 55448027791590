import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	DELETE_PAGE,
	DUPLICATE_PAGE,
	DeletePageMutation,
	DuplicatePageMutation,
	GET_PAGEITEMS_BY_APPID,
	GET_PAGES_BY_APPID,
	GET_PAGE_BY_ID,
	PageItemsQuery,
	PagesQuery,
	SAVE_PAGE,
	SavePageMutation,
	SelectPageQuery
} from '../../../utils';
import { pagesActions } from './pages.actions';
import { pagesFeature } from './pages.state';

export const loadPages = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(pagesActions.load, pagesActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<PagesQuery>({
						query: GET_PAGES_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.pages) {
								throw new Error('no pages found');
							}
							return pagesActions.loadSuccess({
								data: data.data.pages
							});
						}),
						take(1),
						catchError(error => of(pagesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadPageItems = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(pagesActions.loadItems),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<PageItemsQuery>({
						query: GET_PAGEITEMS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.pageItems) {
								throw new Error('no page items found');
							}
							return pagesActions.loadItemsSuccess({
								data: data.data.pageItems
							});
						}),
						take(1),
						catchError(error => of(pagesActions.loadItemsFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectPages = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(pagesActions.select),
			exhaustMap(pages =>
				graphql
					.query<SelectPageQuery>({
						query: GET_PAGE_BY_ID,
						variables: {
							_id: pages._id
						}
					})
					.pipe(
						map(pages => {
							if (!pages.data.selectedPages) {
								message.sendToast(`Error selecting Page!`, 'error');
								throw new Error('no Pages with that id found');
							}

							return pagesActions.selectSuccess({
								data: pages.data.selectedPages
							});
						}),
						take(1),
						catchError(error => of(pagesActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedPage = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(pagesActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Page!`, 'error');
					throw new Error('no data found');
				}
				return pagesActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(pagesActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const savePage = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(pagesActions.save),
			withLatestFrom(
				store.pipe(select(pagesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SavePageMutation>({
						mutation: SAVE_PAGE,
						variables: {
							page: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.savePage) {
								throw new Error('error saving page to the database');
							}
							store.dispatch(
								pagesActions.updateSelect({
									data: data.data.savePage
								})
							);
							return pagesActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(pagesActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicatePage = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(pagesActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([page, appId]) =>
				graphql
					.mutate<DuplicatePageMutation>({
						mutation: DUPLICATE_PAGE,
						variables: {
							_id: page._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicatePage) {
								throw new Error('error duplicating page to the database');
							}
							store.dispatch(pagesActions.reload());
							return pagesActions.duplicateSuccess({
								data: data.data.duplicatePage
							});
						}),
						take(1),
						catchError(error => of(pagesActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deletePage = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(pagesActions.delete),
			switchMap(page =>
				graphql
					.mutate<DeletePageMutation>({
						mutation: DELETE_PAGE,
						variables: {
							_id: page._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deletePage) {
								throw new Error('error deleting page from the database');
							}
							store.dispatch(pagesActions.reload());
							return pagesActions.deleteSuccess({
								_id: data.data.deletePage
							});
						}),
						take(1),
						catchError(error => of(pagesActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const savePageSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(pagesActions.saveSuccess),
			tap(() => {
				message.sendToast(`Page successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const savePageFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(pagesActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Page!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicatePageSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(pagesActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Page successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicatePageFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(pagesActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Page!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deletePageSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(pagesActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Page successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deletePageFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(pagesActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting page!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
