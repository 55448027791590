import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { ChooseTemplateComponent } from '@yuno/admin/features/templates';
import {
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminButtonComponent,
	YunoAdminCloseButtonComponent
} from '@yuno/admin/ui';
import { TextfieldComponents } from '@yuno/api/interface';

import { TextfieldFacade } from '../../data-access';
import { TextfieldLibraryComponent } from './textfield-library.component';
import { TextfieldLibraryService } from './textfield-library.service';

@Component({
	selector: 'yuno-textfield-library-container',
	standalone: true,
	imports: [
		EditContainerComponent,
		YunoAdminCloseButtonComponent,
		TextfieldLibraryComponent,
		AsyncPipe,
		YunoAdminButtonComponent,
		EditContainerContentDirective,
		ChooseTemplateComponent
	],
	templateUrl: './textfield-library.container.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextfieldLibraryContainerComponent {
	private readonly service = inject(TextfieldLibraryService);
	private readonly textfieldFacade = inject(TextfieldFacade);

	toggleTemplates = false;

	onClose(): void {
		this.service.onClose();
		this.service.filter('');
	}

	onCreate(components?: TextfieldComponents[]): void {
		this.textfieldFacade.clearSelect();
		this.textfieldFacade.setTemplate(components);
		this.toggleTemplates = false;
		this.service.editTextfield('new');
	}
}
