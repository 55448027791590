@if (service.markers$ | async) {
	@if (form) {
		<form [formGroup]="form">
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				<yuno-forms-select
					formControlName="id"
					label="Marker"
					[display]="service.markerDisplay"
					[selectValues]="service.markerValues"
					placeholder="Select a Marker..."></yuno-forms-select>
			</div>
		</form>
	}
}
