import { gql } from 'apollo-angular';

export const DELETE_DATASET = gql`
	mutation deleteDatasetById($_id: String!) {
		deleteDataset(_id: $_id)
	}
`;

export type DeleteDatasetMutation = {
	deleteDataset: string;
};
