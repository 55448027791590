import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	GET_TEXTLIST,
	TextListsQuery
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/getTextfieldComponents';
import { GraphQLService } from '@yuno/angular-graphql';

import { textListsActions } from './text-list.actions';

export const loadTextLists = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textListsActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<TextListsQuery>({
						query: GET_TEXTLIST,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.lists) {
								throw new Error('no text-lists found');
							}
							return textListsActions.loadSuccess({
								data: data.data.lists
							});
						}),
						take(1),
						catchError(error => of(textListsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);
