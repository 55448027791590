import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-click-marker',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AnnotationComponent, AsyncPipe],
	templateUrl: './click-marker.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClickMarkerComponent extends EventsDefaultFormItemComponent implements OnInit {
	ngOnInit(): void {
		this.service.getMarkers();
	}
}
