import { gql } from 'apollo-angular';

export const DELETE_THEME = gql`
	mutation deleteThemeById($_id: String!) {
		deleteTheme(_id: $_id)
	}
`;

export type DeleteThemeMutation = {
	deleteTheme: string;
};
