import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { PrivacyStatement } from '@yuno/api/interface';

import { privacyStatementActions } from './privacy-statement-xkp.actions';

export interface PrivacyState {
	values: Partial<PrivacyStatement>[];
	selected: Partial<PrivacyStatement> | undefined;
	status: Status;
	statusSelected: Status;
}

export const initialState: PrivacyState = {
	values: [],
	selected: undefined,
	status: Status.PENDING,
	statusSelected: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(privacyStatementActions.clear, () => initialState),
	on(privacyStatementActions.clearSelected, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.PENDING
	})),
	on(privacyStatementActions.values, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(privacyStatementActions.valuesPending, state => ({
		...state,
		status: Status.PENDING
	})),
	on(privacyStatementActions.valuesSuccess, (state, action) => ({
		...state,
		values: action.data,
		status: Status.SUCCESS
	})),
	on(privacyStatementActions.valuesFailure, state => ({
		...state,
		status: Status.FAILED
	})),
	on(privacyStatementActions.patchValue, (state, action) => ({
		...state,
		selected: action.data
	})),

	on(privacyStatementActions.value, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.VALIDATING
	})),
	on(privacyStatementActions.valueSuccess, (state, action) => ({
		...state,
		selected: action.data,
		statusSelected: Status.SUCCESS
	})),
	on(privacyStatementActions.valueFailure, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.FAILED
	}))
);

export const privacyFeature = createFeature({
	name: 'privacy-statements',
	reducer
});
