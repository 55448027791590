@if (service$ | async; as service) {
	@if (data$ | async; as data) {
		<yuno-edit-container class="absolute" [col]="6" [pos]="2" [span]="4" [backdrop]="false">
			<ng-container buttons>
				<button yuno-admin-close-button (click)="editor._closeOverlay.next(true)"></button>
			</ng-container>

			<div edit-container-content>
				@if (data.listItem) {
					<form [formGroup]="data.listItem" class="flex flex-col gap-4">
						@if (data.selector === 'dateSummary') {
							<yuno-forms-date
								class="flex-1"
								formControlName="date"
								placeholder="Set date"
								label="Date" />
						}

						<section formGroupName="content">
							@for (lang of editService.languages; track lang) {
								@if (lang === data.language) {
									<yuno-forms-text
										[language]="lang"
										[formControlName]="lang"
										placeholder="Add message text here"
										label="Item title" />
								}
							}
						</section>

						<yuno-forms-text
							class="flex-1"
							formControlName="url"
							placeholder="Add url"
							label="Link url (optional)" />

						<yuno-forms-select
							class="flex-1"
							formControlName="page"
							placeholder="Select page url"
							label="Link to Page (optional)"
							[selectValues]="service.navigationSelectValues"
							[display]="service.navigationSelectDisplay" />
					</form>
				}
			</div>
		</yuno-edit-container>
	}
}
