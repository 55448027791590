import { Injectable, signal } from '@angular/core';
import { Environment } from 'nunjucks';

import { nunjuckLanguageFilterFunction, nunjuckLanguageFilterName } from '@yuno/shared/helpers';


@Injectable({
	providedIn: 'root'
})
export class NunjucksService {
	private env: Environment;
	$language = signal<string>('nl');

	constructor() {
		// Initialize the Nunjucks environment
		this.env = new Environment(null, { autoescape: false });

		this.env.addFilter(nunjuckLanguageFilterName, (str: string) => {
			return nunjuckLanguageFilterFunction(str, this.$language());
		});
	}

	setLanguage(lang: string): void {
		this.$language.set(lang);
	}

	// Method to render a string using Nunjucks
	render(template: string, context: any = {}): string {
		return this.env.render(template, context);
	}

	// Method to render a string using Nunjucks
	renderString(template: string, context: any = {}): string {
		const data = this.env.renderString(template, context);
		console.log('DATA', data);
		return data;
	}
}
