import { gql } from 'apollo-angular';

import { GeoPhotosCategory } from '@yuno/api/interface';

// export const CREATE_GEOPHOTO_CATEGORY = gql`
//     mutation createGeoPhotoCategory($appId: String!) {
//         createGeoPhotoCategory(appId: $appId) {
//             _id
//         }
//     }
// `;

export const GET_GEOPHOTO_CATEGORIES = gql`
	query geoPhotoCategories($appId: String!) {
		geoPhotoCategories(appId: $appId) {
			_id
			id
			public
		}
	}
`;

export const GET_GEOPHOTO_CATEGORY = gql`
	query getGeoPhotoCategory($id: String!, $appId: String!) {
		getGeoPhotoCategory(appId: $appId, _id: $id) {
			_id
			id
			viewer
			public
			disclaimer
			theme
			fence
			fenceData {
				polygon
			}
		}
	}
`;

export const SAVE_GEOPHOTO_CATEGORY = gql`
	mutation saveGeoPhotoCategory($data: GeoPhotosCategoryInput!, $appId: String!) {
		saveGeoPhotoCategory(data: $data, appId: $appId) {
			_id
			id
			viewer
			public
			disclaimer
			theme
			fence
			fenceData {
				polygon
			}
		}
	}
`;

export const DELETE_GEOPHOTO_CATEGORY = gql`
	mutation deleteGeoPhotoCategoryById($_id: String!, $appId: String!) {
		deleteGeoPhotoCategoryById(_id: $_id, appId: $appId)
	}
`;

// export const DUPLICATE_GEOPHOTO_CATEGORY = gql`
//     mutation duplicateGeoPhotoCategoryById($_id: String!, $appId: String!) {
//         duplicateGeoPhotoCategoryById(_id: $_id, appId: $appId) {
//             _id
//             id
//             updatedAt
//         }
//     }
// `;

export type CreateMutation = {
	createGeoPhotoCategory: {
		_id: string;
	};
};

export type GetQuery = {
	geoPhotoCategories: Partial<GeoPhotosCategory>[];
};

export type SelectQuery = {
	getGeoPhotoCategory: Partial<GeoPhotosCategory>;
};

export type SaveMutation = {
	saveGeoPhotoCategory: GeoPhotosCategory;
};

export type DuplicateMutation = {
	duplicateGeoPhotoCategoryById: GeoPhotosCategory;
};

export type DeleteMutation = {
	deleteGeoPhotoCategoryById: string;
};
