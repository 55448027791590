/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unused-vars */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ENV_ADMIN_TYPE } from '@yuno/admin/core';

import { globalEnvironment } from './../../../../_environments/environment.global';

declare let require: any;
export const environment: ENV_ADMIN_TYPE = {
	...globalEnvironment,
	'yuno-matomo-token': 'aa860b5d6c3b60ddf44190669abc1227'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
