import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';
import { AsyncSubject, lastValueFrom } from 'rxjs';

import { waitFor } from '@yuno/shared/helpers';

import { YunoAdminButtonComponent } from '../button';

@Component({
	selector: 'yuno-code-editor',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, MonacoEditorModule, YunoAdminButtonComponent],
	templateUrl: './code-editor.component.html',
	styleUrls: ['./code-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeEditorComponent {
	private readonly cdr = inject(ChangeDetectorRef);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private _editor = new AsyncSubject<void>();
	editor$ = this._editor.asObservable();

	private _control: UntypedFormControl = new UntypedFormControl('');
	private _language: 'json' | 'html' | 'xml' | 'plaintext' = 'json';
	private _readOnly = false;

	@Input()
	set control(form: UntypedFormControl) {
		this._control = form;
	}

	get control() {
		return this._control;
	}

	@Input() format = true;
	@Input() height = 300;
	@Input()
	set language(lang: 'json' | 'html' | 'xml' | 'plaintext') {
		this._language = lang;
		this.updateOptions();
	}

	get language() {
		return this._language;
	}

	@Input()
	set readOnly(bool: boolean) {
		this._readOnly = bool;
		this.updateOptions();
	}

	get readOnly() {
		return this._readOnly;
	}

	@Output() changed = new EventEmitter<string>();

	editorOptions: NgxMonacoEditorConfig['defaultOptions'] = {
		theme: 'vs-dark',
		language: this.language,
		scrollBeyondLastLine: false,
		lineNumbers: 'off',
		contextmenu: false,
		snippetSuggestions: 'none',
		codeLens: false,
		automaticLayout: true,
		minimap: {
			enabled: false
		},
		readOnly: this.readOnly
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	editor: any;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMonacoInit(editor: any): void {
		this.editor = editor;

		this._editor.next(undefined);
		this._editor.complete();

		this.onFormat();
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async getEditor(): Promise<any> {
		await lastValueFrom(this.editor$);
		return this.editor;
	}

	async onFormat(): Promise<void> {
		await this.getEditor();

		// adds a additional wait
		// to let the editor populate its data
		await waitFor(500);

		// perform the format action
		this.editor.getAction('editor.action.formatDocument').run();
		this.cdr.detectChanges();
	}

	async updateOptions(): Promise<void> {
		await this.getEditor();
		this.editorOptions = {
			...this.editorOptions,
			language: this.language,
			readOnly: this.readOnly
		};
		this.cdr.detectChanges();
	}

	onChange(event: string): void {
		this.changed.emit(event);
		this.onFormat();
	}
}
