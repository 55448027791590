@if (control?.invalid && (control?.dirty || control?.touched)) {
	<div class="invalid-message" [ngClass]="{ top: top }">
		<div class="invalid-message-wrapper grid grid-cols-1 gap-2">
			<ng-content></ng-content>
		</div>
		<!-- if no ng-content -->
		<span class="fallback-errors">
			{{ control?.errors | json }}
		</span>
	</div>
}
