import { ElementRef, Injectable } from '@angular/core';

import { loadScript } from './_helpers';

export const VimeoApiUrl = 'https://player.vimeo.com/api/player.js';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Vimeo: any;

@Injectable({
	providedIn: 'root'
})
export class VimeoService {
	// Check if the VimeoAPI should be loaded in Memory
	private loaded = false;

	// Store all Active Videos
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private videoMap: { [key: string]: any } = {};

	/**
	 * Load the Vimeo API script
	 * @return {*}  {Promise<boolean>}
	 */
	async setupApi(): Promise<boolean> {
		try {
			if (this.loaded) {
				return true;
			}

			return await loadScript(VimeoApiUrl);
		} catch (err) {
			console.error(err);
			return false;
		}
	}

	/**
	 * Creates a Vimeo player using the Vimeo Player API
	 *
	 * @param {ElementRef['nativeElement']} el  The Native Element of the Parent Container
	 * @param {string} url Url to the Video, can be a VimeoID or player.vimeo.com url
	 * @param {string} id ID to store in memory
	 * @return {*}  {Promise<void>}
	 */
	async setupPlayer(el: ElementRef['nativeElement'], url: string, id: string): Promise<void> {
		await this.setupApi();
		const options = {
			url: url,
			maxwidth: 1280,
			/* No tracking */
			dnt: true
		};
		this.videoMap[id] = new Vimeo.Player(el, options);
	}

	/**
	 * removes a video by its ID
	 * @param id {string}
	 */
	async destroyPlayer(id: string): Promise<void> {
		if (this.videoMap[id]) {
			await this.videoMap[id].destroy();
			delete this.videoMap[id];
		}
	}
}
