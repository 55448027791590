import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DestroyRef, Injectable, TrackByFunction, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { isEqual, sortBy } from 'lodash';
import { debounceTime, startWith, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { EventForm, EventsFacade } from '@yuno/admin/features/events';
import { MessageService } from '@yuno/angular/notifications';
import { TextfieldDataInject, TextfieldDataService } from '@yuno/angular/textfield';
import {
	Pages,
	PagesComponentKeys,
	PagesComponents,
	PagesComponentsTypes
} from '@yuno/api/interface';
import { PagesHeader } from '@yuno/api/schema';

import { PageComponentForm, PageEditorService, PageForm, PagesFacade } from '../../../data-access';
import { ContentKeys } from '../../content/types';
import {
	HeaderForm,
	ImageBannerForm
} from '../../item-editors/page-header-editor/page-header.interface';
import { DuplicatePageComponent } from './pages-dynamic/pages-dynamic.component';

export interface editorTypes {
	type: ContentKeys | 'header' | undefined;
	content: PagesComponentsTypes | FormGroup | undefined;
	index: number | undefined;
	side: 'left' | 'right';
}

@Injectable()
export class PagesEditorComponentService {
	private readonly destroyRef = inject(DestroyRef);
	private readonly appFacade = inject(AppFacade);
	private readonly pagesFacade = inject(PagesFacade);
	private readonly eventsFacade = inject(EventsFacade);

	private readonly message = inject(MessageService);
	private readonly textfieldDataService = inject(TextfieldDataService);

	readonly pagesEditorService = inject(PageEditorService);

	originalData: Partial<Pages>;
	checkForId = false;
	addItems = false;
	library = false;
	desktopView = true;
	disableClose = false;
	view: 'desktop' | 'mobile' = 'desktop';

	injectorComponents: TextfieldDataInject[];

	editor: editorTypes = {
		type: undefined,
		content: undefined,
		index: undefined,
		side: 'left'
	};

	page$ = this.pagesFacade.selectedPages$.pipe(
		tap(data => {
			if (!this.originalData) {
				this.originalData = data as Pages;
				this.pagesEditorService.active$.next(true);

				// First we patch the value of the form
				// to make sure all default values are set
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				this.pagesEditorService.form.patchValue(this.originalData as any);

				// then we create the components
				// and add them to the form, prepatched at creation
				this.pagesEditorService.addComponents(data?.components);

				// update order at import
				this.pagesEditorService.updateComponentIndexOrder();

				if (data?.header?.public === null) {
					this.pagesEditorService.header.get('public')?.setValue(true);
				}
			}

			if (data && this.checkForId) {
				this.checkForId = false;
				if (data.components && data.components.length >= 1) {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.pagesEditorService.components.patchValue(data.components as any);
				}
			}

			// if (data?._id && this.router.url.includes('create')) {
			// 	redirectTo(this.route, this.router, ['edit', `${data._id}`]);
			// }
		})
	);
	toggle$ = this.eventsFacade.toggle$.pipe(
		startWith(false),
		tap(toggle => {
			if (toggle) {
				this.pagesEditorService.disableClose = true;
			}
			if (!toggle) {
				setTimeout(() => {
					this.pagesEditorService.disableClose = false;
				}, 500);
			}
		})
	);
	language$ = this.appFacade.language$.pipe(startWith('nl'));

	get form(): FormGroup<PageForm> {
		return this.pagesEditorService.form;
	}

	get header(): FormGroup<HeaderForm> {
		return this.pagesEditorService.header;
	}

	get logo(): FormGroup {
		return this.pagesEditorService.logo;
	}

	get banner(): FormGroup<{
		active: FormControl<boolean>;
		imageBanner: FormGroup<ImageBannerForm>;
	}> {
		return this.pagesEditorService.banner;
	}

	get component(): FormGroup | null {
		return this.pagesEditorService.component;
	}

	get components(): FormArray<FormGroup<PageComponentForm>> {
		return this.pagesEditorService.components;
	}

	get events(): FormArray<FormGroup<EventForm>> | null {
		return this.pagesEditorService.events;
	}

	get eventType(): 'events' | 'activeEvents' {
		return this.pagesEditorService.eventType;
	}

	onChanges(): void {
		this.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.pagesFacade.updateSelect(this.form.getRawValue() as Partial<Pages>);
			});
	}

	onSelect(id: string | null) {
		if (!id) {
			this.pagesFacade.updateSelect(this.form.getRawValue() as Partial<Pages>);
			return;
		}
		this.pagesFacade.select(id);
	}

	/**
	 * Save the current Page data
	 */
	onSave(): void {
		this.form.markAllAsTouched();
		this.pagesFacade.save();
		this.checkForId = true;
	}

	/**
	 * Start closing the current editor
	 */
	onClose(): void {
		this.pagesFacade.clearSelect();
		this.pagesEditorService.active$.next(false);
	}

	/**
	 * Changes all the components to the default view
	 * does not do a thing when switching to side-by-side
	 */
	onDisplayChange(event: string | null): void {
		if (event === 'default') {
			this.pagesEditorService.changeAllToDefaultView();
		}
	}

	/**
	 * Opens the Library to pick a existing component
	 * to add to the current page
	 */
	onBrowseLibrary(): void {
		this.onEditClear();
		this.addItems = false;
		this.library = true;
	}

	onUpdatePagesContent(cont: TextfieldDataInject[]): void {
		const components: PagesComponents[] = [];
		for (const value of cont) {
			const element: PagesComponents = {
				[value.key]: value.data,
				order: value.page?.order || 0,
				orderMobile: value.page?.orderMobile || 0,
				pageSide: value.page?.pageSide || 'left'
			};
			components.push(element);
		}

		this.pagesEditorService.addComponents(components);
	}

	onRemoveComponent(index: number, side: 'left' | 'right') {
		this.pagesEditorService.remove(index, side);
	}

	injectComponents(data: TextfieldDataInject[]) {
		this.injectorComponents = data;
		// this.cdr.markForCheck();
	}

	// Creates a object with all TextfieldDataInjector
	// components. Sorted in the Left, Right and Mobile arrays
	returnComponents(data: TextfieldDataInject[] | undefined):
		| {
				left: TextfieldDataInject[];
				right: TextfieldDataInject[];
				mobile: TextfieldDataInject[];
				mobileOrderDefault: TextfieldDataInject[];
		  }
		| undefined {
		if (!data) {
			return;
		}

		const left: TextfieldDataInject[] = sortBy(
			data.filter(comp => comp.page?.pageSide === 'left'),
			data => data.page?.order || 0
		);

		const right: TextfieldDataInject[] = sortBy(
			data.filter(comp => comp.page?.pageSide === 'right'),
			data => data.page?.order || 0
		);

		// Special mobile ordering
		const mobile: TextfieldDataInject[] = sortBy(data, data => data.page?.orderMobile || 0);

		// Uses the default page order. When using the
		// default view
		const mobileOrderDefault: TextfieldDataInject[] = sortBy(
			data,
			data => data.page?.order || 0
		);

		return {
			left,
			right,
			mobile,
			mobileOrderDefault
		};
	}

	trackByComponentId: TrackByFunction<TextfieldDataInject> = (index, component) => {
		this.textfieldDataService.getComponentId(index, component);
	};

	/**
	 * Drag and Drop items in Single Column mode
	 * or when Side-By-Side is active for Desktop only
	 *
	 * can change the order of item in the same array
	 * or move items from left to right and vice versa
	 */
	drop(
		event: CdkDragDrop<TextfieldDataInject[]>,
		arr: TextfieldDataInject[],
		targetArr: TextfieldDataInject[],
		target: 'left' | 'right'
	): void {
		// clears the edit container
		// because this requires a index, that is being changed here
		this.onEditClear();

		// When it is the same container we move the item
		if (event.previousContainer === event.container) {
			moveItemInArray(targetArr, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(arr, targetArr, event.previousIndex, event.currentIndex);
		}

		// Update Side on the TargetArray
		// and the order to Array Index
		for (const [index, value] of targetArr.entries()) {
			if (value.page) {
				value.page.order = index;
				value.page.pageSide = target;
			}
		}

		// Update order to Array index of the other Array
		for (const [index, value] of arr.entries()) {
			if (value.page) {
				value.page.order = index;
			}
		}

		// Updates the pages view
		this.onUpdatePagesContent([...targetArr, ...arr]);
	}

	/**
	 * Only move items in the Mobile Column
	 */
	dropMobile(event: CdkDragDrop<TextfieldDataInject[]>): void {
		// clears the edit container
		// because this requires a index, that is being changed here
		this.onEditClear();

		// Moves the item in the array
		moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

		// Updates mobileOrder to Array Index
		for (const [index, value] of event.container.data.entries()) {
			if (value.page) {
				value.page.orderMobile = index;
			}
		}

		// Updates the pages view
		this.onUpdatePagesContent(event.container.data);
	}

	/**
	 * Opends the selector view to add new components
	 * to the current page
	 */
	onAddItem(): void {
		this.onEditClear();
		this.addItems = true;
		this.library = false;
	}

	/**
	 * Adds a new or existing component to the page
	 * When the component parameter is defined
	 * we add a existing component to the page
	 */
	onAddComponent(
		key: ContentKeys | 'header',
		component?: PagesComponents | PagesHeader | undefined
	): void {
		if (key === 'header') {
			this.pagesEditorService.header.get('public')?.setValue(true);
			this.message.sendToast(`added the header!`, 'success');
			return;
		}

		this.pagesEditorService.addComponent(key, component as PagesComponents).then(bool => {
			bool
				? this.message.sendToast(`successfully added the ${key}!`, 'success')
				: this.message.sendToast(`failed to add the ${key}!`, 'error');
		});
	}

	/**
	 * Duplicate a existing component
	 */
	onDuplicateComponent(
		options: DuplicatePageComponent,
		side: 'left' | 'right',
		index: number
	): void {
		if (!options.component) {
			this.message.sendToast(`failed to duplicated the ${options.type}!`, 'error');
			return;
		}

		const filteredArray: FormGroup<PageComponentForm>[] = this.components.controls.filter(
			value => value.get('pageSide')?.value === side
		);

		// Get the item from the filtered array
		// then we get the original index of the item
		// that we need in order to update the correct item
		const desiredItem: FormGroup<PageComponentForm> = filteredArray[index];
		const originalIndex: number = this.components.controls.findIndex(
			value => value.value === desiredItem.value
		);

		// Original Page component data
		const originalPageComponent = this.components.controls[originalIndex].value;

		// increment order and orderMobile
		// so the component is added after the original component
		let newOrder: number = originalPageComponent.order ? originalPageComponent.order + 1 : 1;
		let newOrderMobile: number = originalPageComponent.orderMobile
			? originalPageComponent.orderMobile + 1
			: 1;

		if (this.form?.get('type')?.value === 'side-by-side') {
			newOrder = (originalIndex || 0) + 1;
			newOrderMobile = originalPageComponent.orderMobile
				? originalPageComponent.orderMobile + 1
				: 1;
		}

		// Create a deep copy of the component
		// and remove the id when available
		const comp = JSON.parse(JSON.stringify(options.component));
		if (typeof comp !== 'string') {
			// removes the objectId
			// to later save as a new component
			delete comp._id;

			// id is used to create a unique identifier
			// when duplicating we adjust this, to avoid
			// saving issues
			if (comp.id) {
				comp.id = `${comp.id}-duplicate-${Date.now()}`;
			} else {
				comp.id = `duplicate-${Date.now()}`;
			}

			// 	same applies to Name
			if (comp.name) {
				comp.name = `${comp.name}-duplicate-${Date.now()}`;
			} else {
				comp.name = `duplicate-${Date.now()}`;
			}
		}

		// creates the component
		this.pagesEditorService
			.addComponent(options.type, {
				[options.type]: comp,
				order: newOrder,
				orderMobile: newOrderMobile,
				pageSide: side
			})
			.then(form => {
				if (!form) {
					this.message.sendToast(`failed to duplicated the ${options.type}!`, 'error');
					return;
				}

				// start editing the duplicated component
				this.onEditComponent(options.type, comp, side, originalIndex + 1);

				this.message.sendToast(`successfully duplicated the ${options.type}!`, 'success');
			});
	}

	onEditHeader(header: FormGroup) {
		this.onEditClear();

		setTimeout(() => {
			this.editor.type = 'header';
			this.editor.content = header;
			this.addItems = false;
			this.library = false;
		}, 100);
	}

	onDisableHeader() {
		this.onEditClear();
		this.pagesEditorService.header.get('public')?.setValue(false);
	}

	onEditComponent(
		event: ContentKeys | 'header',
		item: PagesComponentsTypes,
		side: 'left' | 'right',
		index: number
	) {
		this.onEditClear();

		const filteredArray = this.components.controls.filter(
			value => value.get('pageSide')?.value === side
		);

		// Get the item from the filtered array
		// then we get the original index of the item
		// that we need in order to update the correct item
		const desiredItem = filteredArray.find(value => value.value.order === index);
		if (!desiredItem) {
			return;
		}

		const originalIndex = this.components.controls.findIndex(value =>
			isEqual(value.value, desiredItem.value)
		);

		setTimeout(() => {
			this.editor = {
				type: event,
				content: item,
				index,
				side
			};

			this.addItems = false;
			this.library = false;
			this.pagesEditorService.componentIndex = originalIndex;
			this.pagesEditorService.componentType = event;
		});
	}

	onEditMobileComponent(
		event: PagesComponentKeys | 'header',
		item: PagesComponentsTypes,
		index: number
	) {
		this.onEditClear();

		const components = this.components as FormArray<FormGroup<PageComponentForm>>;

		const sortedArray = sortBy(components.getRawValue(), data => data.orderMobile);
		const desiredItem = sortedArray[index];

		const originalIndex = components.controls.findIndex(value =>
			isEqual(value.value, desiredItem)
		);

		setTimeout(() => {
			this.editor = {
				type: event,
				content: item,
				index: originalIndex,
				side: desiredItem.pageSide || 'left'
			};

			this.addItems = false;
			this.library = false;
			this.pagesEditorService.componentIndex = originalIndex;
			this.pagesEditorService.componentType = event;
		}, 100);
	}

	onEditClear() {
		this.pagesEditorService.componentIndex = undefined;
		this.pagesEditorService.componentType = undefined;

		this.editor = {
			type: undefined,
			content: undefined,
			index: undefined,
			side: 'left'
		};
	}

	onDestroy() {
		this.pagesFacade.clearSelect();
	}
}
