import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
	catchError,
	exhaustMap,
	map,
	mergeMap,
	of,
	switchMap,
	take,
	tap,
	withLatestFrom
} from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { sourcesFeature } from '@yuno/admin/features/sources/data-access';
import {
	DELETE_SOURCE,
	DeleteSourceMutation
} from '@yuno/admin/features/sources/utils/graphql/sources/deleteSources';
import {
	GET_SOURCELAYERS,
	GET_SOURCES_BY_APPID,
	GET_SOURCE_BY_ID,
	GetSourceLayersQuery,
	SelectSourceQuery,
	SourcesQuery
} from '@yuno/admin/features/sources/utils/graphql/sources/getSources';
import {
	DUPLICATE_SOURCE,
	DuplicateSourceMutation,
	SAVE_SOURCE,
	SaveSourceMutation
} from '@yuno/admin/features/sources/utils/graphql/sources/saveSources';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { sourcesActions } from './sources.actions';

export const loadSources = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesActions.load, sourcesActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<SourcesQuery>({
						query: GET_SOURCES_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.sources) {
								throw new Error('no Sources found');
							}
							return sourcesActions.loadSuccess({
								data: data.data.sources
							});
						}),
						take(1),
						catchError(error => of(sourcesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectSource = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(sourcesActions.select),
			exhaustMap(data =>
				graphql
					.query<SelectSourceQuery>({
						query: GET_SOURCE_BY_ID,
						variables: {
							_id: data._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedSource) {
								message.sendToast(`Error selecting Source!`, 'error');
								throw new Error('no Source with that id found');
							}

							return sourcesActions.selectSuccess({
								data: data.data.selectedSource
							});
						}),
						take(1),
						catchError(error => of(sourcesActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const getSourceLayers = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesActions.sourceLayers),
			exhaustMap(data =>
				graphql
					.query<GetSourceLayersQuery>({
						query: GET_SOURCELAYERS,
						variables: {
							url: data.url
						}
					})
					.pipe(
						map(data => {
							if (!data.data.sourceLayers) {
								throw new Error('no Source Layers found');
							}

							return sourcesActions.sourceLayersSuccess({
								data: data.data.sourceLayers
							});
						}),
						take(1),
						catchError(error => of(sourcesActions.sourceLayersFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const getSourceLayersById = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesActions.sourceLayersById),
			mergeMap(action => store.select(sourcesFeature.getSourceUrl(action.id))),
			switchMap(id => {
				if (!id) {
					return of(sourcesActions.sourceLayersSuccess({ data: [] }));
				}
				return graphql
					.query<GetSourceLayersQuery>({
						query: GET_SOURCELAYERS,
						variables: {
							url: id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.sourceLayers) {
								throw new Error('no Source Layers found');
							}

							return sourcesActions.sourceLayersSuccess({
								data: data.data.sourceLayers
							});
						}),
						take(1),
						catchError(error => of(sourcesActions.sourceLayersFailure({ error })))
					);
			})
		),
	{ functional: true }
);

export const getSourceLayersByName = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesActions.sourceLayersByName),
			mergeMap(action => store.select(sourcesFeature.getSourceName(action.id))),
			switchMap(name => {
				if (!name) {
					return of(sourcesActions.sourceLayersSuccess({ data: [] }));
				}
				return graphql
					.query<GetSourceLayersQuery>({
						query: GET_SOURCELAYERS,
						variables: {
							url: name
						}
					})
					.pipe(
						map(data => {
							if (!data.data.sourceLayers) {
								throw new Error('no Source Layers found');
							}

							return sourcesActions.sourceLayersSuccess({
								data: data.data.sourceLayers
							});
						}),
						take(1),
						catchError(error => of(sourcesActions.sourceLayersFailure({ error })))
					);
			})
		),
	{ functional: true }
);

export const updateSelected = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating source!`, 'error');
					throw new Error('no Source found');
				}
				return sourcesActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(sourcesActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveSource = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesActions.save),
			withLatestFrom(
				store.pipe(select(sourcesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveSourceMutation>({
						mutation: SAVE_SOURCE,
						variables: {
							source: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveSource) {
								throw new Error('error saving Source to the database');
							}
							store.dispatch(
								sourcesActions.updateSelect({ data: data.data.saveSource })
							);
							const _id = data.data?.saveSource._id || '';
							return sourcesActions.saveSuccess({ _id });
						}),
						take(1),
						catchError(error => of(sourcesActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateSource = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.mutate<DuplicateSourceMutation>({
						mutation: DUPLICATE_SOURCE,
						variables: {
							_id: data._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateSource) {
								throw new Error('error duplicating Source to the database');
							}
							store.dispatch(sourcesActions.reload());
							return sourcesActions.duplicateSuccess({
								data: data.data.duplicateSource
							});
						}),
						take(1),
						catchError(error => of(sourcesActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteSource = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesActions.delete),
			switchMap(data =>
				graphql
					.mutate<DeleteSourceMutation>({
						mutation: DELETE_SOURCE,
						variables: {
							_id: data._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteSource) {
								throw new Error('error deleting Source from the database');
							}
							store.dispatch(sourcesActions.reload());
							return sourcesActions.deleteSuccess({
								_id: data.data.deleteSource
							});
						}),
						take(1),
						catchError(error => of(sourcesActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesActions.saveSuccess),
			tap(() => {
				message.sendToast(`Source successfully saved!`, 'success');
			})
		),
	{ functional: true, dispatch: false }
);
export const saveFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Source!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);

export const duplicateSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Source successfully duplicated!`, 'success');
			})
		),
	{ functional: true, dispatch: false }
);
export const duplicateFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Source!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);

export const deleteSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Source successfully deleted!`, 'success');
			})
		),
	{ functional: true, dispatch: false }
);
export const deleteFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Source!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);

export const getSourceLayersFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesActions.sourceLayersFailure),
			tap(() => {
				message.sendToast(`Error getting Source Layers!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);
