<router-outlet></router-outlet>

<yuno-card>
	@if (fences$ | async; as fences) {
		<ng-container content>
			<section class="grid grid-cols-1 gap-4">
				@if (minimalAppRole(userRoles.EDITOR)) {
					<div class="flex justify-between">
						<button yuno-admin-add-button (click)="onCreate()">New Fence</button>
						@if (minimalAppRole(userRoles.ADMIN)) {
							<div class="flex gap-2">
								<button yuno-admin-button (click)="uploadGeojson()">
									<div class="flex gap-2">
										<svg
											viewBox="0 0 24 24"
											class="h-5 w-5"
											fill="none"
											stroke-width="1.5"
											stroke="currentColor">
											<use href="#upload-icon" />
										</svg>
										<span>Upload GeoJSON</span>
									</div>
								</button>
								<button
									yuno-admin-button
									(click)="downloadGeojson()"
									color="secondary">
									<div class="flex gap-2">
										<svg
											viewBox="0 0 24 24"
											class="h-5 w-5"
											fill="none"
											stroke-width="1.5"
											stroke="currentColor">
											<use href="#download-icon" />
										</svg>
										<span> Download GeoJSON </span>
									</div>
								</button>
							</div>
						}
					</div>
				}
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="false"
					[pagination]="true"
					[pageOptions]="{
						pageSize: 100,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="fences"
					[buttons]="tableButtons"
					[columns]="[
						{ key: 'id', label: 'fence' },
						{ key: 'minZoom', label: 'Min. Zoom' },
						{ key: 'maxZoom', label: 'Max. Zoom' }
					]"
					(clicked)="onSelect($event)">
				</yuno-admin-table>
			</section>
		</ng-container>
	}
</yuno-card>

<ng-template #tableButtons let-row>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
