import { Injectable, inject } from '@angular/core';
import { combineLatest, startWith, tap } from 'rxjs';

import { TextfieldDataInject } from '@yuno/angular/textfield';
import {
	MediaImage,
	MediaNotification,
	MediaVideo,
	PageItem,
	PagesComponents,
	TextButton,
	TextCustom,
	TextLink,
	TextList,
	TextNewsItem,
	TextToggle,
	Textblock,
	TextfieldComponents
} from '@yuno/api/interface';

import {
	ImageFacade,
	NotificationFacade,
	PagesFacade,
	TextButtonFacade,
	TextCustomFacade,
	TextLinkFacade,
	TextListFacade,
	TextNewsItemFacade,
	TextToggleFacade,
	TextblockFacade,
	VideoFacade
} from '../../data-access';
import { ContentKeys } from './types';

export interface ComponentList {
	key: ContentKeys;
	label: string;
	disabled?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class TextfieldContentService {
	private readonly textblockFacade = inject(TextblockFacade);
	private readonly textButtonFacade = inject(TextButtonFacade);
	private readonly textCustomFacade = inject(TextCustomFacade);
	private readonly textLinkFacade = inject(TextLinkFacade);
	private readonly textListFacade = inject(TextListFacade);
	private readonly textToggleFacade = inject(TextToggleFacade);
	private readonly mediaNotificationFacade = inject(NotificationFacade);
	private readonly mediaImageFacade = inject(ImageFacade);
	private readonly mediaVideoFacade = inject(VideoFacade);
	private readonly newsFacade = inject(TextNewsItemFacade);
	private readonly pagesFacade = inject(PagesFacade);

	injectorComponents: TextfieldDataInject[];
	components: PagesComponents[] = [];
	loaderActive = false;
	listVal: ContentKeys | undefined;

	data$ = combineLatest({
		textblocks: this.textblockFacade.allTextblocks$.pipe(
			startWith([]),
			tap(texts => {
				if (this.listVal === 'textblock') {
					this.components = [];
					if (texts && texts.length >= 1) {
						texts.forEach(txt => {
							this.components.push({ textblock: txt as Textblock });
						});
					}

					this.loaderActive = false;
				}
			})
		),
		buttons: this.textButtonFacade.buttons$.pipe(
			startWith([]),
			tap(buttons => {
				if (this.listVal === 'button') {
					this.components = [];
					if (buttons && buttons.length >= 1) {
						buttons.forEach(btn => {
							this.components.push({ button: btn as TextButton });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		customs: this.textCustomFacade.customs$.pipe(
			startWith([]),
			tap(customs => {
				if (this.listVal === 'custom') {
					this.components = [];
					if (customs && customs.length >= 1) {
						customs.forEach(custom => {
							this.components.push({ custom: custom as TextCustom });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		links: this.textLinkFacade.links$.pipe(
			startWith([]),
			tap(links => {
				if (this.listVal === 'link') {
					this.components = [];
					if (links && links.length >= 1) {
						links.forEach(link => {
							this.components.push({ link: link as TextLink });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		lists: this.textListFacade.lists$.pipe(
			startWith([]),
			tap(lists => {
				if (this.listVal === 'list') {
					this.components = [];
					if (lists && lists.length >= 1) {
						lists.forEach(list => {
							this.components.push({ list: list as TextList });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		toggles: this.textToggleFacade.toggles$.pipe(
			startWith([]),
			tap(toggles => {
				if (this.listVal === 'toggles') {
					this.components = [];
					if (toggles && toggles.length >= 1) {
						toggles.forEach(toggle => {
							this.components.push({ toggles: toggle as TextToggle });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		images: this.mediaImageFacade.images$.pipe(
			startWith([]),
			tap(images => {
				if (this.listVal === 'image') {
					this.components = [];
					if (images && images.length >= 1) {
						images.forEach(image => {
							this.components.push({ image: image as MediaImage });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		videos: this.mediaVideoFacade.videos$.pipe(
			startWith([]),
			tap(videos => {
				if (this.listVal === 'video') {
					this.components = [];
					if (videos && videos.length >= 1) {
						videos.forEach(video => {
							this.components.push({ video: video as MediaVideo });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		items: this.pagesFacade.pageItems$.pipe(
			tap(items => {
				if (this.listVal === 'item') {
					this.components = [];
					if (items && items.length >= 1) {
						items.forEach(item => {
							this.components.push({ item: item as PageItem });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		news: this.newsFacade.data$.pipe(
			tap(data => {
				if (this.listVal === 'news') {
					this.components = [];
					if (data && data.length >= 1) {
						data.forEach(item => {
							this.components.push({ news: item as TextNewsItem });
						});
					}
					this.loaderActive = false;
				}
			})
		),
		notifications: this.mediaNotificationFacade.notifications$.pipe(
			startWith([]),
			tap(notifications => {
				if (this.listVal === 'notification') {
					this.components = [];
					if (notifications && notifications.length >= 1) {
						notifications.forEach(note => {
							this.components.push({ notification: note as MediaNotification });
						});
					}
					this.loaderActive = false;
				}
			})
		)
	});

	onSelect(item: ComponentList) {
		this.components = [];
		this.loaderActive = true;

		this.listVal = item.key;
		switch (item.key) {
			case 'textblock': {
				this.textblockFacade.get();
				break;
			}
			case 'button': {
				this.textButtonFacade.get();
				break;
			}
			case 'link': {
				this.textLinkFacade.get();
				break;
			}
			case 'list': {
				this.textListFacade.get();
				break;
			}
			case 'toggles': {
				this.textToggleFacade.get();
				break;
			}
			case 'image': {
				this.mediaImageFacade.get();
				break;
			}
			case 'video': {
				this.mediaVideoFacade.get();
				break;
			}
			case 'custom': {
				this.textCustomFacade.get();
				break;
			}
			case 'item': {
				this.pagesFacade.getPageItems();
				break;
			}
			case 'news': {
				this.newsFacade.get();
				break;
			}
			case 'notification': {
				this.mediaNotificationFacade.get();
				break;
			}
			default: {
				break;
			}
		}
	}

	onAdd(item: TextfieldDataInject) {
		switch (item.key) {
			case 'textblock': {
				const component: TextfieldComponents = { textblock: item.data as Textblock };
				return {
					item: { key: 'textblock', label: 'Textblock' } as ComponentList,
					component
				};
			}
			case 'button': {
				const component: TextfieldComponents = { button: item.data as TextButton };
				return {
					item: { key: 'button', label: 'Button' } as ComponentList,
					component
				};
			}
			case 'link': {
				const component: TextfieldComponents = { link: item.data as TextLink };
				return {
					item: { key: 'link', label: 'Link' } as ComponentList,
					component
				};
			}
			case 'list': {
				const component: TextfieldComponents = { list: item.data as TextList };
				return {
					item: { key: 'list', label: 'List' } as ComponentList,
					component
				};
			}
			case 'toggles': {
				const component: TextfieldComponents = { toggles: item.data as TextToggle };
				return {
					item: { key: 'toggles', label: 'Toggle' } as ComponentList,
					component
				};
			}
			case 'image': {
				const component: TextfieldComponents = { image: item.data as MediaImage };
				return {
					item: { key: 'image', label: 'Image' } as ComponentList,
					component
				};
			}
			case 'video': {
				const component: TextfieldComponents = { video: item.data as MediaVideo };
				return {
					item: { key: 'video', label: 'Video' } as ComponentList,
					component
				};
			}
			case 'custom': {
				const component: TextfieldComponents = { custom: item.data as TextCustom };
				return {
					item: { key: 'custom', label: 'Custom Textblock' } as ComponentList,
					component
				};
			}
			case 'item': {
				const component: PagesComponents = { item: item.data as PageItem };
				return {
					item: { key: 'item', label: 'Page Item' } as ComponentList,
					component: component
				};
			}
			case 'news': {
				const component: TextfieldComponents = { news: item.data as TextNewsItem };
				return {
					item: { key: 'news', label: 'News Item' } as ComponentList,
					component: component
				};
			}
			case 'notification': {
				const component: TextfieldComponents = {
					notification: item.data as MediaNotification
				};
				return {
					item: { key: 'notification', label: 'Notification' } as ComponentList,
					component
				};
			}
			default: {
				return undefined;
			}
		}
	}
}
