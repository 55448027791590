import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { TilesetCustom } from '@yuno/api/interface';

import { customTilesetActions } from './tilesets-custom.actions';
import { customTilesetFeature } from './tilesets-custom.state';

@Injectable()
export class TilesetsCustomFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(customTilesetFeature.selectLoaded));
	customTilesets$ = this.store.pipe(select(customTilesetFeature.selectCustomTilesets));
	selected$ = this.store.pipe(select(customTilesetFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(customTilesetFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(customTilesetFeature.selectStatus));

	get() {
		this.store.dispatch(customTilesetActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(customTilesetActions.select({ _id }));
	}

	updateSelect(data: Partial<TilesetCustom>) {
		this.store.dispatch(customTilesetActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(customTilesetActions.clearSelect());
	}

	save() {
		this.store.dispatch(customTilesetActions.save());
	}

	delete(_id: string) {
		this.store.dispatch(customTilesetActions.delete({ _id }));
	}

	upload(files: File[]) {
		this.store.dispatch(customTilesetActions.upload({ files }));
	}
}
