import { InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PagesComponentsTypes } from '@yuno/api/interface';

export const token = new InjectionToken<InjectClass>('');

export class InjectClass {
	editor: 'textfield' | 'pages';
	data: PagesComponentsTypes | FormGroup;
	index: number;
}
