import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Legend } from '@yuno/api/interface';

import { legendActions } from './legend.actions';

export interface LegendsState {
	loaded: boolean;
	legends: Partial<Legend>[];
	selected: Partial<Legend> | undefined;
	selectedLoaded: boolean;
	status: Status;
}

export const initialState: LegendsState = {
	loaded: false,
	legends: [],
	selected: undefined,
	selectedLoaded: false,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(legendActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(legendActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		legends: data,
		status: Status.SUCCESS
	})),
	on(legendActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(legendActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false,
		saved: undefined
	})),
	on(legendActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(legendActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(legendActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(legendActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(legendActions.saveSuccess, state => {
		const index = state.legends.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const legends = [...state.legends];
		if (index >= 0) {
			legends[index] = { ...legends[index], ...state.selected };
		} else if (state.selected) {
			legends.push({ ...state.selected });
		}

		return {
			...state,
			legends: legends
		};
	})
);

export const legendFeature = createFeature({
	name: 'legend',
	reducer
});
