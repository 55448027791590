import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { categoriesFeature } from '@yuno/admin/features/geophotos/data-access';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	DELETE_GEOPHOTO,
	GET_GEOPHOTO,
	GET_GEOPHOTOS,
	GeoPhotoDelete,
	GeoPhotoSave,
	GeoPhotoSelectQuery,
	GeoPhotosQuery,
	SAVE_GEOPHOTO
} from '../../../utils';
import { photosActions } from './photos.actions';
import { photosFeature } from './photos.state';

export const loadPhotos = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(photosActions.load, photosActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([action, appId]) =>
				graphql
					.query<GeoPhotosQuery>({
						query: GET_GEOPHOTOS,
						variables: {
							categoryId: action.category,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.geoPhotos) {
								throw new Error('No Photos found');
							}

							return photosActions.loadSuccess({
								data: data.data.geoPhotos
							});
						}),
						take(1),
						catchError(error => of(photosActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectStates = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(photosActions.select),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			exhaustMap(([action, appId]) =>
				graphql
					.query<GeoPhotoSelectQuery>({
						query: GET_GEOPHOTO,
						variables: {
							id: action._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getGeoPhoto) {
								throw new Error('No Photo found');
							}

							return photosActions.selectSuccess({
								data: data.data.getGeoPhoto
							});
						}),
						take(1),
						catchError(error => of(photosActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedPhoto = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(photosActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Photo!`, 'error');
					throw new Error('no Photo found');
				}
				return photosActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(photosActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const savePhoto = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(photosActions.save),
			withLatestFrom(
				store.pipe(select(photosFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<GeoPhotoSave>({
						mutation: SAVE_GEOPHOTO,
						variables: {
							data: { ...selected, updatedAt: undefined },
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveGeoPhoto) {
								throw new Error('error saving');
							}
							store.dispatch(
								photosActions.selectSuccess({
									data: data.data.saveGeoPhoto
								})
							);

							return photosActions.saveSuccess();
						}),
						take(1),
						catchError(error => {
							return of(photosActions.saveFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

// export const duplicatePhoto = createEffect(
// 	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
// 		actions$.pipe(
// 			ofType(photosActions.duplicate),
// 			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
// 			switchMap(([data, appId]) =>
// 				graphql
// 					.mutate<GeoPhotoDuplicate>({
// 						mutation: DUPLICATE_GEOPHOTO,
// 						variables: {
// 							_id: data._id,
// 							appId: appId
// 						}
// 					})
// 					.pipe(
// 						map(data => {
// 							if (!data.data?.duplicateGeoPhotoById) {
// 								throw new Error('error duplicating');
// 							}
// 							store.dispatch(photosActions.reload());
// 							return photosActions.duplicateSuccess({
// 								data: data.data.duplicateGeoPhotoById
// 							});
// 						}),
// 						take(1),
// 						catchError(error => {
// 							return of(photosActions.duplicateFailure({ error }));
// 						})
// 					)
// 			)
// 		),
// 	{ functional: true }
// );

export const deletePhoto = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(photosActions.delete),
			withLatestFrom(
				store.pipe(select(categoriesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([action, category, appId]) =>
				graphql
					.mutate<GeoPhotoDelete>({
						mutation: DELETE_GEOPHOTO,
						variables: {
							_id: action._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteGeoPhotoById) {
								throw new Error('error deleting Photo from the database');
							}
							if (category?._id) {
								store.dispatch(photosActions.reload({ category: category._id }));
							}
							return photosActions.deleteSuccess({
								_id: data.data?.deleteGeoPhotoById
							});
						}),
						take(1),
						catchError(error => {
							return of(photosActions.deleteFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const savePhotoSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(photosActions.saveSuccess),
			tap(() => {
				message.sendToast(`Photo successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const savePhotoFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(photosActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Photo!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicatePhotoSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(photosActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Photo successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicatePhotoFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(photosActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Photo!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deletePhotoSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(photosActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Photo successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deletePhotoFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(photosActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Photo!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
