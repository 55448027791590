import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { LanguagesArray, LanguagesDisplayArray } from '@yuno/api/interface';

import { UsersFacade } from '../../data-access';

interface AddUserForm {
	email: FormControl<string>;
	displayName: FormControl<string>;
	language: FormControl<string>;
}

@Component({
	selector: 'yuno-admin-add-user',
	templateUrl: './add-user.component.html',
	styleUrls: ['./add-user.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddUserComponent implements OnInit {
	private readonly userFacade = inject(UsersFacade);

	form: FormGroup<AddUserForm>;
	language = LanguagesArray;
	languageDisplay = LanguagesDisplayArray;

	ngOnInit(): void {
		this.form = new FormGroup<AddUserForm>({
			email: new FormControl('', { nonNullable: true, validators: Validators.required }),
			displayName: new FormControl('', {
				nonNullable: true,
				validators: Validators.required
			}),
			language: new FormControl('nl', { nonNullable: true })
		});
	}

	addUser() {
		this.userFacade.addUser(this.form.getRawValue());
	}
}
