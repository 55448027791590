import { gql } from 'apollo-angular';

import { GfsImageModel, MediaImage } from '@yuno/api/interface';

export const GET_IMAGES = gql`
	query getImagesByAppId($appId: String!) {
		images(appId: $appId) {
			_id
			file {
				_id
				data {
					url
					filename
					fileName
				}
			}
			createdAt
			updatedAt
		}
	}
`;

export const GET_IMAGE_BY_ID = gql`
	query getImageById($_id: String!) {
		selectedImage(_id: $_id) {
			_id
			id
			file {
				_id
				data {
					url
					path
					filename
					fileName
				}
				type
			}
			alt
			url
			zoomable
			createdBy
			updatedBy
			createdAt
			updatedAt
		}
	}
`;

export const GET_IMAGEFILES = gql`
	query getImageFilesByAppId($appId: String!) {
		imageFiles(appId: $appId) {
			_id
			data {
				url
				fileName
				sizes
				maxSize
				ext
				originalSize
			}
			updatedAt
		}
	}
`;

export type ImagesQuery = {
	images: Partial<MediaImage>[];
};
export type ImageQuery = {
	selectedImage: Partial<MediaImage>;
};
export type ImageFilesQuery = {
	imageFiles: Partial<GfsImageModel>[];
};
