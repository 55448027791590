import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export interface ClientForm {
	_id: FormControl<string>;
	id: FormControl<string>;
	path: FormControl<string>;
	url: FormArray<FormControl<string>>;
}

// interface ColorForm {
// 	additional: FormControl<string>;
// 	button: FormControl<string>;
// 	buttonFont: FormControl<string>;
// 	embedNavbar: FormGroup<{
// 		active: FormControl<string>;
// 		activeFont: FormControl<string>;
// 		background: FormControl<string>;
// 		font: FormControl<string>;
// 	}>;
// 	locationMarker: FormControl<string>;
// 	measure: FormControl<string>;
// 	navbar: FormGroup<{
// 		active: FormControl<string>;
// 		activeFont: FormControl<string>;
// 		background: FormControl<string>;
// 		font: FormControl<string>;
// 	}>;
// 	panoramas: FormControl<string>;
// 	panoramasClusterCount: FormControl<string>;
// 	states: FormGroup<{
// 		background: FormControl<string>;
// 		font: FormControl<string>;
// 	}>;
// 	textHeading: FormControl<string>;
// 	textLink: FormControl<string>;
// }

@Injectable({
	providedIn: 'root'
})
export class ClientsEditorService {
	form: FormGroup<ClientForm>;

	get urls() {
		return this.form.get('url') as FormArray;
	}

	createFormGroup(): void {
		this.form = new FormGroup<ClientForm>({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{
					nonNullable: true
				}
			),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			path: new FormControl('', { nonNullable: true }),
			url: new FormArray<FormControl<string>>([])
		});
	}

	// URL
	removeUrl(i: number): void {
		this.urls.removeAt(i);
	}

	addUrl(): void {
		this.urls.push(new FormControl('', { nonNullable: true }));
	}

	addUrls(urls?: string[]): void {
		if (!urls) {
			return;
		}
		const count = urls.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addUrl();
			}
		}
	}
}
