import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { GeoPhoto } from '@yuno/api/interface';

export const photosActions = createActionGroup({
	source: 'GeoPhotos Photos',
	events: {
		load: props<{ category: string }>(),
		loadSuccess: props<{ data: Partial<GeoPhoto>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: props<{ category: string }>(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: GeoPhoto }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<GeoPhoto> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<GeoPhoto> }>(),
		updateSelectSuccess: props<{ data: Partial<GeoPhoto> }>(),
		updateSelectFailure: props<{ error: Error }>(),
		reset: emptyProps()
	}
});
