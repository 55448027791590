<h1>{{ editor | UpperCaseFirst }} items</h1>
<p>What would you like to add?</p>

@if (data$ | async; as data) {
	<div class="flex flex-col gap-8">
		<div>
			<h4>Content blocks:</h4>
			<section class="grid grid-cols-2 gap-2">
				@for (item of list; track item) {
					<yuno-select-field
						[display]="item.label"
						[disabled]="item?.disabled || false"
						(clicked)="onAdd(item)"></yuno-select-field>
				}
			</section>
		</div>
		<div>
			<h4>Reusable Content blocks:</h4>
			<section class="grid grid-cols-2 gap-2">
				@for (item of getList(); track item) {
					<yuno-select-field
						[display]="item.label"
						[disabled]="item?.disabled || false"
						(clicked)="onAdd(item)"></yuno-select-field>
				}
			</section>
		</div>
	</div>
}
