import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { ParticipationNotifications } from '@yuno/api/interface';

export const notificationsActions = createActionGroup({
	source: 'Participation Notifications',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<ParticipationNotifications>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: ParticipationNotifications }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<ParticipationNotifications> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<ParticipationNotifications> }>(),
		updateSelectSuccess: props<{ data: Partial<ParticipationNotifications> }>(),
		updateSelectFailure: props<{ error: Error }>(),
		reset: emptyProps()
	}
});
