import { gql } from 'apollo-angular';

import { MediaImageButton } from '@yuno/api/interface';

export const GET_IMAGE_BUTTONS = gql`
	query getImageButtonsByAppId($appId: String!) {
		mediaImageButton(appId: $appId) {
			_id
			id
			file {
				_id
				data {
					url
				}
			}
			size
			wide
		}
	}
`;

export const GET_IMAGE_BUTTON_BY_ID = gql`
	query getImageButtonById($_id: String!) {
		mediaImageButtonId(_id: $_id) {
			_id
			id
			size
			wide
			title
			src
			file {
				_id
				data {
					url
					fileName
				}
			}
		}
	}
`;

export type ImageButtonsQuery = {
	mediaImageButton: Partial<MediaImageButton>[];
};
export type ImageButtonQuery = {
	mediaImageButtonId: Partial<MediaImageButton>;
};
