function rad(x) {
  return x * Math.PI / 180;
}
function isRingClockwise(coords) {
  var area = 0;
  if (coords.length > 2) {
    var p1, p2;
    for (var i = 0; i < coords.length - 1; i++) {
      p1 = coords[i];
      p2 = coords[i + 1];
      area += rad(p2[0] - p1[0]) * (2 + Math.sin(rad(p1[1])) + Math.sin(rad(p2[1])));
    }
  }
  return area >= 0;
}
function isPolyRHR(coords) {
  if (coords && coords.length > 0) {
    if (isRingClockwise(coords[0])) return false;
    var interiorCoords = coords.slice(1, coords.length);
    if (!interiorCoords.every(isRingClockwise)) return false;
  }
  return true;
}
function rightHandRule(geometry) {
  if (geometry.type === 'Polygon') {
    return isPolyRHR(geometry.coordinates);
  } else if (geometry.type === 'MultiPolygon') {
    return geometry.coordinates.every(isPolyRHR);
  }
}
module.exports = function validateRightHandRule(geometry, errors) {
  if (!rightHandRule(geometry)) {
    errors.push({
      message: 'Polygons and MultiPolygons should follow the right-hand rule',
      level: 'message',
      line: geometry.__line__
    });
  }
};