import { gql } from 'apollo-angular';

import { SafeUser } from '@yuno/api/interface';

// export const SAVE_USER = gql`
// 	mutation saveUser($user: UserInput!) {
// 		saveUser(user: $user) {
// 			_id
// 			email
// 			role
// 		}
// 	}
// `;

export const INVITE_USER = gql`
	mutation inviteNewUser($user: InputUser!) {
		inviteNewUser(user: $user) {
			displayName
			email
			language
		}
	}
`;

export type UserInvite = {
	inviteNewUser: SafeUser;
};
