import { createFeature, createReducer, on } from '@ngrx/store';
import { Map, StyleSpecification } from 'maplibre-gl';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { MapStyle, MapViewOptions } from '@yuno/api/interface';

import { mapActions } from './map.actions';

export interface MapState {
	loaded: boolean;
	map: Partial<Map> | undefined;
	mapStyles: MapStyle[];
	style: StyleSpecification | undefined;
	defaultStyle: {
		style: StyleSpecification | undefined;
		view: MapViewOptions | undefined;
	};
	status: Status;
}

export const initialState: MapState = {
	loaded: false,
	map: undefined,
	mapStyles: [],
	style: undefined,
	defaultStyle: {
		style: undefined,
		view: undefined
	},
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(mapActions.loadMapStyles, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(mapActions.loadMapStylesSuccess, (state, { data }) => ({
		...state,
		mapStyles: data,
		status: Status.SUCCESS,
		loaded: true
	})),
	on(mapActions.loadMapStylesFailure, state => ({
		...state,
		status: Status.FAILED
	})),

	on(mapActions.loadStyleSuccess, (state, { data }) => ({
		...state,
		style: data
	})),
	on(mapActions.clearStyle, state => ({
		...state,
		style: undefined
	})),

	on(mapActions.loadDefaultSuccess, (state, { style, view }) => ({
		...state,
		defaultStyle: {
			style,
			view
		}
	})),
	on(mapActions.clearDefault, state => ({
		...state,
		defaultStyle: {
			style: undefined,
			view: undefined
		}
	}))
);

export const mapFeature = createFeature({
	name: 'map',
	reducer
});
