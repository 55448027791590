import { gql } from 'apollo-angular';

import { MediaImageButton } from '@yuno/api/interface';

export const SAVE_IMAGE_BUTTON = gql`
	mutation saveImageButton($imageButton: MediaImageButtonInput!, $appId: String!) {
		mediaImageButtonSave(imageButton: $imageButton, appId: $appId) {
			_id
			id
		}
	}
`;

export type SaveImageButtonMutation = {
	mediaImageButtonSave: Partial<MediaImageButton>;
};
