import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { MainUiService } from '../services';

export const uiContainerResolver: ResolveFn<void> = (route): void => {
	const service = inject(MainUiService);

	let type = route.data['containerType'];

	if (!type && (type !== 'map' || type !== 'data')) {
		type = 'data';
	}

	service.setContainerType(type);
};
