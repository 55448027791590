import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { EventData } from '@maplibre/ngx-maplibre-gl';
import { Point, Position } from 'geojson';
import {
	FitBoundsOptions,
	LngLat,
	LngLatBoundsLike,
	LngLatLike,
	Map,
	MapLibreEvent,
	PaddingOptions
} from 'maplibre-gl';
import {
	AsyncSubject,
	BehaviorSubject,
	Subject,
	combineLatest,
	debounceTime,
	lastValueFrom,
	map,
	startWith,
	tap,
	withLatestFrom
} from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { ThemeFacade } from '@yuno/admin/features/themes';
import { MapMarker, Marker } from '@yuno/api/interface';
import { waitFor } from '@yuno/shared/helpers';

import { MarkersFacade } from '../../../../../index';

export type MarkersFilter = 'all' | 'public' | 'non-public';
const DefaultMapStyle = '5e42a2df5e53be02b5f37b00';

@Injectable({
	providedIn: 'root'
})
export class MarkersMapViewerService {
	private readonly markersFacade = inject(MarkersFacade);
	private readonly themeFacade = inject(ThemeFacade);

	private readonly appFacade = inject(AppFacade);

	private readonly router = inject(Router);

	private _mapCreated = new AsyncSubject<void>();
	mapCreated$ = this._mapCreated.asObservable();
	private _map = new Subject<Map>();
	map$ = this._map.asObservable();
	map: Map;

	basedOnRoute = false;

	zoom: number;
	center: [number, number];
	pitch: number;
	bearing: number;

	private bounds?: LngLatBoundsLike;
	private _bounds = new BehaviorSubject<LngLatBoundsLike | undefined>(undefined);
	bounds$ = this._bounds.asObservable();

	get hasBounds(): boolean {
		return !!this.bounds;
	}

	private _boundsOptions = new BehaviorSubject<FitBoundsOptions>({
		padding: 60,
		animate: false
	});
	boundsOptions$ = this._boundsOptions.asObservable();

	private _draggable = new BehaviorSubject<boolean>(false);
	draggable$ = this._draggable.asObservable();

	private _filter = new BehaviorSubject<MarkersFilter>('all');
	filter$ = this._filter.asObservable();
	$filter = toSignal(this.filter$);

	_dragEnd = new Subject<LngLat>();
	dragEnd$ = this._dragEnd.asObservable();

	private _polygon = new BehaviorSubject<Position[] | null>(null);
	polygon$ = this._polygon.asObservable();

	language$ = this.appFacade.language$.pipe(
		startWith('nl'),
		map(val => val || 'nl')
	);

	data$ = combineLatest({
		marker: this.markersFacade.selectedMarkers$.pipe(
			startWith(null),
			debounceTime(500),
			map(data => {
				if (data) {
					const mapMarker = {
						id: data._id,
						events: JSON.stringify(data.events),
						style: JSON.stringify(data.style),
						properties: JSON.stringify(data.properties)
					};
					return {
						geometry: data.geometry as Point,
						data: mapMarker as MapMarker
					};
				}
				return null;
			})
		),
		markers: combineLatest({
			data: this.markersFacade.markers$.pipe(startWith(null)),
			filter: this.filter$
		}).pipe(
			map(({ data, filter }) => {
				if (data && data.length >= 1) {
					let markerData = data as Marker[];

					if (filter === 'non-public') {
						markerData = markerData.filter(marker => !marker.public);
					}

					if (filter === 'public') {
						markerData = markerData.filter(marker => marker.public);
					}
					return markerData.map(marker => {
						const mapMarker = {
							id: marker._id,
							events: JSON.stringify(marker.events),
							style: JSON.stringify({ type: '', ...marker.style }),
							properties: JSON.stringify(marker.properties)
						};
						return {
							geometry: marker.geometry as Point,
							data: mapMarker as MapMarker
						};
					});
				}

				return [];
			})
		),
		theme: this.themeFacade.themes$.pipe(
			withLatestFrom(this.appFacade.appId$),
			tap(([data, appId]) => {
				if (!appId) {
					return;
				}

				if (!data || data.length < 1) {
					return;
				}

				const id = data[0]._id;
				this.getMapStyle(appId, id);
			})
		)
	});

	animateBounds(bool = true): void {
		this._boundsOptions.next({ padding: 60, animate: bool });
	}

	getMapStyle(appId: string, id?: string): void {
		if (id) {
			this.markersFacade.loadMapStyle(`theme/${appId}/${id}/style.json?intern=true`);
			return;
		}

		const styleUrl = `mapstyle/${DefaultMapStyle}/app/${appId}/style.json?intern=true`;
		this.markersFacade.loadMapStyle(styleUrl);
	}

	/* Update the router with coordinates */
	setMapUrl(event: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & EventData) {
		const center = event.target.getCenter();
		const zoom = event.target.getZoom();
		const pitch = event.target.getPitch();
		const bearing = event.target.getBearing();

		this.router.navigate([], {
			queryParams: {
				map: `${center.lat},${center.lng},${zoom},${bearing},${pitch}`
			},
			queryParamsHandling: 'merge'
		});
	}

	setMarkerFilter(filter: MarkersFilter): void {
		this._filter.next(filter);
	}

	async updateBounds(): Promise<void> {
		this._bounds.next(undefined);

		await waitFor(0);
		this._bounds.next(this.bounds);
	}

	setBounds(bounds: LngLatBoundsLike): void {
		this.bounds = bounds;
		this._bounds.next(bounds);
	}

	setMap(map: Map): void {
		this._mapCreated.next(undefined);
		this._mapCreated.complete();

		this._map.next(map);
		this.map = map;
	}

	setDraggable(bool: boolean): void {
		this._draggable.next(bool);
	}

	setDragend(lngLat: LngLat): void {
		this._dragEnd.next(lngLat);
	}

	async zoomTo(center?: LngLatLike): Promise<void> {
		if (!center) {
			return;
		}
		await lastValueFrom(this.mapCreated$);
		this.map.flyTo({ center, zoom: this.map.getZoom() });
	}

	async getCenter(): Promise<[number, number]> {
		await lastValueFrom(this.mapCreated$);
		const center = this.map?.getCenter();
		return [center.lng, center.lat];
	}

	async setPadding(padding: PaddingOptions): Promise<void> {
		await lastValueFrom(this.mapCreated$);
		this.map.setPadding(padding);
	}

	async zoomToFence(bounds?: LngLatBoundsLike): Promise<void> {
		if (!bounds) {
			return;
		}
		await lastValueFrom(this.mapCreated$);
		this.map.fitBounds(bounds, { padding: 40 });
	}
}
