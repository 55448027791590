import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	inject
} from '@angular/core';
import { tap } from 'rxjs';

import { LanguagePipe } from '@yuno/angular/pipes';
import { LanguageStringsModel, MediaVideo } from '@yuno/api/interface';

import { EventsService } from '../../services/events.service';
import { TextfieldToken } from '../../textfield.injection.token';
import { TextNoCookieComponent } from '../text-no-cookie/text-no-cookie.component';
import { VimeoService } from './services/vimeo.service';
import { YoutubeService } from './services/youtube.service';

@Component({
	selector: 'yuno-textfield-video',
	// providers: [provideTranslocoScope('textfields')],
	template: `
		@if ({ show: showCookieMessage | async }; as data) {
			<ng-template [ngTemplateOutlet]="data.show ? video : body"></ng-template>
		}

		<ng-template #video>
			<!-- not using the aspect-video because older ios systems are not capable  -->
			<div #container class="yuno-text-video aspect-h-9  aspect-w-16">
				<div [id]="elementId"></div>
			</div>

			<!-- Description -->
			@if (description) {
				<span class="text-sm">
					<ng-content></ng-content>
					{{ description | languageSelect: language }}
				</span>
			}
		</ng-template>

		<ng-template #noCookie>
			<yuno-text-no-cookie></yuno-text-no-cookie>
		</ng-template>
	`,
	styleUrls: ['./text-video.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgTemplateOutlet, TextNoCookieComponent, AsyncPipe, NgClass, LanguagePipe]
})
export class TextVideoComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	private cdr = inject(ChangeDetectorRef);
	private service = inject(EventsService);
	private vimeo = inject(VimeoService);
	private youtube = inject(YoutubeService);

	@HostBinding('class') classes = 'mt-2 md:mt-4 first:mt-0';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ViewChild('video', { static: false, read: TemplateRef }) videoTemplate: TemplateRef<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@ViewChild('noCookie', { static: false, read: TemplateRef }) noCookieTemplate: TemplateRef<any>;
	@ViewChild('container', { static: false, read: ElementRef }) container: ElementRef;

	private _init = false;
	private _videourl: string;
	private _type?: MediaVideo['type'];
	private _description?: LanguageStringsModel;

	elementId = this.generateUniqueId();

	@Input() language?: string;
	@Input() disableInjection = false;

	@Input() set videourl(url: string) {
		this._videourl = url;
	}

	get videourl(): string {
		return this._videourl;
	}

	@Input() set type(type: MediaVideo['type'] | undefined) {
		this._type = type;
	}

	get type(): MediaVideo['type'] | undefined {
		return this._type;
	}

	@Input() set description(desc: LanguageStringsModel | undefined) {
		this._description = desc;
	}

	get description(): LanguageStringsModel | undefined {
		return this._description;
	}

	body: TemplateRef<ElementRef>;
	showCookieMessage = this.service.acceptThirdParty$.pipe(
		tap(() => {
			this.ngOnChanges();
		})
	);

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as MediaVideo,
				this.injectValue.language
			);
	}

	ngOnChanges(): void {
		if (!this._init) {
			return;
		}

		this.removeVideos();
		this.addPlayer();
	}

	ngAfterViewInit(): void {
		this._init = true;
		this.addPlayer();
	}

	/**
	 * Setup the Video Component by using the Injection Method
	 *
	 * @param {MediaVideo} data
	 */
	handleInjectionData(data: MediaVideo, language?: string) {
		this.language = language;
		this._videourl = data.videoId;
		this._type = data.type;
		this.description = data.description;
	}

	/**
	 * Add a video player, Youtube or Vimeo to this ElementRef
	 *
	 * @return {*}  {void}
	 * @memberof TextVideoComponent
	 */
	async addPlayer(): Promise<void> {
		if (!this.videourl) {
			return;
		}

		if (this._type === 'vimeo' || this.videourl.includes('vimeo.com')) {
			this.body = this.videoTemplate;
			this.cdr.detectChanges();

			this.vimeo.setupPlayer(this.container.nativeElement, this.videourl, this.elementId);
			this.cdr.detectChanges();
			return;
		}

		// setup no cookie template as fallback
		this.body = this.noCookieTemplate;

		// When the videourl is not a valid weburl,
		// treat it as a Youtube ID
		if (!this.isValidUrl(this.videourl) && this._type === 'youtube') {
			this.youtube.setupPlayer(
				{
					elementId: this.elementId,
					url: this.videourl
				},
				true
			);

			this.cdr.detectChanges();
			return;
		}

		// When it's not Vimeo nor Youtube
		// youtu is in both youtube.com and youtu.be
		if (!this.videourl.includes('youtu')) {
			console.error(`A player for, '${this.videourl}' has not been implemented!`);
			return;
		}

		// When it does include youtu, load with a default video url
		// using a delay, to accommodate for the Cookie Message
		setTimeout(() => {
			this.youtube.setupPlayer({
				elementId: this.elementId,
				url: this.videourl
			});

			this.cdr.detectChanges();
		});

		this.cdr.markForCheck();
	}

	/**
	 * Validates if a string is a valud URL
	 *
	 * @param {string} urlString
	 * @return {*}  {boolean}
	 */
	isValidUrl(urlString: string): boolean {
		try {
			return Boolean(new URL(urlString));
		} catch (e) {
			return false;
		}
	}

	/**
	 * Generates a unique id
	 */
	generateUniqueId(): string {
		const len = 7;
		return (
			'ypa-video-' +
			Math.random()
				.toString(35)
				.substring(2, 2 + len)
		);
	}

	/**
	 * Removes all videos with this elementId
	 */
	removeVideos(): void {
		this.vimeo.destroyPlayer(this.elementId);
		this.youtube.destroyPlayer(this.elementId);
	}

	ngOnDestroy(): void {
		this.removeVideos();
	}
}
