import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { textCustomsActions } from './text-custom.actions';
import { textCustomsFeature } from './text-custom.state';

@Injectable()
export class TextCustomFacade {
	private readonly store = inject(Store);

	customs$ = this.store.pipe(select(textCustomsFeature.selectCustoms));
	status$ = this.store.pipe(select(textCustomsFeature.selectStatus));

	get() {
		this.store.dispatch(textCustomsActions.load());
	}
}
