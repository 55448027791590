import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	GET_TEXTLINKS,
	TextLinksQuery
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/getTextfieldComponents';
import { GraphQLService } from '@yuno/angular-graphql';

import { textLinksActions } from './text-link.actions';

export const loadTextLinks = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textLinksActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<TextLinksQuery>({
						query: GET_TEXTLINKS,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.links) {
								throw new Error('no text-links found');
							}
							return textLinksActions.loadSuccess({
								data: data.data.links
							});
						}),
						take(1),
						catchError(error => of(textLinksActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);
