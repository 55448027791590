import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { EventForm, EventFormsService, EventsForm } from '@yuno/admin/features/events';
import {
	LanguageFormType,
	defaultNewLanguageFormGroup,
	newLanguageFormGroup
} from '@yuno/angular/forms';
import {
	Marker,
	MarkerAlignmentKeys,
	MarkerClassKeys,
	MarkerEventDisplayKeys,
	MarkerEventKeys
} from '@yuno/api/interface';

export interface MarkerForm {
	_id: FormControl<string>;
	public: FormControl<boolean>;
	category: FormControl<string | null>;

	style: FormGroup<StyleForm>;
	geometry: FormGroup<{
		type: FormControl<'Point'>;
		coordinates: FormArray<FormControl<number>>;
	}>;
	properties: FormGroup<PartialFixedPropertiesForm>;
	events: FormGroup<EventsForm>;
}

interface StyleForm {
	visibility: FormControl<'none' | 'visible'>;
	zIndex?: FormControl<number>;

	class?: FormControl<string>;
	eventStyle?: FormControl<string>;
	alignment?: FormControl<string>;
	color?: FormControl<string>;
	backgroundColor?: FormControl<string>;
}

interface PartialFixedPropertiesForm {
	id?: FormControl<string>;
	display?: FormGroup<LanguageFormType>;
	maxZoom?: FormControl<number>;
	minZoom?: FormControl<number>;
}

@Injectable({
	providedIn: 'root'
})
export class MarkerEditorService {
	private readonly eventsForm = inject(EventFormsService);

	changesMade = 0;
	disabled = true;
	disableClose = false;
	form: FormGroup<MarkerForm>;

	icon = MarkerClassKeys;
	eventTypes = MarkerEventKeys;
	eventTypesDisplay = MarkerEventDisplayKeys;
	alignment = MarkerAlignmentKeys;

	get style(): FormGroup<StyleForm> {
		return this.form.get('style') as FormGroup;
	}

	get coordinates(): FormArray<FormControl<number>> {
		return this.form.get('geometry.coordinates') as FormArray;
	}

	get markerEvents(): FormGroup<EventsForm> {
		return this.form?.get('events') as FormGroup;
	}

	get onClickEvents() {
		return this.markerEvents.get('onClick') as FormArray<FormGroup<EventForm>>;
	}

	get onMouseMoveEvents() {
		return this.markerEvents.get('onMouseMove') as FormArray<FormGroup<EventForm>>;
	}

	createFormGroup(): void {
		this.form = new FormGroup<MarkerForm>({
			_id: new FormControl<string>(
				{ value: '', disabled: true },
				{
					nonNullable: true
				}
			),
			public: new FormControl<boolean>(true, { nonNullable: true }),
			category: new FormControl<string>({ value: '', disabled: true }),
			style: new FormGroup<StyleForm>({
				visibility: new FormControl('visible', { nonNullable: true }),
				zIndex: new FormControl(100, { nonNullable: true }),
				class: new FormControl('label pointer', { nonNullable: true }),
				alignment: new FormControl('bottom', { nonNullable: true }),
				eventStyle: new FormControl('chevron-event', { nonNullable: true }),
				color: new FormControl('#fff', { nonNullable: true }),
				backgroundColor: new FormControl('#31aae1', { nonNullable: true })
			}),
			geometry: new FormGroup({
				type: new FormControl('Point', { nonNullable: true }),
				coordinates: new FormArray<FormControl<number>>([
					new FormControl(4.4609758, { nonNullable: true }),
					new FormControl(51.9122931, { nonNullable: true })
				])
			}),
			properties: new FormGroup<PartialFixedPropertiesForm>({
				id: new FormControl('', { nonNullable: true, validators: Validators.required }),
				display: newLanguageFormGroup(),
				minZoom: new FormControl(1, { nonNullable: true }),
				maxZoom: new FormControl(22, { nonNullable: true })
			}),
			events: this.eventsForm.createEventsForm()
		});
	}

	/**
	 * Resets the form to the default values.
	 * Needed when switching between markers to make sure the data
	 * is reset correctly and no other data is left over
	 */
	resetDefaultValues(): void {
		this.form.patchValue({
			_id: '',
			public: true,
			category: '',
			style: {
				visibility: 'visible',
				zIndex: 100,
				class: 'label pointer',
				alignment: 'bottom',
				color: '#fff',
				backgroundColor: '#31aae1'
			},
			geometry: {
				type: 'Point',
				coordinates: [4.4609758, 51.9122931]
			},
			properties: {
				id: '',
				display: defaultNewLanguageFormGroup(),
				minZoom: 1,
				maxZoom: 22
			}
		});

		// reset events
		this.onClickEvents.clear();
		this.onMouseMoveEvents.clear();
	}

	addEvents(events?: Marker['events']) {
		this.onClickEvents.clear();
		this.onMouseMoveEvents.clear();

		if (!events) {
			return;
		}

		if (events?.onClick && events.onClick.length >= 1) {
			const count = events.onClick.length;
			if (count > 0) {
				for (let i = 0; i < count; i++) {
					this.onClickEvents.push(
						this.eventsForm.createEvent(
							events.onClick[i].type,
							events.onClick[i].options
						)
					);
				}
			}
		}

		if (events?.onMouseMove && events.onMouseMove.length >= 1) {
			const count = events.onMouseMove.length;
			if (count > 0) {
				for (let i = 0; i < count; i++) {
					this.onMouseMoveEvents.push(
						this.eventsForm.createEvent(
							events.onMouseMove[i].type,
							events.onMouseMove[i].options
						)
					);
				}
			}
		}
	}
}
