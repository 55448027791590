import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { notificationActions } from './notification.actions';
import { notificationFeature } from './notification.state';

@Injectable()
export class NotificationFacade {
	private readonly store = inject(Store);

	notifications$ = this.store.pipe(select(notificationFeature.selectNotifications));
	status$ = this.store.pipe(select(notificationFeature.selectStatus));

	get() {
		this.store.dispatch(notificationActions.load());
	}
}
