@if (service.textfields$ | async) {
	@if (form) {
		<form [formGroup]="form">
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				<yuno-forms-select
					formControlName="id"
					label="Textfield"
					[display]="service.textfieldSelectDisplay.slice(1)"
					[selectValues]="service.textfieldSelectValues.slice(1)"
					placeholder="Select a Textfield..."></yuno-forms-select>
				<yuno-forms-text
					formControlName="container"
					placeholder="container name..."
					label="container"></yuno-forms-text>
				<yuno-admin-annotation type="info">
					<yuno-forms-span>
						To embed a textfield, you also need to create a
						<strong>container component</strong> with the same name in the currently
						active <strong>textfield</strong>.
					</yuno-forms-span>
				</yuno-admin-annotation>
				<yuno-forms-toggle formControlName="clear" label="Clear"></yuno-forms-toggle>
			</div>
		</form>
	}
}
