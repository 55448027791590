import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { textblocksActions } from './textblock.actions';
import { textblocksFeature } from './textblock.state';

@Injectable()
export class TextblockFacade {
	private readonly store = inject(Store);

	allTextblocks$ = this.store.pipe(select(textblocksFeature.selectTextblocks));
	selectedTextblock$ = this.store.pipe(select(textblocksFeature.selectSelected));
	loaded$ = this.store.pipe(select(textblocksFeature.selectLoaded));
	selectedLoaded$ = this.store.pipe(select(textblocksFeature.selectSelectedLoaded));

	get() {
		this.store.dispatch(textblocksActions.load());
	}

	select(id: string | undefined) {
		if (!id) {
			return;
		}
		this.store.dispatch(textblocksActions.select({ _id: id }));
	}

	clearSelect() {
		this.store.dispatch(textblocksActions.clearSelect());
	}

	save() {
		this.store.dispatch(textblocksActions.save());
	}
}
