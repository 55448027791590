@if (service$ | async; as service) {
	@if (service.component) {
		<form [formGroup]="service.component" class="flex flex-col gap-4">
			<section>
				<yuno-forms-title>Image Editor</yuno-forms-title>
				<yuno-forms-span>You can edit the selected image component below.</yuno-forms-span>
			</section>
			<yuno-forms-image
				[formValue]="'object'"
				formControlName="file"
				label="File"
				placeholder="select an image from the list below...">
			</yuno-forms-image>

			@if (data$ | async; as data) {
				<section formGroupName="description">
					@for (lang of editService.languages; track lang) {
						@if (lang === data.language) {
							<yuno-forms-textarea
								[rows]="2"
								[language]="lang"
								class="flex-1"
								[formControlName]="lang"
								placeholder="image description"
								label="Caption"></yuno-forms-textarea>
						}
					}
				</section>
				<section formGroupName="alt">
					@for (lang of editService.languages; track lang) {
						@if (lang === data.language) {
							<yuno-forms-textarea
								[rows]="2"
								[language]="lang"
								class="flex-1"
								[formControlName]="lang"
								label="Alt tag (for the visually impaired)"></yuno-forms-textarea>
						}
					}
				</section>
			}
			<yuno-forms-toggle
				formControlName="zoomable"
				label="Allow users to open the image fullscreen"></yuno-forms-toggle>
			<yuno-forms-divider></yuno-forms-divider>
		</form>
	}
}
