@if (data$ | async; as data) {
	@if (data.style.mapStyle) {
		<mgl-map
			class="absolute left-0 top-0 z-0 h-full w-full"
			(mapLoad)="mapLoad($event)"
			(styleImageMissing)="mapOnMissingImage($event)"
			[style]="data.style.mapStyle"
			[zoom]="[service.zoom]"
			[center]="service.center"
			[pitch]="[service.pitch]"
			[bearing]="[service.bearing]"
			[fitBounds]="data.bounds"
			[fitBoundsOptions]="data.boundsOptions">
			<ng-content></ng-content>
			@if (service.map$ | async; as map) {
				<!-- Layer -->
				@if (
					data.style.layer &&
					data.style.layer.source &&
					data.style.layer.type &&
					data.style.layer.type !== 'background' &&
					data.style.layer.type !== 'symbol'
				) {
					@if (data.style.layer.sourceLayer) {
						<mgl-layer
							[id]="data.style.layer.id || 'new-layer'"
							[type]="getLayerType(data.style.layer.type)"
							[source]="data.style.layer.source || undefined"
							[sourceLayer]="data.style.layer.sourceLayer || undefined"
							[layout]="data.style.layer.layout || {}"
							[paint]="data.style.layer.paint || {}"></mgl-layer>
					}
					@if (!data.style.layer.sourceLayer) {
						<mgl-layer
							[id]="data.style.layer.id || 'new-layer'"
							[type]="getLayerType(data.style.layer.type)"
							[source]="data.style.layer.source || undefined"
							[layout]="data.style.layer.layout || {}"
							[paint]="data.style.layer.paint || {}"></mgl-layer>
					}
				}
				<!-- Layer - type: Background  -->
				@if (data.style.layer && data.style.layer.type === 'background') {
					<mgl-layer
						[id]="data.style.layer.id || 'new-layer'"
						type="background"
						[layout]="data.style.layer.layout || {}"
						[paint]="data.style.layer.paint || {}"></mgl-layer>
				}
				<!-- Layer - type: Symbol  -->
				@if (
					data.style.layer &&
					data.style.layer.source &&
					data.style.layer.type === 'symbol'
				) {
					<mgl-layer
						[id]="data.style.layer.id || 'new-layer'"
						type="symbol"
						[source]="data.style.layer.source || undefined"
						[sourceLayer]="data.style.layer.sourceLayer || undefined"
						[layout]="data.style.layer.layout || {}"
						[paint]="data.style.layer.paint || {}"></mgl-layer>
				}
			}
		</mgl-map>
	}
}
