<form [formGroup]="getFormGroup()">
	<label class="yuno-form">
		@if (label) {
			<span class="select-none" [innerHtml]="label | labelRequiredStandalone: required">
			</span>
		}
		<yuno-tinymce-editor-image-library-controlname
			[formControlName]="ngControl.name"></yuno-tinymce-editor-image-library-controlname>

		<yuno-language-icons [language]="language" [padding]="!!label"></yuno-language-icons>

		@if (ngControl.invalid && (ngControl.dirty || ngControl.touched)) {
			<div class="invalid-message">
				<ng-content></ng-content>
			</div>
		}
	</label>
</form>
