import { gql } from 'apollo-angular';

import { Sidemenu } from '@yuno/api/interface';

export const SAVE_SIDEMENU = gql`
	mutation saveSidemenu($data: SidemenuInput!, $appId: String!) {
		saveSidemenu(data: $data, appId: $appId) {
			_id
			id
		}
	}
`;

export const DUPLICATE_SIDEMENU = gql`
	mutation duplicateSidemenu($_id: String!, $appId: String!) {
		duplicateSidemenuById(_id: $_id, appId: $appId) {
			_id
			id
		}
	}
`;

export type SaveMutation = {
	saveSidemenu: Sidemenu;
};

export type DupeMutation = {
	duplicateSidemenuById: Sidemenu;
};
