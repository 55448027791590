import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/cache';
import { ApolloLink, DefaultOptions } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

const defaultOptions: DefaultOptions = {
	query: {
		fetchPolicy: 'cache-first',
		errorPolicy: 'all'
	},
	mutate: {
		errorPolicy: 'all'
	}
};

@NgModule({
	imports: [CommonModule, ApolloModule],
	providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class GraphqlAngularModule {
	static withConfig(config: {
		uri: string;
		addTypeName?: boolean;
		defaultOptions?: DefaultOptions;
	}): ModuleWithProviders<GraphqlAngularModule> {
		return {
			ngModule: GraphqlAngularModule,
			providers: [
				{
					provide: APOLLO_OPTIONS,
					useFactory(httpLink: HttpLink) {
						// Based on https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-428560796
						// fix when official fix is created
						const http = httpLink.create({
							uri: config.uri
						});

						// Remove __typename from all values
						const typenameMiddleware = new ApolloLink((operation, forward) => {
							if (operation.variables) {
								operation.variables = JSON.parse(
									JSON.stringify(operation.variables),
									omitTypename
								);
							}
							return forward(operation);
						});

						const omitTypename = (key: string, value: unknown) => {
							return key === '__typename' ? undefined : value;
						};

						// Create new ApolloLink
						const finalLink = ApolloLink.from([typenameMiddleware, http]);

						return {
							cache: new InMemoryCache({
								addTypename:
									config.addTypeName !== undefined ? config.addTypeName : true,
								resultCaching: false
							}),
							link: finalLink,
							defaultOptions: config.defaultOptions || defaultOptions
						};
					},
					deps: [HttpLink]
				}
			]
		};
	}
}
