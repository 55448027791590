@if (data$ | async) {
	<div
		class="absolute bottom-0 left-0 mb-20 flex h-auto w-full items-center justify-center px-20">
		<div
			class="pointer-events-auto flex h-full w-auto max-w-full flex-row flex-wrap items-center justify-around rounded-md bg-yuno-gray-600 px-8 text-gray-200 shadow-lg drop-shadow-xl lg:max-w-5xl lg:flex-nowrap">
			<form #offsetForm="ngForm" class="flex flex-row flex-wrap items-start lg:flex-nowrap">
				<div class="mb-4 mr-4">
					<label class="block text-sm text-gray-300" for="y">Y - yaw</label>
					<input
						type="number"
						class="focus:shadow-outline w-20 appearance-none rounded border px-3 py-2 leading-tight text-gray-900 shadow focus:outline-none"
						id="y"
						name="y"
						step="0.01"
						[(ngModel)]="offset.y"
						(ngModelChange)="changeOffset()" />
					<button
						class="cursor-pointer rounded-md bg-xkp-green px-6 text-xkp-green-text shadow-sm hover:bg-xkp-green-hover"
						(click)="copyData('y')">
						copy
					</button>
				</div>
				<div class="mb-4 mr-4">
					<label class="block text-sm text-gray-300" for="x"> X - pitch </label>
					<input
						type="number"
						class="focus:shadow-outline w-20 appearance-none rounded border px-3 py-2 leading-tight text-gray-900 shadow focus:outline-none"
						id="x"
						name="x"
						step="0.01"
						[(ngModel)]="offset.x"
						(ngModelChange)="changeOffset()" />
					<button
						class="cursor-pointer rounded-md bg-xkp-green px-6 text-xkp-green-text shadow-sm hover:bg-xkp-green-hover"
						(click)="copyData('x')">
						copy
					</button>
				</div>
				<div class="mb-4 mr-4">
					<label class="block text-sm text-gray-300" for="z">Z - roll</label>
					<input
						type="number"
						class="focus:shadow-outline w-20 appearance-none rounded border px-3 py-2 leading-tight text-gray-900 shadow focus:outline-none"
						id="z"
						name="z"
						step="0.01"
						[(ngModel)]="offset.z"
						(ngModelChange)="changeOffset()" />
					<button
						class="cursor-pointer rounded-md bg-xkp-green px-6 text-xkp-green-text shadow-sm hover:bg-xkp-green-hover"
						(click)="copyData('z')">
						copy
					</button>
				</div>
				<div class="mb-4 mr-4">
					<label class="block text-sm text-gray-300" for="height">Height (meters)</label>
					<input
						type="number"
						class="focus:shadow-outline w-32 appearance-none rounded border px-3 py-2 leading-tight text-gray-900 shadow focus:outline-none"
						id="height"
						name="height"
						step="0.01"
						[(ngModel)]="offset.height"
						(ngModelChange)="changeOffset()" />
				</div>
				<div class="mb-4 mr-4">
					<label class="block text-sm text-gray-300" for="locX">Loc X (meters)</label>
					<input
						type="number"
						class="focus:shadow-outline w-32 appearance-none rounded border px-3 py-2 leading-tight text-gray-900 shadow focus:outline-none"
						id="locX"
						name="locX"
						step="0.01"
						[(ngModel)]="offset.locX"
						(ngModelChange)="changeOffset()" />
				</div>
				<div class="mb-4 mr-4">
					<label class="block text-sm text-gray-300" for="locY">Loc Y (meters)</label>
					<input
						type="number"
						class="focus:shadow-outline w-32 appearance-none rounded border px-3 py-2 leading-tight text-gray-900 shadow focus:outline-none"
						id="locY"
						name="locY"
						step="0.01"
						[(ngModel)]="offset.locY"
						(ngModelChange)="changeOffset()" />
				</div>
			</form>
			<button
				class="cursor-pointer rounded-md bg-xkp-green px-6 py-3 text-xkp-green-text shadow-sm hover:bg-xkp-green-hover"
				(click)="exportData()">
				export
			</button>
			<button
				class="cursor-pointer rounded-md bg-gray-900 px-6 py-3 text-xkp-green-text shadow-sm hover:bg-gray-600"
				(click)="clearData()">
				clear
			</button>
		</div>
	</div>
}
