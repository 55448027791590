import { Feature, FeatureCollection, Point } from 'geojson';
import { cloneDeep, startCase } from 'lodash';
import { GeoJSONSourceSpecification } from 'maplibre-gl';
import slugify from 'slugify';

import {
	DatasetStatesMarkerCategories,
	DateLayoutOption,
	DateOptions,
	GeoJSONSourceSpecificationExtended,
	Marker,
	MarkerCategory,
	MarkerCategoryCluster,
	MarkerCategoryLayout,
	MarkerFixedPropertiesKeys,
	MarkerIconStyle,
	MarkerLabelStyle,
	MarkerPanoramaStyle,
	NumberLayoutOption,
	StringLayoutOption,
	VisibilityEnum,
	instanceOfMarkerIconStyle,
	instanceOfMarkerLabelStyle
} from '@yuno/api/interface';
import { MarkersCategoriesClusterSource } from '@yuno/libs/shared/helpers';

import {
	DateImportance,
	comparisonDateTypes,
	comparisonOperators,
	comparisonOperatorsDate,
	comparisonOperatorsType
} from './layout.helpers';

export const createCategoryMarkers = (
	category: Partial<MarkerCategory>,
	markers: Marker[],
	state?: DatasetStatesMarkerCategories[]
): {
	features: FeatureCollection<Point>['features'];
	clusterSourceName?: string;
	clusterSource?: GeoJSONSourceSpecification;
} => {
	// Do not add empty marker arrays
	if (!markers || markers?.length < 1) {
		return {
			features: []
		};
	}

	if (!category.layout) {
		return {
			features: []
		};
	}

	markers = cloneDeep(markers);

	let clusterSource: GeoJSONSourceSpecification | undefined = undefined;
	let clusterSourceName: string | undefined = undefined;

	// FILTER BASED ON STATE
	if (state) {
		const found = state.find(e => e.markerCategory?._id === category._id);

		// When a state is found and should not be visible,
		// stop checking this MarkerCategory and skip to the next
		if (found?.visibility === 'none') {
			return {
				features: []
			};
		}
	}

	// Generate the Layout
	const styledMarkers = getLayout(category.layout, category.styles, markers, true);
	const markerArr: Feature<Point>[] = [];

	for (const marker of styledMarkers) {
		// If CustomEvents is disabled or
		// the Marker does not have its own events
		if (
			!category.customEvents ||
			!(
				(marker.events?.onClick && marker.events.onClick?.length >= 1) ||
				(marker.events?.onMouseMove && marker.events.onMouseMove?.length >= 1) ||
				(marker.events?.onMouseLeave && marker.events.onMouseLeave?.length >= 1)
			)
		) {
			marker.events = category.events || {};
		}

		markerArr.push(createMarkerFeature(marker, category.layout.filter));
	}

	if (category.cluster?.active) {
		// creates a safeName and transforms the first letter to Uppercase
		const slug = startCase(slugify(category.id || '', { replacement: '', lower: true }));

		clusterSourceName = MarkersCategoriesClusterSource + slug;
		clusterSource = generateClusterSource(category.cluster, markerArr);
	}

	return {
		features: markerArr,
		clusterSourceName,
		clusterSource
	};
};

const createMarkerFeature = (
	marker: Partial<Marker>,
	filter: MarkerFixedPropertiesKeys = 'status'
): Feature<Point> => {
	const styling = {
		visibility: 'visible',
		minZoom: 1,
		maxZoom: 24,
		zIndex: 1,
		...marker.style
	};

	return {
		type: 'Feature',
		properties: {
			id: marker._id,
			minZoom: marker.properties?.minZoom || 0,
			maxZoom: marker.properties?.maxZoom || 24,
			style: JSON.stringify(styling),
			properties: JSON.stringify(marker.properties),
			customProperties: JSON.stringify(marker.customProperties),
			events: JSON.stringify(marker.events || {}),
			filter: marker.properties?.[filter]
		},
		geometry: marker.geometry as Point
	} as Feature<Point>;
};

const generateClusterSource = (
	cluster?: MarkerCategoryCluster,
	markers?: Feature<Point>[]
): GeoJSONSourceSpecificationExtended => {
	return {
		type: 'geojson',
		data: {
			type: 'FeatureCollection',
			features: [...(markers || [])],
			customData: {
				style: cluster?.style || {
					icon: 'https://cdn.projectatlas.app/sprites/public/vp-circle-grey.svg',
					scale: 1,
					alignment: 'center',
					rotation: 0
				},
				counter: cluster?.countStyle || {
					backgroundColor: '#394551',
					color: 'rgba(255,255,255,0.85)',
					alignment: 'top-right'
				},
				minZoom: cluster?.options?.minZoom || 1,
				maxZoom: cluster?.options?.maxZoom || 16,
				minPoints: cluster?.options?.minPoints || 2,
				radius: cluster?.options?.radius || 50
			}
		}
	};
};

// Returns the Default MarkerCategory Style
const returnDefault = (
	styles: MarkerCategory['styles'],
	layout: MarkerCategory['layout']
): MarkerIconStyle | MarkerLabelStyle | undefined =>
	styles?.find(style => style.id === layout.fallback)?.style;

// Returns a specific MarkerCategory Style, based on id
const returnValue = (
	styles: MarkerCategory['styles'],
	val: string
): MarkerIconStyle | MarkerLabelStyle | undefined =>
	(styles && styles.find(style => style.id === val)?.style) || undefined;

// Returns the Minimal Zoom level the Marker should be visible
const returnMinZoom = (styles: MarkerCategory['styles'], val: string) =>
	(styles && styles.find(style => style.id === val)?.style?.['minZoom']) || 1;

// Returns the Maximum Zoom level the Marker should be visible
const returnMaxZoom = (styles: MarkerCategory['styles'], val: string) =>
	(styles && styles.find(style => style.id === val)?.style?.['maxZoom']) || 24;

// Returns the Name of the Function needed to Apply the correct Operators
const getFilterFunction = (
	option: (DateLayoutOption | NumberLayoutOption | StringLayoutOption)[]
) => {
	if (isDate(option)) {
		return applyDateFilter;
	}

	if (isNumber(option)) {
		return applyNumberFilter;
	}

	return applyStringFilter;
};

/**
 * Returns True when the obj is a DateLayoutOption
 * based on the length of the Operator Array
 */
const isDate = (obj: unknown[]): obj is DateLayoutOption => {
	if (!Array.isArray(obj)) {
		return false;
	}

	if (!Array.isArray(obj[0]) || !Array.isArray(obj[0][0])) {
		return false;
	}

	return obj[0][0].length === 3;
};

/**
 * Returns True when the obj is a NumberLayoutOption
 * based on the length of the Operator Array
 */
const isNumber = (obj: unknown[]): obj is NumberLayoutOption => {
	if (!Array.isArray(obj)) {
		return false;
	}

	if (!Array.isArray(obj[0])) {
		return false;
	}

	return obj[0][0].length === 2;
};

/**
 * Returns True when the obj is a StringLayoutOption
 * based on the type of value
 */
export const isString = (obj: unknown[]): obj is StringLayoutOption => {
	if (!Array.isArray(obj)) {
		return false;
	}

	if (!Array.isArray(obj[0])) {
		return false;
	}

	return typeof obj[0][0] === 'string';
};

/**
 * Sort all Layout Numbers based on Operator
 *
 * @param {NumberLayoutOption[]} arr
 * @return {*}  {NumberLayoutOption[]}
 * @memberof  */
const layoutNumberOrder = (arr: NumberLayoutOption[]): NumberLayoutOption[] => {
	// first the equals
	const equals = arr.filter(e => e[0][0] === '==');

	// then the descending order of numbers
	const lowerOrder = arr
		.filter(e => ['<=', '<'].includes(e[0][0])) // filter operators
		.sort((a, b) => (a[0][0] == '<=' ? -1 : b[0][0] === '<' ? 1 : 0)) // sort operator
		.sort((a, b) => a[0][1] - b[0][1]); // sort number

	// now the ascending order of numbers
	const higherOrder = arr
		.filter(e => ['>=', '>'].includes(e[0][0])) // filter operators
		.sort((a, b) => (a[0][0] == '>=' ? -1 : b[0][0] === '>' ? 1 : 0)) // sort operator
		.sort((a, b) => (a[0][1] > b[0][1] ? -1 : 1)); // sort number

	// combine all to a single sorted array
	return [...equals, ...higherOrder, ...lowerOrder];
};

/**
 * Sorts all Layout Dates based on Operators and DateImportance
 *
 * @param {DateLayoutOption[]} arr
 * @return {*}  {DateLayoutOption[]}
 * @memberof  */
const layoutDateOrder = (arr: DateLayoutOption[]): DateLayoutOption[] => {
	// first filter all equals Dates
	const equals = arr.filter(e => e[0][0] === '==');

	// Then sort the order based on importance of Date
	// descending
	const lowerOrder = arr
		.filter(e => ['<=', '<'].includes(e[0][0])) // filter operators
		.sort((a, b) => a[0][1] - b[0][1]) // sort number
		.sort((a, b) => DateImportance[a[0][2]] - DateImportance[b[0][2]]) // sort dateOption
		.sort((a, b) => (a[0][0] == '<=' ? -1 : b[0][0] === '<' ? 1 : 0)); // sort operator

	// Now sort the order based on importance of Date
	// ascending
	const higherOrder = arr
		.filter(e => ['>=', '>'].includes(e[0][0])) // filter operators
		.sort((a, b) => (a[0][1] > b[0][1] ? -1 : 1)) // sort number
		.sort((a, b) => DateImportance[a[0][2]] - DateImportance[b[0][2]]) // sort dateOption
		.sort((a, b) => (a[0][0] == '>=' ? -1 : b[0][0] === '>' ? 1 : 0)); // sort operator

	// combine all to a single sorted array
	return [...equals, ...higherOrder, ...lowerOrder];
};

/**
 * The Markers inside a Category will be filtered and altered here
 * This function should apply all styling to the requested Markers
 *
 * When no styles inside the Category it should apply the Default Category styling
 * otherwise it uses all Operator functions to determine the Order, Styling an Visibility
 * of the Markers
 *
 * @param {MarkerCategory["layout"]} layout
 * @param {MarkerCategory["styles"]} styles
 * @param {Marker[]} markers
 * @param {boolean} visibility
 * @return {*}  {Marker[]}
 * @memberof  */
const getLayout = (
	layout: MarkerCategory['layout'],
	styles: MarkerCategory['styles'],
	markers: Marker[],
	visibility: boolean
): Marker[] => {
	/* When no layout options and no layoutFallback */
	if (!layout?.options && !layout.fallback) {
		return markers;
	}

	// When no layout options and layoutFallback exists.
	// Apply fallback to all markers
	// and return the Marker[]
	if ((!layout?.options || layout.options.length < 1) && layout.fallback) {
		return applyDefaultStyle(markers, layout, styles);
	}

	if (!layout.options) {
		return [];
	}

	// Get the Filter Function name
	const filterFunction = getFilterFunction(layout.options);

	// Applies the filter and returns 2 arrays of Markers
	const [markArr, leftOver] = filterFunction(layout, markers, styles, visibility);

	// Combines all Markers and applies Default Styling to the LeftOver markers
	return [...markArr, ...applyDefaultStyle(leftOver, layout, styles)];
};

/**
 * Applies the default MarkerCategory style to all Markers
 *
 * @param {Marker[]} markers
 * @param {MarkerCategory["layout"]} layout
 * @param {MarkerCategory["styles"]} styles
 * @return {*}  {Marker[]}
 * @memberof  */
const applyDefaultStyle = (
	markers: Marker[],
	layout: MarkerCategory['layout'],
	styles: MarkerCategory['styles']
): Marker[] => {
	const markerArr = [...markers];
	for (const marker of markerArr) {
		const style = returnDefault(styles, layout);

		if (!style) {
			continue;
		}

		marker.style = { ...style };

		if (!styles?.[0].overwriteZoom) {
			marker.properties.minZoom = returnMinZoom(styles, layout.fallback as string);
			marker.properties.maxZoom = returnMaxZoom(styles, layout.fallback as string);
		}

		if (instanceOfMarkerIconStyle(marker.style)) {
			marker.style.alignment = marker.properties.alignment;
			marker.style.rotation = marker.properties.rotation;
		}

		if (instanceOfMarkerLabelStyle(marker.style)) {
			marker.style.alignment = marker.properties.alignment;
		}
	}

	return markerArr;
};

/**
 * Applies Date based operator filters to all Markers
 *
 * @param {MarkerCategoryLayout} layout
 * @param {Marker[]} markers
 * @param {MarkerCategory["styles"]} styles
 * @param {boolean} [visibility]
 * @return {*}  {[Marker[], Marker[]]}
 * @memberof  */
const applyDateFilter = (
	layout: MarkerCategoryLayout,
	markers: Marker[],
	styles: MarkerCategory['styles'],
	visibility?: boolean
): [Marker[], Marker[]] => {
	let markerArr: Marker[] = [];
	let leftOvers: Marker[] = [...markers];

	const options = layoutDateOrder(layout.options as DateLayoutOption[]);

	// [["<", 2, "years"], "style4"]
	// [[operator, number, token], style]
	for (const option of options) {
		const operator: string = option[0][0];
		const num: number = option[0][1];
		const token: DateOptions = option[0][2];

		const [markArr, leftOver] = leftOvers.reduce(
			(markerArr: Marker[][], marker) => {
				if (!layout.filter) {
					return markerArr;
				}

				const val = marker.properties[layout.filter];
				if (
					val &&
					comparisonOperatorsDate[operator as comparisonDateTypes](
						new Date(val as string),
						num,
						token
					)
				) {
					const newMarker = markerProperties(marker, option, styles, visibility);
					return [[...markerArr[0], newMarker], markerArr[1]];
				}

				return [markerArr[0], [...markerArr[1], marker]];
			},
			[[], []]
		);

		markerArr = [...markerArr, ...markArr];
		leftOvers = [...leftOver];
	}

	return [markerArr, leftOvers];
};

/**
 * Applies Number based operator filters to all Markers
 *
 * @param {MarkerCategoryLayout} layout
 * @param {Marker[]} markers
 * @param {MarkerCategory["styles"]} styles
 * @param {boolean} [visibility]
 * @return {*}  {[Marker[], Marker[]]}
 * @memberof  */
const applyNumberFilter = (
	layout: MarkerCategoryLayout,
	markers: Marker[],
	styles: MarkerCategory['styles'],
	visibility?: boolean
): [Marker[], Marker[]] => {
	let markerArr: Marker[] = [];
	let leftOvers: Marker[] = [...markers];

	const options = layoutNumberOrder(layout.options as NumberLayoutOption[]);

	// [["==", 10], "style"]
	// [[operator, number], style]
	for (const option of options) {
		const operator: string = option[0][0];
		const num: number = option[0][1];

		const [markArr, leftOver] = leftOvers.reduce(
			(markerArr: Marker[][], marker) => {
				if (!layout.filter) {
					return markerArr;
				}

				const val = marker.properties[layout.filter];
				if (
					val &&
					comparisonOperators[operator as comparisonOperatorsType](
						Number(val),
						Number(num)
					)
				) {
					const newMarker = markerProperties(marker, option, styles, visibility);
					return [[...markerArr[0], newMarker], markerArr[1]];
				}

				return [markerArr[0], [...markerArr[1], marker]];
			},
			[[], []]
		);

		markerArr = [...markerArr, ...markArr];
		leftOvers = [...leftOver];
	}

	return [markerArr, leftOvers];
};

/**
 * Applies String based operator filters to all Markers
 *
 * @param {MarkerCategoryLayout} layout
 * @param {Marker[]} markers
 * @param {MarkerCategory["styles"]} styles
 * @param {boolean} [visibility]
 * @return {*}  {[Marker[], Marker[]]}
 * @memberof  */
const applyStringFilter = (
	layout: MarkerCategoryLayout,
	markers: Marker[],
	styles: MarkerCategory['styles'],
	visibility?: boolean
): [Marker[], Marker[]] => {
	let markerArr: Marker[] = [];
	let leftOvers: Marker[] = [...markers];

	// ["style", "style"]
	// [string, style]
	for (const option of layout.options as StringLayoutOption[]) {
		const [markArr, leftOver] = leftOvers.reduce(
			(markerArr: Marker[][], marker) => {
				if (!layout.filter) {
					return markerArr;
				}

				const val = marker.properties[layout.filter];
				if (
					val &&
					typeof val === 'string' &&
					val.toLowerCase() === option[0].toLowerCase()
				) {
					const newMarker = markerProperties(marker, option, styles, visibility);
					return [[...markerArr[0], newMarker], markerArr[1]];
				}

				return [markerArr[0], [...markerArr[1], marker]];
			},
			[[], []]
		);

		markerArr = [...markerArr, ...markArr];
		leftOvers = [...leftOver];
	}

	return [markerArr, leftOvers];
};

/**
 * Applies styles to a marker
 * based on the MarkerCategory stylesheet
 *
 * @param {Marker} marker
 * @param {(StringLayoutOption | DateLayoutOption | NumberLayoutOption)} option
 * @param {MarkerCategory["styles"]} styles
 * @param {boolean} [visibility]
 * @return {*}  {Marker}
 * @memberof  */
const markerProperties = (
	marker: Marker,
	option: StringLayoutOption | DateLayoutOption | NumberLayoutOption,
	styles: MarkerCategory['styles'],
	visibility?: boolean
): Marker => {
	const styling: Partial<MarkerIconStyle | MarkerLabelStyle | MarkerPanoramaStyle> = {
		type: marker.style?.type || 'label',
		visibility: VisibilityEnum.visible,
		minZoom: 1,
		maxZoom: 24,
		zIndex: 1,
		...marker.style
	};

	// get the marker Style
	marker.style = {
		...styling,
		...returnValue(styles, option[1])
	};

	// check if the style should overwrite the MarkerZoom level
	// then overwrites it with the styles minMaxZoom values
	if (!styles?.find(e => e.id === option[1])?.overwriteZoom) {
		marker.properties.minZoom = returnMinZoom(styles, option[1]);
		marker.properties.maxZoom = returnMaxZoom(styles, option[1]);
	}

	if (instanceOfMarkerIconStyle(marker.style)) {
		if (marker.properties.alignment) {
			marker.style.alignment = marker.properties.alignment;
		}
		if (marker.properties.rotation) {
			marker.style.rotation = marker.properties.rotation;
		}
	}

	if (instanceOfMarkerLabelStyle(marker.style)) {
		if (marker.properties.alignment) {
			marker.style.alignment = marker.properties.alignment;
		}
	}

	// disable the Visibility of the Marker when it should not be visible
	!visibility && (marker.public = visibility || false);

	// return a new object
	return JSON.parse(JSON.stringify(marker));
};
