import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { combineLatest, take } from 'rxjs';

import { ThemeFacade } from '@yuno/admin/features/themes';
import {
	PillItem,
	YunoAdminButtonComponent,
	YunoAdminEditButtonComponent,
	YunoAdminNavbarButtonsComponent,
	YunoAdminPillModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { MarkersFacade } from '../../../index';
import { MarkersMapViewerComponent } from './components/map-viewer/map-viewer.component';
import { MarkersMapViewerService } from './components/map-viewer/map-viewer.service';

interface PillItemUrl extends PillItem {
	url: string;
	exact: boolean;
}

@Component({
	selector: 'yuno-admin-marker-viewer',
	standalone: true,
	imports: [
		YunoFormsModule,
		CommonModule,
		MatBadgeModule,
		YunoAdminEditButtonComponent,
		YunoEditContainerModule,
		YunoAdminPillModule,
		RouterModule,
		YunoAdminButtonComponent,
		MarkersMapViewerComponent,
		YunoAdminNavbarButtonsComponent
	],
	templateUrl: './view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerViewerComponent extends AppDataComponent implements OnInit {
	private readonly markersFacade = inject(MarkersFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly mapService = inject(MarkersMapViewerService);

	selector: PillItemUrl[] = [
		{
			key: 'Map',
			url: 'map',
			exact: false,
			selected: true
		},
		{
			key: 'List',
			url: 'list',
			exact: false,
			selected: false
		}
	];

	data$ = combineLatest({
		filter: this.mapService.filter$
	});

	ngOnInit(): void {
		this.route.queryParamMap.pipe(take(1)).subscribe(data => {
			const map = data.get('map')?.split(',');
			if (!map) {
				return;
			}

			this.mapService.basedOnRoute = true;
			this.mapService.zoom = Number(map[2]);
			this.mapService.center = [Number(map[1]), Number(map[0])];
			this.mapService.bearing = Number(map[3]);
			this.mapService.pitch = Number(map[4]);
		});

		this.markersFacade.get();
		this.themeFacade.get();
	}
}
