import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-image-editor',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AsyncPipe],
	templateUrl: './image-editor.component.html',
	styleUrls: ['./image-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService();
	data$ = this.editService.data$;

	ngOnInit() {
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
