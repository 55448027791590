import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { NunjucksService } from '@yuno/angular/nunjucks';


@Pipe({
	name: 'renderString',
	standalone: true
})
export class RenderStringPipe implements PipeTransform {
	private readonly sanitizer = inject(DomSanitizer);
	private readonly nunjucks = inject(NunjucksService);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public transform(value: string, data?: any): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(this.nunjucks.renderString(value, data));
	}
}
