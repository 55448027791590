import { gql } from 'apollo-angular';

import { Panorama } from '@yuno/api/interface';

export const SAVE_PANO = gql`
	mutation savePanorama($panorama: PanoramaInput!, $appId: String!) {
		savePanorama(panorama: $panorama, appId: $appId) {
			_id
			id
			alt
			public
			selected
			map {
				color
				coordinates
				display
				icon
				minZoom
				maxZoom
				rotation
				visibility
			}
			textfield {
				default
				states {
					state
					text
				}
			}
		}
	}
`;

export const DUPLICATE_PANO = gql`
	mutation duplicatePanorama($_id: String!, $appId: String!) {
		duplicatePanorama(_id: $_id, appId: $appId) {
			_id
			id
			alt
			public
			selected
			map {
				display
				icon
			}
			textfield {
				states {
					text
				}
			}
		}
	}
`;

export type SavePanoramaQuery = {
	savePanorama: Partial<Panorama>;
};

export type DuplicatePanoramaMutation = {
	duplicatePanorama: Partial<Panorama>;
};
