import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { CdnFile, MediaImage } from '@yuno/api/interface';

import { imagesActions } from './image.actions';

export interface ImageState {
	loaded: boolean;
	images: Partial<MediaImage>[];
	imageFiles: Partial<CdnFile>[] | undefined;
	selectedLoaded: boolean;
	selected: Partial<MediaImage> | undefined;
	status: Status;
}

export const initialStateImage: ImageState = {
	loaded: false,
	images: [],
	imageFiles: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateImage,
	on(appActions.reset, () => initialStateImage),
	on(imagesActions.load, state => ({
		...state,
		loaded: false,
		status: Status.VALIDATING
	})),
	on(imagesActions.loadSuccess, (state, { data }) => ({
		...state,
		images: data,
		loaded: true,
		status: Status.SUCCESS
	})),
	on(imagesActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	})),

	on(imagesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		saved: undefined
	})),
	on(imagesActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),

	on(imagesActions.loadImageFilesSuccess, (state, { data }) => ({
		...state,
		imageFiles: data
	})),
	on(imagesActions.loadImageFilesFailure, state => ({
		...state,
		imageFiles: []
	}))
);

export const imagesFeature = createFeature({
	name: 'images',
	reducer
});
