import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import {
	YunoFormsCheckboxComponent,
	YunoFormsColorComponent,
	YunoFormsDateComponent,
	YunoFormsDividerComponent,
	YunoFormsEmailComponent,
	YunoFormsFileComponent,
	YunoFormsFormControlSelectorComponent,
	YunoFormsHtmlTextComponent,
	YunoFormsImageComponent,
	YunoFormsNumberComponent,
	YunoFormsPasswordComponent,
	YunoFormsRadioComponent,
	YunoFormsRangeComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTelephoneComponent,
	YunoFormsTextComponent,
	YunoFormsTextareaComponent,
	YunoFormsTextfieldComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent,
	YunoFormsUrlComponent
} from '@yuno/angular/forms/components';
import { YunoTinymceEditorModule } from '@yuno/angular/tinymce';

import { YunoFormsArrayContainerComponent } from './components/_array-container/array-container.component';
import { YunoFormsDefaultComponent } from './components/_default/default.component';
import { YunoFormsValidatorMessagesComponent } from './components/_validator-messages/validator-messages.component';
import { YunoFormsLabelRequired } from './pipes/label-required/label-required.pipe';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ColorPickerModule,
		YunoTinymceEditorModule,
		YunoFormsLabelRequired,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsDefaultComponent,
		YunoFormsCheckboxComponent,
		YunoFormsColorComponent,
		YunoFormsDateComponent,
		YunoFormsEmailComponent,
		YunoFormsFileComponent,
		YunoFormsHtmlTextComponent,
		YunoFormsImageComponent,
		YunoFormsNumberComponent,
		YunoFormsPasswordComponent,
		YunoFormsRadioComponent,
		YunoFormsRangeComponent,
		YunoFormsSelectComponent,
		YunoFormsTelephoneComponent,
		YunoFormsTextComponent,
		YunoFormsTextareaComponent,
		YunoFormsTextfieldComponent,
		YunoFormsToggleComponent,
		YunoFormsUrlComponent,
		YunoFormsDividerComponent,
		YunoFormsFormControlSelectorComponent,
		YunoFormsValidatorMessagesComponent,
		YunoFormsArrayContainerComponent
	],
	providers: [DatePipe],
	exports: [
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsDividerComponent,
		YunoFormsDefaultComponent,
		YunoFormsCheckboxComponent,
		YunoFormsColorComponent,
		YunoFormsDateComponent,
		YunoFormsEmailComponent,
		YunoFormsFileComponent,
		YunoFormsHtmlTextComponent,
		YunoFormsImageComponent,
		YunoFormsNumberComponent,
		YunoFormsPasswordComponent,
		YunoFormsRadioComponent,
		YunoFormsRangeComponent,
		YunoFormsSelectComponent,
		YunoFormsTelephoneComponent,
		YunoFormsTextComponent,
		YunoFormsTextareaComponent,
		YunoFormsTextfieldComponent,
		YunoFormsToggleComponent,
		YunoFormsUrlComponent,
		YunoFormsFormControlSelectorComponent,
		YunoFormsArrayContainerComponent
	]
})
export class YunoFormsModule {}
