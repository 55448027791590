import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { Template } from '@yuno/api/interface';

import { templatesActions } from './templates.actions';

export interface TemplatesState {
	loaded: boolean;
	templates: Partial<Template>[];
	selectedLoaded: boolean;
	selected: Partial<Template> | undefined;
	status: Status;
}

export const initialState: TemplatesState = {
	loaded: false, // has the States list been loaded
	templates: [], // which States record has been selected
	selectedLoaded: false, // last known error (if any)
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(templatesActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(templatesActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		templates: data,
		status: Status.SUCCESS
	})),
	on(templatesActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	// Select
	on(templatesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(templatesActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(templatesActions.selectFailure, (state, { error }) => ({ ...state, error })),

	// Select Update
	on(templatesActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(templatesActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(templatesActions.saveSuccess, state => {
		const index = state.templates.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const states = [...state.templates];
		if (index >= 0) {
			states[index] = { ...states[index], ...state.selected };
		} else if (state.selected) {
			states.push({ ...state.selected });
		}
		// states.sort((a, b) => ((a.state as string) > (b.state as string) ? 1 : -1));

		return {
			...state,
			states: states
		};
	})
);

export const templatesFeature = createFeature({
	name: 'templates',
	reducer
});
