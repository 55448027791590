import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FormArray, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs';

import { YunoAdminButtonComponent, YunoAdminDeleteAdminButtonComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule, moveItemInFormArray } from '@yuno/angular/forms';

import { TextEditorsService } from '../../text-editors.service';
import { ToggleItemForm } from '../toggles.interface';

@Component({
	selector: 'yuno-admin-toggle-item',
	standalone: true,
	imports: [
		AsyncPipe,
		ReactiveFormsModule,
		DragDropModule,
		YunoFormsModule,
		YunoAdminDeleteAdminButtonComponent,
		YunoAdminButtonComponent
	],
	templateUrl: './toggle-item.component.html',
	styleUrls: ['./toggle-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleItemComponent extends AppDataComponent implements OnInit {
	@Input() form: FormGroup<ToggleItemForm>;
	@Input() index: number;

	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService().pipe(
		map(service => {
			return this.editService.returnTextfieldService(service);
		})
	);

	ngOnInit(): void {
		this.editService.onInitLayers();
		this.editService.onInitDatasets();
	}

	dropItem(event: CdkDragDrop<FormArray<FormGroup<Omit<ToggleItemForm, 'items'>>>>): void {
		moveItemInFormArray(event.container.data, event.previousIndex, event.currentIndex);
	}

	clearItem(event: Event) {
		const type: 'layer' | 'dataset' = (event.target as HTMLInputElement).value as
			| 'layer'
			| 'dataset';
		if (type === 'layer') {
			const datasets = this.form.get('dataset') as FormArray;
			datasets.clear();
		} else if (type === 'dataset') {
			const layers = this.form.get('layer') as FormArray;
			layers.clear();
		}
	}
}
