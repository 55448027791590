import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoAdminCodeEditorModule,
	YunoCardModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import { YunoFormsModule } from '@yuno/angular/forms';

import { ObjectEditorComponent } from './object-editor.component';

@NgModule({
	declarations: [ObjectEditorComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoFormsModule,
		JsonFormsModule,
		YunoEditContainerModule,
		YunoCardModule,
		YunoAdminButtonsModule,
		YunoAdminCodeEditorModule,
		UserRightsMessageComponent,
		EditContainerContentDirective
	]
})
export class ObjectEditorModule {}
