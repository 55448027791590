@if (userData$ | async; as userData) {
	<h2 mat-dialog-title>{{ data.displayName }} - {{ data.email }}</h2>
	<mat-dialog-content class="mat-typography" style="overflow: hidden">
		<div class="flex h-full max-h-[inherit] w-screen max-w-screen-md flex-col">
			<h3>Applications ({{ userData.length }})</h3>
			<div class="flex-1 overflow-y-auto">
				<yuno-admin-table
					[sortable]="true"
					[data]="userData"
					[defaultColumn]="'appName'"
					[columns]="[
						{ key: 'appName', label: 'App', mobile: true },
						{ key: 'role', label: 'Role', mobile: true }
					]">
				</yuno-admin-table>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<div class="flex gap-2">
			@if (data.allowResendInvitation) {
				<button yuno-admin-button color="danger" (click)="resendInvite()">
					Resend Invite
				</button>
			}
			@if (data.allowPasswordReset) {
				<button yuno-admin-button color="danger" (click)="resetPassword()">
					Send Reset Password
				</button>
			}
			<button
				yuno-admin-button
				color="muted"
				[mat-dialog-close]="true"
				(click)="removeUserData()">
				Close
			</button>
		</div>
	</mat-dialog-actions>
}
