import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

export class VideoOptions {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	query?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	attr?: any;
	image?: string;
}

@Injectable({
	providedIn: 'root'
})
export class EmbedVideoService {
	private validYouTubeOptions = [
		'default',
		'mqdefault',
		'hqdefault',
		'sddefault',
		'maxresdefault'
	];
	private validVimeoOptions = ['thumbnail_small', 'thumbnail_medium', 'thumbnail_large'];
	private validDailyMotionOptions = [
		'thumbnail_60_url',
		'thumbnail_120_url',
		'thumbnail_180_url',
		'thumbnail_240_url',
		'thumbnail_360_url',
		'thumbnail_480_url',
		'thumbnail_720_url',
		'thumbnail_1080_url'
	];

	constructor(
		private http: HttpClient,
		private sanitizer: DomSanitizer
	) {}

	public embed(url: URL, options?: VideoOptions): SafeHtml | undefined {
		let id;
		url = new URL(url);

		id = this.detectYoutube(url);
		if (id) {
			return this.embed_youtube(id, options);
		}

		id = this.detectVimeo(url);
		if (id) {
			return this.embed_vimeo(id, options);
		}

		id = this.detectDailymotion(url);
		if (id) {
			return this.embed_dailymotion(id, options);
		}

		return undefined;
	}

	public embed_youtube(id: string, options?: VideoOptions): SafeHtml {
		options = this.parseOptions(options as VideoOptions);

		return this.sanitize_iframe(
			'<iframe src="https://www.youtube.com/embed/' +
				id +
				options?.query +
				'"' +
				options?.attr +
				' frameborder="0" allowfullscreen></iframe>'
		);
	}

	public embed_vimeo(id: string, options?: VideoOptions): SafeHtml {
		options = this.parseOptions(options as VideoOptions);

		return this.sanitize_iframe(
			'<iframe src="https://player.vimeo.com/video/' +
				id +
				options?.query +
				'"' +
				options?.attr +
				' frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
		);
	}

	public embed_dailymotion(id: string, options?: VideoOptions): SafeHtml {
		options = this.parseOptions(options as VideoOptions);

		return this.sanitize_iframe(
			'<iframe src="https://www.dailymotion.com/embed/video/' +
				id +
				options?.query +
				'"' +
				options?.attr +
				' frameborder="0" allowfullscreen></iframe>'
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public embed_image(url: URL, options?: VideoOptions): any {
		let id;

		url = new URL(url);

		id = this.detectYoutube(url);
		if (id) {
			return this.embed_youtube_image(id, options);
		}

		id = this.detectVimeo(url);
		if (id) {
			return this.embed_vimeo_image(id, options);
		}

		id = this.detectDailymotion(url);
		if (id) {
			return this.embed_dailymotion_image(id, options);
		}
	}

	private embed_youtube_image(
		id: string,
		options?: VideoOptions
	): Promise<{ link: string; html: string }> {
		if (typeof options === 'function') {
			options = {};
		}
		options = options || {};

		options.image = this.validYouTubeOptions.includes(options.image as string)
			? options.image
			: 'default';

		const src = ('https://img.youtube.com/vi/' + id + '/' + options.image + '.jpg') as string;

		const result = {
			link: src,
			html: '<img src="' + src + '"/>'
		};

		return new Promise(resolve => {
			resolve(result);
		});
	}

	private embed_vimeo_image(
		id: string,
		options?: VideoOptions
	): Promise<void | { link: string; html: string } | undefined> {
		if (typeof options === 'function') {
			options = {};
		}

		options = options || {};

		options.image =
			this.validVimeoOptions.indexOf(options.image as string) >= 0
				? options.image
				: 'thumbnail_large';

		return this.http
			.get('https://vimeo.com/api/v2/video/' + id + '.json')
			.pipe(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				map((res: any) => {
					return {
						link: res[0][options?.image as string],
						html: '<img src="' + res[0][options?.image as string] + '"/>'
					};
				})
			)
			.toPromise()
			.catch(error => console.log(error));
	}

	private embed_dailymotion_image(
		id: string,
		options?: VideoOptions
	): Promise<void | { link: string; html: string } | undefined> {
		if (typeof options === 'function') {
			options = {};
		}

		options = options || {};

		options.image =
			this.validDailyMotionOptions.indexOf(options?.image as string) >= 0
				? options.image
				: 'thumbnail_480_url';

		return this.http
			.get('https://api.dailymotion.com/video/' + id + '?fields=' + options.image)
			.pipe(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				map((res: any) => {
					return {
						link: res[options?.image as string],
						html: '<img src="' + res[options?.image as string] + '"/>'
					};
				})
			)
			.toPromise()
			.catch(error => console.log(error));
	}

	private parseOptions(options: VideoOptions): VideoOptions {
		let queryString = '',
			attributes = '';

		if (options && Object.prototype.hasOwnProperty.call(options, 'query')) {
			queryString = '?' + this.serializeQuery(options.query);
		}

		if (options && Object.prototype.hasOwnProperty.call(options, 'attr')) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const temp = <any>[];

			Object.keys(options.attr).forEach(function (key) {
				temp.push(((key + '="' + options.attr[key]) as string) + '"');
			});

			attributes = ' ' + temp.join(' ');
		}
		return {
			query: queryString,
			attr: attributes
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private serializeQuery(query: any): any {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const queryString: any = [];

		for (const p in query) {
			if (Object.prototype.hasOwnProperty.call(query, p)) {
				queryString.push(encodeURIComponent(p) + '=' + encodeURIComponent(query[p]));
			}
		}

		return queryString.join('&');
	}

	private sanitize_iframe(iframe: string): string {
		return this.sanitizer.bypassSecurityTrustHtml(iframe) as string;
	}

	private detectVimeo(url: URL): string | null {
		return url.hostname === 'vimeo.com' ? url.pathname.split('/')[1] : null;
	}

	private detectYoutube(url: URL): string {
		if (url.hostname.indexOf('youtube.com') > -1) {
			let video_id = url.search.split('v=')[1];
			const ampersandPosition = video_id.indexOf('&');
			if (ampersandPosition != -1) {
				video_id = video_id.substring(0, ampersandPosition);
			}

			return video_id;
		}

		if (url.hostname === 'youtu.be') {
			return url.pathname.split('/')[1];
		}

		return '';
	}

	private detectDailymotion(url: URL): string {
		if (url.hostname.indexOf('dailymotion.com') > -1) {
			return url.pathname.split('/')[2].split('_')[0];
		}

		if (url.hostname === 'dai.ly') {
			return url.pathname.split('/')[1];
		}

		return '';
	}
}
