import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	GET_TEXTBLOCKS,
	GET_TEXTBLOCK_BY_ID,
	TextblockQuery,
	TextblocksQuery
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/textblock/getTextblocks';
import {
	SAVE_TEXTBLOCK,
	SaveTextblockMutation
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/textblock/saveTextblocks';
import { GraphQLService } from '@yuno/angular-graphql';

import { textblocksActions } from './textblock.actions';
import { textblocksFeature } from './textblock.state';

export const loadTextblocks = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textblocksActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<TextblocksQuery>({
						query: GET_TEXTBLOCKS,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.textblocks) {
								throw new Error('no textblocks found');
							}
							return textblocksActions.loadSuccess({
								data: data.data.textblocks
							});
						}),
						take(1),
						catchError(error => of(textblocksActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectTextblocks = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textblocksActions.select),
			exhaustMap(textblock =>
				graphql
					.query<TextblockQuery>({
						query: GET_TEXTBLOCK_BY_ID,
						variables: {
							_id: textblock._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedTextblock) {
								throw new Error('no textblock with that id found');
							}
							return textblocksActions.selectSuccess({
								data: data.data.selectedTextblock
							});
						}),
						take(1),
						catchError(error => of(textblocksActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveTextblock = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textblocksActions.save),
			withLatestFrom(
				store.pipe(select(textblocksFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveTextblockMutation>({
						mutation: SAVE_TEXTBLOCK,
						variables: {
							textblock: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveTextblock) {
								throw new Error('error saving textblock to the database');
							}

							return textblocksActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(textblocksActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);
