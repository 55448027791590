import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard, EditorGuard, ResetContainerUi } from '@yuno/admin/core/guards';
import { uiContainerResolver } from '@yuno/admin/core/resolvers';

import { GeoPhotoViewerRoutingComponent } from './components/routing/routing.component';

const routes: Routes = [
	{
		path: '',
		canDeactivate: [ResetContainerUi],
		resolve: [uiContainerResolver],
		data: { containerType: 'map' },
		loadComponent: () => import('./view.component').then(c => c.GeoPhotoViewerComponent),
		children: [
			{
				path: 'map',
				component: GeoPhotoViewerRoutingComponent,
				children: [
					{
						path: 'edit',
						canActivate: [AdminGuard],
						loadComponent: () =>
							import('../settings/category/category-editor.component').then(
								c => c.GeoPhotoCategoryEditorComponent
							)
					},
					{
						path: 'photo/create',
						canActivate: [EditorGuard],
						loadComponent: () =>
							import('../settings/photo/photo-editor.component').then(
								c => c.GeoPhotoPhotoEditorComponent
							)
					},
					{
						path: 'photo/:id',
						loadComponent: () =>
							import('../settings/photo/photo-editor.component').then(
								c => c.GeoPhotoPhotoEditorComponent
							)
					}
				]
			},
			{
				path: 'list',
				loadComponent: () =>
					import('./components/list-viewer/list-viewer.component').then(
						c => c.GeoPhotoListViewerComponent
					),
				children: [
					{
						path: 'edit',
						canActivate: [AdminGuard],
						loadComponent: () =>
							import('../settings/category/category-editor.component').then(
								c => c.GeoPhotoCategoryEditorComponent
							)
					}
				]
			},
			{ path: '**', redirectTo: 'map' }
		]
	},
	{
		path: 'create',
		canActivate: [EditorGuard],
		loadComponent: () =>
			import('../settings/photo/photo-editor.component').then(
				c => c.GeoPhotoPhotoEditorComponent
			)
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
	declarations: []
})
export class GeoPhotoViewViewerRoutingModule {}
