@if (data$ | async; as data) {
	@if (data.mapStyle) {
		<mgl-map
			class="absolute left-0 top-0 z-0 h-full w-full"
			(styleImageMissing)="mapOnMissingImage($event)"
			(mapLoad)="service.mapLoad($event)"
			[style]="data.mapStyle"
			[fitBounds]="data.bounds.fitBounds || service.getMapBounds()"
			[fitBoundsOptions]="{ padding: service.boundPadding }">
		</mgl-map>
		<ng-content></ng-content>
		@if (!readonly) {
			<yuno-draw-tool-bar [settings]="drawMapSettings" />
		}
	}
}
