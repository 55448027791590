@if (service.customTextfields$ | async) {
	@if (form) {
		<form [formGroup]="form" class="grid grid-cols-1 gap-4">
			<yuno-admin-annotation type="info">
				<yuno-forms-span>
					Popup a <strong>Custom Textfield</strong> as a Label on the map. <br />
					The Popup will spawn right above the event-holder on MouseOver or Click and will
					disappear on MouseLeave or when clicking outside the event-holder's area.
				</yuno-forms-span>
			</yuno-admin-annotation>
			<div formGroupName="options">
				<yuno-forms-select
					formControlName="id"
					label="Custom Text ID"
					[selectValues]="service.customTextBlockSelectValues"
					[display]="service.customTextBlockSelectDisplay"
					placeholder="Custom Text..."></yuno-forms-select>
			</div>
		</form>
	}
}
