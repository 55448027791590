import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NavbarGuard } from '@yuno/admin/core/guards';

const routes: Routes = [
	{
		path: 'category',
		canActivate: [NavbarGuard('Participation Category', 2)],
		loadComponent: () =>
			import('./view/view.component').then(c => c.ParticipationViewComponent),
		loadChildren: () =>
			import('./view/view.routing.module').then(m => m.ParticipationViewRoutingModule)
	},
	{
		path: 'notification',
		canActivate: [NavbarGuard('Participation Notification', 2)],
		loadComponent: () =>
			import('./notifications/notifications.component').then(
				c => c.ParticipationNotificationComponent
			),
		loadChildren: () =>
			import('./notifications/notifications.routing.module').then(
				m => m.ParticipationNotificationRoutingModule
			)
	},
	{ path: '**', redirectTo: 'category' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ParticipationRoutingModule {}
