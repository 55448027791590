<ng-container>
	<h2 mat-dialog-title>Create account</h2>
	<mat-dialog-content class="mat-typography">
		<div class="w-screen max-w-screen-md">
			@if (form) {
				<form [formGroup]="form" class="grid grid-cols-3 gap-4">
					<yuno-forms-text
						class="col-span-3"
						formControlName="email"
						label="Email"
						placeholder="example@email.com"></yuno-forms-text>
					<yuno-forms-text
						class="col-span-3"
						formControlName="displayName"
						label="Name"
						placeholder="John Doe"></yuno-forms-text>
					<yuno-forms-select
						class="col-span-2"
						formControlName="language"
						[display]="languageDisplay"
						[selectValues]="language"
						label="Language"></yuno-forms-select>
				</form>
			}
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<div class="flex gap-2">
			<button yuno-admin-button (click)="addUser()" [add]="true" color="primary">
				Sign Up
			</button>
			<button yuno-admin-button [mat-dialog-close]="true" color="secondary">Close</button>
		</div>
	</mat-dialog-actions>
</ng-container>
