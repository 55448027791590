import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { participationsFeature } from '@yuno/admin/features/participation/data-access';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	GET_PARTICIPATION_PARTICIPATION,
	GET_PARTICIPATION_PARTICIPATIONS,
	PARTICIPATION_ARCHIVED,
	PARTICIPATION_ONHOLD,
	PARTICIPATION_PUBLISHED,
	PARTICIPATION_SEND_ANSWER,
	PARTICIPATION_UPDATE_ANSWER,
	PARTICIPATION_UPDATE_REACTION,
	ParticipatedAnswered,
	ParticipatedArchived,
	ParticipatedOnHold,
	ParticipatedPublished,
	ParticipatedQuery,
	ParticipatedSelectQuery,
	ParticipatedUpdateAnswer,
	ParticipatedUpdateReaction
} from './../../../utils';
import { participationsActions } from './participations.actions';

export const loadParticipates = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(participationsActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([action, appId]) =>
				graphql
					.query<ParticipatedQuery>({
						query: GET_PARTICIPATION_PARTICIPATIONS,
						variables: {
							id: action.id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getParticipationPosts) {
								throw new Error('Not posts found');
							}

							return participationsActions.loadSuccess({
								data: data.data.getParticipationPosts
							});
						}),
						take(1),
						catchError(error => {
							return of(participationsActions.loadFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const selectParticipate = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(participationsActions.select),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([action, appId]) =>
				graphql
					.query<ParticipatedSelectQuery>({
						query: GET_PARTICIPATION_PARTICIPATION,
						variables: {
							id: action._id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getParticipationPostAdmin) {
								throw new Error('No Participations found');
							}

							return participationsActions.selectSuccess({
								data: data.data.getParticipationPostAdmin
							});
						}),
						take(1),
						catchError(error => of(participationsActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const onHold = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(participationsActions.setOnHold),
			withLatestFrom(
				store.pipe(select(participationsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<ParticipatedOnHold>({
						mutation: PARTICIPATION_ONHOLD,
						variables: {
							_id: selected?._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.setParticipationOnHold) {
								throw new Error('');
							}

							message.sendToast(`Successfully put on hold!`, 'success');

							return participationsActions.setOnHoldSuccess({
								data: data.data.setParticipationOnHold
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error putting on hold!`, 'error');
							return of(participationsActions.setOnHoldFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const sendAnswer = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(participationsActions.sendAnswerOnly),
			withLatestFrom(
				store.pipe(select(participationsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<ParticipatedAnswered>({
						mutation: PARTICIPATION_SEND_ANSWER,
						variables: {
							_id: selected?._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.setParticipationAnswered) {
								throw new Error('');
							}

							message.sendToast(`Successfully send the answer!`, 'success');
							return participationsActions.sendAnswerOnlySuccess({
								data: data.data.setParticipationAnswered
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Failed to send answer!`, 'error');
							return of(participationsActions.sendAnswerOnlyFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const setPublished = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(participationsActions.setPublished),
			withLatestFrom(
				store.pipe(select(participationsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<ParticipatedPublished>({
						mutation: PARTICIPATION_PUBLISHED,
						variables: {
							_id: selected?._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.setParticipationPublished) {
								throw new Error('');
							}

							message.sendToast(`Successfully published!`, 'success');

							return participationsActions.setPublishedSuccess({
								data: data.data.setParticipationPublished
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error publishing!`, 'error');
							return of(participationsActions.setPublishedFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const setArchived = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(participationsActions.setArchived),
			withLatestFrom(
				store.pipe(select(participationsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<ParticipatedArchived>({
						mutation: PARTICIPATION_ARCHIVED,
						variables: {
							_id: selected?._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.setParticipationArchived) {
								throw new Error('');
							}

							message.sendToast(`Successfully moved to archived!`, 'success');

							return participationsActions.setArchivedSuccess({
								data: data.data.setParticipationArchived
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error moving to archived!`, 'error');
							return of(participationsActions.setArchivedFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const updateAnswer = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(participationsActions.onUpdateAnswer),
			withLatestFrom(
				store.pipe(select(participationsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([action, selected, appId]) =>
				graphql
					.mutate<ParticipatedUpdateAnswer>({
						mutation: PARTICIPATION_UPDATE_ANSWER,
						variables: {
							_id: selected?._id,
							appId: appId,
							content: action.data
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.setParticipationUpdateAnswer) {
								throw new Error('');
							}

							message.sendToast(`Successfully updated answer!`, 'success');

							return participationsActions.setArchivedSuccess({
								data: data.data.setParticipationUpdateAnswer
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error updating answer!`, 'error');
							return of(participationsActions.setArchivedFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const updateReaction = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(participationsActions.onUpdateReaction),
			withLatestFrom(
				store.pipe(select(participationsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([action, selected, appId]) =>
				graphql
					.mutate<ParticipatedUpdateReaction>({
						mutation: PARTICIPATION_UPDATE_REACTION,
						variables: {
							_id: selected?._id,
							appId: appId,
							content: action.data
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.setParticipationUpdateReaction) {
								throw new Error('');
							}

							message.sendToast(`Successfully updated reaction!`, 'success');

							return participationsActions.setArchivedSuccess({
								data: data.data.setParticipationUpdateReaction
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error updating reaction!`, 'error');
							return of(participationsActions.setArchivedFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);
