import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { textNewsItemsActions } from './text-news-item.actions';
import { textNewsItemsFeature } from './text-news-item.state';

@Injectable()
export class TextNewsItemFacade {
	private readonly store = inject(Store);

	data$ = this.store.pipe(select(textNewsItemsFeature.selectData));
	status$ = this.store.pipe(select(textNewsItemsFeature.selectStatus));

	get() {
		this.store.dispatch(textNewsItemsActions.load());
	}
}
