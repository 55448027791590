@if (service.sources$ | async) {
	@if (form) {
		<form [formGroup]="form">
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				<yuno-forms-text
					formControlName="id"
					label="Attribute"
					[placeholder]="'{{ properties.attribute }}'"></yuno-forms-text>
				<yuno-admin-annotation type="info">
					<yuno-forms-span ngNonBindable>
						To dynamically get a feature attribute, follow this syntax: <br />
						<strong>{{ properties.<i>GeoJSON attribute</i> &#125;&#125;</strong>, <br />
						You can also give it a fixed value.<br />
					</yuno-forms-span>
				</yuno-admin-annotation>
				<yuno-admin-annotation type="info">
					<yuno-forms-span ngNonBindable>
						The <strong><i>GeoJSON attribute</i></strong> should be the same as the
						<strong><i>'promoteId'</i></strong> in the selected <strong>source</strong>.
					</yuno-forms-span>
				</yuno-admin-annotation>
				@if (srcData$ | async; as data) {
					<yuno-forms-select
						formControlName="source"
						label="Source"
						placeholder="select source..."
						(changes)="getSourceLayers($event)"
						[display]="service.sourceSelectDisplay"
						[selectValues]="service.sourceSelectDisplay"></yuno-forms-select>
					@if (data?.sourceLayers?.length) {
						<yuno-forms-select
							formControlName="sourceLayer"
							label="Source Layer"
							placeholder="select source layer..."
							[display]="service.sourceLayersSelectDisplay"
							[selectValues]="service.sourceLayersSelectValues"></yuno-forms-select>
					}
				}
				<yuno-forms-divider></yuno-forms-divider>
				<yuno-forms-span class="font-semibold"> Merge Previous State:</yuno-forms-span>
				<yuno-forms-toggle formControlName="merge"></yuno-forms-toggle>
				<yuno-admin-annotation type="info">
					<yuno-forms-span>
						When <strong>multiple actions</strong> can write
						<strong>featureStates</strong> to the same Feature, allow Merge to include
						all set states. Otherwise, it <strong>resets</strong> with only the data in
						<strong>this dialog</strong>.
					</yuno-forms-span>
				</yuno-admin-annotation>
				<yuno-forms-divider></yuno-forms-divider>
				<div formGroupName="state" class="grid grid-cols-1 gap-4">
					<yuno-forms-toggle formControlName="hover" label="Hover"></yuno-forms-toggle>
					<yuno-forms-divider></yuno-forms-divider>
					<yuno-forms-title>Custom Values:</yuno-forms-title>
					@for (item of controls?.controls | keyvalue; track item) {
						@if (item.key !== 'hover') {
							<div
								class="flex flex-row items-center justify-start gap-4 rounded-md border border-gray-300 bg-gray-200 p-2">
								<yuno-forms-span>{{ item.key }}</yuno-forms-span>
								<yuno-forms-text
									class="flex-1"
									placeholder="custom value"
									[formControlName]="item.key"></yuno-forms-text>
								<button
									yuno-admin-button
									color="danger"
									(click)="removeControl(item.key)">
									Delete
								</button>
							</div>
						}
					}
				</div>
			</div>
		</form>
	}
	<div class="mt-4 flex flex-row items-center justify-start gap-4">
		<label class="yuno-form flex-1">
			<input class="truncate" type="text" [(ngModel)]="newKeyValue" />
		</label>
		<button yuno-admin-button (click)="createNewValue()">Add Key</button>
	</div>
}
