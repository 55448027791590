import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-open-url',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AnnotationComponent, AsyncPipe],
	templateUrl: './open-url.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenUrlComponent extends EventsDefaultFormItemComponent {}
