import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Position } from 'geojson';
import { LngLatBoundsLike } from 'maplibre-gl';

import { ControlsOf, LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import {
	LanguageType,
	MapViewOptions,
	Theme,
	ThemeModulesParticipationCategory,
	ThemeStatesAvailable,
	ThemeTextfieldDefault
} from '@yuno/api/interface';

export const DefaultMapStyle = '5e3d6ee6af66e10614a5425c';
export const DefaultMapBoundsLngLat: LngLatBoundsLike = [
	[7.22, 53.7],
	[3.2, 50.75]
];
export const DefaultMapBounds: Position[] = [
	[7.22, 53.7],
	[3.2, 53.7],
	[3.2, 50.75],
	[3.2, 53.7],
	[7.22, 53.7]
];

export interface ThemeForm {
	id: FormControl<string>;
	_id?: FormControl<string>;

	// appId: FormControl<string>;
	controls: FormGroup<{
		backToOverview: FormGroup<{ active: FormControl<boolean> }>;
		controlbar: FormGroup<{
			location: FormControl<boolean>;
			measure: FormControl<boolean>;
			zoom: FormControl<boolean>;
			mapStyles: FormControl<boolean>;
		}>;
		mapscale: FormControl<boolean>;
	}>;
	datasets: FormArray<themeDatasets>;
	keepView: FormControl<boolean>;
	legend?: FormGroup<{
		_id: FormControl<string | null>;
	}>;
	modules?: FormGroup<{
		disclaimer: FormGroup<{
			active: FormControl<boolean>;
			when: FormControl<string>;
			textblock: FormGroup<{
				_id: FormControl<string>;
			}>;
		}>;
		panoramas: FormGroup<{
			hotspots: FormGroup<{
				active: FormControl<boolean>;
				range: FormControl<number>;
				type: FormControl<string>;
			}>;
			splitscreen: FormGroup<{
				active: FormControl<boolean>;
				dial: FormControl<boolean>;
				keepView: FormControl<boolean>;
			}>;
			threejs: FormGroup<{
				active: FormControl<boolean>;
				range: FormControl<number>;
			}>;
			vr: FormControl<boolean>;
			calibrate: FormControl<boolean>;
			popup: FormControl<boolean>;
		}>;
		participation: FormGroup<{
			button: FormControl<boolean>;
			buttonText: FormGroup<LanguageFormType>;
			showData: FormControl<boolean>;
			startTitle: FormGroup<LanguageFormType>;
			startText: FormGroup<LanguageFormType>;
			dataTitle: FormGroup<LanguageFormType>;
			data: FormArray<FormGroup<themeParticipationData>>;
		}>;
		searchAdress: FormGroup<{
			active: FormControl<boolean>;
			country: FormControl<string>;
		}>;
	}>;
	sidemenu?: FormGroup<{
		_id: FormControl<string | null>;
	}>;

	states: FormGroup<{
		available: FormArray<themeAvailableStates>;
		default: FormGroup<{
			_id: FormControl<string>;
		}>;
	}>;

	textfield: FormGroup<{
		default: FormGroup<ControlsOf<Required<ThemeTextfieldDefault>>>;
		isClosed: FormControl<string>;
		visibility: FormControl<string>;
		width: FormControl<number>;
		wide: FormControl<boolean>;
	}>;

	mapStyles: FormArray<CleanedMapStyles>;
	view: FormGroup<CleanedMapView>;
	transition: FormControl<boolean>;
}

type themeDatasets = FormGroup<{
	_id: FormControl<string>;
}>;
type themeAvailableStates = FormGroup<{
	defaultText: FormControl<string>;
	state?: FormGroup<{
		_id: FormControl<string>;
	}>;
}>;

export type themeParticipationData = {
	data: FormControl<string>;
};
type themeMaxBounds = FormArray<FormControl<number>>; // [[number, number], [number, number]];
type CleanedMapView = ControlsOf<
	Omit<
		Required<MapViewOptions>,
		'style' | 'center' | 'clustering' | 'fitBoundsOptions' | 'maxBounds'
	>
> & {
	center: FormArray<FormControl<number>>; // [number, number]
	fitBoundsOptions: FormArray<FormControl<number>>; // [number, number, number, number];
	clustering: FormGroup<{
		mp: FormGroup<{
			active: FormControl<boolean>;
			minZoom: FormControl<number>;
		}>;
	}>;
	// style: FormGroup<{
	// 	_id: FormControl<string>;
	// }>;
	maxBounds: FormArray<themeMaxBounds>;
};

type CleanedMapStyles = FormGroup<{
	style: FormGroup<{
		_id: FormControl<string>;
	}>;
	description: FormGroup<LanguageFormType>;
}>;

@Injectable()
export class ThemeEditorService {
	form: FormGroup<ThemeForm>;
	language: LanguageType;

	textfieldValues: string[] = [];
	textfieldDisplay: string[] = [];

	textblockValues: string[] = [];
	textblockDisplay: string[] = [];

	statesValues: string[] = [];
	statesDisplay: string[] = [];

	datasetValues: string[] = [];
	datasetDisplay: string[] = [];

	styleValues: string[] = [];
	styleDisplay: string[] = [];

	legendValues: string[] = [];
	legendDisplay: string[] = [];

	participateValues: string[] = [];
	participateDisplay: string[] = [];

	sidemenuValues: string[] = [];
	sidemenuDisplay: string[] = [];

	triggerSelectValues = ['default', 'once'];
	triggerSelectDisplay = ['Default', 'Once'];

	isClosedSelectValues = ['default', 'pano', 'map'];
	isClosedSelectDisplay = ['Default', 'Pano', 'Map'];

	visibilitySelectValues = ['default', 'pano', 'map', 'none'];
	visibilitySelectDisplay = ['Default', 'Pano', 'Map', 'None'];

	whenSelectValues = ['default', 'always', 'pano', 'map'];
	whenSelectDisplay = ['Default', 'Always', 'Pano', 'Map'];

	panoramaHotspotsMarkersValues = ['vp', 'mp', 'pano', 'markers', 'all'];
	panoramaHotspotsMarkersDisplay = this.panoramaHotspotsMarkersValues;

	fencesValues = ['vp', 'mp', 'pano', 'markers', 'all'];
	fencesDisplay = this.panoramaHotspotsMarkersValues;

	get datasets() {
		return this.form.get('datasets') as FormArray;
	}

	get availableStates() {
		return this.form.controls.states.get('available') as FormArray;
	}

	get bounds() {
		return this.form.controls.view.get('bounds') as FormArray;
	}

	get mapStyles(): FormArray<CleanedMapStyles> {
		return this.form.get('mapStyles') as FormArray;
	}

	get maxBounds() {
		return this.form.controls.view.get('maxBounds') as FormArray;
	}

	get themeParticipation(): FormArray<FormGroup<themeParticipationData>> {
		return this.form.controls.modules?.controls.participation.get('data') as FormArray;
	}

	createFormGroup(): void {
		this.form = new FormGroup<ThemeForm>({
			_id: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),

			// appId: new FormControl('', { nonNullable: true }),
			controls: new FormGroup({
				mapscale: new FormControl(false, { nonNullable: true }),
				backToOverview: new FormGroup({
					active: new FormControl(false, { nonNullable: true })
				}),
				controlbar: new FormGroup({
					location: new FormControl(false, { nonNullable: true }),
					measure: new FormControl(false, { nonNullable: true }),
					zoom: new FormControl(false, { nonNullable: true }),
					mapStyles: new FormControl(false, { nonNullable: true })
				})
			}),
			datasets: new FormArray<themeDatasets>([]),
			keepView: new FormControl(false, { nonNullable: true }),
			transition: new FormControl(true, { nonNullable: true }),
			legend: new FormGroup({
				_id: new FormControl<string | null>(null, { nonNullable: false })
			}),
			sidemenu: new FormGroup({
				_id: new FormControl<string | null>(null, { nonNullable: false })
			}),
			textfield: new FormGroup({
				default: new FormGroup({
					text: new FormControl('', {
						nonNullable: true,
						validators: Validators.required
					}),
					when: new FormControl('', { nonNullable: true })
				}),
				isClosed: new FormControl('default', { nonNullable: true }),
				visibility: new FormControl('default', { nonNullable: true }),
				width: new FormControl(60, { nonNullable: true }),
				wide: new FormControl(false, { nonNullable: true })
			}),
			modules: new FormGroup({
				disclaimer: new FormGroup({
					active: new FormControl(false, { nonNullable: true }),
					when: new FormControl('default', { nonNullable: true }),
					textblock: new FormGroup({
						_id: new FormControl('', { nonNullable: true })
					})
				}),
				panoramas: new FormGroup({
					hotspots: new FormGroup({
						active: new FormControl(false, { nonNullable: true }),
						range: new FormControl(2000, { nonNullable: true }),
						type: new FormControl('', { nonNullable: true })
					}),
					splitscreen: new FormGroup({
						active: new FormControl(false, { nonNullable: true }),
						dial: new FormControl(false, { nonNullable: true }),
						keepView: new FormControl(false, { nonNullable: true })
					}),
					threejs: new FormGroup({
						active: new FormControl(false, { nonNullable: true }),
						range: new FormControl(2000, { nonNullable: true })
					}),
					vr: new FormControl(false, { nonNullable: true }),
					calibrate: new FormControl(false, { nonNullable: true }),
					popup: new FormControl(false, { nonNullable: true })
				}),
				participation: new FormGroup({
					button: new FormControl(false, { nonNullable: true }),
					buttonText: newLanguageFormGroup(),
					showData: new FormControl(false, { nonNullable: true }),
					startTitle: newLanguageFormGroup(),
					startText: newLanguageFormGroup(),
					dataTitle: newLanguageFormGroup(),
					data: new FormArray<FormGroup<themeParticipationData>>([])
				}),
				searchAdress: new FormGroup({
					active: new FormControl(false, { nonNullable: true }),
					country: new FormControl('', { nonNullable: true })
				})
			}),
			states: new FormGroup({
				available: new FormArray<themeAvailableStates>([]),
				default: new FormGroup({
					_id: new FormControl('', { nonNullable: true })
				})
			}),
			mapStyles: new FormArray<CleanedMapStyles>([
				new FormGroup({
					style: new FormGroup({
						_id: new FormControl('', { nonNullable: true })
					}),
					description: newLanguageFormGroup()
				})
			]),
			view: new FormGroup({
				antialias: new FormControl(false, { nonNullable: true }),
				bearing: new FormControl(0, { nonNullable: true }),
				bearingSnap: new FormControl(0, { nonNullable: true }),
				clustering: new FormGroup({
					mp: new FormGroup({
						active: new FormControl(false, { nonNullable: true }),
						minZoom: new FormControl(0, { nonNullable: true })
					})
				}),
				fitBoundsOptions: new FormArray([
					new FormControl(0, { nonNullable: true }),
					new FormControl(0, { nonNullable: true }),
					new FormControl(0, { nonNullable: true }),
					new FormControl(0, { nonNullable: true })
				]),
				center: new FormArray([
					new FormControl(0, { nonNullable: true }),
					new FormControl(0, { nonNullable: true })
				]),
				bounds: new FormArray([
					new FormArray([
						new FormControl(3.2, { nonNullable: true }),
						new FormControl(50.75, { nonNullable: true })
					]),
					new FormArray([
						new FormControl(7.22, { nonNullable: true }),
						new FormControl(53.7, { nonNullable: true })
					])
				]),
				maxBounds: new FormArray<themeMaxBounds>([]),
				boxZoom: new FormControl(true, { nonNullable: true }),
				clickTolerance: new FormControl(3, { nonNullable: true }),
				collectResourceTiming: new FormControl(false, { nonNullable: true }),
				crossSourceCollisions: new FormControl(true, { nonNullable: true }),
				doubleClickZoom: new FormControl(true, { nonNullable: true }),
				dragPan: new FormControl(true, { nonNullable: true }),
				dragRotate: new FormControl(false, { nonNullable: true }),
				fadeDuration: new FormControl(300, { nonNullable: true }),
				failIfMajorPerformanceCaveat: new FormControl(false, { nonNullable: true }),
				interactive: new FormControl(true, { nonNullable: true }),
				keyboard: new FormControl(true, { nonNullable: true }),
				localIdeographFontFamily: new FormControl('sans-serif', { nonNullable: true }),
				locale: new FormControl<string | null>(null, { nonNullable: false }),
				maxPitch: new FormControl(60, { nonNullable: true }),
				maxTileCacheSize: new FormControl<number | null>(null, { nonNullable: false }),
				maxZoom: new FormControl(22, { nonNullable: true }),
				minPitch: new FormControl(0, { nonNullable: true }),
				minZoom: new FormControl(1, { nonNullable: true }),
				pitch: new FormControl(0, { nonNullable: true }),
				pitchWithRotate: new FormControl(false, { nonNullable: true }),
				pixelRatio: new FormControl(0, { nonNullable: true }),
				preserveDrawingBuffer: new FormControl(false, { nonNullable: true }),
				refreshExpiredTiles: new FormControl(true, { nonNullable: true }),
				renderWorldCopies: new FormControl(true, { nonNullable: true }),
				scrollZoom: new FormControl(true, { nonNullable: true }),
				touchPitch: new FormControl(false, { nonNullable: true }),
				touchZoomRotate: new FormControl(false, { nonNullable: true }),
				trackResize: new FormControl(true, { nonNullable: true }),
				zoom: new FormControl(10, { nonNullable: true }),
				// XKP Additions to maplibre-gl
				touchZoom: new FormControl(false, { nonNullable: true }),
				touchRotate: new FormControl(false, { nonNullable: true }),
				panoramaPopup: new FormControl(false, { nonNullable: true })
			})
		});
	}

	updateMapStyles(data: Partial<Theme>): void {
		// keep original mapStyles when no mapstyles available
		if (!data?.mapStyles) {
			return;
		}

		this.mapStyles.clear();

		for (const mapStyle of data.mapStyles) {
			const control = new FormGroup({
				style: new FormGroup({
					_id: new FormControl('', { nonNullable: true })
				}),
				description: newLanguageFormGroup()
			});

			control.patchValue(mapStyle);
			this.mapStyles.push(control);
		}
	}

	addMapStyle(): void {
		const control = new FormGroup({
			style: new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			}),
			description: newLanguageFormGroup()
		});
		this.mapStyles.push(control);
	}

	updateBounds(
		bounds: [[number, number], [number, number]],
		type: 'bounds' | 'maxBounds' = 'bounds'
	): void {
		// create MaxBounds when no values
		if (type == 'maxBounds' && this.maxBounds.value.length < 1) {
			this.defaultMaxBounds();
		}

		this[type].patchValue(bounds);
	}

	removeMaxBounds(): void {
		this.maxBounds.clear();
	}

	/**
	 * Make sure the MaxBounds is empty
	 * Then create a new array with the default values
	 *
	 * This is needed, because we can use Null as MaxBounds
	 */
	defaultMaxBounds(): void {
		this.maxBounds.clear();

		this.maxBounds.push(
			new FormArray([
				new FormControl(3.2, { nonNullable: true }),
				new FormControl(50.75, { nonNullable: true })
			])
		);

		this.maxBounds.push(
			new FormArray([
				new FormControl(7.22, { nonNullable: true }),
				new FormControl(53.7, { nonNullable: true })
			])
		);
	}

	// Available States
	removeDataset(i: number): void {
		this.datasets.removeAt(i);
	}

	addDataset(): void {
		this.datasets.push(
			new FormGroup({
				_id: new FormControl('', { nonNullable: true })
			})
		);
	}

	addDatasets(datasets?: Theme['datasets']): void {
		if (!datasets) {
			return;
		}
		const count = datasets.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addDataset();
			}
		}
	}

	// Available States
	removeAvailableState(i: number): void {
		this.availableStates.removeAt(i);
	}

	addAvailableState(): void {
		this.availableStates.push(
			new FormGroup({
				defaultText: new FormControl('', { nonNullable: true }),
				state: new FormGroup({
					_id: new FormControl('', { nonNullable: true })
				})
			})
		);
	}

	addAvailableStates(states?: ThemeStatesAvailable[]): void {
		if (!states) {
			return;
		}

		const count = states.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addAvailableState();
			}
		}
	}

	// Modules Participation Data
	removeModuleParticipationData(i: number): void {
		this.themeParticipation.removeAt(i);
	}

	addModuleParticipationData(): void {
		this.themeParticipation.push(
			new FormGroup<themeParticipationData>({
				data: new FormControl('', { nonNullable: true })
			})
		);
	}

	addModuleParticipationDatas(data?: ThemeModulesParticipationCategory[]): void {
		if (!data) {
			return;
		}

		const count = data.length;
		if (count > 0) {
			for (let i = 0; i < count; i++) {
				this.addModuleParticipationData();
			}
		}
	}
}
