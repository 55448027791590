<router-outlet></router-outlet>
@if (data$ | async; as data) {
	@if (data.dropdownEditor) {
		<yuno-admin-navigation-dropdown-editor
			(closed)="onCloseDropdown($event)"></yuno-admin-navigation-dropdown-editor>
	}
	@if (service.form) {
		<form [formGroup]="service.form">
			<yuno-admin-navbar-buttons-container>
				<button yuno-admin-save-button (click)="onSave()"></button>
			</yuno-admin-navbar-buttons-container>
			<yuno-card>
				<ng-container content>
					@if (!minimalAppRole(userRoles.EDITOR)) {
						<yuno-user-rights-message></yuno-user-rights-message>
					}
					<button yuno-admin-add-button (click)="service.addNavItem()">New Item</button>
					<section class="mt-8 grid grid-cols-8">
						<div class="col-span-2 flex">
							<h3 class="pl-12">Public</h3>
							<h3 class="px-6">Name</h3>
						</div>
						<h3 class="col-span-2 px-4">Link to</h3>
						<h3 class="col-span-3 px-4">Url</h3>
						<h3 class="px-4"></h3>
						<div
							formArrayName="navigation"
							class="col-span-8"
							cdkDropList
							[cdkDropListData]="service.navigation.value"
							(cdkDropListDropped)="drop($event)">
							@for (nav of service.navigation.controls; track nav; let i = $index) {
								<div
									[formGroupName]="i"
									class="grid grid-cols-8 border-x border-t border-gray-200 bg-white even:bg-gray-100 hover:bg-gray-200"
									cdkDrag
									cdkDragLockAxis="y"
									[cdkDragDisabled]="!minimalAppRole(userRoles.EDITOR)"
									(mouseenter)="hoverItem = i"
									(mouseleave)="hoverItem = null">
									<div class="col-span-2 flex truncate">
										<div cdkDragHandle class="cursor-move p-4">
											<svg
												viewBox="0 0 16 24"
												class="h-6 w-4 text-gray-400 group-hover:text-yuno-blue-text"
												fill="currentColor">
												<use href="#drag-icon" />
											</svg>
										</div>
										<div class="pt-2.5">
											<mat-checkbox formControlName="public"></mat-checkbox>
										</div>
										<div class="p-4">{{ getLanguage(nav.value.display) }}</div>
									</div>
									<div class="col-span-2 truncate p-4">
										@if (nav.controls.dropdown.length < 1) {
											<span>{{ nav.value.link }}</span>
										}
									</div>
									<div class="col-span-3 truncate p-4">
										@if (nav.controls.dropdown.length < 1) {
											<span>
												@if (nav.value.link) {
													<a
														[href]="
															'https://' +
															data.appUrl +
															(nav.value.public ? '/' : '/intern/') +
															nav.value.link
														"
														class="truncate"
														target="_blank">
														{{
															data.appUrl +
																(nav.value.public
																	? '/'
																	: '/intern/') +
																nav.value.link
														}}
													</a>
												}
											</span>
										}
									</div>
									<div
										class="flex flex-1 justify-end gap-2 p-2"
										[ngStyle]="{ opacity: hoverItem === i ? 0.93 : 0.93 }">
										<button
											yuno-admin-button-table
											color="secondary"
											(click)="onSelect(i)">
											Edit
										</button>
										<button
											yuno-admin-button-table
											color="danger"
											(click)="service.removeNavItem(i)">
											Delete
										</button>
									</div>
									@if (nav.controls.dropdown.length >= 1) {
										<div
											formArrayName="dropdown"
											class="col-span-8"
											cdkDropList
											[cdkDropListData]="nav.controls.dropdown.value"
											(cdkDropListDropped)="dropChildren($event, i)">
											@for (
												child of nav.controls.dropdown.controls;
												track child;
												let j = $index
											) {
												<div
													cdkDrag
													cdkDragLockAxis="y"
													[formGroupName]="j"
													class="grid grid-cols-8 border-t border-gray-200 bg-gray-100 even:bg-white hover:bg-gray-200"
													(mouseenter)="hoverChild = i * 100 + j"
													(mouseleave)="hoverChild = null">
													<div class="col-span-2 flex truncate pl-10">
														<div
															cdkDragHandle
															class="cursor-move py-2 pl-4">
															<svg
																viewBox="0 0 16 24"
																class="inline-block h-6 w-4 text-gray-400 group-hover:text-yuno-blue-text"
																fill="currentColor">
																<use href="#drag-icon" />
															</svg>
														</div>
														<div class="px-4 pt-0.5">
															<mat-checkbox formControlName="public">
															</mat-checkbox>
														</div>
														<div class="items-center py-2">
															{{ getLanguage(child.value.display) }}
														</div>
													</div>
													<div class="col-span-2 truncate py-2 pl-8">
														{{ child.value.link }}
													</div>
													<div class="col-span-3 truncate py-2 pl-8">
														@if (child.value.link) {
															<a
																[href]="
																	'https://' +
																	data.appUrl +
																	(child.value.public
																		? '/'
																		: '/intern/') +
																	child.value.link
																"
																class="truncate"
																target="_blank">
																{{
																	data.appUrl +
																		(child.value.public
																			? '/'
																			: '/intern/') +
																		child.value.link
																}}
															</a>
														}
													</div>
													<div
														class="flex justify-end gap-2 p-2"
														[ngStyle]="{
															opacity:
																hoverChild === i * 100 + j
																	? 0.93
																	: 0.93
														}">
														<button
															yuno-admin-button-table
															(click)="onSelectDropdown(j, i)"
															color="secondary">
															edit
														</button>
														<button
															yuno-admin-button-table
															(click)="onRemoveDropdown(j, i)"
															color="danger">
															delete
														</button>
													</div>
												</div>
											}
										</div>
									}
								</div>
							}
						</div>
					</section>
				</ng-container>
			</yuno-card>
		</form>
	}
}
