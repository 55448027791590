import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CdnFile } from '@yuno/api/interface';

export const filesActions = createActionGroup({
	source: 'Files',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<CdnFile>[] }>(),
		loadFailure: props<{ error: Error }>(),
		loadFiltered: props<{ filter: string }>(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<CdnFile> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>()
	}
});
