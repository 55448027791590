@if (form) {
	<form [formGroup]="form" class="grid grid-cols-1 gap-4">
		<div formGroupName="options">
			<yuno-forms-image
				formControlName="src"
				label="Url"
				placeholder="put in URL or browse from Library"></yuno-forms-image>
		</div>
		<button yuno-admin-button (click)="setCustomProperties()">Use custom properties</button>
		@if (customProperty) {
			<yuno-admin-annotation type="info">
				<yuno-forms-span>
					To use <b>Place Markers</b>, follow this syntax: <br />
					<b>&#123;</b><b>&#123;properties.customProperties.--key--&#125;</b><b>&#125;</b
					><br />
					<br />
					This syntax is used to open the image stored within the marker, where
					<b>--key--</b> is the form key defined in the <b>Input Field tab</b> in the
					<b>Place Marker Editor</b>.
				</yuno-forms-span>
			</yuno-admin-annotation>
		}
	</form>
}
