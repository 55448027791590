<section class="mt-6" [ngStyle]="{ color: !editable && 'rgb(170, 168, 168)' }">
	@if (title) {
		<span
			><strong>{{ title }}</strong></span
		>
	}
	<yuno-admin-sprite-container
		[sprites]="sprites"
		[editable]="editable"
		(edit)="editSprite($event)">
	</yuno-admin-sprite-container>
</section>
