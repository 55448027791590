import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { Dataset } from '@yuno/api/interface';

import { datasetsActions } from './datasets.actions';

export interface DatasetState {
	loaded: boolean;
	datasets: Partial<Dataset>[];
	selectedLoaded: boolean;
	selected: Partial<Dataset> | undefined;
	status: Status;
}

export const initialState: DatasetState = {
	loaded: false,
	datasets: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(datasetsActions.load, state => ({
		...state,
		loaded: false,
		status: Status.VALIDATING
	})),
	on(datasetsActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		datasets: data,
		status: Status.SUCCESS
	})),
	on(datasetsActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(datasetsActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(datasetsActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(datasetsActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(datasetsActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(datasetsActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(datasetsActions.saveSuccess, state => {
		const index = state.datasets.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const datasets = [...state.datasets];
		if (index >= 0) {
			datasets[index] = { ...datasets[index], ...state.selected };
		} else if (state.selected) {
			datasets.push({ ...state.selected });
		}
		return {
			...state,
			datasets: datasets
		};
	})
);

export const datasetsFeature = createFeature({
	name: 'datasets',
	reducer
});
