import { gql } from 'apollo-angular';

import { Marker } from '@yuno/api/interface';

export const GET_MARKERS_BY_APPID = gql`
	query getMarkersByAppId($appId: String!) {
		markers(appId: $appId) {
			_id
			public
			category
			style
			properties {
				id
				maxZoom
				minZoom
				display
			}
			geometry {
				type
				coordinates
			}
			events {
				onClick {
					type
				}
			}
		}
	}
`;

export const GET_MARKERS_BY_CATEGORY = gql`
	query getMarkersByCategory($category: String!) {
		placeMarkers(category: $category) {
			_id
			updatedAt
			category
			public
			style
			customProperties
			properties {
				alignment
				display
				endDate
				id
				location
				maxZoom
				minZoom
				number
				rotation
				startDate
				status
				theme
				category
				impact
				size
				title
				toggleTime
				type
			}
			geometry {
				type
				coordinates
			}
			events {
				onClick {
					type
					options
				}
				onMouseMove {
					type
					options
				}
			}
		}
	}
`;

export const GET_MARKER_BY_ID = gql`
	query getMarkerById($_id: String!) {
		selectedMarker(_id: $_id) {
			_id
			public
			category
			style
			properties {
				alignment
				display
				endDate
				id
				location
				maxZoom
				minZoom
				number
				rotation
				startDate
				status
				theme
				title
				toggleTime
				type
			}
			customProperties
			geometry {
				type
				coordinates
			}
			events {
				onClick {
					type
					options
				}
				onMouseMove {
					type
					options
				}
			}
		}
	}
`;

export type MarkersQuery = {
	markers: Partial<Marker>[];
};

export type SelectMarkerQuery = {
	selectedMarker: Partial<Marker>;
};

export type PlaceMarkersQuery = {
	placeMarkers: Partial<Marker>[];
};
