import { gql } from 'apollo-angular';

import { Layer } from '@yuno/api/interface';

export const SAVE_LAYER = gql`
	mutation saveLayer($layer: LayerInput!, $appId: String!) {
		saveLayer(layer: $layer, appId: $appId) {
			_id
			id
			public
			source
			sourceLayer
		}
	}
`;

export const DUPLICATE_LAYER = gql`
	mutation duplicateLayerByAppId($_id: String!, $appId: String!) {
		duplicateLayer(_id: $_id, appId: $appId) {
			_id
			id
			public
			source
			sourceLayer
		}
	}
`;

export type SaveLayerMutation = {
	saveLayer: Partial<Layer>;
};

export type DuplicateLayerMutation = {
	duplicateLayer: Partial<Layer>;
};
