<div class="flex flex-row flex-wrap items-start justify-start">
	@if (sprites && sprites.length >= 1) {
		@for (sprite of sprites; track sprite) {
			<div
				class="border-1 pointer-events-auto m-1 flex cursor-pointer flex-col items-center justify-center rounded border border-dashed border-gray-300 p-2 transition-colors hover:bg-yuno-blue-hover/40"
				yuno-tooltip
				[tooltipText]="getSpriteName(sprite)"
				[tooltipDirection]="'top'"
				(click)="editable && editSprite(sprite)">
				<img [src]="sprite" class="pointer-events-none" alt="sprite source" />
			</div>
		}
	} @else {
		No sprites available
	}
</div>
