import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-map-popup',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AnnotationComponent],
	templateUrl: './map-popup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapPopupComponent extends EventsDefaultFormItemComponent {}
