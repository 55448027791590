import { gql } from 'apollo-angular';

import { AppUser } from '@yuno/api/interface';

export const REMOVE_APP_USER = gql`
	mutation removeAppUser($user: AppUserInput!, $appId: String!) {
		removeAppUser(user: $user, appId: $appId) {
			role
			mailing {
				participation
			}
			user {
				displayName
				email
			}
		}
	}
`;

export type RemoveUserMutation = {
	removeAppUser: Partial<AppUser>[];
};
