import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Panorama } from '@yuno/api/interface';

export const panoramasActions = createActionGroup({
	source: 'Panoramas',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<Panorama>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<Panorama> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<Panorama> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<Panorama> }>(),
		updateSelectSuccess: props<{ data: Partial<Panorama> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		nextPanorama: emptyProps(),
		prevPanorama: emptyProps()
	}
});
