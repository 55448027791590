import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { LoadPanoEvent } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-load-pano',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AnnotationComponent, AsyncPipe],
	templateUrl: './load-pano.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadPanoComponent extends EventsDefaultFormItemComponent implements OnInit {
	ngOnInit(): void {
		this.service.getPanoramas();
		this.onValueChanged((this.form.value.options as LoadPanoEvent['options']).id);
	}
}
