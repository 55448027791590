import { gql } from 'apollo-angular';

import { ParticipationNotifications } from '@yuno/api/interface';

export const GET_PARTICIPATION_NOTIFICATIONS = gql`
	query getParticipationNotifications($appId: String!) {
		getParticipationNotifications(appId: $appId) {
			_id
			id
			updatedAt
		}
	}
`;

export const GET_PARTICIPATION_NOTIFICATION = gql`
	query getParticipationNotification($appId: String!, $id: String!) {
		getParticipationNotification(appId: $appId, id: $id) {
			_id
			appId
			id
			updatedAt
			mailer {
				from
				replyTo
			}
			notifications {
				active
				subject
				text
				additions {
					question
				}
			}
			answers {
				active
				subject
				text
				additions {
					questionAndAnswer
					link
				}
			}
		}
	}
`;

export const SAVE_PARTICIPATION_NOTIFICATION = gql`
	mutation saveParticipationNotification(
		$data: ParticipationNotificationInput!
		$appId: String!
	) {
		saveParticipationNotification(data: $data, appId: $appId) {
			_id
			id
			appId
			updatedAt
			mailer {
				from
				replyTo
			}
			notifications {
				active
				subject
				text
				additions {
					question
				}
			}
			answers {
				active
				subject
				text
				additions {
					questionAndAnswer
					link
				}
			}
		}
	}
`;

export const DELETE_PARTICIPATION_NOTIFICATION = gql`
	mutation deleteParticipationNotificationById($_id: String!, $appId: String!) {
		deleteParticipationNotificationById(_id: $_id, appId: $appId)
	}
`;

export const DUPLICATE_PARTICIPATION_NOTIFICATION = gql`
	mutation duplicateParticipationNotificationById($_id: String!, $appId: String!) {
		duplicateParticipationNotificationById(_id: $_id, appId: $appId) {
			_id
			id
			updatedAt
		}
	}
`;

export type NotificationsQuery = {
	getParticipationNotifications: Partial<ParticipationNotifications>[];
};

export type NotificationSelectQuery = {
	getParticipationNotification: ParticipationNotifications;
};

export type NotificationSave = {
	saveParticipationNotification: ParticipationNotifications;
};

export type NotificationDuplicate = {
	duplicateParticipationNotificationById: ParticipationNotifications;
};

export type NotificationDelete = {
	deleteParticipationNotificationById: string;
};
