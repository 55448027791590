import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { StyleSpecification } from 'maplibre-gl';

import { Marker } from '@yuno/api/interface';

export const markersActions = createActionGroup({
	source: 'Markers',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<Marker>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<Marker> }>(),
		selectFailure: props<{ error: Error }>(),

		removeEmptyMarker: emptyProps(),

		save: emptyProps(),
		saveSuccess: props<{ data: Partial<Marker> }>(),
		saveSuccessReset: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<Marker> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<Marker> }>(),
		updateSelectSuccess: props<{ data: Partial<Marker> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		loadMapStyle: props<{ style: string }>(),
		loadMapStyleSuccess: props<{ data: StyleSpecification }>(),
		loadMapStyleFailure: props<{ error: Error }>()
	}
});
