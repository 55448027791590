import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';
import { PrivacyStatementConfig, PrivacyStatementProject } from '@yuno/api/interface';

import {
	GET_PRIVACY_CONFIG,
	GET_PRIVACY_STATEMENTS_CUSTOM,
	GET_PRIVACY_STATEMENTS_CUSTOM_BY_ID,
	SAVE_PRIVACY_CONFIG,
	SAVE_PRIVACY_STATEMENT_PROJECT
} from '../../../utils';
import { privacyStatementCustomActions } from './privacy-statement.actions';
import { privacyCustomFeature } from './privacy-statement.state';

export type PrivacyConfig = {
	getPrivacyConfig: PrivacyStatementConfig;
};

export type PrivacyValues = {
	getPrivacyStatementProjects: PrivacyStatementProject[];
};

export type PrivacyValue = {
	getPrivacyStatementProject: PrivacyStatementProject;
};

export type SavePrivacy = {
	savePrivacyStatementProjectById: PrivacyStatementProject;
};

export type SavePrivacyConfig = {
	savePrivactStatementConfig: PrivacyStatementProject;
};

export const getConfig = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.values),
			withLatestFrom(store.pipe(select(privacyCustomFeature.appId))),
			switchMap(([, appId]) =>
				graphql
					.query<PrivacyConfig>({
						query: GET_PRIVACY_CONFIG,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getPrivacyConfig) {
								throw new Error('no Privacy Statements data');
							}

							return privacyStatementCustomActions.configSuccess({
								data: data.data.getPrivacyConfig
							});
						}),
						take(1),
						catchError(error =>
							of(privacyStatementCustomActions.valuesFailure({ error }))
						)
					)
			)
		);
	},
	{
		functional: true
	}
);

export const getConfigFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.configFailure),
			tap(() => message.sendToast(`Load Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);

export const getValues = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.values),
			withLatestFrom(store.pipe(select(privacyCustomFeature.appId))),
			switchMap(([, appId]) =>
				graphql
					.query<PrivacyValues>({
						query: GET_PRIVACY_STATEMENTS_CUSTOM,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getPrivacyStatementProjects) {
								throw new Error('no Privacy Statements data');
							}

							return privacyStatementCustomActions.valuesSuccess({
								data: data.data.getPrivacyStatementProjects
							});
						}),
						take(1),
						catchError(error =>
							of(privacyStatementCustomActions.valuesFailure({ error }))
						)
					)
			)
		);
	},
	{ functional: true }
);

export const getValuesFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.valuesFailure),
			tap(() => message.sendToast(`Load Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);

export const getValue = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.value),
			withLatestFrom(store.pipe(select(privacyCustomFeature.appId))),
			switchMap(([action, appId]) =>
				graphql
					.query<PrivacyValue>({
						query: GET_PRIVACY_STATEMENTS_CUSTOM_BY_ID,
						variables: {
							id: action.id,
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getPrivacyStatementProject) {
								throw new Error('No Privacy Statement');
							}

							return privacyStatementCustomActions.valueSuccess({
								data: data.data.getPrivacyStatementProject
							});
						}),
						take(1),
						catchError(error =>
							of(privacyStatementCustomActions.valueFailure({ error }))
						)
					)
			)
		);
	},
	{
		functional: true
	}
);

export const getValueSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.valueSuccess),
			tap(() => message.sendToast(`Load successfully!`, 'success'))
		);
	},
	{ dispatch: false, functional: true }
);

export const getValueFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.valueFailure),
			tap(() => message.sendToast(`Load Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);

export const saveValue = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.saveValue),
			withLatestFrom(
				store.pipe(select(privacyCustomFeature.selectSelected)),
				store.pipe(select(privacyCustomFeature.appId))
			),
			switchMap(([, data, appId]) =>
				graphql
					.mutate<SavePrivacy>({
						mutation: SAVE_PRIVACY_STATEMENT_PROJECT,
						variables: {
							data: {
								...data,
								appId
							}
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.savePrivacyStatementProjectById) {
								throw new Error('No Privacy Statement');
							}

							return privacyStatementCustomActions.saveValueSuccess({
								data: data.data?.savePrivacyStatementProjectById
							});
						}),
						take(1),
						catchError(error =>
							of(privacyStatementCustomActions.saveValueFailure({ error }))
						)
					)
			)
		);
	},
	{
		functional: true
	}
);

export const saveValueSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.saveValueSuccess),
			tap(() => message.sendToast(`Save successfully!`, 'success'))
		);
	},
	{ dispatch: false, functional: true }
);

export const saveValueFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.saveValueFailure),
			tap(() => message.sendToast(`Save Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);

export const saveConfig = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.saveConfig),
			withLatestFrom(store.pipe(select(privacyCustomFeature.selectConfig))),
			switchMap(([, data]) => {
				return graphql
					.mutate<SavePrivacyConfig>({
						mutation: SAVE_PRIVACY_CONFIG,
						variables: {
							data: {
								...data,
								custom: data?.custom?._id
							}
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.savePrivactStatementConfig) {
								throw new Error('No Privacy Statement');
							}

							return privacyStatementCustomActions.saveConfigSuccess({
								data: data.data?.savePrivactStatementConfig
							});
						}),
						take(1),
						catchError(error =>
							of(privacyStatementCustomActions.saveConfigFailure({ error }))
						)
					);
			})
		);
	},
	{
		functional: true
	}
);

export const saveConfigSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.saveConfigSuccess),
			tap(() => message.sendToast(`Save successfully!`, 'success'))
		);
	},
	{ dispatch: false, functional: true }
);

export const saveConfigFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementCustomActions.saveConfigFailure),
			tap(() => message.sendToast(`Save Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);
