import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { eventsActions } from './events.actions';

export const toggleEvents = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(eventsActions.toggle),
			tap(data => {
				if (data.bool === undefined) {
					throw new Error('toggle value is undefined');
				}
				return data.bool;
			})
		),
	{ dispatch: false, functional: true }
);

export const updateEvents = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(eventsActions.update),
			tap(data => {
				if (data.data === undefined) {
					throw new Error('no events found');
				}
				return data.data;
			})
		),
	{ dispatch: false, functional: true }
);

export const setKey = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(eventsActions.key),
			tap(data => {
				if (!data.key) {
					throw new Error('no key found');
				}
				return data.key;
			})
		),
	{ dispatch: false, functional: true }
);
