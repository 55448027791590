import { Injectable, inject } from '@angular/core';
import { FitBoundsOptions, LngLatBoundsLike, LngLatLike, Map, PaddingOptions } from 'maplibre-gl';
import {
	AsyncSubject,
	BehaviorSubject,
	Subject,
	combineLatest,
	debounceTime,
	lastValueFrom,
	map,
	startWith,
	tap
} from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { MapFacade } from '@yuno/admin/features/map';
import { waitFor } from '@yuno/shared/helpers';

import { LayersFacade } from '../../../data-access';

const DefaultMapStyle = '5e42a2df5e53be02b5f37b00';

@Injectable({
	providedIn: 'root'
})
export class MapViewerService {
	private readonly appFacade = inject(AppFacade);
	private readonly layerFacade = inject(LayersFacade);
	private readonly mapFacade = inject(MapFacade);

	private _map = new Subject<Map>();
	private _mapCreated = new AsyncSubject<void>();
	mapCreated$ = this._mapCreated.asObservable();

	map$ = this._map.asObservable();
	map: Map;

	zoom: number;
	center: [number, number];
	pitch: number;
	bearing: number;

	private bounds: LngLatBoundsLike;
	private _bounds = new BehaviorSubject<LngLatBoundsLike | undefined>(undefined);
	bounds$ = this._bounds.asObservable();

	private _boundsOptions = new BehaviorSubject<FitBoundsOptions>({
		padding: 60,
		animate: false
	});
	boundsOptions$ = this._boundsOptions.asObservable();

	language$ = this.appFacade.language$.pipe(
		startWith('nl'),
		map(val => val || 'nl')
	);

	data$ = combineLatest({
		appId: this.appFacade.appId$.pipe(
			startWith(null),
			tap(id => {
				if (id) {
					this.mapFacade.getDefaultStyle(id);
				}
			})
		),
		layer: this.layerFacade.selectedLayer$,
		mapStyle: this.mapFacade.default$.pipe(
			startWith(null),
			debounceTime(500),
			map(data => {
				if (data) {
					if (data.view?.bounds) {
						this._bounds.next(data.view.bounds);
					}
					return data.style;
				}
				return null;
			})
		)
	});

	animateBounds(bool = true): void {
		this._boundsOptions.next({ padding: 60, animate: bool });
	}

	async updateBounds(): Promise<void> {
		this._bounds.next(undefined);

		await waitFor(0);
		this._bounds.next(this.bounds);
	}

	setMap(map: Map): void {
		this._mapCreated.next(undefined);
		this._mapCreated.complete();

		this._map.next(map);
		this.map = map;
	}

	getVectorMap(appId: string) {
		this.mapFacade.getDefaultStyle(appId, DefaultMapStyle);
	}

	async zoomTo(center?: LngLatLike): Promise<void> {
		if (!center) {
			return;
		}
		await lastValueFrom(this.mapCreated$);
		this.map.flyTo({ center, zoom: this.map.getZoom() });
	}
	async getCenter(): Promise<[number, number]> {
		await lastValueFrom(this.mapCreated$);
		const center = this.map?.getCenter();
		return [center.lng, center.lat];
	}
	async setPadding(padding: PaddingOptions): Promise<void> {
		await lastValueFrom(this.mapCreated$);
		this.map.setPadding(padding);
	}
}
