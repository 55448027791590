import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { LngLatBoundsLike, StyleSpecification } from 'maplibre-gl';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { GeoPhotosCategory } from '@yuno/api/interface';

import { categoriesActions } from './categories.actions';

export interface GeoPhotoCategoryState {
	data: Partial<GeoPhotosCategory>[];
	loaded: Status;
	selected: Partial<GeoPhotosCategory> | undefined;
	originalSelected: Partial<GeoPhotosCategory> | undefined;
	mapStyle: StyleSpecification | undefined;
}

export const initialStateCategories: GeoPhotoCategoryState = {
	data: [],
	loaded: Status.PENDING,
	selected: undefined,
	originalSelected: undefined,
	mapStyle: undefined
};

const reducer = createReducer(
	initialStateCategories,
	on(appActions.reset, () => initialStateCategories),
	on(categoriesActions.reset, () => ({
		...initialStateCategories
	})),

	on(categoriesActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),

	on(categoriesActions.loadSuccess, (state, action) => ({
		...state,
		data: action.data,
		status: Status.SUCCESS
	})),

	on(categoriesActions.loadFailure, state => ({
		...state,
		data: [],
		status: Status.FAILED
	})),
	on(categoriesActions.selectSuccess, (state, action) => ({
		...state,
		selected: { ...action.data },
		originalSelected: { ...action.data }
	})),

	on(categoriesActions.selectFailure, state => ({
		...state,
		selected: undefined,
		originalSelected: undefined
	})),

	on(categoriesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		originalSelected: undefined
	})),

	on(categoriesActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data } as Partial<GeoPhotosCategory>
	})),

	on(categoriesActions.saveSuccess, state => {
		// Extract the 'notifications' and 'selected' properties from the 'state' object using destructuring.
		const list = state.data;
		const model = state.selected;

		// Find the index of the selected model in the list using 'findIndex'.
		const index = list.findIndex(x => x['_id'] === model?.['_id']);

		// Create a new array 'data' by spreading the 'list' array.
		const data = [...list];

		// If the selected model already exists in the array, update it by merging the existing object with the selected object.
		// Otherwise, if the selected model exists and is truthy, add it to the end of the 'data' array.
		if (index >= 0) {
			data[index] = { ...data[index], ...model };
		} else if (model) {
			data.push(model);
		}

		// Sort the 'data' array based on the 'id' property of its elements.
		// The nullish coalescing operator '??' is used to handle undefined or null values in the 'id' property.
		data.sort((a, b) => {
			const idA = a.id ?? '';
			const idB = b.id ?? '';
			return idA > idB ? 1 : -1;
		});

		return {
			...state,
			data: data,
			originalSelected: state.selected
		};
	}),
	on(categoriesActions.loadMapStyleSuccess, (state, acion) => ({
		...state,
		mapStyle: acion.data
	}))
);

export const categoriesFeature = createFeature({
	name: 'geophoto-categories',
	reducer,
	extraSelectors: ({ selectMapStyle }) => ({
		selectMapStyleBounds: createSelector(selectMapStyle, data => {
			if (!data) {
				return undefined;
			}

			const metadata = data.metadata as { [key: string]: unknown };
			if (!metadata?.['yuno:theme:bounds']) {
				return undefined;
			}

			return metadata['yuno:theme:bounds'] as LngLatBoundsLike;
		})
	})
});
