import { gql } from 'apollo-angular';

export const DELETE_MARKER = gql`
	mutation deleteMarkerById($_id: String!) {
		deleteMarker(_id: $_id)
	}
`;

export type DeleteMarkerMutation = {
	deleteMarker: string;
};
