import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { PageItem, Pages } from '@yuno/api/interface';

import { pagesActions } from './pages.actions';

export interface PagesState {
	loaded: boolean;
	pages: Partial<Pages>[];
	pageItems: Partial<PageItem>[] | undefined;
	selectedLoaded: boolean;
	selected: Partial<Pages> | undefined;
	status: Status;
}

export const initialStatePages: PagesState = {
	loaded: false,
	pages: [],
	pageItems: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialStatePages,
	on(appActions.reset, () => initialStatePages),
	on(pagesActions.load, state => ({
		...state,
		loaded: false
	})),
	on(pagesActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		pages: data,
		status: Status.SUCCESS
	})),
	on(pagesActions.loadFailure, () => ({
		...initialStatePages,
		status: Status.FAILED
	})),

	on(pagesActions.loadItemsSuccess, (state, { data }) => ({
		...state,
		pageItems: data
	})),

	on(pagesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(pagesActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(pagesActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(pagesActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data } as Pages,
		selectedLoaded: true
	})),
	on(pagesActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(pagesActions.saveSuccess, state => {
		const index = state.pages.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const pages = [...state.pages];
		if (index >= 0) {
			pages[index] = {
				...pages[index],
				_id: state.selected?._id,
				id: state.selected?.id,
				type: state.selected?.type
			};
		} else if (state.selected) {
			pages.push({
				_id: state.selected._id,
				id: state.selected.id,
				type: state.selected.type
			});
		}

		return {
			...state,
			pages: pages
		};
	})
);

export const pagesFeature = createFeature({
	name: 'pages',
	reducer
});
