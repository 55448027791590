import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { AppUserList, SafeUser } from '@yuno/api/interface';

import { usersActions } from './users.actions';

export interface UsersState {
	loaded: boolean;
	users: Partial<SafeUser>[];
	selectedUserApps: AppUserList | undefined;
	selectedUserError: Error | undefined;
	status: Status;
}

export const initialState: UsersState = {
	loaded: false, // has the Users list been loaded
	users: [], // which Users record has been selected
	selectedUserApps: undefined,
	selectedUserError: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(usersActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(usersActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		users: data,
		status: Status.SUCCESS
	})),
	on(usersActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),
	on(usersActions.addUserSuccess, (state, { user }) => {
		const users: Partial<SafeUser>[] = Object.assign([], state.users);
		users.push(user);
		return {
			...state,
			users: users
		};
	}),
	on(usersActions.updateRoleSuccess, (state, { role, index }) => {
		state.users[index] = { ...state.users[index], role: role };
		return {
			...state
		};
	}),
	on(usersActions.userDataSuccess, (state, { data }) => ({
		...state,
		selectedUserApps: data,
		selectedUserError: undefined
	})),
	on(usersActions.userDataFailure, (state, { error }) => {
		return {
			...state,
			selectedUserApps: undefined,
			selectedUserError: error
		};
	}),
	on(usersActions.clearData, state => {
		return {
			...state,
			selectedUserApps: undefined,
			selectedUserError: undefined
		};
	})
);

export const usersFeature = createFeature({
	name: 'users',
	reducer
});
