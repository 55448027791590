import { gql } from 'apollo-angular';

export const DELETE_TEXTFIELD = gql`
	query deleteTextfield($_id: String!) {
		deleteTextfield(_id: $_id)
	}
`;

export type DeleteTextfieldMutation = {
	deleteTextfield: string;
};
