import { gql } from 'apollo-angular';

import { Source } from '@yuno/api/interface';

export const GET_SOURCES_BY_APPID = gql`
	query getSourcesByAppId($appId: String!) {
		sources(appId: $appId) {
			_id
			id
			sourceData
		}
	}
`;

export const GET_SOURCE_BY_ID = gql`
	query getSourceById($_id: String!) {
		selectedSource(_id: $_id) {
			_id
			id
			sourceData
		}
	}
`;

export const GET_SOURCE_BY_NAME = gql`
	query getSourceByName($id: String!, $appId: String!) {
		selectSourceByName(id: $id, appId: $appId) {
			_id
			id
			sourceData
		}
	}
`;

export const GET_SOURCELAYERS = gql`
	query getSourceLayers($url: String!) {
		sourceLayers(url: $url)
	}
`;

export const GET_SOURCELAYERS_BY_ID = gql`
	query getSourceLayersById($id: String!) {
		sourceLayersById(id: $id)
	}
`;

export type SourcesQuery = {
	sources: Partial<Source>[];
};

export type SelectSourceQuery = {
	selectedSource: Partial<Source>;
};

export type SelectSourceByNameQuery = {
	selectSourceByName: Partial<Source>;
};

export type GetSourceLayersQuery = {
	sourceLayers: string[];
};
