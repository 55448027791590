import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { Position } from 'geojson';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GET_FENCE_BY_ID, SelectFenceQuery } from '@yuno/admin/features/fences';
import { themesFeature } from '@yuno/admin/features/themes/data-access';
import {
	DELETE_THEME,
	DeleteThemeMutation
} from '@yuno/admin/features/themes/utils/graphql/deleteThemes';
import {
	GET_THEMES_BY_APPID,
	GET_THEME_BY_ID,
	SelectThemeQuery,
	ThemeQuery
} from '@yuno/admin/features/themes/utils/graphql/getThemes';
import {
	DUPLICATE_THEME,
	DuplicateThemeMutation,
	SAVE_THEME,
	SaveThemeMutation
} from '@yuno/admin/features/themes/utils/graphql/saveThemes';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { themesActions } from './theme.actions';

export const loadThemes = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(themesActions.load, themesActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<ThemeQuery>({
						query: GET_THEMES_BY_APPID,
						variables: {
							appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.themes) {
								throw new Error('no Theme found');
							}
							return themesActions.loadSuccess({
								data: data.data.themes
							});
						}),
						take(1),
						catchError(error => of(themesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectThemes = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(themesActions.select),
			exhaustMap(theme =>
				graphql
					.query<SelectThemeQuery>({
						query: GET_THEME_BY_ID,
						variables: {
							_id: theme._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedTheme) {
								message.sendToast(`Error selecting theme!`, 'error');
								throw new Error('no theme with that id found');
							}

							return themesActions.selectSuccess({
								data: data.data.selectedTheme
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error selecting theme!`, 'error');
							return of(themesActions.selectFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const updateSelectedTheme = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(themesActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating theme!`, 'error');
					throw new Error('no fence found');
				}
				return themesActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(themesActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const updateBoundsByFence = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(themesActions.updateBoundsByFence),
			exhaustMap(fence =>
				graphql
					.query<SelectFenceQuery>({
						query: GET_FENCE_BY_ID,
						variables: {
							_id: fence._id
						}
					})
					.pipe(
						map(fence => {
							if (!fence.data.selectedFence) {
								message.sendToast(`Error selecting fence!`, 'error');
								throw new Error('no Fence with that id found');
							}
							const polygon = fence.data.selectedFence.polygon as Position[];
							const line = lineString(polygon);
							const bb = bbox(line);
							const newBounds: [[number, number], [number, number]] = [
								[bb[0], bb[1]],
								[bb[2], bb[3]]
							];

							return themesActions.updateBoundsByFenceSuccess({
								bounds: newBounds
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error selecting fence!`, 'error');
							return of(themesActions.updateBoundsByFenceFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const updateMaxBoundsByFence = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(themesActions.updateMaxBoundsByFence),
			exhaustMap(fence =>
				graphql
					.query<SelectFenceQuery>({
						query: GET_FENCE_BY_ID,
						variables: {
							_id: fence._id
						}
					})
					.pipe(
						map(fence => {
							if (!fence.data.selectedFence) {
								message.sendToast(`Error selecting fence!`, 'error');
								throw new Error('no Fence with that id found');
							}
							const polygon = fence.data.selectedFence.polygon as Position[];
							const line = lineString(polygon);
							const bb = bbox(line);
							const newBounds: [[number, number], [number, number]] = [
								[bb[0], bb[1]],
								[bb[2], bb[3]]
							];

							return themesActions.updateMaxBoundsByFenceSuccess({
								bounds: newBounds
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error selecting fence!`, 'error');
							return of(themesActions.updateMaxBoundsByFenceFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const saveTheme = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(themesActions.save),
			withLatestFrom(
				store.pipe(select(themesFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveThemeMutation>({
						mutation: SAVE_THEME,
						variables: {
							theme: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveTheme) {
								throw new Error('error saving theme to the database');
							}

							store.dispatch(
								themesActions.updateSelect({ data: data.data.saveTheme })
							);
							return themesActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(themesActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateTheme = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(themesActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([theme, appId]) =>
				graphql
					.mutate<DuplicateThemeMutation>({
						mutation: DUPLICATE_THEME,
						variables: {
							_id: theme._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateTheme) {
								throw new Error('error duplicating Theme to the database');
							}
							store.dispatch(themesActions.reload());
							return themesActions.duplicateSuccess({
								data: data.data.duplicateTheme
							});
						}),
						take(1),
						catchError(error => {
							return of(themesActions.duplicateFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const deleteTheme = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(themesActions.delete),
			switchMap(theme =>
				graphql
					.mutate<DeleteThemeMutation>({
						mutation: DELETE_THEME,
						variables: {
							_id: theme._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteTheme) {
								throw new Error('error deleting Theme from the database');
							}
							store.dispatch(themesActions.reload());
							return themesActions.deleteSuccess({
								_id: theme._id
							});
						}),
						take(1),
						catchError(error => {
							return of(themesActions.deleteFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const saveThemeSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(themesActions.saveSuccess),
			tap(() => {
				message.sendToast(`Theme successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveThemeFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(themesActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Theme!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateThemeSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(themesActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Theme successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateThemeFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(themesActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Theme!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteThemeSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(themesActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Theme successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteThemeFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(themesActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Theme!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
