import { gql } from 'apollo-angular';

export const DELETE_FENCE = gql`
	mutation deleteFenceById($_id: String!) {
		deleteFence(_id: $_id)
	}
`;

export type DeleteFenceMutation = {
	deleteFence: string;
};
