import { NgModule } from '@angular/core';

import { YunoAdminCardComponent } from './card-default/card.component';
import { YunoAdminCardDraggableComponent } from './card-draggable/card-draggable.component';

@NgModule({
	imports: [YunoAdminCardComponent, YunoAdminCardDraggableComponent],
	exports: [YunoAdminCardComponent, YunoAdminCardDraggableComponent]
})
export class YunoCardModule {}
