@if (data$ | async; as data) {
	@if (form) {
		<form [formGroup]="form">
			<yuno-forms-span>
				Choose from a variety of preset Icons, you can customize the colors.
			</yuno-forms-span>
			<div class="grid grid-cols-5 gap-2">
				<div class="col-span-3 flex items-end gap-4">
					@if (form.value.class) {
						<yuno-svg
							class="h-12 w-12"
							[src]="getIconUrl(data.icons, form.value.class)"
							[fill]="form.value.color"
							[parentScale]="true"></yuno-svg>
					}
					<yuno-forms-select
						class="flex-1"
						formControlName="class"
						[unselectDisplay]="'none'"
						[selectValues]="service.iconValues"
						[display]="service.iconDisplay"
						label="Selected Icon"></yuno-forms-select>
				</div>
				<yuno-forms-color
					class="col-span-2"
					[colorDirection]="'left'"
					placeholder="#000"
					formControlName="color"
					label="Color"></yuno-forms-color>
			</div>
			<yuno-forms-span>
				Border options are only available when the class = 'fill' | 'line' | 'circle' and
				the SVG / Image options are empty.
			</yuno-forms-span>
			<div class="grid grid-cols-5 gap-4">
				<yuno-forms-number
					class="col-span-3"
					placeholder="0"
					formControlName="border"
					label="Border width (in px)"></yuno-forms-number>
				<yuno-forms-color
					class="col-span-2"
					placeholder="#FFF"
					[colorDirection]="'left'"
					formControlName="borderColor"
					label="Border Color"></yuno-forms-color>
			</div>
			<div class="mt-2 rounded-md border-2 border-gray-300 bg-gray-200 p-4">
				@if (data.icons && data.icons.length >= 1) {
					<span><strong>Frequently used</strong></span>
					<div class="flex flex-row flex-wrap items-start justify-start">
						@for (icon of frequentIcons; track icon) {
							<ng-container
								[ngTemplateOutlet]="iconTemplate"
								[ngTemplateOutletContext]="{ url: icon.url, id: icon.id }">
							</ng-container>
						}
					</div>
					<span><strong>Legend Classes</strong></span>
					<div class="flex flex-row flex-wrap items-start justify-start">
						@for (icon of data.icons; track icon) {
							<ng-container
								[ngTemplateOutlet]="iconTemplate"
								[ngTemplateOutletContext]="{ url: icon.url, id: icon.id }">
							</ng-container>
						}
					</div>
				}
			</div>
		</form>
	}
}

<ng-template #iconTemplate let-id="id" let-url="url">
	<div
		class="border-1 pointer-events-auto m-1 flex h-14 w-14 cursor-pointer flex-col items-center justify-center rounded border border-dashed border-gray-300 p-2 transition-colors hover:bg-yuno-blue-hover"
		(click)="setIcon(id)">
		<img [src]="url" class="pointer-events-none w-full" />
	</div>
</ng-template>
