import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs';

import { DatasetsFacade, MarkersFacade } from '@yuno/admin/core/state';
import { FencesFacade } from '@yuno/admin/features/fences';
import { LayersFacade } from '@yuno/admin/features/layers';
import { PanoramasFacade } from '@yuno/admin/features/panoramas';
import { SourcesFacade } from '@yuno/admin/features/sources/data-access';
import { TextCustomFacade, TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import { ControlsOf } from '@yuno/angular/forms';
import {
	Event,
	FeatureState,
	NavigateToQueryParamsOptions,
	OpenTextfieldOverlayTypeArray,
	OpenTextfieldOverlayTypes,
	SetFeatureState,
	SetLayerStyleOptions,
	TextOptions,
	ZoomTo
} from '@yuno/api/interface';

export interface EventForm {
	type: FormControl<Event['type']>;
	options: FormGroup<EventOptionsForm>;
}

export type EventOptionsForm = ControlsOf<NonNullable<Event['options']>> & {
	coordinates?: FormArray<FormControl<number>>;
	bounds?: FormArray<FormControl<number>>;
	polygon?: FormArray<FormArray<FormControl<number>>>;
	padding?: FormControl<number | undefined>;
};

export interface EventsForm {
	onClick: FormArray<FormGroup<EventForm>>;
	onMouseLeave: FormArray<FormGroup<EventForm>>;
	onMouseMove: FormArray<FormGroup<EventForm>>;
}

export type EventFormType = 'onClick' | 'onMouseLeave' | 'onMouseMove';

@Injectable({
	providedIn: 'root'
})
export class EventFormsService {
	private readonly layerFacade = inject(LayersFacade);
	private readonly fenceFacade = inject(FencesFacade);
	private readonly markerFacade = inject(MarkersFacade);
	private readonly sourceFacade = inject(SourcesFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly customFacade = inject(TextCustomFacade);
	private readonly panoramaFacade = inject(PanoramasFacade);

	/**
	 * Stores textfield SelectionValues
	 * Display: id
	 * Values: _id
	 */
	textfieldSelectDisplay: string[] = [];
	textfieldSelectValues: string[] = [];

	/**
	 * Stores customTextBlock SelectionValues
	 * Display: id
	 * Values: _id
	 */
	customTextBlockSelectDisplay: string[] = [];
	customTextBlockSelectValues: string[] = [];

	/**
	 * Stores Sources SelectionValues
	 * Display: id
	 * Values: _id
	 */
	sourceSelectDisplay: string[] = [];
	sourceSelectValues: string[] = [];

	/**
	 * Stores SourcesLayers SelectionValues
	 * Display: id
	 * Values: _id
	 */
	sourceLayersSelectDisplay: string[] = [];
	sourceLayersSelectValues: string[] = [];

	/**
	 * Stores Dataset SelectionValues
	 * Display: id
	 * Values: _id
	 */
	loadDatasetDisplay: string[] = [];
	loadDatasetUnselectDisplay = 'Clear Active Dataset';
	loadDatasetValues: string[] = [];

	/**
	 * Stores Panorama SelectionValues
	 * Display: id
	 * Values: _id
	 */
	panoramaDisplay: string[] = [];
	panoramaValues: string[] = [];

	/**
	 * Stores Fences SelectionValues
	 * Display: id
	 * Values: _id
	 */
	fencesDisplay: string[] = [];
	fencesValues: string[] = [];

	/**
	 * Stores Layers SelectionValues
	 * Display: id
	 * Values: _id
	 */
	layersDisplay: string[] = [];
	layersValues: string[] = [];

	/**
	 * Stores Marker SelectionValues
	 * Display: id
	 * Values: _id
	 */
	markerDisplay: string[] = [];
	markerValues: string[] = [];

	overlayLocations = OpenTextfieldOverlayTypeArray;

	// Subscriptions
	customTextfields$ = this.customFacade.customs$.pipe(
		tap(data => {
			if (data) {
				this.customTextBlockSelectDisplay = data.map(object => object.name) as string[];
				this.customTextBlockSelectValues = data.map(object => object._id) as string[];
			}
		})
	);
	sources$ = this.sourceFacade.sources$.pipe(
		tap(data => {
			if (data) {
				this.sourceSelectDisplay = data.map(object => object.id) as string[];
				this.sourceSelectValues = data.map(object => object.id) as string[];
			}
		})
	);
	sourceLayers$ = this.sourceFacade.sourceLayers$.pipe(
		tap(data => {
			if (data) {
				this.sourceLayersSelectDisplay = data as string[];
				this.sourceLayersSelectValues = data as string[];
			} else {
				this.sourceLayersSelectDisplay = [];
				this.sourceLayersSelectValues = [];
			}
		})
	);
	datasets$ = this.datasetFacade.datasets$.pipe(
		tap(data => {
			if (data) {
				this.loadDatasetDisplay = [
					'Use Custom Properties',
					...(data.map(object => object.id) as string[])
				];
				this.loadDatasetValues = [
					'{{properties.customProperties.dataset}}',
					...(data.map(object => object._id) as string[])
				];
			}
		})
	);
	panoramas$ = this.panoramaFacade.panoramas$.pipe(
		tap(data => {
			if (data) {
				this.panoramaDisplay = [
					'Use custom properties',
					...data.map(object => object.id)
				] as string[];
				this.panoramaValues = [
					'{{properties.customProperties.panorama}}',
					...data.map(object => object._id)
				] as string[];
			}
		})
	);
	textfields$ = this.textfieldFacade.allTextfields$.pipe(
		tap(data => {
			if (data) {
				this.textfieldSelectDisplay = [
					'Use custom properties',
					...data.map(object => object.id)
				] as string[];
				this.textfieldSelectValues = [
					'{{properties.customProperties.textfield}}',
					...data.map(object => object._id)
				] as string[];
			}
		})
	);
	markers$ = this.markerFacade.markers$.pipe(
		tap(data => {
			if (data) {
				this.markerDisplay = data.map(object => object.properties?.id) as string[];
				this.markerValues = data.map(object => object._id) as string[];
			}
		})
	);
	fences$ = this.fenceFacade.fences$.pipe(
		tap(data => {
			if (data) {
				this.fencesDisplay = [
					'Use custom properties',
					...data.map(object => object.id)
				] as string[];
				this.fencesValues = [
					'{{properties.customProperties.fence}}',
					...data.map(object => object._id)
				] as string[];
			}
		})
	);

	layers$ = this.layerFacade.layers$.pipe(
		tap(data => {
			if (data) {
				this.layersDisplay = [...data.map(object => object.id)] as string[];
				this.layersValues = [...data.map(object => object._id)] as string[];
			}
		})
	);

	getTextfields(): void {
		this.textfieldFacade.get();
	}

	getCustomTextfields(): void {
		this.customFacade.get();
	}

	getSources(): void {
		this.sourceFacade.get();
	}

	getSourceLayers(id: string): void {
		this.sourceFacade.getSourceLayersByName(id);
	}

	getDataset(): void {
		this.datasetFacade.get();
	}

	getLayers(): void {
		this.layerFacade.get();
	}

	getPanoramas(): void {
		this.panoramaFacade.get();
	}

	getMarkers(): void {
		this.markerFacade.get();
	}

	selectMarker(id: string): void {
		this.markerFacade.select(id);
	}

	getFences(): void {
		this.fenceFacade.get();
	}

	/**
	 * main events form including onClick, onMouseLeave, onMouseMove arrays
	 * @returns FormGroup<EventsForm>
	 */
	createEventsForm(): FormGroup<EventsForm> {
		return new FormGroup<EventsForm>({
			onClick: this.createEventForm(),
			onMouseLeave: this.createEventForm(),
			onMouseMove: this.createEventForm()
		});
	}

	/**
	 * creates the FormArray to contain all Events
	 * @returns FormArray<FormGroup<EventForm>>
	 */
	createEventForm(): FormArray<FormGroup<EventForm>> {
		return new FormArray<FormGroup<EventForm>>([]);
	}

	/**
	 * Creates the event form itself
	 *
	 * @param {Event["type"]} [type='zoomTo']
	 * @param {Event["options"]} options
	 * @returns FormGroup<EventForm>
	 */
	createEvent(type: Event['type'] = 'zoomTo', options?: Event['options']): FormGroup<EventForm> {
		if (!type) {
			type = 'zoomTo';
		}

		const formGroup = new FormGroup<EventForm>({
			type: new FormControl<Event['type']>(type, { nonNullable: true }),
			options: new FormGroup<EventOptionsForm>({})
		});

		const option = this.generateOptions(type);
		if (option) {
			formGroup.setControl('options', option);
		}

		if (options) {
			type === 'zoomToMarker' && this.createZoomToMarkerOptions(formGroup, options);
			type === 'setLayerStyle' &&
				this.createSetLayerStyleOptions(formGroup, options as SetLayerStyleOptions);
			type === 'setFeatureState' && this.createSetFeatureStateOptions(formGroup, options);

			formGroup.controls.options.patchValue(options as never);
		}

		return formGroup;
	}

	generateOptions(type: Event['type']): FormGroup<EventOptionsForm> | undefined {
		//  * type: openPrivacy
		if (type === 'openPrivacy') {
			return this.createPrivacyOptions();
		}

		//  * type: navigateTo
		if (type === 'navigateTo') {
			return this.navigateToOptions();
		}

		//  * type: navigateToQueryParams
		if (type === 'navigateToQueryParams') {
			return this.createPrivacyOptions();
		}

		//  * type: zoom
		if (type === 'zoom') {
			return this.zoomEventOptions();
		}

		//  * type: zoomToFence
		if (type === 'zoomToFence') {
			return this.zoomToFenceEvent();
		}

		//  * type: zoomTo
		//  * type: zoomToPoint
		//  * type: zoomToMarker
		if (['zoomTo', 'zoomToPoint', 'zoomToMarker'].includes(type)) {
			return this.zoomToEventOptions();
		}

		//  * type: zoomToBounds
		if (type === 'zoomToBounds') {
			return this.zoomToBoundsEventOptions();
		}

		//  * type: zoomToFeature
		if (type === 'zoomToFeature') {
			return this.zoomToFeatureEventOptions();
		}

		//  * type: zoomToPolygon
		if (type === 'zoomToPolygon') {
			return this.zoomToPolygonEvent();
		}

		//  * type: mapPopup
		//  * type: popupText
		//  * type: hoverPopup
		if (['mapPopup', 'popupText', 'hoverPopup'].includes(type)) {
			return this.hoverPopupEvent();
		}

		//  * type: popupCustomText
		//  * type: popupHoverCustomText
		if (['popupCustomText', 'popupHoverCustomText'].includes(type)) {
			return this.popupCustomTextEvent();
		}

		//  * type: openParticipation
		if (type === 'openParticipation') {
			return this.openParticipationEvent();
		}

		//  * type: openText
		if (['openText'].includes(type)) {
			return this.openTextEvent();
		}

		//  * type: loadText
		if (['loadText'].includes(type)) {
			return this.loadTextEvent();
		}

		//  * type: openPano
		//  * type: loadPano
		if (['openPano', 'loadPano'].includes(type)) {
			return this.openPanoEvent();
		}

		//  * type: openTextPopup
		//  * type: loadTextPopup
		//  * type: openTextfield
		if (['openTextPopup', 'loadTextPopup', 'openTextfield'].includes(type)) {
			return this.openTextFieldEvent();
		}

		//  * type: embedText
		if (type === 'embedText') {
			return this.embedTextfieldEvent();
		}
		//  * type: customDataPopup
		//  * type: customDataTextPopup
		if (['customDataPopup', 'customDataTextPopup'].includes(type)) {
			return this.customDataPopupEvent();
		}

		//  * type: setFeatureState
		if (type === 'setFeatureState') {
			return this.setFeatureStateEvent();
		}

		//  * type: toggleLayer
		if (type === 'toggleLayer') {
			return this.toggleLayerOptionsEvent();
		}

		if (type === 'setLayerStyle') {
			return this.setLayerStyleEvent();
		}

		//  * type: setFilter
		// if (type === 'setFilter') {
		// 	return this.setFilterEvent();
		// }

		//  * type: loadDataset
		if (type === 'loadDataset') {
			return this.loadDatasetEvent();
		}

		//  * type: loadPano
		if (type === 'loadPano') {
			return this.loadPanoEvent();
		}

		//  * type: replacePage
		if (type === 'replacePage') {
			return this.replacePageEvent();
		}

		//  * type: openImage
		if (type === 'openImage') {
			return this.openImageEvent();
		}

		//  * type: clickMarker
		if (type === 'clickMarker') {
			return this.clickMarkerEvent();
		}

		//  * type: clickMarker
		if (type === 'openUrl') {
			return this.openUrlEvent();
		}

		return;
	}

	/**
	 * type: openPrivacy
	 * Creates controls for OpenPrivacyOptions
	 */
	createPrivacyOptions(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			type: new FormControl<'xkp' | 'client'>('xkp', {
				nonNullable: true,
				validators: Validators.required
			})
		});
	}

	/**
	 *
	 * @param formGroup
	 * @param options
	 */
	createZoomToMarkerOptions(formGroup: FormGroup<EventForm>, options?: Event['options']): void {
		if (!(options as ZoomTo).coordinates) {
			const optionsControls = Object.keys(formGroup.controls.options.controls);
			const formControls = optionsControls.filter(control => control !== 'zoom');
			this.removeControls(formGroup.controls.options, formControls);
		}
	}

	/**
	 * Creates the basic structure needed for the Set Layer Style Options
	 * @param formGroup
	 * @param options
	 */
	createSetLayerStyleOptions(
		formGroup: FormGroup<EventForm>,
		options?: SetLayerStyleOptions
	): void {
		if (options) {
			options.layout &&
				this.setLayerStyleOptionFormControls(formGroup, 'layout', options.layout);
			options.paint &&
				this.setLayerStyleOptionFormControls(formGroup, 'paint', options.paint);
		}
	}

	/**
	 *
	 * @param formGroup
	 * @param options
	 */
	createSetFeatureStateOptions(
		formGroup: FormGroup<EventForm>,
		options?: Event['options']
	): void {
		const form = (formGroup.controls.options.controls as ControlsOf<SetFeatureState>).state;
		const opts = options as SetFeatureState;
		if (!form || !opts.state) {
			return;
		}

		for (const key in opts.state) {
			if (opts.state[key] === 'hover') {
				return;
			}

			const value = opts.state[key] as string;
			const control = new FormControl<string | undefined>(value, {
				nonNullable: true
			});

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			form.addControl(key, control);
		}
	}

	setLayerStyleOptionFormControls(
		formGroup: FormGroup<EventForm>,
		key: 'filter' | 'layout' | 'paint',
		data: any
	): void {
		if (key === 'layout' || key === 'paint') {
			// @ts-ignore MapLibre uses very long types that generates: Type instantiation is excessively deep and possibly infinite.
			const form = formGroup.get('options')?.get(key) as FormGroup;
			const formControls = Object.keys(form.controls);
			formControls.forEach(control => {
				form.removeControl(control);
			});

			for (const control in data) {
				form.addControl(control, new FormControl(''));
			}

			form.patchValue(data);
		}
	}

	/**
	 * type: navigateTo
	 * Creates controls for NavigateToOptions
	 */
	navigateToOptions(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			url: new FormControl<string>('', {
				nonNullable: true,
				validators: Validators.required
			})
		});
	}

	/**
	 * type: navigateToQueryParams
	 * Creates controls for NavigateToQueryParamsOptions
	 */
	navigateToQueryParamsOptions(
		options: NavigateToQueryParamsOptions
	): FormGroup<EventOptionsForm> {
		const formGroup = new FormGroup<EventOptionsForm>({
			params: new FormGroup<ControlsOf<{ [key: string]: string | null }>>({})
		});

		for (const key in options.params) {
			if (Object.prototype.hasOwnProperty.call(options.params, key)) {
				const str = options.params[key];

				if (!str) {
					continue;
				}

				(
					formGroup as FormGroup<ControlsOf<NavigateToQueryParamsOptions>>
				).controls?.params?.addControl(key, new FormControl<string>(str));
			}
		}

		return formGroup;
	}

	/**
	 * type: zoom
	 * Creates controls for ZoomNumber
	 */
	zoomEventOptions(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			bearing: new FormControl<number | undefined>(0, { nonNullable: true }),
			pitch: new FormControl<number | undefined>(0, { nonNullable: true }),
			animate: new FormControl<boolean | undefined>(true, { nonNullable: true }),
			zoom: new FormControl<-1 | 0 | 1>(0, {
				nonNullable: true,
				validators: Validators.required
			})
		});
	}

	/**
	 * type: zoomToFence
	 * Creates controls for ZoomToFence
	 */
	zoomToFenceEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			property: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			minZoom: new FormControl<number | undefined>(1, { nonNullable: true })
		});
	}

	/**
	 * type: zoomTo
	 * type: zoomToPoint
	 * type: zoomToMarker
	 * Creates controls for ZoomTo
	 */
	zoomToEventOptions(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			bearing: new FormControl<number | undefined>(0, { nonNullable: true }),
			pitch: new FormControl<number | undefined>(0, { nonNullable: true }),
			animate: new FormControl<boolean | undefined>(true, { nonNullable: true }),
			coordinates: new FormArray<FormControl<number>>([
				new FormControl<number>(0, { nonNullable: true }),
				new FormControl<number>(0, { nonNullable: true })
			]),
			minZoom: new FormControl<number | undefined>(1, { nonNullable: true }),
			zoom: new FormControl<number | undefined>(1, { nonNullable: true })
		});
	}

	/**
	 * type: zoomToBounds
	 * Creates controls for ZoomToBounds
	 */
	zoomToBoundsEventOptions(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			bearing: new FormControl<number | undefined>(0, { nonNullable: true }),
			pitch: new FormControl<number | undefined>(0, { nonNullable: true }),
			animate: new FormControl<boolean | undefined>(true, { nonNullable: true }),
			bounds: new FormArray<FormControl<number>>([
				new FormControl<number>(0, { nonNullable: true }),
				new FormControl<number>(0, { nonNullable: true }),
				new FormControl<number>(0, { nonNullable: true }),
				new FormControl<number>(0, { nonNullable: true })
			]),
			minZoom: new FormControl<number>(1, { nonNullable: true }),
			padding: new FormControl<number | undefined>(30, { nonNullable: true })
			// ({
			// top: new FormControl<number>(0, { nonNullable: true }),
			// bottom: new FormControl<number>(0, { nonNullable: true }),
			// right: new FormControl<number>(0, { nonNullable: true }),
			// left: new FormControl<number>(0, { nonNullable: true })
			// })
		});
	}

	/**
	 * type: zoomToFeature
	 * Creates controls for ZoomToFeature
	 */
	zoomToFeatureEventOptions(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			bearing: new FormControl<number | undefined>(0, { nonNullable: true }),
			pitch: new FormControl<number | undefined>(0, { nonNullable: true }),
			animate: new FormControl<boolean | undefined>(true, { nonNullable: true }),
			bounds: new FormArray<FormControl<number>>([
				new FormControl<number>(0, { nonNullable: true }),
				new FormControl<number>(0, { nonNullable: true }),
				new FormControl<number>(0, { nonNullable: true }),
				new FormControl<number>(0, { nonNullable: true })
			]),
			minZoom: new FormControl<number | undefined>(1, { nonNullable: true })
		});
	}

	/**
	 * type: zoomToPolygon
	 * Creates controls for ZoomToPolygon
	 */
	zoomToPolygonEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			bearing: new FormControl<number | undefined>(0, { nonNullable: true }),
			pitch: new FormControl<number | undefined>(0, { nonNullable: true }),
			animate: new FormControl<boolean | undefined>(true, { nonNullable: true }),
			polygon: new FormArray<FormArray<FormControl<number>>>([
				new FormArray<FormControl<number>>([
					new FormControl<number>(0, { nonNullable: true }),
					new FormControl<number>(0, { nonNullable: true })
				]),
				new FormArray<FormControl<number>>([
					new FormControl<number>(0, { nonNullable: true }),
					new FormControl<number>(0, { nonNullable: true })
				])
			]),
			property: new FormControl<string | undefined>(undefined, { nonNullable: true })
		});
	}

	/**
	 * type: mapPopup
	 * type: popupText
	 * type: hoverPopup
	 * Creates controls for HoverPopup
	 */
	hoverPopupEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			content: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			hover: new FormControl<boolean | undefined>(false, { nonNullable: true }),
			markerOffset: new FormControl<number | undefined>(0, { nonNullable: true }),
			name: new FormControl<string | undefined>(undefined, { nonNullable: true })
		});
	}

	/**
	 * type: popupCustomText
	 * type: popupHoverCustomText
	 * Creates controls for PopupCustomText
	 */
	popupCustomTextEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true })
		});
	}

	/**
	 * type: openParticipation
	 * Creates controls for OpenParticipationOptions
	 */
	openParticipationEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true })
		});
	}

	/**
	 * type: openText
	 * Creates controls for OpenText
	 */
	openTextEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true })
		});
	}

	/**
	 * type: loadText
	 * Creates controls for loadText
	 */
	loadTextEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			name: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			property: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			overlay: new FormControl<OpenTextfieldOverlayTypes | undefined>('right', {
				nonNullable: true
			})
		});
	}

	/**
	 * type: openPano
	 * type: loadPano
	 * Creates controls for OpenPano
	 */
	openPanoEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			name: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			force: new FormControl<boolean | undefined>(undefined, { nonNullable: true })
		});
	}

	/**
	 * type: openTextPopup
	 * type: loadTextPopup
	 * type: openTextfield
	 * Creates controls for OpenTextField
	 */
	openTextFieldEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			name: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			property: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			overlay: new FormControl<OpenTextfieldOverlayTypes | undefined>('right', {
				nonNullable: true
			})
		});
	}

	/**
	 * type: embedText
	 * Creates controls for EmbedTextfield
	 */
	embedTextfieldEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			container: new FormControl<string>('', { nonNullable: true }),
			id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			clear: new FormControl<boolean | undefined>(false, { nonNullable: true })
			// textfield: new FormControl< Textfield | undefined>(undefined, { nonNullable: true}),
		});
	}

	/**
	 * type: customDataPopup
	 * type: customDataTextPopup
	 * Creates controls for CustomDataPopup
	 * @deprecated The method should not be used
	 */
	customDataPopupEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			property: new FormControl<string>('', { nonNullable: true }),
			type: new FormControl<string>('', { nonNullable: true }),
			textOptions: new FormGroup<ControlsOf<TextOptions>>({
				id: new FormControl<string>('', { nonNullable: true }),

				name: new FormControl<string | undefined>(undefined, { nonNullable: true }),
				property: new FormControl<string | undefined>(undefined, { nonNullable: true }),
				overlay: new FormControl<string | undefined>(undefined, { nonNullable: true })
			})
		});
	}

	/**
	 * type: setFeatureState
	 * Creates controls for SetFeatureState
	 */
	setFeatureStateEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string>('', { nonNullable: true }),
			source: new FormControl<string>('', { nonNullable: true }),
			clear: new FormControl<boolean | undefined>(false, { nonNullable: true }),
			merge: new FormControl<boolean | undefined>(false, { nonNullable: true }),
			sourceLayer: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			state: new FormGroup<ControlsOf<FeatureState> | undefined>({
				hover: new FormControl<boolean>(false, { nonNullable: true })
			})
		});
	}

	/**
	 * type: toggleLayer
	 * Creates controls for ToggleLayerOptions
	 */
	toggleLayerOptionsEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			layers: new FormArray<FormControl<string>>([]),
			visible: new FormControl<boolean>(false, { nonNullable: true })
		});
	}

	/**
	 * type: setLayerStyle
	 * Creates controls for SetLayerStyleOptions
	 */
	setLayerStyleEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl('yest', { nonNullable: true, validators: Validators.required }),
			// layers: new FormArray<FormControl<string>>([]),
			merge: new FormControl<boolean>(false, { nonNullable: true }),
			// @ts-ignore MapLibre uses very long types that generates: Type instantiation is excessively deep and possibly infinite.
			filter: new FormArray([]),
			// @ts-ignore MapLibre uses very long types that generates: Type instantiation is excessively deep and possibly infinite.
			layout: new FormGroup({}),
			// @ts-ignore MapLibre uses very long types that generates: Type instantiation is excessively deep and possibly infinite.
			paint: new FormGroup({})
		});
	}

	/**
	 * type: loadDataset
	 * Creates controls for LoadDataset
	 */
	loadDatasetEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			property: new FormControl<string | undefined>(undefined, { nonNullable: true }),
			multi: new FormControl<boolean | undefined>(false, { nonNullable: true }),
			remove: new FormControl<boolean | undefined>(false, { nonNullable: true })
		});
	}

	/**
	 * type: loadPano
	 * Creates controls for LoadPano
	 */
	loadPanoEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string>('', { nonNullable: true })
		});
	}

	/**
	 * type: setFilter
	 * Creates controls for SetFilter
	 */
	// setFilterEvent(): FormGroup<EventOptionsForm> {
	// 	return new FormGroup<EventOptionsForm>({
	// 	// 	filter: new FormControl<string>('', { nonNullable: true }),
	// 	// 	layerId: new FormControl<string | undefined>('', { nonNullable: true })
	// 	});
	// }

	/**
	 * type: replacePage
	 * Creates controls for ReplacePageOptions
	 */
	replacePageEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string>('', { nonNullable: true })
		});
	}

	/**
	 * type: openImage
	 * Creates controls for OpenImage
	 */
	openImageEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			src: new FormControl<string>('', { nonNullable: true })
		});
	}

	/**
	 * type: openUrl
	 * Creates controls for OpenUrlOptions
	 */
	openUrlEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			url: new FormControl<string>('', { nonNullable: true })
		});
	}

	/**
	 * type: clickMarker
	 * Creates controls for ClickMarkerOptions
	 */
	clickMarkerEvent(): FormGroup<EventOptionsForm> {
		return new FormGroup<EventOptionsForm>({
			id: new FormControl<string>('', { nonNullable: true })
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	generateControls(options: FormGroup, value: any, arrays?: { name: string; length: number }[]) {
		for (const control in value) {
			const array = arrays?.find(c => c.name === control);
			if (array) {
				options.addControl(control, new FormArray([]));
				for (let i = 0; i < array.length; i++) {
					(options.get(array.name) as FormArray).push(new FormControl(''));
				}
			} else {
				options.addControl(control, new FormControl(''));
			}
		}
		options.patchValue(value);
	}

	removeControls(options: FormGroup, controls: string[]) {
		controls.forEach(control => {
			options.removeControl(control);
		});
	}
}
