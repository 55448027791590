import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { LanguageStringsModel } from '@yuno/api/interface';

export interface JsonFormSuccess {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any | any[];
}

export interface JsonFormErrors {
	data: UntypedFormGroup[];
	errors: boolean;
	formErrorIndices: number[];
}

export interface JsonFormEvent {
	event: string;
	options?: {
		[key: string]: unknown;
	};
}

export interface JsonFormValidators {
	min?: number;
	max?: number;
	required?: boolean;
	requiredTrue?: boolean;
	email?: boolean;
	minLength?: number;
	maxLength?: number;
	pattern?: string;
	nullValidator?: boolean;
	noSpacesLowerCase?: boolean;
}

export interface JsonFormGroupValidators {
	/**
	 * the matchingControlName will recieve the error.
	 * To view the error, pass the message to the matchingControl
	 */
	matchControls: { controlName: string; matchingControlName: string };
}

export interface JsonFormControlOptions {
	min?: string;
	max?: string;
	step?: string;
	rows?: number;
	add?: boolean;
	colPos?: number;
	disabled?: boolean;
	labelPos?: 'side' | 'top';
	colorDir?:
		| 'auto'
		| 'top'
		| 'bottom'
		| 'left'
		| 'right'
		| 'top-left'
		| 'top-right'
		| 'bottom-left'
		| 'bottom-right';
}

export const JsonFormInputTypes = [
	'text',
	'textarea',
	'password',
	'url',

	'toggle',
	'checkbox',

	'range',
	'number',

	'select',

	'date',
	'datetime-local',
	'time',

	'email',
	'tel',

	'color',
	'file',
	'image',
	'radio',
	'html',

	'paragraph'
] as const;

export type JsonFormFormType = (typeof JsonFormInputTypes)[number];
export type JsonFormControlType = 'control' | 'group' | 'array';

export class JsonFormControl {
	controlType: 'control';
	control?: UntypedFormControl;
	type: JsonFormFormType;
	key: string;
	label?: LanguageStringsModel;
	value?: unknown;
	autocomplete?: string;
	selectValues?: string[];
	selectDisplay?: LanguageStringsModel[];
	radioValues?: string[];
	placeholder?: LanguageStringsModel;
	colspan?: number | 'full';
	readonly?: boolean;
	options?: JsonFormControlOptions;
	required?: boolean;
	validators?: JsonFormValidators;
	validationMessage?: LanguageStringsModel;

	constructor(options: JsonFormControl) {
		this.controlType = 'control';
		this.key = options?.key;
		this.type = options?.type || 'text';
		this.selectValues = options?.selectValues || (options?.type === 'select' ? [] : undefined);
		this.radioValues = options?.radioValues || (options?.type === 'radio' ? [] : undefined);
		this.label = options?.label || '';
		this.value = options?.value;
		this.autocomplete = options?.autocomplete;
		this.colspan = options?.colspan || 1;
		this.placeholder = options?.placeholder || '';
		this.readonly = options?.readonly || false;
		this.options = options?.options || {};
		this.required = options?.required || false;
		this.validators = options?.validators;
		this.validationMessage =
			options?.validationMessage || options?.label
				? `${options?.label} is required!`
				: 'This is required!';
	}
}

export class JsonFormArrayControl {
	controlType: 'array-control';
	control?: UntypedFormControl;
	type: JsonFormFormType;
	label?: string;
	value?: unknown;
	autocomplete?: string;
	selectValues?: string[];
	selectDisplay?: string[];
	radioValues?: string[];
	placeholder?: string;
	colspan?: number | 'full';
	readonly?: boolean;
	options?: JsonFormControlOptions;
	required?: boolean;
	validators?: JsonFormValidators;
	validationMessage?: string;

	constructor(options: JsonFormArrayControl) {
		this.controlType = 'array-control';
		this.type = options?.type || 'text';
		this.selectValues = options?.selectValues || (options?.type === 'select' ? [] : undefined);
		this.radioValues = options?.radioValues || (options?.type === 'radio' ? [] : undefined);
		this.label = options?.label || '';
		this.value = options?.value;
		this.autocomplete = options?.autocomplete;
		this.colspan = options?.colspan || 1;
		this.placeholder = options?.placeholder || '';
		this.readonly = options?.readonly || false;
		this.options = options?.options || {};
		this.required = options?.required || false;
		this.validators = options?.validators;
		this.validationMessage =
			options?.validationMessage || options?.label
				? `${options?.label} is required!`
				: 'This is required!';
	}
}

export interface JsonGroupForm {
	key: string;
	label?: string;
	controlType: 'group';
	control?: UntypedFormGroup;
	columns?: number;
	colspan?: number | 'full';
	controls: JsonFormKeyType[];
	validators?: JsonFormGroupValidators;
	validationMessage?: string;
}

/**
 * this will create a form using all languages
 * we can use in the application.
 *
 * The form will use the key of the language
 * as main container key.
 * The options.control is needed to populate
 * the required controls.
 * Those control need a Key but will use the
 * language as a key. For example: 'nl'
 *
 * Only use with text, textarea, html
 */
export interface JsonLanguageForm {
	key: string;
	label?: string;
	labelDescr?: string;
	controlType: 'language';
	control?: UntypedFormGroup;
	columns?: number;
	colspan?: number | 'full';
	options?: {
		/**
		 * this control does require a 'key' but does not use it.
		 * it will be replaced by the currentLanguage
		 */
		control?: JsonFormControl;
	};
}

export interface JsonButtonEventForm {
	type: string;
	options?: { [key: string]: unknown };
}

export interface JsonLabel {
	key: string;
	controlType: 'label';
	label?: string;
	labelDescr?: string;
	colspan?: number | 'full';
}
export interface JsonDivider {
	key: string;
	controlType: 'divider';
}

export interface JsonButtonForm {
	key: string;
	controlType: 'button';
	label?: string;
	value?: string;
	colspan?: number | 'full';
	options?: {
		events?: JsonButtonEventForm[];
	};
}

export interface JsonArrayForm {
	key: string;
	label?: string;
	controlType: 'array';
	control?: UntypedFormArray;
	options?: {
		fixed?: boolean;
		type?: 'object';
		add?: boolean;
	};
	columns?: number;
	controls: JsonFormKeyType[];
}
export type JsonFormKeyType =
	| JsonFormControl
	| JsonFormArrayControl
	| JsonGroupForm
	| JsonArrayForm
	| JsonButtonForm
	| JsonLanguageForm
	| JsonLabel
	| JsonDivider;

export interface JsonFormPageData {
	label?: string;
	labelDescr?: string;
	columns?: number;
	controls: JsonFormKeyType[];
}

export interface JsonFormData {
	data: JsonFormPageData[];
}

export interface JsonFormValue {
	options?: {
		multiForm?: boolean;
	};
	data: { [key: string]: unknown }[];
}
