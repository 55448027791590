import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'yuno-user-rights-message',
	standalone: true,
	imports: [],
	template: `
		<div
			class="rounded-md border-2 border-dashed border-yellow-500 bg-yellow-400/30 px-4 py-2 text-sm text-yellow-800">
			You do not have sufficient rights to edit this data.
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRightsMessageComponent {}
