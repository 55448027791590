import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { App, AppUser, Client, Config } from '@yuno/api/interface';

export const appActions = createActionGroup({
	source: 'App',
	events: {
		/**
		 * This reset is used to clear the state of all data
		 * when navigating through the portal
		 *
		 * Not for resetting this actions state
		 */
		reset: emptyProps(),

		app: props<{ appId: string }>(),
		appClearState: emptyProps(),
		appPending: emptyProps(),
		appSuccess: props<{
			app: Partial<App>;
			client: Partial<Client>;
			config: Partial<Config>;
			url: string;
		}>(),
		appRedirect: emptyProps(),
		appFailure: props<{ error: Error }>(),
		appClear: emptyProps(),

		updateApp: props<{ data: Partial<App> }>(),
		updateSettings: props<{ data: Partial<Config> }>(),

		saveSettings: emptyProps(),
		saveSettingsSuccess: emptyProps(),
		saveSettingsFailure: props<{ error: Error }>(),

		loadAppUsers: emptyProps(),
		loadAppUsersClient: emptyProps(),
		loadAppUsersSuccess: props<{ data: Partial<AppUser>[] }>(),
		loadAppUsersFailure: props<{ error: Error }>(),

		addAppUser: props<{ user: Partial<AppUser> }>(),
		updateAppUser: props<{ user: AppUser }>(),
		removeAppUser: props<{ user: AppUser; client?: boolean }>(),

		saveAppUserSuccess: emptyProps(),
		saveAppUserFailure: props<{ error: Error }>()
	}
});
