@if (form) {
	<div class="mt-4 grid grid-cols-1 gap-4">
		<form [formGroup]="form">
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				<yuno-forms-text
					label="Url"
					formControlName="url"
					placeholder="paste your url here">
				</yuno-forms-text>
			</div>
		</form>
		<yuno-admin-annotation type="info">
			<div class="flex flex-col">
				<yuno-forms-span class="font-semibold">*Open Url</yuno-forms-span>
				<yuno-forms-span> Urls will open in a <strong>new tab</strong>. </yuno-forms-span>
			</div>
		</yuno-admin-annotation>
	</div>
}
