<h1>Library</h1>
<p>What would you like to add?</p>
<div class="grid grid-cols-2 gap-2">
	@for (item of getList(); track item) {
		<yuno-select-field
			[display]="item.label"
			(clicked)="service.onSelect(item)"
			[disabled]="item.key === service.listVal"></yuno-select-field>
	}
</div>
@if (service.data$ | async; as data) {
	<div class="item-container">
		<yuno-textfield-injector
			[render]="false"
			[data]="{ components: service.components }"
			[language]="language"
			(componentsOut)="service.injectorComponents = $event">
		</yuno-textfield-injector>
		@if (service.listVal) {
			<h2>{{ service.listVal }} Components:</h2>
		}
		@if (!service.loaderActive) {
			@for (comp of service.injectorComponents; track comp) {
				<div class="mb-2">
					<yuno-admin-textfield-dynamic
						[duplicateDisabled]="true"
						[deleteDisabled]="true"
						[item]="comp"
						[addList]="true"
						(clicked)="selected.emit(service.onAdd(comp))">
					</yuno-admin-textfield-dynamic>
				</div>
			}
		} @else {
			<div class="flex w-full flex-row justify-center">
				<yuno-spinner [spinnerSize]="30" [spinnerWidth]="2"></yuno-spinner>
			</div>
		}
	</div>
}
