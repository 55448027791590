import { gql } from 'apollo-angular';

import { DataObjects } from '@yuno/api/interface';

export const GET_OBJECTS_BY_APPID = gql`
	query getObjectsByAppId($appId: String!) {
		objects(appId: $appId) {
			type
			data
		}
	}
`;

export const GET_OBJECT_BY_ID = gql`
	query getObjectById($_id: String!) {
		selectedObjects(_id: $_id) {
			type
			data
		}
	}
`;

export type ObjectsQuery = {
	objects: Partial<DataObjects>[];
};

export type SelectObjectQuery = {
	selectedObjects: Partial<DataObjects>;
};
