import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Cookie, CookieLanguage } from '@yuno/api/interface';

export const cookieActions = createActionGroup({
	source: 'Cookies',
	events: {
		get: emptyProps(),
		getPending: emptyProps(),
		getFailure: props<{
			error: Error;
		}>(),
		getSuccess: props<{
			data: Partial<Cookie>[];
		}>(),

		getLanguage: emptyProps(),
		getLanguagePending: emptyProps(),
		getLanguageFailure: props<{
			error: Error;
		}>(),
		getLanguageSuccess: props<{
			data: Partial<CookieLanguage>[];
		}>(),

		getById: props<{
			id: string;
		}>(),
		getByIdPending: emptyProps(),
		getByIdFailure: props<{
			error: Error;
		}>(),
		getByIdSuccess: props<{
			data: Cookie;
		}>(),

		getLanguageById: props<{
			id: string;
		}>(),
		getLanguagePendingById: emptyProps(),
		getLanguageFailureById: props<{
			error: Error;
		}>(),
		getLanguageSuccessById: props<{
			data: CookieLanguage;
		}>(),

		resetSelected: emptyProps(),
		resetSelectedLanguage: emptyProps(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{
			error: Error;
		}>(),

		update: props<{
			data: Cookie;
		}>(),
		updateLanguage: props<{
			data: CookieLanguage;
		}>(),

		saveLanguage: emptyProps(),
		saveLanguageSuccess: emptyProps(),
		saveLanguageFailure: props<{
			error: Error;
		}>()
	}
});
