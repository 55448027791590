<ng-container>
	<section class="flex flex-wrap justify-between gap-2">
		<button yuno-admin-button [add]="true" (click)="addSprites()">Add Sprite</button>
		<div class="flex flex-row-reverse flex-wrap-reverse justify-end gap-2">
			<button yuno-admin-button color="secondary" (click)="generateSpritesheet()">
				Generate Spritesheet
			</button>
			<button yuno-admin-button color="secondary" (click)="viewSpritesheet()">
				View Spritesheet
			</button>
			<button yuno-admin-button (click)="zipit()">Download Zip</button>
		</div>
	</section>
</ng-container>
@if (sprites) {
	<yuno-admin-sprite-list
		[title]="'Sprites'"
		[sprites]="sprites.files"
		(edit)="copyToClipboard($event)"></yuno-admin-sprite-list>
	<yuno-admin-sprite-list
		[title]="'SDF Sprites'"
		[sprites]="sprites.sdf"
		(edit)="copyToClipboard($event)"></yuno-admin-sprite-list>
}
@if (clientSprites) {
	<yuno-admin-sprite-list
		[title]="'Client Sprites'"
		[sprites]="clientSprites.files"
		(edit)="copyToClipboard($event)"></yuno-admin-sprite-list>
}
@if (yunoSprites) {
	<yuno-admin-sprite-list
		[title]="'Global Sprites (non editable)'"
		[sprites]="yunoSprites.files"
		(edit)="copyToClipboard($event)"></yuno-admin-sprite-list>
	<yuno-admin-sprite-list
		[title]="'SDF Sprites'"
		[sprites]="yunoSprites.sdf"
		(edit)="copyToClipboard($event)"></yuno-admin-sprite-list>
}
