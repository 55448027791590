import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LanguageType } from '@yuno/api/interface';

@Injectable()
export class LanguageService {
	private _language: BehaviorSubject<LanguageType> = new BehaviorSubject('nl');
	language$ = this._language.asObservable();

	setLanguage(lang: LanguageType): void {
		this._language.next(lang);
	}
}
