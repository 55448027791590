import { Injectable, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface DataObjectForm {
	type: FormControl<'data-shapefile' | 'data-threejs-model' | 'data-threejs-pointcloud'>;
	data: FormGroup<ShapefileForm | ThreejsForm | PointcloudForm>;
}

interface DataForm {
	_id: FormControl<string>;
	id: FormControl<string>;
	public: FormControl<boolean>;
	url: FormControl<string>;
	epsg: FormControl<'EPSG:4326' | 'EPSG:28992'>;
}

interface ShapefileForm extends DataForm {
	styling?: FormGroup<StylingForm>;
}

interface StylingForm {
	type: FormControl<'fill' | 'line' | 'circle'>;
	layout: FormGroup;
	paint: FormGroup;
}

interface ThreejsForm extends DataForm {
	coordinates: FormArray<FormControl<number>>;
	epsg4326?: FormArray<FormControl<number>>;
}

interface PointcloudForm extends DataForm {
	fileName: FormControl<string>;
	pointcount?: FormControl<number | null>;
	coordinates: FormArray<FormControl<number>>;
	epsg4326?: FormArray<FormControl<number>>;
}

@Injectable({
	providedIn: 'root'
})
export class ObjectEditorService {
	private formBuilder = inject(FormBuilder);

	form: FormGroup<DataObjectForm>;
	typeDisplay = ['Shapefile', 'ThreeJS', 'Pointcloud'];
	typeValues = ['data-shapefile', 'data-threejs-model', 'data-threejs-pointcloud'];
	shapefileValues = ['fill', 'line', 'circle'];
	epsgValues = ['EPSG:4326', 'EPSG:28992'];

	get data(): FormGroup {
		return this.form.get('data') as FormGroup;
	}

	get coordinates(): FormArray {
		return this.data.get('coordinates') as FormArray;
	}

	get styling(): FormGroup {
		return this.data.get('styling') as FormGroup;
	}

	get layout(): FormGroup {
		return this.styling.get('layout') as FormGroup;
	}

	get paint(): FormGroup {
		return this.styling.get('paint') as FormGroup;
	}

	createFormGroup(): void {
		this.form = new FormGroup<DataObjectForm>({
			type: new FormControl('data-shapefile', {
				nonNullable: true,
				validators: Validators.required
			}),
			data: new FormGroup<ShapefileForm | ThreejsForm | PointcloudForm>({
				_id: new FormControl<string>(
					{ value: '', disabled: true },
					{
						nonNullable: true
					}
				),
				id: new FormControl('', { nonNullable: true, validators: Validators.required }),
				public: new FormControl(true, { nonNullable: true }),
				url: new FormControl('', { nonNullable: true, validators: Validators.required }),
				epsg: new FormControl('EPSG:4326', { nonNullable: true }),
				//
				fileName: new FormControl('', { nonNullable: true }),
				pointcount: new FormControl(null),
				coordinates: new FormArray<FormControl<number>>([
					new FormControl(0, { nonNullable: true }),
					new FormControl(0, { nonNullable: true }),
					new FormControl(0, { nonNullable: true })
				]),
				//
				styling: new FormGroup<StylingForm>({
					type: new FormControl('fill', { nonNullable: true }),
					layout: new FormGroup({}),
					paint: new FormGroup({})
				})
			})
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	updateFormControls(key: 'layout' | 'paint', json: any) {
		const formControls = Object.keys(this[key].controls);
		formControls.forEach(control => {
			this[key].removeControl(control);
		});

		for (const control in json) {
			this[key].addControl(control, this.formBuilder.control(''));
		}
		this[key].patchValue(json);
	}
}
