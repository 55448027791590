import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnInit,
	ViewChild,
	inject
} from '@angular/core';

import { LanguageStringsModel, Textblock } from '@yuno/api/interface';
import { waitFor } from '@yuno/libs/shared/helpers';

import { LanguagePipe } from '../../../../../pipes/src/lib/language/language.pipe';
import { SafeHtmlIframePipe } from '../../../../../pipes/src/lib/safe-html/safe-html-iframe.pipe';
import { EventsService } from '../../services/events.service';
import { TextfieldToken } from '../../textfield.injection.token';

@Component({
	selector: 'yuno-textfield-block',
	templateUrl: './text-block.component.html',
	styleUrls: ['./text-block.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [SafeHtmlIframePipe, LanguagePipe]
})
export class TextBlockComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private readonly injectValue = inject(TextfieldToken);
	private events = inject(EventsService);
	private cdr = inject(ChangeDetectorRef);

	@ViewChild('contents', { static: false, read: ElementRef }) contents: ElementRef;

	@Input() language?: string;
	@Input() content?: LanguageStringsModel;
	@Input() disableInjection = false;

	ngOnInit(): void {
		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(this.injectValue.data as Textblock, this.injectValue.language);
	}

	/**
	 * Setup the Component by using the Injection Method
	 *
	 * @param {Textblock} data
	 */
	async handleInjectionData(data: Textblock, language?: string): Promise<void> {
		this.language = language;
		this.content = data.content;

		await waitFor(100);
		this.parseContent();
	}

	/**
	 * Add deprecated Textfield events
	 *
	 * @memberof TextBlockComponent
	 */
	parseContent(): void {
		this.parseClickEvents(this.contents?.nativeElement.querySelectorAll('.clickevent'));
		this.cdr.markForCheck();
	}

	parseClickEvents(clickEvents: HTMLElement[]): void {
		if (clickEvents.length < 1) {
			return;
		}

		console.warn('Deprecated use of .clickEvent inside textfields');

		for (const clickEvent of clickEvents) {
			const loadText = clickEvent.getAttribute('loadtext');
			if (!loadText) {
				continue;
			}

			clickEvent.addEventListener('click', () => {
				this.events.sendEvent([
					{
						type: 'loadText',
						options: {
							id: loadText
						}
					}
				]);
			});
		}
	}
}
