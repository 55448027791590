import {
	HttpClient,
	HttpEventType,
	HttpHeaders,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, lastValueFrom } from 'rxjs';
import slugify from 'slugify';

import { ENVIRONMENT } from '@yuno/admin/core';
import { DialogItem, MessageDialogComponent, MessageService } from '@yuno/angular/notifications';
import { TilesetCustom } from '@yuno/api/interface';

import { TilesetsCustomFacade } from '../../data-access';

export interface TilesetForm {
	_id: FormControl<string>;
	id: FormControl<string>;
	// appId: FormControl<string>;
	tippecanoe: FormGroup<{
		preset: FormControl<string>;
		command: FormControl<string>;
	}>;
	geojson: FormArray<FormControl<string>>;
}

export class JsonData {
	index: number;
	geojson: string;
}

@Injectable({
	providedIn: 'root'
})
export class CustomTilesetService {
	private readonly environment = inject(ENVIRONMENT);
	private dialog = inject(MatDialog);
	private tilesetFacade = inject(TilesetsCustomFacade);
	private http = inject(HttpClient);

	private dialogRef: MatDialogRef<MessageDialogComponent>;
	private _dialog = new ReplaySubject<boolean>(1);
	dialog$ = this._dialog.asObservable();

	private _response = new ReplaySubject<TilesetCustom | null>();
	response$ = this._response.asObservable();

	_progress = new ReplaySubject<number | null>();
	progress$ = this._progress.asObservable();

	_progressComplete = new ReplaySubject<boolean>();
	progressComplete$ = this._progressComplete.asObservable();

	readonly message = inject(MessageService);

	files: File[] = [];
	idDisabled = false;
	form: FormGroup<TilesetForm>;

	jsonValues: string[] = [''];
	presetValues: string[] = ['preset-1', 'preset-2', 'cad-drawing', 'custom'];
	presetDisplay: string[] = [
		'Preset 1 (Zoomlevel 8 and up)',
		'Preset 2 (Zoomlevel 1 and up) (Heavier data usage)',
		'CAD Drawing (Even heavier data usage)',
		'Custom'
	];

	get id(): FormControl {
		return this.form.get('id') as FormControl;
	}

	get geojsons(): FormArray {
		return this.form.get('geojson') as FormArray;
	}

	get preset(): FormControl {
		return this.form?.get('tippecanoe')?.get('preset') as FormControl;
	}

	get command(): FormControl {
		return this.form?.get('tippecanoe')?.get('command') as FormControl;
	}

	createFormGroup(): void {
		if (this.form) {
			this.form.reset();
		}

		this.form = new FormGroup<TilesetForm>({
			_id: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
			id: new FormControl({ value: '', disabled: false }, { nonNullable: true }),
			// appId: new FormControl('', { nonNullable: true }),
			tippecanoe: new FormGroup({
				preset: new FormControl('preset-1', { nonNullable: true }),
				command: new FormControl('', { nonNullable: true })
			}),
			geojson: new FormArray<FormControl<string>>([])
		});
	}

	removeJson(i: number): void {
		this.geojsons.removeAt(i);
	}

	addJson(value: string): void {
		this.geojsons.push(new FormControl({ value, disabled: true }, { nonNullable: true }));
	}

	addJsons(jsons?: string[]): void {
		this.geojsons.clear();
		this.geojsons.reset();

		if (!jsons) {
			return;
		}

		for (const json of jsons) {
			this.addJson(json);
		}
	}

	onFileChange(event: Event) {
		const fileList: FileList = (event.target as HTMLInputElement).files as FileList;
		if (fileList) {
			for (const file of Array.from(fileList)) {
				const newFile = new File([file], this.cleanGeoJsonName(file.name), {
					type: file.type
				});

				const filenames: string[] = this.files.map(file => file.name);
				if (!filenames.includes(newFile.name)) {
					this.files.push(newFile);
				}
			}
		}
	}

	changePreset(value?: string): string {
		const FILENAME = this.form.get('id')?.value || '';
		const GEOJSONS = this.geojsons.value.join(' ') || '';

		const str = () => {
			switch (value) {
				case 'preset-1':
					return `tippecanoe -f -o /data/${FILENAME}.mbtiles -B8 -z14 ${GEOJSONS}`;
				case 'preset-2':
					return `tippecanoe -f -o /data/${FILENAME}.mbtiles -B1 -z14 ${GEOJSONS}`;
				case 'cad-drawing':
					return `tippecanoe -f -pk -ps -o /data/${FILENAME}.mbtiles -B1 -Z0 -z20 ${GEOJSONS}`;
				case 'custom':
					return `tippecanoe -f -o {{FILENAME}} -B1 -z14`;
				default:
					return `tippecanoe -f -o /data/${FILENAME}.mbtiles -B8 -z14 ${GEOJSONS}`;
			}
		};

		this.command.setValue(str());
		return str();
	}

	cleanGeoJsonName(fileName: string): string {
		const file = fileName.replace(/\.[^/.]+$/, '');
		return `${slugify(file, {
			replacement: '_',
			remove: undefined, // remove characters that match regex, defaults to `undefined`
			lower: true, // convert to lower case, defaults to `false`
			strict: true, // strip special characters except replacement, defaults to `false`
			// locale: 'vi',      // language code of the locale to use
			trim: true // trim leading and trailing replacement chars, defaults to `true`
		})}.geojson`;
	}

	openUploadDialog(clientId: string, appId: string, id: string) {
		const data: DialogItem = {
			title: 'Upload GeoJSONS',
			message:
				'Uploading GeoJSONS will overwrite existing files with the same name. Do you want to start uploading?',
			buttons: [
				{
					key: 'Yes',
					type: 'primary',
					confirm: true
				},
				{
					key: 'No'
				}
			],
			confirm: 'Confirmed'
		};

		this.dialogRef = this.dialog.open(MessageDialogComponent, { data });
		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.acceptUploadFiles(clientId, appId, id);
				this.tilesetFacade.upload(this.files);
			}
		});
	}

	async acceptUploadFiles(clientId: string, appId: string, id: string) {
		const route = `${this.environment['yuno-tilegenerator']}/custom-tiles/${clientId}/${appId}/${id}/upload/geojson`;
		const formData = new FormData();
		for (const file of this.files) {
			formData.append('files', file);
		}

		const req = new HttpRequest('POST', `${route}`, formData, {
			reportProgress: true,
			responseType: 'json'
		});

		try {
			const event = await lastValueFrom(this.http.request(req));

			this._response.next((event as HttpResponse<TilesetCustom>).body);
			this.uploadComplete();
		} catch (err) {
			console.log('error uploading files');
		}

		// Subscribe to progress updates
		const subscription = this.http.request(req).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {
				// Calculate and update the progress percentage
				const progress = Math.round((100 * event.loaded) / (event.total || 0));
				this._progress.next(progress);
				// If progress is 100%, reset the ReplaySubject
				if (progress === 100) {
					this.message.sendToast(`Successfully uploaded GeoJsons!`, 'success');
					this._progressComplete.next(true);
					subscription.unsubscribe();
				}
				// You can update your UI or perform any other actions with the progress information
			} else if (event instanceof HttpResponse) {
				// Handle the successful response here (if needed)
			}
		});
	}

	uploadComplete() {
		if (this.files?.length) {
			const GeoJSONS: string[] = this.geojsons.value;
			const newGeoJSON = this.files.map(e => e.name);

			// remove all duplicates
			const merged = [...new Set([...GeoJSONS, ...newGeoJSON])];
			this.addJsons(merged);

			this.files = [];
			this.changePreset(this.preset.value);
		}
	}

	async downloadZip(appId: string, clientId: string, id: string) {
		const blob = await lastValueFrom(
			this.http.get(
				`${this.environment['yuno-tilegenerator']}/custom-tiles/${clientId}/${appId}/${id}/geojson`,
				{ responseType: 'blob' }
			)
		);
		if (!blob) {
			return;
		}

		const a = document.createElement('a');
		const objectUrl = URL.createObjectURL(blob);
		a.href = objectUrl;
		a.download = `${id}-geojsons.zip`;
		a.click();
		URL.revokeObjectURL(objectUrl);
	}

	async generateTileset(clientId: string, appId: string, id: string) {
		const route = `${this.environment['yuno-tilegenerator']}/custom-tiles/${clientId}/${appId}/${id}/generate`;
		const req = new HttpRequest('POST', `${route}`, this.form.getRawValue(), {
			reportProgress: true,
			responseType: 'json'
		});

		try {
			await lastValueFrom(this.http.request(req));
			const dialog: DialogItem = {
				title: 'Tile Generation',
				message: `Tile generation started, please be patient. You can safely close this window and the edit container.<br> <a class="underline" href="${this.getLogUrl(
					clientId,
					appId,
					id
				)}" target="_blank" rel="noopener">View the log</a> for more information about the status. You can refresh the log to see the latest status.`,
				buttons: [
					{
						key: 'Ok',
						type: 'primary',
						confirm: true
					}
				],
				confirm: 'Confirmed'
			};
			this.dialogRef = this.dialog.open(MessageDialogComponent, { data: dialog });
		} catch (err) {
			const dialog: DialogItem = {
				title: 'Tile Generation',
				message: `Error starting Tile Generation procedure, check your data.<br> <a class="underline" href="${this.getLogUrl(
					clientId,
					appId,
					id
				)}" target="_blank" rel="noopener">View the log</a> for more information. You can refresh the log to see the latest status.`,
				buttons: [
					{
						key: 'Ok',
						type: 'primary',
						confirm: true
					}
				],
				confirm: 'Confirmed'
			};
			this.dialogRef = this.dialog.open(MessageDialogComponent, { data: dialog });
		}
	}

	getLogUrl(clientId: string, appId: string, id: string): string {
		return `${this.environment['yuno-tilegenerator']}/custom-tiles/${clientId}/${appId}/${id}/log`;
	}

	openRemoveDialog(clientId: string, appId: string, id: string, deleting: JsonData) {
		const data: DialogItem = {
			title: 'Remove GeoJSON',
			message: 'Are you sure you want to remove this GeoJson?',
			buttons: [
				{
					key: 'Yes',
					type: 'primary',
					confirm: true
				},
				{
					key: 'No'
				}
			],
			confirm: 'Confirmed'
		};

		this.dialogRef = this.dialog.open(MessageDialogComponent, { data });
		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.acceptRemoveGeoJSON(clientId, appId, id, deleting);
			}
		});
	}

	async acceptRemoveGeoJSON(clientId: string, appId: string, id: string, deleting: JsonData) {
		const route = `${this.environment['yuno-tilegenerator']}/custom-tiles/${clientId}/${appId}/${id}/remove/${deleting.geojson}`;
		const requestOptions = {
			headers: new HttpHeaders().set('Content-Type', 'application/json')
		};

		try {
			await lastValueFrom(this.http.delete(route, requestOptions));
			this.removeJson(deleting.index);
		} catch (err) {
			const dialog: DialogItem = {
				title: 'Remove GeoJson',
				message: 'Error removing GeoJson from server, check your data',
				buttons: [
					{
						key: 'Ok',
						type: 'primary',
						confirm: true
					}
				],
				confirm: 'Confirmed'
			};
			this.dialogRef = this.dialog.open(MessageDialogComponent, { data: dialog });
		}
	}
}
