import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { catchError, firstValueFrom } from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core';

@Injectable({
	providedIn: 'root'
})
export class SpritesheetsService {
	readonly environment = inject(ENVIRONMENT);
	private readonly http = inject(HttpClient);
	private readonly sanitizer = inject(DomSanitizer);

	file: File[];
	images: string[];
	spriteapi = `${this.environment['yuno-cdn']}/spritesheet`;

	onFileChange(event: Event): void {
		this.file = [];
		this.images = [];

		const files = (event.target as HTMLInputElement).files as FileList;
		for (let i = 0; i < files.length; i++) {
			this.file.push(
				new File(
					[files.item(i) as File],
					`${this.setName((files.item(i) as File).name)}.svg`,
					{ type: (files.item(i) as File).type }
				)
			);

			/* Create preview */
			const reader = new FileReader();
			reader.onload = async () => {
				const value = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
				this.images.push(value as string);
			};

			reader.readAsDataURL(files.item(i) as File);
		}
	}

	setName(name: string) {
		return name.split('.')[0];
	}

	toHttpParams(params: Params) {
		return Object.getOwnPropertyNames(params).reduce(
			(p, key) => p.set(key, params[key]),
			new HttpParams()
		);
	}

	async get<T>(requestUrl: string, params?: Params) {
		const requestOptions = {
			params: this.toHttpParams({ ...params }),
			headers: new HttpHeaders().set('Content-Type', 'application/json')
		};

		return await firstValueFrom(
			this.http.get<T>(`${this.spriteapi}/${requestUrl}`, requestOptions).pipe(
				catchError((err: HttpErrorResponse) => {
					throw err;
				})
			)
		);
	}

	async downloadFile(requestUrl: string): Promise<Blob> {
		return await firstValueFrom(
			this.http.get(`${this.spriteapi}/${requestUrl}`, { responseType: 'blob' }).pipe(
				catchError((error: HttpErrorResponse) => {
					throw error;
				})
			)
		);
	}

	async post<T>(requestUrl: string, body: unknown = {}): Promise<unknown> {
		return firstValueFrom(
			this.http.post<T>(`${this.spriteapi}/${requestUrl}`, body).pipe(
				catchError((error: HttpErrorResponse) => {
					throw error;
				})
			)
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async delete<T>(requestUrl: string, body: any = {}): Promise<any> {
		return firstValueFrom(
			this.http.delete<T>(`${this.spriteapi}/${requestUrl}`, body).pipe(
				catchError((error: HttpErrorResponse) => {
					throw error;
				})
			)
		);
	}

	async postFile<T>(requestUrl: string, files: File[]): Promise<T> {
		const formData = new FormData();
		for (const file of files) {
			formData.append('files', file);
		}

		return firstValueFrom(
			this.http.post<T>(`${this.spriteapi}/${requestUrl}`, formData).pipe(
				catchError((error: HttpErrorResponse) => {
					throw error;
				})
			)
		);
	}
}
