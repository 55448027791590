import { gql } from 'apollo-angular';

export const DELETE_LEGEND = gql`
	mutation deleteLegend($_id: String!) {
		deleteLegend(_id: $_id)
	}
`;

export type DeleteLegendMutation = {
	deleteLegend: string;
};
