import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { Textblock } from '@yuno/api/interface';

import { textblocksActions } from './textblock.actions';

export interface TextblockState {
	loaded: boolean;
	textblocks: Partial<Textblock>[];
	selectedLoaded: boolean;
	selected: Partial<Textblock> | undefined;
	status: Status;
}

export const initialStateTextblock: TextblockState = {
	loaded: false,
	textblocks: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateTextblock,
	on(appActions.reset, () => initialStateTextblock),
	on(textblocksActions.load, state => ({
		...state,
		loaded: false,
		status: Status.VALIDATING
	})),
	on(textblocksActions.loadSuccess, (state, { data }) => ({
		...state,
		textblocks: data,
		loaded: true,
		status: Status.SUCCESS
	})),
	on(textblocksActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	})),

	on(textblocksActions.clearSelect, state => ({
		...state,
		selected: undefined,
		saved: undefined
	})),
	on(textblocksActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data as Textblock,
		selectedLoaded: true
	}))
);

export const textblocksFeature = createFeature({
	name: 'textblocks',
	reducer
});
