import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { MediaImageButton } from '@yuno/api/interface';

import { imageButtonsActions } from './image-button.actions';
import { imageButtonsFeature } from './image-button.state';

@Injectable()
export class ImageButtonFacade {
	private readonly store = inject(Store);

	imageButtons$ = this.store.pipe(select(imageButtonsFeature.selectImageButtons));
	selectedImageButton$ = this.store.pipe(select(imageButtonsFeature.selectSelected));
	loaded$ = this.store.pipe(select(imageButtonsFeature.selectLoaded));
	selectedLoaded$ = this.store.pipe(select(imageButtonsFeature.selectSelectedLoaded));

	get(): void {
		this.getImageButtons();
	}

	getImageButtons() {
		this.store.dispatch(imageButtonsActions.load());
	}

	onSelect(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(imageButtonsActions.select({ _id }));
	}

	clearSelect() {
		this.store.dispatch(imageButtonsActions.clearSelect());
	}

	update(data: Partial<MediaImageButton>) {
		this.store.dispatch(imageButtonsActions.updateSelect({ data }));
	}

	save() {
		this.store.dispatch(imageButtonsActions.save());
	}

	delete(_id: string) {
		this.store.dispatch(imageButtonsActions.delete({ _id }));
	}
}
