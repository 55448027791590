<ng-container [formGroup]="form">
	@if (item.label) {
		<span
			class="select-none"
			[innerHtml]="
				item.label
					| languageSelect: language
					| safeHtml: item.validators?.required || item.required
			">
		</span>
	}
</ng-container>
