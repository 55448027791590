import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';

import { FileQuery, FilesQuery, GET_FILES_BY_APPID, GET_FILE_BY_ID } from '../../utils';
import { filesActions } from './files.actions';

export const loadFiles = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(filesActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<FilesQuery>({
						query: GET_FILES_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.files) {
								throw new Error('no Files found');
							}
							return filesActions.loadSuccess({
								data: data.data.files
							});
						}),
						take(1),
						catchError(error => of(filesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadFilteredFiles = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(filesActions.loadFiltered),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.query<FilesQuery>({
						query: GET_FILES_BY_APPID,
						variables: {
							appId: appId,
							filter: data.filter
						}
					})
					.pipe(
						map(data => {
							if (!data.data.files) {
								throw new Error('no Files found');
							}
							return filesActions.loadSuccess({
								data: data.data.files
							});
						}),
						take(1),
						catchError(error => of(filesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectFile = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(filesActions.select),
			exhaustMap(file =>
				graphql
					.query<FileQuery>({
						query: GET_FILE_BY_ID,
						variables: {
							_id: file._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedFile) {
								throw new Error('no File with that id found');
							}
							return filesActions.selectSuccess({
								data: data.data.selectedFile
							});
						}),
						take(1),
						catchError(error => of(filesActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);
