import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { textButtonsActions } from './text-button.actions';
import { textButtonsFeature } from './text-button.state';

@Injectable()
export class TextButtonFacade {
	private readonly store = inject(Store);

	buttons$ = this.store.pipe(select(textButtonsFeature.selectButtons));
	status$ = this.store.pipe(select(textButtonsFeature.selectStatus));

	get() {
		this.store.dispatch(textButtonsActions.load());
	}
}
