import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
	static mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
		return (group: AbstractControl): ValidationErrors | null => {
			const control = (group as UntypedFormGroup).controls[controlName];
			const matchingControl = (group as UntypedFormGroup).controls[matchingControlName];

			if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
				return null;
			}

			// set error on matchingControl if validation fails
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({ mustMatch: true });
			} else {
				matchingControl.setErrors(null);
			}

			return null;
		};
	}

	static noSpacesLowerCase(): ValidatorFn {
		// RegEx code
		// spaces /\s/g
		// upppercase /[A-Z]/
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}

			let whitespace = false;
			let uppercase = false;

			if (control.value.match(/\s/g)) {
				control.setErrors({ whitespace: true });
				whitespace = true;
			}

			if (/[A-Z]/.test(control.value)) {
				control.setErrors({ uppercase: true });
				uppercase = true;
			}

			if (whitespace || uppercase) {
				return { noSpacesLowerCase: { whitespace, uppercase } };
			}

			control.setErrors(null);
			return null;
		};
	}
}
