import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { AsyncSubject, combineLatest, debounceTime, startWith, take, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { DatasetsFacade } from '@yuno/admin/features/datasets/data-access';
import { FencesFacade } from '@yuno/admin/features/fences';
import { LegendFacade } from '@yuno/admin/features/legends';
import { MapFacade } from '@yuno/admin/features/map';
import { ParticipationModelFacade } from '@yuno/admin/features/participation/data-access';
import { SidemenuFacade } from '@yuno/admin/features/sidemenus/data-access';
import { StatesFacade } from '@yuno/admin/features/states';
import { TextblockFacade, TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import { MapViewerComponent } from '@yuno/admin/features/themes/feature/editor/map-viewer/map-viewer.component';
import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoCardModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { Theme } from '@yuno/api/interface';

import { ThemeFacade } from '../../data-access';
import {
	ThemeDatasetsComponent,
	ThemeMapOptionsComponent,
	ThemeMapUiComponent,
	ThemePanoramaComponent,
	ThemeParticipateComponent,
	ThemeSettingsComponent
} from './form-editors';
import { ThemeEditorService } from './theme-editor.service';

export type tabOptions =
	| 'Settings'
	| 'Map Options'
	| 'Map UI'
	| 'Datasets'
	| 'Participate'
	| 'Panorama';

@Component({
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoEditContainerModule,
		YunoCardModule,
		YunoAdminButtonsModule,
		ThemeMapOptionsComponent,
		ThemeMapUiComponent,
		ThemePanoramaComponent,
		ThemeParticipateComponent,
		ThemeDatasetsComponent,
		ThemeSettingsComponent,
		MapViewerComponent,
		UserRightsMessageComponent,
		EditContainerContentDirective
	],
	selector: 'yuno-admin-theme-editor',
	templateUrl: './theme-editor.component.html',
	providers: [ThemeEditorService],
	styleUrls: ['./theme-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly appFacade = inject(AppFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly textblockFacade = inject(TextblockFacade);
	private readonly participationFacade = inject(ParticipationModelFacade);
	private readonly legendFacade = inject(LegendFacade);
	private readonly fenceFacade = inject(FencesFacade);
	private readonly stateFacade = inject(StatesFacade);
	private readonly sidemenuFacade = inject(SidemenuFacade);
	private readonly mapFacade = inject(MapFacade);
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);
	readonly service = inject(ThemeEditorService);

	originalData?: Partial<Theme>;
	addItems = false;
	desktopView = true;
	tabValue: tabOptions = 'Settings';

	/* 	an observerable that checks when legends are collected 	*/
	private _menusCreated = new AsyncSubject<boolean>();

	data$ = combineLatest({
		language: this.appFacade.language$.pipe(startWith('nl')),
		bounds: this.themeFacade.bounds$.pipe(
			tap(bounds => {
				if (!bounds) {
					return;
				}
				this.service.updateBounds(bounds, 'bounds');
			})
		),
		maxBounds: this.themeFacade.maxBounds$.pipe(
			tap(bounds => {
				if (!bounds) {
					return;
				}
				this.service.updateBounds(bounds, 'maxBounds');
			})
		),
		theme: this.themeFacade.selectedTheme$.pipe(
			tap(data => {
				if (!this.originalData) {
					this.originalData = data;

					this.service.addDatasets(data?.datasets);
					this.service.addAvailableStates(data?.states?.available);
					this.service.addModuleParticipationDatas(data?.modules?.participation?.data);

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					this.service.form.patchValue(this.originalData as any);
					this.service.updateMapStyles(this.originalData as any);

					if (
						this.originalData?.view?.maxBounds &&
						this.originalData?.view?.maxBounds.length > 1
					) {
						this.service.updateBounds(this.originalData.view.maxBounds, 'maxBounds');
					}
					this.cdr.markForCheck();
				}

				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
				}
			})
		),
		textfields: this.textfieldFacade.allTextfields$.pipe(
			tap(data => {
				if (data) {
					this.service.textfieldValues = ['', ...data.map(text => text._id)] as string[];
					this.service.textfieldDisplay = [
						'none',
						...data.map(text => text.id)
					] as string[];
				}
			})
		),
		textblocks: this.textblockFacade.allTextblocks$.pipe(
			tap(data => {
				this.service.textblockDisplay = ['none', ...data.map(text => text.id)] as string[];
				this.service.textblockValues = ['', ...data.map(text => text._id)] as string[];
			})
		),
		legend: this.legendFacade.legends$.pipe(
			tap(data => {
				this.service.legendDisplay = ['none', ...data.map(object => object.id)] as string[];
				this.service.legendValues = ['', ...data.map(object => object._id)] as string[];
			})
		),
		states: this.stateFacade.states$.pipe(
			tap(data => {
				this.service.statesValues = ['', ...data.map(state => state._id)] as string[];
				this.service.statesDisplay = [
					'none',
					...data.map(state => state.state)
				] as string[];
			})
		),
		datasets: this.datasetFacade.datasets$.pipe(
			tap(data => {
				this.service.datasetValues = data.map(data => data._id) as string[];
				this.service.datasetDisplay = data.map(data => data.id) as string[];
			})
		),
		mapStyles: this.mapFacade.mapStyles$.pipe(
			tap(data => {
				this.service.styleDisplay = data?.map(style => style.id) as string[];
				this.service.styleValues = data?.map(style => style._id) as string[];
			})
		),
		fences: this.fenceFacade.fences$.pipe(
			tap(data => {
				this.service.fencesDisplay = ['none', ...data.map(fence => fence.id)] as string[];
				this.service.fencesValues = ['', ...data.map(fence => fence._id)] as string[];
			})
		),
		participation: this.participationFacade.data$.pipe(
			tap(data => {
				this.service.participateDisplay = data.map(data => data.id) as string[];
				this.service.participateValues = data.map(data => data._id) as string[];
			})
		),
		sidemenus: this.sidemenuFacade.data$.pipe(
			tap(data => {
				this.service.sidemenuDisplay = ['none', ...data.map(text => text.id)] as string[];
				this.service.sidemenuValues = ['', ...data.map(text => text._id)] as string[];
				if (data && data.length >= 1) {
					this._menusCreated.next(true);
					this._menusCreated.complete();
				}
			})
		),
		sidemenusCreated: this._menusCreated.asObservable().pipe(startWith(false))
	});

	ngOnInit(): void {
		this.mapFacade.getMapStyles();
		this.textfieldFacade.get();
		this.textblockFacade.get();
		this.participationFacade.get();
		this.legendFacade.getLegendsByAppId();
		this.stateFacade.get();
		this.datasetFacade.get();
		this.fenceFacade.get();
		this.sidemenuFacade.get();
		this.service.createFormGroup();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.themeFacade.select(id);
		});

		!this.minimalAppRole(this.userRoles.EDITOR) && this.service.form.disable();

		this.languageSelector();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.themeFacade.updateSelect(this.service.form.getRawValue() as Partial<Theme>);
			});
	}

	onSave() {
		this.themeFacade.save();
	}

	onClose(): void {
		redirectTo(this.route, this.router);
		this.themeFacade.clearSelect();
		this.mapFacade.clearStyle();
	}

	onSwitchTab(key: string) {
		this.tabValue = key as tabOptions;
	}

	ngOnDestroy() {
		this.themeFacade.clearSelect();
		this.destroyLanguageSelector();
	}
}
