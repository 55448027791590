import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { JsonButtonEventForm } from '../json-forms.models';

@Injectable()
export class ActionsService {
	private action: Subject<JsonButtonEventForm> = new Subject<JsonButtonEventForm>();
	action$: Observable<JsonButtonEventForm> = this.action.asObservable();

	sendAction(action: JsonButtonEventForm): void {
		this.action.next(action);
	}
}
