import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PrivacyStatement } from '@yuno/api/interface';

export const privacyStatementActions = createActionGroup({
	source: 'PrivacyStatements',
	events: {
		values: emptyProps(),
		valuesPending: emptyProps(),
		valuesSuccess: props<{
			data: Partial<PrivacyStatement>[];
		}>(),
		clear: emptyProps(),
		clearSelected: emptyProps(),
		valuesFailure: props<{
			error: Error;
		}>(),
		saveValue: emptyProps(),
		saveValueSuccess: emptyProps(),
		saveValueFailure: props<{
			error: Error;
		}>(),
		value: props<{
			id: string;
		}>(),
		valueSuccess: props<{
			data: PrivacyStatement;
		}>(),
		valueFailure: props<{
			error: Error;
		}>(),
		patchValue: props<{
			data: Partial<PrivacyStatement>;
		}>()
	}
});
