import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ReplaySubject, firstValueFrom, lastValueFrom } from 'rxjs';
import slugify from 'slugify';

import { ENVIRONMENT } from '@yuno/admin/core';
import { MessageService } from '@yuno/angular/notifications';
import { ProjectTilesets } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class TilesetsService {
	private readonly environment = inject(ENVIRONMENT);
	private readonly http = inject(HttpClient);
	private readonly message = inject(MessageService);
	clientId: string;
	appId: string;

	async get(): Promise<ProjectTilesets> {
		return firstValueFrom(
			this.http.get<ProjectTilesets>(
				`${this.environment['yuno-tilegenerator']}/tilesets/${this.clientId}/${this.appId}`
			)
		);
	}

	// MBTILES
	files: File[] = [];
	close$ = new ReplaySubject<void>();

	onFileChange(event: Event) {
		const fileList: FileList = (event.target as HTMLInputElement).files as FileList;
		if (fileList) {
			for (const file of Array.from(fileList)) {
				const newFile = new File([file], this.cleanMbtileName(file.name), {
					type: file.type
				});
				const filenames: string[] = this.files.map(file => file.name);
				if (!filenames.includes(newFile.name)) {
					this.files.push(newFile);
				}
			}
		}
	}

	cleanMbtileName(fileName: string): string {
		return `${slugify(fileName.replace(/\.[^/.]+$/, ''), '_')}.mbtiles`;
	}

	onClose() {
		this.close$.next();
		this.files = [];
	}

	async upload() {
		const formData = new FormData();
		for (const file of this.files) {
			formData.append('files', file);
		}

		const route = `${this.environment['yuno-tileserver']}/data/${this.appId}`;
		try {
			await lastValueFrom(
				this.http.post(route, formData, { reportProgress: true, responseType: 'json' })
			);
			this.message.sendToast(`MBTiles successfully uploaded!`, 'success');
		} catch (err) {
			this.message.sendToast(`Error uploading MBTiles!`, 'error');
		}
	}
}
