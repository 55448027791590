import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { ParticipationNotifications } from '@yuno/api/interface';

import { notificationsActions } from './notifications.actions';
import { notificationsFeature } from './notifications.state';

@Injectable()
export class ParticipationNotificationFacade {
	private readonly store = inject(Store);

	data$ = this.store.pipe(select(notificationsFeature.selectData));
	selected$ = this.store.pipe(select(notificationsFeature.selectSelected));

	get(): void {
		this.store.dispatch(notificationsActions.load());
	}

	select(id: string): void {
		this.store.dispatch(notificationsActions.select({ _id: id }));
	}

	updateSelected(data: ParticipationNotifications): void {
		this.store.dispatch(notificationsActions.updateSelect({ data }));
	}

	duplicate(id: string): void {
		this.store.dispatch(notificationsActions.duplicate({ _id: id }));
	}

	delete(id: string): void {
		this.store.dispatch(notificationsActions.delete({ _id: id }));
	}

	save(): void {
		this.store.dispatch(notificationsActions.save());
	}

	clearSelected(): void {
		this.store.dispatch(notificationsActions.clearSelect());
	}

	reset(): void {
		this.store.dispatch(notificationsActions.reset());
	}
}
