import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgClass, NgStyle } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	Output,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	inject
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { TextListItemComponents } from '@yuno/api/interface';

@Component({
	selector: 'yuno-textfield-list-dropdown',
	templateUrl: './dropdown.component.html',
	styleUrls: ['./dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgStyle, NgClass]
})
export class TextListDropdownComponent implements OnDestroy {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;

	private overlay = inject(Overlay);
	private viewContainerRef = inject(ViewContainerRef);
	private cdr = inject(ChangeDetectorRef);

	@ViewChild('button', { read: ElementRef }) button: ElementRef;
	@ViewChild('dropdownContainer', { read: TemplateRef }) dropdownContainer: TemplateRef<unknown>;

	private overlayRef: OverlayRef;
	private _activeItem: string;

	_selectOptions: string[];
	_currentValue: string;

	active = false;

	isDropdownOpen = false;

	private destroyed$ = new Subject<boolean>();

	private _color = '#fff';
	@Input() set color(color: string | undefined) {
		this._color = color || '#fff';
	}

	get color(): string {
		return this._color;
	}

	private _backgroundColor = '#00ADF1';
	@Input() set backgroundColor(color: string | undefined) {
		this._backgroundColor = color || '#00ADF1';
	}

	get backgroundColor(): string {
		return this._backgroundColor;
	}

	@Input() resettable = true;
	@Input() resetValue?: string;
	@Input() defaultValue?: string;

	@Input()
	set currentValue(val: string) {
		this._currentValue = val;
	}

	get currentValue(): string {
		return this._currentValue;
	}

	@Input()
	set options(data: string | string[]) {
		if (Array.isArray(data)) {
			this._selectOptions = data;
			return;
		}

		try {
			this._selectOptions = JSON.parse(data);
		} catch (e) {
			// if json parse fails, try splitting the string
			// remove leading and trailing whitespaces
			this._selectOptions = data.split(',').map(option => option.trim());
		}
	}

	get options(): string[] {
		return this._selectOptions;
	}

	_items?: TextListItemComponents[];
	@Input() set items(items: TextListItemComponents[] | undefined) {
		this._items = items;
		items && this.setItems(items);
	}

	get items(): TextListItemComponents[] | undefined {
		return this._items;
	}

	@Output() activeValue = new EventEmitter<string>();

	setItems(items: TextListItemComponents[]): void {
		this.options = items?.map(item => item.title as string);

		const active = items.find(item => item.active);
		if (active && this._activeItem !== active.title) {
			this._activeItem = active.title as string;
			this.onSelect(this._activeItem);
		}
	}

	onSelect(val: string) {
		// Only fire once
		if (this.currentValue === val) {
			return;
		}

		this.active = val.length >= 1;

		this.currentValue = val;
		this.activeValue.emit(val);

		this.cdr.markForCheck();

		this.destroyDropdown();
	}

	// DROPDOWN OPTIONS
	toggleDropdown(): void {
		this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
	}

	openDropdown(): void {
		this.isDropdownOpen = true;

		this.overlayRef = this.overlay.create({
			width: this.button.nativeElement.clientWidth,
			hasBackdrop: true,
			maxHeight: '96%',
			backdropClass: 'cdk-overlay-transparent-backdrop',
			scrollStrategy: this.overlay.scrollStrategies.close(),
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(this.button)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
						offsetY: -8
					}
				])
		});

		const templatePortal = new TemplatePortal(this.dropdownContainer, this.viewContainerRef);
		this.overlayRef.attach(templatePortal);

		this.destroyed$.next(false);
		this.overlayRef
			.backdropClick()
			.pipe(takeUntil(this.destroyed$))
			.subscribe(() => this.destroyDropdown());
	}

	destroyDropdown(): void {
		if (!this.overlayRef || !this.isDropdownOpen) {
			return;
		}

		this.isDropdownOpen = false;
		this.overlayRef.detach();

		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	ngOnDestroy(): void {
		this.destroyDropdown();
		this.destroyed$.complete();
	}
}
