import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { combineLatest, startWith, tap } from 'rxjs';

import { GifPlayComponent, YunoAdminButtonComponent, YunoCardModule } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { MessageDialogComponent } from '@yuno/angular/notifications';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { TextVideoComponent } from '@yuno/angular/textfield';
import { UserRolesEnum, YunoUserRolesEnum } from '@yuno/api/interface';

import { DashboardFacade } from '../../data-access';
import { MATOMO_AUTH_TOKEN } from '../internal-view/dashboard.component';

@Component({
	standalone: true,
	imports: [
		RouterModule,
		GifPlayComponent,
		TextVideoComponent,
		AngularPipesModule,
		YunoCardModule,
		YunoAdminButtonComponent,
		TranslocoModule,
		AsyncPipe,
		NgClass
	],
	providers: [
		{
			provide: 'matomoToken',
			useValue: ''
		}
	],
	selector: 'yuno-admin-dashboard-client',
	templateUrl: './dashboard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardClientViewComponent extends AppDataComponent implements OnInit {
	private readonly matomoAuthToken = inject(MATOMO_AUTH_TOKEN);
	private readonly dashboardFacade = inject(DashboardFacade);

	dialogRef: MatDialogRef<MessageDialogComponent>;

	// USER/APP ROLE ENUM SELECTORS
	readonly authRoles = UserRolesEnum;
	readonly authAppRoles = YunoUserRolesEnum;

	currentPage: 'dashboard' | 'planning' | 'deliverables' = 'dashboard';

	welcomeLinks = [
		{
			key: 'Live',
			href: `https://${this.clientUrl}.${this.environment['yuno-main']}/${this.appUrl}/`
		},
		{
			key: 'Internal',
			href: `https://${this.clientUrl}.${this.environment['yuno-main']}/${this.appUrl}/intern/`
		}
	];

	// Links
	links = [
		{
			key: 'editText',
			href: `${this.environment['yuno-cdn']}/content/cms/manuals/${this.language}/edit-text.pdf`
		},
		{
			key: 'participate',
			href: `${this.environment['yuno-cdn']}/content/cms/manuals/${this.language}/participate.pdf`
		},
		{
			key: 'placeMarkers',
			href: `${this.environment['yuno-cdn']}/content/cms/manuals/${this.language}/place-markers.pdf`
		}
	];

	get matomoLink(): string | undefined {
		const trackingId = this.trackingId;
		if (!trackingId) {
			return undefined;
		}

		return `${this.environment['yuno-matomo']}/index.php?module=Widgetize&action=iframe&containerId=VisitOverviewWithGraph&disableLink=0&widget=1&moduleToWidgetize=CoreHome&actionToWidgetize=renderWidgetContainer&idSite=${trackingId}&period=month&date=yesterday&disableLink=1&widget=1&token_auth=${this.matomoAuthToken}`;
	}

	data$ = combineLatest({
		dashboard: this.dashboardFacade.dashboard$.pipe(startWith(null)),
		itemLoaded: this.dashboardFacade.selectedLoaded$.pipe(
			tap(selected => {
				if (Number(selected) >= 0) {
					this.router.navigate(['edit', selected], {
						relativeTo: this.route
					});
				}
			})
		)
	});

	ngOnInit(): void {
		if (this.route?.snapshot?.data['type']) {
			this.currentPage = this.route.snapshot.data['type'];
		}
		this.dashboardFacade.getDashboardByAppId(this.currentPage);
	}

	addHelpItem(): void {
		this.dashboardFacade.addDashboardItem();
	}
}
