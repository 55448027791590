import { gql } from 'apollo-angular';

import { AppUserList, User } from '@yuno/api/interface';

export const GET_USERS = gql`
	query getUsers {
		users {
			displayName
			email
			role
			language
			dateLastLogin

			allowPasswordReset
			allowResendInvitation
		}
	}
`;

export const GET_USER_APPLIST = gql`
	query getUserApps($email: String!) {
		getUserApps(email: $email) {
			appId
			appName
			role
		}
	}
`;

export type UsersQuery = {
	users: Partial<User>[];
};

export type UserAppData = {
	getUserApps: AppUserList;
};
