import { gql } from 'apollo-angular';

import { State } from '@yuno/api/interface';

export const SAVE_STATE = gql`
	mutation saveState($state: StateInput!, $appId: String!) {
		saveState(state: $state, appId: $appId) {
			_id
			state
			updatedAt
		}
	}
`;

export const DUPLICATE_STATE = gql`
	mutation duplicateStateById($_id: String!, $appId: String!) {
		duplicateState(_id: $_id, appId: $appId) {
			_id
			state
			updatedAt
		}
	}
`;

export type SaveStateMutation = {
	saveState: Partial<State>;
};

export type DuplicateStateMutation = {
	duplicateState: Partial<State>;
};
