import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/utils';
import { Client } from '@yuno/api/interface';

import { clientsActions } from './clients.actions';

export interface ClientsState {
	loaded: boolean;
	clients: Partial<Client>[];
	selectedLoaded: boolean;
	selected: Partial<Client> | undefined;
	status: Status;
}

export const initialState: ClientsState = {
	loaded: false, // has the Users list been loaded
	clients: [], // which Users record has been selected
	selectedLoaded: false, // last known error (if any)
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(clientsActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(clientsActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		clients: data,
		status: Status.SUCCESS
	})),
	on(clientsActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(clientsActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(clientsActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(clientsActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(clientsActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data }
	})),
	on(clientsActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(clientsActions.saveSuccess, state => {
		const index = state.clients.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const clients = [...state.clients];
		if (index >= 0) {
			clients[index] = { ...clients[index], ...state.selected };
		} else if (state.selected) {
			clients.push({ ...state.selected });
		}
		return {
			...state,
			clients: clients
		};
	})
);

export const clientsFeature = createFeature({
	name: 'clients',
	reducer
});
