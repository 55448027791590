import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { navbarActions } from './navbar.actions';
import { NavbarRoute, NavbarRouteTemp, navbarFeature } from './navbar.state';

@Injectable()
export class NavbarFacade {
	private readonly store = inject(Store);

	routes$ = this.store.pipe(select(navbarFeature.selectRoutes));

	/**
	 * Adds Route to the Navbar component
	 *
	 * When provinding route.route it will take the route defined by the user
	 * When not providing the route.route, you must provide a activatedRoute
	 * It will then generate the route url automatically
	 *
	 * @param {NavbarRouteTemp} route
	 * @param {ActivatedRoute} [activatedRoute]
	 */
	addRoute(route: NavbarRouteTemp, activatedRoute?: ActivatedRouteSnapshot) {
		const newRoute: NavbarRoute =
			!route.route && activatedRoute
				? { ...route, route: this.getRouteUrl(activatedRoute) }
				: (route as NavbarRoute);

		this.store.dispatch(navbarActions.addRoute({ route: newRoute }));
	}

	getRouteUrl(route: ActivatedRouteSnapshot): string[] {
		return [
			'/',
			...route.pathFromRoot
				.map(r => r.url)
				.flatMap(f => f)
				.map(f => f.path)
		];
	}

	removeAt(index: number): void {
		this.store.dispatch(navbarActions.removeAt({ index }));
	}
}
