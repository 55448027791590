import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { StyleSpecification } from 'maplibre-gl';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_MARKER,
	DUPLICATE_MARKER,
	DeleteMarkerMutation,
	DuplicateMarkerMutation,
	GET_MARKERS_BY_APPID,
	GET_MARKER_BY_ID,
	MarkersQuery,
	SAVE_MARKER,
	SaveMarkerMutation,
	SelectMarkerQuery
} from '@yuno/admin/features/markers/utils';
import { GraphQLService } from '@yuno/angular-graphql';
import { ApiService } from '@yuno/angular/api';
import { MessageService } from '@yuno/angular/notifications';

import { markersActions } from './markers.actions';
import { markersFeature } from './markers.state';

export const loadMarkers = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markersActions.load, markersActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<MarkersQuery>({
						query: GET_MARKERS_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.markers) {
								throw new Error('no Marker found');
							}
							return markersActions.loadSuccess({
								data: data.data.markers
							});
						}),
						take(1),
						catchError(error => of(markersActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectMarker = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(markersActions.select),
			switchMap(marker =>
				graphql
					.query<SelectMarkerQuery>({
						query: GET_MARKER_BY_ID,
						variables: {
							_id: marker._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedMarker) {
								message.sendToast(`Error getting marker data!`, 'error');
								throw new Error('no Marker with that id found');
							}

							return markersActions.selectSuccess({
								data: data.data.selectedMarker
							});
						}),
						take(1),
						catchError(error => of(markersActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateMarker = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markersActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating marker!`, 'error');
					throw new Error('no Marker found');
				}

				return markersActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(markersActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveMarker = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markersActions.save),
			withLatestFrom(
				store.pipe(select(markersFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveMarkerMutation>({
						mutation: SAVE_MARKER,
						variables: {
							marker: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveMarker) {
								throw new Error('error saving Marker to the database');
							}

							return markersActions.saveSuccess({ data: data.data.saveMarker });
						}),
						take(1),
						catchError(error => of(markersActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateMarker = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markersActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([marker, appId]) =>
				graphql
					.mutate<DuplicateMarkerMutation>({
						mutation: DUPLICATE_MARKER,
						variables: {
							_id: marker._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateMarker) {
								throw new Error('error duplicating Marker');
							}
							store.dispatch(markersActions.reload());
							return markersActions.duplicateSuccess({
								data: data.data.duplicateMarker
							});
						}),
						take(1),
						catchError(error => of(markersActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteMarker = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(markersActions.delete),
			switchMap(marker =>
				graphql
					.mutate<DeleteMarkerMutation>({
						mutation: DELETE_MARKER,
						variables: {
							_id: marker._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteMarker) {
								throw new Error('error deleting Marker from the database');
							}
							store.dispatch(markersActions.reload());

							return markersActions.deleteSuccess({
								_id: data.data.deleteMarker
							});
						}),
						take(1),
						catchError(error => of(markersActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveMarkerSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markersActions.saveSuccess),
			tap(() => {
				message.sendToast(`Marker successfully saved!`, 'success');
			}),
			switchMap(() => of(markersActions.saveSuccessReset()))
		),
	{ functional: true }
);

export const saveMarkerFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markersActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Marker!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateMarkerSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markersActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Marker successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateMarkerFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markersActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Marker!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteMarkerSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markersActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Marker successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteMarkerFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(markersActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Marker!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const loadMapStyle = createEffect(
	(actions$ = inject(Actions), api = inject(ApiService)) =>
		actions$.pipe(
			ofType(markersActions.loadMapStyle),
			switchMap(action =>
				api.getObservable<StyleSpecification>(action.style).pipe(
					map(data => {
						return markersActions.loadMapStyleSuccess({ data });
					}),
					take(1),
					catchError(error => of(markersActions.loadMapStyleFailure({ error })))
				)
			)
		),
	{ functional: true }
);
