import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Fence } from '@yuno/api/interface';

import { fencesActions } from './fences.actions';

export interface FencesState {
	loaded: boolean;
	fences: Partial<Fence>[];
	selectedLoaded: boolean;
	selected: Partial<Fence> | undefined;
	multi: Partial<Fence>[];
	status: Status;
}

export const initialState: FencesState = {
	loaded: false,
	fences: [],
	selectedLoaded: false,
	selected: undefined,
	multi: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(fencesActions.load, state => ({
		...state,
		loaded: false,
		status: Status.VALIDATING
	})),
	on(fencesActions.loadFencesSuccess, (state, { fences }) => ({
		...state,
		loaded: true,
		fences: fences,
		status: Status.SUCCESS
	})),
	on(fencesActions.loadFencesFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	// Select
	on(fencesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(fencesActions.selectFenceSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(fencesActions.selectFenceFailure, (state, { error }) => ({ ...state, error })),

	// Select Update
	on(fencesActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(fencesActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	// Save Update
	on(fencesActions.saveSuccess, state => {
		const index = state.fences.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const fences = [...state.fences];
		if (index >= 0) {
			fences[index] = { ...fences[index], ...state.selected };
		} else if (state.selected) {
			fences.push({ ...state.selected });
		}
		return {
			...state,
			fences: fences
		};
	}),

	// Select
	on(fencesActions.multiClear, state => ({
		...state,
		multi: []
	})),
	on(fencesActions.multiSuccess, (state, { data }) => ({
		...state,
		multi: data
	}))
);

export const fencesFeature = createFeature({
	name: 'fences',
	reducer
});
