import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { StyleSpecification } from 'maplibre-gl';

import { GeoPhotosCategory } from '@yuno/api/interface';

export const categoriesActions = createActionGroup({
	source: 'GeoPhotos Categories',
	events: {
		create: emptyProps(),
		createSuccess: props<{ id: string }>(),
		createFailure: props<{ error: Error }>(),

		load: emptyProps(),
		loadSuccess: props<{ data: Partial<GeoPhotosCategory>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<GeoPhotosCategory> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<GeoPhotosCategory> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<GeoPhotosCategory> }>(),
		updateSelectSuccess: props<{ data: Partial<GeoPhotosCategory> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		loadMapStyle: props<{ style: string }>(),
		loadMapStyleSuccess: props<{ data: StyleSpecification }>(),
		loadMapStyleFailure: props<{ error: Error }>(),

		reset: emptyProps()
	}
});
