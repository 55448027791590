import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { layoutActions } from './layout.actions';

export const toggleSidemenu = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(layoutActions.toggle),
			tap(data => {
				if (data.toggle === undefined) {
					throw new Error('toggle value is undefined');
				}
				return data.toggle;
			})
		),
	{ dispatch: false, functional: true }
);

export const setLanguage = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(layoutActions.language),
			tap(data => {
				if (!data.language) {
					throw new Error('no language found');
				}
				return data.language;
			})
		),
	{ dispatch: false, functional: true }
);

export const setMode = createEffect(
	(actions$ = inject(Actions)) =>
		actions$.pipe(
			ofType(layoutActions.mode),
			tap(data => {
				if (!data.mode) {
					throw new Error('no mode found');
				}
				return data.mode;
			})
		),
	{ dispatch: false, functional: true }
);
