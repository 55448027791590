import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AppUserList, SafeUser, User, UserRoles } from '@yuno/api/interface';

export const usersActions = createActionGroup({
	source: 'Users',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<SafeUser>[] }>(),
		loadFailure: props<{ error: Error }>(),

		userData: props<{ email: string }>(),
		userDataSuccess: props<{ data: AppUserList }>(),
		userDataFailure: props<{ error: Error }>(),
		clearData: emptyProps(),

		updateRole: props<{ role: UserRoles; index: number }>(),
		updateRoleSuccess: props<{ role: UserRoles; index: number }>(),
		updateRoleFailure: props<{ error: Error }>(),

		addUser: props<{ user: Partial<User> }>(),
		addUserSuccess: props<{ user: Partial<SafeUser> }>(),
		addUserFailure: props<{ error: Error }>(),

		resetPassword: props<{ email: string }>(),
		resetPasswordSuccess: emptyProps(),
		resetPasswordFailure: props<{ error: Error }>(),

		resendInvite: props<{ email: string }>(),
		resendInviteSuccess: emptyProps(),
		resendInviteFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>()
	}
});
