import { gql } from 'apollo-angular';

import { Legend } from '@yuno/api/interface';

export const GET_LEGENDS_BY_APPID = gql`
	query getLegendsByAppId($appId: String!) {
		legends(appId: $appId) {
			_id
			id
		}
	}
`;

export const GET_LEGEND_BY_ID = gql`
	query getLegendById($_id: String!) {
		selectedLegend(_id: $_id) {
			_id
			id
			title
			openOnStartup
			content {
				public
				title
				bold
				class
				color
				border
				borderColor
				svg
				image
				gradiant {
					color
					offset
					title
				}
				gradiantHeight
				layer {
					_id
					id
				}
				activeLayers {
					_id
					id
				}
				list {
					public
					title
					bold
					class
					color
					border
					borderColor
					svg
					image
					gradiant {
						color
						offset
						title
					}
					gradiantHeight
					layer {
						_id
						id
					}
					activeLayers {
						_id
						id
					}
				}
			}
		}
	}
`;

export type LegendsQuery = {
	legends: Legend[];
};

export type SelectLegendQuery = {
	selectedLegend: Legend;
};
