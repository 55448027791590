import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { TextCustom } from '@yuno/api/interface';

import { textCustomsActions } from './text-custom.actions';

export interface TextCustomState {
	customs: Partial<TextCustom>[];
	status: Status;
}

export const initialStateTextCustom: TextCustomState = {
	customs: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialStateTextCustom,
	on(appActions.reset, () => initialStateTextCustom),
	on(textCustomsActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(textCustomsActions.loadSuccess, (state, { data }) => ({
		...state,
		customs: data,
		status: Status.SUCCESS
	})),
	on(textCustomsActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	}))
);

export const textCustomsFeature = createFeature({
	name: 'textCustoms',
	reducer
});
