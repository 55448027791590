@if (language$ | async; as lang) {
	<div class="relative">
		<!-- text -->
		@if (item.type === 'paragraph') {
			<yuno-json-forms-paragraph
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-paragraph>
		}

		<!-- text -->
		@if (item.type === 'text') {
			<yuno-json-forms-text
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-text>
		}

		<!-- email -->
		@if (item.type === 'email') {
			<yuno-json-forms-email
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-email>
		}

		<!-- password -->
		@if (item.type === 'password') {
			<yuno-json-forms-password
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-password>
		}

		<!-- telephone -->
		@if (item.type === 'tel') {
			<yuno-json-forms-telephone
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-telephone>
		}

		<!-- url -->
		@if (item.type === 'url') {
			<yuno-json-forms-url
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-url>
		}

		<!-- 'date', 'datetime-local' -->
		@if (['date', 'datetime-local'].includes(item.type)) {
			<yuno-json-forms-date
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-date>
		}

		<!-- number -->
		@if (item.type === 'number') {
			<yuno-json-forms-number
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-number>
		}

		<!-- range -->
		@if (item.type === 'range') {
			<yuno-json-forms-range
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-range>
		}

		<!-- select -->
		@if (item.type === 'select') {
			<yuno-json-forms-select
				[form]="form"
				[control]="control"
				[item]="item"
				[display]="item.selectDisplay"
				[language]="lang"></yuno-json-forms-select>
		}

		<!-- textarea -->
		@if (item.type === 'textarea') {
			<yuno-json-forms-textarea
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-textarea>
		}

		<!-- checkbox -->
		@if (item.type === 'checkbox') {
			<yuno-json-forms-checkbox
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-checkbox>
		}

		<!-- radio -->
		@if (item.type === 'radio') {
			<yuno-json-forms-radio
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-radio>
		}

		<!-- toggle -->
		@if (item.type === 'toggle') {
			<yuno-json-forms-toggle
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-toggle>
		}

		<!-- file -->
		@if (item.type === 'file') {
			<yuno-json-forms-file
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-file>
		}

		<!-- image -->
		@if (item.type === 'image') {
			<yuno-json-forms-file
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"
				[accept]="'image/*'"></yuno-json-forms-file>
		}

		<!-- color -->
		@if (item.type === 'color') {
			<yuno-json-forms-color
				[form]="form"
				[control]="control"
				[item]="item"
				[language]="lang"></yuno-json-forms-color>
		}

		@if (
			(validationMessage && (validationService.validate$ | async) && control.invalid) ||
			(control.invalid && (control.dirty || control.touched))
		) {
			<div class="invalid-message">
				{{ validationMessage | languageSelect: lang }}
			</div>
		}
	</div>
}
