@if (service.textfields$ | async) {
	@if (form) {
		<form [formGroup]="form">
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				<yuno-forms-select
					formControlName="id"
					label="Textfield"
					(changes)="onValueChanged($event)"
					[display]="service.textfieldSelectDisplay"
					[selectValues]="service.textfieldSelectValues"
					[customProperties]="true"
					placeholder="Select a Textfield..."></yuno-forms-select>
				@if (customProperty) {
					<yuno-forms-text formControlName="id"></yuno-forms-text>
					<yuno-admin-annotation type="info">
						<yuno-forms-span>
							To use <b>Place Markers</b>, follow this syntax: <br />
							<b>&#123;</b><b>&#123;properties.customProperties.--key--&#125;</b
							><b>&#125;</b><br />
							<br />
							This syntax is used to open the textfield stored within the marker,
							where
							<b>--key--</b> is the form key defined in the <b>Input Field tab</b> in
							the <b>Place Marker Editor</b>.
						</yuno-forms-span>
					</yuno-admin-annotation>
				}
			</div>
		</form>
	}
}
