import { gql } from 'apollo-angular';

import { CdnFile } from '@yuno/api/interface';

export const GET_FILES_BY_APPID = gql`
	query getFilesByAppId($appId: String!, $filter: String) {
		files(appId: $appId, filter: $filter) {
			_id
			type
			data {
				url
				fileName
				sizes
				maxSize
				ext
				originalSize
			}
			updatedAt
		}
	}
`;

export type FilesQuery = {
	files: Partial<CdnFile>[];
};

export const GET_FILE_BY_ID = gql`
	query getFileById($_id: String!) {
		selectedFile(_id: $_id) {
			_id
			data {
				url
				fileName
				sizes
				maxSize
				ext
				originalSize
			}
			updatedAt
		}
	}
`;

export type FileQuery = {
	selectedFile: Partial<CdnFile>;
};
