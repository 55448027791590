import { NgModule } from '@angular/core';

import { CodeEditorComponent } from './code-editor.component';

@NgModule({
	declarations: [],
	imports: [CodeEditorComponent],
	providers: [],
	exports: [CodeEditorComponent]
})
export class YunoAdminCodeEditorModule {}
