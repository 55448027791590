import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { textLinksActions } from './text-link.actions';
import { textLinksFeature } from './text-link.state';

@Injectable()
export class TextLinkFacade {
	private readonly store = inject(Store);

	links$ = this.store.pipe(select(textLinksFeature.selectLinks));
	status$ = this.store.pipe(select(textLinksFeature.selectStatus));

	get() {
		this.store.dispatch(textLinksActions.load());
	}
}
