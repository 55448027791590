import { Injectable, inject } from '@angular/core';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { FeatureCollection, Polygon, Position } from 'geojson';
import { DrawMapService } from 'libs/angular/draw-map/src/lib/services/draw-map.service';
import { isEqual } from 'lodash';
import { LngLatBoundsLike, Map } from 'maplibre-gl';
import {
	BehaviorSubject,
	combineLatest,
	distinctUntilChanged,
	map,
	startWith,
	tap,
	withLatestFrom
} from 'rxjs';

import { MapFacade } from '@yuno/admin/features/map';

import { FencesFacade } from '../../../data-access';
import { FencesEditorService } from '../fences-editor.service';

const DefaultMapStyle = '5e42a2df5e53be02b5f37b00';
const DefaultMapBounds: Position[] = [
	[7.22, 53.7],
	[3.2, 53.7],
	[3.2, 50.75],
	[3.2, 53.7],
	[7.22, 53.7]
];
const DefaultMapBoundsLngLat: LngLatBoundsLike = [
	[7.22, 53.7],
	[3.2, 50.75]
];

@Injectable()
export class FencesMapService {
	private fenceFacade = inject(FencesFacade);
	private mapFacade = inject(MapFacade);
	private fenceEditorService = inject(FencesEditorService);
	private drawService = inject(DrawMapService);

	private _map = new BehaviorSubject<Map | null>(null);
	map$ = this._map.asObservable();

	zoom: number;
	center: [number, number];
	pitch: number;
	bearing: number;

	boundPadding = 30;

	data$ = combineLatest({
		map: this.map$.pipe(
			startWith(null, null),
			withLatestFrom(this.fenceFacade.selectedFences$),
			tap(([map, fence]) => {
				if (map) {
					this.drawService.addDrawFeatures(map);
					if (fence && fence.id && fence.polygon) {
						this.fitBounds(map, fence.polygon);
						this.drawService.addPolygon(fence.id, fence.polygon);
						if (fence.style?.color) {
							this.drawService.updateDrawColor(fence.style.color);
						}
						if (fence.style?.opacity) {
							this.drawService.updateDrawOpacity(fence.style.opacity);
						}
					}
				}
			})
		),
		drawCreate: this.drawService.drawCreate$.pipe(
			startWith(null),
			tap(data => {
				if (data?.features && data.features.length === 1) {
					const polygon = data.features[0].geometry as Polygon;
					this.fenceEditorService.updatePolygon(polygon.coordinates[0]);
				}
			})
		),
		drawUpdate: this.drawService.drawUpdate$.pipe(
			startWith(null),
			tap(data => {
				if (data?.features && data.features.length === 1) {
					const polygon = data.features[0].geometry as Polygon;
					this.fenceEditorService.updatePolygon(polygon.coordinates[0]);
				}
			})
		),
		drawDelete: this.drawService.drawDelete$.pipe(
			startWith(null),
			tap(data => {
				if (data?.features && data.features.length === 1) {
					this.fenceEditorService.updatePolygon([]);
				}
			})
		),
		bounds: this.fenceFacade.selectedFences$.pipe(
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					return false;
				}

				return isEqual(prev?.polygon, curr?.polygon);
			}),
			map(data => {
				if (data?.polygon && data.polygon.length >= 2) {
					const line = lineString(data.polygon);
					const bb = bbox(line);
					const bounds: LngLatBoundsLike = [
						[bb[0], bb[1]],
						[bb[2], bb[3]]
					];

					return {
						polygon: this.createPolygonFeature([data.polygon]),
						fitBounds: bounds
					};
				}

				return {
					polygon: this.createPolygonFeature([DefaultMapBounds]),
					fitBounds: undefined
				};
			})
		),
		mapStyle: this.mapFacade.style$.pipe(
			startWith(undefined),
			distinctUntilChanged((prev, curr) => {
				if (!prev && !curr) {
					// when no style is selected, load the default mapstyle
					this.mapFacade.getStyle(DefaultMapStyle);
					return false;
				}

				const prevMetadata: { [key: string]: unknown } = prev?.metadata as {
					[key: string]: unknown;
				};
				const currMetadata: { [key: string]: unknown } = curr?.metadata as {
					[key: string]: unknown;
				};

				if (!prevMetadata || !currMetadata) {
					return false;
				}

				return isEqual(prev?.metadata, curr?.metadata);
			})
		)
	});

	async mapLoad(map: Map): Promise<void> {
		this._map.next(map);
	}

	fitBounds(map: Map, bounds: Position[]): void {
		const line = lineString(bounds);
		const bb = bbox(line);
		const fitBounds: LngLatBoundsLike = [
			[bb[0], bb[1]],
			[bb[2], bb[3]]
		];

		map.fitBounds(fitBounds, { animate: false, padding: this.boundPadding });
	}

	getMapBounds(): LngLatBoundsLike {
		return DefaultMapBoundsLngLat;
	}

	createPolygonFeature(polygon: Position[][]): FeatureCollection {
		return {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					properties: {},
					geometry: {
						type: 'Polygon',
						coordinates: polygon
					}
				}
			]
		};
	}

	resetMap(): void {
		this.drawService.deleteDrawFunctions();
	}
}
