import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, take, tap } from 'rxjs';

import { EventsModule } from '@yuno/admin/features/events';
import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoAdminCardDraggableComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';
import { LanguagePipe } from '@yuno/angular/pipes';
import { TextfieldInjectorComponent } from '@yuno/angular/textfield';
import { YunoUserRolesEnum } from '@yuno/api/interface';

import { AddItemsComponent, BrowseItemsComponent } from '../../../content';
import { EditorDynamicComponent } from '../pages-content-editor-dynamic/editor-dynamic.component';
import { PagesDynamicComponent } from '../pages-dynamic/pages-dynamic.component';
import { PagesEditorComponentService } from '../pages-editor-component.service';

@Component({
	selector: 'yuno-admin-pages-editor',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		DragDropModule,
		EventsModule,
		YunoFormsModule,
		YunoEditContainerModule,
		YunoAdminButtonsModule,
		YunoAdminCardDraggableComponent,
		UserRightsMessageComponent,
		EditContainerContentDirective,
		LanguagePipe,
		TextfieldInjectorComponent,
		PagesDynamicComponent,
		AddItemsComponent,
		BrowseItemsComponent,
		EditorDynamicComponent
	],
	providers: [PagesEditorComponentService],
	templateUrl: './pages-editor.component.html',
	styleUrls: ['./pages-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagesEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly cdr = inject(ChangeDetectorRef);
	protected readonly redirectTo = redirectTo;
	readonly service = inject(PagesEditorComponentService);

	data$ = combineLatest({
		page: this.service.page$.pipe(
			tap(data => {
				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
				}
			})
		),
		toggle: this.service.toggle$,
		language: this.service.language$
	});

	async ngOnInit(): Promise<void> {
		this.service.pagesEditorService.createFormGroup();
		this.service.onChanges();

		if (!this.minimalAppRole(YunoUserRolesEnum.EDITOR)) {
			this.service.form.disable();
		}

		this.route.paramMap?.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.service.onSelect(id);
		});
	}

	detectChanges(ms: number) {
		setTimeout(() => {
			this.cdr.detectChanges();
		}, ms);
	}

	ngOnDestroy() {
		this.service.onDestroy();
	}
}
