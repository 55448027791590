import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { GeoPhotosCategory } from '@yuno/api/interface';

import { categoriesActions } from './categories.actions';
import { categoriesFeature } from './categories.state';

@Injectable()
export class GeoPhotoCategoryFacade {
	private readonly store = inject(Store);

	data$ = this.store.pipe(select(categoriesFeature.selectData));
	selected$ = this.store.pipe(select(categoriesFeature.selectSelected));
	mapStyle$ = this.store.pipe(select(categoriesFeature.selectMapStyle));
	mapStyleBounds$ = this.store.pipe(select(categoriesFeature.selectMapStyleBounds));

	get(): void {
		this.store.dispatch(categoriesActions.load());
	}

	createNew(): void {
		this.store.dispatch(categoriesActions.create());
	}

	select(id: string): void {
		if (!id || id === 'create') {
			return;
		}
		this.store.dispatch(categoriesActions.select({ _id: id }));
	}

	updateSelected(data: GeoPhotosCategory): void {
		this.store.dispatch(categoriesActions.updateSelect({ data }));
	}

	duplicate(id: string): void {
		this.store.dispatch(categoriesActions.duplicate({ _id: id }));
	}

	delete(id: string): void {
		this.store.dispatch(categoriesActions.delete({ _id: id }));
	}

	save(): void {
		this.store.dispatch(categoriesActions.save());
	}

	clearSelected(): void {
		this.store.dispatch(categoriesActions.clearSelect());
	}

	reset(): void {
		this.store.dispatch(categoriesActions.reset());
	}

	loadMapStyle(style: string): void {
		this.store.dispatch(categoriesActions.loadMapStyle({ style }));
	}
}
