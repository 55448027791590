import { gql } from 'apollo-angular';
import { StyleSpecification } from 'maplibre-gl';

import { MapStyle } from '@yuno/api/interface';

export const GET_MAPSTYLES = gql`
	query getMapStyles {
		mapStyles {
			_id
			id
		}
	}
`;

export const GET_STYLE_BY_ID = gql`
	query getStyleById($_id: String!) {
		style(_id: $_id) {
			version
			name
			metadata
			center
			zoom
			bearing
			pitch
			sources
			sprite
			glyphs
			layers
		}
	}
`;

export const GetDefaultStyleQuery = gql`
	query getDefaultStyle($appId: String!, $style: String) {
		getThemeDefaultStyle(appId: $appId, style: $style) {
			view
			style
		}
	}
`;

export type StyleQuery = {
	style: StyleSpecification;
};

export type MapStylesQuery = {
	mapStyles: MapStyle[];
};
