<ng-container [formGroup]="form">
	<label class="yuno-form mt-4">
		<input type="checkbox" [name]="item.key" [formControl]="control" />
		@if (item.label) {
			<span
				class="select-none"
				[innerHtml]="
					item.label
						| languageSelect: language
						| safeHtml: item.validators?.required || item.required
				">
			</span>
		}
	</label>
</ng-container>
