import { gql } from 'apollo-angular';

export const DELETE_STATE = gql`
	mutation deleteStateById($_id: String!) {
		deleteState(_id: $_id)
	}
`;

export type DeleteStateMutation = {
	deleteState: string;
};
