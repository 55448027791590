import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';

import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoAdminTableComponent,
	YunoCardModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import { YunoFormsModule } from '@yuno/angular/forms';
import { PanoramaComponent } from '@yuno/angular/krpano';
import { AngularPipesModule } from '@yuno/angular/pipes';

import { PanoMapComponent } from './pano-map/pano-map.component';
import { PanoViewerComponent } from './pano-viewer/pano-viewer.component';
import { PanoramaEditorComponent } from './panorama-editor.component';

@NgModule({
	declarations: [PanoramaEditorComponent, PanoViewerComponent, PanoMapComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		JsonFormsModule,
		YunoFormsModule,
		YunoEditContainerModule,
		YunoCardModule,
		YunoAdminButtonsModule,
		UserRightsMessageComponent,
		YunoAdminTableComponent,
		PanoramaComponent,
		NgxMapLibreGLModule,
		AngularPipesModule,
		EditContainerContentDirective
	]
})
export class PanoramaEditorModule {}
