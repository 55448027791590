import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { themesFeature } from '@yuno/admin/features/themes/data-access/+state/theme.state';
import { Theme } from '@yuno/api/interface';

import { themesActions } from './theme.actions';

@Injectable()
export class ThemeFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(themesFeature.selectLoaded));
	themes$ = this.store.pipe(select(themesFeature.selectThemes));
	selectedTheme$ = this.store.pipe(select(themesFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(themesFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(themesFeature.selectStatus));
	bounds$ = this.store.pipe(select(themesFeature.selectBounds));
	maxBounds$ = this.store.pipe(select(themesFeature.selectMaxBounds));

	get() {
		this.store.dispatch(themesActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(themesActions.select({ _id }));
	}

	updateSelect(data: Partial<Theme>) {
		this.store.dispatch(themesActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(themesActions.clearSelect());
	}

	updateBoundsByFence(_id: string) {
		this.store.dispatch(themesActions.updateBoundsByFence({ _id }));
	}
	updateMaxBoundsByFence(_id: string) {
		this.store.dispatch(themesActions.updateMaxBoundsByFence({ _id }));
	}

	save() {
		this.store.dispatch(themesActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(themesActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(themesActions.delete({ _id }));
	}
}
