import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { GeoPhotoViewerUiSelectorsComponent } from '../ui-selectors/ui-selectors.component';

@Component({
	selector: 'yuno-admin-geophotos-routing',
	standalone: true,
	imports: [RouterModule, GeoPhotoViewerUiSelectorsComponent],
	template: `
		<yuno-admin-geophoto-viewer-ui-selectors />
		<router-outlet></router-outlet>
	`,
	styleUrls: ['./routing.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoPhotoViewerRoutingComponent {}
