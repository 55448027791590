import { gql } from 'apollo-angular';

import { MarkerCategory } from '@yuno/api/interface';

export const GET_MARKERCATEGORIES_BY_APPID = gql`
	query getMarkerCategoriesByAppId($appId: String!) {
		markerCategories(appId: $appId) {
			_id
			id
			order
			public
			intern
			type
			inputs {
				custom {
					type
				}
				preset {
					type
				}
			}
			cluster {
				active
			}
			events {
				onClick {
					type
					options
				}
				onMouseMove {
					type
					options
				}
			}
		}
	}
`;

export const GET_MARKERCATEGORY_BY_ID = gql`
	query getMarkerCategoryById($_id: String!) {
		selectedMarkerCategory(_id: $_id) {
			_id
			appId
			id
			order
			public
			intern
			zIndex
			fence
			fenceData {
				polygon
			}
			type
			layers {
				_id
				id
			}
			theme
			mapStyle
			styles {
				id
				overwriteZoom
				style
			}
			inputs {
				custom {
					key
					label
					listView
					options
					placeholder
					required
					type
				}
				preset {
					label
					listView
					options
					placeholder
					required
					type
				}
			}
			layout {
				fallback
				filter
				options
			}
			cluster {
				active
				countStyle {
					alignment
					backgroundColor
					color
				}
				options {
					minPoints
					minZoom
					maxZoom
					radius
				}
				style {
					alignment
					icon
					scale
				}
			}
			customEvents
			events {
				onClick {
					type
					options
				}
				onMouseMove {
					type
					options
				}
				onMouseLeave {
					type
					options
				}
			}
		}
	}
`;

export type MarkerCategoriesQuery = {
	markerCategories: Partial<MarkerCategory>[];
};

export type SelectedCategoryQuery = {
	selectedMarkerCategory: Partial<MarkerCategory>;
};

/* style {
	icon
	scale
	class
	color
	rotation
	alignment
	backgroundColor
} */
