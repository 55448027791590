import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
	selector: 'yuno-json-forms-types-divider',
	template: `<div class="col-span-full my-2 border-b-2 border-gray-200"></div>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class DividerComponent {
	@HostBinding('className') className = 'col-span-full';
}
