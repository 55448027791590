import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	DELETE_IMAGE_BUTTON,
	DeleteImageButtonMutation,
	GET_IMAGE_BUTTONS,
	GET_IMAGE_BUTTON_BY_ID,
	ImageButtonQuery,
	ImageButtonsQuery,
	SAVE_IMAGE_BUTTON,
	SaveImageButtonMutation
} from '@yuno/admin/features/media/utils';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { imageButtonsActions } from './image-button.actions';
import { imageButtonsFeature } from './image-button.state';

export const loadImageButtons = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(imageButtonsActions.load, imageButtonsActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<ImageButtonsQuery>({
						query: GET_IMAGE_BUTTONS,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.mediaImageButton) {
								throw new Error('no Image-Buttons found');
							}
							return imageButtonsActions.loadSuccess({
								data: data.data.mediaImageButton
							});
						}),
						take(1),
						catchError(error => of(imageButtonsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectImageButtons = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(imageButtonsActions.select),
			exhaustMap(button =>
				graphql
					.query<ImageButtonQuery>({
						query: GET_IMAGE_BUTTON_BY_ID,
						variables: {
							_id: button._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.mediaImageButtonId) {
								message.sendToast(`Error selecting Image-Button!`, 'error');
								throw new Error('no Image-Button with that id found');
							}
							return imageButtonsActions.selectSuccess({
								data: data.data.mediaImageButtonId
							});
						}),
						take(1),
						catchError(error => of(imageButtonsActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedImageButton = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(imageButtonsActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Image-Button!`, 'error');
					throw new Error('no Image-Button found');
				}
				return imageButtonsActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(imageButtonsActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveImageButton = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(imageButtonsActions.save),
			withLatestFrom(
				store.pipe(select(imageButtonsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveImageButtonMutation>({
						mutation: SAVE_IMAGE_BUTTON,
						variables: {
							imageButton: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.mediaImageButtonSave) {
								throw new Error('error saving Image-Button to the database');
							}
							store.dispatch(
								imageButtonsActions.updateSelect({
									data: data.data.mediaImageButtonSave
								})
							);
							return imageButtonsActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(imageButtonsActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteImageButton = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(imageButtonsActions.delete),
			switchMap(button =>
				graphql
					.mutate<DeleteImageButtonMutation>({
						mutation: DELETE_IMAGE_BUTTON,
						variables: {
							_id: button._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.mediaImageButtonDelete) {
								throw new Error('error deleting Image-Button from the database');
							}
							store.dispatch(imageButtonsActions.reload());
							return imageButtonsActions.deleteSuccess({
								_id: data.data.mediaImageButtonDelete
							});
						}),
						take(1),
						catchError(error => of(imageButtonsActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveImageButtonSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(imageButtonsActions.saveSuccess),
			tap(() => {
				message.sendToast(`Image-Button successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveImageButtonFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(imageButtonsActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Image-Button!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteImageButtonSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(imageButtonsActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Image-Button successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteImageButtonFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(imageButtonsActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Image-Button!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
