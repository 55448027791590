import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
	selector: 'yuno-forms-telephone',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<input
				class="truncate"
				type="tel"
				[id]="ngControl.name"
				[name]="ngControl.name"
				[placeholder]="placeholder"
				[formControl]="getFormControl()" />

			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		YunoFormsValidatorMessagesComponent,
		YunoFormsLabelRequired
	]
})
export class YunoFormsTelephoneComponent extends YunoFormsDefaultComponent {}

@Component({
	selector: 'yuno-forms-template-telephone',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<input
				class="truncate"
				type="tel"
				[ngModel]="value"
				(ngModelChange)="valueChange($event)"
				[placeholder]="placeholder"
				[required]="required" />
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, YunoFormsValidatorMessagesComponent, YunoFormsLabelRequired],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsTelephoneTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsTelephoneTemplateComponent extends YunoFormsDefaultTemplateComponent {}
