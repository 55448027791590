import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PagesEditorComponent } from '../editor/pages-editor/pages-editor.component';

const routes: Routes = [
	{
		path: '',
		loadComponent: () => import('./pages.component').then(m => m.PagesComponent),
		children: [
			{ path: 'create', component: PagesEditorComponent },
			{ path: 'edit/:id', component: PagesEditorComponent }
		]
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PagesRoutingModule {}
