@if (form) {
	<form [formGroup]="form">
		<yuno-forms-title>Gradient</yuno-forms-title>
		<div formArrayName="gradiant" class="my-2 grid grid-cols-1 gap-1">
			@for (alias of service.gradiants.controls; track alias; let i = $index) {
				<div
					[formGroupName]="i"
					class="flex w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-400 bg-gray-200 p-2">
					<div class="flex flex-1 gap-2">
						<yuno-forms-color
							class="w-30"
							formControlName="color"
							label="color"></yuno-forms-color>
						<yuno-forms-number
							style="width: 130px"
							formControlName="offset"
							label="offset (percentage)"
							placeholder="offset"></yuno-forms-number>
						<yuno-forms-text
							class="flex-1"
							formControlName="title"
							label="title"
							placeholder="title"></yuno-forms-text>
					</div>
					<div class="flex justify-center">
						<button yuno-admin-button color="danger" (click)="removeGradiantAt(i)">
							remove
						</button>
					</div>
				</div>
			}
		</div>
		<button yuno-admin-button color="primary" (click)="addGradiant()" [add]="true">
			Add Gradient
		</button>
	</form>
}
