import { gql } from 'apollo-angular';

import { DataObjects } from '@yuno/api/interface';

export const SAVE_OBJECT = gql`
	mutation saveObject($object: ObjectInput!, $appId: String!) {
		saveObject(object: $object, appId: $appId) {
			type
			data
		}
	}
`;

export const DUPLICATE_OBJECT = gql`
	mutation duplicateObjectById($object: ObjectInput!, $appId: String!) {
		duplicateObject(object: $object, appId: $appId) {
			type
			data
		}
	}
`;

export type SaveObjectMutation = {
	saveObject: Partial<DataObjects>;
};

export type DuplicateObjectMutation = {
	duplicateObject: Partial<DataObjects>;
};
