@if (data$ | async; as data) {
	<yuno-edit-container [col]="4" [pos]="3" [span]="2">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()">Save</button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>
		<div edit-container-content>
			@if (service.dashboardForm) {
				<form [formGroup]="service.dashboardForm">
					<div class="mb-4 grid grid-cols-4 gap-4">
						<section class="col-span-3" formGroupName="title">
							@for (lang of languages; track lang) {
								@if (lang === data.language) {
									<yuno-forms-text
										[language]="lang"
										[formControlName]="lang"
										label="Title"></yuno-forms-text>
								}
							}
						</section>
						<yuno-forms-toggle
							class="col-span-1"
							formControlName="public"
							label="Public"></yuno-forms-toggle>
					</div>
					<section formGroupName="description">
						@for (lang of languages; track lang) {
							@if (lang === data.language) {
								<yuno-forms-html-image-library
									[language]="lang"
									[formControlName]="lang"
									label="Description"></yuno-forms-html-image-library>
							}
						}
					</section>
					<yuno-forms-divider></yuno-forms-divider>
					<yuno-forms-title>Image</yuno-forms-title>
					<div class="grid grid-cols-4 gap-4" formGroupName="image">
						<yuno-forms-select
							class="col-span-1"
							formControlName="typeOf"
							placeholder="Choose Type..."
							[selectValues]="['image', 'gif', 'video']"
							[display]="['Image', 'Gif', 'Video']"
							label="Type"></yuno-forms-select>
						<yuno-forms-text
							class="col-span-3"
							formControlName="source"
							label="Source"></yuno-forms-text>
						@if (service.dashboardForm.value.image?.typeOf === 'gif') {
							<yuno-forms-text
								class="col-span-3 col-start-2"
								formControlName="preview"
								label="Preview image"></yuno-forms-text>
						}
					</div>
					<yuno-forms-divider></yuno-forms-divider>
					<ng-container formArrayName="links">
						<yuno-forms-title>Links</yuno-forms-title>
						@for (alias of service.links.controls; track alias; let i = $index) {
							<div
								[formGroupName]="i"
								class="mb-2 flex w-full flex-row items-center justify-center gap-2 rounded-md border border-gray-400 bg-gray-300 p-2">
								<yuno-forms-text
									class="col-span-3 col-start-2"
									formControlName="title"
									placeholder="link title..."></yuno-forms-text>
								<yuno-forms-select
									formControlName="destination"
									placeholder="Choose Destination..."
									[selectValues]="service.linkValues"
									[display]="service.linkDisplay"></yuno-forms-select>
								<div class="flex justify-end gap-2">
									<button
										yuno-admin-button
										color="danger"
										(click)="service.removeLink(i)">
										Remove
									</button>
								</div>
							</div>
						}
						<button
							yuno-admin-button
							color="primary"
							(click)="service.addLink()"
							[add]="true">
							Add Link
						</button>
					</ng-container>
				</form>
			}
		</div>
	</yuno-edit-container>
}
