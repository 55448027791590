import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadComponent: () =>
			import('../internal-view/tilesets.component').then(c => c.TilesetsComponent),
		children: [
			{
				path: 'custom/create',
				loadComponent: () =>
					import('../tileset-custom/tileset-custom-editor.component').then(
						c => c.TilesetCustomEditorComponent
					)
			},
			{
				path: 'custom/edit/:id',
				loadComponent: () =>
					import('../tileset-custom/tileset-custom-editor.component').then(
						c => c.TilesetCustomEditorComponent
					)
			}
		]
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TilesetsRoutingModule {}
