<div class="flex flex-col gap-2 sm:flex-row">
	<yuno-admin-ui-selector>
		<yuno-admin-ui-selector-item [link]="['../map']"> Map</yuno-admin-ui-selector-item>
		<yuno-admin-ui-selector-item [link]="['../list']"> List</yuno-admin-ui-selector-item>
	</yuno-admin-ui-selector>
	@if (!router.url.includes('list')) {
		<yuno-admin-ui-selector-button (click)="backToBounds()">
			Back to overview
		</yuno-admin-ui-selector-button>

		@if (showAddMarker && minimalAppRole(userRoles.EDITOR)) {
			<yuno-admin-ui-selector-button
				color="primary"
				[add]="true"
				(click)="openEditor(['marker', 'create'])">
				Add Marker
			</yuno-admin-ui-selector-button>
		}
	}
</div>

<yuno-admin-ui-selector-dropdown>
	<span description>Filter:</span>

	@if (activeDisplay($filter() || 'all'); as activeFiltered) {
		<yuno-admin-ui-selector-dropdown-item active class="active">
			{{ activeFiltered }}
		</yuno-admin-ui-selector-dropdown-item>
	}
	<yuno-admin-ui-selector-dropdown-item
		(click)="filterMarkers('all')"
		[ngClass]="{
			'active-color': $filter() === 'all'
		}">
		All
	</yuno-admin-ui-selector-dropdown-item>
	<yuno-admin-ui-selector-dropdown-item
		(click)="filterMarkers('public')"
		[ngClass]="{
			'active-color': $filter() === 'public'
		}">
		Public
	</yuno-admin-ui-selector-dropdown-item>
	<yuno-admin-ui-selector-dropdown-item
		(click)="filterMarkers('non-public')"
		[ngClass]="{
			'active-color': $filter() === 'non-public'
		}">
		Non-public
	</yuno-admin-ui-selector-dropdown-item>
</yuno-admin-ui-selector-dropdown>
