import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';

import { FilesFacade } from '@yuno/admin/features/file-library';
import { newLanguageFormGroup } from '@yuno/angular/forms';
import { CdnFile } from '@yuno/api/interface';

import { PageEditorService, TextfieldEditorService } from '../../../data-access';
import { PredefinedListForm, PredefinedSummaryForm } from './predefined.interface';

@Injectable({
	providedIn: 'root'
})
export class PredefinedEditorService {
	private readonly fileFacade = inject(FilesFacade);
	private readonly pageService = inject(PageEditorService);
	private readonly textService = inject(TextfieldEditorService);

	_selector = new BehaviorSubject<string>('');
	_listItem = new BehaviorSubject<FormGroup<PredefinedListForm> | null>(null);
	_closeOverlay = new BehaviorSubject<boolean>(false);

	files$ = this.fileFacade.files$.pipe(
		tap(data => {
			if (data && data.length >= 1) {
				this.pageService.filesDisplay = data.map(file => file?.data?.fileName) as string[];
				this.pageService.filesValues = data.map(file => file) as CdnFile[];
				this.textService.filesDisplay = data.map(file => file?.data?.fileName) as string[];
				this.textService.filesValues = data.map(file => file) as CdnFile[];
			}
		})
	);

	getSelector(form: FormGroup): Observable<string> {
		return of(form.value.selector);
	}

	getTitle(selector: string) {
		let value;
		switch (selector) {
			case 'h1':
				value = 'Header';
				break;
			case 'h2':
				value = 'Title';
				break;
			case 'h3':
				value = 'Sub Title';
				break;
			case 'paragraph':
				value = 'Paragraph';
				break;
			case 'dateSummary':
				value = 'Date Summary';
				break;
			case 'summary':
				value = 'Summary';
				break;
			case 'blockquote':
				value = 'Blockquote';
				break;
			case 'divider':
				value = 'Divider';
				break;
			case 'file':
				value = 'File';
				break;
			default:
				value = 'Predefined';
				break;
		}
		return value;
	}

	getFiles(): void {
		this.fileFacade.get();
	}

	getList(form: FormGroup) {
		const options = form.controls['options'] as FormGroup<PredefinedSummaryForm>;
		return options.controls.list;
	}

	removeListAt(i: number, list: FormArray) {
		list.removeAt(i);
	}

	addListItem(list: FormArray) {
		const newItem = new FormGroup<PredefinedListForm>({
			content: newLanguageFormGroup(),
			date: new FormControl(null, { nonNullable: true }),
			url: new FormControl(null, { nonNullable: true }),
			page: new FormControl(null, { nonNullable: true })
		});
		list.push(newItem);
	}
}
