import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CmsConfig } from '@yuno/api/interface';

export const appDataActions = createActionGroup({
	source: 'AppData',
	events: {
		checkProduction: emptyProps(),
		productionSuccess: props<{ data: Partial<CmsConfig> }>(),
		productionFailure: props<{ error: Error }>()
	}
});
