import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { privacyFeature } from '@yuno/admin/features/privacy-and-cookies-editor/data-access/+state/privacy-statement-xkp/privacy-statement-xkp.state';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';
import { PrivacyStatement } from '@yuno/api/interface';

import {
	GET_PRIVACY_STATEMENT,
	GET_PRIVACY_STATEMENTS,
	SAVE_PRIVACY_STATEMENT
} from '../../../utils';
import { privacyStatementActions } from './privacy-statement-xkp.actions';

export type PrivacyValues = {
	getPrivacyStatements: PrivacyStatement[];
};

export type PrivacyValue = {
	getPrivacyStatementById: PrivacyStatement;
};

export type SavePrivacy = {
	savePrivacyStatementById: PrivacyStatement;
};

export const values = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) => {
		return actions$.pipe(
			ofType(privacyStatementActions.values),
			switchMap(() =>
				graphql
					.query<PrivacyValues>({
						query: GET_PRIVACY_STATEMENTS
					})
					.pipe(
						map(data => {
							if (!data.data.getPrivacyStatements) {
								throw new Error('no Privacy Statements data');
							}

							return privacyStatementActions.valuesSuccess({
								data: data.data.getPrivacyStatements
							});
						}),
						take(1),
						catchError(error => of(privacyStatementActions.valuesFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);

export const valuesFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementActions.valuesFailure),
			tap(() => message.sendToast(`Load Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);

export const value = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) => {
		return actions$.pipe(
			ofType(privacyStatementActions.value),
			switchMap(action =>
				graphql
					.query<PrivacyValue>({
						query: GET_PRIVACY_STATEMENT,
						variables: {
							id: action.id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.getPrivacyStatementById) {
								throw new Error('No Privacy Statement');
							}

							return privacyStatementActions.valueSuccess({
								data: data.data.getPrivacyStatementById
							});
						}),
						take(1),
						catchError(error => of(privacyStatementActions.valueFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);

export const valueSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementActions.valueSuccess),
			tap(() => message.sendToast(`Load successfully!`, 'success'))
		);
	},
	{ dispatch: false, functional: true }
);

export const valueFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementActions.valueFailure),
			tap(() => message.sendToast(`Load Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);

export const saveValue = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService), store = inject(Store)) => {
		return actions$.pipe(
			ofType(privacyStatementActions.saveValue),
			withLatestFrom(store.pipe(select(privacyFeature.selectSelected))),
			switchMap(([, data]) =>
				graphql
					.mutate<SavePrivacy>({
						mutation: SAVE_PRIVACY_STATEMENT,
						variables: {
							data
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.savePrivacyStatementById) {
								throw new Error('No Privacy Statement');
							}

							return privacyStatementActions.saveValueSuccess();
						}),
						take(1),
						catchError(error => of(privacyStatementActions.saveValueFailure({ error })))
					)
			)
		);
	},
	{ functional: true }
);

export const saveValueSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementActions.saveValueSuccess),
			tap(() => message.sendToast(`Save successfully!`, 'success'))
		);
	},
	{ dispatch: false, functional: true }
);

export const saveValueFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) => {
		return actions$.pipe(
			ofType(privacyStatementActions.saveValueFailure),
			tap(() => message.sendToast(`Save Failure!`, 'error'))
		);
	},
	{ dispatch: false, functional: true }
);
