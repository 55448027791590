import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { tap } from 'rxjs';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { Marker } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-zoom-to-marker',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, YunoFormsModule, AnnotationComponent, AsyncPipe],
	templateUrl: './zoom-to-marker.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomToMarkerComponent extends EventsDefaultFormItemComponent implements OnInit {
	markerValue: string | null;
	markers: Partial<Marker>[];
	formOptions = {
		id: undefined,
		bearing: 0,
		pitch: 0,
		animate: true,
		coordinates: [0, 0],
		minZoom: 1,
		zoom: 1
	};

	data$ = this.service.markers$.pipe(
		tap(data => {
			if (data) {
				this.service.markerDisplay = [
					...data.map(object => object.properties?.id)
				] as string[];
				this.service.markerValues = [...data.map(object => object._id)] as string[];
				this.markers = data;
			}
		})
	);

	ngOnInit(): void {
		this.service.getMarkers();
		const options = this.form.get('options') as FormGroup;
		if (options.value.id) {
			this.markerValue = options.value.id;
		}
		if (!options.value.coordinates) {
			this.markerValue = null;
		}
	}

	onSelectMarker(e: string): void {
		const options = this.form.get('options') as FormGroup;
		const optionsControls = Object.keys(options.controls);
		const formControls = optionsControls.filter(control => control !== 'zoom');

		if (e !== 'null') {
			if (formControls.length < 1) {
				this.service.generateControls(options, this.formOptions, [
					{ name: 'coordinates', length: 2 }
				]);
			}
			const index = this.markers.findIndex(x => x['_id'] === e);
			const coords = this.markers[index].geometry?.coordinates;
			const coordForm = this.form.get('options')?.get('coordinates');
			coordForm?.patchValue(coords);
			const idForm = this.form.get('options')?.get('id');
			idForm?.patchValue(e);
		} else {
			this.service.removeControls(options, formControls);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	returnControls(options: any) {
		const formControls = Object.keys(options.controls);
		return formControls.length >= 1;
	}

	returnDisplay(index: number, select: string | undefined) {
		if (this.service.markerDisplay && this.service.markerDisplay[index]) {
			return this.service.markerDisplay[index];
		} else {
			return select;
		}
	}
}
