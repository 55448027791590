import { gql } from 'apollo-angular';

import { Textfield } from '@yuno/api/interface';

export const GET_TEXTFIELDS = gql`
	query getTextfieldsByAppId($appId: String!, $filter: String) {
		textfields(appId: $appId, filter: $filter) {
			_id
			id
			public

			createdAt
			updatedAt
			updatedBy {
				displayName
			}
		}
	}
`;

export const GET_TEXTFIELD_BY_ID = gql`
	query getTextfieldById($_id: String!) {
		selectedTextfield(_id: $_id) {
			_id
			id
			public
			components {
				textblock {
					_id
					id
					content
				}
				button {
					_id
					id
					content
					activeContent
					activeToggle
					alignBottom
					align
					events {
						type
						options
					}
					activeEvents {
						type
						options
					}
				}
				container
				custom {
					_id
					name
					content
					customData
				}
				image {
					_id
					file {
						_id
						data {
							url
							path
							filename
							fileName
						}
					}
					alt
					description
					url
					zoomable
				}
				link {
					_id
					id
					display
					url
					page
					type
					link
					color
				}
				list {
					_id
					id
					title
					active {
						color
						backgroundColor
					}
					items {
						id
						title
						active
						events {
							type
							options
						}
					}
					keepActiveDataset
					disableActiveState
					multiselect
					select
					defaultValue
				}
				toggles {
					_id
					id
					listLike
					addColor
					title
					items {
						active
						group
						title
						items {
							public
							title
							type
							dataset
							layer
							color
							layers {
								_id
							}
						}
						type
						public
						layer
						dataset
						color
					}
				}
				video {
					_id
					id
					type
					videoId
					description
				}
				news {
					_id
					id
					bgColor
					textColor
					textHeadingColor
					textLinkColor
					align
					size
					title
					subTitle
					content
					imageSrc
					imageFile {
						_id
						data {
							fileName
							url
						}
					}
					imageLink
					imageZoom
					secondaryImageSrc
					secondaryImageFile {
						_id
						data {
							fileName
							url
						}
					}
					secondaryImageLink
					secondaryImageZoom
					imageOrder
					videoSrc
					videoType
					description
					links {
						color
						page
						title
						type
						text
						link
						url
					}
					updatedAt
				}
				legend {
					_id
					id
					title
					content {
						public
						title
						bold
						class
						color
						border
						borderColor
						svg
						image
						gradiant {
							color
							offset
							title
						}
						gradiantHeight
						layer {
							_id
							id
						}
						activeLayers {
							_id
							id
						}
						list {
							public
							title
							bold
							class
							color
							border
							borderColor
							svg
							image
							gradiant {
								color
								offset
								title
							}
							gradiantHeight
							layer {
								_id
								id
							}
							activeLayers {
								_id
								id
							}
						}
					}
					updatedAt
				}
				category {
					title
					category {
						_id
						id
						public
						intern
						type
						styles {
							id
							overwriteZoom
							style
						}
						layout {
							fallback
							filter
							options
						}
						updatedAt
					}
					dropdown
					hide
				}
				notification {
					_id
					id
					type
					title
					content
				}
				imageButton {
					item {
						_id
						id
						size
						wide
						title
						file {
							_id
							data {
								url
							}
						}
						updatedAt
					}
					link
					type
					url
				}
				predefined {
					selector
					options
				}
			}
		}
	}
`;

export type TextfieldsQuery = {
	textfields: Partial<Textfield>[];
	selectedTextfield: Partial<Textfield>;
};

export type TextfieldQuery = {
	_id: string;
	textfield: Partial<Textfield>;
};
