import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DoCheck,
	Input,
	OnInit,
	Optional,
	Self,
	inject
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	NgControl,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NOOP_VALUE_ACCESSOR, labelRequiredStandalone } from '@yuno/angular/forms';

import { YunoLanguageIconsComponent } from '../../../../../angular/forms/src/lib/components/_language-icons/language-icons.component';
import { YunoTinymceHtmlEditorModule } from './tinymce/tinymce-editor.module';

@Component({
	standalone: true,
	imports: [
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		labelRequiredStandalone,
		YunoTinymceHtmlEditorModule,
		YunoLanguageIconsComponent
	],
	selector: 'yuno-forms-html-image-library',
	templateUrl: './html.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlEditorImageLibraryComponent implements DoCheck, OnInit {
	readonly cdr = inject(ChangeDetectorRef);

	@Input() label?: string;
	@Input() placeholder? = '';
	@Input() required?: boolean;
	@Input() language?: string;

	@Self()
	@Optional()
	ngControl = inject(NgControl);

	constructor() {
		if (this.ngControl) {
			this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
		}
	}

	ngOnInit(): void {
		const required = this.ngControl.control?.hasValidator(Validators.required);
		if (required) {
			this.required = true;
		}
	}

	ngDoCheck(): void {
		this.markTouched();
	}

	markTouched(): void {
		if (!this.ngControl) {
			return;
		}

		if (this.ngControl.touched || this.ngControl.dirty) {
			this.cdr.markForCheck();
		}
	}

	hasFormControl(): boolean {
		return !!this.ngControl;
	}

	getFormControl(): FormControl {
		return this.ngControl?.control as FormControl;
	}

	getFormGroup(): FormGroup {
		return this.ngControl?.control?.parent as FormGroup;
	}
}
