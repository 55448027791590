<ng-container [formGroup]="form">
	<div class="col-span-full">
		<span class="text-sm font-semibold"> {{ item.label }} </span>

		<!-- Fixed Array -->
		@if (item.options?.fixed) {
			<fieldset class="grid items-start gap-4" [ngClass]="colspan()">
				@for (arrayItem of item.controls; track arrayItem; let i = $index) {
					<ng-container [formArrayName]="item.key">
						@switch (arrayItem.controlType) {
							@case ('button') {
								@if (asButton(arrayItem); as buttonItem) {
									<yuno-json-forms-types-button
										[disableAction]="true"
										[item]="buttonItem">
									</yuno-json-forms-types-button>
								}
							}
							@case ('label') {
								@if (asLabel(arrayItem); as labelItem) {
									<yuno-json-forms-types-label [item]="labelItem">
									</yuno-json-forms-types-label>
								}
							}
							@case ('divider') {
								<yuno-json-forms-types-divider> </yuno-json-forms-types-divider>
							}
							@case ('array') {
								@if (asArray(arrayItem); as arrayControlItem) {
									<yuno-json-forms-types-array
										[formValue]="formValue[i]"
										[form]="form"
										[item]="arrayControlItem"
										[control]="arrayControlItem.control">
									</yuno-json-forms-types-array>
								}
							}
							@case ('group') {
								@if (asGroup(arrayItem); as groupControlItem) {
									<yuno-json-forms-types-group
										[formValue]="formValue[i]"
										[form]="form"
										[item]="groupControlItem"
										[control]="groupControlItem.control">
									</yuno-json-forms-types-group>
								}
							}
							@case ('control') {
								@if (asFormControl(arrayItem); as formControlItem) {
									<yuno-json-forms-types-control
										[form]="form"
										[item]="formControlItem"
										[control]="formControlItem.control">
									</yuno-json-forms-types-control>
								}
							}
						}
					</ng-container>
				}
			</fieldset>
		}
		<!-- Dynamic Array -->
		@if (!item.options?.fixed) {
			<fieldset [formArrayName]="item.key" class="flex flex-col gap-2">
				<!-- MAIN ARRAY -->
				@for (
					arrayItemControl of control?.controls;
					track arrayItemControl;
					let i = $index
				) {
					<div class="grid gap-4 rounded-lg" [ngClass]="colspan()">
						@for (arrayItem of item.controls; track arrayItem; let arrIndex = $index) {
							@switch (arrayItem.controlType) {
								@case ('label') {
									@if (asLabel(controls[i][arrIndex]); as labelItem) {
										<yuno-json-forms-types-label [item]="labelItem">
										</yuno-json-forms-types-label>
									}
								}
								@case ('divider') {
									<yuno-json-forms-types-divider> </yuno-json-forms-types-divider>
								}
								@case ('array') {
									@if (asArray(controls[i][arrIndex]); as arrayControlItem) {
										<yuno-json-forms-types-array
											[formValue]="formValue[i][arrayControlItem.key]"
											[form]="asFormGroup(arrayItemControl)"
											[item]="arrayControlItem"
											[control]="arrayControlItem.control">
										</yuno-json-forms-types-array>
									}
								}
								@case ('group') {
									@if (asGroup(controls[i][arrIndex]); as groupControlItem) {
										<yuno-json-forms-types-group
											[formValue]="formValue[i][groupControlItem.key]"
											[form]="asFormGroup(arrayItemControl)"
											[item]="groupControlItem"
											[control]="groupControlItem.control">
										</yuno-json-forms-types-group>
									}
								}
								@case ('control') {
									@if (asFormControl(controls[i][arrIndex]); as formControlItem) {
										<yuno-json-forms-types-control
											[form]="asFormGroup(arrayItemControl)"
											[item]="formControlItem"
											[control]="formControlItem.control">
										</yuno-json-forms-types-control>
									}
								}
								@case ('array-control') {
									@if (
										asFormArrayControl(controls[i][arrIndex]);
										as formControlItem
									) {
										<yuno-json-forms-types-array-control
											[form]="asFormGroup(arrayItemControl)"
											[item]="formControlItem"
											[key]="i.toString()"
											[control]="formControlItem.control"
											(removeFromParent)="removeItemFromArray($event)">
										</yuno-json-forms-types-array-control>
									}
								}
							}
						}
					</div>
				}
			</fieldset>
			<div class="my-2 flex justify-start">
				<yuno-json-forms-types-button
					[removeMarginTop]="true"
					[disableAction]="true"
					(click)="addItem()"
					[add]="true">
					Add
				</yuno-json-forms-types-button>
			</div>
		}
	</div>
</ng-container>
