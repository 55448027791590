<ng-container *transloco="let t">
	<router-outlet></router-outlet>

	@if (data$ | async; as data) {
		<div class="sm:container sm:mx-auto">
			@if (minimalAppRole(userRoles.ADMIN)) {
				<yuno-admin-navbar-buttons-container>
					<button yuno-admin-button color="primary" (click)="addHelpItem()" [add]="true">
						Add Item
					</button>
				</yuno-admin-navbar-buttons-container>
			}
			<section class="mb-4 w-full">
				<div class="grid grid-cols-2 gap-4">
					@if (currentPage !== 'dashboard') {
						<form [formGroup]="service.dashboardForm">
							<yuno-forms-toggle
								class="col-span-1"
								formControlName="public"
								[labelPos]="'side'"
								label="Set public for clients"></yuno-forms-toggle>
						</form>
					}

					@if (currentPage === 'dashboard') {
						<yuno-card [fullHeight]="true" [blue]="true">
							<ng-container title>
								{{ t('intro.title') }}
							</ng-container>
							<ng-container content>
								<div
									[innerHTML]="
										t('intro.description', { title: config.title })
									"></div>
								<div class="flex flex-row justify-start gap-8">
									@for (link of welcomeLinks; track link) {
										<a
											[href]="link.href"
											target="_blank"
											rel="noopener"
											class="text-white hover:text-sky-200">
											<span
												class="flex flex-row items-center justify-start gap-2">
												<svg
													viewBox="0 0 16 16"
													class="h-4 w-4"
													fill="currentColor">
													<circle
														fill="currentColor"
														cx="8"
														cy="8"
														r="8" />
													<path
														d="M8 3l-.909.909 3.441 3.442H3v1.3h7.532l-3.441 3.44L8 13l5-5z"
														fill="#0EA5E9" />
												</svg>
												{{ link.key }}
											</span>
										</a>
									}
								</div>
								@if (variants.length) {
									<div class="mt-4 font-semibold">Variants:</div>
									<div class="flex flex-col justify-start">
										@for (link of variants; track link) {
											<a
												[href]="link.href"
												target="_blank"
												rel="noopener"
												class="text-white hover:text-sky-200">
												<span
													class="flex flex-row items-center justify-start gap-2">
													<svg
														viewBox="0 0 16 16"
														class="h-4 w-4"
														fill="currentColor">
														<circle
															fill="currentColor"
															cx="8"
															cy="8"
															r="8" />
														<path
															d="M8 3l-.909.909 3.441 3.442H3v1.3h7.532l-3.441 3.44L8 13l5-5z"
															fill="#0EA5E9" />
													</svg>
													{{ link.key }}
												</span>
											</a>
										}
									</div>
								}
							</ng-container>
						</yuno-card>
						<yuno-card [fullHeight]="true">
							<ng-container title>
								{{ t('matomo.title') }}
							</ng-container>
							<ng-container content>
								<div [innerHTML]="t('matomo.description')"></div>
								@if (matomoLink; as link) {
									<a [href]="link" target="_blank" rel="noopener">
										<span
											class="flex flex-row items-center justify-start gap-2">
											<svg
												viewBox="0 0 16 16"
												class="h-4 w-4"
												fill="currentColor">
												<use href="#arrow-right-circle" />
											</svg>
											{{ t('matomo.link') }}
										</span>
									</a>
								}
							</ng-container>
						</yuno-card>

						<!-- GUIDES -->
						<yuno-card class="col-span-2">
							<ng-container title>
								{{ t('guides.title') }}
							</ng-container>
							<ng-container content>
								<div [innerHTML]="t('guides.description')"></div>
								@for (link of links; track link) {
									<a [href]="link.href" target="_blank" rel="noopener">
										<span
											class="flex flex-row items-center justify-start gap-2">
											<svg
												viewBox="0 0 16 16"
												class="h-4 w-4"
												fill="currentColor">
												<use href="#arrow-right-circle" />
											</svg>
											{{ t(link.key) }}
										</span>
									</a>
								}
							</ng-container>
						</yuno-card>

						<!-- URL GENERATOR-->
						<yuno-card class="col-span-2">
							<ng-container title> Generate Embed Code</ng-container>
							<ng-container content>
								<yuno-url-generator></yuno-url-generator>
							</ng-container>
						</yuno-card>
					}

					@for (item of data.dashboard?.help?.items; track item; let i = $index) {
						<yuno-card class="col-span-2" [hoverMode]="true">
							<ng-container buttons>
								@if (minimalAppRole(userRoles.EDITOR)) {
									<button yuno-admin-button color="secondary" (click)="onEdit(i)">
										edit
									</button>
								}
								@if (minimalAppRole(userRoles.ADMIN)) {
									<button yuno-admin-button color="danger" (click)="onDelete(i)">
										delete
									</button>
								}
							</ng-container>
							<ng-container title>
								<div
									[innerHTML]="
										item.title | languageSelect: language | safeHtml
									"></div>
							</ng-container>
							<ng-container content>
								<div class="grid grid-cols-5 gap-6">
									@if (item.image && (item.image.source || item.image.preview)) {
										<div class="col-span-2">
											@if (
												item.image.preview &&
												item.image.source &&
												item.image.typeOf === 'gif'
											) {
												<yuno-gif
													[preview]="item.image.preview"
													[gif]="item.image.source"></yuno-gif>
											}

											@if (item.image.typeOf === 'image') {
												<img alt="image-source" [src]="item.image.source" />
											}

											@if (
												item.image.source && item.image.typeOf === 'video'
											) {
												<div class="m-auto">
													<yuno-textfield-video
														[videourl]="item.image.source"
														[type]="'vimeo'"></yuno-textfield-video>
												</div>
											}
										</div>
									}

									<div
										class="yuno__textfield__styling"
										[ngClass]="{
											'col-span-5': !(
												item.image &&
												(item.image.source || item.image.preview)
											),
											'col-span-3':
												item.image &&
												(item.image.source || item.image.preview)
										}">
										<div
											[innerHTML]="
												item.description
													| languageSelect: language
													| safeHtmlIframe
											"></div>

										@for (link of item.links; track link) {
											<a [routerLink]="['../', link.destination]">
												<span
													class="flex flex-row items-center justify-start gap-2">
													<svg
														viewBox="0 0 16 16"
														class="h-4 w-4"
														fill="currentColor">
														<use href="#arrow-right-circle" />
													</svg>
													<span
														[innerHTML]="
															link.title
																| languageSelect: language
																| safeHtmlIframe
														">
													</span>
												</span>
											</a>
										}
									</div>
								</div>
							</ng-container>
						</yuno-card>
					}
				</div>
			</section>
		</div>
	}
</ng-container>
