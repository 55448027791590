import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Participated } from '@yuno/api/interface';

export const participationsActions = createActionGroup({
	source: 'Participation Participations',
	events: {
		load: props<{ id: string }>(),
		loadSuccess: props<{ data: Partial<Participated>[] }>(),
		loadFailure: props<{ error: Error }>(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Participated }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<Participated> }>(),
		updateSelectSuccess: props<{ data: Partial<Participated> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		setOnHold: emptyProps(),
		setOnHoldSuccess: props<{ data: Participated }>(),
		setOnHoldFailure: props<{ error: Error }>(),

		sendAnswerOnly: emptyProps(),
		sendAnswerOnlySuccess: props<{ data: Participated }>(),
		sendAnswerOnlyFailure: props<{ error: Error }>(),

		setPublished: emptyProps(),
		setPublishedSuccess: props<{ data: Participated }>(),
		setPublishedFailure: props<{ error: Error }>(),

		setArchived: emptyProps(),
		setArchivedSuccess: props<{ data: Participated }>(),
		setArchivedFailure: props<{ error: Error }>(),

		onUpdateAnswer: props<{ data: string }>(),
		onUpdateAnswerSuccess: props<{ data: Participated }>(),
		onUpdateAnswerFailure: props<{ error: Error }>(),

		onUpdateReaction: props<{ data: string }>(),
		onUpdateReactionSuccess: props<{ data: Participated }>(),
		onUpdateReactionFailure: props<{ error: Error }>(),

		reset: emptyProps()
	}
});
