import { gql } from 'apollo-angular';

export const DELETE_TEMPLATE = gql`
	mutation deleteTemplateById($_id: String!) {
		deleteTemplate(_id: $_id)
	}
`;

export type DeleteTemplateMutation = {
	deleteTemplate: string;
};
