import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Cookie, CookieLanguage } from '@yuno/api/interface';

import { cookieActions } from './cookies.actions';

export interface CookiesState {
	values: Partial<Cookie>[];
	languageValues: Partial<CookieLanguage>[];
	selected: Cookie | undefined;
	selectedLanguage: CookieLanguage | undefined;
	status: Status;
	languageStatus: Status;
	statusSelected: Status;
	statusSelectedLanguage: Status;
}

export const initialState: CookiesState = {
	values: [],
	languageValues: [],
	selected: undefined,
	selectedLanguage: undefined,
	status: Status.PENDING,
	languageStatus: Status.PENDING,
	statusSelected: Status.PENDING,
	statusSelectedLanguage: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(cookieActions.get, state => ({
		...state,
		values: [],
		status: Status.VALIDATING
	})),
	on(cookieActions.getFailure, state => ({
		...state,
		values: [],
		status: Status.FAILED
	})),
	on(cookieActions.getSuccess, (state, action) => ({
		...state,
		values: action.data,
		status: Status.SUCCESS
	})),
	on(cookieActions.getLanguage, state => ({
		...state,
		languageValues: [],
		languageStatus: Status.VALIDATING
	})),
	on(cookieActions.getLanguageFailure, state => ({
		...state,
		languageValues: [],
		languageStatus: Status.FAILED
	})),
	on(cookieActions.getLanguageSuccess, (state, action) => ({
		...state,
		languageValues: action.data,
		languageStatus: Status.SUCCESS
	})),

	on(cookieActions.getById, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.VALIDATING
	})),
	on(cookieActions.getByIdFailure, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.FAILED
	})),
	on(cookieActions.getByIdSuccess, (state, action) => ({
		...state,
		selected: action.data,
		statusSelected: Status.SUCCESS
	})),

	on(cookieActions.getLanguageById, state => ({
		...state,
		selectedLanguage: undefined,
		statusSelectedLanguage: Status.VALIDATING
	})),
	on(cookieActions.getLanguageFailureById, state => ({
		...state,
		selectedLanguage: undefined,
		statusSelectedLanguage: Status.FAILED
	})),
	on(cookieActions.getLanguageSuccessById, (state, action) => ({
		...state,
		selectedLanguage: action.data,
		statusSelectedLanguage: Status.SUCCESS
	})),
	on(cookieActions.update, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(cookieActions.updateLanguage, (state, action) => ({
		...state,
		selectedLanguage: action.data
	})),
	on(cookieActions.resetSelected, state => ({
		...state,
		selected: undefined,
		statusSelected: Status.PENDING
	})),
	on(cookieActions.resetSelectedLanguage, state => ({
		...state,
		selectedLanguage: undefined,
		statusSelectedLanguage: Status.PENDING
	}))
);

export const cookieFeature = createFeature({
	name: 'cookies',
	reducer
});
