import { AsyncPipe, CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	inject
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RouterLinkActive, RouterOutlet } from '@angular/router';
import { combineLatest, map, startWith, take, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { FileUploadService } from '@yuno/admin/features/file-upload';
import {
	AdminSearchBarComponent,
	YunoAdminButtonsModule,
	YunoAdminTableComponent,
	YunoCardModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { ApiService, DialogType, FileFormatTypes } from '@yuno/angular/api';
import {
	DialogItem,
	MessageDialogComponent,
	MessageService,
	ToastItem
} from '@yuno/angular/notifications';
import { CdnFile, MediaImageFile } from '@yuno/api/interface';

import { FilesFacade } from '../../data-access';
import { FileLibraryService } from './file-library.service';

@Component({
	selector: 'yuno-admin-file-library',
	standalone: true,
	imports: [
		CommonModule,
		YunoCardModule,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		AsyncPipe,
		RouterOutlet,
		RouterLinkActive,
		AdminSearchBarComponent
	],
	providers: [ApiService],
	templateUrl: './file-library.component.html',
	styleUrls: ['./file-library.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryComponent extends AppDataComponent implements OnInit {
	private readonly appFacade = inject(AppFacade);
	private readonly filesFacade = inject(FilesFacade);
	private readonly service = inject(FileLibraryService);
	private readonly fileUploadService = inject(FileUploadService);
	private readonly message = inject(MessageService);

	private readonly cdr = inject(ChangeDetectorRef);
	private readonly dialog = inject(MatDialog);
	private dialogRef: MatDialogRef<MessageDialogComponent>;

	previewPills: string[] = ['No', 'Yes'];
	preview = 'Yes';

	filterPills: { label: string; value: string }[] = [
		{
			label: 'All',
			value: ''
		},
		{
			label: 'Images',
			value: 'image'
		},
		{
			label: 'PDF',
			value: 'pdf'
		},
		{
			label: 'ZIP',
			value: 'zip'
		}
	];
	filter = '';

	activeFile: MediaImageFile;
	editFile = false;
	data$ = combineLatest({
		appId: this.appFacade.appId$.pipe(startWith(undefined)),
		files: combineLatest([
			this.service.filter$.pipe(startWith('')),
			this.filesFacade.files$
		]).pipe(
			map(([filter, files]) => {
				if (filter) {
					return files?.filter(file =>
						file.data?.fileName?.toLowerCase().includes(filter)
					);
				}
				return files;
			}),
			tap(() => {
				this.runChangeDetection();
			})
		),
		close: this.fileUploadService.closed$.pipe(
			startWith(undefined),
			tap(() => {
				this.editFile = false;
			})
		)
	});

	ngOnInit(): void {
		this.filesFacade.get();
	}

	applyFilter(str: string): void {
		this.service.filter(str.toLowerCase());
	}

	setPreview(pill: string) {
		this.preview = pill;
	}

	setFilter(pill: string) {
		this.filter = pill;
		this.filesFacade.search(pill);
	}

	setActiveFile(row: Partial<CdnFile>, file?: CdnFile) {
		this.activeFile = row;
		this.service.onActiveFile(this.activeFile);
		this.runChangeDetection();
	}

	// For some reason when using inside TinyMCE the change detection does not work
	// correctly. This is a workaround to force the change detection to run.
	runChangeDetection() {
		setTimeout(() => {
			this.cdr.detectChanges();
		});
	}

	onAdd(row?: Partial<CdnFile>, appId?: string): void {
		if (appId) {
			const data: DialogType = {
				appId: appId,
				type: FileFormatTypes.file,
				filesizeLimit: 50,
				patch: this.editFile ? (row ? row._id : undefined) : undefined
			};

			this.fileUploadService.openDialogCdn(data);
			this.fileUploadService.response.pipe(take(1)).subscribe(event => {
				event && this.setActiveFile(event as CdnFile);
			});
		} else {
			const fail: ToastItem = {
				message: `Could not find an Application ID!`
			};
			this.message.showToast(fail, 'error');
		}
	}

	onEdit(row: Partial<CdnFile>, appId?: string) {
		this.editFile = !!row._id;
		this.onAdd(row, appId);
	}

	onCopy(row: Partial<CdnFile>) {
		this.copyToClipboard(row);
	}

	onDelete(row: Partial<CdnFile>, appId?: string) {
		const data: DialogItem = {
			title: 'Confirm',
			message: 'Are you sure you want to remove this File?',
			buttons: [
				{
					key: 'delete',
					type: 'danger',
					confirm: true
				},
				{
					key: 'Cancel'
				}
			],
			confirm: 'Confirmed'
		};

		this.dialogRef = this.dialog.open(MessageDialogComponent, {
			data: data
		});
		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				this.removeFile(row, appId);
			}
		});
	}

	removeFile(row: Partial<CdnFile>, appId?: string) {
		if (appId && row._id) {
			this.fileUploadService.deleteFile(appId, row._id).subscribe(() => {
				this.filesFacade.get();
			});
		}
	}

	copyToClipboard(row: Partial<CdnFile>) {
		if (!row?.data?.url) {
			const message = `error copying to clipboard`;
			const data: ToastItem = {
				message: message,
				duration: 3,
				dismiss: false,
				unique: false
			};
			this.message.showToast(data, 'warning');
		}

		if (row?.data?.url) {
			navigator.clipboard.writeText(row.data.url);
			const message = `file url is copied to your clipboard`;
			const data: ToastItem = {
				message: message,
				duration: 3,
				dismiss: false,
				unique: false
			};
			this.message.showToast(data, 'success');
		}
	}

	createSrcSet(row: Partial<CdnFile>): undefined | string | string[] {
		const fileSizes = [320, 500, 720, 1280, 1920];

		if (!row.data?.sizes) {
			return;
		}

		const minSize = Math.min(...row.data.sizes);
		const isGif = row.data?.ext === '.gif';

		if (minSize < 320 || isGif) {
			return `${row.data?.url} ${row.data?.maxSize}w`;
		}

		const containerWidth = 240;
		let selectedImage = row.data?.url || ''; // default to the original image

		for (const size of row.data.sizes) {
			if (!fileSizes.includes(size)) {
				selectedImage = `${row.data?.url} ${row.data?.maxSize}w`;
				break;
			}

			if (containerWidth <= size) {
				const fileName =
					row.data?.url?.substring(0, row.data?.url.lastIndexOf('.')) || row.data?.url;

				selectedImage = `${fileName}-w${size}${row.data?.ext} ${size}w`;
				break;
			}
		}

		return selectedImage;
	}

	onImgError(event: Event, row: Partial<CdnFile>) {
		const imgElement = event.target as HTMLImageElement;
		if (!imgElement.srcset) {
			imgElement.src =
				'https://cdn.projectatlas.app/content/static/images/portal-404.svg' as string;
			return;
		}

		imgElement.src = row.data?.url as string;
		imgElement.removeAttribute('srcset');
	}
}
