import { Injectable, InjectionToken, inject } from '@angular/core';
import {
	ApolloError,
	ApolloQueryResult,
	MutationOptions,
	NetworkStatus
} from '@apollo/client/core';
import { Apollo, MutationResult, WatchQueryOptions } from 'apollo-angular';
import { GraphQLError } from 'graphql';
import { Observable, tap } from 'rxjs';

export const API_URL = new InjectionToken('ApiUrl');

export class QueryResult<T> {
	data: T;
	errors?: readonly GraphQLError[] | undefined;
	error?: ApolloError | undefined;
	loading: true;
	networkStatus: NetworkStatus;
	partial?: boolean | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(obj: any) {
		this.data = obj.data;
		this.errors = obj.errors || undefined;
		this.error = obj.error || undefined;
		this.loading = obj.loading || false;
		this.networkStatus = obj.networkStatus || NetworkStatus.ready;
		this.partial = obj.partial || undefined;
	}
}

@Injectable({
	providedIn: 'root'
})
export class GraphQLService {
	private readonly apollo = inject(Apollo);

	query<T>(options: {
		query: WatchQueryOptions['query'];
		variables?: { [key: string]: unknown };
	}): Observable<ApolloQueryResult<T>> {
		return this.apollo
			.query({
				query: options.query,
				variables: options.variables
			})
			.pipe(
				tap(data => {
					// Check for errors and throw if Error is Found
					if (data.errors) {
						throw data.errors;
					}
				})
			);
	}

	mutate<T>(options: {
		mutation: MutationOptions['mutation'];
		variables?: { [key: string]: unknown };
	}): Observable<MutationResult<T>> {
		return this.apollo
			.mutate({
				mutation: options.mutation,
				variables: options.variables
			})
			.pipe(
				tap(data => {
					// Check for errors and throw if Error is Found
					if (data.errors) {
						throw data.errors;
					}
				})
			);
	}
}
