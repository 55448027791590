import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Subject, combineLatest, debounceTime, startWith, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import {
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoAdminNavbarButtonsComponent,
	YunoAdminTableComponent,
	YunoCardModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule, moveItemInFormArray } from '@yuno/angular/forms';
import { LanguagesModel, Navigation } from '@yuno/api/interface';

import { NavigationsFacade } from './../../data-access';
import { NavigationDropdownEditorComponent } from './../editor/navigation-dropdown-editor/navigation-dropdown-editor.component';
import { NavigationsRoutingModule } from './navigations-routing.module';
import { NavDropdownItemForm, NavItemForm, NavigationEditorService } from './navigations.service';

@Component({
	selector: 'yuno-admin-navigations',
	standalone: true,
	imports: [
		CommonModule,
		NavigationsRoutingModule,
		ReactiveFormsModule,
		DragDropModule,
		MatCheckboxModule,
		YunoCardModule,
		YunoFormsModule,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		UserRightsMessageComponent,
		YunoAdminNavbarButtonsComponent,
		NavigationDropdownEditorComponent
	],
	providers: [NavigationEditorService],
	templateUrl: './navigations.component.html',
	styleUrls: ['./navigations.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationsComponent extends AppDataComponent implements OnInit {
	private readonly appFacade = inject(AppFacade);
	private readonly navigationsFacade = inject(NavigationsFacade);
	private readonly destroyRef = inject(DestroyRef);
	private _dropdownEditor = new Subject<boolean>();
	readonly service = inject(NavigationEditorService);

	originalData: Partial<Navigation>;

	data$ = combineLatest({
		navigation: this.navigationsFacade.navigation$.pipe(
			startWith(undefined),
			tap(data => {
				if (data) {
					if (!this.originalData) {
						this.originalData = data;

						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						this.service.form.patchValue(data as any);
						if (data?.navigation && data.navigation.length >= 1) {
							this.service.addNavItems(data.navigation);
						}

						// toggle public
						if (this.minimalAppRole(this.userRoles.EDITOR)) {
							this.service.enablePublicToggles();
						}
					}
				}
			})
		),
		appUrl: this.appFacade.appUrl$,
		dropdownEditor: this._dropdownEditor.asObservable().pipe(startWith(false))
	});

	hoverItem: number | null;
	hoverChild: number | null;

	ngOnInit(): void {
		this.navigationsFacade.getNavigationsByAppId();
		this.service.createFormGroup();
		this.onChanges();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.navigationsFacade.update(
					this.service.form.getRawValue() as Partial<Navigation>
				);
			});
	}

	onSave() {
		this.navigationsFacade.save();
	}

	drop(event: CdkDragDrop<FormArray<FormGroup<NavItemForm>>['value']>): void {
		moveItemInFormArray(this.service.navigation, event.previousIndex, event.currentIndex);
	}

	async dropChildren(
		event: CdkDragDrop<FormArray<FormGroup<NavDropdownItemForm>>['value']>,
		index: number
	): Promise<void> {
		this.service.currentIndex = index;
		moveItemInFormArray(this.service.navDropdown, event.previousIndex, event.currentIndex);
	}

	getLanguage(val?: LanguagesModel): string {
		if (val) {
			return val[this.language || 'nl'] as string;
		}
		return '';
	}

	onSelect(index: number) {
		this.service.currentIndex = index;

		this.router.navigate(['edit'], {
			relativeTo: this.route
		});
	}

	onSelectDropdown(index: number, selectedIndex: number) {
		this.service.currentIndex = selectedIndex;
		this.service.dropdownIndex = index;
		this._dropdownEditor.next(true);
	}

	onRemoveDropdown(index: number, selectedIndex: number) {
		this.service.currentIndex = selectedIndex;
		this.service.removeDropdownItem(index);
	}

	onCloseDropdown(bool: boolean) {
		this._dropdownEditor.next(!bool);
		this.languageService.destroyLanguageSelector();
	}
}
