import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { TextEditorsService } from '../text-editors.service';
import { ToggleItemComponent } from './toggle-item/toggle-item.component';

@Component({
	selector: 'yuno-admin-toggle-editor',
	standalone: true,
	imports: [
		DragDropModule,
		ReactiveFormsModule,
		YunoFormsModule,
		AsyncPipe,
		YunoAdminButtonComponent,
		ToggleItemComponent
	],
	templateUrl: './toggle-editor.component.html',
	styleUrls: ['./toggle-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService().pipe(
		map(service => {
			return this.editService.returnTextfieldService(service);
		})
	);

	ngOnInit() {
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
