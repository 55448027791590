import { gql } from 'apollo-angular';

import { MediaImage } from '@yuno/api/interface';

export const SAVE_IMAGE = gql`
	mutation saveImage($image: MediaImageInput!, $appId: String!) {
		saveImage(image: $image, appId: $appId) {
			_id
			file
		}
	}
`;

export type SaveImageMutation = {
	saveImage: Partial<MediaImage>;
};
