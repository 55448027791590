import { gql } from 'apollo-angular';

export const SAVE_COOKIE_LANGUAGE = gql`
	mutation saveCookieLanguage($data: CookieLanguageInput!) {
		saveCookieLanguage(data: $data) {
			_id
			language
			interface {
				title
				description
				show
				hide
				saveAll
				saveSelection
			}
			cookies {
				analytics {
					description
					title
				}
				functional {
					description
					title
				}
				thirdparty {
					description
					title
				}
			}
		}
	}
`;

export const SAVE_COOKIE = gql`
	mutation saveCookie($data: CookieInput!) {
		saveCookie(data: $data) {
			_id
			cookieType
			expiry
			name
			policy
			provider
			purpose
			type
		}
	}
`;

export const SAVE_PRIVACY_STATEMENT = gql`
	mutation savePrivacyStatementById($data: PrivacyStatementInput!) {
		savePrivacyStatementById(data: $data) {
			_id
			language
			content
		}
	}
`;

export const SAVE_PRIVACY_STATEMENT_PROJECT = gql`
	mutation savePrivacyStatementProjectById($data: PrivacyStatementProjectInput!) {
		savePrivacyStatementProjectById(data: $data) {
			_id
			id
			languages
		}
	}
`;

export const SAVE_PRIVACY_CONFIG = gql`
	mutation savePrivactStatementConfig($data: PrivacyStatementConfigInput!) {
		savePrivactStatementConfig(data: $data) {
			_id
			appId
			options {
				participate
				processingAgreement
			}
			clientActive
			clientName
			url
			custom {
				_id
			}
		}
	}
`;
