import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import {
	GET_TEXTTOGGLES,
	TextTogglesQuery
} from '@yuno/admin/features/textfield-pages/utils/graphql/components/getTextfieldComponents';
import { GraphQLService } from '@yuno/angular-graphql';

import { textTogglesActions } from './text-toggle.actions';

export const loadTextToggles = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textTogglesActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<TextTogglesQuery>({
						query: GET_TEXTTOGGLES,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.toggles) {
								throw new Error('no text-toggles found');
							}
							return textTogglesActions.loadSuccess({
								data: data.data.toggles
							});
						}),
						take(1),
						catchError(error => of(textTogglesActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);
