import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { colspan } from '../../../helpers';
import { JsonLabel } from '../../../json-forms.models';

@Component({
	selector: 'yuno-json-forms-types-label',
	template: `
		@if (item.label || item.labelDescr) {
			<section class="flex flex-col gap-2">
				@if (item.label) {
					<span class="text-xl font-bold">{{ item.label }}</span>
				}
				@if (item.labelDescr) {
					<span class="mb-4 text-sm">{{ item.labelDescr }}</span>
				}
			</section>
		}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true
})
export class LabelComponent {
	_item: JsonLabel;

	@Input()
	set item(item: JsonLabel) {
		this._item = item;
		this.className = this.colspan();
	}
	get item(): JsonLabel {
		return this._item;
	}

	@HostBinding('className') className: string;

	colspan(): string {
		return colspan(this.item.colspan || 'full');
	}
}
