import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import {
	ClientsQuery,
	DELETE_CLIENT,
	DUPLICATE_CLIENT,
	DeleteClientMutation,
	DuplicateClientMutation,
	GET_CLIENTS,
	GET_CLIENT_BY_ID,
	SAVE_CLIENT,
	SaveClientMutation,
	SelectClientQuery
} from '@yuno/admin/features/clients/utils';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { clientsActions } from './clients.actions';
import { clientsFeature } from './clients.state';

export const loadClients = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(clientsActions.load, clientsActions.reload),
			switchMap(() =>
				graphql
					.query<ClientsQuery>({
						query: GET_CLIENTS
					})
					.pipe(
						map(data => {
							if (!data.data.clients) {
								throw new Error('no clients found');
							}
							return clientsActions.loadSuccess({
								data: data.data.clients
							});
						}),
						take(1),
						catchError(error => of(clientsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectClient = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(clientsActions.select),
			switchMap(client =>
				graphql
					.query<SelectClientQuery>({
						query: GET_CLIENT_BY_ID,
						variables: {
							_id: client._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedClient) {
								message.sendToast(`Error selecting Client!`, 'error');
								throw new Error('no Client with that id found');
							}

							return clientsActions.selectSuccess({
								data: data.data.selectedClient
							});
						}),
						take(1),
						catchError(error => {
							message.sendToast(`Error selecting Client!`, 'error');
							return of(clientsActions.selectFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const updateClient = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(clientsActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating Client!`, 'error');
					throw new Error('no Client found');
				}
				return clientsActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(clientsActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveClient = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(clientsActions.save),
			withLatestFrom(store.pipe(select(clientsFeature.selectSelected))),
			switchMap(([, selected]) =>
				graphql
					.mutate<SaveClientMutation>({
						mutation: SAVE_CLIENT,
						variables: {
							client: selected
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveClient) {
								throw new Error('error saving client to the database');
							}
							store.dispatch(
								clientsActions.updateSelect({ data: data.data.saveClient })
							);
							return clientsActions.saveSuccess();
						}),
						take(1),
						catchError(error => of(clientsActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const duplicateClient = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(clientsActions.duplicate),
			switchMap(client =>
				graphql
					.mutate<DuplicateClientMutation>({
						mutation: DUPLICATE_CLIENT,
						variables: {
							_id: client._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateClient) {
								throw new Error('error saving client to the database');
							}
							store.dispatch(clientsActions.reload());
							return clientsActions.duplicateSuccess({
								data: data.data.duplicateClient
							});
						}),
						take(1),
						catchError(error => of(clientsActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteClient = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(clientsActions.delete),
			switchMap(client =>
				graphql
					.mutate<DeleteClientMutation>({
						mutation: DELETE_CLIENT,
						variables: {
							_id: client._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteClient) {
								throw new Error('error deleting Client from the database');
							}
							store.dispatch(clientsActions.reload());
							return clientsActions.deleteSuccess({
								_id: data.data.deleteClient
							});
						}),
						take(1),
						catchError(error => of(clientsActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveClientSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(clientsActions.saveSuccess),
			tap(() => {
				message.sendToast(`Client successfully saved!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveClientFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(clientsActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Client!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateClientSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(clientsActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Client successfully duplicated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const duplicateClientFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(clientsActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Client!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteClientSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(clientsActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Client successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteClientFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(clientsActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Client!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
