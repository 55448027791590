import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { YunoFormsModule } from '@yuno/angular/forms';

import { TextEditorsService } from '../text-editors.service';

@Component({
	selector: 'yuno-admin-link-editor',
	standalone: true,
	imports: [ReactiveFormsModule, YunoFormsModule, AsyncPipe, AnnotationComponent],
	templateUrl: './link-editor.component.html',
	styleUrls: ['./link-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly editService = inject(TextEditorsService);

	service$ = this.editService.getActiveService();
	data$ = this.editService.data$;

	onType(form: FormGroup, type: string | null) {
		if (type === 'url') {
			form.get('page')?.reset();
			form.get('link')?.reset();
		}
		if (type === 'link') {
			form.get('url')?.reset();
			form.get('page')?.reset();
		}
		if (type === 'page') {
			form.get('url')?.reset();
			form.get('link')?.reset();
		}
	}

	ngOnInit(): void {
		this.editService.onInitPages();
		this.editService.onInitNav();
		this.languageSelector();
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
