import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';

import { GET_PRODUCTION_BY_APPID, ProductionQuery } from '../../utils/graphql/checkProduction';
import { appDataActions } from './app-data.actions';

export const checkProduction = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(appDataActions.checkProduction),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<ProductionQuery>({
						query: GET_PRODUCTION_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.checkProduction) {
								throw new Error('no return value');
							}
							return appDataActions.productionSuccess({
								data: data.data.checkProduction
							});
						}),
						take(1),
						catchError(error => of(appDataActions.productionFailure({ error })))
					)
			)
		),
	{ functional: true }
);
