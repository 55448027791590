import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Dashboard, DashboardHelp, DashboardHelpItem } from '@yuno/api/interface';

import { dashboardActions } from './dashboard.actions';

export interface DashboardState {
	loaded: boolean;
	dashboard: Partial<Dashboard> | undefined;
	selectedLoaded: number | undefined;
	selected: Partial<DashboardHelpItem> | undefined;
	status: Status;
}

export const initialState: DashboardState = {
	loaded: false,
	dashboard: undefined,
	selectedLoaded: undefined,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(dashboardActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(dashboardActions.loadSuccess, (state, action) => ({
		...state,
		loaded: true,
		dashboard: action.data,
		status: Status.SUCCESS
	})),
	on(dashboardActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(dashboardActions.addItem, state => {
		const dashboard: Partial<Dashboard> = Object.assign({}, state.dashboard);
		const help: DashboardHelp = Object.assign({}, dashboard.help);
		const items: DashboardHelpItem[] = Object.assign([], help?.items);
		items.push({
			title: {
				nl: 'Nieuw Item',
				de: 'Neuer Item',
				en: 'New Item',
				fr: 'Nouvel Article'
			},
			description: {
				nl: 'beschrijving...',
				de: 'Beschreibung...',
				en: 'description...',
				fr: 'description...'
			}
		});
		help.items = [...items];
		dashboard.help = { ...help };
		return {
			...state,
			dashboard
		};
	}),

	on(dashboardActions.removeItem, (state, action) => {
		const dashboard: Partial<Dashboard> = Object.assign({}, state.dashboard);
		const help: DashboardHelp = Object.assign({}, dashboard.help);
		const items: DashboardHelpItem[] = Object.assign([], help?.items);
		items.splice(action.index, 1);
		help.items = [...items];
		dashboard.help = { ...help };
		return {
			...state,
			dashboard
		};
	}),

	on(dashboardActions.select, (state, action) => {
		let selected = undefined;
		if (state.dashboard?.help?.items) {
			selected = state.dashboard.help.items[action.index];
		}
		return {
			...state,
			selectedLoaded: action.index,
			selected: selected
		};
	}),
	on(dashboardActions.clearSelect, state => ({
		...state,
		selectedLoaded: undefined,
		selected: undefined
	})),

	on(dashboardActions.updateSelect, (state, action) => ({
		...state,
		selected: action.data
	})),

	on(dashboardActions.updatePublic, (state, action) => ({
		...state,
		dashboard: { ...state.dashboard, public: action.public }
	})),

	on(dashboardActions.update, state => {
		const dashboard: Partial<Dashboard> = Object.assign({}, state.dashboard);
		if (dashboard && dashboard?.help?.items && state.selectedLoaded !== undefined) {
			const help: DashboardHelp = Object.assign({}, dashboard.help);
			const items: DashboardHelpItem[] = Object.assign([], help?.items);
			items[state.selectedLoaded] = {
				...items[state.selectedLoaded],
				...state.selected
			};
			help.items = [...items];
			dashboard.help = { ...help };
		}
		return {
			...state,
			dashboard
		};
	})
);

export const dashboardFeature = createFeature({
	name: 'dashboard',
	reducer
});
