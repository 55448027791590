import { gql } from 'apollo-angular';

import { Panorama } from '@yuno/api/interface';

export const GET_PANORAMAS_BY_APPID = gql`
	query getPanoramasByAppId($appId: String!) {
		panoramas(appId: $appId) {
			_id
			id
			public
			selected
			map {
				display
				icon
			}
			textfield {
				states {
					text
				}
			}
		}
	}
`;

export const GET_PANORAMA_BY_ID = gql`
	query getPanoramaById($_id: String!) {
		selectedPanorama(_id: $_id) {
			_id
			id
			alt
			public
			selected
			tileType
			visibility
			map {
				color
				coordinates
				display
				icon
				minZoom
				maxZoom
				rotation
				visibility
			}
			view {
				fov
				pitch
				yaw
			}
			location {
				coordinates
				height
			}
			offset {
				position {
					x
					y
					z
				}
				rotation {
					x
					y
					z
				}
			}
			textfield {
				default
				states {
					state
					text
				}
			}
			states {
				available {
					_id
					state
				}
				default {
					_id
					state
				}
			}
			url {
				client
				pano
				prefix
				preview
				project
				xml
			}
			limitView {
				active
				hlookatmin
				hlookatmax
				vlookatmin
				vlookatmax
				limitview
			}
			hotspots {
				active
				distance
				type
			}
		}
	}
`;

export type PanoramasQuery = {
	panoramas: Partial<Panorama>[];
};

export type SelectPanoramaQuery = {
	selectedPanorama: Partial<Panorama>;
};
