import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { DataObjects, Pointcloud, Shapefile, Threejs } from '@yuno/api/interface';

import { objectsActions } from './objects.actions';

export interface ObjectsState {
	loaded: boolean;
	objects: Partial<DataObjects>[];
	selectedLoaded: boolean;
	selected: Partial<DataObjects> | undefined;
	status: Status;
}

export const initialState: ObjectsState = {
	loaded: false,
	objects: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(objectsActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(objectsActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		objects: data,
		status: Status.SUCCESS
	})),
	on(objectsActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	// Select
	on(objectsActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false
	})),
	on(objectsActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(objectsActions.selectFailure, (state, { error }) => ({ ...state, error })),

	// Select Update
	on(objectsActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: {
			...state.selected,
			...data
		},
		selectedLoaded: true
	})),
	on(objectsActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	// Save Update
	on(objectsActions.saveSuccess, state => {
		const index = state.objects.findIndex(
			x =>
				(x.data && x.data._id) ===
				(state.selected && state.selected.data && state.selected.data._id)
		);
		const objects = [...state.objects];
		if (index >= 0) {
			objects[index] = { ...objects[index], ...state.selected };
		} else if (state.selected) {
			let data = state.selected.data;
			if (state.selected.type === 'data-shapefile') {
				data = { ...data } as Shapefile;
			}
			if (state.selected.type === 'data-threejs-model') {
				data = { ...data } as Threejs;
			}
			if (state.selected.type === 'data-threejs-pointcloud') {
				data = { ...data } as Pointcloud;
			}
			objects.push({ ...state.selected, data });
		}
		return {
			...state,
			objects: objects
		};
	})
);

export const objectsFeature = createFeature({
	name: 'objects',
	reducer
});
