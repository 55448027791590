import { InjectionToken } from '@angular/core';

import { PagesComponentsTypes } from '@yuno/api/interface';

export const token = new InjectionToken<injectClass>('');

export class injectClass {
	data: PagesComponentsTypes;
	editor: 'textfield' | 'page';
	side: 'left' | 'right';
	index: number;
}
