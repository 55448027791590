<router-outlet></router-outlet>

<yuno-card>
	<ng-container content>
		<section class="grid grid-cols-1">
			@if (data$ | async; as data) {
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="false"
					[pagination]="false"
					[pageOptions]="{
						pageSize: 25,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="data.layers"
					[buttons]="tableButtons"
					[columns]="[
						{ key: 'public', type: 'visible', disableVisibility: true },
						{ key: 'id', label: 'layer' },
						{ key: 'source', label: 'Source' },
						{ key: 'sourceLayer', label: 'Source layer' }
					]"
					(clicked)="onSelect($event)">
					<button yuno-admin-add-button (click)="onCreate()">New Layer</button>
				</yuno-admin-table>
			}
		</section>
	</ng-container>
</yuno-card>

<ng-template #tableButtons let-row>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
