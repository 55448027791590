import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AnnotationComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { DialogType, FileData, FileFormatTypes } from '@yuno/angular/api';

import { CdnFileUploadService } from '../data-access/cdn-file-upload.service';
import { FileUploadService } from '../data-access/file-upload.service';

@Component({
	standalone: true,
	imports: [MatDialogModule, MatProgressBarModule, YunoAdminButtonsModule, AnnotationComponent],
	selector: 'yuno-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent {
	@ViewChild('fileInput', { static: false }) private fileInput: ElementRef;

	@Input() filesizeLimit = 50;
	@Input() acceptedFileTypes = FileFormatTypes.excel;

	@Output() filedata: EventEmitter<FileData>;

	dialog: DialogType = {
		appId: '',
		type: FileFormatTypes.excel,
		filesizeLimit: 50
	};

	selectedFiles: FileList | null;
	currentFileUpload: File | null;
	fileToBig = false;
	conflict = false;
	conflictType: 'key' | 'file' = 'file';

	progress: { percentage: number } = { percentage: 0 };

	warning = false;
	error = false;
	success = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) private data: DialogType,
		private dialogRef: MatDialogRef<FileUploadComponent>,
		private uploadService: FileUploadService,
		private uploadCdnService: CdnFileUploadService,
		private cdr: ChangeDetectorRef
	) {
		if (data) {
			this.dialog.appId = data.appId;
			this.dialog.cdn = !!data.cdn;

			if (data.type) {
				this.dialog.type = data.type;
				this.acceptedFileTypes = data.type;
			}

			if (data?.patch) {
				this.dialog.patch = data.patch;
			}

			if (data?.filesizeLimit) {
				this.dialog.filesizeLimit = data.filesizeLimit;
				this.filesizeLimit = data.filesizeLimit as number;
			} else {
				this.dialog.filesizeLimit = this.filesizeLimit;
			}
		}
	}

	selectFile(event: Event): void {
		const eventTarget = event.target as HTMLInputElement;
		this.selectedFiles = eventTarget.files as FileList;
		const fileSize = eventTarget.files && eventTarget.files[0].size / 1024 / 1024;
		this.fileToBig = (fileSize && fileSize > this.filesizeLimit) || false;

		this.cdr.detectChanges();
	}

	startUpload(overwrite?: boolean): void {
		if (this.dialog.cdn) {
			this.uploadCdn(overwrite);
			return;
		}

		this.upload();
	}

	async upload(): Promise<void> {
		if (!this.selectedFiles) {
			return;
		}

		this.progress.percentage = 0;
		this.currentFileUpload = this.selectedFiles.item(0) as File;

		this.uploadService
			.uploadFile(this.currentFileUpload)
			.subscribe((event: HttpEvent<unknown>) => {
				if (event.type === HttpEventType.UploadProgress) {
					this.progress.percentage = Math.round(
						(100 * event.loaded) / (event.total || 100)
					);
					this.cdr.detectChanges();
				} else if (event instanceof HttpResponse) {
					if (event.body && event.body['file' as keyof typeof event.body]) {
						this.filedata?.emit(event.body['file' as keyof typeof event.body]);
					}

					this.uploadService.response.next(event);
					this.clear();
				}
			});
	}

	async uploadCdn(overwrite?: boolean): Promise<void> {
		if (!this.selectedFiles) {
			return;
		}

		this.progress.percentage = 0;
		this.currentFileUpload = this.selectedFiles.item(0) as File;

		if (this.dialog.patch) {
			await this.patchFile(this.dialog.appId, this.currentFileUpload, true);
		} else {
			await this.postFile(this.dialog.appId, this.currentFileUpload, overwrite);
		}
	}

	async patchFile(appId: string, file: File, overwrite?: boolean, body?: unknown): Promise<void> {
		try {
			const formData = this.uploadCdnService.getFileBody(file, body);
			if (overwrite !== undefined) {
				formData.append('overwrite', overwrite ? 'true' : 'false');
			}

			/* Patch file to CDN */
			this.uploadCdnService
				.update(`update/${appId}/file/${this.dialog.patch}`, formData)
				.subscribe(
					async (event: HttpEvent<unknown>) => {
						if (event.type === HttpEventType.UploadProgress) {
							this.progress.percentage = Math.round(
								(100 * event.loaded) / (event.total || 100)
							);
							this.cdr.detectChanges();
						} else if (event.type === HttpEventType.Response) {
							if (event.body && event.body['file' as keyof typeof event.body]) {
								this.filedata?.emit(event.body['file' as keyof typeof event.body]);
							}

							const dbfile = await this.uploadCdnService.saveToDB(appId, event);
							this.uploadService.response.next(dbfile);
							this.clear();
						}
					},
					error => {
						if (error.status === 409) {
							this.showErrorMessage(error);
						}
					}
				);
		} catch (error) {
			console.error(error);
		}
	}

	async postFile(appId: string, file: File, overwrite?: boolean, body?: unknown): Promise<void> {
		try {
			const formData = this.uploadCdnService.getFileBody(file, body);

			if (overwrite !== undefined) {
				formData.append('overwrite', overwrite ? 'true' : 'false');
			}

			/* Save file to CDN */
			this.uploadCdnService.post(`${appId}/file`, formData).subscribe(
				async (event: HttpEvent<unknown>) => {
					if (event.type === HttpEventType.UploadProgress) {
						this.progress.percentage = Math.round(
							(100 * event.loaded) / (event.total || 100)
						);
						this.cdr.detectChanges();
					} else if (event.type === HttpEventType.Response) {
						if (event.body && event.body['file' as keyof typeof event.body]) {
							this.filedata?.emit(event.body['file' as keyof typeof event.body]);
						}

						const dbfile = await this.uploadCdnService.saveToDB(appId, event);
						this.uploadService.response.next(dbfile);
						this.clear();
					}
				},
				error => {
					if (error.status === 409) {
						this.showErrorMessage(error);
					}
				}
			);
		} catch (error) {
			console.error(error);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private showErrorMessage(err: any) {
		// Database Error
		// Key data.fileName duplicate, handle accordingly
		if (err.error.error.code === 11000) {
			this.conflictType = 'key';
		}
		// File Server error
		// File already exists, handle accordingly
		console.error('File already exists:', err);
		if (err.error.message === 'File already exists') {
			this.conflictType = 'file';
		}
		this.conflict = true;
		this.cdr.detectChanges();
	}

	private clear() {
		this.currentFileUpload = null;
		this.selectedFiles = null;
		this.progress.percentage = 0;

		if (this.fileInput) {
			this.fileInput.nativeElement.value = null;
		}

		this.dialogRef.close(true);
	}
}
