import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { DataObjects } from '@yuno/api/interface';

import { objectsActions } from './objects.actions';
import { objectsFeature } from './objects.state';

@Injectable()
export class ObjectsFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(objectsFeature.selectLoaded));
	objects$ = this.store.pipe(select(objectsFeature.selectObjects));
	selectedObjects$ = this.store.pipe(select(objectsFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(objectsFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(objectsFeature.selectStatus));

	get() {
		this.store.dispatch(objectsActions.load());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(objectsActions.select({ _id }));
	}

	updateSelect(data: Partial<DataObjects>) {
		this.store.dispatch(objectsActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(objectsActions.clearSelect());
	}

	save() {
		this.store.dispatch(objectsActions.save());
	}

	duplicate(data: Partial<DataObjects>) {
		this.store.dispatch(objectsActions.duplicate({ data }));
	}

	delete(data: Partial<DataObjects>) {
		this.store.dispatch(objectsActions.delete({ data }));
	}
}
