import { createFeature, createReducer, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { textfieldsActions } from '@yuno/admin/features/textfield-pages/data-access/+state/textfield/textfield.actions';
import { Status } from '@yuno/admin/utils';
import { Textfield, TextfieldComponents } from '@yuno/api/interface';

export interface TextfieldsState {
	loaded: boolean;
	textfields: Partial<Textfield>[] | undefined;
	selectedLoaded: boolean;
	selected: Partial<Textfield> | undefined;
	templates: TextfieldComponents[] | undefined;
	status: Status;
}

export const initialState: TextfieldsState = {
	loaded: false,
	textfields: [],
	selectedLoaded: false,
	selected: undefined,
	templates: [],
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(textfieldsActions.load, state => ({ ...state, loaded: false })),
	on(textfieldsActions.loadSuccess, (state, { data }) => ({
		...state,
		textfields: data,
		loaded: true
	})),
	on(textfieldsActions.loadFailure, (state, { error }) => ({ ...state, error })),

	on(textfieldsActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedLoaded: false,
		templates: []
	})),
	on(textfieldsActions.setTemplate, (state, { data }) => ({
		...state,
		templates: data
	})),

	on(textfieldsActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(textfieldsActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(textfieldsActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data } as Textfield,
		selectedLoaded: true
	})),
	on(textfieldsActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	on(textfieldsActions.updateTextfieldSuccess, (state, { bool, index }) => {
		const textfields = Object.assign([], state.textfields) as Textfield[];
		if (textfields) {
			textfields[index] = { ...textfields[index], public: bool };
		}
		return {
			...state,
			textfields: textfields
		};
	}),

	on(textfieldsActions.updateAllTextfieldsSuccess, (state, { bool, filter }) => {
		let textfields = Object.assign([], state.textfields) as Textfield[];
		if (filter) {
			textfields = textfields.map(text => {
				if (text.id.includes(filter)) {
					return { ...text, public: bool };
				}
				return text;
			});
		} else {
			textfields = textfields.map(text => {
				return { ...text, public: bool };
			});
		}
		return {
			...state,
			textfields: textfields
		};
	}),

	on(textfieldsActions.saveSuccess, state => {
		if (state.textfields && state.textfields.length >= 1) {
			const index = state.textfields.findIndex(
				x => x['_id'] === (state.selected && state.selected['_id'])
			);
			const textfields = [...(state.textfields as Textfield[])];
			const selected = { ...state.selected };
			delete selected.components;
			if (index >= 0) {
				textfields[index] = { ...textfields[index], ...selected };
			} else if (state.selected) {
				textfields.push({ ...selected } as Textfield);
			}

			textfields.sort((a, b) => ((a.id as string) > (b.id as string) ? 1 : -1));

			return {
				...state,
				textfields: textfields
			};
		} else {
			return state;
		}
	})
);

export const textfieldsFeature = createFeature({
	name: 'textfield',
	reducer
});
