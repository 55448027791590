import { gql } from 'apollo-angular';

export const DELETE_SOURCE = gql`
	mutation deleteSourceById($_id: String!) {
		deleteSource(_id: $_id)
	}
`;

export type DeleteSourceMutation = {
	deleteSource: string;
};
