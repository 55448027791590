import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, combineLatest, startWith, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { EventsFacade, EventsModule } from '@yuno/admin/features/events';
import { PagesFacade } from '@yuno/admin/features/textfield-pages';
import { ThemeFacade } from '@yuno/admin/features/themes';
import {
	AnnotationComponent,
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminTableComponent,
	YunoCardModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { JsonFormsModule } from '@yuno/angular-json-forms';
import { YunoFormsModule } from '@yuno/angular/forms';
import { DialogItem, MessageDialogComponent, MessageService } from '@yuno/angular/notifications';
import { Event, LanguageAll, NavigationItemDropdown } from '@yuno/api/interface';

import { NavDropdownItemForm, NavigationEditorService } from '../internal-view/navigations.service';
import { NavigationDropdownEditorComponent } from './navigation-dropdown-editor/navigation-dropdown-editor.component';

@Component({
	selector: 'yuno-admin-navigation-editor',
	standalone: true,
	imports: [
		CommonModule,
		JsonFormsModule,
		ReactiveFormsModule,
		YunoEditContainerModule,
		YunoCardModule,
		YunoFormsModule,
		YunoAdminButtonsModule,
		NavigationDropdownEditorComponent,
		YunoAdminTableComponent,
		EventsModule,
		AnnotationComponent,
		EditContainerContentDirective
	],
	templateUrl: './navigation-editor.component.html',
	styleUrls: ['./navigation-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly pagesFacade = inject(PagesFacade);
	private readonly themesFacade = inject(ThemeFacade);
	private readonly eventsFacade = inject(EventsFacade);
	private readonly appFacade = inject(AppFacade);
	private readonly dialog = inject(MatDialog);
	private dialogRef: MatDialogRef<MessageDialogComponent>;

	readonly service = inject(NavigationEditorService);
	readonly message = inject(MessageService);

	languages = LanguageAll;
	navEvents: boolean;
	disableClose = false;
	originLink: string;
	linkChanged = false;

	private _dropdownEditor = new Subject<boolean>();

	data$ = combineLatest({
		pages: this.pagesFacade.pages$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.pageValues = data.map(object => 'page/' + object.id) as string[];
					this.service.pageDisplay = data.map(object => object.id) as string[];
				}
			})
		),
		themes: this.themesFacade.themes$.pipe(
			tap(data => {
				if (data && data.length >= 1) {
					this.service.themeValues = data.map(object => object._id) as string[];
					this.service.themeDisplay = data.map(object => object.id) as string[];
				}
			})
		),
		dropdownEditor: this._dropdownEditor.asObservable().pipe(startWith(false)),
		toggle: this.eventsFacade.toggle$.pipe(
			startWith(false),
			tap(toggle => {
				if (!toggle) {
					this.navEvents = false;
					setTimeout(() => {
						this.disableClose = false;
					}, 500);
				}
			})
		),
		language: this.appFacade.language$.pipe(startWith('nl'))
	});

	linkToggle: 'map' | 'page' = 'map';

	ngOnInit(): void {
		this.pagesFacade.get();
		this.themesFacade.get();
		if (!this.service.navItem) {
			this.onClose();
		}
		if (this.service.navItem) {
			const link = this.service.navItem.controls.link.value;
			this.originLink = link;
			if (link && link.includes('page/')) {
				this.linkToggle = 'page';
			}
		}
		this.languageSelector();
	}

	onClose() {
		if (!this.linkChanged) {
			this.doClose();
		} else {
			const data: DialogItem = {
				title: 'Warning',
				message:
					'<p>Changing the link can <strong>break the connection</strong> of certain events, <br> make sure to check and relink those <strong>events</strong>/<strong>textfield-links</strong>.</p>',
				buttons: [
					{
						key: 'Confirm',
						type: 'success',
						confirm: true
					},
					{
						key: 'Cancel'
					}
				],
				confirm: 'Confirmed'
			};

			this.dialogRef = this.dialog.open(MessageDialogComponent, {
				data: data
			});
			this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.doClose();
					this.message.sendToast(
						`Link changed, relink your events and textfield-links!`,
						'warning'
					);
				}
			});
		}
	}

	/* 	navigates back to the Dataset page */
	doClose(): void {
		const routerLink = this.route?.parent?.snapshot.pathFromRoot
			.map(s => s.url)
			.reduce((a, e) => {
				if (a.length + e.length !== this.route?.parent?.snapshot.pathFromRoot.length) {
					return a.concat(e);
				}
				return a;
			})
			.map(s => s.path);

		this.router.navigate(routerLink as string[]);
	}

	getDropdownData(data: FormArray<FormGroup<NavDropdownItemForm>>) {
		return data.value as { [key: string]: unknown }[];
	}

	onSelectDropdown(row: Partial<NavigationItemDropdown>) {
		this.service.dropdownIndex = this.service.navDropdown.value.indexOf(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			row as any
		);
		this._dropdownEditor.next(true);
	}

	onDeleteDropdown(row: Partial<NavigationItemDropdown>) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const index = this.service.navDropdown.value.indexOf(row as any);
		this.service.removeDropdownItem(index);
	}

	onCloseDropdown(bool: boolean) {
		this._dropdownEditor.next(!bool);
	}

	onToggleEvents() {
		const events: Event[] = this.service.navEvents.getRawValue() as Event[];
		this.eventsFacade.toggleEvents(true);
		this.eventsFacade.setKey('events');
		this.eventsFacade.updateEvents(events);
		this.navEvents = true;
		this.disableClose = true;
	}

	onLinkChange(val: string) {
		if (!this.originLink) {
			return;
		}
		this.linkChanged = this.originLink !== val;
	}

	ngOnDestroy() {
		this.languageService.destroyLanguageSelector();
	}
}
