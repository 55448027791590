import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
	EditContainerContentDirective,
	YunoAdminButtonsModule,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { TooltipModule } from '@yuno/project-atlas/ui';

import { SpriteEditorComponent } from './editor/sprite-editor.component';
import { SpritesheetManagerComponent } from './manager/spritesheet-manager.component';
import { SpriteContainerComponent } from './sprite-container/sprite-container.component';
import { SpriteListComponent } from './sprite-list/sprite-list.component';

@NgModule({
	declarations: [
		SpriteContainerComponent,
		SpriteListComponent,
		SpriteEditorComponent,
		SpritesheetManagerComponent
	],
	imports: [
		CommonModule,
		YunoAdminButtonsModule,
		YunoEditContainerModule,
		TooltipModule,
		EditContainerContentDirective,
		YunoAdminSaveButtonComponent,
		YunoAdminCloseButtonComponent
	],
	exports: [SpritesheetManagerComponent, SpriteEditorComponent]
})
export class SpritesheetModule {}
