import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { CdnFile } from '@yuno/api/interface';

import { filesActions } from './files.actions';

export interface FilesState {
	loaded: boolean;
	files: Partial<CdnFile>[] | undefined;
	selectedLoaded: boolean;
	selected: Partial<CdnFile> | undefined;
	status: Status;
}

export const initialState: FilesState = {
	loaded: false,
	files: [],
	selectedLoaded: false,
	selected: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(filesActions.load, state => ({
		...state,
		loaded: false,
		status: Status.VALIDATING
	})),
	on(filesActions.loadSuccess, (state, { data }) => ({
		...state,
		files: data,
		loaded: true,
		status: Status.SUCCESS
	})),
	on(filesActions.loadFailure, state => ({
		...state,
		status: Status.FAILED
	})),

	on(filesActions.clearSelect, state => ({
		...state,
		selected: undefined,
		saved: undefined
	})),
	on(filesActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	}))
);

export const filesFeature = createFeature({
	name: 'files',
	reducer
});
