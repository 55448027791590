import { gql } from 'apollo-angular';

import { TilesetCustom } from '@yuno/api/interface';

export const GET_TILESETS_BY_APPID = gql`
	query getTilesetsByAppId($appId: String!) {
		customTilesets(appId: $appId) {
			_id
			id
			updatedAt
		}
	}
`;

export type CustomTilesetsQuery = {
	customTilesets: Partial<TilesetCustom>[];
};

export const GET_TILESET_BY_ID = gql`
	query getTilesetById($_id: String!) {
		selectedCustomTileset(_id: $_id) {
			_id
			id
			geojson
			tippecanoe {
				preset
				command
			}
		}
	}
`;

export type SelectCustomTilesetQuery = {
	selectedCustomTileset: Partial<TilesetCustom>;
};
