import { createActionGroup, props } from '@ngrx/store';

import { NavbarRoute } from './navbar.state';

export const navbarActions = createActionGroup({
	source: 'Navbar',
	events: {
		addRoute: props<{ route: NavbarRoute }>(),
		removeAt: props<{ index: number }>()
	}
});
