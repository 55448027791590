@if (service$ | async; as service) {
	@if (language$ | async; as language) {
		<section class="flex flex-col gap-4">
			@if (service.form) {
				<form [formGroup]="service.header" class="flex flex-col gap-4">
					<ng-container formGroupName="title">
						<div formGroupName="projectTitle">
							@for (lang of editService.languages; track lang) {
								@if (lang === language) {
									<yuno-forms-text
										[language]="lang"
										[formControlName]="lang"
										label="Title"
										placeholder="Add page title"
										class="flex-1"></yuno-forms-text>
								}
							}
						</div>
						<div formGroupName="description">
							@for (lang of editService.languages; track lang) {
								@if (lang === language) {
									<yuno-forms-text
										[language]="lang"
										[formControlName]="lang"
										label="Description"
										placeholder="Add page description"
										class="flex-1"></yuno-forms-text>
								}
							}
						</div>

						<yuno-forms-color
							[colorDirection]="'bottom'"
							formControlName="color"
							label="Title color"
							placeholder="#334155"
							class="flex-1"></yuno-forms-color>
					</ng-container>
					<ng-container formGroupName="logo">
						<yuno-forms-toggle
							formControlName="active"
							label="Show Logo"></yuno-forms-toggle>
						@if (service.logo.value.active) {
							<yuno-forms-select
								formControlName="position"
								label="Position"
								[selectValues]="['left', 'right', 'top-center', 'project']">
							</yuno-forms-select>
							<yuno-forms-image
								formControlName="src"
								label="Logo image"
								placeholder="image"
								class="flex-1"></yuno-forms-image>
							<section class="flex gap-4">
								<yuno-forms-number
									formControlName="height"
									label="Logo height"
									placeholder="96"></yuno-forms-number>
								<yuno-forms-number
									formControlName="width"
									label="Logo width"
									placeholder="96"></yuno-forms-number>
							</section>
							<yuno-forms-image
								formControlName="mobileSrc"
								label="Logo mobile image"
								placeholder="image"
								class="flex-1"></yuno-forms-image>
							<section class="flex gap-4">
								<yuno-forms-number
									formControlName="mobileHeight"
									label="Logo mobile height"
									placeholder="48"></yuno-forms-number>
								<yuno-forms-number
									formControlName="mobileWidth"
									label="Logo mobile width"
									placeholder="48"></yuno-forms-number>
							</section>
						}
					</ng-container>
				</form>
			}
			@if (service.form) {
				<form [formGroup]="service.banner" class="flex flex-col gap-4">
					<yuno-forms-toggle
						formControlName="active"
						label="Show Banner"></yuno-forms-toggle>
					@if (service.banner.value.active) {
						<ng-container formGroupName="imageBanner">
							<yuno-forms-toggle
								formControlName="active"
								label="Image Banner"></yuno-forms-toggle>
							<yuno-forms-toggle
								formControlName="reactive"
								label="Image Reactive"></yuno-forms-toggle>
							@if (service.banner.value.imageBanner?.active) {
								<yuno-forms-image
									formControlName="src"
									label="Banner image"
									placeholder="image"
									class="flex-1"></yuno-forms-image>
								<yuno-forms-image
									formControlName="mobileSrc"
									label="Banner image on Mobile Devices"
									placeholder="image"
									class="flex-1"></yuno-forms-image>
								<ng-container formGroupName="split">
									<yuno-forms-toggle
										formControlName="active"
										label="Split"></yuno-forms-toggle>
									@if (service.banner.value.imageBanner?.split?.active) {
										<section class="flex gap-4">
											<yuno-forms-color
												formControlName="backgroundColor"
												placeholder="#000"
												label="Background color"></yuno-forms-color>
											<yuno-forms-color
												formControlName="fontColor"
												placeholder="#fff"
												label="Font color"></yuno-forms-color>
										</section>
										<section formGroupName="description">
											@for (lang of editService.languages; track lang) {
												@if (lang === language) {
													<yuno-forms-text
														[formControlName]="lang"
														placeholder="header description"
														label="Description"></yuno-forms-text>
												}
											}
										</section>
									}
								</ng-container>
							}
						</ng-container>
					}
				</form>
			}
		</section>
	}
}
