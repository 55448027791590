import { gql } from 'apollo-angular';

import { App } from '@yuno/api/interface';

export const CREATE_APPLICATION = gql`
	mutation createApplication($dto: AppInput!) {
		createApplication(dto: $dto) {
			_id
			id
			updatedAt
		}
	}
`;

export type CreateAppQuery = {
	createApplication: App;
};
